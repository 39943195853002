export enum SyncInterval {
    DAILY = 'daily',
    WEEKDAYS = 'weekdays',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
    TETRAHOURLY = 'tetrahourly',
}

export interface AdvisoryGroup extends Document {
    name?: string;
    assets?: string;
    performance?: {
        aum?: number;
        investments?: number;
    };
    plan?: string;
    integrations?: any;
    allowed_integrations?: any;
    archived_integrations?: any;
    disable_sla_issues?: boolean;
    custom_connection_email_domain?: string;
    _id: string;
    created_at: Date;
    updated_at?: Date;
    deleted_at?: Date;
}

import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import api from '../../api';
import api2 from '../../api2';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import { ConnectionOutreachStatus } from '../../openApiClient';
import { AssetManagerSearchFilter } from '../Filters/GenericSearchFilter';
import GenericDrawer from './GenericDrawer';
import saveCsv from '../../utilities/file/saveCsv';
import '../../styles/addConnectionDrawer.css';

interface CreateBulkConnectionDrawerProps extends RouteComponentProps {
    userAccountListObj: Record<string, string[]>;
    loading: (timeout?: number, key?: string) => void;
    loaded: (key?: string) => void;
    close: () => void;
    setAlert: (message: string, isError?: boolean) => void;
    reloadData: () => void;
}

interface CommonData {
    name: string;
    integration: any | null;
    asset_manager: any | null;
    status: string;
    outreach_status?: string;
}

interface Integration {
    _id: string;
    name: string;
}

interface ConnectionResponse {
    _id: string;
    account_list: string[];
    user: {
        name: string;
        _id: string;
    };
    tfa_email?: string;
    use_tfa?: boolean;
    status: string;
    outreach_status?: string;
    asset_manager?: {
        name: string;
        _id: string;
    };
    integration?: {
        name: string;
        _id: string;
    };
}

interface ErrorResult {
    userId: string;
    accountIdList: string[];
    error: string;
}

interface State {
    connectionOutreachStatuses: string[];
    integrations: Integration[];
    commonData: CommonData;
    errors: Record<string, string>;
    keyValueList: Array<{ key: string; value: string }>;
    showCredentials: boolean;
}

const CreateBulkConnectionDrawer: React.FC<CreateBulkConnectionDrawerProps> = ({ userAccountListObj, loading, loaded, close, setAlert, reloadData }) => {
    const [state, setState] = useState<State>({
        connectionOutreachStatuses: [],
        integrations: [],
        commonData: {
            name: '',
            integration: null,
            asset_manager: null,
            status: 'Pending',
        },
        errors: {},
        keyValueList: [],
        showCredentials: false,
    });

    const loadData = async () => {
        let integrations: Integration[] = [];
        try {
            integrations = await api2.paginateApiRoute(async (paginate_params: any) => {
                return (
                    await api2.client.IntegrationApi.listIntegrations({
                        ...paginate_params,
                    })
                ).data.integrations;
            });
        } catch (error) {
            console.error(error);
        }

        const connectionOutreachStatuses = Object.values(ConnectionOutreachStatus);
        setState((prev) => ({
            ...prev,
            integrations,
            connectionOutreachStatuses,
            commonData: {
                ...prev.commonData,
                outreach_status: connectionOutreachStatuses?.[0],
            },
        }));
    };

    const optionallyDownloadCreationInfo = async (name: string, successResults: any[], errorResults: any[]) => {
        const shouldDownload = window.confirm(`Would you like to download the creation${errorResults.length ? ' and error' : ''} info?`);
        if (!shouldDownload) return;

        loading(0, 'optionallyDownloadCreationInfo');

        if (successResults.length) {
            const filename = `Connection_creation_info_${name}.csv`;
            const csvHeader =
                'User Name,User ID,Account IDs,TFA Email,Use TFA,Connection Status,Connection Outreach Status,Asset Manager Name,Asset Manager ID,Integration Name,Integration ID';
            let successCsvEntries = [];

            for (const success of successResults) {
                const connection = await api.get(`/connections/${success._id}?populate_user=true&populate_asset_manager=true&populate_integration=true`);
                successCsvEntries.push([
                    connection.user.name,
                    connection.user._id,
                    success.account_list.join(' | '),
                    connection.tfa_email,
                    connection.use_tfa,
                    connection.status,
                    connection.outreach_status,
                    connection.asset_manager?.name,
                    connection.asset_manager?._id,
                    connection.integration?.name,
                    connection.integration?._id,
                ]);
            }

            const successCsv = [csvHeader, ...successCsvEntries].join('\n');
            saveCsv(successCsv, filename);
        }

        if (errorResults.length) {
            const filename = `Connection_creation_error_${name}.csv`;
            const errorCsvHeader = 'User Name,User ID,Account IDs,Error';
            let errorCsvEntries = [];

            let users: any[] = [];
            try {
                const response = await api2.client.UserApi.listUsers({
                    users: errorResults.map((error) => error.userId),
                });
                users = response.data.users || [];
            } catch (error) {
                console.error(error);
            }

            for (const error of errorResults) {
                let user = users.find((user) => user._id === error.userId);
                errorCsvEntries.push([user?.name || 'Unknown User', error.userId, error.accountIdList.join(' | '), error.error]);
            }

            const errorCsv = [errorCsvHeader, ...errorCsvEntries].join('\n');
            saveCsv(errorCsv, filename);
            window.alert(`Information about the ${errorResults.length} errors has been downloaded to ${filename}.`);
        }

        loaded('optionallyDownloadCreationInfo');
    };

    const handleSubmit = async (data: CommonData) => {
        loading(0, 'createOrSaveConnection');

        const successResults: ConnectionResponse[] = [];
        const errorResults: ErrorResult[] = [];

        const createConnection = async (userId: string, accountIdList: string[]) => {
            const account_list = userAccountListObj[userId];

            const connectionBody = {
                account_list: accountIdList,
                account: account_list[0],
                name: data.name,
                integration: data.integration?._id ?? null,
                credentials: {},
                status: data.status,
                outreach_status: data.outreach_status,
                asset_manager: data.asset_manager?._id ?? null,
            };

            let connectionRes = await api.post('/connections', connectionBody);

            if (connectionRes) {
                successResults.push(connectionRes);
            } else {
                errorResults.push({
                    userId,
                    accountIdList,
                    error: 'Unknown error',
                });
            }
        };

        const functions = Object.entries(userAccountListObj)
            .map(([userId, accountIdList]) => {
                if (!accountIdList?.length) return null;
                return () => createConnection(userId, accountIdList);
            })
            .filter(Boolean);

        await Promise.all(functions.map((f) => f!()));

        if (successResults.length === 0) {
            setDismissableAlert(setAlert, `Error creating all ${functions.length} connections.`, true);
            console.log('Error creating all connections:', errorResults);
        } else if (errorResults.length === 0) {
            setDismissableAlert(setAlert, `Connections have been created.`);
        } else {
            setDismissableAlert(setAlert, `Error creating some connections. ${errorResults.length} / ${functions.length} connections failed.`, true);
            console.log('Error creating some connections:', errorResults);
        }

        loaded('createOrSaveConnection');
        await optionallyDownloadCreationInfo(data.name, successResults, errorResults);
        await reloadData();

        // Only close if no errors
        if (errorResults.length === 0) {
            close();
        }
    };

    const getFields = () => ({
        'Connection Name': {
            label: 'Connection Name',
            placeholder: 'Name',
            fieldName: 'name',
            type: 'text',
        },
        Integration: {
            placeholder: 'Select Integration',
            label: 'Integration',
            defaultOptionString: 'AltX Manual Investments',
            optionClearable: true,
            fieldName: 'integration',
            type: 'select',
            options: [...state.integrations],
            optionField: 'name',
            callback: (integration: any) => {
                setState((prev) => ({
                    ...prev,
                    showCredentials: true,
                    commonData: {
                        ...prev.commonData,
                        integration: integration?._id || null,
                        status: !integration ? 'Active' : prev.commonData.status,
                    },
                }));
            },
        },
        'Asset Manager': {
            label: 'Asset Manager',
            type: 'custom',
            component: () => (
                <AssetManagerSearchFilter
                    defaultLabel={'Search Asset Manager'}
                    selected={state.commonData.asset_manager}
                    onChange={(assetManager) => {
                        setState((prev) => ({
                            ...prev,
                            commonData: {
                                ...prev.commonData,
                                asset_manager: assetManager?._id || null,
                            },
                        }));
                    }}
                    width={'100%'}
                    defaultOptions={true}
                    noDefaultOption={true}
                />
            ),
        },
        'Connection Status': {
            placeholder: 'Select Connection Status',
            label: 'Connection Status',
            fieldName: 'status',
            type: 'option',
            options: ['Active', 'Pending', 'Error', 'Under Review'],
            optionClearable: false,
        },
        'Connection Outreach Status': {
            placeholder: 'Select Connection Outreach Status',
            label: 'Connection Outreach Status',
            fieldName: 'outreach_status',
            type: 'option',
            options: state.connectionOutreachStatuses,
            optionClearable: false,
        },
    });

    const checkCommonData = (data: CommonData, errors: Record<string, string>) => {
        if (!data.name) errors['name'] = 'This field is required.';
    };

    const handleFieldChange = (fieldName: string, value: any) => {
        setState((prev) => ({
            ...prev,
            commonData: {
                ...prev.commonData,
                [fieldName]: value,
            },
        }));
    };

    return (
        <GenericDrawer
            title="Create Connection"
            close={close}
            onSubmit={handleSubmit}
            actionStr="Create Connection"
            loadingKey="CreateBulkConnectionDrawer"
            loading={loading}
            loaded={loaded}
            errors={state.errors}
            setErrors={(errors) => setState((prev) => ({ ...prev, errors }))}
            isSafeToSave={(_, formData) => {
                const errors: Record<string, string> = {};
                checkCommonData(formData, errors);
                return Object.keys(errors).length === 0;
            }}
            getFields={getFields}
            loadData={loadData}
            className="addNewAdvisorToFirm-drawer"
            onFieldChange={handleFieldChange}
            formData={state.commonData}
        />
    );
};

export default withRouter(CreateBulkConnectionDrawer);

import admin from './admin';
import advisor from './advisor';
import investor from './investor';
import { ClientPageOptions } from './routeTypes';
import { UserAccess } from '../types/User';

interface RouteParams {
    access: (typeof UserAccess)[keyof typeof UserAccess];
    userId?: string;
    accountId?: string;
    investmentId?: string;
    documentId?: string;
}

const getClientVuePath = ({
    access,
    page,
    userId,
    accountId,
    investmentId,
}: RouteParams & {
    page: ClientPageOptions;
}) => {
    const pathMap = {
        admin: !userId ? null : admin.client[page](userId),
        advisor: !userId ? null : advisor.client[page](userId),
        investor: investor[page](accountId ?? '', investmentId ?? ''),
        asset_manager: null,
    };
    return pathMap[access];
};

// build routes map, with each page as a key
// const routes: { [key in ClientPageOptions]: (props: RouteParams) => string | null } = {} as { [key in ClientPageOptions]: (props: RouteParams) => string | null };

// Object.values(ClientPageOptions).forEach((page) => {
//     routes[page] = (props: RouteParams) => getClientVuePath({ ...props, page });
// });

const routes2 = {
    investments: (props: RouteParams) => getClientVuePath({ ...props, page: ClientPageOptions.investments }),
    capitalCalls: (props: RouteParams) => getClientVuePath({ ...props, page: ClientPageOptions.capitalCalls }),
    transactions: (props: RouteParams) => getClientVuePath({ ...props, page: ClientPageOptions.transactions }),
    documents: (props: RouteParams) => getClientVuePath({ ...props, page: ClientPageOptions.documents }),
    taxCenter: (props: RouteParams) => getClientVuePath({ ...props, page: ClientPageOptions.taxCenter }),
    manageInvestments: (props: RouteParams) => getClientVuePath({ ...props, page: ClientPageOptions.manageInvestments }),
    performance: (props: RouteParams) => getClientVuePath({ ...props, page: ClientPageOptions.performance }),
    manageAccount: (props: RouteParams) => getClientVuePath({ ...props, page: ClientPageOptions.manageAccount }),

    // investmentDetails = (userId: string, investmentId: string) => `${getClientVueBasePath(userId)}/details/${investmentId}`;
    investmentDetails: (props: RouteParams) => {
        const { access, userId, accountId, investmentId } = props;
        const pathMap = {
            admin: !userId ? null : admin.client.investmentDetails(userId, investmentId ?? ''),
            advisor: !userId ? null : advisor.client.investmentDetails(userId, investmentId ?? ''),
            investor: investor.investmentDetails(accountId ?? '', investmentId ?? ''),
            asset_manager: null,
        };
        return pathMap[access];
    },

    viewDocument: (props: RouteParams) => {
        const { access, userId, accountId, investmentId, documentId } = props;
        const pathMap = {
            admin: !userId ? null : admin.client.viewDocument(userId, accountId ?? '', investmentId ?? '', documentId ?? ''),
            advisor: !userId ? null : advisor.client.viewDocument(userId, accountId ?? '', investmentId ?? '', documentId ?? ''),
            investor: investor.viewDocument(accountId ?? '', investmentId ?? '', documentId ?? ''),
            asset_manager: null,
        };
        return pathMap[access];
    },
};

export default routes2;

import React from 'react';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import GenericDrawer from './GenericDrawer';
import '../../styles/addConnectionDrawer.css';

const DEACTIVATE_TEXT = 'DEACTIVATE';

interface DeactivateDrawerProps {
    dataType: string;
    name: string;
    deactivate: () => Promise<boolean>;
    loaded: () => void;
    reloadData: () => void;
    close: () => void;
    setAlert: (message: string, isError?: boolean) => void;
}

const DeactivateDrawer: React.FC<DeactivateDrawerProps> = ({ dataType, name, deactivate, loaded, reloadData, close, setAlert }) => {
    const getFields = () => ({
        DeactivateText: {
            label: 'Type "DEACTIVATE" to confirm',
            fieldName: 'deactivateText',
            type: 'text',
        },
    });

    const handleSubmit = async (formData: Record<string, any>) => {
        if (formData?.deactivateText !== DEACTIVATE_TEXT) {
            return;
        }

        try {
            const res = await deactivate();
            loaded();

            if (res) {
                setDismissableAlert(setAlert, `The ${dataType} has been deactivated.`);
                await reloadData();
                close();
            } else {
                setDismissableAlert(setAlert, `Error deactivating ${dataType}.`, true);
            }
        } catch (error) {
            loaded();
            setDismissableAlert(setAlert, `Error deactivating ${dataType}.`, true);
        }
    };

    return (
        <GenericDrawer
            title={`Deactivate ${dataType} ${name}?`}
            actionStr="Deactivate"
            className="addNewAdvisorToFirm-drawer"
            close={close}
            onSubmit={handleSubmit}
            getFields={getFields}
            isSafeToSave={(_, formData) => formData?.deactivateText === DEACTIVATE_TEXT}
            errors={{
                deactivateText: 'Please type "DEACTIVATE" to confirm',
                submit: 'Please type "DEACTIVATE" exactly to confirm',
            }}
        />
    );
};

export default DeactivateDrawer;

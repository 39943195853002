import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';

import AddEditExchangeDrawer from './AddEditExchangeDrawer';

import _ from 'lodash';
import { formatUTCDate } from '../../../utilities/format/formatDate';
import IconTextButton from '../../../components/Buttons/IconTextButton';
import { EM_DASH, VUES } from '../../../constants/constantStrings';
import { clearQueryParams } from '../../../utilities/query/queryParamHelpers';
import PaginationTable from '../../../components/Tables/PaginationTable';
import { CopyButton } from '../../../components/Buttons/IconButton';

type ExchangeTableProps = {
    user?: { name?: string };
    closeDrawer: () => void;
    setTitle: (title: React.ReactNode) => void;
    vue: string;
    history: any;
    loading: (progress: number, key: string) => void;
    loaded: (key: string) => void;
    openDrawer: (content: () => JSX.Element) => void;
    setAlert: (alert: any) => void;
    loadData: () => Promise<void>;
};
export const ExchangeTable = (props: ExchangeTableProps) => {
    const [childCallables, setChildCallables] = useState<{ loadData: () => Promise<void> } | null>(null);
    const [selectedUser] = useState<{ _id: string } | null>(null);
    const { user, setTitle, vue, history, loading, loaded, closeDrawer, openDrawer } = props;

    useEffect(() => {
        const load = async () => {
            // if not admin, redirect to home
            if (vue !== VUES.ADMIN) {
                console.log('not admin, redirect to home');
                history.push('/');
                return;
            }

            loading(0, 'ExchangeTable_load');
            loaded('ExchangeTable_load');
        };

        const loadQueryParams = () => {
            // Add your query params logic here
        };

        load();
        loadQueryParams();

        return () => {
            closeDrawer();
            clearQueryParams(['tab']);
        };
    }, [vue, history, loading, loaded, closeDrawer]);

    useEffect(() => {
        if (!_.isEqual(user?.name, props.user?.name)) {
            setTitle(
                <>
                    <span className="title-account-name">{props.user?.name}</span>
                    <div className="separator" />
                    Exchanges
                </>
            );
        }
    }, [props.user?.name, setTitle]);

    const getRoute = () => `/exchanges`;

    const getColumns = () => {
        return [
            {
                title: 'Name',
                field: 'name',
                sort: { field: 'name' },
                render: (col: any, row: any, items: { [x: string]: any }, i: string) => {
                    const exchange = items[i];
                    const name = exchange.name;

                    return (
                        <td key={'exchange_name' + i} title={`${name ?? EM_DASH}`}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                {name}
                                <CopyButton text={exchange._id} title={`Copy ID to Clipboard (${exchange._id})`} />
                            </div>
                        </td>
                    );
                },
            },
            {
                title: 'Description',
                field: 'description',
                sort: { field: 'description' },
            },
            {
                title: 'Ticker Prefix',
                field: 'ticker_prefix',
                sort: { field: 'ticker_prefix' },
            },
            {
                title: 'Website',
                field: 'website',
                sort: { field: 'website' },
            },
            {
                title: 'Location',
                field: 'location',
                sort: { field: 'location.city' },
                render: (col: any, row: any, items: { [x: string]: any }, i: string) => {
                    const exchange = items[i];
                    return (
                        <td key={'ral_location' + i} title={`Location`} style={{ width: '50px' }}>
                            {exchange.location?.city}, {exchange.location?.country}
                        </td>
                    );
                },
            },
            {
                title: 'Founded',
                field: 'founded',
                sort: { field: 'founded' },
                format: (value: any) => formatUTCDate(value),
            },
            {
                title: 'Edit',
                render: (col: any, row: any, items: { [x: string]: any }, i: string) => {
                    const exchange = items[i];
                    return (
                        <td key={'ral_editEntity' + i} title={`Edit entity`} style={{ width: '50px' }}>
                            <FontAwesomeIcon
                                icon={faPencil}
                                size="xl"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openAddEditExchangeDrawer(exchange._id);
                                }}
                            />
                        </td>
                    );
                },
            },
        ];
    };

    const openAddEditExchangeDrawer = (exchangeId = null) => {
        openDrawer(() => (
            <AddEditExchangeDrawer exchangeId={exchangeId} close={closeDrawer} loading={loading} loaded={loaded} setAlert={props.setAlert} reloadData={childCallables?.loadData} />
        ));
    };

    return (
        <>
            <div className="docProcess-container">
                <PaginationTable
                    noInvestmentDropdown={true}
                    containerStyle={{ margin: '0px' }}
                    name="processor"
                    initialSortField="name"
                    initialSortAscending={true}
                    loading={loading}
                    loaded={loaded}
                    disableDefaultOption={true}
                    noTypeDropdown={true}
                    noDateFilters={true}
                    dateFilterField="created_at"
                    titleStyle={{ margin: 0 }}
                    headerStyle={{
                        alignItems: 'flex-end',
                    }}
                    route={getRoute()}
                    title={
                        <>
                            Exchanges
                            <IconTextButton icon="/images/icons/plus.png" text="New Exchange" onClick={() => openAddEditExchangeDrawer(null)} />
                        </>
                    }
                    columns={getColumns()}
                    setCallables={setChildCallables}
                    selectedUserId={selectedUser?._id}
                    vue={vue}
                    disableTypeFilter={true}
                    postProcessData={(data: any) => {
                        return data;
                    }}
                    enableClickSelect={true}
                    useApi2={true}
                    getResultsFromResponse={(response: { exchanges: any }) => response.exchanges}
                />
            </div>

            <div style={{ clear: 'both', height: '100px' }} />
        </>
    );
};

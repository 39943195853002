import React, { useState } from 'react';
import UTCDatePicker from '../../components/Inputs/UTCDatePicker/UTCDatePicker';
import api2 from '../../api2';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';

import { withRouter, RouteComponentProps } from 'react-router-dom';

import { TimelessDate } from '../../types/TimelessDate';
import { saveAs } from 'file-saver';

interface AdminToolsProps extends RouteComponentProps {
    setAlert: (alert: string, alertId?: string | null, alertError?: boolean) => void;
    loading: (left: number, loadingId: string | null) => void;
    loaded: (loadingId: string | null) => void;
}

const AdminTools: React.FC<AdminToolsProps> = (props) => {
    const [startDate, setStartDate] = useState<TimelessDate | null>(null);
    const [endDate, setEndDate] = useState<TimelessDate | null>(null);

    const handleStartDateChange = (date: TimelessDate | null) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date: TimelessDate | null) => {
        setEndDate(date);
    };

    const generateUserActionsReport = async () => {
        if (!startDate || !endDate) {
            setDismissableAlert(props.setAlert, 'Please select both start and end dates.', true);
            return;
        }

        props.loading(0, 'generateUserActionsReport');
        try {
            const response = await api2.client.AdminApi.getActivityCounts({
                start_date: startDate.toISOString(),
                end_date: endDate.toISOString(),
            });

            const csvContent = [
                ['Name', 'Email', 'ID', 'Messages Completed', 'Messages Changed', 'Documents Changed', 'Extracts Changed', 'Sorts Changed'],
                ...(response.data.data?.map((item) => [
                    item.name,
                    item.email,
                    item.id,
                    item.messages_completed,
                    item.messages_changed,
                    item.documents_changed,
                    item.extracts_changed,
                    item.sorts_changed,
                ]) || []),
            ];

            const csvBlob = new Blob([csvContent.map((e) => e.join(',')).join('\n')], { type: 'text/csv;charset=utf-8;' });
            saveAs(csvBlob, 'user_actions_report.csv');

            setDismissableAlert(props.setAlert, 'User actions report generated and downloaded successfully.', false);
        } catch (error) {
            setDismissableAlert(props.setAlert, 'Failed to generate user actions report.', true);
            console.error(error);
        } finally {
            props.loaded('generateUserActionsReport');
        }
    };

    return (
        <div className="admin-tools-panel" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
            <h2>Admin Tools</h2>
            <div className="date-range-selector" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' }}>
                    <label>Select Start Date:</label>
                    <UTCDatePicker placeholderText="MM-DD-YYYY" dateFormat="MM-dd-yyyy" selected={startDate} onChange={handleStartDateChange} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' }}>
                    <label>Select End Date:</label>
                    <UTCDatePicker placeholderText="MM-DD-YYYY" dateFormat="MM-dd-yyyy" selected={endDate} onChange={handleEndDateChange} />
                </div>
            </div>
            <button style={{ padding: '10px 20px' }} onClick={generateUserActionsReport}>
                Generate User Actions Report
            </button>
        </div>
    );
};

export default withRouter(AdminTools);

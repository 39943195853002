// See LICENSE.md file in project root directory

import axios from 'axios';

import pLimit from 'p-limit';
import api from './api';
import { VUES } from './constants/constantStrings';
import * as openApiClientLib from './openApiClient/index';

// construct API and UI URLs based on the host
let uiUrl, apiUrl, environment;
const hostHas = (x) => window.location.hostname.includes(x);
if (hostHas('localhost')) {
    uiUrl = 'http://localhost:3000';
    apiUrl = 'http://localhost:8001';
    environment = 'local';
} else if (hostHas('dev.altexchange.com')) {
    uiUrl = 'https://app.dev.altexchange.com';
    apiUrl = 'https://api.dev.altexchange.com';
    environment = 'development';
} else if (hostHas('staging.altexchange.com') || hostHas('.altexchange.pages.dev')) {
    uiUrl = `https://${window.location.hostname}`;
    apiUrl = 'https://api.staging.altexchange.com';
    environment = 'staging';
} else {
    // Production
    uiUrl = 'https://app.altexchange.com';
    apiUrl = 'https://api.altexchange.com';
    environment = 'production';
}

const apiUrlV2 = `${apiUrl}/v2`;

const getAccessToken = () => {
    const authHeader = api.getHeaders()['Authorization'];
    return authHeader ? authHeader.replace('Bearer ', '') : undefined;
};

class RequestError extends Error {
    constructor(status, statusText, message, data = {}) {
        super(message);
        this.status = status;
        this.statusText = statusText;
        this.data = data;
    }
    // override print
    toString() {
        return JSON.stringify({
            status: this.status,
            statusText: this.statusText,
            message: this.message,
            data: this.data,
        });
    }
}

// Errors must be a map of status codes to callbacks that accept the error response data
const handleError = async (request, errors = {}) => {
    try {
        // attempt the request
        const res = await request();
        return {
            success: true,
            status: res.status,
            statusText: res.statusText,
            data: res.data,
        };
    } catch (error) {
        // Check if the token has expired
        const isOnLoginPage = window.location.pathname.endsWith('/login');
        if (isTokenExpired() && !isOnLoginPage) {
            const decodedToken = decodeToken();
            if (decodedToken.role === VUES.ASSET_MANAGER) {
                window.location.href = `/assetmanager/${decodedToken.id}/login`;
            } else {
                window.location.href = '/login';
            }
        }
        if (axios.isAxiosError(error)) {
            console.log('Axios error encountered', error);
            // Execute error handler based on status code if exists
            const status = error.response?.status;
            if (errors[status]) {
                return errors[status](error.response.data);
            }
            // Default error handling
            throw new RequestError(error.response?.status, error.response?.statusText, error.response?.data?.message ?? error.message, error.response?.data);
        } else {
            console.log('Non-Axios error encountered', error);
            throw error; // Rethrow if it's not an axios error
        }
    }
};

const decodeToken = () => {
    try {
        const token = api.getHeaders()['Authorization'].split(' ')[1];
        let decode = JSON.parse(atob(token.split('.')[1]));
        return decode;
    } catch (e) {
        return null;
    }
};

const isTokenExpired = () => {
    try {
        const decodedData = decodeToken();
        if (decodedData) {
            // exp is in UTC, but now is local
            const now = new Date();
            const exp = new Date(decodedData.exp * 1000);
            const isExpired = now > exp;
            return isExpired;
        }
    } catch (error) {
        console.log('error checking token expiration', error);
    }
    return false;
};

const paginateApiRoute = async (fetch_function) => {
    let page = 1;
    let results = [];
    let response_data = await fetch_function({
        limit: 100,
        skip: (page - 1) * 100,
    });
    results = results.concat(response_data);
    while (response_data.length === 100) {
        page += 1;
        response_data = await fetch_function({
            limit: 100,
            skip: (page - 1) * 100,
        });
        results = results.concat(response_data);
    }
    return results;
};

// if count is not known, use will use the above function "paginateApiRoute"
const paginateApiRouteConcurrently = async (fetch_function, total_count) => {
    if (!total_count || typeof total_count !== 'number' || total_count <= 0) {
        return paginateApiRoute(fetch_function);
    }

    const promises = [];
    for (let i = 0; i < total_count; i += 100) {
        promises.push(fetch_function({ limit: 100, skip: i }));
    }

    const limit = pLimit(30);
    const results = await Promise.all(promises.map((promise) => limit(() => promise)));
    return results.flat();
    // TODO: implement concurrent pagination
};

const openApiClientConfig = new openApiClientLib.Configuration({
    accessToken: getAccessToken,
    basePath: apiUrl,
});

// Add a response interceptor
axios.interceptors.response.use(
    (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            const isOnLoginPage = window.location.pathname.endsWith('/login');
            if (!isOnLoginPage) {
                const decodedToken = decodeToken();
                if (decodedToken?.role !== VUES.ASSET_MANAGER) {
                    window.location.href = '/login';
                } else {
                    window.location.href = `/assetmanager/${decodedToken.id}/login`;
                }
            }
        }
        // Rethrow the error for further handling if needed
        return Promise.reject(error);
    }
);

const api2 = {
    uiUrl,
    apiUrl,
    environment,

    decodeToken,

    openApiClientConfig,

    client: {
        // Export instances of all of the openApiClient apis that are used in the ui
        UserApi: new openApiClientLib.UserApi(openApiClientConfig),
        AccountApi: new openApiClientLib.AccountApi(openApiClientConfig),
        AuditResultApi: new openApiClientLib.AuditResultApi(openApiClientConfig),
        AdminTeamApi: new openApiClientLib.AdminTeamApi(openApiClientConfig),
        AdminApi: new openApiClientLib.AdminApi(openApiClientConfig),
        AdvisoryGroupApi: new openApiClientLib.AdvisoryGroupApi(openApiClientConfig),
        AdvisoryGroupExtegrationSyncApi: new openApiClientLib.ExtegrationSyncApi(openApiClientConfig),
        CommitmentApi: new openApiClientLib.CommitmentApi(openApiClientConfig),
        ConnectionApi: new openApiClientLib.ConnectionApi(openApiClientConfig),
        ConnectionDataApi: new openApiClientLib.ConnectionDataApi(openApiClientConfig),
        ContactApi: new openApiClientLib.ContactApi(openApiClientConfig),
        DocumentApi: new openApiClientLib.DocumentApi(openApiClientConfig),
        ExchangeApi: new openApiClientLib.ExchangeApi(openApiClientConfig),
        InvestmentApi: new openApiClientLib.InvestmentApi(openApiClientConfig),
        InvestmentMasterApi: new openApiClientLib.InvestmentMasterApi(openApiClientConfig),
        IntegrationApi: new openApiClientLib.IntegrationApi(openApiClientConfig),
        MessagesApi: new openApiClientLib.MessagesApi(openApiClientConfig),
        NotesApi: new openApiClientLib.NoteApi(openApiClientConfig),
        TransactionApi: new openApiClientLib.TransactionApi(openApiClientConfig),
        ValuationApi: new openApiClientLib.ValuationApi(openApiClientConfig),
        AssetManagerApi: new openApiClientLib.AssetManagerApi(openApiClientConfig),
        AssetManagerPortalApi: new openApiClientLib.AssetManagerPortalApi(openApiClientConfig),
        RALRequestApi: new openApiClientLib.RALRequestApi(openApiClientConfig),
        RALRequestTemplateApi: new openApiClientLib.RALRequestTemplateApi(openApiClientConfig),
        DefaultDocumentImportSettingApi: new openApiClientLib.DefaultDocumentImportSettingApi(openApiClientConfig),
    },

    paginateApiRoute,
    paginateApiRouteConcurrently,

    get: async (endpoint, errors = {}, source = {}) => {
        return await handleError(async () => await axios.get(apiUrlV2 + endpoint, { headers: api.getHeaders(), cancelToken: source.token }), errors);
    },

    getBlob: async (endpoint, errors = {}) => {
        return await handleError(async () => await axios.get(endpoint, { responseType: 'blob' }), errors);
    },

    post: async (endpoint, data = {}, errors = {}) => {
        return await handleError(async () => await axios.post(apiUrlV2 + endpoint, data, { headers: api.getHeaders() }), errors);
    },

    patch: async (endpoint, data = {}, errors = {}) => {
        return await handleError(async () => await axios.patch(apiUrlV2 + endpoint, data, { headers: api.getHeaders() }), errors);
    },

    delete: async (endpoint, errors = {}) => {
        return await handleError(async () => await axios.delete(apiUrlV2 + endpoint, { headers: api.getHeaders() }), errors);
    },
};
export default api2;

import { faCopy, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, MouseEvent } from 'react';

import { useState } from 'react';
import styles from '../../styles/iconButton.module.scss';

interface IconButtonProps {
    key?: string;
    faIcon?: IconDefinition;
    iconString?: string;
    size?: number | undefined | string;
    iconStyle?: CSSProperties;
    className?: string;
    onClick?: (e: MouseEvent<HTMLDivElement | HTMLAnchorElement>) => void;
    allowPropagation?: boolean;
    href?: string;
    title?: string;
    color?: string;
    containerStyle?: CSSProperties;
    label?: string;
    labelClassName?: string;
    labelPlacement?: 'left' | 'right' | 'top' | 'bottom';
    labelStyle?: CSSProperties;
    disabled?: boolean;
    noHover?: boolean;
    'data-testid'?: string; // Add data-testid prop
}

const IconButton: React.FC<IconButtonProps> = (props) => {
    const {
        key,
        faIcon,
        iconString,
        size,
        iconStyle,
        className,
        onClick,
        allowPropagation,
        href,
        title,
        color,
        containerStyle,
        label,
        labelClassName,
        labelPlacement = 'right',
        labelStyle,
        disabled,
        noHover,
        'data-testid': dataTestId, // Destructure data-testid
    } = props;

    const getIcon = (): IconDefinition => {
        if (faIcon) return faIcon;
        if (iconString === 'copy') return faCopy;
        return faCopy;
    };

    const iconStyleObj: CSSProperties = {
        width: size ?? '20px',
        height: size ?? '20px',
        ...iconStyle,
    };

    let finalClassName = className;
    let finalTitle = title;

    if (disabled) {
        finalClassName = `${className || ''} ${styles.disabled}`;
        finalTitle = `${title ? `${title} ` : ''}(Disabled)`;
    }

    if (noHover) {
        finalClassName = `${finalClassName} ${styles.noHover}`;
    }

    let component = <FontAwesomeIcon style={iconStyleObj} icon={getIcon()} size="xl" className={`${styles.icon} ${label ? '' : finalClassName}`} data-testid="icon-button-icon" />;

    if (label) {
        const sharedTextContainerStyle: CSSProperties = {
            display: 'flex',
            alignItems: 'center',
            gap: '7px',
        };

        let labelElem = (
            <span
                className={`${styles.label} ${labelClassName}`}
                style={{
                    fontSize: size ?? '16px',
                    whiteSpace: 'nowrap',
                    ...labelStyle,
                }}
            >
                {label}
            </span>
        );

        if (labelPlacement === 'left') {
            component = (
                <div className={finalClassName} style={{ ...sharedTextContainerStyle }}>
                    {labelElem}
                    {component}
                </div>
            );
        } else if (labelPlacement === 'right') {
            component = (
                <div className={finalClassName} style={{ ...sharedTextContainerStyle }}>
                    {component}
                    {labelElem}
                </div>
            );
        } else if (labelPlacement === 'top') {
            component = (
                <div className={finalClassName} style={{ ...sharedTextContainerStyle, flexDirection: 'column' }}>
                    {labelElem}
                    {component}
                </div>
            );
        } else if (labelPlacement === 'bottom') {
            component = (
                <div className={finalClassName} style={{ ...sharedTextContainerStyle, flexDirection: 'column' }}>
                    {component}
                    {labelElem}
                </div>
            );
        }
    }

    const sharedContainerStyle: CSSProperties & { '--icon-button-component-color'?: string } = {
        '--icon-button-component-color': color ?? 'var(--color-dark-blue)',
        padding: '5px',
        ...containerStyle,
    };

    const onClickHandler = (e: MouseEvent<HTMLDivElement | HTMLAnchorElement>) => {
        if (!allowPropagation) {
            e.stopPropagation();
            e.preventDefault();
        }
        if (disabled) return;
        onClick?.(e);
    };

    if (href) {
        return (
            <a className={styles.main} title={finalTitle ?? label} onClick={onClick ? onClickHandler : undefined} style={{ ...sharedContainerStyle }} href={href} key={key}>
                {component}
            </a>
        );
    }

    return (
        <div
            className={`${styles.main} ${disabled ? styles.disabled : ''}`}
            title={finalTitle ?? label}
            onClick={onClickHandler}
            style={{ ...sharedContainerStyle }}
            data-testid={dataTestId} // Pass data-testid to div
            key={key}
        >
            {component}
        </div>
    );
};

export default IconButton;

interface CopyButtonProps extends Omit<IconButtonProps, 'iconString'> {
    text: string;
    title?: string;
    onClick?: (e: MouseEvent<HTMLDivElement | HTMLAnchorElement>) => void;
    showCopiedText?: boolean;
}

export const CopyButton: React.FC<CopyButtonProps> = ({ text, showCopiedText = true, ...props }) => {
    const [copied, setCopied] = useState(false);

    const handleClick = (e: MouseEvent<HTMLDivElement | HTMLAnchorElement>) => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
        props.onClick?.(e);
    };

    return (
        <div style={{ position: 'relative' }}>
            <IconButton {...props} iconString="copy" title={props.title || 'Copy to Clipboard'} onClick={handleClick} size={props.size || '18px'} />
            {copied && showCopiedText && (
                <div
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        backgroundColor: 'black',
                        color: 'white',
                        padding: '5px',
                        borderRadius: '3px',
                        marginTop: '5px',
                    }}
                >
                    Copied!
                </div>
            )}
        </div>
    );
};

import QRCode from 'qrcode.react';
import React, { useState, useEffect } from 'react';
import api from '../../api';
import '../../styles/tfaDrawer.css';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';

interface TFADrawerProps {
    loading: () => Promise<void>;
    loaded: () => Promise<void>;
    reloadData: () => Promise<void>;
    close: () => Promise<void>;
    setAlert: (message: string, isError: boolean, duration: number) => void;
}

interface TFAState {
    loading: boolean;
    setupCompleted: boolean;
    otpURL: string;
    recoveryCodes: Array<{ code: string }>;
    secret: string;
    totp: string;
}

const TFADrawer: React.FC<TFADrawerProps> = ({ loading, loaded, reloadData, close, setAlert }) => {
    const [tfa, setTfa] = useState<TFAState>({
        loading: false,
        setupCompleted: false,
        otpURL: '',
        recoveryCodes: [],
        secret: '',
        totp: '',
    });
    const [user, setUser] = useState<any>(null);
    const [verificationError, setVerificationError] = useState<string | undefined>(undefined);

    useEffect(() => {
        const initialize = async () => {
            await loading();
            const user = await loadUser();
            setUser(user);
            if (!user?.tfa?.totp?.enabled) {
                await setupTfa(user);
            }
            await loaded();
        };
        initialize();
    }, [loading, loaded]);

    const loadUser = async () => {
        const response = await api.get('/users/self');
        return response;
    };

    const disableTfa = async () => {
        setTfa((prev) => ({ ...prev, loading: true }));
        const response = await api.post(`/users/${user?._id}/totp_status`, {
            totpEnabled: false,
        });
        setTfa((prev) => ({ ...prev, loading: false }));
        if (response?.success) {
            setDismissableAlert(setAlert, 'TFA Disabled Successfully', false, 7000);
            closeDrawer();
        } else {
            setDismissableAlert(setAlert, 'TFA Disable Failed', true, 7000);
            closeDrawer();
        }
    };

    const setupTfa = async (user: any) => {
        setTfa((prev) => ({ ...prev, loading: true }));
        const response = await api.post(`/users/${user?._id}/totp_setup`);
        const { recovery_codes, totp_secret } = response;
        const label = `AltExchange - ${user?.email}`;
        const issuer = 'AltExchange';
        const otpURL = `otpauth://totp/${label}?secret=${totp_secret}&issuer=${issuer}&algorithm=SHA1&digits=6&period=30`;
        setTfa({
            recoveryCodes: recovery_codes,
            secret: totp_secret,
            otpURL,
            setupCompleted: true,
            loading: false,
            totp: '',
        });
    };

    const handleTotpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTfa((prev) => ({ ...prev, totp: e.target.value }));
    };

    const verifyTotp = async () => {
        setTfa((prev) => ({ ...prev, loading: true }));
        const response = await api.post(
            `/users/${user?._id}/totp_status`,
            {
                totpEnabled: true,
                totpCode: tfa.totp,
            },
            {
                400: (err: any) => {
                    setVerificationError(err.message);
                    setTfa((prev) => ({ ...prev, loading: false }));
                },
            }
        );
        setTfa((prev) => ({ ...prev, loading: false }));
        if (response?.success) {
            setDismissableAlert(setAlert, 'TFA Configured Successfully', false, 7000);
            closeDrawer();
        }
    };

    const cancelTfaSetup = async () => {
        if (tfa.setupCompleted) {
            await api.post(`/users/${user?._id}/totp_status`, {
                totpEnabled: false,
            });
        }
        closeDrawer();
    };

    const closeDrawer = async () => {
        setTfa((prev) => ({ ...prev, loading: false }));
        await loading();
        await reloadData();
        await loaded();
        await close();
    };

    let drawerContent;
    if (user?.tfa?.totp?.enabled && !tfa.loading) {
        drawerContent = (
            <div className="tfa-setup-content">
                <h1 className="tfa-header">TFA is currently enabled for your account</h1>
                <button className="tfa-button tfa-disable-button" onClick={disableTfa}>
                    Disable TFA
                </button>
                <div className="tfa-cancel" onClick={cancelTfaSetup}>
                    <span className="tfa-cancel-text">Cancel</span>
                </div>
            </div>
        );
    } else if (!tfa.setupCompleted || tfa.loading) {
        drawerContent = (
            <div className="tfa-setup">
                <p className="tfa-loading">Loading...</p>
            </div>
        );
    } else {
        const { otpURL, recoveryCodes } = tfa;
        drawerContent = (
            <div className="tfa-setup-details">
                <h1 className="tfa-header">Two Factor Code Setup</h1>
                <div>
                    <label>Scan this QR Code with your Authenticator app:</label>
                    <QRCode value={otpURL} className="tfa-qrcode" />
                    <div className="otp-container">
                        <p className="otp-url">{otpURL}</p>
                    </div>
                </div>
                <div>
                    <label className="label">Recovery Codes:</label>
                    <div className="recovery-codes-container">
                        {recoveryCodes.slice(0, recoveryCodes.length / 2).map((code, index) => (
                            <p key={index}>{code?.code}</p>
                        ))}
                    </div>
                    <div className="recovery-codes-container">
                        {recoveryCodes.slice(recoveryCodes.length / 2).map((code, index) => (
                            <p key={index}>{code?.code}</p>
                        ))}
                    </div>
                </div>
                <div>
                    <label className="tfa-totp-label" htmlFor="totp">
                        Enter the TOTP from the app:
                    </label>
                    {verificationError && <p className="error-message">{verificationError}</p>}
                    <input id="totp" onChange={handleTotpChange} className="tfa-input" />
                </div>
                <button onClick={verifyTotp} className="tfa-button tfa-verify-button">
                    Verify and Enable
                </button>
                <div className="tfa-cancel" onClick={cancelTfaSetup}>
                    <span className="a">Cancel</span>
                </div>
            </div>
        );
    }

    return <div className="tfa-drawer">{drawerContent}</div>;
};

export default TFADrawer;

// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import inputKey from '../../utilities/inputKey';

import '../../styles/drawer.css';

interface DrawerProps {
    drawer: () => void;
    content?: React.ReactNode;
    viewerContent?: React.ReactNode;
    size?: 'sm' | 'lg';
    width?: string;
}

const Drawer: React.FC<DrawerProps> = ({ drawer, content, viewerContent, size, width }) => {
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            inputKey(e, {
                Escape: () => {
                    drawer();
                },
            });
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [drawer]);

    const renderContent = () => {
        return content ? <div className="drawer-content">{content}</div> : <></>;
    };

    const getViewerContentStyle = () => {
        let w = size === 'sm' ? '350px' : '500px';

        if (width) {
            w = width;
        }

        return {
            '--drawer-width': w,
            bottom: '0px',
            top: '80px',
        };
    };

    return (
        <>
            {viewerContent && (
                <div className="drawerViewer" style={getViewerContentStyle()}>
                    {viewerContent}
                </div>
            )}
            <div className="drawer" style={getViewerContentStyle()}>
                <Scrollbars renderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style, width: 0 }} />}>{renderContent()}</Scrollbars>
            </div>
        </>
    );
};

export default Drawer;

import { toQueryString } from './queryString';

export const addQueryToRoute = (route: string, query: Record<string, any> | string): string => {
    // Remove ? or & from the end of the route if exists
    route = route.replace(/[?&]$/, '');

    const delimiter = route.includes('?') ? '&' : '?';
    if (!query) return route;

    if (typeof query === 'string' && query.startsWith('?')) {
        // If query is a string and starts with a '?', then append the query string to the route
        return `${route}${query}`;
    } else if (typeof query === 'string' && query.includes('?')) {
        // If query is a string and already contains a '?', then append the query string to the route
        return `${route}${delimiter}${query.split('?')[1]}`;
    } else if (typeof query === 'string') {
        // If query is a string and does not contain a '?', then append the query string to the route
        return `${route}${delimiter}${query}`;
    }

    // If query is an object, then convert it to a query string and append it to the route
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const queryString = typeof query === 'string' ? query : toQueryString(query);
    return `${route}${delimiter}${toQueryString(query)}`;
};

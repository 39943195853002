import React, { useEffect, useState } from 'react';
import api2 from '../api2';
import { setDismissableAlert } from '../utilities/alert/setDismissableAlert';

type ExternalIds = Record<string, string>;

interface EditExternalIdsProps {
    userId: string;
    loading: (leftIndent: number, key: string) => void;
    loaded: (key: string) => void;
    setAlert: (message: string, isError: boolean, duration?: number) => void;
    saveExternalIds: (externalIds: ExternalIds) => void;
    externalIdsObject: ExternalIds;
}

interface ExtegrationConnection {
    _id: string;
    name: string;
}

const EditExternalIds: React.FC<EditExternalIdsProps> = ({ userId, loading, loaded, setAlert, saveExternalIds, externalIdsObject }) => {
    const [externalIds, setExternalIds] = useState<Record<string, string>>({});
    const [advisoryGroups, setAdvisoryGroups] = useState<any[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        setExternalIds(externalIdsObject);
    }, [externalIdsObject]);

    useEffect(() => {
        if (isOpen) {
            loadAdvisoryGroups();
        }
    }, [isOpen]);

    const loadAdvisoryGroups = async () => {
        loading(0, 'loadAdvisoryGroupsForExternalIds');
        try {
            const response = await api2.client.UserApi.getUser({ user_id: userId, populate_advisory_groups_extegrations: true });
            setAdvisoryGroups(response.data.user?.advisors?.map((a) => a.group) || []);
        } catch (error) {
            console.error('Error loading advisory groups:', error);
            setDismissableAlert(setAlert, 'Error loading advisory groups', true);
        }
        loaded('loadAdvisoryGroupsForExternalIds');
    };

    return (
        <div>
            {isOpen ? (
                <>
                    {/* For each advisory group, then for each of their extegrations, add an input box to set the value for custom id of that particular group. '' means clear */}
                    {advisoryGroups.map((group) => (
                        <div key={group._id}>
                            <h3>{group.name}</h3>
                            {group.extegrations.map((ec: ExtegrationConnection) => (
                                <div key={ec._id}>
                                    <label>{ec.name}</label>
                                    <input
                                        type="text"
                                        value={externalIds[ec._id] || ''}
                                        onChange={(e) => {
                                            const newExternalIds = { ...externalIds };
                                            if (e.target.value === '') {
                                                delete newExternalIds[ec._id];
                                            } else {
                                                newExternalIds[ec._id] = e.target.value;
                                            }
                                            setExternalIds(newExternalIds);
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    ))}
                    {/* For any ids in externalIds that don't have an advisory group/extegration pair */}
                    {Object.keys(externalIds)
                        .filter((id) => !advisoryGroups.some((group) => group.extegrations.some((ec: ExtegrationConnection) => ec._id === id)))
                        .map((id) => (
                            <div key={id}>
                                <label>{`${id} (removed)`}</label>
                                <input
                                    type="text"
                                    value={externalIds[id] || ''}
                                    onChange={(e) => {
                                        const newExternalIds = { ...externalIds };
                                        if (e.target.value === '') {
                                            delete newExternalIds[id];
                                        } else {
                                            newExternalIds[id] = e.target.value;
                                        }
                                        setExternalIds(newExternalIds);
                                    }}
                                />
                            </div>
                        ))}
                    <button
                        style={{ cursor: 'pointer', textAlign: 'center' }}
                        onClick={async () => {
                            await saveExternalIds(externalIds);
                        }}
                    >
                        Save
                    </button>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <span style={{ color: 'var(--dark-gray)', cursor: 'pointer', textDecoration: 'underline', marginLeft: '10px' }} onClick={() => setIsOpen(false)}>
                            Close External ID Editor
                        </span>
                    </div>
                </>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span style={{ color: 'var(--dark-gray)', cursor: 'pointer', textDecoration: 'underline', textAlign: 'center' }} onClick={() => setIsOpen(true)}>
                        Edit External IDs
                    </span>
                </div>
            )}
        </div>
    );
};

export default EditExternalIds;

import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import renderInputError from '../renderInputError';
import api from '../../api';

import '../../styles/addEditUnrealizedValueDrawer.css';
import { validEmail } from '../../utilities/validation/validators';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import Dropdown from '../Dropdowns/Dropdown';

const ADVISOR_TYPES = [
    {
        label: 'Full Access Advisor',
        value: 'full',
    },
    {
        label: 'CPA (Tax Only)',
        value: 'tax',
    },
];

interface InviteAdvisorDrawerProps extends RouteComponentProps {
    advisorType?: string;
    user?: { _id: string };
    loading: (duration: number) => void;
    reloadData?: () => Promise<void>;
    closeDrawer: () => void;
    loaded: () => void;
    setAlert: (message: React.ReactNode) => void;
}

const InviteAdvisorDrawer: React.FC<InviteAdvisorDrawerProps> = ({ advisorType, user, loading, reloadData, closeDrawer, loaded, setAlert }) => {
    const [email, setEmail] = useState('');
    const [advisorTypeList, setAdvisorTypeList] = useState(ADVISOR_TYPES);
    const [advisorTypeState, setAdvisorTypeState] = useState(ADVISOR_TYPES[0]);
    const [errors, setErrors] = useState<{ advisorEmail?: string }>({});

    useEffect(() => {
        setAdvisorType();
    }, [advisorType]);

    const setAdvisorType = () => {
        if (!advisorType) return;
        const foundAdvisorType = ADVISOR_TYPES.find((type) => type.value === advisorType);
        if (foundAdvisorType) {
            setAdvisorTypeState(foundAdvisorType);
            setAdvisorTypeList([foundAdvisorType]);
            // setAdvisorTypeForced(true);
        }
    };

    const inviteAdvisor = async (email: string) => {
        if (email === '') {
            setErrors({ advisorEmail: 'This field is required' });
        } else if (!validEmail(email)) {
            setErrors({ advisorEmail: 'Please enter a valid email.' });
        } else {
            loading(320);
            const data = {
                receiver: email,
                plan_name: advisorTypeState.value,
                senderId: user?._id,
            };
            const res = await api.post('/invitations/', data, {
                400: (err: any) => console.error(err.message),
            });
            if (res) {
                if (reloadData) await reloadData();
                closeDrawer();
                loaded();
                setDismissableAlert(
                    setAlert,
                    <>
                        A notification email has been sent to <strong style={{ color: '#fff' }}>{email}</strong>.
                    </>
                );
            } else {
                loaded();
                setDismissableAlert(setAlert, 'There was an error inviting the advisor.', true);
            }
        }
    };

    return (
        <div className="profile-drawer">
            <h1>Invite Investment Advisor</h1>
            <div>
                <label htmlFor="email-address">Email Address</label>
                <input id="email-address" type="text" placeholder="Email Address" autoComplete="off" onChange={(e) => setEmail(e.target.value)} style={{ height: '30px' }} />
                {renderInputError(errors, 'advisorEmail')}
            </div>

            <div style={{ clear: 'both', height: '20px' }} />

            <Dropdown
                disableDefaultOption={true}
                idPrefix="rowsPerPage"
                options={advisorTypeList}
                optionField="label"
                select={(value) => setAdvisorTypeState(value)}
                selection={advisorTypeState}
                style={{ height: '37px' }}
                textAlign="left"
            />
            <div style={{ clear: 'both', height: '20px' }} />

            <button onClick={() => inviteAdvisor(email)}>Invite</button>

            <div style={{ marginTop: '20px' }} onClick={closeDrawer}>
                <span className="a" style={{ width: '100px' }}>
                    Cancel
                </span>
            </div>
        </div>
    );
};

export default withRouter(InviteAdvisorDrawer);

import { saveAs } from 'file-saver';
import api from '../../api';

import JSZip from 'jszip';
import api2 from '../../api2';
import { formatUTCDate } from '../format/formatDate';

const getUserRalInfoZip = async (connectionIds = []) => {
    if (!connectionIds.length) return false;

    // get user info for each connection
    const route = `/connections/outreach_data?connectionIds=${connectionIds.join(',')}`;
    const connections = await api.get(route);
    if (!connections) return false;

    // create zip
    const zip = new JSZip();

    const headers = ['Client Name', 'Client Email', 'Ownership Type', 'Ownership Names', 'Unique Email', 'Unique Phone Number'];

    // 2 CSVs with user info (include creds if 2fa)
    let csvSignedRequests = [...headers, 'Signed Date', 'RAL Filename'].join(',') + '\n';
    let csvSentRequests = [...headers, 'Sent Date'].join(',') + '\n';
    let csvUnsentRequests = [...headers].join(',') + '\n';

    const ralFolder = zip.folder('RALs');

    // connections.forEach((connection) => {
    //     connection.ral_requests?.forEach((ral_request) => {
    //         let commonRowData = [
    //             ral_request.user?.name || '',
    //             ral_request.user?.email || '',
    //             ral_request.ownership_type || 'User',
    //             ral_request.ownership_ids?.map((obj) => obj.name).join(' / ') || '',
    //             connection.tfa_email || '',
    //             connection.tfa_phone || '',
    //         ];

    //         if (ral_request.signed_at) {
    //             commonRowData.push(formatUTCDate(ral_request.signed_at));
    //             if (ral_request.signed_ral_download_url) {
    //                 const pdfBlob = api2.getBlob(ral_request.signed_ral_download_url);
    //                 const filename = `${ral_request.user?.name || ''}__${ral_request.ownership_type || 'User'}__${ral_request.ownership_ids?.[0]?.name}.pdf`;
    //                 ralFolder.file(filename, pdfBlob);
    //                 commonRowData.push(filename);
    //             } else {
    //                 commonRowData.push('Not available');
    //             }
    //             csvSignedRequests += commonRowData.join(',') + '\n';
    //         } else if (ral_request.sent_at) {
    //             commonRowData.push(formatUTCDate(ral_request.sent_at));
    //             csvSentRequests += commonRowData.join(',') + '\n';
    //         } else {
    //             csvUnsentRequests += commonRowData.join(',') + '\n';
    //         }
    //     });
    // });

    for (const connection of connections) {
        if (!connection.ral_requests) continue;
        for (const ral_request of connection.ral_requests) {
            let commonRowData = [
                ral_request.user?.name || '',
                ral_request.user?.email || '',
                ral_request.ownership_type || 'User',
                ral_request.ownership_ids?.map((obj) => obj.name).join(' / ') || '',
                connection.tfa_email || '',
                connection.tfa_phone || '',
            ];

            if (ral_request.signed_at) {
                commonRowData.push(formatUTCDate(ral_request.signed_at));
                if (ral_request.signed_ral_download_url) {
                    const pdfBlobRes = await api2.getBlob(ral_request.signed_ral_download_url);
                    const pdfBlob = pdfBlobRes.data;
                    const filename = `${(ral_request.user?.name || '').replace(/\//g, '')}__${(ral_request.ownership_type || 'User').replace(/\//g, '')}__${(ral_request.ownership_ids?.[0]?.name || '').replace(/\//g, '')}.pdf`;
                    ralFolder.file(filename, pdfBlob);
                    commonRowData.push(filename);
                } else {
                    commonRowData.push('Not available');
                }
                csvSignedRequests += commonRowData.join(',') + '\n';
            } else if (ral_request.sent_at) {
                commonRowData.push(formatUTCDate(ral_request.sent_at));
                csvSentRequests += commonRowData.join(',') + '\n';
            } else {
                csvUnsentRequests += commonRowData.join(',') + '\n';
            }
        }
    }

    // add csvs to zip
    zip.file(`Signed Requests.csv`, csvSignedRequests);
    zip.file(`Sent Requests.csv`, csvSentRequests);
    zip.file(`Unsent Requests.csv`, csvUnsentRequests);

    // generate zip
    const content = await zip.generateAsync({ type: `blob` });

    // download zip
    saveAs(content, `outreach_export_${new Date().getTime()}.zip`);

    // all ral files
    return !!connections;
};

export default getUserRalInfoZip;

import _ from 'lodash';
import { Component } from 'react';

// components
import Drawer from '../../components/Drawers/Drawer';
import EditInvestmentDrawerContent from '../../components/Drawers/EditInvestmentDrawerContent';
import PaginationTable from '../../components/Tables/PaginationTable';
import Multitoggle from '../../components/Toggles/Multitoggle';

// utilities
import api from '../../api';
import api2 from '../../api2';
import { getGlobalInvestmentFilterQuery } from '../../common/getSetGlobalInvestmentFilter';
import Search from '../../components/Inputs/Search';
import { VUES } from '../../constants/constantStrings';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import { toQueryString } from '../../utilities/apiHelpers/queryString';
import { getFieldsByType, getInvestmentSubtypes } from './AddManualInvestmentStages/investmentTypeConstants';

import { InvestmentTypes } from '../../openApiClient';
import '../../styles/manageConnections.css';
import '../../styles/table.css';
import { clearQueryParams, setQueryParam } from '../../utilities/query/queryParamHelpers';

const isMobile = window.innerWidth <= 768;

// const INVESTMENT_FILTER_OPTIONS = {
//     all: 'All',
//     active: 'Active',
//     hidden: 'Hidden',
// }

const BILLING_FILTER_OPTIONS = {
    all: 'All',
    exclude: 'Exclude',
    include: 'Include',
};

export default class ManageInvestments extends Component {
    //TODO: make this "all entites" friendly when advisors view shows up.

    childCallables = null;

    state = {
        investments: [],
        drawerContent: undefined,
        backupDrawerContent: undefined,
        drawerKind: null,
        editingInvestment: null,
        filterString: '',
        excludeBillingFilter: BILLING_FILTER_OPTIONS.all,
    };

    componentDidMount = () => {
        this.loadData(null);
    };

    componentDidUpdate = async (prevProps) => {
        if (!_.isEqual(this.props.account, prevProps.account)) {
            this.loadData();
        }
    };

    componentWillUnmount = () => {
        clearQueryParams('tab');
    };

    clearEditingInvestment = (callback) => {
        this.setState(this.getClearedInvestmentObject(), callback);
    };

    setChildCallables = (callables) => {
        this.childCallables = callables;
    };

    getUserId = () => this.props.relevantUser?._id;

    getUserQueryStr = () => (this.props.advisorVue ? `&userId=${this.getUserId()}` : '');

    getAccountQueryStr = () => (this.props.account ? `&account=${this.props.account._id}` : '');

    getBillingQueryStr = () => {
        if (this.state.excludeBillingFilter === BILLING_FILTER_OPTIONS.include) return { exclude_from_billing: false };
        if (this.state.excludeBillingFilter === BILLING_FILTER_OPTIONS.exclude) return { exclude_from_billing: true };
        return {};
    };

    loadData = async (callback, useLoading = true) => {
        const p = this.props;
        const account = p.account;

        if (useLoading) await p.loading(320, 'manageInvestments_loadData');
        if (!p.advisorVue) {
            p.setTitle(
                <>
                    <span className="title-account-name">{account?.name ?? 'All Entities'}</span>
                    <div className="separator" />
                    Manage Investments
                </>
            );
        }

        // load investments
        let investments = [];
        try {
            investments = await api2.paginateApiRoute(async (paginate_params) => {
                return (
                    await api2.client.InvestmentApi.listInvestments({
                        users: p.advisorVue ? [this.getUserId()] : undefined,
                        accounts: p.account ? [p.account._id] : undefined,
                        ...getGlobalInvestmentFilterQuery(p.vue),
                        ...this.getBillingQueryStr(),
                        ...paginate_params,
                    })
                ).data.investments;
            });
            console.log('mng inv investments', investments);
        } catch (e) {
            console.error('Error loading investments', e);
        }

        // load accounts
        let accounts = [];
        try {
            accounts = await api2.client.AccountApi.listAccounts({
                users: p.advisorVue ? [this.getUserId()] : undefined,
            }).data.accounts;
            console.log('mng inv accounts', accounts);
        } catch (e) {
            console.error('Error loading accounts', e);
        }

        const connections = (await api.get(`/connections?accounts=${p.account?._id ? `${p.account?._id}` : ''}${this.getUserQueryStr()}`))?.results ?? [];

        const taxDocumentTypes = await api.get('/investments/taxDocumentTypes');
        this.setState({ connections: connections?.results });

        const stateCallback = () => {
            p.loaded('manageInvestments_loadData');
            callback?.();
        };

        await this.loadQueryParams();

        this.setState(
            {
                investments,
                accounts,
                types: Object.values(InvestmentTypes),
                taxDocumentTypes,
                connections,
            },
            stateCallback
        );
    };

    loadQueryParams = async () => {
        const q = new URLSearchParams(window.location.search);

        const filterString = q.get('investment_search');
        this.setState({ filterString });

        const excludeBillingFilter = q.get('exclude_from_billing');
        if (excludeBillingFilter) {
            this.setState({ excludeBillingFilter });
        }
    };

    loadContacts = async (callback) => {
        const contacts = await api.get(`/userContacts${this.getUserQueryStr().replace('&', '?')}`);
        this.setState({ contacts }, callback);
    };

    submitInvestmentEdit = async (investment, update) => {
        const investmentId = update?._id;
        const updateSubtype = update?.investment_data?.type;
        if (investmentId) {
            update._id = investmentId;
            update.allowBaseDataUpdates = true;
            // keep fields that are shared between the old and new investment type
            if (investment.type !== update.type) {
                let investment_data = investment.investment_data;
                if (investment_data) {
                    // get investment_data fields for investment type
                    const fields = getFieldsByType(update.type, null, true);
                    Object.keys(investment_data).forEach((key) => {
                        if (!fields[key]) {
                            delete investment_data[key];
                        }
                    });
                    delete investment_data.type;
                    const subtypes = getInvestmentSubtypes(investment.type);
                    if (subtypes?.length) {
                        investment_data.type = subtypes.find((s) => s === updateSubtype) ?? subtypes[0];
                    }
                    update.investment_data = investment_data;
                } else {
                    update.investment_data = {};
                    if (updateSubtype) {
                        update.investment_data.type = updateSubtype;
                    }
                }
            }
            this.props.loading(0, 'manageInvestments_submitInvestmentEdit');

            try {
                await api2.client.InvestmentApi.updateInvestment({
                    investment_id: investmentId,
                    UpdateInvestmentRequest: update,
                });
                this.childCallables.loadData();
                await this.loadData(this.closeDrawer);
                setDismissableAlert(this.props.setAlert, 'Investment updated.', false, 7000);
            } catch (e) {
                console.error('Error updating investment', e);
                setDismissableAlert(this.props.setAlert, 'Unable to update investment.', true, 7000);
            }
            this.props.loaded('manageInvestments_submitInvestmentEdit');
        }
    };

    getClearedInvestmentObject = () => {
        return {
            editingInvestment: null,
        };
    };

    editInvestment = (investment) => {
        this.setState({
            editingInvestment: investment,
            drawerKind: 'investment',
        });
    };

    cancelAddContact = () => {
        this.setState(() => {
            return {
                drawerKind: null,
                drawerOpen: false,
            };
        });
    };

    addContact = (kind, investment = undefined) => {
        this.setState(() => {
            return {
                editingInvestment: investment,
                drawerKind: kind,
            };
        });
    };

    closeDrawer = () => {
        this.setState({
            drawerKind: null,
            ...this.getClearedInvestmentObject(),
        });
    };

    getColumns = () => {
        const p = this.props;
        const res = [
            {
                title: 'Investment name',
                field: 'name',
                sort: {
                    field: 'name',
                },
                render: (col, row, items, i) => {
                    const inv = items[i];
                    let investmentPath = p.advisorVue
                        ? p.user.access === 'admin'
                            ? `/admin/users/${inv.user._id}/details/${inv._id}`
                            : `/advisor/clients/${inv.user._id}/details/${inv._id}`
                        : `/accounts/${inv.account?._id}/investments/${inv._id}/details`;
                    const logoUrl = inv?.connection?.integration?.logo_url;
                    return (
                        <td key={'name' + i} title={inv.name}>
                            <div className="mngConn_manage-investments-title">
                                <div className="mngConn_investment-entity">{inv.account?.name}</div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {logoUrl && <img src={logoUrl} alt="Integration Logo" style={{ marginRight: '5px', height: '40px' }} />}
                                    <a className="mngConn_investment-name a" href={investmentPath}>
                                        {inv.name}
                                    </a>
                                </div>
                            </div>
                        </td>
                    );
                },
            },
            {
                title: 'Type',
                field: 'type',
                sort: {
                    field: 'type',
                },
            },
            {
                title: 'Manager',
                render: (col, row, items, i) => {
                    const inv = items[i];
                    return (
                        <td key={'Manager' + i}>
                            <div style={{ paddingLeft: '15px', width: '30px', display: 'flex', justifyContent: 'center' }}>
                                {inv.investment_master?.asset_manager ? (
                                    <img src={'/images/icons/static-checkbox.svg'} alt="checkbox" title={inv.investment_master?.asset_manager?.name} />
                                ) : null}
                            </div>
                        </td>
                    );
                },
            },
        ];

        res.push({
            title: 'Active',
            field: 'inactive',
            sort: {
                field: 'inactive',
            },
            render: (col, row, items, i) => {
                const inv = items[i];
                const key = 'active' + i;
                return (
                    <td key={key}>
                        <div style={{ paddingLeft: '10px' }}>{inv.inactive ? 'No' : 'Yes'}</div>
                    </td>
                );
            },
        });

        if (p.vue === VUES.ADMIN) {
            res.push({
                title: 'Hidden',
                field: 'hidden',
                sort: {
                    field: 'hidden',
                },
                render: (col, row, items, i) => {
                    const inv = items[i];
                    const key = 'hidden' + i;
                    return (
                        <td key={key}>
                            <div style={{ paddingLeft: '10px' }}>{inv.hidden ? 'Yes' : 'No'}</div>
                        </td>
                    );
                },
            });
        }

        if (p.vue === VUES.ADMIN) {
            res.push(
                ...[
                    {
                        title: 'Billing',
                        render: (col, row, items, i, c, parent, onUpdateItem) => {
                            const inv = items[i];
                            return (
                                <td key={'inBilling' + i} style={{ width: '110px' }}>
                                    {this.renderExcludeFromBillingToggle(inv, onUpdateItem)}
                                </td>
                            );
                        },
                    },
                ]
            );
        }

        res.push(
            ...[
                {
                    title: 'Docs Expected?',
                    render: (col, row, items, i, c, parent, onUpdateItem) => {
                        const inv = items[i];
                        return (
                            <td key={'docsExpected' + i} style={{ width: '110px' }}>
                                {this.renderTaxDocExpectedToggle(inv, onUpdateItem)}
                            </td>
                        );
                    },
                },
                {
                    title: 'Edit',
                    render: (col, row, items, i) => {
                        const inv = items[i];
                        return (
                            <td key={'edit' + i} onClick={() => this.editInvestment(inv)}>
                                <div style={{ width: '30px', margin: 0 }} className="edit-icon-row">
                                    <img src={'/images/icons/pencil.svg'} alt="pencil" />
                                </div>
                            </td>
                        );
                    },
                },
            ]
        );
        return res;
    };

    renderDrawer = (state = this.state) => {
        let drawerContent = <></>;
        if (state.drawerKind === 'investment') {
            drawerContent = (
                <EditInvestmentDrawerContent
                    relevantUser={this.props.relevantUser}
                    title="Edit Investment"
                    investmentId={state.editingInvestment?._id}
                    connections={state.connections}
                    types={state.types}
                    accounts={this.props.accounts}
                    account={this.props.accounts.find((a) => String(a._id) === String(state.editingInvestment?.account))}
                    taxDocumentTypes={state.taxDocumentTypes}
                    submitEnabled={true}
                    onSubmit={this.submitInvestmentEdit}
                    onCancel={this.closeDrawer}
                    closeDrawer={this.closeDrawer}
                    idPrefix={'editInvestment_' + state.editingInvestment._id}
                    loading={this.props.loading}
                    loaded={this.props.loaded}
                    reloadData={this.childCallables.loadData}
                    setAlert={this.props.setAlert}
                />
            );
        }
        return <Drawer size="lg" content={drawerContent} drawer={this.closeDrawer} />;
    };

    filteredInvestments = () => {
        const s = this.state;
        let ret = s.investments.filter((i) => {
            for (let field of ['original_name', 'name']) {
                if (i?.[field]?.toLowerCase?.()?.includes?.(s.filterString ?? '')) {
                    return true;
                }
            }
            return false;
        });
        return ret ?? [];
    };

    renderMobileInvestment = (investment, investmentIdx) => {
        const account = this.state.accounts.find((a) => a._id === investment.account);

        const onUpdateItem = (newItem) => {
            const investments = this.state.investments;
            const idx = investments.findIndex((x) => x._id === newItem._id);
            investments[idx] = newItem;
            this.setState({ investments });
        };

        return (
            <div className="altx-mobile-table-row" style={{ position: 'relative', padding: '15px', paddingTop: '10px' }} key={'mngInv_mobile_tableRow_' + investmentIdx}>
                <div className="altx-mobile-table-cell">
                    <label className="altx-mobile-table-header-label" style={{ display: 'flex' }}>
                        <div className="mobile-width-constraint" style={{ fontWeight: 600, fontSize: '12px', color: 'var(--color-light-gray)' }}>
                            {account?.name?.toUpperCase()}
                        </div>
                        <div className="pagTable_centerDiv mngConn_link" style={{ position: 'absolute', right: '20px' }} onClick={() => this.editInvestment(investment)}>
                            <img src="/images/icons/edit.png" alt="Edit Credentials" title="Edit Credentials" style={{ marginRight: 10 }} />
                        </div>
                    </label>
                </div>

                <div className="mobile-width-constraint" style={{ fontSize: '16px', fontWeight: 600 }}>
                    {investment.name}
                </div>

                <div className="altx-mobile-table-cell" style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
                    <label>Type</label>
                    <div style={{ fontSize: '14px' }}>{investment.type}</div>
                </div>

                <div style={{ position: 'absolute', right: '30px', bottom: '25px' }}>{this.renderTaxDocExpectedToggle(investment, onUpdateItem)}</div>
            </div>
        );
    };

    renderTaxDocExpectedToggle = (investment, onUpdateItem) => {
        const enabled = investment.expected_tax_documents;
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Multitoggle
                    keyStr={'TaxDocExpectedToggle'}
                    options={['Yes', 'No']}
                    selection={enabled ? 'Yes' : 'No'}
                    zIndex={2000}
                    onSelect={
                        !UserPermissions().canUpdateAnyInvestments
                            ? null
                            : async () => {
                                  const newEnabledValue = enabled ? 0 : 1;
                                  try {
                                      await api2.client.InvestmentApi.updateInvestment({
                                          investment_id: investment._id,
                                          UpdateInvestmentRequest: { expected_tax_documents: newEnabledValue },
                                      });
                                      onUpdateItem({ ...investment, expected_tax_documents: newEnabledValue });
                                  } catch (e) {
                                      console.error('Error updating investment', e);
                                      setDismissableAlert(this.props.setAlert, 'Error changing expected value.', true);
                                  }
                              }
                    }
                    containerStyle={{ width: '70px', height: '24px' }}
                    optionStyle={{ cursor: UserPermissions().canUpdateAnyInvestments ? null : 'default' }}
                />
            </div>
        );
    };

    renderExcludeFromBillingToggle = (investment, onUpdateItem) => {
        const p = this.props;
        const disabled = p.vue !== VUES.ADMIN;
        const inBilling = investment.exclude_from_billing;
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Multitoggle
                    keyStr={'exclude_from_billing_Toggle'}
                    options={[BILLING_FILTER_OPTIONS.exclude, BILLING_FILTER_OPTIONS.include]}
                    selection={inBilling ? BILLING_FILTER_OPTIONS.exclude : BILLING_FILTER_OPTIONS.include}
                    zIndex={2000}
                    onSelect={async () => {
                        if (disabled) return;
                        const newExcludeValue = !inBilling;
                        try {
                            await api2.client.InvestmentApi.updateInvestment({
                                investment_id: investment._id,
                                UpdateInvestmentRequest: { exclude_from_billing: newExcludeValue },
                            });
                            onUpdateItem({ ...investment, exclude_from_billing: newExcludeValue });
                        } catch (e) {
                            console.error('Error updating investment', e);
                            setDismissableAlert(this.props.setAlert, 'Error changing expected value.', true);
                        }
                    }}
                    containerStyle={{ width: '130px', height: '24px' }}
                    disabled={disabled}
                />
            </div>
        );
    };

    getSelectFilters = () => {
        const s = this.state;

        const filters = [];

        filters.push(
            ...[
                {
                    label: 'Excluded From Billing',
                    render: () => {
                        let toggle_options_map = {
                            [BILLING_FILTER_OPTIONS.all]: 'All',
                            [BILLING_FILTER_OPTIONS.exclude]: 'Yes',
                            [BILLING_FILTER_OPTIONS.include]: 'No',
                        };
                        const toggle_options_map_inverted = _.invert(toggle_options_map);
                        let toggle_options = Object.values(toggle_options_map);
                        const selection = toggle_options_map[s.excludeBillingFilter];
                        return (
                            <Multitoggle
                                containerStyle={{ marginBottom: '0px', height: '35px', width: toggle_options.length * 70 }}
                                options={toggle_options}
                                selection={selection}
                                onSelect={(newValue) => {
                                    const excludeBillingFilter = toggle_options_map_inverted[newValue];
                                    this.setState(
                                        {
                                            excludeBillingFilter,
                                        },
                                        () => this.loadData()
                                    );
                                    setQueryParam('exclude_from_billing', excludeBillingFilter === BILLING_FILTER_OPTIONS.all ? undefined : excludeBillingFilter);
                                }}
                            />
                        );
                    },
                },
                {
                    label: 'Search Investments',
                    render: () => (
                        <Search
                            value={this.state.filterString}
                            callback={(val) => {
                                this.setState({ filterString: val });
                                setQueryParam('investment_search', val || undefined);
                            }}
                            placeholder="Investment Name"
                            style={{ width: '300px' }}
                        />
                    ),
                },
            ]
        );

        return filters;
    };

    getRoute = () => {
        const s = this.state;
        const p = this.props;

        let queryObj = {
            populate_investment_master: true,
            populate_account: true,
            populate_user: true,
            populate_connection: true,
            populate_integration_logo: true,
            ...(p.advisorVue ? { users: this.getUserId() } : {}),
            ...(s.filterString && { search: s.filterString }),

            // exclude from billing filter
            ...(s.excludeBillingFilter !== BILLING_FILTER_OPTIONS.all && { exclude_from_billing: s.excludeBillingFilter === BILLING_FILTER_OPTIONS.exclude }),

            ...(p.account?._id && { accounts: [p.account._id] }), // global account
            ...getGlobalInvestmentFilterQuery(p.vue),
        };

        if (p.vue === VUES.ADVISOR) {
            // Additional advisor-specific query parameters can be added here
        }

        let route = `/investments?${toQueryString(queryObj)}`;

        return route;
    };

    render = () => {
        const s = this.state; // Shorthand
        const p = this.props; // Shorthand

        if (!UserPermissions().canReadAnyInvestments) return null;

        if (isMobile) {
            return (
                <div style={{ marginLeft: '0px' }}>
                    <div className="mngInv_filter">
                        <span style={{ fontSize: '14px', bottom: '5px', position: 'relative' }}>Search Investments</span>
                        <Search callback={(val) => this.setState({ filterString: val })} placeholder="Investment Name" />
                    </div>
                    <div className="mngInv_mobileHeader">Investment</div>
                    <div className="altx-mobile-table">{this.filteredInvestments()?.map?.(this.renderMobileInvestment)}</div>
                    {s.drawerKind && this.renderDrawer()}
                </div>
            );
        }

        let toggle_options = ['All', 'Active'];
        if (UserPermissions().access.admin) {
            toggle_options.push('Hidden');
        }

        return (
            <>
                <div
                    className="mngInv_filter1"
                    style={{
                        marginTop: isMobile ? null : '-115px',
                    }}
                >
                    <PaginationTable
                        selectFilters={this.getSelectFilters()}
                        noInvestmentDropdown={true}
                        noDateFilters={true}
                        containerStyle={{ margin: '0px' }}
                        initialSortField="date"
                        initialSortAscending={false}
                        loading={p.loading}
                        loaded={p.loaded}
                        noTypeDropdown
                        titleStyle={{ margin: 0 }}
                        headerStyle={{
                            alignItems: 'flex-end',
                        }}
                        route={this.getRoute()}
                        useApi2={true}
                        getResultsFromResponse={(res) => res.investments}
                        // noHeader={true}
                        title={null}
                        columns={this.getColumns()}
                        setCallables={this.setChildCallables}
                        rowInfo={{
                            rowClick: async () => {},
                        }}
                        postProcessData={(data) => {
                            console.log('postProcessData', data);
                            return data;
                        }}
                    />
                    {s.drawerKind && this.renderDrawer()}
                    <div style={{ clear: 'both', height: '200px' }} />
                </div>
            </>
        );
    };
}

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import validator from 'validator';
// import _ from 'lodash';

// components
import GenericDrawer from './GenericDrawer';
import AuditLogDisplay from '../AuditLogDisplay';

// utilities
import api2 from '../../api2';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import { isEIN, isSSN, isTIN } from '../../utilities/validation/validators';

// constants
import { STATES } from '../../constants/constantStrings';

// types
import { Account } from '../../types/Account';
import { CreateAccountRequestAccountTypeEnum, CreateAccountRequestAddressStateEnum, CreateAccountRequestIdTypeEnum } from '../../openApiClient';

// styles
import '../../styles/addConnectionDrawer.css';

export const ID_TYPES = [
    { name: 'Employer Identification Number (EIN)', value: 'EIN' },
    { name: 'Social Security Number (SSN)', value: 'SSN' },
    { name: 'Tax Identification Number (TIN)', value: 'TIN' },
] as const;

export const ACCOUNT_TYPES = [
    { name: 'Individual', value: 'individual' },
    { name: 'Joint', value: 'joint' },
    { name: 'Trust', value: 'trust' },
    { name: 'Individual Retirement Account (IRA)', value: 'ira' },
    { name: 'Limited Liability Company (LLC)', value: 'llc' },
    { name: 'Partnership', value: 'partnership' },
    { name: 'Corporation', value: 'corporation' },
    { name: 'Other', value: 'other' },
] as const;

interface Props extends RouteComponentProps {
    accountId?: string;
    userId?: string;
    account?: Account;
    close: () => void;
    loading: (duration?: number, key?: string) => void;
    loaded: (key?: string) => void;
    setAlert: (message: string, isError?: boolean) => void;
    reloadData: () => Promise<void>;
    vue?: string;
}

interface FormData {
    name: string;
    account_type?: { name: string; value: string };
    id_type?: { name: string; value: string };
    id_number?: string;
    streetLine1?: string;
    streetLine2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    country?: string;
}

interface FormErrors {
    name?: string;
    id_number?: string;
    zipCode?: string;
    submit?: string;
    [key: string]: string | undefined;
}

const AddEditAccountDrawer: React.FC<Props> = ({ accountId, userId, account: propAccount, close, loading, loaded, setAlert, reloadData, vue }) => {
    // State management
    const [account, setAccount] = useState<Account | null>(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [commonData, setCommonData] = useState<FormData>({
        name: '',
    });

    // Refs
    const isLoadingRef = useRef(false);
    const mounted = useRef(true);

    // Cleanup effect
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    // Load account data effect
    useEffect(() => {
        if (propAccount) {
            setAccount(propAccount);
            const initialData = {
                name: propAccount.name,
                account_type: ACCOUNT_TYPES.find((a) => a.value === propAccount.account_type),
                id_type: ID_TYPES.find((a) => a.value === propAccount.id_type),
                id_number: propAccount.id_number,
                streetLine1: propAccount.address?.streetLine1,
                streetLine2: propAccount.address?.streetLine2,
                city: propAccount.address?.city,
                state: propAccount.address?.state,
                zipCode: propAccount.address?.zipCode,
                country: propAccount.address?.country,
            };
            setCommonData(initialData);

            // Clear errors if initial data is valid
            const validationErrors = checkCommonData(initialData);
            if (Object.keys(validationErrors).length === 0) {
                setErrors({});
            } else {
                setErrors({
                    ...validationErrors,
                    submit: 'Please fill in all required fields correctly',
                });
            }
        } else if (accountId) {
            loadData();
        }
    }, [accountId, propAccount]);

    const loadData = async () => {
        if (isLoadingRef.current || !accountId) {
            return;
        }

        try {
            isLoadingRef.current = true;
            loading?.(0, 'accountDrawer');

            const response = await api2.client.AccountApi.getAccount({
                account_id: accountId,
            });

            const loadedAccount = response.data.account;
            if (!loadedAccount || !mounted.current) return;

            setAccount(loadedAccount as Account);
            const initialData = {
                name: loadedAccount.name ?? '',
                account_type: ACCOUNT_TYPES.find((a) => a.value === loadedAccount.account_type),
                id_type: ID_TYPES.find((a) => a.value === loadedAccount.id_type),
                id_number: loadedAccount.id_number,
                streetLine1: loadedAccount.address?.streetLine1,
                streetLine2: loadedAccount.address?.streetLine2,
                city: loadedAccount.address?.city,
                state: loadedAccount.address?.state,
                zipCode: loadedAccount.address?.zipCode,
                country: loadedAccount.address?.country,
            };
            setCommonData(initialData);

            // Initialize errors based on validation
            const validationErrors = checkCommonData(initialData);
            setErrors((prev) => ({
                ...prev,
                ...validationErrors,
                submit: Object.keys(validationErrors).length > 0 ? 'Please fill in all required fields correctly' : '',
            }));
        } catch (error) {
            console.error('Error loading account:', error);
            if (mounted.current) {
                setDismissableAlert(setAlert, 'Failed to load account details', true);
            }
        } finally {
            if (mounted.current) {
                isLoadingRef.current = false;
                loaded?.('accountDrawer');
            }
        }
    };

    const checkCommonData = (data: FormData): FormErrors => {
        const errors: FormErrors = {};

        // Required field validation - only set error if field is empty or whitespace
        if (!data.name?.trim()) {
            errors.name = 'Name is required';
        }

        // ID validation - only validate if ID type is selected
        if (data.id_type?.value) {
            if (!data.id_number) {
                errors.id_number = 'ID Number is required since ID Type is set';
            } else {
                switch (data.id_type.value) {
                    case 'SSN':
                        if (!isSSN(data.id_number)) {
                            errors.id_number = 'Invalid SSN';
                        }
                        break;
                    case 'EIN':
                        if (!isEIN(data.id_number)) {
                            errors.id_number = 'Invalid EIN';
                        }
                        break;
                    case 'TIN':
                        if (!isTIN(data.id_number)) {
                            errors.id_number = 'Invalid TIN';
                        }
                        break;
                }
            }
        }

        // Zip code validation - only validate if provided
        if (data.zipCode) {
            if (!validator.isPostalCode(String(data.zipCode), 'US') && !validator.isPostalCode(String(data.zipCode), 'CA')) {
                errors.zipCode = 'Invalid Zip Code';
            }
        }

        return errors;
    };

    const isSafeToSave = useCallback(
        (setErrorState = true): boolean => {
            const validationErrors = checkCommonData(commonData);
            const isSafe = Object.keys(validationErrors).length === 0;

            if (setErrorState && mounted.current) {
                if (!isSafe) {
                    setErrors({
                        ...validationErrors,
                        submit: 'Please fill in all required fields correctly',
                    });
                } else {
                    setErrors({});
                }
            }

            return isSafe;
        },
        [commonData]
    );

    const renderAuditLogs = useCallback(() => {
        if (!account?._id || !UserPermissions().access.admin) {
            return null;
        }

        return (
            <div data-testid="audit-log-section" className="addNewAdvisorToFirm-audit">
                <h3 data-testid="audit-log-title">Audit Log</h3>
                <AuditLogDisplay
                    fetchLogs={async () => {
                        try {
                            const response = await api2.client.AccountApi.getAccount({
                                account_id: account._id,
                                populate_audit_log: true,
                            });
                            return (response.data.account?.audit_log ?? []).map((log) => ({
                                id: String(Date.now()),
                                timestamp: log.timestamp || new Date().toISOString(),
                                action: log.action || '',
                                initiator_type: log.initiator_type || '',
                                initiator: log.initiator || {},
                                action_details: log.action_details || {},
                            }));
                        } catch (error) {
                            console.error('Error loading audit logs:', error);
                            return [];
                        }
                    }}
                />
            </div>
        );
    }, [account?._id]);

    const getFields = useCallback(
        () => ({
            Name: {
                placeholder: 'Enter Name',
                label: 'Name',
                fieldName: 'name',
                type: 'text',
                required: true,
            },
            'ID Type': {
                placeholder: 'Select ID Type',
                label: 'ID Type',
                fieldName: 'id_type',
                type: 'option',
                optionField: 'name',
                options: ID_TYPES,
                optionClearable: true,
            },
            'ID Number': {
                placeholder: 'Enter ID Number',
                label: 'ID Number',
                fieldName: 'id_number',
                type: account ? 'password' : 'text',
                allowShow: true,
            },
            'Account Type': {
                placeholder: 'Select Account Type',
                label: 'Account Type',
                fieldName: 'account_type',
                type: 'option',
                optionField: 'name',
                options: ACCOUNT_TYPES,
                optionClearable: true,
            },
            'Street Line 1': {
                placeholder: 'Enter Street Line 1',
                label: 'Street Line 1',
                fieldName: 'streetLine1',
                type: 'text',
            },
            'Street Line 2': {
                placeholder: 'Enter Street Line 2',
                label: 'Street Line 2',
                fieldName: 'streetLine2',
                type: 'text',
            },
            City: {
                placeholder: 'Enter City',
                label: 'City',
                fieldName: 'city',
                type: 'text',
            },
            State: {
                placeholder: 'Enter State eg. CA, NY, etc.',
                label: 'State',
                fieldName: 'state',
                type: 'select',
                options: STATES,
                optionClearable: true,
            },
            'Zip Code': {
                placeholder: 'Enter Zip Code',
                label: 'Zip Code',
                fieldName: 'zipCode',
                type: 'text',
            },
            Country: {
                placeholder: 'Enter Country',
                label: 'Country',
                fieldName: 'country',
                type: 'select',
                options: ['US', 'CA'],
                optionClearable: true,
            },
        }),
        [account]
    );

    const handleSubmit = async () => {
        if (!isSafeToSave(true)) return;

        loading(320, 'postPatchAccount');

        try {
            const createNewAccount = !account;
            if (createNewAccount) {
                const newAccountId = (
                    await api2.client.AccountApi.createAccount({
                        CreateAccountRequest: {
                            user: userId ?? '',
                            name: commonData.name,
                            id_type: commonData.id_type?.value as CreateAccountRequestIdTypeEnum,
                            id_number: commonData.id_number ?? '',
                            address:
                                Object.keys(commonData).length > 0
                                    ? {
                                          ...commonData,
                                          state: commonData.state as CreateAccountRequestAddressStateEnum,
                                      }
                                    : undefined,
                            account_type: commonData.account_type?.value as CreateAccountRequestAccountTypeEnum,
                        },
                    })
                ).data.account_id;
                const newAccount = (
                    await api2.client.AccountApi.getAccount({
                        account_id: newAccountId as string,
                    })
                ).data.account;
                setAccount(newAccount as Account);
            } else {
                const accountId = account._id;
                await api2.client.AccountApi.updateAccount({
                    account_id: accountId,
                    UpdateAccountRequest: {
                        user: userId ?? '',
                        name: commonData.name,
                        id_type: commonData.id_type?.value as CreateAccountRequestIdTypeEnum,
                        id_number: commonData.id_number ?? '',
                        address:
                            Object.keys(commonData).length > 0
                                ? {
                                      ...commonData,
                                      state: commonData.state as CreateAccountRequestAddressStateEnum,
                                  }
                                : undefined,
                        account_type: commonData.account_type?.value as CreateAccountRequestAccountTypeEnum,
                    },
                });
                const updatedAccount = (
                    await api2.client.AccountApi.getAccount({
                        account_id: accountId,
                    })
                ).data.account;
                setAccount(updatedAccount as Account);
            }
            setDismissableAlert(setAlert, `The account has been ${account ? 'updat' : 'creat'}ed`);

            reloadData();
        } catch (e) {
            console.error('Error creating or updating account', e);
            setDismissableAlert(setAlert, `Error ${account ? 'updating' : 'creating'} account. Ensure field validity`, true);
        }

        loaded('postPatchAccount');
    };

    const deactivateAccount = async () => {
        if (!account) return;
        if (!confirmDelete) {
            setConfirmDelete(true);
            setTimeout(() => {
                setConfirmDelete(false);
            }, 3000);
        } else {
            loading(0, 'accountDrawer_deactivateAccount');
            try {
                const accountId = account._id;
                await api2.client.AccountApi.deleteAccount({
                    account_id: accountId,
                });
                reloadData();
                setDismissableAlert(setAlert, 'Account deactivated.');
                loaded('accountDrawer_deactivateAccount');
                close();
            } catch (e) {
                console.error('Error deactivating account', e);
                setDismissableAlert(setAlert, 'Error deactivating account.', true);
            }

            loaded('accountDrawer_deactivateAccount');
        }
    };

    const renderDeactivateButton = useCallback(() => {
        if (!account) return null;
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '20px',
                }}
            >
                <div
                    className="a"
                    data-testid="deactivate-button"
                    onClick={deactivateAccount}
                    style={{
                        color: 'var(--color-dark-red)',
                        width: 'fit-content',
                    }}
                >
                    {confirmDelete ? 'Confirm Deactivate Account' : 'Deactivate Account'}
                </div>
            </div>
        );
    }, [account, confirmDelete]);

    const handleFieldChange = (fieldName: string, value: any) => {
        if (!mounted.current) return;

        setCommonData((prev) => {
            const newData = {
                ...prev,
                [fieldName]: value,
            };

            // Validate immediately after state update
            const validationErrors = checkCommonData(newData);
            setErrors((prev) => ({
                ...prev,
                [fieldName]: validationErrors[fieldName] || '',
                submit: Object.keys(validationErrors).length > 0 ? 'Please fill in all required fields correctly' : '',
            }));

            return newData;
        });
    };

    return (
        <GenericDrawer
            title={account ? `Edit Entity` : 'Create New Entity'}
            actionStr={account ? 'Save' : 'Create'}
            loadingKey="accountDrawer"
            getFields={getFields}
            onSubmit={handleSubmit}
            onDelete={account ? deactivateAccount : undefined}
            close={close}
            loading={loading}
            loaded={loaded}
            extraContent={() => (
                <>
                    {renderDeactivateButton()}
                    {renderAuditLogs()}
                </>
            )}
            errors={errors}
            setErrors={setErrors}
            isSafeToSave={isSafeToSave}
            formData={commonData}
            className="addNewAdvisorToFirm-drawer"
            onFieldChange={handleFieldChange}
        />
    );
};

export default withRouter(AddEditAccountDrawer);

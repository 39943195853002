import React, { useEffect, useRef, useState } from 'react';
import api2 from '../../api2';
import '../../styles/addConnectionDrawer.css';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import AuditLogDisplay from '../AuditLogDisplay';
import { AccountSearchFilter, DocumentSearchFilter, InvestmentSearchFilter, UserSearchFilter } from '../Filters/GenericSearchFilter';
import GenericDrawer from './GenericDrawer';

interface ValuationDrawerProps {
    valuation_id?: string;
    investment?: { _id: string; account: string };
    account?: { _id: string };
    relevantUser?: any;
    setAlert: (message: string, isError: boolean) => void;
    closeDrawer: () => void;
    loading: (time?: number, key?: string) => void;
    loaded: (key?: string) => void;
    reloadData: () => Promise<void>;
    vue?: any;
}

interface ValuationFormData {
    investment: { _id: string; account: string };
    date: string;
    amount: number;
    unit_price?: number;
    documents?: Array<{ _id: string }>;
    user: { _id: string };
    account: { _id: string };
}

const ValuationDrawer: React.FC<ValuationDrawerProps> = ({ valuation_id, loading, loaded, ...props }) => {
    const [valuationId, setValuationId] = useState<string | null>(valuation_id || null);
    const editing = !!valuationId;
    const isLoadingRef = useRef(false);
    const initialLoadDone = useRef(false);
    const [formData, setFormData] = useState<Partial<ValuationFormData>>({});
    useEffect(() => {
        const handleValuationIdChange = async () => {
            setValuationId(valuation_id || null);
            if (!initialLoadDone.current && valuation_id) {
                initialLoadDone.current = true;
                setValuationId(valuation_id);
                loadData();
            }
        };

        handleValuationIdChange();
    }, [valuation_id]);

    const loadData = async (): Promise<any> => {
        if (isLoadingRef.current) {
            return;
        }

        try {
            isLoadingRef.current = true;
            loading?.(0, 'valuationDrawer');

            let loadedData: any = {};

            if (valuationId) {
                const response = await api2.client.ValuationApi.getValuation({
                    valuation_id: valuationId,
                    populate_user: true,
                    populate_account: true,
                    populate_investment: true,
                    populate_documents: true,
                });
                const valuation = response.data.valuation;

                if (valuation) {
                    loadedData = {
                        investment: valuation.investment,
                        date: valuation.date,
                        amount: valuation.amount,
                        unit_price: valuation.unit_price,
                        documents: valuation.documents,
                        user: valuation.user,
                        account: valuation.account,
                    };
                }
            } else {
                if (props.investment) {
                    // Get account ID from investment
                    const accountId = props.investment.account;

                    if (accountId) {
                        try {
                            // Fetch account details
                            const accountResponse = await api2.client.AccountApi.getAccount({
                                account_id: accountId,
                                populate_user: true,
                            });

                            if (accountResponse?.data?.account) {
                                const accountData = accountResponse.data.account;

                                loadedData = {
                                    investment: {
                                        ...props.investment,
                                        account: accountData, // Update investment's account
                                    },
                                    account: accountData, // Set standalone account
                                    user: props.relevantUser,
                                    documents: [],
                                };
                            } else {
                                console.warn('Account API response was empty for ID:', accountId);
                                throw new Error('Empty account response');
                            }
                        } catch (err) {
                            console.error('Failed to load account:', err);
                            // Set minimal account data on error
                            loadedData = {
                                investment: props.investment,
                                account: {
                                    _id: accountId,
                                    name: 'Unknown Account', // Fallback name
                                },
                                user: props.relevantUser,
                                documents: [],
                            };
                        }
                    } else {
                        console.warn('No account ID found in investment');
                        loadedData = {
                            investment: props.investment,
                            user: props.relevantUser,
                            documents: [],
                        };
                    }
                } else {
                    loadedData = {
                        user: props.relevantUser,
                        investment: props.investment,
                        documents: [],
                    };
                }
            }

            setFormData(loadedData);

            return loadedData;
        } catch (err) {
            console.error('ValuationDrawer: Error loading data:', err);
            setDismissableAlert(props.setAlert, 'Could not load data', true);
        } finally {
            isLoadingRef.current = false;
            loaded?.('valuationDrawer');
        }
    };

    const renderExtra = () => {
        if (valuationId && UserPermissions().access.admin) {
            return (
                <div style={{ marginTop: '20px', clear: 'both' }}>
                    <AuditLogDisplay
                        fetchLogs={async () => {
                            try {
                                if (!valuationId) return [];
                                const response = await api2.client.ValuationApi.getValuation({
                                    valuation_id: valuationId,
                                    populate_audit_log: true,
                                });
                                const auditLogs = response.data.valuation?.audit_log || [];
                                return auditLogs.map((log: any) => ({
                                    id: log.id,
                                    ...log,
                                }));
                            } catch (err) {
                                console.error('Error loading audit logs:', err);
                                return [];
                            }
                        }}
                    />
                </div>
            );
        }
        return null;
    };

    const deleteValuation = async () => {
        if (!valuationId) return;
        try {
            await api2.client.ValuationApi.deleteValuation({
                valuation_id: valuationId,
            });
            setDismissableAlert(props.setAlert, 'Valuation deleted successfully', false);
            await props.reloadData();
            props.closeDrawer();
        } catch (err) {
            console.error('Could not delete valuation', err);
            setDismissableAlert(props.setAlert, 'Could not delete valuation', true);
        }
    };

    const getFields = (drawerState: any, setDrawerState: any) => {
        let fields = {};
        const user = formData.user || drawerState?.commonData?.user;
        const account = formData.account || drawerState?.commonData?.account;
        const investment = formData.investment || drawerState?.commonData?.investment;

        fields = {
            ...fields,
            User: {
                label: 'User',
                type: 'custom',
                fieldName: 'user',
                component: (value: any, onChange: any) => {
                    return (
                        <UserSearchFilter
                            defaultLabel="Select User"
                            selected={value}
                            onChange={(newUser) => {
                                if (newUser?._id === user?._id) return;
                                onChange(newUser);
                                setDrawerState((prevState: any) => {
                                    return {
                                        ...prevState,
                                        commonData: {
                                            ...prevState.commonData,
                                            user: newUser,
                                            account: null,
                                        },
                                    };
                                });
                            }}
                            width={'100%'}
                            defaultOptions={false}
                            disabled={!!props.relevantUser || !!props.account || !!props.investment}
                        />
                    );
                },
            },
            Account: {
                label: 'Account',
                type: 'custom',
                fieldName: 'account',
                component: (value: any, onChange: any) => (
                    <AccountSearchFilter
                        filter={{
                            ...(user && { users: [user._id] }),
                        }}
                        selected={value}
                        onChange={(newAccount) => {
                            if (newAccount?._id === account?._id) return;

                            // Update GenericDrawer's field
                            onChange(newAccount);

                            // Update form data correctly
                            setFormData((prev: any) => ({
                                ...prev,
                                account: newAccount,
                                investment: null, // Clear investment when account changes
                            }));

                            // Update drawer state if needed
                            setDrawerState((prevState: any) => ({
                                ...prevState,
                                commonData: {
                                    ...prevState.commonData,
                                    account: newAccount,
                                    investment: null,
                                },
                            }));
                        }}
                        width={'100%'}
                        defaultOptions={true}
                        disabled={!user || !!props.account || !!props.investment}
                        excludeDisplayFields={['user']}
                    />
                ),
            },
            Investment: {
                label: 'Investment',
                type: 'custom',
                fieldName: 'investment',
                component: (value: any, onChange: any) => {
                    return (
                        <InvestmentSearchFilter
                            filter={{
                                ...(formData.user && { users: [formData.user._id] }),
                                ...(formData.account && { accounts: [formData.account._id] }),
                            }}
                            selected={value}
                            onChange={(newInvestment: any) => {
                                if (!Array.isArray(newInvestment) && newInvestment?._id === investment?._id) return;

                                // Update GenericDrawer's field
                                onChange(newInvestment);

                                // Update form data correctly
                                setFormData((prev) => ({
                                    ...prev,
                                    investment: newInvestment,
                                    account: newInvestment?.account
                                        ? {
                                              ...newInvestment.account,
                                              user: newInvestment.user,
                                          }
                                        : null,
                                }));

                                // Update drawer state if needed
                                setDrawerState((prevState: any) => ({
                                    ...prevState,
                                    commonData: {
                                        ...prevState.commonData,
                                        investment: newInvestment,
                                        account: newInvestment?.account
                                            ? {
                                                  ...newInvestment.account,
                                                  user: newInvestment.user,
                                              }
                                            : null,
                                    },
                                }));
                            }}
                            width={'100%'}
                            defaultOptions={true}
                            isDisabled={!account || !!props.investment}
                            excludeDisplayFields={['user']}
                        />
                    );
                },
            },
            Date: {
                label: 'Date',
                type: 'date',
                fieldName: 'date',
                required: true,
            },
            Amount: {
                label: 'Amount',
                type: 'currency',
                fieldName: 'amount',
                required: true,
            },
            UnitPrice: {
                label: 'Unit Price',
                type: 'currency',
                fieldName: 'unit_price',
                required: false,
            },
            Documents: {
                label: 'Documents',
                type: 'custom',
                fieldName: 'documents',
                component: (value: any, onChange: any) => {
                    return (
                        <DocumentSearchFilter
                            filter={{
                                ...(user && { users: [user._id] }),
                                ownership_type: 'Investment',
                                ownership_ids: [investment?._id],
                            }}
                            selected={value}
                            onChange={(newDocuments) => {
                                if (!Array.isArray(newDocuments) && newDocuments?._id === investment?._id) return;
                                onChange(newDocuments);
                            }}
                            isMulti={true}
                            width={'100%'}
                            defaultOptions={true}
                            isDisabled={!investment}
                            excludeDisplayFields={['ownership', 'user']}
                            vue={props.vue}
                            linkDocument={true}
                        />
                    );
                },
            },
        };

        return fields;
    };

    const handleValuationSubmit = async (data: ValuationFormData) => {
        try {
            if (editing && valuationId) {
                const updateData: any = {};
                if (data.investment?._id) updateData.investment = data.investment._id;
                if (data.date) updateData.date = data.date;
                if (data.amount) updateData.amount = data.amount;
                if (data.unit_price) updateData.unit_price = data.unit_price;
                if (data.documents) updateData.documents = data.documents.map((d) => d._id);

                await api2.client.ValuationApi.updateValuation({
                    valuation_id: valuationId,
                    UpdateValuationRequest: updateData,
                });
            } else {
                // Validate required fields for new valuation
                if (!formData.investment?._id) {
                    console.error('Missing investment data:', { formData, data });
                    throw new Error('Investment is required');
                }

                const createData = {
                    investment: formData.investment._id,
                    account: formData.account?._id, // Include account from formData
                    user: formData.user?._id, // Include user from formData
                    date: data.date,
                    amount: data.amount,
                    unit_price: data.unit_price,
                    documents: data.documents?.map((d) => d._id) || [],
                };

                const response = await api2.client.ValuationApi.createValuation({
                    CreateValuationRequest: createData,
                });

                if (response.data.valuation_id) {
                    setValuationId(response.data.valuation_id);
                }
            }

            setDismissableAlert(props.setAlert, `Valuation ${editing ? 'updated' : 'created'} successfully`, false);
            props.reloadData();
            // props.closeDrawer();
        } catch (err) {
            console.error('Could not save valuation', err);
            setDismissableAlert(props.setAlert, err instanceof Error ? err.message : 'Could not save valuation', true);
            throw err;
        }
    };

    const handleFieldChange = (fieldName: string, value: any) => {
        setFormData((prev) => ({
            ...prev,
            [fieldName]: value,
        }));
    };

    return (
        <>
            <GenericDrawer
                className="addNewAdvisorToFirm-drawer"
                key={valuationId || 'new'}
                title={`${editing ? 'Update' : 'Create'} Valuation`}
                actionStr={`${editing ? 'Update' : 'Create'} Valuation`}
                loadingKey="valuationDrawer"
                close={props.closeDrawer}
                loading={loading}
                loaded={loaded}
                loadData={loadData}
                extraContent={renderExtra}
                onDelete={valuationId ? deleteValuation : undefined}
                getFields={getFields}
                onSubmit={handleValuationSubmit}
                formData={formData}
                onFieldChange={handleFieldChange}
            />
            <div style={{ clear: 'both', height: '300px' }} />
        </>
    );
};

export default ValuationDrawer;

import React, { FC, useEffect, useRef, useState } from 'react';

import api from '../../api';
import api2 from '../../api2';
import '../../styles/addConnectionDrawer.css';

import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { VUES } from '../../constants/constantStrings';
import { ConnectionOutreachStatus } from '../../openApiClient';
import ContactsSelectWithList from '../../pages/Admin/SecurityMaster/ContactsSelectWithList';
import { Account, AdvisoryGroup, AssetManager, Connection, ConnectionStatus, Contact, Integration, RALRequest, User } from '../../types/index';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import { getUserStatusColor } from '../../utilities/color/getUserStatusColor';
import formatDate from '../../utilities/format/formatDate';
import ConnectionOauthSetupButton from '../Buttons/ConnectionOauthSetupButton';
import KeyValueDrawerList from '../DrawerComponents/KeyValueDrawerList';
import { ViewConnectionErrors } from '../DrawerComponents/ViewConnectionErrors';
import ObjectSearchSelectTs from '../Dropdowns/ObjectSearchSelectTs';
import { AccountSearchFilter, AdvisoryGroupSearchFilter, RalRequestSearchFilter, UserSearchFilter } from '../Filters/GenericSearchFilter';
import Checkbox from '../Inputs/Checkbox';
import renderInputError from '../renderInputError';
import GenericDrawer from './GenericDrawer';

interface PaginateParams {
    page?: number;
    limit?: number;
    [key: string]: any;
}

interface Props {
    connectionId?: string;
    close: () => void;
    user?: User;
    advisory_group?: AdvisoryGroup;
    accounts?: Account[];
    reloadData?: () => void;
    loading: (time?: number, key?: string) => void;
    loaded?: (key?: string) => void;
    setAlert: (message: React.ReactNode | null, type?: string | null) => void;
    vue?: string;
    adminOrAdvisorVue?: boolean;
}

interface KeyValueItem {
    key: string;
    value: string;
}

interface CommonData {
    connectionName: string;
    integration: Integration | null;
    account: Account | null;
    account_list: Account[];
    user: User | null;
    advisory_group: AdvisoryGroup | null;
    tfaEmail: string;
    is_enabled: boolean;
    long_running: boolean;
    status: string;
    error: string | null;
    contacts: Contact[];
    outreach_status: ConnectionOutreachStatus;
    expect_documents_over_email: boolean;
    ral_requests: RALRequest[];
    asset_manager: AssetManager | null;
    requirePhoneNumber: boolean;
    forcedStatusChange?: string;
    required2FA: boolean;
}

enum OwnerType {
    User = 'user',
    AdvisoryGroup = 'advisory_group',
}

interface State {
    loaded: boolean;
    connection: Connection | null;
    commonData: CommonData;
    keyValueList: KeyValueItem[];
    errors: Record<string, string>;
    isTestingConnection: boolean;
    confirmRemove: boolean;
    showCredentials: boolean;
    copiedIdToClipboard: boolean;
    copiedEmailToClipboard: boolean;
    copiedPhoneToClipboard: boolean;
    accounts: Account[];
    integrations: Integration[];
    addExistingUser: boolean;
    testErrors: Record<string, string> | null;
    ownerType: OwnerType;
}

interface Credential {
    key: string;
    display_name?: string;
}

const AddEditConnectionDrawer: FC<Props> = ({
    connectionId,
    close,
    user,
    advisory_group,
    accounts = [],
    reloadData,
    loading: loadingProp,
    loaded,
    setAlert,
    vue = VUES.ADMIN,
    adminOrAdvisorVue = false,
}) => {
    const [state, setState] = useState<State>({
        loaded: false,
        connection: null,
        commonData: {
            connectionName: '',
            integration: null,
            account: null,
            account_list: [],
            user: user || null,
            advisory_group: advisory_group || null,
            tfaEmail: '',
            is_enabled: true,
            long_running: false,
            status: 'Pending',
            error: null,
            contacts: [],
            outreach_status: ConnectionOutreachStatus.ConnectionCreated,
            expect_documents_over_email: false,
            ral_requests: [],
            asset_manager: null,
            requirePhoneNumber: false,
            forcedStatusChange: undefined,
            required2FA: false,
        },
        keyValueList: [],
        errors: {},
        isTestingConnection: false,
        confirmRemove: false,
        showCredentials: false,
        copiedIdToClipboard: false,
        copiedEmailToClipboard: false,
        copiedPhoneToClipboard: false,
        accounts: accounts,
        integrations: [],
        addExistingUser: false,
        testErrors: null,
        ownerType: advisory_group ? OwnerType.AdvisoryGroup : OwnerType.User,
    });

    useEffect(() => {
        if (connectionId) {
            load(connectionId);
        }
    }, []);

    useEffect(() => {
        if (!_.isEqual(prevConnectionId.current, connectionId)) {
            load(connectionId);
        }

        prevConnectionId.current = connectionId;
    }, [connectionId]);

    const prevConnectionId = useRef(connectionId);

    const load = async (connectionId: string | null = null) => {
        loadingProp(0, 'addEditConnectionDrawer_load');

        let stateUpdate: Partial<State> = {};

        try {
            // Load integrations
            let allIntegrations = [];
            try {
                allIntegrations = await api2.paginateApiRoute(async (paginate_params: PaginateParams) => {
                    return (
                        await api2.client.IntegrationApi.listIntegrations({
                            ...paginate_params,
                        })
                    ).data.integrations;
                });
            } catch (error) {
                console.error('AddEditConnectionDrawer: Error fetching integrations:', error);
            }

            const manualIntegration: Integration = {
                _id: undefined,
                name: 'Manual (AltExchange)',
                has_worker: false,
                tfa_required: false,
                phone_required: false,
                required_creds: [],
                worker_version: 0,
                created_at: '',
                updated_at: '',
                deleted_at: '',
                connections_count: 0,
                errored_connections_count: 0,
                investments: [],
            };
            stateUpdate.integrations = [manualIntegration, ...allIntegrations];

            if (connectionId) {
                const connection = await api.get(
                    `/connections/${connectionId}?populate_user=true&populate_account=true&populate_ral_requests=true&${UserPermissions().access?.admin ? '&most_recent_jobs=true' : ''}`
                );

                const connUserId = connection.user?._id ?? connection.user;

                if (connUserId) {
                    try {
                        const accounts = await api2.paginateApiRoute(async (paginate_params: PaginateParams) => {
                            return (
                                await api2.client.AccountApi.listAccounts({
                                    users: [connUserId],
                                    ...paginate_params,
                                })
                            ).data.accounts;
                        });
                        stateUpdate.accounts = accounts;
                    } catch (error) {
                        console.error('AddEditConnectionDrawer: Error fetching accounts:', error);
                        stateUpdate.accounts = [];
                    }
                }

                let this_account,
                    this_account_list = [];

                let all_account_ids = [connection.account, ...connection.account_list.map((item: any) => item.value)].filter((aid) => aid);

                if (all_account_ids.length > 0) {
                    try {
                        const accounts = await api2.paginateApiRoute(async (paginate_params: PaginateParams) => {
                            return (
                                await api2.client.AccountApi.listAccounts({
                                    accounts: all_account_ids,
                                    ...paginate_params,
                                })
                            ).data.accounts;
                        });
                        this_account_list = accounts.filter((acc: any) => acc._id !== connection.account && all_account_ids.includes(acc._id));
                        this_account = accounts.find((acc: any) => acc._id === connection.account);
                    } catch (error) {
                        console.error('AddEditConnectionDrawer: Error fetching accounts:', error);
                    }
                }

                const integration =
                    connection.integration === null
                        ? manualIntegration
                        : stateUpdate.integrations.find((int) => int._id === (connection.integration?._id ?? connection.integration));

                let contacts = [];
                if (connection.contacts?.length && UserPermissions().access?.admin) {
                    const contactList = (await api.get(`/contacts?contactIds=${connection.contacts.map((c: any) => c._id ?? c).join(',')}&count=100000`))?.results ?? [];
                    contacts = contactList.map((c: any) => ({
                        contact: c._id,
                        roles: [],
                    }));
                }

                let advisory_group = null;
                if (connection.advisory_group) {
                    stateUpdate.ownerType = OwnerType.AdvisoryGroup;
                    try {
                        advisory_group = (await api2.client.AdvisoryGroupApi.getAdvisoryGroupById({ advisory_group_id: connection.advisory_group })).data
                            .advisory_group as unknown as AdvisoryGroup;
                    } catch (error) {
                        console.error('AddEditConnectionDrawer: Error fetching advisory group:', error);
                    }
                }

                stateUpdate.connection = {
                    ...connection,
                    account: this_account,
                    integration,
                };

                stateUpdate.commonData = {
                    connectionName: connection.name,
                    user: connection.user,
                    advisory_group,
                    account: this_account,
                    account_list: this_account_list,
                    integration: integration ?? null,
                    tfaEmail: connection.tfa_email,
                    required2FA: connection.use_tfa,
                    requirePhoneNumber: connection.require_phone ?? false,
                    is_enabled: connection.is_enabled,
                    long_running: connection.long_running,
                    status: connection.status,
                    error: connection.error,
                    contacts,
                    outreach_status: connection.outreach_status,
                    expect_documents_over_email: connection.expect_documents_over_email ?? false,
                    ral_requests: connection.ral_requests,
                    asset_manager: null,
                };

                if (connection.asset_manager) {
                    const asset_manager = await api.get(`/assetmanagers/${connection.asset_manager}`);
                    stateUpdate.commonData.asset_manager = asset_manager;
                }

                stateUpdate.keyValueList = !connection.credentials
                    ? []
                    : Object.keys(connection.credentials).map((credKey) => ({
                          key: credKey,
                          value: connection.credentials[credKey],
                      }));
            } else if (user || advisory_group) {
                stateUpdate.commonData = {
                    user: user || null,
                    advisory_group: advisory_group || null,
                    connectionName: '',
                    integration: null,
                    account: null,
                    account_list: [],
                    tfaEmail: '',
                    is_enabled: true,
                    long_running: false,
                    status: 'Pending',
                    error: null,
                    contacts: [],
                    outreach_status: ConnectionOutreachStatus.ConnectionCreated,
                    expect_documents_over_email: false,
                    ral_requests: [],
                    asset_manager: null,
                    requirePhoneNumber: false,
                    required2FA: false,
                };
                stateUpdate.connection = null;
            }

            stateUpdate.loaded = true;

            setState((prev) => ({
                ...prev,
                ...stateUpdate,
            }));

            setTimeout(() => {
                loaded?.('addEditConnectionDrawer_load');
            }, 1000);
        } catch (error) {
            console.error('AddEditConnectionDrawer: Error in load:', error);
            setState((prev) => ({ ...prev, loaded: true }));
            setDismissableAlert(setAlert, 'Error loading connection', true);
            loaded?.('addEditConnectionDrawer_load');
        }
    };

    const checkCommonData = (data: CommonData, errors: Record<string, string>): void => {
        if (!data.connectionName) {
            errors['connectionName'] = 'This field is required.';
        }

        if (isEdit() && !data.tfaEmail) {
            errors['tfaEmail'] = 'This field is required.';
        }

        if (adminOrAdvisorVue) {
            if (!data.integration) {
                errors['integration'] = 'This field is required.';
            }
        }

        if (!data.account && data.user) {
            errors['account'] = 'This field is required.';
        }

        // check credential fields only if connection is enabled
        if (data.is_enabled) {
            const areFieldsValid = state.keyValueList.every((kv) => kv.key.length > 0 && kv.value.length > 0);

            if (!areFieldsValid) {
                errors['kvList'] = 'All credentials must be complete.';
            }
        }
    };

    const isSafeToSave = (setErrors = true): boolean => {
        let errors: Record<string, string> = {};
        checkCommonData(state.commonData, errors);

        // clear unset errors (only keep truthy values)
        errors = Object.entries(errors).reduce(
            (res, [k, v]) => {
                if (v) res[k] = v;
                return res;
            },
            {} as Record<string, string>
        );

        const isSafe = Object.values(errors).filter(Boolean).length === 0;
        if (setErrors) {
            errors.submit = isSafe ? '' : 'There are missing required fields.';
            setState((prev) => ({
                ...prev,
                errors: isSafe ? {} : errors,
            }));
        }

        return isSafe;
    };

    const isEdit = (): boolean => {
        return Boolean(state.connection);
    };

    const getConnectionId = (): string | null => {
        return state.connection?._id || connectionId || null;
    };

    const createOrSaveConnection = async () => {
        // Check if safe to save
        const safeToSave = isSafeToSave(true);
        if (!safeToSave) return;

        loadingProp(0, 'addEditConnectionDrawer_submit');
        try {
            const commonData = JSON.parse(JSON.stringify(state.commonData));

            // Determine status
            let status;
            if (!commonData.integration._id) {
                status = 'Active';
            } else if (commonData.forcedStatusChange) {
                status = commonData.forcedStatusChange;
            } else if (!['Pending', 'Active', 'Awaiting 2FA'].includes(commonData.status)) {
                status = 'Active';
            } else {
                status = commonData.status;
            }

            const user = commonData.user?._id ?? null;
            const advisory_group = commonData.advisory_group?._id ?? null;

            let account;
            if (state.ownerType === OwnerType.User) {
                account = commonData.account?._id ?? null;
            }

            let account_list = commonData.account_list?.map((acc: any) => acc.value._id) ?? [];
            if (account && commonData.account?._id) {
                account_list.push(account);
            }
            account_list = Array.from(new Set(account_list));

            const connectionBody = {
                name: commonData.connectionName,
                ...(commonData.tfaEmail ? { tfa_email: commonData.tfaEmail } : {}),
                user,
                advisory_group,
                account,
                account_list,
                credentials: state.keyValueList.reduce(
                    (res, kv) => {
                        res[kv.key] = kv.value;
                        return res;
                    },
                    {} as Record<string, string>
                ),
                integration: commonData.integration._id,
                use_tfa: commonData.required2FA,
                require_phone: commonData.requirePhoneNumber ?? false,
                expect_documents_over_email: commonData.expect_documents_over_email ?? false,
                is_enabled: commonData.is_enabled,
                long_running: commonData.long_running,
                status,
                error: commonData.error,
                outreach_status: commonData.outreach_status,
                ral_requests: commonData.ral_requests?.map((r: any) => r._id) ?? [],
                contacts: [],
                asset_manager: null,
            };

            if (UserPermissions().access?.admin) {
                connectionBody.contacts = (commonData.contacts ?? []).map((c: any) => c.contact);
                connectionBody.asset_manager = commonData.asset_manager?._id ?? null;
            }

            const isEditMode = isEdit();

            const connectionRes = isEditMode ? await api.patch(`/connections/${getConnectionId()}`, connectionBody) : await api.post('/connections', connectionBody);

            if (connectionRes) {
                setDismissableAlert(setAlert, `Connection has been ${isEditMode ? 'updated' : 'created.'}`, false);
                if (loaded) {
                    loaded('addEditConnectionDrawer_submit');
                }
                if (reloadData) {
                    await reloadData();
                }
                await load(connectionRes?._id);
                // close();
            } else {
                setDismissableAlert(setAlert, 'Error creating connection.', true);
                if (loaded) loaded('addEditConnectionDrawer_submit');
            }
        } catch (error) {
            console.error('Error in createOrSaveConnection:', error);
            console.error('Error details:', {
                response: (error as any).response,
                message: (error as any).response?.data?.message,
            });
            setDismissableAlert(setAlert, (error as any).response?.data?.message || 'Error saving connection.', true);
            if (loaded) loaded('addEditConnectionDrawer_submit');
        } finally {
            loadingProp(100, 'addEditConnectionDrawer_submit');
            loaded?.('addEditConnectionDrawer_submit');
        }
    };

    const removeConnection = async () => {
        if (state.confirmRemove) {
            loadingProp(0, 'addEditConnectionDrawer_removeConnection');
            await api.delete('/connections/' + state.connection?._id);
            loaded?.('addEditConnectionDrawer_removeConnection');
            if (reloadData) await reloadData();
            close();
        } else {
            setState((prev) => ({ ...prev, confirmRemove: true }));
            setTimeout(() => {
                setState((prev) => {
                    if (prev.confirmRemove) {
                        return { ...prev, confirmRemove: false };
                    }
                    return prev;
                });
            }, 1000);
        }
    };

    const renderRemoveButton = () => (
        <div className="a" style={{ color: 'var(--color-dark-red)' }} onClick={removeConnection}>
            {state.confirmRemove ? 'Confirm' : 'Remove'}
        </div>
    );

    const renderTestConnectionButton = () => {
        const conn = state.connection;
        if (!conn || isManualConnection(conn) || !UserPermissions().canUpdateConnections) {
            return <></>;
        }
        return (
            <div style={{ position: 'absolute', right: 0, display: 'flex' }}>
                {!['Active', 'Pending', 'Error', 'Under Review'].includes(conn.status) && <div style={{ float: 'right', marginRight: '10px' }} className="syncing-spinner"></div>}
                <span className="a" onClick={testConnection}>
                    Test Connection
                </span>
            </div>
        );
    };
    const renderStatus = () => {
        if (!state.connection) return null;
        return (
            <div className="status" style={{ color: getUserStatusColor(state.connection.status) }}>
                {state.connection.status}
            </div>
        );
    };

    const renderHeader = () => (
        <>
            {isEdit() && (
                <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-between', height: '20px' }}>
                    {UserPermissions().canDeleteConnections && adminOrAdvisorVue && renderRemoveButton()}
                    {renderTestConnectionButton()}
                </div>
            )}
            <div style={{ clear: 'both', height: '20px' }} />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <h1
                        style={{
                            marginBottom: '0px',
                        }}
                    >
                        {!!connectionId || isEdit() ? 'Edit' : 'Create'} Connection
                    </h1>
                    {getConnectionId() && (
                        <FontAwesomeIcon
                            icon={faRefresh}
                            size="lg"
                            title="Refresh Data"
                            className={`ral_refreshIcon a`}
                            style={{ marginLeft: '20px' }}
                            onClick={async () => {
                                const id = getConnectionId();
                                if (id) {
                                    load(id);
                                }
                            }}
                        />
                    )}
                </div>
                {isEdit() && renderStatus()}
            </div>

            {state.connection?.tfa_email && (
                <div
                    style={{ fontSize: '10px', cursor: 'pointer' }}
                    onClick={() => {
                        navigator.clipboard.writeText(state.connection?.tfa_email || '');
                        setState((prev) => ({
                            ...prev,
                            copiedEmailToClipboard: true,
                        }));
                        setTimeout(() => {
                            setState((prev) => ({
                                ...prev,
                                copiedEmailToClipboard: false,
                            }));
                        }, 1000);
                    }}
                    title={state.copiedEmailToClipboard ? 'Copied!' : 'Copy to clipboard'}
                >
                    2FA Email: {state.connection.tfa_email} {state.copiedEmailToClipboard && ' (Copied!)'}
                </div>
            )}
            {state.connection?.tfa_phone && (
                <div
                    style={{ fontSize: '10px', cursor: 'pointer' }}
                    onClick={() => {
                        navigator.clipboard.writeText(state.connection?.tfa_phone || '');
                        setState((prev) => ({
                            ...prev,
                            copiedPhoneToClipboard: true,
                        }));
                        setTimeout(() => {
                            setState((prev) => ({
                                ...prev,
                                copiedPhoneToClipboard: false,
                            }));
                        }, 1000);
                    }}
                    title={state.copiedPhoneToClipboard ? 'Copied!' : 'Copy to clipboard'}
                >
                    2FA Phone: {state.connection.tfa_phone} {state.copiedPhoneToClipboard && ' (Copied!)'}
                </div>
            )}
            {UserPermissions()?.access?.admin && (
                <div>
                    <ViewConnectionErrors most_recent_import={state.connection?.most_recent_import} most_recent_sync={state.connection?.most_recent_sync} />
                </div>
            )}
        </>
    );

    const renderKeyValueList = (requiredCredentials: Credential[]) => {
        const readOnly = !canEdit();
        if (readOnly && state.keyValueList.length === 0) return null;

        return (
            <>
                <KeyValueDrawerList
                    readOnly={readOnly}
                    label="Required Credentials"
                    requiredCredentials={requiredCredentials}
                    keyValueList={state.keyValueList}
                    setKeyValueList={(list) => {
                        setState((prev) => ({ ...prev, keyValueList: list }));
                    }}
                    showCredentials={state.showCredentials}
                    setShowCredentials={(show) => {
                        setState((prev) => ({ ...prev, showCredentials: show }));
                    }}
                    errors={state.errors}
                />
                {renderInputError(state.errors, 'kvList')}
            </>
        );
    };

    const canEdit = (): boolean => {
        if ((isEdit() || connectionId) && !UserPermissions().canUpdateConnections) return false;
        if (!isEdit() && !UserPermissions().canCreateConnections) return false;
        return true;
    };

    const getCommonDataFields = () => {
        if (!state.loaded) return {};

        const selectedUser = state.commonData?.user;
        const fields: Record<string, any> = {};

        const userAdvisoryGroupSelectDisabled = vue === VUES.ADVISOR;

        // Connection Name Field
        if (!state.addExistingUser) {
            fields['Connection Name'] = {
                label: (
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        Connection Name
                        {adminOrAdvisorVue && isEdit() && state.connection?._id && (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    fontSize: '10px',
                                }}
                                onClick={() => {
                                    if (state.connection?._id) {
                                        navigator.clipboard.writeText(state.connection._id);
                                        setState((prev) => ({ ...prev, copiedIdToClipboard: true }));
                                        setTimeout(() => {
                                            setState((prev) => ({ ...prev, copiedIdToClipboard: false }));
                                        }, 1000);
                                    }
                                }}
                                title={state.copiedIdToClipboard ? 'Copied!' : 'Copy to clipboard'}
                            >
                                {state.copiedIdToClipboard ? 'Copied!' : state.connection._id}
                            </div>
                        )}
                    </div>
                ),
                placeholder: 'Name',
                fieldName: 'connectionName',
                type: 'text',
            };
        }

        // Account Options
        let accountOptions = state.accounts.map((acc) => ({
            name: acc.name,
            _id: acc._id,
        }));

        // TFA Email Field
        if (isEdit()) {
            fields['Tfa Email'] = {
                label: 'Tfa Email',
                type: 'text',
                fieldName: 'tfaEmail',
                format: (val: string) => val?.toLowerCase?.() || val,
            };
        }

        // User Field
        fields['User'] = {
            label: (
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    {state.ownerType === OwnerType.User ? 'User' : 'Advisory Group'}
                    {!userAdvisoryGroupSelectDisabled && (
                        <div
                            className="a"
                            onClick={() => {
                                setState((prev) => ({
                                    ...prev,
                                    ownerType: state.ownerType === OwnerType.User ? OwnerType.AdvisoryGroup : OwnerType.User,
                                    commonData: { ...prev.commonData, user: null, advisory_group: null, ral_requests: [] },
                                }));
                            }}
                        >
                            Use {state.ownerType === OwnerType.User ? 'Advisory Group' : 'User'} instead
                        </div>
                    )}
                </div>
            ),
            type: 'custom',
            component: (
                <>
                    {state.ownerType === OwnerType.User ? (
                        <>
                            <UserSearchFilter
                                onChange={async (user) => {
                                    // Load new accounts list
                                    let accounts: Account[] = [];
                                    if (user?._id) {
                                        accounts = await api2.paginateApiRoute(async (paginate_params: PaginateParams) => {
                                            return (
                                                await api2.client.AccountApi.listAccounts({
                                                    users: [user._id],
                                                    ...paginate_params,
                                                })
                                            ).data.accounts;
                                        });
                                    }
                                    setState((prev) => ({ ...prev, commonData: { ...prev.commonData, user }, accounts }));
                                }}
                                selected={state.commonData.user}
                                disabled={userAdvisoryGroupSelectDisabled}
                                defaultLabel={'Select User'}
                            />
                            {renderInputError(state.errors, 'user')}
                        </>
                    ) : (
                        <>
                            <AdvisoryGroupSearchFilter
                                onChange={(advisoryGroup) => {
                                    setState((prev) => ({ ...prev, commonData: { ...prev.commonData, advisory_group: advisoryGroup } }));
                                }}
                                selected={state.commonData.advisory_group}
                                disabled={userAdvisoryGroupSelectDisabled}
                                defaultLabel="Select Advisory Group"
                            />
                            {renderInputError(state.errors, 'advisory_group')}
                        </>
                    )}
                </>
            ),
        };

        // Entity Field
        if (state.ownerType === OwnerType.User) {
            fields['Entity'] = {
                label: 'Entity',
                type: 'custom',
                fieldName: 'account',
                component: (
                    <>
                        <AccountSearchFilter
                            filter={{
                                ...(selectedUser?._id ? { users: [selectedUser?._id] } : {}),
                            }}
                            selected={state.commonData.account}
                            onChange={(account) => {
                                setState((prev) => ({
                                    ...prev,
                                    commonData: { ...prev.commonData, account },
                                }));
                            }}
                            defaultOptions={!!selectedUser?._id}
                            defaultLabel="Select Entity"
                        />
                    </>
                ),
            };

            // Entity List Field
            fields['Entity List'] = {
                placeholder: 'Select Entity List',
                label: <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>Other Entity List</div>,
                fieldName: 'account_list',
                type: 'select-multi',
                options: accountOptions.filter((acc) => acc._id !== state.commonData?.account?._id),
                optionClearable: false,
                getLabel: (value: any) => value.name,
            };
        }

        if (adminOrAdvisorVue) {
            // Integration Field
            fields['Integration'] = {
                placeholder: 'Select Integration',
                label: (
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        Integration
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                                checked={state.commonData.requirePhoneNumber}
                                setChecked={(checked: boolean) => {
                                    setState((prev) => ({
                                        ...prev,
                                        commonData: { ...prev.commonData, requirePhoneNumber: checked },
                                    }));
                                }}
                                style={{ width: '15px', height: '15px' }}
                            />
                            <span style={{ marginLeft: '5px' }}>Require Phone Number</span>
                        </div>
                    </div>
                ),
                fieldName: 'integration',
                type: 'select',
                options: state.integrations,
                optionField: 'name',
                optionClearable: false,
                callback: () => {
                    const curKVList = [...state.keyValueList];
                    let nextKVList: KeyValueItem[] = [];

                    curKVList.forEach((curKV) => {
                        if (!curKV.value?.length) return;
                        const nextKV = nextKVList.find((nextKV) => nextKV.key.toLowerCase() === curKV.key.toLowerCase());
                        if (!nextKV) {
                            nextKVList.push(curKV);
                        } else {
                            nextKV.value = curKV.value;
                        }
                    });

                    setState((prev) => ({
                        ...prev,
                        keyValueList: nextKVList,
                        showCredentials: true,
                    }));
                },
            };

            // Admin-specific fields
            if (UserPermissions().access?.admin) {
                fields['Contacts'] = {
                    placeholder: 'Select Contacts',
                    label: 'Contacts',
                    fieldName: 'contacts',
                    type: 'custom',
                    component: (
                        <>
                            <ContactsSelectWithList
                                excludeRoles={true}
                                contactRelations={state.commonData?.contacts}
                                setContacts={(contacts: any) => {
                                    setState((prev) => ({
                                        ...prev,
                                        commonData: { ...prev.commonData, contacts },
                                    }));
                                }}
                            />
                        </>
                    ),
                };

                fields['Asset Manager'] = {
                    label: 'Asset Manager',
                    type: 'custom',
                    component: (
                        <>
                            <ObjectSearchSelectTs
                                placeholder="Asset Manager"
                                selected={state.commonData?.asset_manager}
                                onChange={(newValue: AssetManager | (AssetManager | null)[] | null) => {
                                    const selectedValue = Array.isArray(newValue) ? newValue[0] : newValue;

                                    setState((prev) => ({
                                        ...prev,
                                        commonData: { ...prev.commonData, asset_manager: selectedValue },
                                    }));
                                }}
                                getLabel={(asset_manager: AssetManager | null) => asset_manager?.name}
                                defaultLabel="None"
                                defaultOptions={false}
                                matchProperty="_id"
                                loadOptions={async (inputValue) => {
                                    const inv = await api.get(`/assetmanagers?search=${inputValue}&count=50`);
                                    return inv.results ?? [];
                                }}
                                width="100%"
                            />
                            {renderInputError(state.errors, 'asset_manager')}
                        </>
                    ),
                };
            }

            // Connection Status Fields
            fields['Connection Status'] = {
                placeholder: 'Select Connection Status',
                label: (
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        Connection Status
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                                checked={state.commonData.is_enabled}
                                setChecked={(checked) => {
                                    setState((prev) => ({
                                        ...prev,
                                        commonData: { ...prev.commonData, is_enabled: checked },
                                    }));
                                }}
                                style={{ width: '15px', height: '15px' }}
                            />
                            <span style={{ marginLeft: '5px', marginRight: '5px' }}>Connection Enabled</span>
                            <Checkbox
                                checked={state.commonData.long_running}
                                setChecked={(checked) => {
                                    setState((prev) => ({
                                        ...prev,
                                        commonData: { ...prev.commonData, long_running: checked },
                                    }));
                                }}
                                style={{ width: '15px', height: '15px' }}
                            />
                            <span style={{ marginLeft: '5px' }}>Long Running</span>
                        </div>
                    </div>
                ),
                fieldName: 'status',
                type: 'option',
                options: ['Active', 'Pending', 'Error', 'Under Review'],
                optionClearable: false,
                callback: (value: string) => {
                    setState((prev) => ({
                        ...prev,
                        commonData: {
                            ...prev.commonData,
                            forcedStatusChange: value,
                        },
                    }));
                },
            };

            fields['Connection Outreach Status'] = {
                placeholder: 'Select Connection Outreach Status',
                label: (
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        Connection Outreach Status
                        {UserPermissions().access?.admin && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    checked={state.commonData.expect_documents_over_email}
                                    setChecked={(checked) => {
                                        setState((prev) => ({
                                            ...prev,
                                            commonData: {
                                                ...prev.commonData,
                                                expect_documents_over_email: checked,
                                            },
                                        }));
                                    }}
                                    style={{ width: '15px', height: '15px' }}
                                />
                                <span style={{ marginLeft: '5px' }}>Expect Documents Over Email</span>
                            </div>
                        )}
                    </div>
                ),
                fieldName: 'outreach_status',
                type: 'option',
                options: Object.values(ConnectionOutreachStatus),
                optionClearable: false,
                callback: (value: string) => {
                    setState((prev) => ({
                        ...prev,
                        commonData: {
                            ...prev.commonData,
                            forcedStatusChange: value,
                        },
                    }));
                },
            };

            // RAL Requests Field for Admin Vue
            if (vue === VUES.ADMIN && state.ownerType === OwnerType.User) {
                fields['RAL Requests'] = {
                    placeholder: 'Select RAL Requests',
                    label: 'RAL Requests',
                    type: 'custom',
                    component: (
                        <>
                            <RalRequestSearchFilter
                                filter={{
                                    ...(selectedUser?._id ? { users: [selectedUser?._id] } : {}),
                                }}
                                selected={state.commonData.ral_requests ?? []}
                                onChange={(newValue: RALRequest | RALRequest[]) => {
                                    setState((prev) => ({
                                        ...prev,
                                        commonData: { ...prev.commonData, ral_requests: Array.isArray(newValue) ? newValue : [newValue] },
                                    }));
                                    return;
                                }}
                                width="100%"
                                isMulti={true}
                                defaultOptions={!!selectedUser?._id}
                            />
                            {renderInputError(state.errors, 'ral_requests')}
                        </>
                    ),
                };
            }
        }

        return fields;
    };

    const renderSetSyncedButton = () => {
        const conn = state.connection;
        if (!conn?._id) return null;

        const handleSetSynced = async () => {
            const confirmConnectionCheck = window.confirm(
                'Do you confirm that you have logged into this connection and checked that the last received document in the portal matches the last document received in AltExchange?'
            );

            if (!confirmConnectionCheck) return;

            loadingProp(0, 'setSyncedButton');

            try {
                // mark connection as synced
                const connRes = await api.patch(`/connections/${conn._id}`, {
                    last_connection_at: new Date(),
                });

                if (!connRes) {
                    setDismissableAlert('Error marking connection as synced.', 'error', true);
                    return;
                }

                if (conn._id) {
                    await load(conn._id);
                }

                const adminActionRes = await api.post('/adminactions', {
                    action: 'sync_manual_connection',
                    admin_id: '627c604d9453fca102b63667',
                    connection_id: conn._id,
                });

                if (!adminActionRes) {
                    setDismissableAlert('Error saving Admin Action after syncing connection.', 'error', true);
                    return;
                }

                setDismissableAlert('Connection marked as synced.', 'success', true);
            } catch (error) {
                console.error('Error setting connection as synced:', error);
                setDismissableAlert('Error marking connection as synced.', 'error', true);
            } finally {
                loadingProp(100, 'setSyncedButton');
            }
        };

        return (
            <div
                style={{
                    fontSize: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                }}
            >
                <span className="a" onClick={handleSetSynced} style={{ cursor: 'pointer' }}>
                    Mark as synced
                </span>
                <span>Last sync: {conn.last_connection_at ? formatDate(conn.last_connection_at, true, false, true) : 'Never'}</span>
            </div>
        );
    };

    const isManualConnection = (connection?: Connection): boolean => {
        return Boolean(connection?.integration && typeof connection.integration !== 'string');
    };

    const testConnection = async () => {
        const connection = { ...state.connection };
        connection.status = ConnectionStatus.Connecting;
        api.post(`/connections/${state.connection?._id}/sync`);
        const commonData = { ...state.commonData };
        commonData.status = connection.status;
        setState((prev: State) => {
            const updatedConnection = {
                ...(prev.connection ?? {}),
                ...connection,
                created_at: connection?.created_at ?? '', // Add null coalescing to handle undefined
                updated_at: connection?.updated_at ?? '',
                is_enabled: connection.is_enabled ?? true, // Ensure is_enabled is never undefined
                long_running: connection.long_running ?? false, // Ensure long_running is never undefined
                use_tfa: connection.use_tfa ?? false, // Ensure use_tfa is never undefined
                name: connection.name || '', // Ensure name is never undefined
                status: connection.status || ConnectionStatus.Connecting, // Ensure status is never undefined
                ral_status: connection.ral_status || '', // Ensure ral_status is never undefined
                outreach_status: connection.outreach_status || '', // Ensure outreach_status is never undefined
                require_phone: connection.require_phone ?? false, // Ensure require_phone is never undefined
            };

            return {
                ...prev,
                connection: updatedConnection,
                commonData,
            };
        });
        setTimeout(() => checkSyncTest(), 5000);
    };

    const checkSyncTest = async () => {
        if (state.connection?._id) {
            const res = await api.get(`/connections/${state.connection._id}/`, {
                403: (syncError: any) => setState((prev: State) => ({ ...prev, syncError })),
                406: (syncError: any) => setState((prev: State) => ({ ...prev, syncError })),
                500: (syncError: any) => setState((prev: State) => ({ ...prev, syncError })),
            });
            if (res?.status) {
                setState((prev: State) => {
                    const updatedConnection = {
                        ...(prev.connection ?? {}),
                        status: res.status,
                        name: prev.connection?.name || '',
                        created_at: prev.connection?.created_at || '',
                        updated_at: prev.connection?.updated_at || '',
                        is_enabled: prev.connection?.is_enabled ?? true,
                        long_running: prev.connection?.long_running ?? false,
                        use_tfa: prev.connection?.use_tfa ?? false,
                        ral_status: prev.connection?.ral_status || '',
                        outreach_status: prev.connection?.outreach_status || '',
                        require_phone: prev.connection?.require_phone ?? false,
                    };
                    return {
                        ...prev,
                        connection: updatedConnection,
                        commonData: { ...prev.commonData, status: res.status },
                    };
                });
            }

            if (res?.status === 'Error') {
                console.error('checkSyncTest error:', res.error?.user);
                setState((prev) => ({ ...prev, syncError: res.error?.user }));
            } else if (!['Pending', 'Active', 'Awaiting 2FA'].includes(res?.status)) {
                setTimeout(() => checkSyncTest(), 5000);
            }
        }
    };

    const getBaseUrl = (credentials: Record<string, string> | null): string | null => {
        if (typeof credentials !== 'object' || !credentials) {
            return null;
        }
        const keys = ['url', 'portal url', 'login url'];
        for (const [key, value] of Object.entries(credentials)) {
            if (keys.includes(key.toLowerCase())) {
                return value.trim();
            }
        }
        console.error('URL not found in credentials');
        return null;
    };

    const getUsername = (credentials: Record<string, string> | null): string | null => {
        if (typeof credentials !== 'object' || !credentials) {
            return null;
        }
        const keys = ['email', 'username', 'mail', 'login', 'user'];
        for (const [key, value] of Object.entries(credentials)) {
            if (keys.includes(key.toLowerCase())) {
                return value;
            }
        }
        console.error('Username not found in credentials');
        return null;
    };

    const getPassword = (credentials: Record<string, string> | null): string | null => {
        if (typeof credentials !== 'object' || !credentials) {
            return null;
        }
        const keys = ['password', 'pass'];
        for (const [key, value] of Object.entries(credentials)) {
            if (keys.includes(key.toLowerCase())) {
                return value;
            }
        }
        console.error('Password not found in credentials');
        return null;
    };

    const renderSendCredentialsButton = () => {
        const integration = state.commonData?.integration;
        const autologinConfig = integration?.autologin_config;

        if (
            !UserPermissions()?.access?.admin ||
            !autologinConfig ||
            !autologinConfig.query_selectors ||
            !autologinConfig.query_selectors.username ||
            !autologinConfig.query_selectors.password ||
            !autologinConfig.query_selectors.submit ||
            !state.connection?.credentials
        ) {
            return null;
        }

        let baseUrl = getBaseUrl(state.connection.credentials);
        if (!baseUrl) {
            baseUrl = state.commonData?.integration?.portal_url || null;
            if (!baseUrl) {
                return null;
            }
        }

        return (
            <button
                className="send-credentials-button"
                onClick={() => {
                    const messageData = {
                        credentials: {
                            username: getUsername(state.connection?.credentials ?? null),
                            password: getPassword(state.connection?.credentials ?? null),
                            baseUrl,
                        },
                        authToken: (api.getHeaders() as { Authorization?: string })?.Authorization?.replace('Bearer ', '') || '',
                        // Integration specific information:
                        querySelectors: {
                            usernameSelector: autologinConfig.query_selectors.username,
                            passwordSelector: autologinConfig.query_selectors.password,
                            submitSelector: autologinConfig.query_selectors.submit,
                        },
                        tfaUrl: `${api2.apiUrl}/v2/messages?connections=${state.connection?._id}&limit=1&sort_field=_id&sort_direction=desc`,
                        regex: autologinConfig.tfa_code_regex,
                    };

                    // Add integration URL to credentials if integration exists
                    const integrationUrl = integration.url;
                    if (integrationUrl) {
                        messageData.credentials.baseUrl = integrationUrl;
                    }

                    window.postMessage(
                        {
                            type: 'SEND_CONNECTION_CREDENTIALS',
                            data: messageData,
                        },
                        '*'
                    );
                }}
            >
                Login With Browser Extension
            </button>
        );
    };

    const renderNewCredsButton = () => {
        const alreadyRequested = state.connection?.error?.user === 'Update Credentials Required';
        return (
            <div
                className={alreadyRequested ? '' : 'a'}
                style={{ float: 'left' }}
                onClick={
                    alreadyRequested
                        ? undefined
                        : () => {
                              if (!state.connection) return;

                              const connection: Connection = {
                                  ...state.connection,
                                  status: ConnectionStatus.Error,
                                  error: { user: 'Update Credentials Required' },
                              };

                              const commonData = {
                                  ...state.commonData,
                                  status: 'Error',
                                  error: 'Update Credentials Required',
                              };

                              setState((prev) => ({ ...prev, connection, commonData }));
                          }
                }
            >
                {alreadyRequested ? 'New Credentials Requested' : 'Request New Credentials'}
            </div>
        );
    };

    const renderCopyRequestButton = () => {
        return (
            <div
                className="a"
                style={{ float: 'left' }}
                onClick={async () => {
                    try {
                        const res = await api.post(`/connections/${state.connection?._id}/sync`, {
                            debug: true,
                        });
                        if (res) {
                            // Copy the response request to the clipboard
                            await navigator.clipboard.writeText(JSON.stringify(res.request));
                            setDismissableAlert(setAlert, 'Request copied to clipboard.');
                        } else {
                            setDismissableAlert(setAlert, 'Error copying connection request', true);
                        }
                    } catch (error) {
                        setDismissableAlert(setAlert, 'Error copying connection request', true);
                    }
                }}
            >
                Copy Request
            </div>
        );
    };

    const handleFieldChange = (key: string, value: any) => {
        setState((prev) => ({
            ...prev,
            commonData: {
                ...prev.commonData,
                [key]: value,
            },
        }));
    };

    return (
        <GenericDrawer
            title={''}
            renderHeader={renderHeader}
            getFields={getCommonDataFields}
            close={close}
            onSubmit={createOrSaveConnection}
            actionStr={isEdit() ? 'Save Changes' : 'Create Connection'}
            className="addNewAdvisorToFirm-drawer"
            loading={loadingProp}
            loaded={loaded}
            loadData={load}
            loadingKey="addEditConnectionDrawer"
            formData={state.commonData}
            onFieldChange={handleFieldChange}
            isSafeToSave={isSafeToSave}
            errors={state.errors}
            setErrors={(errors) => {
                setState((prev) => ({ ...prev, errors }));
            }}
            extraContent={() => {
                return (
                    <>
                        <ConnectionOauthSetupButton
                            integration_name={state.commonData?.integration?.name}
                            connection_id={state.connection?._id}
                            keyValueList={state.keyValueList}
                        />
                        {renderSendCredentialsButton()}
                        {state.commonData?.integration && user?.access !== 'advisor' && renderKeyValueList(state.commonData.integration?.required_creds ?? [])}
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {UserPermissions().canUpdateConnections && isEdit() && adminOrAdvisorVue && user?.access !== 'advisor' && renderNewCredsButton()}
                            {UserPermissions().canUpdateConnections && renderSetSyncedButton()}
                            {UserPermissions().canUpdateConnections && isEdit() && adminOrAdvisorVue && user?.access !== 'advisor' && renderCopyRequestButton()}
                        </div>
                    </>
                );
            }}
        />
    );
};

export default AddEditConnectionDrawer;

import React, { useState, useEffect } from 'react';
import { getDateTimeString } from '../../utilities/format/formatDate';
import ReactJson from 'react-json-view';

import '../../styles/viewConnectionErrors.css';
import { ImportData, SyncData } from '../../types/Connection';

interface ViewConnectionErrorsProps {
    most_recent_import?: ImportData;
    most_recent_sync?: SyncData;
}

export const ViewConnectionErrors: React.FC<ViewConnectionErrorsProps> = ({ most_recent_import, most_recent_sync }) => {
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {}, [most_recent_import, most_recent_sync]);

    if (!most_recent_import && !most_recent_sync) {
        return <div className="connectionErrors-component"></div>;
    }

    // Determine which one is most recent
    // const most_recent_type = most_recent_import?.created_at && most_recent_sync?.created_at ? (most_recent_import.created_at > most_recent_sync.created_at ? 'Import' : 'Sync') : '';

    return (
        <>
            <div style={{ marginTop: '10px' }}>
                {most_recent_import && expanded && (
                    <div className="connectionErrors-component">
                        <div className="connectionErrors-item">
                            <div className="connectionErrors-item-header">
                                <span className="connectionErrors-item-type">Import</span>
                                <span className="connectionErrors-item-status">{most_recent_import?.exit_status}</span>
                            </div>
                            <div className="connectionErrors-item-info">
                                <div>ID: {most_recent_import._id}</div>
                                <div>Request ID: {most_recent_import?.job_id}</div>
                                <div>Created: {most_recent_import.created_at ? getDateTimeString(most_recent_import.created_at) : null}</div>
                                <div>Completed: {most_recent_import.completed_at ? getDateTimeString(most_recent_import.completed_at) : null}</div>
                                <div>
                                    Duration:{' '}
                                    {most_recent_import.completed_at && most_recent_import.created_at
                                        ? `${(Number(new Date(Date.parse(most_recent_import.completed_at))) - Number(new Date(Date.parse(most_recent_import.created_at)))) / 1000} seconds`
                                        : 'N/A'}
                                </div>
                                <div>Status: {most_recent_import.exit_status}</div>
                            </div>
                            {most_recent_import?.completion_result && (
                                <div className="connectionErrors-item-result">
                                    <ReactJson src={most_recent_import.completion_result} name={false} displayDataTypes={false} theme={'ashes'} collapsed={true} />
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {most_recent_sync && expanded && (
                    <div className="connectionErrors-item">
                        <div className="connectionErrors-item-header">
                            <span className="connectionErrors-item-type">Sync</span>
                            <span className="connectionErrors-item-status">{most_recent_sync?.exit_status}</span>
                        </div>
                        <div className="connectionErrors-item-info">
                            <div>ID: {most_recent_sync._id}</div>
                            <div>Request ID: {most_recent_sync?.job_id}</div>
                            <div>Created: {most_recent_sync.created_at ? getDateTimeString(most_recent_sync.created_at) : null}</div>
                            <div>Completed: {most_recent_sync.completed_at ? getDateTimeString(most_recent_sync.completed_at) : null}</div>
                            <div>
                                Duration:{' '}
                                {most_recent_sync.completed_at && most_recent_sync.created_at
                                    ? `${(new Date(Date.parse(most_recent_sync.completed_at)).getTime() - new Date(Date.parse(most_recent_sync.created_at)).getTime()) / 1000} seconds`
                                    : 'N/A'}
                            </div>
                            <div>Status: {most_recent_sync.exit_status}</div>
                        </div>
                        {most_recent_sync?.error_report && (
                            <div className="connectionErrors-item-result">
                                <ReactJson src={most_recent_sync.error_report} name={false} displayDataTypes={false} theme={'ashes'} collapsed={true} />
                            </div>
                        )}
                    </div>
                )}
            </div>

            <div className="connectionErrors-controls">
                <a
                    href="#"
                    onClick={() => {
                        setExpanded(!expanded);
                    }}
                >
                    {expanded ? 'Close' : 'Show Recent Jobs'}
                </a>
            </div>
        </>
    );
};

export default ViewConnectionErrors;

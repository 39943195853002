import React, { useState, useCallback, useEffect } from 'react';
import api2 from '../../api2';
import { ConfirmButton } from '../Buttons/ConfirmButton';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import dateToUTCMidnight from '../../utilities/date/dateToUTCMidnight';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import isLastCharZeroOrDot from '../../utilities/string/isLastCharZeroOrDot';
import { JOINT_TRANSACTION, VUES } from '../../constants/constantStrings';
import '../../styles/addEditTransactionDrawer.css';
import JointTransactionDrawerComponent from '../DrawerComponents/JointTransactionDrawerComponent';
import { CURRENCIES, CURRENCY_SYMBOLS } from '../../constants/currencyConstants';
import AuditLogDisplay from '../AuditLogDisplay';
import { AccountSearchFilter, InvestmentSearchFilter, DocumentSearchFilter } from '../Filters/GenericSearchFilter';
import IconButton, { CopyButton } from '../Buttons/IconButton';
import additionalEnums from '../../additionalEnums';
import GenericDrawer from './GenericDrawer';
import { Moment } from 'moment';
import routes from '../../routes';
import { useHistory } from 'react-router-dom';
import { faSave } from '@fortawesome/free-solid-svg-icons';

// Add any required type imports
import type { Investment, Account, User, Document, AuditLog, UserAccess } from '../../types'; // You'll need to create these types if they don't exist

interface Transaction {
    _id: string;
    type: string;
    date: Date;
    amount: number;
    description?: string;
    user?: User | string;
    investment: Investment;
    account: Account;
    document?: Document;
    auto_extracted?: boolean;
    joint_transactions?: Transaction[];
    audit_log?: AuditLog[];
}

interface TransactionFormData {
    investment: Investment | undefined;
    account: Account | null;
    type: TransactionType | null;
    date: Date | Moment | null;
    description: string;
    amount: number | null;
    document: Document | undefined;
    transaction: Transaction | undefined;
}

interface TransactionType {
    transaction_type: string;
    transaction_type_name: string;
    label: string;
}

interface ApiError extends Error {
    response?: {
        data?: {
            message?: string;
            errors?: Record<string, string>;
        };
        status?: number;
    };
}

type LoadingKey =
    | 'addEditTransactionDrawer_fetchTransaction'
    | 'addEditTransactionDrawer_loadTransaction'
    | 'addEditTransactionDrawer_loadDefaultInvestment'
    | 'addEditTransactionDrawer_loadInvestmentsAndAccounts'
    | 'addEditTransactionDrawer_submit'
    | 'addEditTransactionDrawer_delete'
    | 'addEditTransactionDrawer_dissociateDocument'
    | 'addEditTransactionDrawer_addOrUpdateTransaction';

interface AddEditTransactionDrawerProps {
    transactionId?: string;
    defaultInvestment?: Investment;
    isOpen: boolean;
    close: () => void;
    loading: (progress?: number, key?: LoadingKey) => void;
    loaded: (key?: LoadingKey) => void;
    reloadData: () => void;
    setAlert: (message: string, type: 'success' | 'error' | 'warning' | 'info', duration?: number) => void;
    relevantUser?: User;
    vue?: string;
    viewAssociatedDocumentCallback?: () => void;
}

const AddEditTransactionDrawer: React.FC<AddEditTransactionDrawerProps> = ({
    transactionId,
    defaultInvestment,
    isOpen,
    close,
    loading,
    loaded,
    reloadData,
    setAlert,
    relevantUser,
    vue,
    viewAssociatedDocumentCallback,
}) => {
    // State management
    const [transaction, setTransaction] = useState<Transaction | null>(null);
    const [commonData, setCommonData] = useState<TransactionFormData>({
        investment: undefined,
        account: null,
        type: null,
        date: null,
        description: '',
        amount: null,
        document: undefined,
        transaction: undefined,
    });
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [allUserInvestments, setAllUserInvestments] = useState<Investment[]>([]);
    const [allUserAccounts, setAllUserAccounts] = useState<Account[]>([]);
    const [jointTransactionList, setJointTransactionList] = useState<any[]>([]);
    const [allowSelectDocument, setAllowSelectDocument] = useState<boolean>(false);
    const [currency] = useState<keyof typeof CURRENCY_SYMBOLS>(CURRENCIES.USD);

    const history = useHistory();

    // Utility functions
    const getUserId = useCallback(() => {
        return typeof transaction?.user === 'string' ? transaction.user : (transaction?.user?._id ?? relevantUser?._id);
    }, [transaction, relevantUser]);

    const isUpdating = useCallback(() => {
        return !!transaction?._id;
    }, [transaction]);

    const isAutoExtracted = useCallback(
        (tran: Transaction | null = null) => {
            const transaction_to_check = tran ?? transaction;
            return !!transaction_to_check?.document && !!transaction_to_check?.auto_extracted;
        },
        [transaction]
    );

    const canEditTransaction = useCallback(() => {
        if (isAutoExtracted()) return false;
        return UserPermissions().canUpdateTransactions;
    }, [isAutoExtracted]);

    const canCreateTransaction = useCallback(() => {
        return UserPermissions().canCreateTransactions;
    }, []);

    const handleApiError = useCallback(
        (error: ApiError, defaultMessage: string) => {
            console.error('API Error:', error);
            setDismissableAlert(setAlert, error.response?.data?.message || defaultMessage, true);
        },
        [setAlert]
    );

    const handleSuccess = useCallback(
        (message: string) => {
            setDismissableAlert(setAlert, message, true);
        },
        [setAlert]
    );

    const loadInvestments = useCallback(
        async (reload = false) => {
            if (!getUserId()) return [];
            if (!reload && allUserInvestments.length) return allUserInvestments;

            let investments = [];
            try {
                investments = await api2.paginateApiRoute(async (paginate_params: any) => {
                    return (
                        await api2.client.InvestmentApi.listInvestments({
                            users: [getUserId()],
                            ...paginate_params,
                        })
                    ).data.investments;
                });
                setAllUserInvestments(investments);
            } catch (err) {
                console.error('Error loading investments', err);
            }

            return investments;
        },
        [getUserId, allUserInvestments]
    );

    const loadAccounts = useCallback(
        async (reload = false) => {
            if (!getUserId()) return [];
            if (!reload && allUserAccounts.length) return allUserAccounts;

            let accounts = [];
            try {
                accounts = await api2.paginateApiRoute(async (paginate_params: any) => {
                    return (
                        await api2.client.AccountApi.listAccounts({
                            users: [getUserId()],
                            ...paginate_params,
                        })
                    ).data.accounts;
                });
            } catch (err) {
                console.error('Error loading accounts', err);
            }
            setAllUserAccounts(accounts);

            return accounts;
        },
        [getUserId, allUserAccounts]
    );

    const loadDefaultInvestment = useCallback(
        async (defaultInvestment: Investment) => {
            loading(0, 'addEditTransactionDrawer_loadDefaultInvestment');
            const allUserInvestments = await loadInvestments();
            const investment = allUserInvestments.find((inv) => inv._id === defaultInvestment._id);
            const account = (await loadAccounts()).find((acc) => {
                const accountId = defaultInvestment.account;
                if (typeof accountId === 'string') {
                    return acc._id === accountId;
                }
                return acc._id === accountId?._id;
            });
            setCommonData((prev) => ({
                ...prev,
                investment,
                account,
            }));
            loaded('addEditTransactionDrawer_loadDefaultInvestment');
        },
        [loading, loaded, loadInvestments, loadAccounts, commonData]
    );

    const fetchTransaction = useCallback(
        async (transactionId: string): Promise<Transaction | null> => {
            let transaction = null;
            try {
                loading(0, 'addEditTransactionDrawer_fetchTransaction');
                const response = await api2.client.TransactionApi.getTransaction({
                    transaction_id: transactionId,
                    populate_document: true,
                    populate_investment: true,
                    populate_account: true,
                });

                transaction = response.data.transaction;

                if (transaction?.document?._id) {
                    const documentResponse = await api2.client.DocumentApi.getDocument({
                        document_id: transaction.document._id,
                    });
                    transaction.document = documentResponse.data.document;
                }
            } catch (err: any) {
                let errMsg = 'Error: could not load the transaction.';
                if (err.response?.data?.message?.toLowerCase().includes('not found')) {
                    errMsg = `Error: ${err.response.data.message}`;
                }
                console.error(errMsg);
                setDismissableAlert(setAlert, errMsg, true);
            }
            loaded('addEditTransactionDrawer_fetchTransaction');
            return transaction as Transaction | null;
        },
        [loading, loaded, setAlert]
    );

    const loadTransaction = useCallback(
        async (transactionId: string) => {
            const transaction = await fetchTransaction(transactionId);
            if (!transaction) return;

            const type = transaction.type;
            const description = transaction.description;

            const newCommonData: TransactionFormData = {
                investment: transaction.investment,
                account: transaction.account,
                document: transaction.document || undefined,
                date: dateToUTCMidnight(transaction.date) as Date,
                type: additionalEnums.TransactionTypes.typeArr.find((t) => t.transaction_type === type),
                description: description || '',
                amount: transaction.amount,
                transaction: transaction,
            };

            if (transaction.type === 'joint_transaction') {
                const jointTransactions = transaction.joint_transactions ?? [];
                newCommonData.type = additionalEnums.TransactionTypes.typeArr.find((t) => t.transaction_type === JOINT_TRANSACTION);
                setJointTransactionList(jointTransactions);
            } else {
                newCommonData.amount = transaction.amount;
                setJointTransactionList([]);
            }

            setTransaction(transaction);
            setCommonData(newCommonData);
        },
        [fetchTransaction]
    );

    const addOrUpdateTransaction = async () => {
        // check field validity and set errors
        if (!validateForm()) return;
        loading(0, 'addEditTransactionDrawer_addOrUpdateTransaction');

        // deep copy commonData
        let commonDataCopy = JSON.parse(JSON.stringify(commonData));
        makeDataCorrections(commonDataCopy, true);

        let type = commonDataCopy.type;

        let corrections = {
            investment: commonDataCopy.investment._id,
            type: commonDataCopy.type.transaction_type,
            account: commonDataCopy.account._id,
            document: commonDataCopy.document?._id || null,
        };

        let reqBody = {
            ...commonDataCopy,
            ...corrections,
            allowBaseDataUpdates: true,
            date: commonDataCopy.date instanceof Date ? commonDataCopy.date.toISOString().split('T')[0] : commonDataCopy.date,
        };

        // set user if admin or advisor
        if (vue === VUES.ADMIN || vue === VUES.ADVISOR) {
            reqBody.user = getUserId();
        }

        let jointTranList = [...(jointTransactionList ?? [])].map((jt) => ({
            ...jt,
            ...reqBody,
            joint_transaction: transaction?._id,
            amount: jt.amount,
            type: jt.type,
        }));

        // Delete removed joint transactions
        const deletedJointTranIds: string[] = [];
        transaction?.joint_transactions?.forEach((jt) => {
            if (!jointTranList.find((jt2) => jt2._id === jt._id)) {
                deletedJointTranIds.push(jt._id);
            }
        });

        try {
            await Promise.all(
                deletedJointTranIds.map(async (id) => {
                    await api2.client.TransactionApi.deleteTransaction({
                        transaction_id: id,
                    });
                })
            );
        } catch (err) {
            setDismissableAlert(setAlert, 'Error deleting joint transaction.', true);
            loaded('addEditTransactionDrawer_addOrUpdateTransaction');
            return;
        }

        let res: Transaction | null = null;

        if (isNetTransaction(type)) {
            if (isUpdating()) {
                const parentTranId = transaction!._id;

                // update parent joint transaction
                try {
                    await api2.client.TransactionApi.updateTransaction({
                        transaction_id: parentTranId,
                        UpdateTransactionRequest: {
                            ...reqBody,
                            type: JOINT_TRANSACTION,
                            amount: 0,
                        },
                    });
                    res = await fetchTransaction(parentTranId);
                } catch (err: any) {
                    setErrors({ submit: err?.message ?? 'Transaction Data Error' });
                    loaded('addEditTransactionDrawer_addOrUpdateTransaction');
                    setDismissableAlert(setAlert, 'Error updating joint transaction.', true);
                    return;
                }

                // update or create joint transactions
                const allRes = await Promise.all(
                    jointTranList.map(async (jt) => {
                        jt.joint_transaction = parentTranId;
                        if (jt._id) {
                            try {
                                await api2.client.TransactionApi.updateTransaction({
                                    transaction_id: jt._id,
                                    UpdateTransactionRequest: jt,
                                });
                                return await fetchTransaction(jt._id);
                            } catch {
                                return null;
                            }
                        } else {
                            try {
                                const createRes = await api2.client.TransactionApi.createTransaction({
                                    CreateTransactionRequest: {
                                        ...jt,
                                        date: jt.date instanceof Date ? jt.date.toISOString().split('T')[0] : jt.date,
                                    },
                                });
                                if (!createRes.data.transaction_id) return null;
                                return await fetchTransaction(createRes.data.transaction_id);
                            } catch {
                                return null;
                            }
                        }
                    })
                );
                allRes.push(res);

                if (allRes.filter((r) => r).length === jointTranList.length + 1) {
                    setDismissableAlert(setAlert, 'Joint Transaction has been updated.');
                    reloadData();
                    setTransaction(res);
                } else {
                    setDismissableAlert(setAlert, 'Error updating joint transaction.', true);
                    reloadData();
                }
            } else {
                // Create new joint transaction
                let jointTransaction = null;
                try {
                    const createRes = await api2.client.TransactionApi.createTransaction({
                        CreateTransactionRequest: {
                            ...reqBody,
                            type: JOINT_TRANSACTION,
                            amount: 0,
                        },
                    });
                    if (!createRes.data.transaction_id) throw new Error('No transaction ID returned');
                    jointTransaction = await fetchTransaction(createRes.data.transaction_id);
                } catch (err) {
                    handleApiError(err as ApiError, 'Failed to create joint transaction');
                    loaded('addEditTransactionDrawer_addOrUpdateTransaction');
                    return;
                }

                if (!jointTransaction) {
                    handleApiError(new Error('Failed to create joint transaction'), 'Failed to create joint transaction');
                    loaded('addEditTransactionDrawer_addOrUpdateTransaction');
                    return;
                }

                const jointId = jointTransaction._id;
                let createdJointTranIds = [jointId];

                // Create child transactions
                for (let i = 0; i < jointTranList.length; i++) {
                    const tranData = jointTranList[i];
                    if (!tranData || !isFinite(tranData.amount) || tranData.amount === 0) continue;

                    tranData.joint_transaction = jointId;
                    try {
                        const newTransactionId = (
                            await api2.client.TransactionApi.createTransaction({
                                CreateTransactionRequest: {
                                    ...tranData,
                                    date: tranData.date instanceof Date ? tranData.date.toISOString().split('T')[0] : tranData.date,
                                },
                            })
                        ).data.transaction_id;
                        if (newTransactionId) {
                            createdJointTranIds.push(newTransactionId);
                            jointTranList[i]._id = newTransactionId;
                        }
                    } catch (err) {
                        // Cleanup on error
                        await Promise.all(
                            createdJointTranIds.map((id) =>
                                api2.client.TransactionApi.deleteTransaction({
                                    transaction_id: id,
                                })
                            )
                        );
                        handleApiError(err as ApiError, 'Failed to create joint transaction components');
                        loaded('addEditTransactionDrawer_addOrUpdateTransaction');
                        return;
                    }
                }

                setDismissableAlert(setAlert, 'Joint Transaction has been created.');
                reloadData();

                try {
                    const jointTran = await fetchTransaction(jointId);
                    setTransaction(jointTran);
                } catch (err) {
                    console.error('Error fetching joint transaction:', err);
                }
            }
        } else {
            if (isUpdating()) {
                // Update single transaction
                try {
                    await api2.client.TransactionApi.updateTransaction({
                        transaction_id: transaction!._id,
                        UpdateTransactionRequest: reqBody,
                    });
                    res = await fetchTransaction(transaction!._id);

                    setDismissableAlert(setAlert, 'Transaction has been updated.');
                    reloadData();
                    setTransaction(res);
                } catch (err) {
                    handleApiError(err as ApiError, 'Failed to update transaction');
                    loaded('addEditTransactionDrawer_addOrUpdateTransaction');
                    return;
                }
            } else {
                // Create single transaction
                try {
                    const createRes = await api2.client.TransactionApi.createTransaction({
                        CreateTransactionRequest: reqBody,
                    });
                    if (!createRes.data.transaction_id) {
                        throw new Error('No transaction ID returned from create');
                    }
                    res = await fetchTransaction(createRes.data.transaction_id);

                    setDismissableAlert(setAlert, 'Transaction has been created.');
                    reloadData();
                    setTransaction(res);
                    close();
                } catch (err) {
                    handleApiError(err as ApiError, 'Failed to create transaction');
                    loaded('addEditTransactionDrawer_addOrUpdateTransaction');
                    return;
                }
            }
        }

        loaded('addEditTransactionDrawer_addOrUpdateTransaction');
        close();
    };

    const deleteTransaction = async () => {
        if (!transaction?._id) return;

        await withLoading('addEditTransactionDrawer_delete', async () => {
            await api2.client.TransactionApi.deleteTransaction({
                transaction_id: transaction._id,
            });
            handleSuccess('Transaction deleted successfully.');
            reloadData();
            close();
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const renderAddRemoveDocumentSection = (tran: Transaction | null) => {
        if (!tran) return <></>;

        if (!allowSelectDocument && !tran?.document) {
            return (
                <div className="a" onClick={() => setAllowSelectDocument(true)}>
                    Associate with a document
                </div>
            );
        } else {
            return (
                <>
                    {renderDocumentSelect(tran)}

                    {/* don't allow deletion of transactions that have an attached document */}
                    {/* if this is changed, make sure to still not allow transactions to be deleted if their associated document is a Capital Account Statement */}
                    {transaction?.document && renderEditAssociatedDocumentSection(transaction)}

                    <div style={{ clear: 'both', height: '10px' }} />

                    {renderDissociateButton(tran)}
                </>
            );
        }
    };

    const getCommonDataFields = () => {
        const fields: Record<string, any> = {
            Entity: {
                label: 'Entity',
                type: 'custom',
                fieldName: 'account',
                component: () => (
                    <AccountSearchFilter
                        filter={{
                            ...(getUserId() ? { users: [getUserId()] } : {}),
                        }}
                        selected={commonData.account}
                        onChange={(account) => {
                            setCommonData((prev) => ({
                                ...prev,
                                account,
                            }));
                        }}
                        defaultOptions={!!getUserId()}
                    />
                ),
            },
            Investment: {
                label: 'Investment',
                type: 'custom',
                fieldName: 'investment',
                component: () => (
                    <InvestmentSearchFilter
                        filter={{
                            ...(getUserId() ? { users: [getUserId()] } : {}),
                            ...(commonData.account ? { accounts: [commonData.account._id] } : {}),
                        }}
                        selected={commonData.investment}
                        onChange={(investment) => {
                            setCommonData((prev) => ({
                                ...prev,
                                investment: investment as Investment,
                            }));
                            if (!Array.isArray(investment) && investment?.account) {
                                setCommonData((prev) => ({
                                    ...prev,
                                    account: investment.account as Account,
                                }));
                            } else {
                                setCommonData((prev) => ({
                                    ...prev,
                                    account: null,
                                }));
                            }
                        }}
                        defaultOptions={!!getUserId()}
                    />
                ),
            },
            'Transaction Type': {
                placeholder: 'Select Transaction Type',
                label: 'Transaction Type',
                fieldName: 'type',
                type: 'select',
                options: additionalEnums.TransactionTypes.typeArr,
                optionField: 'transaction_type_name',
                optionClearable: true,
            },
            'Date Posted': {
                label: 'Date Posted',
                fieldName: 'date',
                type: 'date',
            },
            Description: {
                label: 'Description (optional)',
                fieldName: 'description',
                type: 'text',
                placeholder: 'Enter an optional description',
                renderRightComponent:
                    !transaction?.document && vue === VUES.ADMIN
                        ? null
                        : () => {
                              return <IconButton faIcon={faSave} title="Save description only" onClick={saveDescriptionOnly} />;
                          },
            },
        };

        if (isNetTransaction(commonData?.type)) {
            fields['Joint Transaction'] = {
                type: 'custom',
                component: () => (
                    <>
                        <JointTransactionDrawerComponent
                            currency={commonData?.investment?.currency ?? CURRENCIES.USD}
                            jointTransactionList={jointTransactionList ?? []}
                            setJointTransactionList={(data) => {
                                setJointTransactionList(data);
                                validateForm();
                            }}
                            setAlert={(message, isError) => {
                                setAlert(message, isError ? 'error' : 'success');
                            }}
                        />
                        {errors?.[JOINT_TRANSACTION] && <div className="error-text">{errors[JOINT_TRANSACTION]}</div>}
                    </>
                ),
            };
        } else {
            fields['Amount'] = {
                placeholder: `Eg: ${CURRENCY_SYMBOLS[currency]}X,XXX,XXX`,
                label: 'Amount',
                fieldName: 'amount',
                type: 'currency',
                currency: commonData?.investment?.currency ?? CURRENCIES.EUR,
            };
        }

        return fields;
    };

    const isNetTransaction = useCallback((type: any) => {
        return type?.transaction_type === JOINT_TRANSACTION;
    }, []);

    const renderHeader = useCallback(() => {
        return (
            <>
                <h1
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    {isUpdating() ? 'Edit Transaction Values' : 'Add New Transaction'}

                    <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '16px' }}>
                        {transaction?._id && vue === VUES.ADMIN && <CopyButton text={transaction._id} />}
                    </div>
                </h1>
            </>
        );
    }, [isUpdating, transaction, vue]);

    const withLoading = useCallback(
        async (key: string, fn: () => Promise<void>) => {
            loading(0, key as LoadingKey);
            try {
                await fn();
            } finally {
                loaded(key as LoadingKey);
            }
        },
        [loading, loaded]
    );

    const validateForm = useCallback(() => {
        const errors: Record<string, string> = {};

        // base type check
        if (!commonData.type || !additionalEnums.TransactionTypes.typeArr?.find((t) => t.transaction_type_name === commonData.type?.transaction_type_name)) {
            errors['type'] = 'A valid transaction type is required.';
        }
        if (!commonData.investment) {
            errors['investment'] = 'This field is required.';
        }
        if (!commonData.account) {
            errors['account'] = 'This field is required.';
        }
        if (!commonData.date) {
            errors['date'] = 'This field is required.';
        }

        if (isNetTransaction(commonData.type)) {
            const uniqueJointTranTypes = jointTransactionList?.map((jt) => jt.type) ?? [];
            const uniqueTypes = uniqueJointTranTypes.filter((v, i, arr) => arr.indexOf(v) === i);

            // need at least 2 unique transaction types for a joint transaction
            if (uniqueTypes.length < 2) {
                errors[JOINT_TRANSACTION] = 'At least two unique transaction types are required for a joint transaction.';
            }

            // ensure no duplicate transaction types
            if (uniqueTypes.length !== jointTransactionList.length) {
                errors[JOINT_TRANSACTION] = 'Duplicate transaction types are not allowed.';
            }

            // ensure all amounts are non zero
            if (jointTransactionList.some((jt) => jt.amount === 0)) {
                errors[JOINT_TRANSACTION] = 'All transaction amounts must be non-zero.';
            }

            // ensure all types are present
            if (jointTransactionList.some((jt) => !jt.type)) {
                errors[JOINT_TRANSACTION] = 'All transactions must have a type.';
            }
        } else {
            if (!commonData.amount) {
                errors['amount'] = 'This field is required.';
            } else if (commonData.amount && isNaN(commonData.amount)) {
                errors['amount'] = 'This field must be a valid number.';
            }
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    }, [commonData, jointTransactionList, isNetTransaction, setErrors]);

    const renderExtraContent = useCallback(() => {
        return (
            <>
                {/* if creating a doc transaction doesn't already have a doc */}
                {commonData.investment && renderAddRemoveDocumentSection(transaction)}
            </>
        );
    }, [commonData.investment, transaction, renderAddRemoveDocumentSection]);

    const renderAuditLogs = useCallback(() => {
        const transaction_id = transaction?._id;
        if (transaction_id && UserPermissions().access.admin) {
            return (
                <AuditLogDisplay
                    fetchLogs={async () => {
                        if (!transaction_id) return [];
                        try {
                            const audit_log = (
                                await api2.client.TransactionApi.getTransaction({
                                    transaction_id: transaction_id,
                                    populate_audit_log: true,
                                })
                            ).data.transaction?.audit_log;
                            return audit_log ?? [];
                        } catch (err) {
                            console.error('Error loading audit logs:', err);
                            return [];
                        }
                    }}
                />
            );
        }
        return null;
    }, [transaction?._id]);
    // Initial load effect
    // Add this initialization logic
    useEffect(() => {
        const initializeDrawer = async () => {
            if (transactionId) {
                await loadTransaction(transactionId);
            } else if (defaultInvestment) {
                await loadDefaultInvestment(defaultInvestment);
            } else {
                loading(0, 'addEditTransactionDrawer_loadInvestmentsAndAccounts');
                await loadInvestments();
                await loadAccounts();
                loaded('addEditTransactionDrawer_loadInvestmentsAndAccounts');
            }
        };

        initializeDrawer();
    }, [transactionId, defaultInvestment]);
    const makeDataCorrections = useCallback((data: TransactionFormData, isFinalUpdate = false) => {
        // Make sure argument is not commonData directly, as this will not trigger update.
        const docType = data.type?.transaction_type;
        if (!data || !docType) return;

        // ensure numbers are numbers
        if (isFinalUpdate || !isLastCharZeroOrDot(data.amount?.toString())) {
            if (data.amount !== null) {
                data.amount = Number(data.amount);
            }

            data.date = dateToUTCMidnight(data.date as Date);

            // set capital call open status by date
            if (['capital_call'].includes(docType)) {
                (data as any).open = false;
                if (data.date && data.date > dateToUTCMidnight()) {
                    (data as any).open = true;
                }
            }
        }

        setCommonData(data);
    }, []);

    const renderDocumentSelect = (tran: Transaction | null) => {
        return (
            <DocumentSearchFilter
                defaultLabel="None"
                isDisabled={!!tran?.document && !!tran?.auto_extracted}
                filter={{
                    ownership_type: 'Investment',
                    ownership_ids: [commonData.investment?._id],
                }}
                selected={commonData.document}
                onChange={(document: Document | Document[]) => {
                    setCommonData((prev) => ({
                        ...prev,
                        document: Array.isArray(document) ? document[0] : document,
                    }));
                    setAllowSelectDocument(false);
                }}
                width="100%"
                defaultOptions={true}
                noDefaultOption={true}
                excludeDisplayFields={['user', 'ownership']}
                vue={vue}
                linkDocument={true}
            />
        );
    };

    const renderEditAssociatedDocumentSection = (tran: Transaction) => {
        if (!tran?.document) return <></>;

        let msg = '';
        if (tran.auto_extracted) {
            msg =
                'This transaction cannot be updated or deleted directly because it was extracted automatically from the attached document. ' +
                'Please modify the associated document to update or delete this transaction.';
        }

        // get url params to add to current path
        const urlParams = new URLSearchParams(window.location.search);
        const params: Record<string, string> = {};
        Array.from(urlParams.entries()).forEach(([key, value]) => {
            params[key] = value;
        });
        const urlParamsStr = Object.keys(params).length
            ? '?' +
              Object.keys(params)
                  .map((k) => `${k}=${params[k]}`)
                  .join('&')
            : '';

        const currentUrlPath = window.location.pathname + urlParamsStr;

        const clientId = typeof transaction?.user === 'string' ? transaction.user : transaction?.user?._id;

        return (
            <div style={{ fontSize: '16px', color: 'var(--color-dark-red)' }}>
                <br />
                {msg}
                <div
                    className="a"
                    style={{ marginTop: '10px' }}
                    onClick={() => {
                        const pathname = routes.client.documents({
                            access: vue as UserAccess,
                            userId: clientId as string,
                            accountId: commonData.account?._id,
                            investmentId: commonData.investment?._id,
                        });

                        const docId = transaction?.document?._id;

                        if (!pathname || !docId) return;

                        history.push({
                            pathname,
                            state: {
                                editDocument: docId,
                                goBackPath: currentUrlPath,
                            },
                        });

                        viewAssociatedDocumentCallback?.();
                    }}
                >
                    Edit associated document
                </div>
            </div>
        );
    };

    const renderDissociateButton = (tran: Transaction | null) => {
        if (!tran?.document) return null;

        return (
            <ConfirmButton
                color="var(--color-dark-red)"
                text="Disconnect associated document"
                onClick={async () => {
                    try {
                        await api2.client.TransactionApi.updateTransaction({
                            transaction_id: tran._id,
                            UpdateTransactionRequest: {
                                document: undefined,
                            },
                        });
                        await loadTransaction(tran._id);
                        setDismissableAlert(setAlert, 'Transaction disconnected from document.', true);
                        reloadData();
                    } catch (err) {
                        setDismissableAlert(setAlert, 'Error disconnecting document.', true);
                    }
                }}
            />
        );
    };

    const saveDescriptionOnly = async () => {
        if (!isAutoExtracted()) return;

        const description = commonData.description;
        const transactionType = commonData.type?.transaction_type;
        const document_id = commonData.document?._id;

        loading(0, 'addEditTransactionDrawer_saveDescriptionOnly' as LoadingKey);

        try {
            await api2.client.DocumentApi.updateDocument({
                document_id: document_id as string,
                UpdateDocumentRequest: {
                    transaction_descriptions_diff: {
                        [transactionType as string]: description,
                    },
                },
            });
            setDismissableAlert(setAlert, 'Description saved successfully', false);
        } catch {
            setDismissableAlert(setAlert, 'Error saving description', true);
        }

        loaded('addEditTransactionDrawer_saveDescriptionOnly' as LoadingKey);
    };

    const handleFieldChange = (fieldName: string, value: any) => {
        setCommonData((prev) => ({
            ...prev,
            [fieldName]: value,
        }));

        // Validate form after field change
        validateForm();

        // Special handling for investment and account relationships
        if (fieldName === 'investment' && !Array.isArray(value) && value?.account) {
            setCommonData((prev) => ({
                ...prev,
                account: value.account,
            }));
        } else if (fieldName === 'account') {
            // If account changes, we may need to filter available investments
            if (!value) {
                setCommonData((prev) => ({
                    ...prev,
                    investment: undefined,
                }));
            }
        }

        // Special handling for transaction type changes
        if (fieldName === 'type' && value?.transaction_type === JOINT_TRANSACTION) {
            // Reset amount when switching to joint transaction
            setCommonData((prev) => ({
                ...prev,
                amount: null,
            }));
        }
    };
    return (
        <GenericDrawer
            close={close}
            renderHeader={renderHeader}
            getFields={getCommonDataFields}
            errors={errors}
            onSubmit={addOrUpdateTransaction}
            readOnly={!canEditTransaction() && !canCreateTransaction()}
            extraContent={renderExtraContent}
            loading={(progress?: number, key?: string) => loading(progress, key as LoadingKey)}
            loaded={(key?: string) => loaded(key as LoadingKey)}
            setErrors={setErrors}
            loadingKey="addEditTransactionDrawer"
            actionStr={isUpdating() ? 'Update' : 'Add'}
            title=""
            onDelete={deleteTransaction}
            className="addNewAdvisorGroup-drawer"
            formData={commonData}
            onFieldChange={handleFieldChange}
        >
            {renderAuditLogs()}
        </GenericDrawer>
    );
};

export default AddEditTransactionDrawer;

import React, { useCallback, useEffect, useState } from 'react';
import validator from 'validator';

import KeyValueDrawerList from '../DrawerComponents/KeyValueDrawerList';
import { ObjectFilterSelect } from '../Dropdowns/ObjectFilterSelect';
import { UserSearchFilter, DocumentSearchFilter } from '../Filters/GenericSearchFilter';
import { CopyButton } from '../Buttons/IconButton';
import GenericDrawer from './GenericDrawer';

import api from '../../api';
import api2 from '../../api2';
import { VUES } from '../../constants/constantStrings';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import { DocumentOwnershipType } from '../../constants/documentConstants';
import {
    CreateRALRequestRequestOwnershipTypeEnum,
    DocumentSigningRemoteOptions,
    ListRALRequests200ResponseRalRequestsInner,
    ListRALRequestTemplates200Response,
    UpdateRALRequestRequestOwnershipTypeEnum,
} from '../../openApiClient';
import { PANDA_RAL_STATUSES_WITH_URL } from '../../types/RalRequest';
import RalSignatoryListEditor, { generateSignatoriesFromRequiredSignatories, mergeAndGenerateSignatories } from '../DrawerComponents/RalSignatoryListEditor';
import '../../styles/addEditRalRequestDrawer.css';
import { capitalizeFirstEveryWord } from '../../utilities/format/capitalizeFirst';
import { ConfirmButton } from '../Buttons/ConfirmButton';
import { copyToClipboard } from '../../utilities/string/copyToClipboard';
import ObjectSearchSelectTs from '../Dropdowns/ObjectSearchSelectTs';
import { getDocumentOwnershipFields } from '../../common/documents/drawer/ownershipFields';

interface AddEditRalRequestDrawerProps {
    ralRequestId?: string;
    isOpen: boolean;
    close: () => void;
    loading: (time?: number, key?: string) => void;
    loaded: (key?: string) => void;
    reloadData: () => void;
    setAlert?: (message: string, type: string) => void;
}

interface CommonData {
    user: {
        _id: string;
        [key: string]: any;
    };
    ownership_type: string | null;
    ownership_ids?: Array<{ _id: string; [key: string]: any }>;
    ral_template: {
        _id: string;
        required_tokens?: string[];
        required_signatories?: Array<{ role: string }>;
        [key: string]: any;
    };
    tokens: Array<{ key: string; value: string }>;
    signatories: Array<{
        role: string;
        first_name: string;
        last_name: string;
        email: string;
    }>;
}

interface CreateUpdateRequestBody {
    user: string;
    ownership_type: string | null;
    ownership_ids: string[] | null;
    ral_request_template: string;
    tokens: Record<string, string>;
    signatories: Array<{
        role: string;
        first_name: string;
        last_name: string;
        email: string;
    }>;
    signed_ral?: string;
}

interface User {
    name?: string;
    email?: string;
    _id: string;
    [key: string]: any;
}

interface RALRequestSignatory {
    role: string;
    first_name: string;
    last_name: string;
    email: string;
}

interface RALRequestTemplate {
    _id: string;
    required_signatories: Array<{ role: string }>;
    required_tokens: string[];
    [key: string]: any;
}

interface RALRequestUser {
    _id: string;
    name?: string;
    email?: string;
    [key: string]: any;
}

interface RALRequest {
    _id: string;
    user: RALRequestUser;
    ownership_type: string | null;
    ownership_ids: Array<{ _id: string }>;
    ral_request_template: RALRequestTemplate;
    tokens: Record<string, string>;
    signatories: RALRequestSignatory[];
}

const AddEditRalRequestDrawer: React.FC<AddEditRalRequestDrawerProps> = ({ ralRequestId, isOpen, close, loading, loaded, reloadData, setAlert }) => {
    const [ralRequest, setRalRequest] = useState<ListRALRequests200ResponseRalRequestsInner | null>(null);
    const [ralRequestTemplates, setRalRequestTemplates] = useState<ListRALRequestTemplates200Response[]>([]);
    const [useExistingDocument, setUseExistingDocument] = useState(false);
    const [existingDocument, setExistingDocument] = useState<any>(null);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [sentRalUrls, setSentRalUrls] = useState<
        Array<{
            signing_url: string;
            role: string;
            first_name: string;
            last_name: string;
            email: string;
        }>
    >([]);
    const [commonData, setCommonData] = useState<CommonData>({} as CommonData);
    const [selectAdvisorSignatory, setSelectAdvisorSignatory] = useState(false);

    const isUpdating = useCallback(() => !!ralRequest || !!ralRequestId, [ralRequest, ralRequestId]);

    const sendRalToSign = async (send_method: DocumentSigningRemoteOptions, send_silent = false) => {
        const ral_request_id = ralRequest?._id;
        if (!ral_request_id) return;

        loading(0, 'ralRequestDrawer_sendRalRequest');
        try {
            await api2.client.RALRequestApi.sendRALRequest({
                SendRALRequestRequest: {
                    ral_request_ids: [ral_request_id],
                    send_method,
                    ...(send_method === DocumentSigningRemoteOptions.Pandadoc ? { send_silent } : {}),
                },
            });
            await reloadData();
            await loadRalRequest(ral_request_id);
            setDismissableAlert(setAlert, 'RAL Request sent successfully.', false);
        } catch (e) {
            console.error('Error sending RAL Request', e);
            setDismissableAlert(setAlert, 'Failed to send RAL Request', true);
        } finally {
            loaded('ralRequestDrawer_sendRalRequest');
        }
    };

    const createUpdateRalRequest = async () => {
        if (!isSafeToSave()) return;

        loading(0, 'ralRequestDrawer_createUpdateRalRequest');
        try {
            const commonDataCopy: CommonData = JSON.parse(JSON.stringify(commonData));
            cleanDataForSave(commonDataCopy);

            const ownership_ids = commonDataCopy.ownership_ids?.map((id) => id._id);

            const requestBody: CreateUpdateRequestBody = {
                user: commonDataCopy.user._id,
                ownership_type: commonDataCopy.ownership_type || null,
                ownership_ids: ownership_ids?.length ? ownership_ids : null,
                ral_request_template: commonDataCopy.ral_template._id,
                tokens: commonDataCopy.tokens.reduce<Record<string, string>>((acc, rt) => {
                    acc[rt.key] = rt.value;
                    return acc;
                }, {}),
                signatories: commonDataCopy.signatories.map((signatory) => ({
                    role: signatory.role,
                    first_name: signatory.first_name,
                    last_name: signatory.last_name,
                    email: signatory.email,
                })),
            };

            if (isUpdating()) {
                await api2.client.RALRequestApi.updateRALRequest({
                    ral_request_id: ralRequest?._id || '',
                    UpdateRALRequestRequest: {
                        ...requestBody,
                        ownership_ids: requestBody.ownership_ids || undefined,
                        ownership_type: requestBody.ownership_type as UpdateRALRequestRequestOwnershipTypeEnum | null,
                    },
                });
                setDismissableAlert(setAlert, 'RAL request updated successfully.', false);
            } else {
                const createRequestBody: CreateUpdateRequestBody = {
                    ...requestBody,
                    ...(useExistingDocument && existingDocument ? { signed_ral: existingDocument._id } : {}),
                };
                const response = await api2.client.RALRequestApi.createRALRequest({
                    CreateRALRequestRequest: {
                        ...createRequestBody,
                        ownership_ids: createRequestBody.ownership_ids || undefined,
                        ownership_type: createRequestBody.ownership_type as CreateRALRequestRequestOwnershipTypeEnum | null,
                    },
                });

                const ral_request_id = response.data.ral_request_id;
                if (!ral_request_id) throw new Error('No RAL request ID returned');
                const ralRequestResponse = await api2.client.RALRequestApi.getRALRequest({ ral_request_id });
                // Convert ListRALRequests200ResponseRalRequestsInner to RALRequest
                const ralRequest = ralRequestResponse.data.ral_request
                    ? {
                          ...ralRequestResponse.data.ral_request,
                          ral_template: commonDataCopy.ral_template, // Add required ral_template property
                          _id: ralRequestResponse.data.ral_request._id,
                          user: ralRequestResponse.data.ral_request.user,
                      }
                    : null;
                setRalRequest(ralRequest);
                setDismissableAlert(setAlert, 'RAL request created successfully.', false);
            }

            reloadData();
        } catch (e) {
            console.error(`Error ${isUpdating() ? 'updating' : 'creating'} RAL request`, e);
            setDismissableAlert(setAlert, `Error ${isUpdating() ? 'updating' : 'creating'} RAL request.`, true);
        } finally {
            loaded('ralRequestDrawer_createUpdateRalRequest');
        }
    };

    const renderStatus = () => {
        if (!ralRequest) return null;

        const sentAt = ralRequest.sent_at;
        const signedAt = ralRequest.signed_at;
        const remote_type = ralRequest.remote_type;
        const showGetSigningUrl =
            String(ralRequest.remote_type) === String(DocumentSigningRemoteOptions.Pandadoc) &&
            PANDA_RAL_STATUSES_WITH_URL.includes(ralRequest.remote_status as 'sent' | 'viewed' | 'completed' | 'declined');

        return (
            <div style={{ fontSize: '14px', textAlign: 'left' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>Status: {capitalizeFirstEveryWord(ralRequest.remote_status)}</span>
                    {!sentAt && (
                        <>
                            <ConfirmButton onClick={() => sendRalToSign(DocumentSigningRemoteOptions.Docusign)} text={'Docusign Send'} />
                            <ConfirmButton onClick={() => sendRalToSign(DocumentSigningRemoteOptions.Pandadoc)} text={'PandaDoc Send'} />
                            <ConfirmButton onClick={() => sendRalToSign(DocumentSigningRemoteOptions.Pandadoc, true)} text={'PandaDoc Send Silent'} />
                        </>
                    )}
                </div>

                {sentAt && !signedAt && remote_type === DocumentSigningRemoteOptions.Pandadoc && (
                    <>
                        <div style={{ fontSize: '14px' }}>Sent at: {new Date(sentAt).toLocaleString()}</div>
                        {!showGetSigningUrl ? (
                            <span>URL not available. Invalid status.</span>
                        ) : sentRalUrls?.length > 0 ? (
                            sentRalUrls.map((urlObj, i) => (
                                <>
                                    <br />
                                    <div>Signing URLs:</div>
                                    <div
                                        key={i}
                                        className="a table-ellipses"
                                        onClick={() => copyToClipboard(urlObj.signing_url)}
                                        title={`Copy URL for ${urlObj.role} ${urlObj.first_name} ${urlObj.last_name} (${urlObj.email}) (${urlObj.signing_url})`}
                                    >
                                        {urlObj.role}: {urlObj.first_name} {urlObj.last_name} {urlObj.email}
                                    </div>
                                </>
                            ))
                        ) : (
                            <span
                                className="a"
                                onClick={async () => {
                                    try {
                                        const urlRes = await api2.client.RALRequestApi.generatePandaDocSigningUrls({
                                            ral_request_ids: [ralRequest?._id || ''],
                                        });
                                        const newSentRalUrls = urlRes.data?.data?.[ralRequest?._id || ''];
                                        if (!newSentRalUrls) {
                                            throw new Error('No signing URLs returned');
                                        }
                                        // Type assertion since we know the shape matches
                                        setSentRalUrls(
                                            newSentRalUrls as {
                                                signing_url: string;
                                                role: string;
                                                first_name: string;
                                                last_name: string;
                                                email: string;
                                            }[]
                                        );
                                    } catch (e) {
                                        console.error('Error generating PandaDoc signing URL', e);
                                        setDismissableAlert(setAlert, 'Error generating PandaDoc signing URL.', true);
                                    }
                                }}
                            >
                                Request Signing URLs
                            </span>
                        )}
                    </>
                )}
                {signedAt && <div style={{ fontSize: '14px' }}>Signed at: {new Date(signedAt).toLocaleString()}</div>}
            </div>
        );
    };

    const renderUseExistingDocumentButton = useCallback(() => {
        if (isUpdating()) return null;

        const hasChosenUser = !!commonData.user;

        return (
            <div
                className="addEditRalRequestDrawer-useExistingDocument"
                style={{
                    gap: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                }}
            >
                <span className="a" onClick={() => setUseExistingDocument(!useExistingDocument)}>
                    {!useExistingDocument ? 'Use Existing Document' : 'Create From Scratch'}
                </span>

                {!hasChosenUser && useExistingDocument && 'Please select a user first.'}

                {hasChosenUser && useExistingDocument && (
                    <>
                        <br />
                        <DocumentSearchFilter
                            defaultLabel={'Search Document'}
                            filter={{
                                users: [commonData.user?._id],
                                types: ['Reporting Authorization Letter'],
                            }}
                            selected={existingDocument}
                            onChange={(doc: any) => setExistingDocument(doc)}
                            width={'100%'}
                            defaultOptions={true}
                            noDefaultOption={true}
                            excludeDisplayFields={['user', 'type']}
                        />
                        {errors.existingDocument && <div className="error">{errors.existingDocument}</div>}
                    </>
                )}
            </div>
        );
    }, [isUpdating, commonData.user, useExistingDocument, existingDocument, errors.existingDocument, setUseExistingDocument, setExistingDocument]);

    const renderHeader = () => (
        <>
            <h1 style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                {ralRequestId ? 'Update' : 'Create'} RAL Request
                {ralRequest?._id && <CopyButton text={ralRequest._id} title="Copy RAL Request ID to clipboard" />}
            </h1>
            <br />
            {renderUseExistingDocumentButton()}
            {renderStatus()}
        </>
    );

    const handleDelete = useCallback(async () => {
        if (!ralRequest?._id) return;

        loading(0, 'ralRequestDrawer_deleteRalRequest');
        try {
            await api2.client.RALRequestApi.deleteRALRequest({
                ral_request_id: ralRequest._id,
            });
            setDismissableAlert(setAlert, 'RAL request deleted successfully.', false, 5000);
            reloadData();
            close();
        } catch (e) {
            console.error('Error deleting RAL request', e);
            setDismissableAlert(setAlert, 'Error deleting RAL request.', true);
        } finally {
            loaded('ralRequestDrawer_deleteRalRequest');
        }
    }, [ralRequest?._id, loading, loaded, close, reloadData]);

    const checkCommonData = useCallback(
        (data: any, errors: Record<string, string | null>) => {
            // check required fields
            ['user', 'ral_template'].forEach((field) => {
                if (!data[field]) {
                    errors[field] = 'This field is required.';
                }
            });

            // check ownership fields
            if (data.ownership_type && !data.ownership_ids?.length) {
                errors.ownership_ids = 'This field is required.';
            } else if (!data.ownership_type && data.ownership_ids?.length) {
                errors.ownership_type = 'This field is required.';
            }

            if (useExistingDocument) {
                if (!existingDocument) {
                    errors.existingDocument = 'Please select an existing document.';
                }
            } else if (!ralRequest?.sent_at && !ralRequest?.signed_at) {
                // check signatories and tokens only if not using existing document and not already sent or signed

                // check signatories, required signatories
                if (data.ral_template?.required_signatories?.length) {
                    const requiredRoles = data.ral_template.required_signatories.map((rs: any) => rs.role);
                    const signatoryRoles = data.signatories?.map((s: any) => s.role) ?? [];
                    requiredRoles.forEach((role: string) => {
                        if (!signatoryRoles.includes(role)) {
                            errors.signatories = `Missing required signatory role: ${role}.`;
                        }
                    });
                }

                // check signatory fields are filled out
                if (data.signatories?.length) {
                    data.signatories.forEach((signatory: any) => {
                        if (signatory.email && !validator.isEmail(signatory.email)) {
                            errors.signatories = 'Invalid email format.';
                        }
                        if (!signatory.first_name || !signatory.last_name || !signatory.email) {
                            errors.signatories = 'All fields (first name, last name, email, role) are required for each signatory.';
                        }
                    });
                }

                // check required tokens
                const requiredTokens = data.ral_template?.required_tokens ?? [];
                const missingTokens = requiredTokens.filter((tk: string) => !data?.tokens?.find((rt: any) => rt.key === tk && rt.value));
                if (missingTokens.length) {
                    errors.tokens = 'All required tokens must be filled out.';
                }
            }
        },
        [useExistingDocument, existingDocument, ralRequest]
    );

    const isSafeToSave = useCallback(
        (setErrorsFlag = true) => {
            let errors: Record<string, string | null> = {};
            checkCommonData(commonData, errors);
            const isSafe = Object.keys(errors).length === 0;
            if (setErrorsFlag) {
                errors.submit = isSafe ? null : 'There are missing required fields.';
                setErrors(errors as Record<string, string>);
            }
            return isSafe;
        },
        [commonData, checkCommonData]
    );

    const cleanDataForSave = useCallback(
        (data: any) => {
            // if creating from a document, clear empty signatories
            if (useExistingDocument || ralRequest?.sent_at || ralRequest?.signed_at) {
                data.signatories = data.signatories.filter((signatory: any) => signatory.first_name && signatory.last_name && signatory.email);
            }
        },
        [useExistingDocument, ralRequest]
    );

    const setTemplate = useCallback(
        (ral_template: any) => {
            setCommonData((prev) => ({ ...prev, ral_template }));

            // set required tokens, preserving existing values
            const curTokens = commonData.tokens ?? [];
            const newTokens = (ral_template?.required_tokens ?? []).map((tk: string) => ({
                key: tk,
                value: '',
            }));
            newTokens.forEach((newToken: any) => {
                const existingToken = curTokens.find((curToken: any) => curToken.key === newToken.key);
                if (existingToken) {
                    newToken.value = existingToken.value ?? '';
                }
            });
            setCommonData((prev) => ({ ...prev, tokens: newTokens }));

            // set required signatories
            const curSignatories = commonData.signatories ?? [];
            const newSignatories = generateSignatoriesFromRequiredSignatories(ral_template?.required_signatories ?? []);

            // preserve existing signatory values
            const addedRoles: string[] = [];
            newSignatories.forEach((newSignatory: any) => {
                // only add one signatory per role
                if (addedRoles.includes(newSignatory.role)) return;
                const existingSignatory = curSignatories.find((curSignatory: any) => curSignatory.role === newSignatory.role);
                if (existingSignatory) {
                    newSignatory.first_name = existingSignatory.first_name ?? '';
                    newSignatory.last_name = existingSignatory.last_name ?? '';
                    newSignatory.email = existingSignatory.email ?? '';
                }
                addedRoles.push(newSignatory.role);
            });

            setCommonData((prev) => ({ ...prev, signatories: newSignatories }));
        },
        [commonData]
    );

    const loadState = useCallback(async () => {
        loading(0, 'ralDrawer_load');

        try {
            const ralRequestTemplates = (await api2.client.RALRequestTemplateApi.listRALRequestTemplates()).data.templates ?? [];
            setRalRequestTemplates(ralRequestTemplates as ListRALRequestTemplates200Response[]);

            // set default template if a specific ralRequestId is not provided
            if (!ralRequestId && ralRequestTemplates.length > 0) {
                const defaultTemplate = ralRequestTemplates.find((t: any) => t.default) ?? ralRequestTemplates[0];
                setTemplate(defaultTemplate);
            }
        } catch (e) {
            console.error('Error loading RAL request templates', e);
        } finally {
            // Move loaded call here to ensure it's always called
            loaded('ralDrawer_load');
        }
    }, [loading, loaded, ralRequestId, setTemplate]);

    useEffect(() => {
        loadState();
    }, []);

    const loadRalRequest = useCallback(
        async (ralRequestId: string) => {
            if (!ralRequestId) return;

            loading(0, 'ralDrawer_load_RalRequest');
            try {
                const ralRequest = (
                    await api2.client.RALRequestApi.getRALRequest({
                        ral_request_id: ralRequestId,
                        populate_user: true,
                        populate_ownership: true,
                        populate_ral_template: true,
                        populate_signed_ral: true,
                    })
                ).data.ral_request as RALRequest;
                setRalRequest(ralRequest as ListRALRequests200ResponseRalRequestsInner | null);

                // get full template
                const ral_template = ralRequestTemplates.find((t) => t.templates?.[0]?._id === ralRequest?.ral_request_template?._id) ?? ralRequest?.ral_request_template;

                // convert tokens from object to array of key-value pairs
                const tokens = Object.entries(ralRequest.tokens || {}).map(([key, value]) => ({
                    key,
                    value,
                }));

                setCommonData((prev) => ({
                    ...prev,
                    user: ralRequest.user as RALRequestUser,
                    ownership_type: ralRequest.ownership_type || null,
                    ownership_ids: ralRequest.ownership_ids || [],
                    ral_template: ral_template as RALRequestTemplate,
                    signatories: mergeAndGenerateSignatories(
                        ((ral_template as RALRequestTemplate).required_signatories || []).map((sig) => ({
                            ...sig,
                            count: 1, // Set default count of 1 since it doesn't exist on the type
                        })),
                        ralRequest.signatories as RALRequestSignatory[]
                    ),
                    tokens,
                }));
            } catch (e) {
                console.error('Error loading RAL request', e);
                setDismissableAlert(setAlert, 'Error loading RAL Request.', true);
            } finally {
                // Move loaded call here to ensure it's always called
                loaded('ralDrawer_load_RalRequest');
            }
        },
        [loading, loaded, ralRequestTemplates]
    );

    useEffect(() => {
        if (ralRequestId) {
            loadRalRequest(ralRequestId);
        }
    }, [ralRequestId, loadRalRequest]);

    const setTokensAndClientSignatoryFromUserChoice = useCallback(
        (user: User) => {
            // get user info
            const userName = user?.name || '';
            const userSplit = userName?.split(' ') || '';
            const userLastName = userSplit?.pop() || '';
            const userFirstName = userSplit?.join(' ') || '';
            const userEmail = user?.email || '';

            // set tokens
            const tokens = [...(commonData.tokens ?? [])];
            tokens.forEach((token) => {
                if (token.key === 'Investor Name') {
                    token.value = userName;
                } else if (token.key === 'Signatory Name') {
                    token.value = userName;
                } else if (token.key === 'Entity Name') {
                    token.value = '';
                }
            });

            // set signatories
            const signatories = [...(commonData.signatories ?? [])];
            signatories.forEach((signatory) => {
                if (signatory.role === 'Client') {
                    signatory.last_name = userLastName;
                    signatory.first_name = userFirstName;
                    signatory.email = userEmail;
                }
            });

            // Update state with both changes at once
            setCommonData((prev) => ({
                ...prev,
                tokens,
                signatories,
            }));
        },
        [commonData.tokens, commonData.signatories]
    );

    const getCommonDataFields = useCallback(() => {
        const isLocked = !!ralRequest?.sent_at;
        const lockedMessage = 'This RAL Request has already been sent.';

        let fields: Record<string, any> = {
            User: {
                label: 'User',
                fieldName: 'user',
                type: 'custom',
                component: () => (
                    <UserSearchFilter
                        defaultLabel={'Search User'}
                        filter={{ access: VUES.INVESTOR }}
                        selected={commonData.user}
                        onChange={(user: any) => {
                            setCommonData((prev) => ({ ...prev, user }));
                            // clear ownership fields
                            setCommonData((prev) => ({ ...prev, ownership_type: null }));
                            setTokensAndClientSignatoryFromUserChoice(user);
                        }}
                        width={'100%'}
                        defaultOptions={false}
                        noDefaultOption={true}
                    />
                ),
            },
        };

        if (commonData.user) {
            const ownershipFields = getDocumentOwnershipFields({
                userId: commonData.user?._id,
                ownershipType: commonData.ownership_type,
                onChangeOwnershipType: (ownership_type: string) => {
                    setCommonData((prev) => ({ ...prev, ownership_type }));
                    if (!ownership_type) {
                        const tokens = commonData.tokens ?? [];
                        tokens.forEach((token: any) => {
                            if (token.key === 'Entity Name') {
                                token.value = '';
                            }
                        });
                        setCommonData((prev) => ({ ...prev, tokens }));
                    }
                },
                ownershipIdObjects: commonData.ownership_ids,
                onChangeOwnershipIdObjects: (ownershipIdObjects: any[]) => {
                    setCommonData((prev) => ({ ...prev, ownership_ids: ownershipIdObjects }));

                    if (ownershipIdObjects?.length === 1) {
                        const accountName = commonData.ownership_type === DocumentOwnershipType.account ? ownershipIdObjects[0].name : ownershipIdObjects[0].account?.name;
                        const tokens = commonData.tokens ?? [];
                        tokens.forEach((token: any) => {
                            if (token.key === 'Entity Name') {
                                token.value = accountName;
                            }
                        });
                        setCommonData((prev) => ({ ...prev, tokens }));
                    }
                },
            });
            fields = {
                ...fields,
                ...ownershipFields,
            };
        }

        fields['RalTemplate'] = {
            isLocked,
            lockedMessage,
            label: 'RAL Template',
            fieldName: 'ral_template',
            type: 'custom',
            component: () => (
                <ObjectFilterSelect
                    selected={commonData.ral_template}
                    objects={ralRequestTemplates}
                    onChange={(ral_template: any) => {
                        if (isLocked) return;
                        setTemplate(ral_template?.value);
                    }}
                    getLabel={(obj: any) => obj.name}
                    defaultLabel="Select RAL Template"
                    matchProperty="_id"
                    width={'100%'}
                    noDefaultOption={true}
                />
            ),
        };

        fields['RequiredTokens'] = {
            label: isLocked ? 'Template Tokens' : '',
            isLocked,
            lockedMessage,
            type: 'custom',
            fieldName: 'tokens',
            component: () => (
                <KeyValueDrawerList
                    readOnly={isLocked}
                    label={isLocked ? '' : 'Template Tokens'}
                    hideVisibilityIcon={true}
                    showCredentials={true}
                    requiredCredentials={commonData.ral_template?.required_tokens?.map((tk: string) => ({ key: tk })) || []}
                    keyValueList={commonData.tokens || []}
                    setKeyValueList={(tokens: any) => {
                        if (isLocked) return;
                        setCommonData((prev) => ({ ...prev, tokens }));
                    }}
                    errors={errors}
                />
            ),
        };

        fields['Signatories'] = {
            isLocked,
            lockedMessage,
            type: 'custom',
            fieldName: 'signatories',
            label: isLocked ? 'Signatories' : '',
            component: () => (
                <RalSignatoryListEditor
                    readOnly={isLocked}
                    label={isLocked ? '' : 'Signatories'}
                    signatories={commonData.signatories ?? []}
                    setSignatories={(signatories: any) => !isLocked && setCommonData((prev) => ({ ...prev, signatories }))}
                />
            ),
        };

        return fields;
    }, [commonData, ralRequest, ralRequestTemplates, setTemplate, errors, setTokensAndClientSignatoryFromUserChoice]);

    const renderChooseAdvisorSignatoryButton = useCallback(() => {
        const hasChosenUser = !!commonData.user;
        if (!hasChosenUser || ralRequest?.sent_at || ralRequest?.signed_at) return null;

        const defaultLabel = `Search Advisor Signatory`;

        return (
            <div
                style={{
                    gap: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                }}
            >
                {!selectAdvisorSignatory && (
                    <span className="a" onClick={() => setSelectAdvisorSignatory(true)}>
                        Choose Advisor Signatory
                    </span>
                )}

                {selectAdvisorSignatory && (
                    <ObjectSearchSelectTs
                        selected={null}
                        placeholder={defaultLabel}
                        onChange={(advisor: any) => {
                            // add advisor as signatory
                            const signatories = commonData.signatories ?? [];
                            const userName = advisor?.name ?? '';
                            const userSplit = userName?.split(' ') ?? '';

                            let userLastName = '';
                            let userFirstName = '';

                            if (userSplit.length > 1) {
                                userLastName = userSplit.pop();
                                userFirstName = userSplit.join(' ');
                            } else {
                                userFirstName = userName;
                                userLastName = userName;
                            }
                            const userEmail = advisor?.email ?? '';

                            // if no existing signatory with same name and email, add new signatory
                            const existingSignatoryIndex = signatories.findIndex(
                                (s) => s.role === '' && s.first_name === userFirstName && s.last_name === userLastName && s.email === userEmail
                            );

                            if (existingSignatoryIndex === -1) {
                                // add advisor as signatory
                                setCommonData((prev) => ({
                                    ...prev,
                                    signatories: [
                                        ...signatories,
                                        {
                                            role: '',
                                            first_name: userFirstName,
                                            last_name: userLastName,
                                            email: userEmail,
                                        },
                                    ],
                                }));
                            }
                        }}
                        getLabel={(advisor: any) => {
                            if (!advisor) return defaultLabel;
                            return (
                                <>
                                    <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{advisor?.advisor_group_memberships?.[0]?.group?.name}</div>
                                    <div>{advisor?.name}</div>
                                </>
                            );
                        }}
                        defaultLabel={defaultLabel}
                        matchProperty="_id"
                        loadOptions={async () => {
                            const thisUser = await api.get(`/users/self/advisors?populate_group=true&userId=${commonData.user?._id}`);
                            return thisUser?.advisor_users ?? [];
                        }}
                        width={'100%'}
                        defaultOptions={true}
                        noDefaultOption={true}
                    />
                )}
            </div>
        );
    }, [commonData.user, commonData.signatories, ralRequest?.sent_at, ralRequest?.signed_at, selectAdvisorSignatory]);

    return (
        <GenericDrawer
            close={close}
            renderHeader={renderHeader}
            onSubmit={createUpdateRalRequest}
            actionStr={isUpdating() ? 'Update RAL Request' : 'Create RAL Request'}
            onDelete={ralRequestId ? handleDelete : undefined}
            loading={loading}
            loaded={loaded}
            errors={errors}
            setErrors={setErrors}
            getFields={getCommonDataFields}
            extraContent={renderChooseAdvisorSignatoryButton}
            title={''}
            formData={commonData}
            className="addNewAdvisorGroup-drawer"
        />
    );
};

export default AddEditRalRequestDrawer;

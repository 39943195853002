/* tslint:disable */
/* eslint-disable */
/**
 * AltExchange API Version 2
 * This is the second version of the AltExchange API, designed to provide a comprehensive suite of financial data and reporting operations for AltExchange clients. It includes enhanced features for asset reporting, aiming to deliver a more robust and user-friendly experience.
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const AccountIdTypes = {
    Ssn: 'SSN',
    Ein: 'EIN',
    Tin: 'TIN'
} as const;

export type AccountIdTypes = typeof AccountIdTypes[keyof typeof AccountIdTypes];


/**
 * 
 * @export
 * @enum {string}
 */

export const AccountTypes = {
    Individual: 'individual',
    Joint: 'joint',
    Trust: 'trust',
    Ira: 'ira',
    Llc: 'llc',
    Partnership: 'partnership',
    Corporation: 'corporation',
    Other: 'other'
} as const;

export type AccountTypes = typeof AccountTypes[keyof typeof AccountTypes];


/**
 * 
 * @export
 * @interface AddAdvisorToGroupRequest
 */
export interface AddAdvisorToGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof AddAdvisorToGroupRequest
     */
    'userId': string | null;
    /**
     * 
     * @type {string}
     * @memberof AddAdvisorToGroupRequest
     */
    'role'?: AddAdvisorToGroupRequestRoleEnum;
}

export const AddAdvisorToGroupRequestRoleEnum = {
    Owner: 'Owner',
    Admin: 'Admin',
    Member: 'Member'
} as const;

export type AddAdvisorToGroupRequestRoleEnum = typeof AddAdvisorToGroupRequestRoleEnum[keyof typeof AddAdvisorToGroupRequestRoleEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const AdminActionOptions = {
    SyncManualConnection: 'sync_manual_connection'
} as const;

export type AdminActionOptions = typeof AdminActionOptions[keyof typeof AdminActionOptions];


/**
 * 
 * @export
 * @interface AdvisoryGroupFromExtIntegrationId200Response
 */
export interface AdvisoryGroupFromExtIntegrationId200Response {
    /**
     * 
     * @type {boolean}
     * @memberof AdvisoryGroupFromExtIntegrationId200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {ListAdvisoryGroups200ResponseAdvisoryGroupsInner}
     * @memberof AdvisoryGroupFromExtIntegrationId200Response
     */
    'advisory_group'?: ListAdvisoryGroups200ResponseAdvisoryGroupsInner;
}
/**
 * 
 * @export
 * @interface ApplyAllInvestment200Response
 */
export interface ApplyAllInvestment200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ApplyAllInvestment200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplyAllInvestment200Response
     */
    'modified_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplyAllInvestment200Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApplyAllInvestmentRequest
 */
export interface ApplyAllInvestmentRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplyAllInvestmentRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyAllInvestmentRequest
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyAllInvestmentRequest
     */
    'sub_type'?: string;
}
/**
 * The status of the outreach process for a connection that is being processed by an asset manager.
 * @export
 * @interface AssetManagerConnectionOutreachStatuses
 */
export interface AssetManagerConnectionOutreachStatuses {
    /**
     * 
     * @type {string}
     * @memberof AssetManagerConnectionOutreachStatuses
     */
    'all_statuses'?: AssetManagerConnectionOutreachStatusesAllStatusesEnum;
    /**
     * 
     * @type {string}
     * @memberof AssetManagerConnectionOutreachStatuses
     */
    'incomplete_statuses'?: AssetManagerConnectionOutreachStatusesIncompleteStatusesEnum;
    /**
     * 
     * @type {string}
     * @memberof AssetManagerConnectionOutreachStatuses
     */
    'complete_statuses'?: AssetManagerConnectionOutreachStatusesCompleteStatusesEnum;
    /**
     * 
     * @type {AssetManagerConnectionOutreachStatusesPhrasingMap}
     * @memberof AssetManagerConnectionOutreachStatuses
     */
    'phrasing_map'?: AssetManagerConnectionOutreachStatusesPhrasingMap;
}

export const AssetManagerConnectionOutreachStatusesAllStatusesEnum = {
    ConnectionCreated: 'Connection Created',
    ReadyForAmToStart: 'Ready For AM To Start',
    AmUnableToSupport: 'AM Unable To Support',
    AmNeedsMoreInformation: 'AM Needs More Information',
    ConnectionSetupCompleteByAm: 'Connection Setup Complete By AM',
    PortalCredentialsCreated: 'Portal Credentials Created'
} as const;

export type AssetManagerConnectionOutreachStatusesAllStatusesEnum = typeof AssetManagerConnectionOutreachStatusesAllStatusesEnum[keyof typeof AssetManagerConnectionOutreachStatusesAllStatusesEnum];
export const AssetManagerConnectionOutreachStatusesIncompleteStatusesEnum = {
    ReadyForAmToStart: 'Ready For AM To Start',
    AmUnableToSupport: 'AM Unable To Support',
    AmNeedsMoreInformation: 'AM Needs More Information',
    ConnectionSetupCompleteByAm: 'Connection Setup Complete By AM'
} as const;

export type AssetManagerConnectionOutreachStatusesIncompleteStatusesEnum = typeof AssetManagerConnectionOutreachStatusesIncompleteStatusesEnum[keyof typeof AssetManagerConnectionOutreachStatusesIncompleteStatusesEnum];
export const AssetManagerConnectionOutreachStatusesCompleteStatusesEnum = {
    PortalCredentialsCreated: 'Portal Credentials Created'
} as const;

export type AssetManagerConnectionOutreachStatusesCompleteStatusesEnum = typeof AssetManagerConnectionOutreachStatusesCompleteStatusesEnum[keyof typeof AssetManagerConnectionOutreachStatusesCompleteStatusesEnum];

/**
 * 
 * @export
 * @interface AssetManagerConnectionOutreachStatusesPhrasingMap
 */
export interface AssetManagerConnectionOutreachStatusesPhrasingMap {
    /**
     * 
     * @type {string}
     * @memberof AssetManagerConnectionOutreachStatusesPhrasingMap
     */
    'keys'?: AssetManagerConnectionOutreachStatusesPhrasingMapKeysEnum;
    /**
     * 
     * @type {string}
     * @memberof AssetManagerConnectionOutreachStatusesPhrasingMap
     */
    'values'?: AssetManagerConnectionOutreachStatusesPhrasingMapValuesEnum;
}

export const AssetManagerConnectionOutreachStatusesPhrasingMapKeysEnum = {
    ConnectionCreated: 'Connection Created',
    ReadyForAmToStart: 'Ready For AM To Start',
    AmUnableToSupport: 'AM Unable To Support',
    AmNeedsMoreInformation: 'AM Needs More Information',
    ConnectionSetupCompleteByAm: 'Connection Setup Complete By AM',
    PortalCredentialsCreated: 'Portal Credentials Created'
} as const;

export type AssetManagerConnectionOutreachStatusesPhrasingMapKeysEnum = typeof AssetManagerConnectionOutreachStatusesPhrasingMapKeysEnum[keyof typeof AssetManagerConnectionOutreachStatusesPhrasingMapKeysEnum];
export const AssetManagerConnectionOutreachStatusesPhrasingMapValuesEnum = {
    ConnectionCreated: 'Connection Created',
    NotStarted: 'Not Started',
    UnableToSetupPortal: 'Unable to setup portal',
    NeedMoreInfoFromAltExchange: 'Need more info from AltExchange',
    PortalSetUpCompleted: 'Portal set up completed',
    Completed: 'Completed'
} as const;

export type AssetManagerConnectionOutreachStatusesPhrasingMapValuesEnum = typeof AssetManagerConnectionOutreachStatusesPhrasingMapValuesEnum[keyof typeof AssetManagerConnectionOutreachStatusesPhrasingMapValuesEnum];

/**
 * The status of the outreach process for an asset manager.
 * @export
 * @enum {string}
 */

export const AssetManagerOutreachStatus = {
    NotStarted: 'Not Started',
    NoResponse: 'No Response',
    UnableToSupport: 'Unable to Support',
    GrantingPortalAccess: 'Granting Portal Access',
    InvestmentSetup: 'Investment Setup',
    Done: 'Done'
} as const;

export type AssetManagerOutreachStatus = typeof AssetManagerOutreachStatus[keyof typeof AssetManagerOutreachStatus];


/**
 * Audit result categories.
 * @export
 * @enum {string}
 */

export const AuditResultCategory = {
    None: 'None',
    Biqc: 'BIQC',
    Sorting: 'Sorting',
    Tagging: 'Tagging',
    DocumentProcessing: 'Document Processing',
    Integrations: 'Integrations',
    Users: 'Users',
    Entities: 'Entities',
    Connections: 'Connections',
    Messages: 'Messages',
    Advisor: 'Advisor',
    Investments: 'Investments',
    Rals: 'RALs',
    SecurityMaster: 'Security Master',
    Extegrations: 'Extegrations'
} as const;

export type AuditResultCategory = typeof AuditResultCategory[keyof typeof AuditResultCategory];


/**
 * Audit result check names.
 * @export
 * @enum {string}
 */

export const AuditResultCheckNames = {
    SecurityMasterDocumentsReceivedByAllInvestments: 'Security Master Documents Received by All Investments',
    InvestmentUnderAdministrativeReview: 'Investment Under Administrative Review',
    UnrealizedValueSpike: 'Unrealized Value Spike',
    EndingBeginningBalanceMismatch: 'Ending/beginning balance mismatch',
    InvalidDateRange: 'Invalid date range',
    InaccurateDistributionsSum: 'Inaccurate distributions sum',
    InaccurateContributionsSum: 'Inaccurate contributions sum',
    CapNavStatementTimeliness: 'Cap/NAV Statement Timeliness',
    HaveCapNavStatementEveryPeriod: 'Have Cap/NAV Statement every period',
    TaxDocumentTimeliness: 'Tax Document Timeliness',
    HasTaxDocumentEveryYearSinceInvestmentStart: 'Has Tax Document every year since investment start',
    TransactionWithDefaultDateData: 'Transaction with default date data',
    DuplicateTransactions: 'Duplicate Transactions',
    UnsortedDocument: 'Unsorted Document',
    CapitalStatementWithInvalidMetadata: 'Capital Statement With Invalid Metadata',
    CapitalCallWithInvalidMetadata: 'Capital Call With Invalid Metadata',
    DistributionWithInvalidMetadata: 'Distribution With Invalid Metadata',
    NavStatementWithInvalidMetadata: 'NAV Statement WithInvalid Metadata',
    NetCapitalCallWithInvalidMetadata: 'Net Capital Call With Invalid Metadata',
    NetDistributionWithInvalidMetadata: 'Net Distribution With Invalid Metadata',
    TaxDocumentWithInvalidMetadata: 'Tax Document With Invalid Metadata',
    ManualConnectionPortalSync: 'Manual Connection Portal Sync',
    ConnectionStatusNotActive: 'Connection Status Not Active',
    ConnectionWithoutContacts: 'Connection Without Contacts',
    ConnectionCompletedByAssetManager: 'Connection Completed By Asset Manager',
    UnmappedDataInMostRecentConnectionSyncStagingArea: 'Unmapped Data in Most Recent Connection Sync (Staging Area)',
    MessagesUnreadFor3Days: 'Messages Unread for 3 Days',
    MessagesWithDocumentsAttached: 'Messages with Documents Attached',
    MessagesFlaggedFor5Days: 'Messages Flagged for 5 Days',
    FundInvestmentsWithoutSecurityMasterTicker: 'Fund investments without Security Master Ticker',
    FundInvestmentsMissingSubtype: 'Fund investments missing Subtype',
    NonHiddenInvestmentHasTypeDiverseInvestments: 'Non-hidden Investment has type Diverse Investments',
    InvestmentsWithoutPastYearTaxDoc: 'Investments without past year tax doc',
    AbnormalInvestmentIrr: 'Abnormal Investment IRR',
    InvestmentHiddenTooLong: 'Investment Hidden Too Long',
    InvestmentTypeStatementTypeMismatch: 'Investment Type / Statement Type Mismatch',
    InvestmentWithNegativeUnfundedAmount: 'Investment with negative unfunded amount',
    ExtegrationSyncFailedByAdvisoryGroup: 'Extegration Sync Failed by Advisory Group'
} as const;

export type AuditResultCheckNames = typeof AuditResultCheckNames[keyof typeof AuditResultCheckNames];


/**
 * The status of an audit result.
 * @export
 * @enum {string}
 */

export const AuditResultStatus = {
    Active: 'active',
    Ignored: 'ignored',
    AutoIgnored: 'auto-ignored',
    Completed: 'completed'
} as const;

export type AuditResultStatus = typeof AuditResultStatus[keyof typeof AuditResultStatus];


/**
 * Authorization categories.
 * @export
 * @enum {string}
 */

export const AuthCategories = {
    C: 'c',
    R: 'r',
    U: 'u',
    D: 'd',
    Star: '*'
} as const;

export type AuthCategories = typeof AuthCategories[keyof typeof AuthCategories];


/**
 * 
 * @export
 * @interface CompleteExtegrationSyncRequest
 */
export interface CompleteExtegrationSyncRequest {
    /**
     * 
     * @type {string}
     * @memberof CompleteExtegrationSyncRequest
     */
    'exit_status': CompleteExtegrationSyncRequestExitStatusEnum;
    /**
     * 
     * @type {object}
     * @memberof CompleteExtegrationSyncRequest
     */
    'error_report': object;
    /**
     * 
     * @type {object}
     * @memberof CompleteExtegrationSyncRequest
     */
    'job_response': object;
}

export const CompleteExtegrationSyncRequestExitStatusEnum = {
    Success: 'success',
    Failure: 'failure',
    Pending: 'pending'
} as const;

export type CompleteExtegrationSyncRequestExitStatusEnum = typeof CompleteExtegrationSyncRequestExitStatusEnum[keyof typeof CompleteExtegrationSyncRequestExitStatusEnum];

/**
 * 
 * @export
 * @interface CompleteMultipartUploadRequest
 */
export interface CompleteMultipartUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof CompleteMultipartUploadRequest
     */
    'uploadId': string;
    /**
     * 
     * @type {Array<CompleteMultipartUploadRequestPartsInner>}
     * @memberof CompleteMultipartUploadRequest
     */
    'parts': Array<CompleteMultipartUploadRequestPartsInner>;
}
/**
 * 
 * @export
 * @interface CompleteMultipartUploadRequestPartsInner
 */
export interface CompleteMultipartUploadRequestPartsInner {
    /**
     * 
     * @type {string}
     * @memberof CompleteMultipartUploadRequestPartsInner
     */
    'ETag': string;
    /**
     * 
     * @type {number}
     * @memberof CompleteMultipartUploadRequestPartsInner
     */
    'PartNumber': number;
}
/**
 * The status of the outreach process for a connection.
 * @export
 * @enum {string}
 */

export const ConnectionOutreachStatus = {
    ConnectionCreated: 'Connection Created',
    ReadyForAmToStart: 'Ready For AM To Start',
    AmUnableToSupport: 'AM Unable To Support',
    AmNeedsMoreInformation: 'AM Needs More Information',
    ConnectionSetupCompleteByAm: 'Connection Setup Complete By AM',
    PortalCredentialsCreated: 'Portal Credentials Created'
} as const;

export type ConnectionOutreachStatus = typeof ConnectionOutreachStatus[keyof typeof ConnectionOutreachStatus];


/**
 * The RAL status of a connection.
 * @export
 * @enum {string}
 */

export const ConnectionRALStatus = {
    Send: 'Send',
    Resend: 'Resend',
    Received: 'Received',
    Approved: 'Approved'
} as const;

export type ConnectionRALStatus = typeof ConnectionRALStatus[keyof typeof ConnectionRALStatus];


/**
 * The status of a connection.
 * @export
 * @enum {string}
 */

export const ConnectionStatus = {
    UnderReview: 'Under Review',
    Pending: 'Pending',
    Importing: 'Importing',
    Authenticating: 'Authenticating',
    Syncing: 'Syncing',
    Active: 'Active',
    Error: 'Error'
} as const;

export type ConnectionStatus = typeof ConnectionStatus[keyof typeof ConnectionStatus];


/**
 * Contact roles.
 * @export
 * @enum {string}
 */

export const ContactRole = {
    Primary: 'primary',
    Direct: 'direct',
    Tax: 'tax',
    Legal: 'legal',
    Ir: 'ir',
    Other: 'other'
} as const;

export type ContactRole = typeof ContactRole[keyof typeof ContactRole];


/**
 * 
 * @export
 * @interface CreateAccount201Response
 */
export interface CreateAccount201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateAccount201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAccount201Response
     */
    'account_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateAccountRequest
 */
export interface CreateAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'account_type'?: CreateAccountRequestAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'id_type'?: CreateAccountRequestIdTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'id_number'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateAccountRequest
     */
    'external_ids'?: { [key: string]: string; };
    /**
     * 
     * @type {CreateAccountRequestAddress}
     * @memberof CreateAccountRequest
     */
    'address'?: CreateAccountRequestAddress;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'user': string | null;
}

export const CreateAccountRequestAccountTypeEnum = {
    Individual: 'individual',
    Joint: 'joint',
    Trust: 'trust',
    Ira: 'ira',
    Llc: 'llc',
    Partnership: 'partnership',
    Corporation: 'corporation',
    Other: 'other',
    Empty: ''
} as const;

export type CreateAccountRequestAccountTypeEnum = typeof CreateAccountRequestAccountTypeEnum[keyof typeof CreateAccountRequestAccountTypeEnum];
export const CreateAccountRequestIdTypeEnum = {
    Ssn: 'SSN',
    Ein: 'EIN',
    Tin: 'TIN',
    Empty: ''
} as const;

export type CreateAccountRequestIdTypeEnum = typeof CreateAccountRequestIdTypeEnum[keyof typeof CreateAccountRequestIdTypeEnum];

/**
 * 
 * @export
 * @interface CreateAccountRequestAddress
 */
export interface CreateAccountRequestAddress {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestAddress
     */
    'streetLine1'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestAddress
     */
    'streetLine2'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestAddress
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestAddress
     */
    'state'?: CreateAccountRequestAddressStateEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestAddress
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestAddress
     */
    'zipCode'?: string;
}

export const CreateAccountRequestAddressStateEnum = {
    Al: 'AL',
    Ak: 'AK',
    Az: 'AZ',
    Ar: 'AR',
    Ca: 'CA',
    Co: 'CO',
    Ct: 'CT',
    De: 'DE',
    Fl: 'FL',
    Ga: 'GA',
    Hi: 'HI',
    Id: 'ID',
    Il: 'IL',
    In: 'IN',
    Ia: 'IA',
    Ks: 'KS',
    Ky: 'KY',
    La: 'LA',
    Me: 'ME',
    Md: 'MD',
    Ma: 'MA',
    Mi: 'MI',
    Mn: 'MN',
    Ms: 'MS',
    Mo: 'MO',
    Mt: 'MT',
    Ne: 'NE',
    Nv: 'NV',
    Nh: 'NH',
    Nj: 'NJ',
    Nm: 'NM',
    Ny: 'NY',
    Nc: 'NC',
    Nd: 'ND',
    Oh: 'OH',
    Ok: 'OK',
    Or: 'OR',
    Pa: 'PA',
    Ri: 'RI',
    Sc: 'SC',
    Sd: 'SD',
    Tn: 'TN',
    Tx: 'TX',
    Ut: 'UT',
    Vt: 'VT',
    Va: 'VA',
    Wa: 'WA',
    Wv: 'WV',
    Wi: 'WI',
    Wy: 'WY',
    Nl: 'NL',
    Pe: 'PE',
    Ns: 'NS',
    Nb: 'NB',
    Qc: 'QC',
    True: 'true',
    Mb: 'MB',
    Sk: 'SK',
    Ab: 'AB',
    Bc: 'BC',
    Yt: 'YT',
    Nt: 'NT',
    Nu: 'NU',
    Empty: ''
} as const;

export type CreateAccountRequestAddressStateEnum = typeof CreateAccountRequestAddressStateEnum[keyof typeof CreateAccountRequestAddressStateEnum];

/**
 * 
 * @export
 * @interface CreateAdminTeam201Response
 */
export interface CreateAdminTeam201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateAdminTeam201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminTeam201Response
     */
    'admin_team_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateAdminTeamRequest
 */
export interface CreateAdminTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAdminTeamRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminTeamRequest
     */
    'type': CreateAdminTeamRequestTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAdminTeamRequest
     */
    'advisory_groups': Array<string>;
}

export const CreateAdminTeamRequestTypeEnum = {
    AccountManagement: 'account_management',
    Onboarding: 'onboarding'
} as const;

export type CreateAdminTeamRequestTypeEnum = typeof CreateAdminTeamRequestTypeEnum[keyof typeof CreateAdminTeamRequestTypeEnum];

/**
 * 
 * @export
 * @interface CreateAdvisoryGroup201Response
 */
export interface CreateAdvisoryGroup201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateAdvisoryGroup201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAdvisoryGroup201Response
     */
    'advisory_group_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateAdvisoryGroupRequest
 */
export interface CreateAdvisoryGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAdvisoryGroupRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdvisoryGroupRequest
     */
    'custom_connection_email_domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdvisoryGroupRequest
     */
    'assets'?: string;
    /**
     * 
     * @type {Array<CreateAdvisoryGroupRequestExtegrationsInner>}
     * @memberof CreateAdvisoryGroupRequest
     */
    'extegrations'?: Array<CreateAdvisoryGroupRequestExtegrationsInner>;
    /**
     * 
     * @type {string}
     * @memberof CreateAdvisoryGroupRequest
     */
    'plan'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAdvisoryGroupRequest
     */
    'disable_sla_issues'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAdvisoryGroupRequest
     */
    'allowed_extegrations'?: Array<string>;
    /**
     * 
     * @type {CreateAdvisoryGroupRequestSettings}
     * @memberof CreateAdvisoryGroupRequest
     */
    'settings'?: CreateAdvisoryGroupRequestSettings;
    /**
     * 
     * @type {string}
     * @memberof CreateAdvisoryGroupRequest
     */
    'owner'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateAdvisoryGroupRequestExtegrationsInner
 */
export interface CreateAdvisoryGroupRequestExtegrationsInner {
    /**
     * 
     * @type {boolean}
     * @memberof CreateAdvisoryGroupRequestExtegrationsInner
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAdvisoryGroupRequestExtegrationsInner
     */
    'entities'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateAdvisoryGroupRequestExtegrationsInner
     */
    'name'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof CreateAdvisoryGroupRequestExtegrationsInner
     */
    'credentials'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof CreateAdvisoryGroupRequestExtegrationsInner
     */
    'sync_interval'?: CreateAdvisoryGroupRequestExtegrationsInnerSyncIntervalEnum | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdvisoryGroupRequestExtegrationsInner
     */
    'extegration_type_name'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof CreateAdvisoryGroupRequestExtegrationsInner
     */
    'user_account_map'?: { [key: string]: any; };
}

export const CreateAdvisoryGroupRequestExtegrationsInnerSyncIntervalEnum = {
    Null: 'null',
    Daily: 'daily',
    Weekdays: 'weekdays',
    Weekly: 'weekly',
    Monthly: 'monthly',
    Tetrahourly: 'tetrahourly'
} as const;

export type CreateAdvisoryGroupRequestExtegrationsInnerSyncIntervalEnum = typeof CreateAdvisoryGroupRequestExtegrationsInnerSyncIntervalEnum[keyof typeof CreateAdvisoryGroupRequestExtegrationsInnerSyncIntervalEnum];

/**
 * 
 * @export
 * @interface CreateAdvisoryGroupRequestSettings
 */
export interface CreateAdvisoryGroupRequestSettings {
    [key: string]: any;

    /**
     * 
     * @type {boolean}
     * @memberof CreateAdvisoryGroupRequestSettings
     */
    'hide_fees_for_investors'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAdvisoryGroupRequestSettings
     */
    'hide_fees_for_advisors'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAdvisoryGroupRequestSettings
     */
    'logo_in_toolbar'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateAssetManager201Response
 */
export interface CreateAssetManager201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateAssetManager201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAssetManager201Response
     */
    'asset_manager_id'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateAssetManagerRequest
 */
export interface CreateAssetManagerRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAssetManagerRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAssetManagerRequest
     */
    'website': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAssetManagerRequest
     */
    'tax_document_distribution_method': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAssetManagerRequest
     */
    'notification_method': CreateAssetManagerRequestNotificationMethodEnum | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAssetManagerRequest
     */
    'integrations'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateAssetManagerRequest
     */
    'outreach_status'?: CreateAssetManagerRequestOutreachStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAssetManagerRequest
     */
    'initial_outreach_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAssetManagerRequest
     */
    'num_outreach_attempts'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAssetManagerRequest
     */
    'passkey'?: string;
    /**
     * 
     * @type {Array<CreateAssetManagerRequestContactsInner>}
     * @memberof CreateAssetManagerRequest
     */
    'contacts'?: Array<CreateAssetManagerRequestContactsInner>;
}

export const CreateAssetManagerRequestNotificationMethodEnum = {
    None: 'none',
    Unknown: 'unknown',
    Email: 'email',
    Mail: 'mail',
    Portal: 'portal',
    Null: 'null',
    Null2: 'null'
} as const;

export type CreateAssetManagerRequestNotificationMethodEnum = typeof CreateAssetManagerRequestNotificationMethodEnum[keyof typeof CreateAssetManagerRequestNotificationMethodEnum];
export const CreateAssetManagerRequestOutreachStatusEnum = {
    NotStarted: 'Not Started',
    NoResponse: 'No Response',
    UnableToSupport: 'Unable to Support',
    GrantingPortalAccess: 'Granting Portal Access',
    InvestmentSetup: 'Investment Setup',
    Done: 'Done'
} as const;

export type CreateAssetManagerRequestOutreachStatusEnum = typeof CreateAssetManagerRequestOutreachStatusEnum[keyof typeof CreateAssetManagerRequestOutreachStatusEnum];

/**
 * 
 * @export
 * @interface CreateAssetManagerRequestContactsInner
 */
export interface CreateAssetManagerRequestContactsInner {
    /**
     * 
     * @type {string}
     * @memberof CreateAssetManagerRequestContactsInner
     */
    'contact': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAssetManagerRequestContactsInner
     */
    'roles': Array<CreateAssetManagerRequestContactsInnerRolesEnum>;
}

export const CreateAssetManagerRequestContactsInnerRolesEnum = {
    Primary: 'primary',
    Direct: 'direct',
    Tax: 'tax',
    Legal: 'legal',
    Ir: 'ir',
    Other: 'other'
} as const;

export type CreateAssetManagerRequestContactsInnerRolesEnum = typeof CreateAssetManagerRequestContactsInnerRolesEnum[keyof typeof CreateAssetManagerRequestContactsInnerRolesEnum];

/**
 * 
 * @export
 * @interface CreateCommitment201Response
 */
export interface CreateCommitment201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateCommitment201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateCommitment201Response
     */
    'commitment_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateCommitmentRequest
 */
export interface CreateCommitmentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCommitmentRequest
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof CreateCommitmentRequest
     */
    'amount': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCommitmentRequest
     */
    'documents'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateCommitmentRequest
     */
    'investment': string | null;
}
/**
 * 
 * @export
 * @interface CreateConnection201Response
 */
export interface CreateConnection201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateConnection201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateConnection201Response
     */
    'connection_id'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateConnectionRequest
 */
export interface CreateConnectionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateConnectionRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateConnectionRequest
     */
    'status'?: CreateConnectionRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateConnectionRequest
     */
    'ral_status'?: CreateConnectionRequestRalStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateConnectionRequest
     */
    'is_enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateConnectionRequest
     */
    'long_running'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateConnectionRequest
     */
    'outreach_status'?: CreateConnectionRequestOutreachStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateConnectionRequest
     */
    'require_phone'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateConnectionRequest
     */
    'expect_documents_over_email'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateConnectionRequest
     */
    'use_tfa'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateConnectionRequest
     */
    'test'?: boolean;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof CreateConnectionRequest
     */
    'data'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof CreateConnectionRequest
     */
    'account'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateConnectionRequest
     */
    'account_list'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateConnectionRequest
     */
    'contacts'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateConnectionRequest
     */
    'ral_requests'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateConnectionRequest
     */
    'asset_manager'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateConnectionRequest
     */
    'integration'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConnectionRequest
     */
    'last_connection_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConnectionRequest
     */
    'tfa_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConnectionRequest
     */
    'advisory_group'?: string | null;
}

export const CreateConnectionRequestStatusEnum = {
    UnderReview: 'Under Review',
    Pending: 'Pending',
    Importing: 'Importing',
    Authenticating: 'Authenticating',
    Syncing: 'Syncing',
    Active: 'Active',
    Error: 'Error'
} as const;

export type CreateConnectionRequestStatusEnum = typeof CreateConnectionRequestStatusEnum[keyof typeof CreateConnectionRequestStatusEnum];
export const CreateConnectionRequestRalStatusEnum = {
    Send: 'Send',
    Resend: 'Resend',
    Received: 'Received',
    Approved: 'Approved'
} as const;

export type CreateConnectionRequestRalStatusEnum = typeof CreateConnectionRequestRalStatusEnum[keyof typeof CreateConnectionRequestRalStatusEnum];
export const CreateConnectionRequestOutreachStatusEnum = {
    ConnectionCreated: 'Connection Created',
    ReadyForAmToStart: 'Ready For AM To Start',
    AmUnableToSupport: 'AM Unable To Support',
    AmNeedsMoreInformation: 'AM Needs More Information',
    ConnectionSetupCompleteByAm: 'Connection Setup Complete By AM',
    PortalCredentialsCreated: 'Portal Credentials Created'
} as const;

export type CreateConnectionRequestOutreachStatusEnum = typeof CreateConnectionRequestOutreachStatusEnum[keyof typeof CreateConnectionRequestOutreachStatusEnum];

/**
 * 
 * @export
 * @interface CreateContact201Response
 */
export interface CreateContact201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateContact201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateContact201Response
     */
    'contact_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateDefaultDocumentImportSetting201Response
 */
export interface CreateDefaultDocumentImportSetting201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateDefaultDocumentImportSetting201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateDefaultDocumentImportSetting201Response
     */
    'default_document_import_setting_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateDefaultDocumentImportSettingRequest
 */
export interface CreateDefaultDocumentImportSettingRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDefaultDocumentImportSettingRequest
     */
    'integration'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDefaultDocumentImportSettingRequest
     */
    'asset_manager'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDefaultDocumentImportSettingRequest
     */
    'advisory_group'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDefaultDocumentImportSettingRequest
     */
    'global_default_extraction_setting'?: CreateDefaultDocumentImportSettingRequestGlobalDefaultExtractionSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDefaultDocumentImportSettingRequest
     */
    'global_default_auto_sort_setting'?: CreateDefaultDocumentImportSettingRequestGlobalDefaultAutoSortSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDefaultDocumentImportSettingRequest
     */
    'global_default_transaction_setting'?: CreateDefaultDocumentImportSettingRequestGlobalDefaultTransactionSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDefaultDocumentImportSettingRequest
     */
    'global_default_process_setting'?: CreateDefaultDocumentImportSettingRequestGlobalDefaultProcessSettingEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateDefaultDocumentImportSettingRequest
     */
    'default_transaction_settings'?: { [key: string]: string; };
}

export const CreateDefaultDocumentImportSettingRequestGlobalDefaultExtractionSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type CreateDefaultDocumentImportSettingRequestGlobalDefaultExtractionSettingEnum = typeof CreateDefaultDocumentImportSettingRequestGlobalDefaultExtractionSettingEnum[keyof typeof CreateDefaultDocumentImportSettingRequestGlobalDefaultExtractionSettingEnum];
export const CreateDefaultDocumentImportSettingRequestGlobalDefaultAutoSortSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type CreateDefaultDocumentImportSettingRequestGlobalDefaultAutoSortSettingEnum = typeof CreateDefaultDocumentImportSettingRequestGlobalDefaultAutoSortSettingEnum[keyof typeof CreateDefaultDocumentImportSettingRequestGlobalDefaultAutoSortSettingEnum];
export const CreateDefaultDocumentImportSettingRequestGlobalDefaultTransactionSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type CreateDefaultDocumentImportSettingRequestGlobalDefaultTransactionSettingEnum = typeof CreateDefaultDocumentImportSettingRequestGlobalDefaultTransactionSettingEnum[keyof typeof CreateDefaultDocumentImportSettingRequestGlobalDefaultTransactionSettingEnum];
export const CreateDefaultDocumentImportSettingRequestGlobalDefaultProcessSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type CreateDefaultDocumentImportSettingRequestGlobalDefaultProcessSettingEnum = typeof CreateDefaultDocumentImportSettingRequestGlobalDefaultProcessSettingEnum[keyof typeof CreateDefaultDocumentImportSettingRequestGlobalDefaultProcessSettingEnum];

/**
 * 
 * @export
 * @interface CreateDocument201Response
 */
export interface CreateDocument201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateDocument201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateDocument201Response
     */
    'document_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocument201Response
     */
    'upload_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocument201Response
     */
    'metadata_error'?: string;
}
/**
 * 
 * @export
 * @interface CreateDocumentRequest
 */
export interface CreateDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentRequest
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentRequest
     */
    'type': string | null;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerTraining}
     * @memberof CreateDocumentRequest
     */
    'training'?: ListDocuments200ResponseDocumentsInnerTraining;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDocumentRequest
     */
    'shouldGenerateTransactions'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDocumentRequest
     */
    'shouldProcessDocument'?: boolean;
    /**
     * 
     * @type {Array<ListDocuments200ResponseDocumentsInnerMetadataInner>}
     * @memberof CreateDocumentRequest
     */
    'metadata'?: Array<ListDocuments200ResponseDocumentsInnerMetadataInner>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateDocumentRequest
     */
    'ownership_ids'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentRequest
     */
    'ownership_type'?: CreateDocumentRequestOwnershipTypeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentRequest
     */
    'file_type'?: CreateDocumentRequestFileTypeEnum;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerTransactionDescriptions}
     * @memberof CreateDocumentRequest
     */
    'transaction_descriptions_diff'?: ListDocuments200ResponseDocumentsInnerTransactionDescriptions;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentRequest
     */
    'hash': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentRequest
     */
    'user': string | null;
}

export const CreateDocumentRequestOwnershipTypeEnum = {
    Account: 'Account',
    Investment: 'Investment',
    Null: 'null'
} as const;

export type CreateDocumentRequestOwnershipTypeEnum = typeof CreateDocumentRequestOwnershipTypeEnum[keyof typeof CreateDocumentRequestOwnershipTypeEnum];
export const CreateDocumentRequestFileTypeEnum = {
    Pdf: 'pdf',
    Csv: 'csv',
    Xlsx: 'xlsx',
    Xls: 'xls'
} as const;

export type CreateDocumentRequestFileTypeEnum = typeof CreateDocumentRequestFileTypeEnum[keyof typeof CreateDocumentRequestFileTypeEnum];

/**
 * 
 * @export
 * @interface CreateExchange201Response
 */
export interface CreateExchange201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateExchange201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {ListExchanges200ResponseExchangesInner}
     * @memberof CreateExchange201Response
     */
    'exchange'?: ListExchanges200ResponseExchangesInner;
}
/**
 * 
 * @export
 * @interface CreateExchangeRequest
 */
export interface CreateExchangeRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateExchangeRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateExchangeRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreateExchangeRequest
     */
    'website'?: string;
    /**
     * 
     * @type {ListExchanges200ResponseExchangesInnerLocation}
     * @memberof CreateExchangeRequest
     */
    'location'?: ListExchanges200ResponseExchangesInnerLocation;
    /**
     * 
     * @type {string}
     * @memberof CreateExchangeRequest
     */
    'founded'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExchangeRequest
     */
    'ticker_prefix': string;
}
/**
 * 
 * @export
 * @interface CreateExtegrationConnection201Response
 */
export interface CreateExtegrationConnection201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateExtegrationConnection201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateExtegrationConnection201Response
     */
    'extegration_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateExtegrationConnectionRequest
 */
export interface CreateExtegrationConnectionRequest {
    [key: string]: any;

    /**
     * 
     * @type {boolean}
     * @memberof CreateExtegrationConnectionRequest
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateExtegrationConnectionRequest
     */
    'entities'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateExtegrationConnectionRequest
     */
    'name'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof CreateExtegrationConnectionRequest
     */
    'credentials'?: { [key: string]: any; };
    /**
     * 
     * @type {any}
     * @memberof CreateExtegrationConnectionRequest
     */
    'sync_interval'?: CreateExtegrationConnectionRequestSyncIntervalEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateExtegrationConnectionRequest
     */
    'extegration_type_name'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof CreateExtegrationConnectionRequest
     */
    'user_account_map'?: { [key: string]: any; };
}

export const CreateExtegrationConnectionRequestSyncIntervalEnum = {
    Null: 'null',
    Daily: 'daily',
    Weekdays: 'weekdays',
    Weekly: 'weekly',
    Monthly: 'monthly',
    Tetrahourly: 'tetrahourly'
} as const;

export type CreateExtegrationConnectionRequestSyncIntervalEnum = typeof CreateExtegrationConnectionRequestSyncIntervalEnum[keyof typeof CreateExtegrationConnectionRequestSyncIntervalEnum];

/**
 * 
 * @export
 * @interface CreateIntegration201Response
 */
export interface CreateIntegration201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateIntegration201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegration201Response
     */
    'integration_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateIntegrationRequest
 */
export interface CreateIntegrationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationRequest
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateIntegrationRequest
     */
    'has_worker'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateIntegrationRequest
     */
    'tfa_required'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateIntegrationRequest
     */
    'phone_required'?: boolean;
    /**
     * 
     * @type {Array<ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner>}
     * @memberof CreateIntegrationRequest
     */
    'required_creds'?: Array<ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner>;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationRequest
     */
    'portal_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationRequest
     */
    'lambda_arn'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateIntegrationRequest
     */
    'from_email_domains'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateIntegrationRequest
     */
    'from_email_addresses'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationRequest
     */
    'cycle_container_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateIntegrationRequest
     */
    'worker_version'?: number;
}
/**
 * 
 * @export
 * @interface CreateInvestment201Response
 */
export interface CreateInvestment201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvestment201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestment201Response
     */
    'investment_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateInvestmentMaster201Response
 */
export interface CreateInvestmentMaster201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvestmentMaster201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentMaster201Response
     */
    'investment_master_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateInvestmentMasterRequest
 */
export interface CreateInvestmentMasterRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentMasterRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentMasterRequest
     */
    'ticker': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentMasterRequest
     */
    'asset_manager'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentMasterRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentMasterRequest
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentMasterRequest
     */
    'sub_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentMasterRequest
     */
    'website'?: string;
    /**
     * 
     * @type {ListInvestmentMasters200ResponseInvestmentMastersInnerSocials}
     * @memberof CreateInvestmentMasterRequest
     */
    'socials'?: ListInvestmentMasters200ResponseInvestmentMastersInnerSocials;
    /**
     * 
     * @type {Array<UpdateAssetManagerRequestContactsInner>}
     * @memberof CreateInvestmentMasterRequest
     */
    'contacts'?: Array<UpdateAssetManagerRequestContactsInner>;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentMasterRequest
     */
    'currency'?: CreateInvestmentMasterRequestCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentMasterRequest
     */
    'country'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateInvestmentMasterRequest
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentMasterRequest
     */
    'exchange'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateInvestmentMasterRequest
     */
    'vintage_year'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentMasterRequest
     */
    'status'?: CreateInvestmentMasterRequestStatusEnum;
}

export const CreateInvestmentMasterRequestCurrencyEnum = {
    Usd: 'USD',
    Eur: 'EUR',
    Cny: 'CNY',
    Jpy: 'JPY',
    Gbp: 'GBP',
    Aud: 'AUD',
    Cad: 'CAD',
    Chf: 'CHF',
    Inr: 'INR',
    Krw: 'KRW',
    Btc: 'BTC'
} as const;

export type CreateInvestmentMasterRequestCurrencyEnum = typeof CreateInvestmentMasterRequestCurrencyEnum[keyof typeof CreateInvestmentMasterRequestCurrencyEnum];
export const CreateInvestmentMasterRequestStatusEnum = {
    Fundraising: 'fundraising',
    Investing: 'investing',
    Harvesting: 'harvesting',
    Liquidated: 'liquidated'
} as const;

export type CreateInvestmentMasterRequestStatusEnum = typeof CreateInvestmentMasterRequestStatusEnum[keyof typeof CreateInvestmentMasterRequestStatusEnum];

/**
 * 
 * @export
 * @interface CreateInvestmentRequest
 */
export interface CreateInvestmentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentRequest
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateInvestmentRequest
     */
    'expected_tax_documents'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvestmentRequest
     */
    'hidden'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvestmentRequest
     */
    'exclude_from_billing'?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateInvestmentRequest
     */
    'external_ids'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentRequest
     */
    'currency'?: CreateInvestmentRequestCurrencyEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateInvestmentRequest
     */
    'attributes'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentRequest
     */
    'account': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentRequest
     */
    'connection'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentRequest
     */
    'investment_master'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvestmentRequest
     */
    'inactive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentRequest
     */
    'inactive_date'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof CreateInvestmentRequest
     */
    'investment_data'?: { [key: string]: any; };
}

export const CreateInvestmentRequestCurrencyEnum = {
    Usd: 'USD',
    Eur: 'EUR',
    Cny: 'CNY',
    Jpy: 'JPY',
    Gbp: 'GBP',
    Aud: 'AUD',
    Cad: 'CAD',
    Chf: 'CHF',
    Inr: 'INR',
    Krw: 'KRW',
    Btc: 'BTC'
} as const;

export type CreateInvestmentRequestCurrencyEnum = typeof CreateInvestmentRequestCurrencyEnum[keyof typeof CreateInvestmentRequestCurrencyEnum];

/**
 * 
 * @export
 * @interface CreateInvitation200Response
 */
export interface CreateInvitation200Response {
    /**
     * 
     * @type {any}
     * @memberof CreateInvitation200Response
     */
    'success'?: any;
    /**
     * 
     * @type {string}
     * @memberof CreateInvitation200Response
     */
    'invitation_id'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateInvitationRequest
 */
export interface CreateInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationRequest
     */
    'sender_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationRequest
     */
    'plan_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationRequest
     */
    'group_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationRequest
     */
    'receiver'?: string;
}
/**
 * 
 * @export
 * @interface CreateNote201Response
 */
export interface CreateNote201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateNote201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateNote201Response
     */
    'note_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateNoteRequest
 */
export interface CreateNoteRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequest
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequest
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequest
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequest
     */
    'account'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequest
     */
    'transaction'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequest
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequest
     */
    'investment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequest
     */
    'admin_audit_result'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequest
     */
    'admin_asset_manager'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequest
     */
    'admin_investment_master'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequest
     */
    'admin_document_type'?: CreateNoteRequestAdminDocumentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequest
     */
    'admin_investment'?: string | null;
}

export const CreateNoteRequestAdminDocumentTypeEnum = {
    CapitalAccountStatement: 'Capital Account Statement',
    CapitalCallNotice: 'Capital Call Notice',
    DistributionNotice: 'Distribution Notice',
    TaxDocument: 'Tax Document',
    Other: 'Other',
    NetCapitalCall: 'Net Capital Call',
    NetDistribution: 'Net Distribution',
    HedgeFund: 'Hedge Fund',
    PpmOm: 'PPM/OM',
    PrivacyNotice: 'Privacy Notice',
    DisclosureAdv: 'Disclosure / ADV',
    InvestmentUpdate: 'Investment Update',
    LegalAgreement: 'Legal Agreement',
    WireInstructionConfirmation: 'Wire Instruction / Confirmation',
    ReportingAuthorizationLetter: 'Reporting Authorization Letter'
} as const;

export type CreateNoteRequestAdminDocumentTypeEnum = typeof CreateNoteRequestAdminDocumentTypeEnum[keyof typeof CreateNoteRequestAdminDocumentTypeEnum];

/**
 * 
 * @export
 * @interface CreateRALRequest201Response
 */
export interface CreateRALRequest201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateRALRequest201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateRALRequest201Response
     */
    'ral_request_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateRALRequestRequest
 */
export interface CreateRALRequestRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateRALRequestRequest
     */
    'ownership_ids'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateRALRequestRequest
     */
    'ownership_type'?: CreateRALRequestRequestOwnershipTypeEnum | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateRALRequestRequest
     */
    'tokens'?: { [key: string]: string; };
    /**
     * 
     * @type {Array<CreateRALRequestRequestSignatoriesInner>}
     * @memberof CreateRALRequestRequest
     */
    'signatories'?: Array<CreateRALRequestRequestSignatoriesInner>;
    /**
     * 
     * @type {string}
     * @memberof CreateRALRequestRequest
     */
    'remote_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRALRequestRequest
     */
    'ral_request_template': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRALRequestRequest
     */
    'user': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRALRequestRequest
     */
    'signed_ral'?: string | null;
}

export const CreateRALRequestRequestOwnershipTypeEnum = {
    Account: 'Account',
    Investment: 'Investment',
    Null: 'null'
} as const;

export type CreateRALRequestRequestOwnershipTypeEnum = typeof CreateRALRequestRequestOwnershipTypeEnum[keyof typeof CreateRALRequestRequestOwnershipTypeEnum];

/**
 * 
 * @export
 * @interface CreateRALRequestRequestSignatoriesInner
 */
export interface CreateRALRequestRequestSignatoriesInner {
    /**
     * 
     * @type {string}
     * @memberof CreateRALRequestRequestSignatoriesInner
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRALRequestRequestSignatoriesInner
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRALRequestRequestSignatoriesInner
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRALRequestRequestSignatoriesInner
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface CreateTransaction201Response
 */
export interface CreateTransaction201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateTransaction201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateTransaction201Response
     */
    'transaction_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateTransactionRequest
 */
export interface CreateTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequest
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequest
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof CreateTransactionRequest
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof CreateTransactionRequest
     */
    'units'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTransactionRequest
     */
    'open'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequest
     */
    'joint_transaction'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequest
     */
    'document'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequest
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequest
     */
    'investment': string | null;
}
/**
 * 
 * @export
 * @interface CreateUser201Response
 */
export interface CreateUser201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateUser201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUser201Response
     */
    'user_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'access'?: CreateUserRequestAccessEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserRequest
     */
    'advisors'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'password'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserRequest
     */
    'disable_initial_account_creation'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'status'?: CreateUserRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'captcha'?: string;
}

export const CreateUserRequestAccessEnum = {
    Investor: 'investor',
    Advisor: 'advisor',
    Admin: 'admin'
} as const;

export type CreateUserRequestAccessEnum = typeof CreateUserRequestAccessEnum[keyof typeof CreateUserRequestAccessEnum];
export const CreateUserRequestStatusEnum = {
    Active: 'Active',
    Staging: 'Staging',
    Disabled: 'Disabled'
} as const;

export type CreateUserRequestStatusEnum = typeof CreateUserRequestStatusEnum[keyof typeof CreateUserRequestStatusEnum];

/**
 * 
 * @export
 * @interface CreateValuation201Response
 */
export interface CreateValuation201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateValuation201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateValuation201Response
     */
    'valuation_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateValuationRequest
 */
export interface CreateValuationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateValuationRequest
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof CreateValuationRequest
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof CreateValuationRequest
     */
    'unit_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateValuationRequest
     */
    'investment': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateValuationRequest
     */
    'documents'?: Array<string>;
}
/**
 * Credential field types.
 * @export
 * @enum {string}
 */

export const CredentialFieldType = {
    Text: 'text',
    Password: 'password',
    Select: 'select',
    Checkbox: 'checkbox'
} as const;

export type CredentialFieldType = typeof CredentialFieldType[keyof typeof CredentialFieldType];


/**
 * 
 * @export
 * @interface DeleteAccount200Response
 */
export interface DeleteAccount200Response {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteAccount200Response
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface DeleteInvitation200Response
 */
export interface DeleteInvitation200Response {
    /**
     * 
     * @type {any}
     * @memberof DeleteInvitation200Response
     */
    'success'?: any;
}
/**
 * Supported LOA signing platforms
 * @export
 * @enum {string}
 */

export const DocumentSigningRemoteOptions = {
    Pandadoc: 'pandadoc',
    Docusign: 'docusign'
} as const;

export type DocumentSigningRemoteOptions = typeof DocumentSigningRemoteOptions[keyof typeof DocumentSigningRemoteOptions];


/**
 * Document types.
 * @export
 * @enum {string}
 */

export const DocumentType = {
    CapitalAccountStatement: 'Capital Account Statement',
    CapitalCallNotice: 'Capital Call Notice',
    DistributionNotice: 'Distribution Notice',
    TaxDocument: 'Tax Document',
    Other: 'Other',
    NetCapitalCall: 'Net Capital Call',
    NetDistribution: 'Net Distribution',
    HedgeFund: 'Hedge Fund',
    PpmOm: 'PPM/OM',
    PrivacyNotice: 'Privacy Notice',
    DisclosureAdv: 'Disclosure / ADV',
    InvestmentUpdate: 'Investment Update',
    LegalAgreement: 'Legal Agreement',
    WireInstructionConfirmation: 'Wire Instruction / Confirmation',
    ReportingAuthorizationLetter: 'Reporting Authorization Letter'
} as const;

export type DocumentType = typeof DocumentType[keyof typeof DocumentType];


/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentTypes = {
    CapitalAccountStatement: 'Capital Account Statement',
    CapitalCallNotice: 'Capital Call Notice',
    DistributionNotice: 'Distribution Notice',
    TaxDocument: 'Tax Document',
    Other: 'Other',
    NetCapitalCall: 'Net Capital Call',
    NetDistribution: 'Net Distribution',
    HedgeFund: 'Hedge Fund',
    PpmOm: 'PPM/OM',
    PrivacyNotice: 'Privacy Notice',
    DisclosureAdv: 'Disclosure / ADV',
    InvestmentUpdate: 'Investment Update',
    LegalAgreement: 'Legal Agreement',
    WireInstructionConfirmation: 'Wire Instruction / Confirmation',
    ReportingAuthorizationLetter: 'Reporting Authorization Letter'
} as const;

export type DocumentTypes = typeof DocumentTypes[keyof typeof DocumentTypes];


/**
 * The exit status of a connection sync.
 * @export
 * @enum {string}
 */

export const ExitStatus = {
    Success: 'success',
    Failure: 'failure',
    Pending: 'pending',
    Queued: 'queued'
} as const;

export type ExitStatus = typeof ExitStatus[keyof typeof ExitStatus];


/**
 * File types.
 * @export
 * @enum {string}
 */

export const FileType = {
    Pdf: 'pdf',
    Csv: 'csv',
    Xlsx: 'xlsx',
    Xls: 'xls'
} as const;

export type FileType = typeof FileType[keyof typeof FileType];


/**
 * 
 * @export
 * @interface FinishOAuthRequest
 */
export interface FinishOAuthRequest {
    /**
     * 
     * @type {string}
     * @memberof FinishOAuthRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface ForgotRequest
 */
export interface ForgotRequest {
    /**
     * 
     * @type {string}
     * @memberof ForgotRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface GeneratePandaDocSigningUrls200Response
 */
export interface GeneratePandaDocSigningUrls200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GeneratePandaDocSigningUrls200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {{ [key: string]: Array<GeneratePandaDocSigningUrls200ResponseDataValueInner>; }}
     * @memberof GeneratePandaDocSigningUrls200Response
     */
    'data'?: { [key: string]: Array<GeneratePandaDocSigningUrls200ResponseDataValueInner>; };
}
/**
 * 
 * @export
 * @interface GeneratePandaDocSigningUrls200ResponseDataValueInner
 */
export interface GeneratePandaDocSigningUrls200ResponseDataValueInner {
    /**
     * 
     * @type {string}
     * @memberof GeneratePandaDocSigningUrls200ResponseDataValueInner
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratePandaDocSigningUrls200ResponseDataValueInner
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratePandaDocSigningUrls200ResponseDataValueInner
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratePandaDocSigningUrls200ResponseDataValueInner
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratePandaDocSigningUrls200ResponseDataValueInner
     */
    'signing_url'?: string;
}
/**
 * 
 * @export
 * @interface GetAccount200Response
 */
export interface GetAccount200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetAccount200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {ListAccounts200ResponseAccountsInner}
     * @memberof GetAccount200Response
     */
    'account'?: ListAccounts200ResponseAccountsInner;
}
/**
 * 
 * @export
 * @interface GetActivityCounts200Response
 */
export interface GetActivityCounts200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetActivityCounts200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<GetActivityCounts200ResponseDataInner>}
     * @memberof GetActivityCounts200Response
     */
    'data'?: Array<GetActivityCounts200ResponseDataInner>;
}
/**
 * 
 * @export
 * @interface GetActivityCounts200ResponseDataInner
 */
export interface GetActivityCounts200ResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof GetActivityCounts200ResponseDataInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActivityCounts200ResponseDataInner
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActivityCounts200ResponseDataInner
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetActivityCounts200ResponseDataInner
     */
    'messages_completed'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetActivityCounts200ResponseDataInner
     */
    'messages_changed'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetActivityCounts200ResponseDataInner
     */
    'documents_changed'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetActivityCounts200ResponseDataInner
     */
    'extracts_changed'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetActivityCounts200ResponseDataInner
     */
    'sorts_changed'?: number;
}
/**
 * 
 * @export
 * @interface GetAdminTeam200Response
 */
export interface GetAdminTeam200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetAdminTeam200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {GetAdminTeam200ResponseAdminTeam}
     * @memberof GetAdminTeam200Response
     */
    'admin_team'?: GetAdminTeam200ResponseAdminTeam;
}
/**
 * 
 * @export
 * @interface GetAdminTeam200ResponseAdminTeam
 */
export interface GetAdminTeam200ResponseAdminTeam {
    /**
     * 
     * @type {string}
     * @memberof GetAdminTeam200ResponseAdminTeam
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdminTeam200ResponseAdminTeam
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdminTeam200ResponseAdminTeam
     */
    'type'?: GetAdminTeam200ResponseAdminTeamTypeEnum;
    /**
     * 
     * @type {Array<GetAuditResult200ResponseAuditResultBreadcrumbsAccount>}
     * @memberof GetAdminTeam200ResponseAdminTeam
     */
    'advisory_groups'?: Array<GetAuditResult200ResponseAuditResultBreadcrumbsAccount>;
    /**
     * 
     * @type {string}
     * @memberof GetAdminTeam200ResponseAdminTeam
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdminTeam200ResponseAdminTeam
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdminTeam200ResponseAdminTeam
     */
    'deleted_at'?: string;
}

export const GetAdminTeam200ResponseAdminTeamTypeEnum = {
    AccountManagement: 'account_management',
    Onboarding: 'onboarding'
} as const;

export type GetAdminTeam200ResponseAdminTeamTypeEnum = typeof GetAdminTeam200ResponseAdminTeamTypeEnum[keyof typeof GetAdminTeam200ResponseAdminTeamTypeEnum];

/**
 * 
 * @export
 * @interface GetAdvisoryGroupById200Response
 */
export interface GetAdvisoryGroupById200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetAdvisoryGroupById200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {ListAdvisoryGroups200ResponseAdvisoryGroupsInner}
     * @memberof GetAdvisoryGroupById200Response
     */
    'advisory_group'?: ListAdvisoryGroups200ResponseAdvisoryGroupsInner;
}
/**
 * 
 * @export
 * @interface GetAdvisoryGroupExtegrationSyncById200Response
 */
export interface GetAdvisoryGroupExtegrationSyncById200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetAdvisoryGroupExtegrationSyncById200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSync}
     * @memberof GetAdvisoryGroupExtegrationSyncById200Response
     */
    'extegration_sync'?: GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSync;
}
/**
 * 
 * @export
 * @interface GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSync
 */
export interface GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSync {
    /**
     * 
     * @type {string}
     * @memberof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSync
     */
    '_id'?: string | null;
    /**
     * 
     * @type {Array<GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSyncAttachmentsInner>}
     * @memberof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSync
     */
    'attachments'?: Array<GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSyncAttachmentsInner>;
    /**
     * 
     * @type {Array<ListConnections200ResponseConnectionsInnerAdvisoryGroup>}
     * @memberof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSync
     */
    'entities'?: Array<ListConnections200ResponseConnectionsInnerAdvisoryGroup>;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerAdvisoryGroup}
     * @memberof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSync
     */
    'initiator'?: ListConnections200ResponseConnectionsInnerAdvisoryGroup;
    /**
     * 
     * @type {string}
     * @memberof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSync
     */
    'exit_status'?: GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSyncExitStatusEnum;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSync
     */
    'error_report'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSync
     */
    'group'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSync
     */
    'extegration_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSync
     */
    'extegration_connection_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSync
     */
    'job_id'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSync
     */
    'job_response'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSync
     */
    'started_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSync
     */
    'completed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSync
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSync
     */
    'updated_at'?: string;
}

export const GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSyncExitStatusEnum = {
    Success: 'success',
    Failure: 'failure',
    Pending: 'pending'
} as const;

export type GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSyncExitStatusEnum = typeof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSyncExitStatusEnum[keyof typeof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSyncExitStatusEnum];

/**
 * 
 * @export
 * @interface GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSyncAttachmentsInner
 */
export interface GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSyncAttachmentsInner {
    /**
     * 
     * @type {string}
     * @memberof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSyncAttachmentsInner
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSyncAttachmentsInner
     */
    'mimetype'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSyncAttachmentsInner
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSyncAttachmentsInner
     */
    'added_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSyncAttachmentsInner
     */
    'presigned_url'?: string;
}
/**
 * 
 * @export
 * @interface GetAssetManager200Response
 */
export interface GetAssetManager200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetAssetManager200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {any}
     * @memberof GetAssetManager200Response
     */
    'asset_manager'?: any;
}
/**
 * 
 * @export
 * @interface GetAssetManager404Response
 */
export interface GetAssetManager404Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetAssetManager404Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAssetManager404Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface GetAuditResult200Response
 */
export interface GetAuditResult200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetAuditResult200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResult}
     * @memberof GetAuditResult200Response
     */
    'audit_result'?: GetAuditResult200ResponseAuditResult;
}
/**
 * 
 * @export
 * @interface GetAuditResult200ResponseAuditResult
 */
export interface GetAuditResult200ResponseAuditResult {
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    '_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'action'?: string;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultCompletedBy}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'completed_by'?: GetAuditResult200ResponseAuditResultCompletedBy;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'completed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'sla_level'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'deadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'status_changed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'ignore_until'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'read'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'flagged'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'flagged_at'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'notes'?: Array<string>;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbs}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'breadcrumbs'?: GetAuditResult200ResponseAuditResultBreadcrumbs;
    /**
     * 
     * @type {object}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'structured_data'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'check_subname'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'task_specific_hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'signature'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'parent_type'?: GetAuditResult200ResponseAuditResultParentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'parent_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'check_name'?: GetAuditResult200ResponseAuditResultCheckNameEnum;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'func_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResult
     */
    'deleted_at'?: string;
}

export const GetAuditResult200ResponseAuditResultParentTypeEnum = {
    User: 'User',
    Account: 'Account',
    Investment: 'Investment',
    Document: 'Document',
    Transaction: 'Transaction',
    Valuation: 'Valuation',
    Connection: 'Connection',
    Message: 'Message',
    InvestmentMaster: 'InvestmentMaster',
    ExtegrationSync: 'ExtegrationSync'
} as const;

export type GetAuditResult200ResponseAuditResultParentTypeEnum = typeof GetAuditResult200ResponseAuditResultParentTypeEnum[keyof typeof GetAuditResult200ResponseAuditResultParentTypeEnum];
export const GetAuditResult200ResponseAuditResultCheckNameEnum = {
    SecurityMasterDocumentsReceivedByAllInvestments: 'Security Master Documents Received by All Investments',
    InvestmentUnderAdministrativeReview: 'Investment Under Administrative Review',
    UnrealizedValueSpike: 'Unrealized Value Spike',
    EndingBeginningBalanceMismatch: 'Ending/beginning balance mismatch',
    InvalidDateRange: 'Invalid date range',
    InaccurateDistributionsSum: 'Inaccurate distributions sum',
    InaccurateContributionsSum: 'Inaccurate contributions sum',
    CapNavStatementTimeliness: 'Cap/NAV Statement Timeliness',
    HaveCapNavStatementEveryPeriod: 'Have Cap/NAV Statement every period',
    TaxDocumentTimeliness: 'Tax Document Timeliness',
    HasTaxDocumentEveryYearSinceInvestmentStart: 'Has Tax Document every year since investment start',
    TransactionWithDefaultDateData: 'Transaction with default date data',
    DuplicateTransactions: 'Duplicate Transactions',
    UnsortedDocument: 'Unsorted Document',
    CapitalStatementWithInvalidMetadata: 'Capital Statement With Invalid Metadata',
    CapitalCallWithInvalidMetadata: 'Capital Call With Invalid Metadata',
    DistributionWithInvalidMetadata: 'Distribution With Invalid Metadata',
    NavStatementWithInvalidMetadata: 'NAV Statement WithInvalid Metadata',
    NetCapitalCallWithInvalidMetadata: 'Net Capital Call With Invalid Metadata',
    NetDistributionWithInvalidMetadata: 'Net Distribution With Invalid Metadata',
    TaxDocumentWithInvalidMetadata: 'Tax Document With Invalid Metadata',
    ManualConnectionPortalSync: 'Manual Connection Portal Sync',
    ConnectionStatusNotActive: 'Connection Status Not Active',
    ConnectionWithoutContacts: 'Connection Without Contacts',
    ConnectionCompletedByAssetManager: 'Connection Completed By Asset Manager',
    UnmappedDataInMostRecentConnectionSyncStagingArea: 'Unmapped Data in Most Recent Connection Sync (Staging Area)',
    MessagesUnreadFor3Days: 'Messages Unread for 3 Days',
    MessagesWithDocumentsAttached: 'Messages with Documents Attached',
    MessagesFlaggedFor5Days: 'Messages Flagged for 5 Days',
    FundInvestmentsWithoutSecurityMasterTicker: 'Fund investments without Security Master Ticker',
    FundInvestmentsMissingSubtype: 'Fund investments missing Subtype',
    NonHiddenInvestmentHasTypeDiverseInvestments: 'Non-hidden Investment has type Diverse Investments',
    InvestmentsWithoutPastYearTaxDoc: 'Investments without past year tax doc',
    AbnormalInvestmentIrr: 'Abnormal Investment IRR',
    InvestmentHiddenTooLong: 'Investment Hidden Too Long',
    InvestmentTypeStatementTypeMismatch: 'Investment Type / Statement Type Mismatch',
    InvestmentWithNegativeUnfundedAmount: 'Investment with negative unfunded amount',
    ExtegrationSyncFailedByAdvisoryGroup: 'Extegration Sync Failed by Advisory Group'
} as const;

export type GetAuditResult200ResponseAuditResultCheckNameEnum = typeof GetAuditResult200ResponseAuditResultCheckNameEnum[keyof typeof GetAuditResult200ResponseAuditResultCheckNameEnum];

/**
 * 
 * @export
 * @interface GetAuditResult200ResponseAuditResultBreadcrumbs
 */
export interface GetAuditResult200ResponseAuditResultBreadcrumbs {
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsUser}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbs
     */
    'user'?: GetAuditResult200ResponseAuditResultBreadcrumbsUser;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbs
     */
    'account'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbs
     */
    'investment'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbs
     */
    'document'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbs
     */
    'transaction'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbs
     */
    'valuation'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbs
     */
    'connection'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbs
     */
    'message'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbs
     */
    'investment_master'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbs
     */
    'integration'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbs
     */
    'ownership_type'?: GetAuditResult200ResponseAuditResultBreadcrumbsOwnershipTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbs
     */
    'ownership_ids'?: Array<string>;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbs
     */
    'extegrationsync'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbs
     */
    'advisorygroup'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
}

export const GetAuditResult200ResponseAuditResultBreadcrumbsOwnershipTypeEnum = {
    Account: 'Account',
    Investment: 'Investment'
} as const;

export type GetAuditResult200ResponseAuditResultBreadcrumbsOwnershipTypeEnum = typeof GetAuditResult200ResponseAuditResultBreadcrumbsOwnershipTypeEnum[keyof typeof GetAuditResult200ResponseAuditResultBreadcrumbsOwnershipTypeEnum];

/**
 * 
 * @export
 * @interface GetAuditResult200ResponseAuditResultBreadcrumbsAccount
 */
export interface GetAuditResult200ResponseAuditResultBreadcrumbsAccount {
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbsAccount
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbsAccount
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface GetAuditResult200ResponseAuditResultBreadcrumbsUser
 */
export interface GetAuditResult200ResponseAuditResultBreadcrumbsUser {
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbsUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbsUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbsUser
     */
    'email'?: string;
    /**
     * 
     * @type {Array<GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInner>}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbsUser
     */
    'advisors'?: Array<GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInner>;
}
/**
 * 
 * @export
 * @interface GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInner
 */
export interface GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInner {
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInnerGroup}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInner
     */
    'group'?: GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInnerGroup;
}
/**
 * 
 * @export
 * @interface GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInnerGroup
 */
export interface GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInnerGroup {
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInnerGroup
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface GetAuditResult200ResponseAuditResultCompletedBy
 */
export interface GetAuditResult200ResponseAuditResultCompletedBy {
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResultCompletedBy
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResultCompletedBy
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResult200ResponseAuditResultCompletedBy
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface GetAuditResultTypes200Response
 */
export interface GetAuditResultTypes200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetAuditResultTypes200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<GetAuditResultTypes200ResponseAuditResultTypesInner>}
     * @memberof GetAuditResultTypes200Response
     */
    'audit_result_types'?: Array<GetAuditResultTypes200ResponseAuditResultTypesInner>;
}
/**
 * 
 * @export
 * @interface GetAuditResultTypes200ResponseAuditResultTypesInner
 */
export interface GetAuditResultTypes200ResponseAuditResultTypesInner {
    /**
     * 
     * @type {string}
     * @memberof GetAuditResultTypes200ResponseAuditResultTypesInner
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResultTypes200ResponseAuditResultTypesInner
     */
    'check_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAuditResultTypes200ResponseAuditResultTypesInner
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetAuditResultTypes200ResponseAuditResultTypesInnerSubtypesInner>}
     * @memberof GetAuditResultTypes200ResponseAuditResultTypesInner
     */
    'subtypes'?: Array<GetAuditResultTypes200ResponseAuditResultTypesInnerSubtypesInner>;
}
/**
 * 
 * @export
 * @interface GetAuditResultTypes200ResponseAuditResultTypesInnerSubtypesInner
 */
export interface GetAuditResultTypes200ResponseAuditResultTypesInnerSubtypesInner {
    /**
     * 
     * @type {number}
     * @memberof GetAuditResultTypes200ResponseAuditResultTypesInnerSubtypesInner
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResultTypes200ResponseAuditResultTypesInnerSubtypesInner
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResultTypes200ResponseAuditResultTypesInnerSubtypesInner
     */
    'check_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditResultTypes200ResponseAuditResultTypesInnerSubtypesInner
     */
    'check_subname'?: string;
}
/**
 * 
 * @export
 * @interface GetCommitment200Response
 */
export interface GetCommitment200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetCommitment200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {GetCommitment200ResponseCommitment}
     * @memberof GetCommitment200Response
     */
    'commitment'?: GetCommitment200ResponseCommitment;
}
/**
 * 
 * @export
 * @interface GetCommitment200ResponseCommitment
 */
export interface GetCommitment200ResponseCommitment {
    /**
     * 
     * @type {string}
     * @memberof GetCommitment200ResponseCommitment
     */
    '_id'?: string;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetCommitment200ResponseCommitment
     */
    'user'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetCommitment200ResponseCommitment
     */
    'account'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {ListCommitments200ResponseCommitmentsInnerInvestment}
     * @memberof GetCommitment200ResponseCommitment
     */
    'investment'?: ListCommitments200ResponseCommitmentsInnerInvestment;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetCommitment200ResponseCommitment
     */
    'connection'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {Array<GetAuditResult200ResponseAuditResultBreadcrumbsAccount>}
     * @memberof GetCommitment200ResponseCommitment
     */
    'documents'?: Array<GetAuditResult200ResponseAuditResultBreadcrumbsAccount>;
    /**
     * 
     * @type {boolean}
     * @memberof GetCommitment200ResponseCommitment
     */
    'auto_extracted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCommitment200ResponseCommitment
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCommitment200ResponseCommitment
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCommitment200ResponseCommitment
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommitment200ResponseCommitment
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommitment200ResponseCommitment
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerAuditLogInner>}
     * @memberof GetCommitment200ResponseCommitment
     */
    'audit_log'?: Array<ListAccounts200ResponseAccountsInnerAuditLogInner>;
}
/**
 * 
 * @export
 * @interface GetConnectionById200Response
 */
export interface GetConnectionById200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetConnectionById200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {GetConnectionById200ResponseConnection}
     * @memberof GetConnectionById200Response
     */
    'connection'?: GetConnectionById200ResponseConnection;
}
/**
 * 
 * @export
 * @interface GetConnectionById200ResponseConnection
 */
export interface GetConnectionById200ResponseConnection {
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnection
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnection
     */
    'name'?: string;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerIntegration}
     * @memberof GetConnectionById200ResponseConnection
     */
    'integration'?: ListConnections200ResponseConnectionsInnerIntegration;
    /**
     * 
     * @type {GetConnectionById200ResponseConnectionUser}
     * @memberof GetConnectionById200ResponseConnection
     */
    'user'?: GetConnectionById200ResponseConnectionUser;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerAdvisoryGroup}
     * @memberof GetConnectionById200ResponseConnection
     */
    'advisory_group'?: ListConnections200ResponseConnectionsInnerAdvisoryGroup;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInnerGroup}
     * @memberof GetConnectionById200ResponseConnection
     */
    'account'?: GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInnerGroup;
    /**
     * 
     * @type {Array<GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInnerGroup>}
     * @memberof GetConnectionById200ResponseConnection
     */
    'account_list'?: Array<GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInnerGroup>;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInnerGroup}
     * @memberof GetConnectionById200ResponseConnection
     */
    'asset_manager'?: GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInnerGroup;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerUser>}
     * @memberof GetConnectionById200ResponseConnection
     */
    'contacts'?: Array<ListAccounts200ResponseAccountsInnerUser>;
    /**
     * 
     * @type {GetConnectionById200ResponseConnectionMostRecentSync}
     * @memberof GetConnectionById200ResponseConnection
     */
    'most_recent_sync'?: GetConnectionById200ResponseConnectionMostRecentSync;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerMostRecentImport}
     * @memberof GetConnectionById200ResponseConnection
     */
    'most_recent_import'?: ListConnections200ResponseConnectionsInnerMostRecentImport;
    /**
     * 
     * @type {Array<ListConnections200ResponseConnectionsInnerAuditLogInner>}
     * @memberof GetConnectionById200ResponseConnection
     */
    'audit_log'?: Array<ListConnections200ResponseConnectionsInnerAuditLogInner>;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnection
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnection
     */
    'ral_status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetConnectionById200ResponseConnection
     */
    'is_enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetConnectionById200ResponseConnection
     */
    'long_running'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnection
     */
    'outreach_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnection
     */
    'outreach_status_updated_at'?: string;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerError}
     * @memberof GetConnectionById200ResponseConnection
     */
    'error'?: ListConnections200ResponseConnectionsInnerError;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerErrorInformation}
     * @memberof GetConnectionById200ResponseConnection
     */
    'error_information'?: ListConnections200ResponseConnectionsInnerErrorInformation;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof GetConnectionById200ResponseConnection
     */
    'credentials'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnection
     */
    'tfa_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnection
     */
    'tfa_phone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetConnectionById200ResponseConnection
     */
    'require_phone'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetConnectionById200ResponseConnection
     */
    'expect_documents_over_email'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnection
     */
    'newest_data_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnection
     */
    'last_connection_at'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof GetConnectionById200ResponseConnection
     */
    'data'?: { [key: string]: any; };
    /**
     * 
     * @type {boolean}
     * @memberof GetConnectionById200ResponseConnection
     */
    'use_tfa'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetConnectionById200ResponseConnection
     */
    'test'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetConnectionById200ResponseConnection
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnection
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnection
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnection
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {Array<GetConnectionById200ResponseConnectionMessagesInner>}
     * @memberof GetConnectionById200ResponseConnection
     */
    'messages'?: Array<GetConnectionById200ResponseConnectionMessagesInner>;
}
/**
 * 
 * @export
 * @interface GetConnectionById200ResponseConnectionMessagesInner
 */
export interface GetConnectionById200ResponseConnectionMessagesInner {
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMessagesInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMessagesInner
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMessagesInner
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMessagesInner
     */
    'to'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMessagesInner
     */
    'account'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMessagesInner
     */
    'user'?: string;
    /**
     * 
     * @type {Array<GetConnectionById200ResponseConnectionMessagesInnerAttachmentsInner>}
     * @memberof GetConnectionById200ResponseConnectionMessagesInner
     */
    'attachments'?: Array<GetConnectionById200ResponseConnectionMessagesInnerAttachmentsInner>;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerMessagesInnerMetadata}
     * @memberof GetConnectionById200ResponseConnectionMessagesInner
     */
    'metadata'?: ListConnections200ResponseConnectionsInnerMessagesInnerMetadata;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMessagesInner
     */
    'category'?: GetConnectionById200ResponseConnectionMessagesInnerCategoryEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetConnectionById200ResponseConnectionMessagesInner
     */
    'connection_expects_documents'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetConnectionById200ResponseConnectionMessagesInner
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof GetConnectionById200ResponseConnectionMessagesInner
     */
    'completed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMessagesInner
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMessagesInner
     */
    'body'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetConnectionById200ResponseConnectionMessagesInner
     */
    'parsed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMessagesInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMessagesInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMessagesInner
     */
    'deleted_at'?: string;
}

export const GetConnectionById200ResponseConnectionMessagesInnerCategoryEnum = {
    Tfa: 'tfa',
    Portal: 'portal',
    Deactivated: 'deactivated'
} as const;

export type GetConnectionById200ResponseConnectionMessagesInnerCategoryEnum = typeof GetConnectionById200ResponseConnectionMessagesInnerCategoryEnum[keyof typeof GetConnectionById200ResponseConnectionMessagesInnerCategoryEnum];

/**
 * 
 * @export
 * @interface GetConnectionById200ResponseConnectionMessagesInnerAttachmentsInner
 */
export interface GetConnectionById200ResponseConnectionMessagesInnerAttachmentsInner {
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMessagesInnerAttachmentsInner
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMessagesInnerAttachmentsInner
     */
    'mimetype'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMessagesInnerAttachmentsInner
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMessagesInnerAttachmentsInner
     */
    'added_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMessagesInnerAttachmentsInner
     */
    '_id'?: string;
}
/**
 * 
 * @export
 * @interface GetConnectionById200ResponseConnectionMostRecentSync
 */
export interface GetConnectionById200ResponseConnectionMostRecentSync {
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMostRecentSync
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMostRecentSync
     */
    'updated_at'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetConnectionById200ResponseConnectionMostRecentSync
     */
    'completion_result'?: object;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof GetConnectionById200ResponseConnectionMostRecentSync
     */
    'error_report'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMostRecentSync
     */
    'job_id'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof GetConnectionById200ResponseConnectionMostRecentSync
     */
    'job_response'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMostRecentSync
     */
    'error_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMostRecentSync
     */
    'completed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMostRecentSync
     */
    'exit_status'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetConnectionById200ResponseConnectionMostRecentSync
     */
    'job_request'?: object;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMostRecentSync
     */
    'connection'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionMostRecentSync
     */
    'user'?: string;
}
/**
 * 
 * @export
 * @interface GetConnectionById200ResponseConnectionUser
 */
export interface GetConnectionById200ResponseConnectionUser {
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionById200ResponseConnectionUser
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetConnectionById200ResponseConnectionUser
     */
    'is_deactivated'?: boolean;
    /**
     * 
     * @type {Array<GetConnectionById200ResponseConnectionUserAdvisorsInner>}
     * @memberof GetConnectionById200ResponseConnectionUser
     */
    'advisors'?: Array<GetConnectionById200ResponseConnectionUserAdvisorsInner>;
}
/**
 * 
 * @export
 * @interface GetConnectionById200ResponseConnectionUserAdvisorsInner
 */
export interface GetConnectionById200ResponseConnectionUserAdvisorsInner {
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerAdvisoryGroup}
     * @memberof GetConnectionById200ResponseConnectionUserAdvisorsInner
     */
    'group'?: ListConnections200ResponseConnectionsInnerAdvisoryGroup;
}
/**
 * 
 * @export
 * @interface GetDefaultDocumentImportSetting200Response
 */
export interface GetDefaultDocumentImportSetting200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetDefaultDocumentImportSetting200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSetting}
     * @memberof GetDefaultDocumentImportSetting200Response
     */
    'default_document_import_setting'?: GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSetting;
}
/**
 * 
 * @export
 * @interface GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSetting
 */
export interface GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSetting {
    /**
     * 
     * @type {string}
     * @memberof GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSetting
     */
    '_id'?: string;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerAdvisoryGroup}
     * @memberof GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSetting
     */
    'integration'?: ListConnections200ResponseConnectionsInnerAdvisoryGroup;
    /**
     * 
     * @type {ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAdvisoryGroup}
     * @memberof GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSetting
     */
    'asset_manager'?: ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAdvisoryGroup;
    /**
     * 
     * @type {ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAdvisoryGroup}
     * @memberof GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSetting
     */
    'advisory_group'?: ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAdvisoryGroup;
    /**
     * 
     * @type {string}
     * @memberof GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSetting
     */
    'global_default_extraction_setting'?: GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSettingGlobalDefaultExtractionSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSetting
     */
    'global_default_auto_sort_setting'?: GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSettingGlobalDefaultAutoSortSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSetting
     */
    'global_default_transaction_setting'?: GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSettingGlobalDefaultTransactionSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSetting
     */
    'global_default_process_setting'?: GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSettingGlobalDefaultProcessSettingEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSetting
     */
    'default_transaction_settings'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSetting
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSetting
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSetting
     */
    'deleted_at'?: string;
}

export const GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSettingGlobalDefaultExtractionSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSettingGlobalDefaultExtractionSettingEnum = typeof GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSettingGlobalDefaultExtractionSettingEnum[keyof typeof GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSettingGlobalDefaultExtractionSettingEnum];
export const GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSettingGlobalDefaultAutoSortSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSettingGlobalDefaultAutoSortSettingEnum = typeof GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSettingGlobalDefaultAutoSortSettingEnum[keyof typeof GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSettingGlobalDefaultAutoSortSettingEnum];
export const GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSettingGlobalDefaultTransactionSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSettingGlobalDefaultTransactionSettingEnum = typeof GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSettingGlobalDefaultTransactionSettingEnum[keyof typeof GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSettingGlobalDefaultTransactionSettingEnum];
export const GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSettingGlobalDefaultProcessSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSettingGlobalDefaultProcessSettingEnum = typeof GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSettingGlobalDefaultProcessSettingEnum[keyof typeof GetDefaultDocumentImportSetting200ResponseDefaultDocumentImportSettingGlobalDefaultProcessSettingEnum];

/**
 * 
 * @export
 * @interface GetDocument200Response
 */
export interface GetDocument200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetDocument200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {GetDocument200ResponseDocument}
     * @memberof GetDocument200Response
     */
    'document'?: GetDocument200ResponseDocument;
}
/**
 * 
 * @export
 * @interface GetDocument200ResponseDocument
 */
export interface GetDocument200ResponseDocument {
    /**
     * 
     * @type {string}
     * @memberof GetDocument200ResponseDocument
     */
    '_id'?: string;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerUser}
     * @memberof GetDocument200ResponseDocument
     */
    'user'?: ListDocuments200ResponseDocumentsInnerUser;
    /**
     * 
     * @type {Array<GetDocument200ResponseDocumentOwnershipIdsInner>}
     * @memberof GetDocument200ResponseDocument
     */
    'ownership_ids'?: Array<GetDocument200ResponseDocumentOwnershipIdsInner>;
    /**
     * 
     * @type {string}
     * @memberof GetDocument200ResponseDocument
     */
    'ownership_type'?: string;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetDocument200ResponseDocument
     */
    'connection'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {Array<ListDocuments200ResponseDocumentsInnerMetadataInner>}
     * @memberof GetDocument200ResponseDocument
     */
    'metadata'?: Array<ListDocuments200ResponseDocumentsInnerMetadataInner>;
    /**
     * 
     * @type {string}
     * @memberof GetDocument200ResponseDocument
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDocument200ResponseDocument
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetDocument200ResponseDocument
     */
    'hasValidMetadata'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetDocument200ResponseDocument
     */
    'shouldGenerateTransactions'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetDocument200ResponseDocument
     */
    'shouldProcessDocument'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetDocument200ResponseDocument
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDocument200ResponseDocument
     */
    'hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDocument200ResponseDocument
     */
    'file_type'?: string;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerFiles}
     * @memberof GetDocument200ResponseDocument
     */
    'files'?: ListDocuments200ResponseDocumentsInnerFiles;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerTraining}
     * @memberof GetDocument200ResponseDocument
     */
    'training'?: ListDocuments200ResponseDocumentsInnerTraining;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerTraining}
     * @memberof GetDocument200ResponseDocument
     */
    'post_job_review_required'?: ListDocuments200ResponseDocumentsInnerTraining;
    /**
     * 
     * @type {string}
     * @memberof GetDocument200ResponseDocument
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDocument200ResponseDocument
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDocument200ResponseDocument
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerFileDownloadUrls}
     * @memberof GetDocument200ResponseDocument
     */
    'file_download_urls'?: ListDocuments200ResponseDocumentsInnerFileDownloadUrls;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerAuditLogInner>}
     * @memberof GetDocument200ResponseDocument
     */
    'audit_log'?: Array<ListAccounts200ResponseAccountsInnerAuditLogInner>;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerTransactionDescriptions}
     * @memberof GetDocument200ResponseDocument
     */
    'transaction_descriptions'?: ListDocuments200ResponseDocumentsInnerTransactionDescriptions;
}
/**
 * 
 * @export
 * @interface GetDocument200ResponseDocumentOwnershipIdsInner
 */
export interface GetDocument200ResponseDocumentOwnershipIdsInner {
    /**
     * 
     * @type {string}
     * @memberof GetDocument200ResponseDocumentOwnershipIdsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDocument200ResponseDocumentOwnershipIdsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDocument200ResponseDocumentOwnershipIdsInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDocument200ResponseDocumentOwnershipIdsInner
     */
    'currency'?: string;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetDocument200ResponseDocumentOwnershipIdsInner
     */
    'user'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetDocument200ResponseDocumentOwnershipIdsInner
     */
    'account'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
}
/**
 * 
 * @export
 * @interface GetDocumentsDiff200Response
 */
export interface GetDocumentsDiff200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetDocumentsDiff200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetDocumentsDiff200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<GetDocumentsDiff200ResponseDocumentsDiffInner>}
     * @memberof GetDocumentsDiff200Response
     */
    'documents_diff'?: Array<GetDocumentsDiff200ResponseDocumentsDiffInner>;
}
/**
 * 
 * @export
 * @interface GetDocumentsDiff200ResponseDocumentsDiffInner
 */
export interface GetDocumentsDiff200ResponseDocumentsDiffInner {
    /**
     * 
     * @type {string}
     * @memberof GetDocumentsDiff200ResponseDocumentsDiffInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentsDiff200ResponseDocumentsDiffInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentsDiff200ResponseDocumentsDiffInner
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentsDiff200ResponseDocumentsDiffInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentsDiff200ResponseDocumentsDiffInner
     */
    'user'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentsDiff200ResponseDocumentsDiffInner
     */
    'ownership_type'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDocumentsDiff200ResponseDocumentsDiffInner
     */
    'ownership_ids'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentsDiff200ResponseDocumentsDiffInner
     */
    'external_id'?: string;
    /**
     * 
     * @type {GetDocumentsDiff200ResponseDocumentsDiffInnerFileDownloadUrls}
     * @memberof GetDocumentsDiff200ResponseDocumentsDiffInner
     */
    'file_download_urls'?: GetDocumentsDiff200ResponseDocumentsDiffInnerFileDownloadUrls;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentsDiff200ResponseDocumentsDiffInner
     */
    'connection_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentsDiff200ResponseDocumentsDiffInner
     */
    'connection_integration_name'?: string;
}
/**
 * 
 * @export
 * @interface GetDocumentsDiff200ResponseDocumentsDiffInnerFileDownloadUrls
 */
export interface GetDocumentsDiff200ResponseDocumentsDiffInnerFileDownloadUrls {
    /**
     * 
     * @type {string}
     * @memberof GetDocumentsDiff200ResponseDocumentsDiffInnerFileDownloadUrls
     */
    'doc'?: string;
}
/**
 * 
 * @export
 * @interface GetExtIntegrationData200Response
 */
export interface GetExtIntegrationData200Response {
    /**
     * 
     * @type {string}
     * @memberof GetExtIntegrationData200Response
     */
    'last_successful_sync_time'?: string;
    /**
     * 
     * @type {any}
     * @memberof GetExtIntegrationData200Response
     */
    'connection_syncs_diff'?: any;
    /**
     * 
     * @type {any}
     * @memberof GetExtIntegrationData200Response
     */
    'ral_requests'?: any;
    /**
     * 
     * @type {any}
     * @memberof GetExtIntegrationData200Response
     */
    'advisory_groups'?: any;
    /**
     * 
     * @type {any}
     * @memberof GetExtIntegrationData200Response
     */
    'advisors'?: any;
    /**
     * 
     * @type {any}
     * @memberof GetExtIntegrationData200Response
     */
    'users'?: any;
    /**
     * 
     * @type {any}
     * @memberof GetExtIntegrationData200Response
     */
    'credentials'?: any;
    /**
     * 
     * @type {any}
     * @memberof GetExtIntegrationData200Response
     */
    'secrets'?: any;
    /**
     * 
     * @type {any}
     * @memberof GetExtIntegrationData200Response
     */
    'entities'?: any;
    /**
     * 
     * @type {any}
     * @memberof GetExtIntegrationData200Response
     */
    'investments'?: any;
    /**
     * 
     * @type {any}
     * @memberof GetExtIntegrationData200Response
     */
    'transactions'?: any;
    /**
     * 
     * @type {any}
     * @memberof GetExtIntegrationData200Response
     */
    'normalized_transactions'?: any;
    /**
     * 
     * @type {any}
     * @memberof GetExtIntegrationData200Response
     */
    'valuation_transactions'?: any;
    /**
     * 
     * @type {any}
     * @memberof GetExtIntegrationData200Response
     */
    'commitments'?: any;
    /**
     * 
     * @type {any}
     * @memberof GetExtIntegrationData200Response
     */
    'types'?: any;
    /**
     * 
     * @type {any}
     * @memberof GetExtIntegrationData200Response
     */
    'investment_masters'?: any;
    /**
     * 
     * @type {any}
     * @memberof GetExtIntegrationData200Response
     */
    'valuations'?: any;
}
/**
 * 
 * @export
 * @interface GetExtegrationConnection200Response
 */
export interface GetExtegrationConnection200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetExtegrationConnection200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {ListAdvisoryGroups200ResponseAdvisoryGroupsInnerExtegrationsInner}
     * @memberof GetExtegrationConnection200Response
     */
    'extegration'?: ListAdvisoryGroups200ResponseAdvisoryGroupsInnerExtegrationsInner;
}
/**
 * 
 * @export
 * @interface GetIntegration200Response
 */
export interface GetIntegration200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetIntegration200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {ListIntegrations200ResponseIntegrationsInner}
     * @memberof GetIntegration200Response
     */
    'integration'?: ListIntegrations200ResponseIntegrationsInner;
}
/**
 * 
 * @export
 * @interface GetInvestment200Response
 */
export interface GetInvestment200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetInvestment200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {ListInvestments200ResponseInvestmentsInner}
     * @memberof GetInvestment200Response
     */
    'investment'?: ListInvestments200ResponseInvestmentsInner;
}
/**
 * 
 * @export
 * @interface GetInvitationById200Response
 */
export interface GetInvitationById200Response {
    /**
     * 
     * @type {any}
     * @memberof GetInvitationById200Response
     */
    'success'?: any;
    /**
     * 
     * @type {number}
     * @memberof GetInvitationById200Response
     */
    'total'?: number;
    /**
     * 
     * @type {ListInvitations200ResponseInvitationsInner}
     * @memberof GetInvitationById200Response
     */
    'invitation'?: ListInvitations200ResponseInvitationsInner;
}
/**
 * 
 * @export
 * @interface GetMessageById200Response
 */
export interface GetMessageById200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetMessageById200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {GetMessageById200ResponseMessage}
     * @memberof GetMessageById200Response
     */
    'message'?: GetMessageById200ResponseMessage;
}
/**
 * 
 * @export
 * @interface GetMessageById200ResponseMessage
 */
export interface GetMessageById200ResponseMessage {
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    'type'?: string;
    /**
     * 
     * @type {Array<GetMessageById200ResponseMessageConnectionsInner>}
     * @memberof GetMessageById200ResponseMessage
     */
    'connections'?: Array<GetMessageById200ResponseMessageConnectionsInner>;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    'to'?: string;
    /**
     * 
     * @type {ListInvestments200ResponseInvestmentsInnerUser}
     * @memberof GetMessageById200ResponseMessage
     */
    'user'?: ListInvestments200ResponseInvestmentsInnerUser;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    'body'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetMessageById200ResponseMessage
     */
    'parsed'?: boolean;
    /**
     * 
     * @type {Array<GetMessageById200ResponseMessageAttachmentsInner>}
     * @memberof GetMessageById200ResponseMessage
     */
    'attachments'?: Array<GetMessageById200ResponseMessageAttachmentsInner>;
    /**
     * 
     * @type {GetMessageById200ResponseMessageEmailData}
     * @memberof GetMessageById200ResponseMessage
     */
    'email_data'?: GetMessageById200ResponseMessageEmailData;
    /**
     * 
     * @type {GetMessageById200ResponseMessageMetadata}
     * @memberof GetMessageById200ResponseMessage
     */
    'metadata'?: GetMessageById200ResponseMessageMetadata;
    /**
     * 
     * @type {boolean}
     * @memberof GetMessageById200ResponseMessage
     */
    'completed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    'category'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetMessageById200ResponseMessage
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof GetMessageById200ResponseMessage
     */
    'connection_expects_documents'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerAuditLogInner>}
     * @memberof GetMessageById200ResponseMessage
     */
    'audit_log'?: Array<ListAccounts200ResponseAccountsInnerAuditLogInner>;
}
/**
 * 
 * @export
 * @interface GetMessageById200ResponseMessageAttachmentsInner
 */
export interface GetMessageById200ResponseMessageAttachmentsInner {
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessageAttachmentsInner
     */
    'presigned_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessageAttachmentsInner
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessageAttachmentsInner
     */
    'mimetype'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessageAttachmentsInner
     */
    'key'?: string;
}
/**
 * 
 * @export
 * @interface GetMessageById200ResponseMessageConnectionsInner
 */
export interface GetMessageById200ResponseMessageConnectionsInner {
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessageConnectionsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessageConnectionsInner
     */
    'name'?: string;
    /**
     * 
     * @type {ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser}
     * @memberof GetMessageById200ResponseMessageConnectionsInner
     */
    'integration'?: ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessageConnectionsInner
     */
    'status'?: string;
    /**
     * 
     * @type {ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser}
     * @memberof GetMessageById200ResponseMessageConnectionsInner
     */
    'advisory_group'?: ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser;
}
/**
 * 
 * @export
 * @interface GetMessageById200ResponseMessageEmailData
 */
export interface GetMessageById200ResponseMessageEmailData {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessageEmailData
     */
    'cc'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessageEmailData
     */
    'raw_from'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessageEmailData
     */
    'raw_to'?: string;
}
/**
 * 
 * @export
 * @interface GetMessageById200ResponseMessageMetadata
 */
export interface GetMessageById200ResponseMessageMetadata {
    /**
     * 
     * @type {GetMessageById200ResponseMessageMetadataAdmin}
     * @memberof GetMessageById200ResponseMessageMetadata
     */
    'admin'?: GetMessageById200ResponseMessageMetadataAdmin;
    /**
     * 
     * @type {GetMessageById200ResponseMessageMetadataAdmin}
     * @memberof GetMessageById200ResponseMessageMetadata
     */
    'advisor'?: GetMessageById200ResponseMessageMetadataAdmin;
    /**
     * 
     * @type {GetMessageById200ResponseMessageMetadataAdmin}
     * @memberof GetMessageById200ResponseMessageMetadata
     */
    'investor'?: GetMessageById200ResponseMessageMetadataAdmin;
}
/**
 * 
 * @export
 * @interface GetMessageById200ResponseMessageMetadataAdmin
 */
export interface GetMessageById200ResponseMessageMetadataAdmin {
    /**
     * 
     * @type {boolean}
     * @memberof GetMessageById200ResponseMessageMetadataAdmin
     */
    'flagged'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessageMetadataAdmin
     */
    'flagged_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetMessageById200ResponseMessageMetadataAdmin
     */
    'read'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessageMetadataAdmin
     */
    'read_at'?: string;
}
/**
 * 
 * @export
 * @interface GetNote200Response
 */
export interface GetNote200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetNote200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {GetNote200ResponseNote}
     * @memberof GetNote200Response
     */
    'note'?: GetNote200ResponseNote;
}
/**
 * 
 * @export
 * @interface GetNote200ResponseNote
 */
export interface GetNote200ResponseNote {
    /**
     * 
     * @type {string}
     * @memberof GetNote200ResponseNote
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNote200ResponseNote
     */
    'type'?: GetNote200ResponseNoteTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetNote200ResponseNote
     */
    'body'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNote200ResponseNote
     */
    'creator'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNote200ResponseNote
     */
    'editor'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNote200ResponseNote
     */
    'user'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNote200ResponseNote
     */
    'account'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNote200ResponseNote
     */
    'investment'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNote200ResponseNote
     */
    'document'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNote200ResponseNote
     */
    'transaction'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNote200ResponseNote
     */
    'admin_audit_result'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNote200ResponseNote
     */
    'admin_asset_manager'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNote200ResponseNote
     */
    'admin_investment_master'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNote200ResponseNote
     */
    'admin_investment'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNote200ResponseNote
     */
    'admin_document_type'?: string;
}

export const GetNote200ResponseNoteTypeEnum = {
    Transaction: 'transaction',
    Investment: 'investment',
    Account: 'account',
    User: 'user',
    Document: 'document',
    AuditResult: 'auditResult',
    AssetManager: 'assetManager',
    InvestmentMaster: 'investmentMaster',
    DocumentType: 'documentType',
    AdminInvestment: 'adminInvestment',
    AuditResult2: 'audit_result',
    AssetManager2: 'asset_manager',
    InvestmentMaster2: 'investment_master',
    DocumentType2: 'document_type',
    AdminAuditResult: 'admin_audit_result',
    AdminAssetManager: 'admin_asset_manager',
    AdminInvestmentMaster: 'admin_investment_master',
    AdminDocumentType: 'admin_document_type',
    AdminInvestment2: 'admin_investment'
} as const;

export type GetNote200ResponseNoteTypeEnum = typeof GetNote200ResponseNoteTypeEnum[keyof typeof GetNote200ResponseNoteTypeEnum];

/**
 * 
 * @export
 * @interface GetRALRequest200Response
 */
export interface GetRALRequest200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetRALRequest200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {GetRALRequest200ResponseRalRequest}
     * @memberof GetRALRequest200Response
     */
    'ral_request'?: GetRALRequest200ResponseRalRequest;
}
/**
 * 
 * @export
 * @interface GetRALRequest200ResponseRalRequest
 */
export interface GetRALRequest200ResponseRalRequest {
    /**
     * 
     * @type {string}
     * @memberof GetRALRequest200ResponseRalRequest
     */
    '_id'?: string;
    /**
     * 
     * @type {ListRALRequests200ResponseRalRequestsInnerUser}
     * @memberof GetRALRequest200ResponseRalRequest
     */
    'user'?: ListRALRequests200ResponseRalRequestsInnerUser;
    /**
     * 
     * @type {Array<GetDocument200ResponseDocumentOwnershipIdsInner>}
     * @memberof GetRALRequest200ResponseRalRequest
     */
    'ownership_ids'?: Array<GetDocument200ResponseDocumentOwnershipIdsInner>;
    /**
     * 
     * @type {string}
     * @memberof GetRALRequest200ResponseRalRequest
     */
    'ownership_type'?: string;
    /**
     * 
     * @type {ListRALRequests200ResponseRalRequestsInnerSignedRal}
     * @memberof GetRALRequest200ResponseRalRequest
     */
    'signed_ral'?: ListRALRequests200ResponseRalRequestsInnerSignedRal;
    /**
     * 
     * @type {Array<GetRALRequest200ResponseRalRequestSignatoriesInner>}
     * @memberof GetRALRequest200ResponseRalRequest
     */
    'signatories'?: Array<GetRALRequest200ResponseRalRequestSignatoriesInner>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetRALRequest200ResponseRalRequest
     */
    'tokens'?: { [key: string]: string; };
    /**
     * 
     * @type {ListRALRequests200ResponseRalRequestsInnerRalRequestTemplate}
     * @memberof GetRALRequest200ResponseRalRequest
     */
    'ral_request_template'?: ListRALRequests200ResponseRalRequestsInnerRalRequestTemplate;
    /**
     * 
     * @type {string}
     * @memberof GetRALRequest200ResponseRalRequest
     */
    'remote_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRALRequest200ResponseRalRequest
     */
    'remote_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRALRequest200ResponseRalRequest
     */
    'remote_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRALRequest200ResponseRalRequest
     */
    'sent_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRALRequest200ResponseRalRequest
     */
    'signed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRALRequest200ResponseRalRequest
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRALRequest200ResponseRalRequest
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRALRequest200ResponseRalRequest
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface GetRALRequest200ResponseRalRequestSignatoriesInner
 */
export interface GetRALRequest200ResponseRalRequestSignatoriesInner {
    /**
     * 
     * @type {string}
     * @memberof GetRALRequest200ResponseRalRequestSignatoriesInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRALRequest200ResponseRalRequestSignatoriesInner
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRALRequest200ResponseRalRequestSignatoriesInner
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRALRequest200ResponseRalRequestSignatoriesInner
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRALRequest200ResponseRalRequestSignatoriesInner
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface GetSelf200Response
 */
export interface GetSelf200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetSelf200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {GetSelf200ResponseUser}
     * @memberof GetSelf200Response
     */
    'user'?: GetSelf200ResponseUser;
}
/**
 * 
 * @export
 * @interface GetSelf200ResponseUser
 */
export interface GetSelf200ResponseUser {
    /**
     * 
     * @type {string}
     * @memberof GetSelf200ResponseUser
     */
    '_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSelf200ResponseUser
     */
    'has_password'?: boolean;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerInvestmentsInner>}
     * @memberof GetSelf200ResponseUser
     */
    'investments'?: Array<ListUsers200ResponseUsersInnerInvestmentsInner>;
    /**
     * 
     * @type {number}
     * @memberof GetSelf200ResponseUser
     */
    'investments_count'?: number;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerAccountsInner>}
     * @memberof GetSelf200ResponseUser
     */
    'accounts'?: Array<ListUsers200ResponseUsersInnerAccountsInner>;
    /**
     * 
     * @type {string}
     * @memberof GetSelf200ResponseUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSelf200ResponseUser
     */
    'email'?: string;
    /**
     * 
     * @type {ListUsers200ResponseUsersInnerEmailVerification}
     * @memberof GetSelf200ResponseUser
     */
    'email_verification'?: ListUsers200ResponseUsersInnerEmailVerification;
    /**
     * 
     * @type {string}
     * @memberof GetSelf200ResponseUser
     */
    'access'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSelf200ResponseUser
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSelf200ResponseUser
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSelf200ResponseUser
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSelf200ResponseUser
     */
    'status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSelf200ResponseUser
     */
    'is_deactivated'?: boolean;
    /**
     * 
     * @type {ListUsers200ResponseUsersInnerTfa}
     * @memberof GetSelf200ResponseUser
     */
    'tfa'?: ListUsers200ResponseUsersInnerTfa;
    /**
     * 
     * @type {ListUsers200ResponseUsersInnerNotificationSettings}
     * @memberof GetSelf200ResponseUser
     */
    'notificationSettings'?: ListUsers200ResponseUsersInnerNotificationSettings;
    /**
     * 
     * @type {boolean}
     * @memberof GetSelf200ResponseUser
     */
    'sample_data'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetSelf200ResponseUser
     */
    'failed_attempts'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSelf200ResponseUser
     */
    'setup_email_sent_times'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetSelf200ResponseUser
     */
    'external_ids'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof GetSelf200ResponseUser
     */
    'last_login_at'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSelf200ResponseUser
     */
    'setup_email_send_times'?: Array<string>;
    /**
     * 
     * @type {Array<GetUser200ResponseUserAdvisorGroupMembershipsInner>}
     * @memberof GetSelf200ResponseUser
     */
    'advisor_group_memberships'?: Array<GetUser200ResponseUserAdvisorGroupMembershipsInner>;
    /**
     * 
     * @type {any}
     * @memberof GetSelf200ResponseUser
     */
    'sessions'?: any;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerNotificationsInner>}
     * @memberof GetSelf200ResponseUser
     */
    'notifications'?: Array<ListUsers200ResponseUsersInnerNotificationsInner>;
    /**
     * 
     * @type {Array<GetUser200ResponseUserAdvisorsInner>}
     * @memberof GetSelf200ResponseUser
     */
    'advisors'?: Array<GetUser200ResponseUserAdvisorsInner>;
    /**
     * 
     * @type {string}
     * @memberof GetSelf200ResponseUser
     */
    'outreach_status'?: string;
    /**
     * 
     * @type {any}
     * @memberof GetSelf200ResponseUser
     */
    'docs_collected_by_account'?: any;
}
/**
 * 
 * @export
 * @interface GetStatementHistory200Response
 */
export interface GetStatementHistory200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetStatementHistory200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<GetStatementHistory200ResponseStatementsInner>}
     * @memberof GetStatementHistory200Response
     */
    'statements'?: Array<GetStatementHistory200ResponseStatementsInner>;
}
/**
 * 
 * @export
 * @interface GetStatementHistory200ResponseStatementsInner
 */
export interface GetStatementHistory200ResponseStatementsInner {
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInner
     */
    'date'?: string;
    /**
     * 
     * @type {GetStatementHistory200ResponseStatementsInnerMetadata}
     * @memberof GetStatementHistory200ResponseStatementsInner
     */
    'metadata'?: GetStatementHistory200ResponseStatementsInnerMetadata;
}
/**
 * 
 * @export
 * @interface GetStatementHistory200ResponseStatementsInnerMetadata
 */
export interface GetStatementHistory200ResponseStatementsInnerMetadata {
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'period_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'period_end'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'beginning_balance'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'ending_balance'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'total_committed'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'management_fee'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'carry'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'management_fee_rebate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'contributions'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'profit_distribution'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'contributions_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'withdrawals_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'profit_distribution_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'principal_distribution'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'recallable_profit_distribution'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'recallable_principal_distribution'?: string;
}
/**
 * 
 * @export
 * @interface GetStats200Response
 */
export interface GetStats200Response {
    /**
     * 
     * @type {Array<GetStats200ResponseStatsInner>}
     * @memberof GetStats200Response
     */
    'stats'?: Array<GetStats200ResponseStatsInner>;
}
/**
 * 
 * @export
 * @interface GetStats200ResponseStatsInner
 */
export interface GetStats200ResponseStatsInner {
    /**
     * 
     * @type {string}
     * @memberof GetStats200ResponseStatsInner
     */
    'outreach_status'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetStats200ResponseStatsInner
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface GetTransaction200Response
 */
export interface GetTransaction200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetTransaction200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {GetTransaction200ResponseTransaction}
     * @memberof GetTransaction200Response
     */
    'transaction'?: GetTransaction200ResponseTransaction;
}
/**
 * 
 * @export
 * @interface GetTransaction200ResponseTransaction
 */
export interface GetTransaction200ResponseTransaction {
    /**
     * 
     * @type {string}
     * @memberof GetTransaction200ResponseTransaction
     */
    '_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetTransaction200ResponseTransaction
     */
    'sample_data'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetTransaction200ResponseTransaction
     */
    'type'?: string;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetTransaction200ResponseTransaction
     */
    'user'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetTransaction200ResponseTransaction
     */
    'account'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {ListCommitments200ResponseCommitmentsInnerInvestment}
     * @memberof GetTransaction200ResponseTransaction
     */
    'investment'?: ListCommitments200ResponseCommitmentsInnerInvestment;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetTransaction200ResponseTransaction
     */
    'document'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetTransaction200ResponseTransaction
     */
    'connection'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {boolean}
     * @memberof GetTransaction200ResponseTransaction
     */
    'auto_extracted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetTransaction200ResponseTransaction
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetTransaction200ResponseTransaction
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTransaction200ResponseTransaction
     */
    'units'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetTransaction200ResponseTransaction
     */
    'open'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetTransaction200ResponseTransaction
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransaction200ResponseTransaction
     */
    'linked_transaction'?: string;
    /**
     * 
     * @type {ListTransactions200ResponseTransactionsInnerJointTransaction}
     * @memberof GetTransaction200ResponseTransaction
     */
    'joint_transaction'?: ListTransactions200ResponseTransactionsInnerJointTransaction;
    /**
     * 
     * @type {Array<ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner>}
     * @memberof GetTransaction200ResponseTransaction
     */
    'joint_transactions'?: Array<ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner>;
    /**
     * 
     * @type {string}
     * @memberof GetTransaction200ResponseTransaction
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransaction200ResponseTransaction
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTransaction200ResponseTransaction
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerAuditLogInner>}
     * @memberof GetTransaction200ResponseTransaction
     */
    'audit_log'?: Array<ListAccounts200ResponseAccountsInnerAuditLogInner>;
}
/**
 * 
 * @export
 * @interface GetUser200Response
 */
export interface GetUser200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetUser200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {GetUser200ResponseUser}
     * @memberof GetUser200Response
     */
    'user'?: GetUser200ResponseUser;
}
/**
 * 
 * @export
 * @interface GetUser200ResponseUser
 */
export interface GetUser200ResponseUser {
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUser
     */
    '_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUser200ResponseUser
     */
    'has_password'?: boolean;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerInvestmentsInner>}
     * @memberof GetUser200ResponseUser
     */
    'investments'?: Array<ListUsers200ResponseUsersInnerInvestmentsInner>;
    /**
     * 
     * @type {number}
     * @memberof GetUser200ResponseUser
     */
    'investments_count'?: number;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerAccountsInner>}
     * @memberof GetUser200ResponseUser
     */
    'accounts'?: Array<ListUsers200ResponseUsersInnerAccountsInner>;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUser
     */
    'email'?: string;
    /**
     * 
     * @type {ListUsers200ResponseUsersInnerEmailVerification}
     * @memberof GetUser200ResponseUser
     */
    'email_verification'?: ListUsers200ResponseUsersInnerEmailVerification;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUser
     */
    'access'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUser
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUser
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUser
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUser
     */
    'status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUser200ResponseUser
     */
    'is_deactivated'?: boolean;
    /**
     * 
     * @type {ListUsers200ResponseUsersInnerTfa}
     * @memberof GetUser200ResponseUser
     */
    'tfa'?: ListUsers200ResponseUsersInnerTfa;
    /**
     * 
     * @type {ListUsers200ResponseUsersInnerNotificationSettings}
     * @memberof GetUser200ResponseUser
     */
    'notificationSettings'?: ListUsers200ResponseUsersInnerNotificationSettings;
    /**
     * 
     * @type {boolean}
     * @memberof GetUser200ResponseUser
     */
    'sample_data'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetUser200ResponseUser
     */
    'failed_attempts'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUser200ResponseUser
     */
    'setup_email_sent_times'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetUser200ResponseUser
     */
    'external_ids'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUser
     */
    'last_login_at'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUser200ResponseUser
     */
    'setup_email_send_times'?: Array<string>;
    /**
     * 
     * @type {Array<GetUser200ResponseUserAdvisorGroupMembershipsInner>}
     * @memberof GetUser200ResponseUser
     */
    'advisor_group_memberships'?: Array<GetUser200ResponseUserAdvisorGroupMembershipsInner>;
    /**
     * 
     * @type {any}
     * @memberof GetUser200ResponseUser
     */
    'sessions'?: any;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerNotificationsInner>}
     * @memberof GetUser200ResponseUser
     */
    'notifications'?: Array<ListUsers200ResponseUsersInnerNotificationsInner>;
    /**
     * 
     * @type {Array<GetUser200ResponseUserAdvisorsInner>}
     * @memberof GetUser200ResponseUser
     */
    'advisors'?: Array<GetUser200ResponseUserAdvisorsInner>;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUser
     */
    'outreach_status'?: string;
}
/**
 * 
 * @export
 * @interface GetUser200ResponseUserAdvisorGroupMembershipsInner
 */
export interface GetUser200ResponseUserAdvisorGroupMembershipsInner {
    /**
     * 
     * @type {GetUser200ResponseUserAdvisorGroupMembershipsInnerGroup}
     * @memberof GetUser200ResponseUserAdvisorGroupMembershipsInner
     */
    'group'?: GetUser200ResponseUserAdvisorGroupMembershipsInnerGroup;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorGroupMembershipsInner
     */
    'role'?: string;
    /**
     * 
     * @type {Array<GetUser200ResponseUserAdvisorGroupMembershipsInnerClientsInner>}
     * @memberof GetUser200ResponseUserAdvisorGroupMembershipsInner
     */
    'clients'?: Array<GetUser200ResponseUserAdvisorGroupMembershipsInnerClientsInner>;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorGroupMembershipsInner
     */
    'invited_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorGroupMembershipsInner
     */
    'accepted_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorGroupMembershipsInner
     */
    '_id'?: string;
}
/**
 * 
 * @export
 * @interface GetUser200ResponseUserAdvisorGroupMembershipsInnerClientsInner
 */
export interface GetUser200ResponseUserAdvisorGroupMembershipsInnerClientsInner {
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorGroupMembershipsInnerClientsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorGroupMembershipsInnerClientsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorGroupMembershipsInnerClientsInner
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorGroupMembershipsInnerClientsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorGroupMembershipsInnerClientsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorGroupMembershipsInnerClientsInner
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface GetUser200ResponseUserAdvisorGroupMembershipsInnerGroup
 */
export interface GetUser200ResponseUserAdvisorGroupMembershipsInnerGroup {
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorGroupMembershipsInnerGroup
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorGroupMembershipsInnerGroup
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorGroupMembershipsInnerGroup
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorGroupMembershipsInnerGroup
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorGroupMembershipsInnerGroup
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface GetUser200ResponseUserAdvisorsInner
 */
export interface GetUser200ResponseUserAdvisorsInner {
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorsInner
     */
    'role'?: string;
    /**
     * 
     * @type {GetUser200ResponseUserAdvisorsInnerGroup}
     * @memberof GetUser200ResponseUserAdvisorsInner
     */
    'group'?: GetUser200ResponseUserAdvisorsInnerGroup;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorsInner
     */
    'invited_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorsInner
     */
    'accepted_on'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUser200ResponseUserAdvisorsInner
     */
    'permissions'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorsInner
     */
    '_id'?: string;
}
/**
 * 
 * @export
 * @interface GetUser200ResponseUserAdvisorsInnerGroup
 */
export interface GetUser200ResponseUserAdvisorsInnerGroup {
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorsInnerGroup
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorsInnerGroup
     */
    'name'?: string;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerAdvisorsInnerGroupExtegrationsInner>}
     * @memberof GetUser200ResponseUserAdvisorsInnerGroup
     */
    'extegrations'?: Array<ListUsers200ResponseUsersInnerAdvisorsInnerGroupExtegrationsInner>;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorsInnerGroup
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorsInnerGroup
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUserAdvisorsInnerGroup
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface GetValuation200Response
 */
export interface GetValuation200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetValuation200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {GetValuation200ResponseValuation}
     * @memberof GetValuation200Response
     */
    'valuation'?: GetValuation200ResponseValuation;
}
/**
 * 
 * @export
 * @interface GetValuation200ResponseValuation
 */
export interface GetValuation200ResponseValuation {
    /**
     * 
     * @type {string}
     * @memberof GetValuation200ResponseValuation
     */
    '_id'?: string;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerUser}
     * @memberof GetValuation200ResponseValuation
     */
    'user'?: ListDocuments200ResponseDocumentsInnerUser;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetValuation200ResponseValuation
     */
    'account'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {ListCommitments200ResponseCommitmentsInnerInvestment}
     * @memberof GetValuation200ResponseValuation
     */
    'investment'?: ListCommitments200ResponseCommitmentsInnerInvestment;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof GetValuation200ResponseValuation
     */
    'connection'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {Array<ListValuations200ResponseValuationsInnerDocumentsInner>}
     * @memberof GetValuation200ResponseValuation
     */
    'documents'?: Array<ListValuations200ResponseValuationsInnerDocumentsInner>;
    /**
     * 
     * @type {boolean}
     * @memberof GetValuation200ResponseValuation
     */
    'auto_extracted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetValuation200ResponseValuation
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetValuation200ResponseValuation
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetValuation200ResponseValuation
     */
    'unit_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetValuation200ResponseValuation
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetValuation200ResponseValuation
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetValuation200ResponseValuation
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerAuditLogInner>}
     * @memberof GetValuation200ResponseValuation
     */
    'audit_log'?: Array<ListAccounts200ResponseAccountsInnerAuditLogInner>;
}
/**
 * 
 * @export
 * @interface ImportConnectionAccountRequest
 */
export interface ImportConnectionAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof ImportConnectionAccountRequest
     */
    'user_id_override'?: string;
}
/**
 * 
 * @export
 * @interface ImportConnectionInvestmentRequest
 */
export interface ImportConnectionInvestmentRequest {
    /**
     * 
     * @type {string}
     * @memberof ImportConnectionInvestmentRequest
     */
    'account_id_override'?: string;
}
/**
 * The status of an investment.
 * @export
 * @enum {string}
 */

export const InvestmentStatuses = {
    Fundraising: 'fundraising',
    Investing: 'investing',
    Harvesting: 'harvesting',
    Liquidated: 'liquidated'
} as const;

export type InvestmentStatuses = typeof InvestmentStatuses[keyof typeof InvestmentStatuses];


/**
 * 
 * @export
 * @enum {string}
 */

export const InvestmentTypes = {
    DiverseInvestments: 'Diverse Investments',
    PrivateEquityFund: 'Private Equity Fund',
    PrivateCreditFund: 'Private Credit Fund',
    VentureCapitalFund: 'Venture Capital Fund',
    FundOfFunds: 'Fund of Funds',
    SecondaryFund: 'Secondary Fund',
    RealEstateFund: 'Real Estate Fund',
    CoinvestmentFund: 'Coinvestment Fund',
    RealAssetsFund: 'Real Assets Fund',
    HedgeFund: 'Hedge Fund',
    Collectible: 'Collectible',
    PrivateCompanyStartup: 'Private Company / Startup',
    RealEstate: 'Real Estate',
    RealAssetsDirectOwned: 'Real Assets (Direct Owned)',
    BondLoan: 'Bond / Loan',
    Crypto: 'Crypto'
} as const;

export type InvestmentTypes = typeof InvestmentTypes[keyof typeof InvestmentTypes];


/**
 * 
 * @export
 * @interface ListAccounts200Response
 */
export interface ListAccounts200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListAccounts200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListAccounts200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInner>}
     * @memberof ListAccounts200Response
     */
    'accounts'?: Array<ListAccounts200ResponseAccountsInner>;
}
/**
 * 
 * @export
 * @interface ListAccounts200ResponseAccountsInner
 */
export interface ListAccounts200ResponseAccountsInner {
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'name'?: string;
    /**
     * 
     * @type {ListAccounts200ResponseAccountsInnerUser}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'user'?: ListAccounts200ResponseAccountsInnerUser;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerAuditLogInner>}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'audit_log'?: Array<ListAccounts200ResponseAccountsInnerAuditLogInner>;
    /**
     * 
     * @type {number}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'investments_count'?: number;
    /**
     * 
     * @type {ListAccounts200ResponseAccountsInnerAddress}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'address'?: ListAccounts200ResponseAccountsInnerAddress;
    /**
     * 
     * @type {object}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'performance'?: object;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'account_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'id_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'id_number'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'external_ids'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListAccounts200ResponseAccountsInnerAddress
 */
export interface ListAccounts200ResponseAccountsInnerAddress {
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAddress
     */
    'streetLine1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAddress
     */
    'streetLine2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAddress
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAddress
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAddress
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAddress
     */
    'zipCode'?: string;
}
/**
 * 
 * @export
 * @interface ListAccounts200ResponseAccountsInnerAuditLogInner
 */
export interface ListAccounts200ResponseAccountsInnerAuditLogInner {
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInner
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInner
     */
    'target'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInner
     */
    'target_type'?: string;
    /**
     * 
     * @type {any}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInner
     */
    'initiator'?: any;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInner
     */
    'initiator_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInner
     */
    'timestamp'?: string;
    /**
     * 
     * @type {ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetails}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInner
     */
    'action_details'?: ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetails;
}
/**
 * 
 * @export
 * @interface ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetails
 */
export interface ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetails {
    /**
     * 
     * @type {boolean}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetails
     */
    'login_success'?: boolean;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetailsFieldModificationsInner>}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetails
     */
    'field_modifications'?: Array<ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetailsFieldModificationsInner>;
}
/**
 * 
 * @export
 * @interface ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetailsFieldModificationsInner
 */
export interface ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetailsFieldModificationsInner {
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetailsFieldModificationsInner
     */
    'field'?: string;
    /**
     * 
     * @type {any}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetailsFieldModificationsInner
     */
    'old_value'?: any;
    /**
     * 
     * @type {any}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetailsFieldModificationsInner
     */
    'new_value'?: any;
}
/**
 * 
 * @export
 * @interface ListAccounts200ResponseAccountsInnerUser
 */
export interface ListAccounts200ResponseAccountsInnerUser {
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerUser
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface ListAccounts400Response
 */
export interface ListAccounts400Response {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ListAccounts400Response
     */
    'request_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListAccounts400Response
     */
    'status_code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts400Response
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts400Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ListAccounts401Response
 */
export interface ListAccounts401Response {
    /**
     * 
     * @type {string}
     * @memberof ListAccounts401Response
     */
    'request_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListAccounts401Response
     */
    'status_code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts401Response
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts401Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ListAccounts409Response
 */
export interface ListAccounts409Response {
    [key: string]: any;

    /**
     * 
     * @type {any}
     * @memberof ListAccounts409Response
     */
    'request_id'?: any;
    /**
     * 
     * @type {any}
     * @memberof ListAccounts409Response
     */
    'status_code'?: any;
    /**
     * 
     * @type {any}
     * @memberof ListAccounts409Response
     */
    'error'?: any;
    /**
     * 
     * @type {any}
     * @memberof ListAccounts409Response
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface ListAdminTeams200Response
 */
export interface ListAdminTeams200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListAdminTeams200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListAdminTeams200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListAdminTeams200ResponseAdminTeamsInner>}
     * @memberof ListAdminTeams200Response
     */
    'admin_teams'?: Array<ListAdminTeams200ResponseAdminTeamsInner>;
}
/**
 * 
 * @export
 * @interface ListAdminTeams200ResponseAdminTeamsInner
 */
export interface ListAdminTeams200ResponseAdminTeamsInner {
    /**
     * 
     * @type {string}
     * @memberof ListAdminTeams200ResponseAdminTeamsInner
     */
    '_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListAdminTeams200ResponseAdminTeamsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdminTeams200ResponseAdminTeamsInner
     */
    'type'?: ListAdminTeams200ResponseAdminTeamsInnerTypeEnum;
    /**
     * 
     * @type {Array<GetAuditResult200ResponseAuditResultBreadcrumbsAccount>}
     * @memberof ListAdminTeams200ResponseAdminTeamsInner
     */
    'advisory_groups'?: Array<GetAuditResult200ResponseAuditResultBreadcrumbsAccount>;
    /**
     * 
     * @type {string}
     * @memberof ListAdminTeams200ResponseAdminTeamsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdminTeams200ResponseAdminTeamsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdminTeams200ResponseAdminTeamsInner
     */
    'deleted_at'?: string;
}

export const ListAdminTeams200ResponseAdminTeamsInnerTypeEnum = {
    AccountManagement: 'account_management',
    Onboarding: 'onboarding'
} as const;

export type ListAdminTeams200ResponseAdminTeamsInnerTypeEnum = typeof ListAdminTeams200ResponseAdminTeamsInnerTypeEnum[keyof typeof ListAdminTeams200ResponseAdminTeamsInnerTypeEnum];

/**
 * 
 * @export
 * @interface ListAdvisorsInGroup200Response
 */
export interface ListAdvisorsInGroup200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListAdvisorsInGroup200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<ListAdvisorsInGroup200ResponseAdvisorsInner>}
     * @memberof ListAdvisorsInGroup200Response
     */
    'advisors'?: Array<ListAdvisorsInGroup200ResponseAdvisorsInner>;
    /**
     * 
     * @type {number}
     * @memberof ListAdvisorsInGroup200Response
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface ListAdvisorsInGroup200ResponseAdvisorsInner
 */
export interface ListAdvisorsInGroup200ResponseAdvisorsInner {
    /**
     * 
     * @type {string}
     * @memberof ListAdvisorsInGroup200ResponseAdvisorsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisorsInGroup200ResponseAdvisorsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisorsInGroup200ResponseAdvisorsInner
     */
    'email'?: string;
    /**
     * 
     * @type {Array<ListAdvisorsInGroup200ResponseAdvisorsInnerAdvisorGroupMembershipsInner>}
     * @memberof ListAdvisorsInGroup200ResponseAdvisorsInner
     */
    'advisor_group_memberships'?: Array<ListAdvisorsInGroup200ResponseAdvisorsInnerAdvisorGroupMembershipsInner>;
}
/**
 * 
 * @export
 * @interface ListAdvisorsInGroup200ResponseAdvisorsInnerAdvisorGroupMembershipsInner
 */
export interface ListAdvisorsInGroup200ResponseAdvisorsInnerAdvisorGroupMembershipsInner {
    /**
     * 
     * @type {string}
     * @memberof ListAdvisorsInGroup200ResponseAdvisorsInnerAdvisorGroupMembershipsInner
     */
    'group'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisorsInGroup200ResponseAdvisorsInnerAdvisorGroupMembershipsInner
     */
    'role'?: string;
    /**
     * 
     * @type {Array<ListAdvisorsInGroup200ResponseAdvisorsInnerAdvisorGroupMembershipsInnerClientsInner>}
     * @memberof ListAdvisorsInGroup200ResponseAdvisorsInnerAdvisorGroupMembershipsInner
     */
    'clients'?: Array<ListAdvisorsInGroup200ResponseAdvisorsInnerAdvisorGroupMembershipsInnerClientsInner>;
    /**
     * 
     * @type {number}
     * @memberof ListAdvisorsInGroup200ResponseAdvisorsInnerAdvisorGroupMembershipsInner
     */
    'aum'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListAdvisorsInGroup200ResponseAdvisorsInnerAdvisorGroupMembershipsInner
     */
    'investmentCount'?: number;
}
/**
 * 
 * @export
 * @interface ListAdvisorsInGroup200ResponseAdvisorsInnerAdvisorGroupMembershipsInnerClientsInner
 */
export interface ListAdvisorsInGroup200ResponseAdvisorsInnerAdvisorGroupMembershipsInnerClientsInner {
    /**
     * 
     * @type {string}
     * @memberof ListAdvisorsInGroup200ResponseAdvisorsInnerAdvisorGroupMembershipsInnerClientsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisorsInGroup200ResponseAdvisorsInnerAdvisorGroupMembershipsInnerClientsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisorsInGroup200ResponseAdvisorsInnerAdvisorGroupMembershipsInnerClientsInner
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListAdvisorsInGroup200ResponseAdvisorsInnerAdvisorGroupMembershipsInnerClientsInner
     */
    'aum'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListAdvisorsInGroup200ResponseAdvisorsInnerAdvisorGroupMembershipsInnerClientsInner
     */
    'investmentCount'?: number;
}
/**
 * 
 * @export
 * @interface ListAdvisoryGroupExtegrationSyncs200Response
 */
export interface ListAdvisoryGroupExtegrationSyncs200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListAdvisoryGroupExtegrationSyncs200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListAdvisoryGroupExtegrationSyncs200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInner>}
     * @memberof ListAdvisoryGroupExtegrationSyncs200Response
     */
    'extegration_syncs'?: Array<ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInner>;
}
/**
 * 
 * @export
 * @interface ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInner
 */
export interface ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInner {
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {Array<GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSyncAttachmentsInner>}
     * @memberof ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInner
     */
    'attachments'?: Array<GetAdvisoryGroupExtegrationSyncById200ResponseExtegrationSyncAttachmentsInner>;
    /**
     * 
     * @type {Array<ListConnections200ResponseConnectionsInnerAdvisoryGroup>}
     * @memberof ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInner
     */
    'entities'?: Array<ListConnections200ResponseConnectionsInnerAdvisoryGroup>;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerAdvisoryGroup}
     * @memberof ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInner
     */
    'initiator'?: ListConnections200ResponseConnectionsInnerAdvisoryGroup;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInner
     */
    'exit_status'?: ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInnerExitStatusEnum;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInner
     */
    'error_report'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInner
     */
    'group'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInner
     */
    'extegration_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInner
     */
    'extegration_connection_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInner
     */
    'job_id'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInner
     */
    'job_response'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInner
     */
    'started_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInner
     */
    'completed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInner
     */
    'updated_at'?: string;
}

export const ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInnerExitStatusEnum = {
    Success: 'success',
    Failure: 'failure',
    Pending: 'pending'
} as const;

export type ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInnerExitStatusEnum = typeof ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInnerExitStatusEnum[keyof typeof ListAdvisoryGroupExtegrationSyncs200ResponseExtegrationSyncsInnerExitStatusEnum];

/**
 * 
 * @export
 * @interface ListAdvisoryGroups200Response
 */
export interface ListAdvisoryGroups200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListAdvisoryGroups200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListAdvisoryGroups200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListAdvisoryGroups200ResponseAdvisoryGroupsInner>}
     * @memberof ListAdvisoryGroups200Response
     */
    'advisory_groups'?: Array<ListAdvisoryGroups200ResponseAdvisoryGroupsInner>;
}
/**
 * 
 * @export
 * @interface ListAdvisoryGroups200ResponseAdvisoryGroupsInner
 */
export interface ListAdvisoryGroups200ResponseAdvisoryGroupsInner {
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInner
     */
    'custom_connection_email_domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInner
     */
    'assets'?: string;
    /**
     * 
     * @type {Array<ListAdvisoryGroups200ResponseAdvisoryGroupsInnerExtegrationsInner>}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInner
     */
    'extegrations'?: Array<ListAdvisoryGroups200ResponseAdvisoryGroupsInnerExtegrationsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInner
     */
    'plan'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInner
     */
    'disable_sla_issues'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInner
     */
    'allowed_extegrations'?: Array<string>;
    /**
     * 
     * @type {ListAdvisoryGroups200ResponseAdvisoryGroupsInnerSettings}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInner
     */
    'settings'?: ListAdvisoryGroups200ResponseAdvisoryGroupsInnerSettings;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInner
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListAdvisoryGroups200ResponseAdvisoryGroupsInnerExtegrationsInner
 */
export interface ListAdvisoryGroups200ResponseAdvisoryGroupsInnerExtegrationsInner {
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInnerExtegrationsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInnerExtegrationsInner
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInnerExtegrationsInner
     */
    'entities'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInnerExtegrationsInner
     */
    'extegration_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInnerExtegrationsInner
     */
    'name'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInnerExtegrationsInner
     */
    'credentials'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInnerExtegrationsInner
     */
    'sync_interval'?: ListAdvisoryGroups200ResponseAdvisoryGroupsInnerExtegrationsInnerSyncIntervalEnum;
}

export const ListAdvisoryGroups200ResponseAdvisoryGroupsInnerExtegrationsInnerSyncIntervalEnum = {
    Daily: 'daily',
    Weekdays: 'weekdays',
    Weekly: 'weekly',
    Monthly: 'monthly',
    Tetrahourly: 'tetrahourly'
} as const;

export type ListAdvisoryGroups200ResponseAdvisoryGroupsInnerExtegrationsInnerSyncIntervalEnum = typeof ListAdvisoryGroups200ResponseAdvisoryGroupsInnerExtegrationsInnerSyncIntervalEnum[keyof typeof ListAdvisoryGroups200ResponseAdvisoryGroupsInnerExtegrationsInnerSyncIntervalEnum];

/**
 * 
 * @export
 * @interface ListAdvisoryGroups200ResponseAdvisoryGroupsInnerSettings
 */
export interface ListAdvisoryGroups200ResponseAdvisoryGroupsInnerSettings {
    /**
     * 
     * @type {boolean}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInnerSettings
     */
    'hide_fees_for_investors'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInnerSettings
     */
    'hide_fees_for_advisors'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInnerSettings
     */
    'logo_in_toolbar'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInnerSettings
     */
    'logo_thumb_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdvisoryGroups200ResponseAdvisoryGroupsInnerSettings
     */
    'logo_full_url'?: string;
}
/**
 * 
 * @export
 * @interface ListAssetManagers200Response
 */
export interface ListAssetManagers200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListAssetManagers200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListAssetManagers200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListAssetManagers200ResponseAssetManagersInner>}
     * @memberof ListAssetManagers200Response
     */
    'asset_managers'?: Array<ListAssetManagers200ResponseAssetManagersInner>;
}
/**
 * 
 * @export
 * @interface ListAssetManagers200ResponseAssetManagersInner
 */
export interface ListAssetManagers200ResponseAssetManagersInner {
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInner
     */
    '_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInner
     */
    'website'?: string;
    /**
     * 
     * @type {Array<ListAssetManagers200ResponseAssetManagersInnerContactsInner>}
     * @memberof ListAssetManagers200ResponseAssetManagersInner
     */
    'contacts'?: Array<ListAssetManagers200ResponseAssetManagersInnerContactsInner>;
    /**
     * 
     * @type {Array<ListAssetManagers200ResponseAssetManagersInnerConnectionsInner>}
     * @memberof ListAssetManagers200ResponseAssetManagersInner
     */
    'connections'?: Array<ListAssetManagers200ResponseAssetManagersInnerConnectionsInner>;
    /**
     * 
     * @type {Array<ListAssetManagers200ResponseAssetManagersInnerIntegrationsInner>}
     * @memberof ListAssetManagers200ResponseAssetManagersInner
     */
    'integrations'?: Array<ListAssetManagers200ResponseAssetManagersInnerIntegrationsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInner
     */
    'outreach_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInner
     */
    'last_login_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInner
     */
    'tax_document_distribution_method'?: ListAssetManagers200ResponseAssetManagersInnerTaxDocumentDistributionMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInner
     */
    'notification_method'?: ListAssetManagers200ResponseAssetManagersInnerNotificationMethodEnum;
    /**
     * 
     * @type {Array<ListAssetManagers200ResponseAssetManagersInnerSessionsInner>}
     * @memberof ListAssetManagers200ResponseAssetManagersInner
     */
    'sessions'?: Array<ListAssetManagers200ResponseAssetManagersInnerSessionsInner>;
    /**
     * 
     * @type {number}
     * @memberof ListAssetManagers200ResponseAssetManagersInner
     */
    'noteCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListAssetManagers200ResponseAssetManagersInner
     */
    'connectionCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInner
     */
    'initial_outreach_date'?: string;
    /**
     * 
     * @type {ListAssetManagers200ResponseAssetManagersInnerRals}
     * @memberof ListAssetManagers200ResponseAssetManagersInner
     */
    'rals'?: ListAssetManagers200ResponseAssetManagersInnerRals;
}

export const ListAssetManagers200ResponseAssetManagersInnerTaxDocumentDistributionMethodEnum = {
    None: 'none',
    Unknown: 'unknown',
    Email: 'email',
    Mail: 'mail',
    Portal: 'portal'
} as const;

export type ListAssetManagers200ResponseAssetManagersInnerTaxDocumentDistributionMethodEnum = typeof ListAssetManagers200ResponseAssetManagersInnerTaxDocumentDistributionMethodEnum[keyof typeof ListAssetManagers200ResponseAssetManagersInnerTaxDocumentDistributionMethodEnum];
export const ListAssetManagers200ResponseAssetManagersInnerNotificationMethodEnum = {
    None: 'none',
    Unknown: 'unknown',
    Email: 'email',
    Mail: 'mail',
    Portal: 'portal'
} as const;

export type ListAssetManagers200ResponseAssetManagersInnerNotificationMethodEnum = typeof ListAssetManagers200ResponseAssetManagersInnerNotificationMethodEnum[keyof typeof ListAssetManagers200ResponseAssetManagersInnerNotificationMethodEnum];

/**
 * 
 * @export
 * @interface ListAssetManagers200ResponseAssetManagersInnerConnectionsInner
 */
export interface ListAssetManagers200ResponseAssetManagersInnerConnectionsInner {
    /**
     * 
     * @type {ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerUser}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInner
     */
    'user'?: ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerUser;
    /**
     * 
     * @type {Array<ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInner>}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInner
     */
    'ral_requests'?: Array<ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInner>;
}
/**
 * 
 * @export
 * @interface ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInner
 */
export interface ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInner {
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInner
     */
    '_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInner
     */
    'sent_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInner
     */
    'signed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInner
     */
    'status'?: string;
    /**
     * 
     * @type {ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRal}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInner
     */
    'signed_ral'?: ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRal;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInner
     */
    'ownership_type'?: string;
    /**
     * 
     * @type {Array<ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInner>}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInner
     */
    'ownership_ids'?: Array<ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInner>;
}
/**
 * 
 * @export
 * @interface ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInner
 */
export interface ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInner {
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInner
     */
    'currency'?: string;
    /**
     * 
     * @type {ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInnerUser}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInner
     */
    'user'?: ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInnerUser;
    /**
     * 
     * @type {ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInnerAccount}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInner
     */
    'account'?: ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInnerAccount;
}
/**
 * 
 * @export
 * @interface ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInnerAccount
 */
export interface ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInnerAccount {
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInnerAccount
     */
    '_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInnerAccount
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInnerUser
 */
export interface ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInnerUser {
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInnerUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInnerUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInnerUser
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRal
 */
export interface ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRal {
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRal
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRal
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRal
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRal
     */
    'updated_at'?: string;
    /**
     * 
     * @type {ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRalFileDownloadUrls}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRal
     */
    'file_download_urls'?: ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRalFileDownloadUrls;
    /**
     * 
     * @type {ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRalFiles}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRal
     */
    'files'?: ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRalFiles;
}
/**
 * 
 * @export
 * @interface ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRalFileDownloadUrls
 */
export interface ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRalFileDownloadUrls {
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRalFileDownloadUrls
     */
    'downloadUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRalFileDownloadUrls
     */
    'wordsUrl'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRalFileDownloadUrls
     */
    'pages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRalFiles
 */
export interface ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRalFiles {
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRalFiles
     */
    'doc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRalFiles
     */
    'words'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerSignedRalFiles
     */
    'pages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerUser
 */
export interface ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerUser {
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerUser
     */
    '_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerUser
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface ListAssetManagers200ResponseAssetManagersInnerContactsInner
 */
export interface ListAssetManagers200ResponseAssetManagersInnerContactsInner {
    /**
     * 
     * @type {ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerContactsInner
     */
    'contact'?: ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerContactsInner
     */
    'roles'?: Array<ListAssetManagers200ResponseAssetManagersInnerContactsInnerRolesEnum>;
}

export const ListAssetManagers200ResponseAssetManagersInnerContactsInnerRolesEnum = {
    Primary: 'primary',
    Direct: 'direct',
    Tax: 'tax',
    Legal: 'legal',
    Ir: 'ir',
    Other: 'other'
} as const;

export type ListAssetManagers200ResponseAssetManagersInnerContactsInnerRolesEnum = typeof ListAssetManagers200ResponseAssetManagersInnerContactsInnerRolesEnum[keyof typeof ListAssetManagers200ResponseAssetManagersInnerContactsInnerRolesEnum];

/**
 * 
 * @export
 * @interface ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact
 */
export interface ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact {
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface ListAssetManagers200ResponseAssetManagersInnerIntegrationsInner
 */
export interface ListAssetManagers200ResponseAssetManagersInnerIntegrationsInner {
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerIntegrationsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerIntegrationsInner
     */
    '_id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerIntegrationsInner
     */
    'tfa_required'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerIntegrationsInner
     */
    'phone_required'?: boolean;
    /**
     * 
     * @type {Array<ListAssetManagers200ResponseAssetManagersInnerIntegrationsInnerRequiredCredsInner>}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerIntegrationsInner
     */
    'required_creds'?: Array<ListAssetManagers200ResponseAssetManagersInnerIntegrationsInnerRequiredCredsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerIntegrationsInner
     */
    'portal_url'?: string;
}
/**
 * 
 * @export
 * @interface ListAssetManagers200ResponseAssetManagersInnerIntegrationsInnerRequiredCredsInner
 */
export interface ListAssetManagers200ResponseAssetManagersInnerIntegrationsInnerRequiredCredsInner {
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerIntegrationsInnerRequiredCredsInner
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerIntegrationsInnerRequiredCredsInner
     */
    'input'?: ListAssetManagers200ResponseAssetManagersInnerIntegrationsInnerRequiredCredsInnerInputEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerIntegrationsInnerRequiredCredsInner
     */
    'options'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerIntegrationsInnerRequiredCredsInner
     */
    'hint'?: string;
}

export const ListAssetManagers200ResponseAssetManagersInnerIntegrationsInnerRequiredCredsInnerInputEnum = {
    Text: 'text',
    Password: 'password',
    Select: 'select',
    Checkbox: 'checkbox'
} as const;

export type ListAssetManagers200ResponseAssetManagersInnerIntegrationsInnerRequiredCredsInnerInputEnum = typeof ListAssetManagers200ResponseAssetManagersInnerIntegrationsInnerRequiredCredsInnerInputEnum[keyof typeof ListAssetManagers200ResponseAssetManagersInnerIntegrationsInnerRequiredCredsInnerInputEnum];

/**
 * 
 * @export
 * @interface ListAssetManagers200ResponseAssetManagersInnerRals
 */
export interface ListAssetManagers200ResponseAssetManagersInnerRals {
    /**
     * 
     * @type {number}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerRals
     */
    'unsigned'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerRals
     */
    'signed'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerRals
     */
    'new'?: number;
}
/**
 * 
 * @export
 * @interface ListAssetManagers200ResponseAssetManagersInnerSessionsInner
 */
export interface ListAssetManagers200ResponseAssetManagersInnerSessionsInner {
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerSessionsInner
     */
    'login_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAssetManagers200ResponseAssetManagersInnerSessionsInner
     */
    'expiry'?: string;
}
/**
 * 
 * @export
 * @interface ListAuditResults200Response
 */
export interface ListAuditResults200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListAuditResults200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListAuditResults200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListAuditResults200ResponseAuditResultsInner>}
     * @memberof ListAuditResults200Response
     */
    'audit_results'?: Array<ListAuditResults200ResponseAuditResultsInner>;
}
/**
 * 
 * @export
 * @interface ListAuditResults200ResponseAuditResultsInner
 */
export interface ListAuditResults200ResponseAuditResultsInner {
    /**
     * 
     * @type {string}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'action'?: string;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultCompletedBy}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'completed_by'?: GetAuditResult200ResponseAuditResultCompletedBy;
    /**
     * 
     * @type {string}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'completed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'sla_level'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'deadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'status_changed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'ignore_until'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'read'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'flagged'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'flagged_at'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'notes'?: Array<string>;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbs}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'breadcrumbs'?: GetAuditResult200ResponseAuditResultBreadcrumbs;
    /**
     * 
     * @type {object}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'structured_data'?: object;
    /**
     * 
     * @type {string}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'check_subname'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'task_specific_hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'signature'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'parent_type'?: ListAuditResults200ResponseAuditResultsInnerParentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'parent_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'check_name'?: ListAuditResults200ResponseAuditResultsInnerCheckNameEnum;
    /**
     * 
     * @type {string}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'func_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAuditResults200ResponseAuditResultsInner
     */
    'deleted_at'?: string;
}

export const ListAuditResults200ResponseAuditResultsInnerParentTypeEnum = {
    User: 'User',
    Account: 'Account',
    Investment: 'Investment',
    Document: 'Document',
    Transaction: 'Transaction',
    Valuation: 'Valuation',
    Connection: 'Connection',
    Message: 'Message',
    InvestmentMaster: 'InvestmentMaster',
    ExtegrationSync: 'ExtegrationSync'
} as const;

export type ListAuditResults200ResponseAuditResultsInnerParentTypeEnum = typeof ListAuditResults200ResponseAuditResultsInnerParentTypeEnum[keyof typeof ListAuditResults200ResponseAuditResultsInnerParentTypeEnum];
export const ListAuditResults200ResponseAuditResultsInnerCheckNameEnum = {
    SecurityMasterDocumentsReceivedByAllInvestments: 'Security Master Documents Received by All Investments',
    InvestmentUnderAdministrativeReview: 'Investment Under Administrative Review',
    UnrealizedValueSpike: 'Unrealized Value Spike',
    EndingBeginningBalanceMismatch: 'Ending/beginning balance mismatch',
    InvalidDateRange: 'Invalid date range',
    InaccurateDistributionsSum: 'Inaccurate distributions sum',
    InaccurateContributionsSum: 'Inaccurate contributions sum',
    CapNavStatementTimeliness: 'Cap/NAV Statement Timeliness',
    HaveCapNavStatementEveryPeriod: 'Have Cap/NAV Statement every period',
    TaxDocumentTimeliness: 'Tax Document Timeliness',
    HasTaxDocumentEveryYearSinceInvestmentStart: 'Has Tax Document every year since investment start',
    TransactionWithDefaultDateData: 'Transaction with default date data',
    DuplicateTransactions: 'Duplicate Transactions',
    UnsortedDocument: 'Unsorted Document',
    CapitalStatementWithInvalidMetadata: 'Capital Statement With Invalid Metadata',
    CapitalCallWithInvalidMetadata: 'Capital Call With Invalid Metadata',
    DistributionWithInvalidMetadata: 'Distribution With Invalid Metadata',
    NavStatementWithInvalidMetadata: 'NAV Statement WithInvalid Metadata',
    NetCapitalCallWithInvalidMetadata: 'Net Capital Call With Invalid Metadata',
    NetDistributionWithInvalidMetadata: 'Net Distribution With Invalid Metadata',
    TaxDocumentWithInvalidMetadata: 'Tax Document With Invalid Metadata',
    ManualConnectionPortalSync: 'Manual Connection Portal Sync',
    ConnectionStatusNotActive: 'Connection Status Not Active',
    ConnectionWithoutContacts: 'Connection Without Contacts',
    ConnectionCompletedByAssetManager: 'Connection Completed By Asset Manager',
    UnmappedDataInMostRecentConnectionSyncStagingArea: 'Unmapped Data in Most Recent Connection Sync (Staging Area)',
    MessagesUnreadFor3Days: 'Messages Unread for 3 Days',
    MessagesWithDocumentsAttached: 'Messages with Documents Attached',
    MessagesFlaggedFor5Days: 'Messages Flagged for 5 Days',
    FundInvestmentsWithoutSecurityMasterTicker: 'Fund investments without Security Master Ticker',
    FundInvestmentsMissingSubtype: 'Fund investments missing Subtype',
    NonHiddenInvestmentHasTypeDiverseInvestments: 'Non-hidden Investment has type Diverse Investments',
    InvestmentsWithoutPastYearTaxDoc: 'Investments without past year tax doc',
    AbnormalInvestmentIrr: 'Abnormal Investment IRR',
    InvestmentHiddenTooLong: 'Investment Hidden Too Long',
    InvestmentTypeStatementTypeMismatch: 'Investment Type / Statement Type Mismatch',
    InvestmentWithNegativeUnfundedAmount: 'Investment with negative unfunded amount',
    ExtegrationSyncFailedByAdvisoryGroup: 'Extegration Sync Failed by Advisory Group'
} as const;

export type ListAuditResults200ResponseAuditResultsInnerCheckNameEnum = typeof ListAuditResults200ResponseAuditResultsInnerCheckNameEnum[keyof typeof ListAuditResults200ResponseAuditResultsInnerCheckNameEnum];

/**
 * 
 * @export
 * @interface ListAvailableClients200Response
 */
export interface ListAvailableClients200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListAvailableClients200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<GetAuditResult200ResponseAuditResultCompletedBy>}
     * @memberof ListAvailableClients200Response
     */
    'clients'?: Array<GetAuditResult200ResponseAuditResultCompletedBy>;
}
/**
 * 
 * @export
 * @interface ListCommitments200Response
 */
export interface ListCommitments200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListCommitments200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListCommitments200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListCommitments200ResponseCommitmentsInner>}
     * @memberof ListCommitments200Response
     */
    'commitments'?: Array<ListCommitments200ResponseCommitmentsInner>;
}
/**
 * 
 * @export
 * @interface ListCommitments200ResponseCommitmentsInner
 */
export interface ListCommitments200ResponseCommitmentsInner {
    /**
     * 
     * @type {string}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    '_id'?: string | null;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'user'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'account'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {ListCommitments200ResponseCommitmentsInnerInvestment}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'investment'?: ListCommitments200ResponseCommitmentsInnerInvestment;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'connection'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {Array<GetAuditResult200ResponseAuditResultBreadcrumbsAccount>}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'documents'?: Array<GetAuditResult200ResponseAuditResultBreadcrumbsAccount>;
    /**
     * 
     * @type {boolean}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'auto_extracted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerAuditLogInner>}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'audit_log'?: Array<ListAccounts200ResponseAccountsInnerAuditLogInner>;
}
/**
 * 
 * @export
 * @interface ListCommitments200ResponseCommitmentsInnerInvestment
 */
export interface ListCommitments200ResponseCommitmentsInnerInvestment {
    /**
     * 
     * @type {string}
     * @memberof ListCommitments200ResponseCommitmentsInnerInvestment
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListCommitments200ResponseCommitmentsInnerInvestment
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListCommitments200ResponseCommitmentsInnerInvestment
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListCommitments200ResponseCommitmentsInnerInvestment
     */
    'currency'?: ListCommitments200ResponseCommitmentsInnerInvestmentCurrencyEnum;
}

export const ListCommitments200ResponseCommitmentsInnerInvestmentCurrencyEnum = {
    Usd: 'USD',
    Eur: 'EUR',
    Cny: 'CNY',
    Jpy: 'JPY',
    Gbp: 'GBP',
    Aud: 'AUD',
    Cad: 'CAD',
    Chf: 'CHF',
    Inr: 'INR',
    Krw: 'KRW',
    Btc: 'BTC'
} as const;

export type ListCommitments200ResponseCommitmentsInnerInvestmentCurrencyEnum = typeof ListCommitments200ResponseCommitmentsInnerInvestmentCurrencyEnum[keyof typeof ListCommitments200ResponseCommitmentsInnerInvestmentCurrencyEnum];

/**
 * 
 * @export
 * @interface ListConnectionAccounts200Response
 */
export interface ListConnectionAccounts200Response {
    /**
     * 
     * @type {number}
     * @memberof ListConnectionAccounts200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListConnectionAccounts200ResponseAccountsInner>}
     * @memberof ListConnectionAccounts200Response
     */
    'accounts'?: Array<ListConnectionAccounts200ResponseAccountsInner>;
}
/**
 * 
 * @export
 * @interface ListConnectionAccounts200ResponseAccountsInner
 */
export interface ListConnectionAccounts200ResponseAccountsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInner
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionAccounts200ResponseAccountsInnerAccount}
     * @memberof ListConnectionAccounts200ResponseAccountsInner
     */
    'account'?: ListConnectionAccounts200ResponseAccountsInnerAccount;
    /**
     * 
     * @type {ListConnectionAccounts200ResponseAccountsInnerConnectionUser}
     * @memberof ListConnectionAccounts200ResponseAccountsInner
     */
    'connection_user'?: ListConnectionAccounts200ResponseAccountsInnerConnectionUser;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionAccounts200ResponseAccountsInner
     */
    'is_auto_import_enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionAccounts200ResponseAccountsInner
     */
    'is_auto_import_documents_enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionAccounts200ResponseAccountsInner
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInner
     */
    'ignore_reason'?: string;
    /**
     * 
     * @type {ListConnectionAccounts200ResponseAccountsInnerAttributes}
     * @memberof ListConnectionAccounts200ResponseAccountsInner
     */
    'attributes'?: ListConnectionAccounts200ResponseAccountsInnerAttributes;
    /**
     * 
     * @type {Array<ListConnectionAccounts200ResponseAccountsInnerLikelyMatchesInner>}
     * @memberof ListConnectionAccounts200ResponseAccountsInner
     */
    'likely_matches'?: Array<ListConnectionAccounts200ResponseAccountsInnerLikelyMatchesInner>;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInner
     */
    'created_at'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionAccounts200ResponseAccountsInnerAccount
 */
export interface ListConnectionAccounts200ResponseAccountsInnerAccount {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAccount
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAccount
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAccount
     */
    'account_type'?: string;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerUser}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAccount
     */
    'user'?: ListDocuments200ResponseDocumentsInnerUser;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAccount
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionAccounts200ResponseAccountsInnerAttributes
 */
export interface ListConnectionAccounts200ResponseAccountsInnerAttributes {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAttributes
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAttributes
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAttributes
     */
    'id_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAttributes
     */
    'id_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAttributes
     */
    'street_line1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAttributes
     */
    'street_line2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAttributes
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAttributes
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAttributes
     */
    'zip'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionAccounts200ResponseAccountsInnerConnectionUser
 */
export interface ListConnectionAccounts200ResponseAccountsInnerConnectionUser {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerConnectionUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerConnectionUser
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionAccounts200ResponseAccountsInnerConnectionUserUser}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerConnectionUser
     */
    'user'?: ListConnectionAccounts200ResponseAccountsInnerConnectionUserUser;
    /**
     * 
     * @type {ListAccounts200ResponseAccountsInnerUser}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerConnectionUser
     */
    'attributes'?: ListAccounts200ResponseAccountsInnerUser;
}
/**
 * 
 * @export
 * @interface ListConnectionAccounts200ResponseAccountsInnerConnectionUserUser
 */
export interface ListConnectionAccounts200ResponseAccountsInnerConnectionUserUser {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerConnectionUserUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerConnectionUserUser
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerConnectionUserUser
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionAccounts200ResponseAccountsInnerLikelyMatchesInner
 */
export interface ListConnectionAccounts200ResponseAccountsInnerLikelyMatchesInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerLikelyMatchesInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerLikelyMatchesInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerLikelyMatchesInner
     */
    'account_type'?: string;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultCompletedBy}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerLikelyMatchesInner
     */
    'user'?: GetAuditResult200ResponseAuditResultCompletedBy;
}
/**
 * @type ListConnectionAccountsAccountParameter
 * @export
 */
export type ListConnectionAccountsAccountParameter = string;

/**
 * 
 * @export
 * @interface ListConnectionCommitments200Response
 */
export interface ListConnectionCommitments200Response {
    /**
     * 
     * @type {number}
     * @memberof ListConnectionCommitments200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListConnectionCommitments200ResponseCommitmentsInner>}
     * @memberof ListConnectionCommitments200Response
     */
    'commitments'?: Array<ListConnectionCommitments200ResponseCommitmentsInner>;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInner
 */
export interface ListConnectionCommitments200ResponseCommitmentsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInner
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInner
     */
    'connection_investment'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitment}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInner
     */
    'commitment'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitment;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInner
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInner
     */
    'ignore_reason'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerAttributes}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInner
     */
    'attributes'?: ListConnectionCommitments200ResponseCommitmentsInnerAttributes;
    /**
     * 
     * @type {Array<ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner>}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInner
     */
    'likely_matches'?: Array<ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner>;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInner
     */
    'created_at'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerAttributes
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerAttributes {
    /**
     * 
     * @type {number}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerAttributes
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerAttributes
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerCommitment
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerCommitment {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitment
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitment
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitment
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitment
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitment
     */
    'investment'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitment
     */
    'account'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitment
     */
    'user'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount
     */
    'account_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment
     */
    'currency'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestmentCurrencyEnum;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestmentInvestmentData}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment
     */
    'investment_data'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestmentInvestmentData;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment
     */
    'deleted_at'?: string;
}

export const ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestmentCurrencyEnum = {
    Usd: 'USD',
    Eur: 'EUR',
    Cny: 'CNY',
    Jpy: 'JPY',
    Gbp: 'GBP',
    Aud: 'AUD',
    Cad: 'CAD',
    Chf: 'CHF',
    Inr: 'INR',
    Krw: 'KRW',
    Btc: 'BTC'
} as const;

export type ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestmentCurrencyEnum = typeof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestmentCurrencyEnum[keyof typeof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestmentCurrencyEnum];

/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestmentInvestmentData
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestmentInvestmentData {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestmentInvestmentData
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentAttributes}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment
     */
    'attributes'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentAttributes;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestment}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment
     */
    'investment'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestment;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccount}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment
     */
    'connection_account'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccount;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentAttributes
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentAttributes {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentAttributes
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentAttributes
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentAttributes
     */
    'subtype'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccount
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccount {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccount
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccount
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountAttributes}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccount
     */
    'attributes'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountAttributes;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountConnectionUser}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccount
     */
    'connection_user'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountConnectionUser;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountAttributes
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountAttributes {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountAttributes
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountAttributes
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountConnectionUser
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountConnectionUser {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountConnectionUser
     */
    '_id'?: string;
    /**
     * 
     * @type {ListAccounts200ResponseAccountsInnerUser}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountConnectionUser
     */
    'attributes'?: ListAccounts200ResponseAccountsInnerUser;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestment
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestment {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestment
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestment
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestment
     */
    'currency'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestmentCurrencyEnum;
}

export const ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestmentCurrencyEnum = {
    Usd: 'USD',
    Eur: 'EUR',
    Cny: 'CNY',
    Jpy: 'JPY',
    Gbp: 'GBP',
    Aud: 'AUD',
    Cad: 'CAD',
    Chf: 'CHF',
    Inr: 'INR',
    Krw: 'KRW',
    Btc: 'BTC'
} as const;

export type ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestmentCurrencyEnum = typeof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestmentCurrencyEnum[keyof typeof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestmentCurrencyEnum];

/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner
     */
    'amount'?: number;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner
     */
    'investment'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner
     */
    'account'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner
     */
    'user'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser;
}
/**
 * @type ListConnectionCommitmentsCommitmentParameter
 * @export
 */
export type ListConnectionCommitmentsCommitmentParameter = string;

/**
 * 
 * @export
 * @interface ListConnectionDocuments200Response
 */
export interface ListConnectionDocuments200Response {
    /**
     * 
     * @type {number}
     * @memberof ListConnectionDocuments200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListConnectionDocuments200ResponseDocumentsInner>}
     * @memberof ListConnectionDocuments200Response
     */
    'documents'?: Array<ListConnectionDocuments200ResponseDocumentsInner>;
}
/**
 * 
 * @export
 * @interface ListConnectionDocuments200ResponseDocumentsInner
 */
export interface ListConnectionDocuments200ResponseDocumentsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'connection_investment'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment;
    /**
     * 
     * @type {ListConnectionDocuments200ResponseDocumentsInnerConnectionAccount}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'connection_account'?: ListConnectionDocuments200ResponseDocumentsInnerConnectionAccount;
    /**
     * 
     * @type {ListConnectionDocuments200ResponseDocumentsInnerDocument}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'document'?: ListConnectionDocuments200ResponseDocumentsInnerDocument;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'ignore_reason'?: string;
    /**
     * 
     * @type {ListConnectionDocuments200ResponseDocumentsInnerAttributes}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'attributes'?: ListConnectionDocuments200ResponseDocumentsInnerAttributes;
    /**
     * 
     * @type {Array<ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner>}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'likely_matches'?: Array<ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner>;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'hash'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'is_file_current'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'file_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'created_at'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionDocuments200ResponseDocumentsInnerAttributes
 */
export interface ListConnectionDocuments200ResponseDocumentsInnerAttributes {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerAttributes
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerAttributes
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerAttributes
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionDocuments200ResponseDocumentsInnerConnectionAccount
 */
export interface ListConnectionDocuments200ResponseDocumentsInnerConnectionAccount {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerConnectionAccount
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerConnectionAccount
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountAttributes}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerConnectionAccount
     */
    'attributes'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountAttributes;
    /**
     * 
     * @type {ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerConnectionAccount
     */
    'account'?: ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount;
}
/**
 * 
 * @export
 * @interface ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount
 */
export interface ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount
     */
    'account_type'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionDocuments200ResponseDocumentsInnerDocument
 */
export interface ListConnectionDocuments200ResponseDocumentsInnerDocument {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocument
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocument
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocument
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocument
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocument
     */
    'hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocument
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocument
     */
    'ownership_type'?: string;
    /**
     * 
     * @type {Array<ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner>}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocument
     */
    'ownership_ids'?: Array<ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner>;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocument
     */
    'user'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser;
}
/**
 * 
 * @export
 * @interface ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner
 */
export interface ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner
     */
    '_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner
     */
    'type'?: string;
    /**
     * 
     * @type {object}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner
     */
    'investment_data'?: object;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner
     */
    'currency'?: string;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerUser}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner
     */
    'user'?: ListDocuments200ResponseDocumentsInnerUser;
    /**
     * 
     * @type {ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccount}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner
     */
    'account'?: ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccount;
}
/**
 * 
 * @export
 * @interface ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccount
 */
export interface ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccount {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccount
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccount
     */
    'name'?: string;
    /**
     * 
     * @type {ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccountUser}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccount
     */
    'user'?: ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccountUser;
}
/**
 * 
 * @export
 * @interface ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccountUser
 */
export interface ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccountUser {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccountUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccountUser
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner
 */
export interface ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner
     */
    'ownership_type'?: string;
    /**
     * 
     * @type {Array<ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInnerOwnershipIdsInner>}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner
     */
    'ownership_ids'?: Array<ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInnerOwnershipIdsInner>;
}
/**
 * 
 * @export
 * @interface ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInnerOwnershipIdsInner
 */
export interface ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInnerOwnershipIdsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInnerOwnershipIdsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInnerOwnershipIdsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInnerOwnershipIdsInner
     */
    'type'?: string;
    /**
     * 
     * @type {object}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInnerOwnershipIdsInner
     */
    'investment_data'?: object;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInnerOwnershipIdsInner
     */
    'currency'?: string;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerUser}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInnerOwnershipIdsInner
     */
    'user'?: ListDocuments200ResponseDocumentsInnerUser;
    /**
     * 
     * @type {ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccount}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInnerOwnershipIdsInner
     */
    'account'?: ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccount;
}
/**
 * @type ListConnectionDocumentsDocumentParameter
 * @export
 */
export type ListConnectionDocumentsDocumentParameter = string;

/**
 * 
 * @export
 * @interface ListConnectionInvestments200Response
 */
export interface ListConnectionInvestments200Response {
    /**
     * 
     * @type {number}
     * @memberof ListConnectionInvestments200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListConnectionInvestments200ResponseInvestmentsInner>}
     * @memberof ListConnectionInvestments200Response
     */
    'investments'?: Array<ListConnectionInvestments200ResponseInvestmentsInner>;
}
/**
 * 
 * @export
 * @interface ListConnectionInvestments200ResponseInvestmentsInner
 */
export interface ListConnectionInvestments200ResponseInvestmentsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInner
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccount}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInner
     */
    'connection_account'?: ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccount;
    /**
     * 
     * @type {ListConnectionInvestments200ResponseInvestmentsInnerInvestment}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInner
     */
    'investment'?: ListConnectionInvestments200ResponseInvestmentsInnerInvestment;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInner
     */
    'is_auto_import_enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInner
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInner
     */
    'ignore_reason'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentAttributes}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInner
     */
    'attributes'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentAttributes;
    /**
     * 
     * @type {Array<ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner>}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInner
     */
    'likely_matches'?: Array<ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner>;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInner
     */
    'created_at'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccount
 */
export interface ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccount {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccount
     */
    '_id'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountAttributes}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccount
     */
    'attributes'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountAttributes;
    /**
     * 
     * @type {ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountAccount}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccount
     */
    'account'?: ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountAccount;
    /**
     * 
     * @type {ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountConnectionUser}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccount
     */
    'connection_user'?: ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountConnectionUser;
}
/**
 * 
 * @export
 * @interface ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountAccount
 */
export interface ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountAccount {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountAccount
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountAccount
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountAccount
     */
    'type'?: string;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultCompletedBy}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountAccount
     */
    'user'?: GetAuditResult200ResponseAuditResultCompletedBy;
}
/**
 * 
 * @export
 * @interface ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountConnectionUser
 */
export interface ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountConnectionUser {
    /**
     * 
     * @type {ListAccounts200ResponseAccountsInnerUser}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountConnectionUser
     */
    'attributes'?: ListAccounts200ResponseAccountsInnerUser;
}
/**
 * 
 * @export
 * @interface ListConnectionInvestments200ResponseInvestmentsInnerInvestment
 */
export interface ListConnectionInvestments200ResponseInvestmentsInnerInvestment {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerInvestment
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerInvestment
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerInvestment
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerInvestment
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {ListConnectionInvestments200ResponseInvestmentsInnerInvestmentInvestmentData}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerInvestment
     */
    'investment_data'?: ListConnectionInvestments200ResponseInvestmentsInnerInvestmentInvestmentData;
    /**
     * 
     * @type {ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerInvestment
     */
    'account'?: ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultCompletedBy}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerInvestment
     */
    'user'?: GetAuditResult200ResponseAuditResultCompletedBy;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerInvestment
     */
    'currency'?: ListConnectionInvestments200ResponseInvestmentsInnerInvestmentCurrencyEnum;
}

export const ListConnectionInvestments200ResponseInvestmentsInnerInvestmentCurrencyEnum = {
    Usd: 'USD',
    Eur: 'EUR',
    Cny: 'CNY',
    Jpy: 'JPY',
    Gbp: 'GBP',
    Aud: 'AUD',
    Cad: 'CAD',
    Chf: 'CHF',
    Inr: 'INR',
    Krw: 'KRW',
    Btc: 'BTC'
} as const;

export type ListConnectionInvestments200ResponseInvestmentsInnerInvestmentCurrencyEnum = typeof ListConnectionInvestments200ResponseInvestmentsInnerInvestmentCurrencyEnum[keyof typeof ListConnectionInvestments200ResponseInvestmentsInnerInvestmentCurrencyEnum];

/**
 * 
 * @export
 * @interface ListConnectionInvestments200ResponseInvestmentsInnerInvestmentInvestmentData
 */
export interface ListConnectionInvestments200ResponseInvestmentsInnerInvestmentInvestmentData {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerInvestmentInvestmentData
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner
 */
export interface ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner
     */
    'type'?: string;
    /**
     * 
     * @type {ListConnectionInvestments200ResponseInvestmentsInnerInvestmentInvestmentData}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner
     */
    'investment_data'?: ListConnectionInvestments200ResponseInvestmentsInnerInvestmentInvestmentData;
    /**
     * 
     * @type {ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner
     */
    'account'?: ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultCompletedBy}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner
     */
    'user'?: GetAuditResult200ResponseAuditResultCompletedBy;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner
     */
    'currency'?: ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInnerCurrencyEnum;
}

export const ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInnerCurrencyEnum = {
    Usd: 'USD',
    Eur: 'EUR',
    Cny: 'CNY',
    Jpy: 'JPY',
    Gbp: 'GBP',
    Aud: 'AUD',
    Cad: 'CAD',
    Chf: 'CHF',
    Inr: 'INR',
    Krw: 'KRW',
    Btc: 'BTC'
} as const;

export type ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInnerCurrencyEnum = typeof ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInnerCurrencyEnum[keyof typeof ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInnerCurrencyEnum];

/**
 * @type ListConnectionInvestmentsInvestmentParameter
 * @export
 */
export type ListConnectionInvestmentsInvestmentParameter = string;

/**
 * 
 * @export
 * @interface ListConnectionSyncs200Response
 */
export interface ListConnectionSyncs200Response {
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListConnectionSyncs200ResponseConnectionsyncsInner>}
     * @memberof ListConnectionSyncs200Response
     */
    'connectionsyncs'?: Array<ListConnectionSyncs200ResponseConnectionsyncsInner>;
}
/**
 * 
 * @export
 * @interface ListConnectionSyncs200ResponseConnectionsyncsInner
 */
export interface ListConnectionSyncs200ResponseConnectionsyncsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {object}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'completion_result'?: object;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'sync_result_file_key'?: string;
    /**
     * 
     * @type {object}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'error_report'?: object;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'job_id'?: string;
    /**
     * 
     * @type {object}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'job_response'?: object;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'error_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'completed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'exit_status'?: string;
    /**
     * 
     * @type {ListConnectionSyncs200ResponseConnectionsyncsInnerConnection}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'connection'?: ListConnectionSyncs200ResponseConnectionsyncsInnerConnection;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'user'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'import'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'documents_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'mapped_documents_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'commitments_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'mapped_commitments_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'transactions_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'mapped_transactions_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'users_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'mapped_users_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'valuations_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'mapped_valuations_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'accounts_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'mapped_accounts_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'investments_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'mapped_investments_count'?: number;
}
/**
 * 
 * @export
 * @interface ListConnectionSyncs200ResponseConnectionsyncsInnerConnection
 */
export interface ListConnectionSyncs200ResponseConnectionsyncsInnerConnection {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInnerConnection
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInnerConnection
     */
    'name'?: string;
    /**
     * 
     * @type {ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInnerConnection
     */
    'user'?: ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser;
    /**
     * 
     * @type {ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInnerConnection
     */
    'account'?: ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser;
}
/**
 * 
 * @export
 * @interface ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser
 */
export interface ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionTags200Response
 */
export interface ListConnectionTags200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionTags200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListConnectionTags200Response
     */
    'tags'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListConnectionTransactions200Response
 */
export interface ListConnectionTransactions200Response {
    /**
     * 
     * @type {number}
     * @memberof ListConnectionTransactions200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListConnectionTransactions200ResponseTransactionsInner>}
     * @memberof ListConnectionTransactions200Response
     */
    'transactions'?: Array<ListConnectionTransactions200ResponseTransactionsInner>;
}
/**
 * 
 * @export
 * @interface ListConnectionTransactions200ResponseTransactionsInner
 */
export interface ListConnectionTransactions200ResponseTransactionsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInner
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment}
     * @memberof ListConnectionTransactions200ResponseTransactionsInner
     */
    'connection_investment'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment;
    /**
     * 
     * @type {Array<ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInner>}
     * @memberof ListConnectionTransactions200ResponseTransactionsInner
     */
    'joint_transactions'?: Array<ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInner>;
    /**
     * 
     * @type {ListConnectionTransactions200ResponseTransactionsInnerTransaction}
     * @memberof ListConnectionTransactions200ResponseTransactionsInner
     */
    'transaction'?: ListConnectionTransactions200ResponseTransactionsInnerTransaction;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionTransactions200ResponseTransactionsInner
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInner
     */
    'ignore_reason'?: string;
    /**
     * 
     * @type {ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInnerAttributes}
     * @memberof ListConnectionTransactions200ResponseTransactionsInner
     */
    'attributes'?: ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInnerAttributes;
    /**
     * 
     * @type {Array<ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner>}
     * @memberof ListConnectionTransactions200ResponseTransactionsInner
     */
    'likely_matches'?: Array<ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner>;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInner
     */
    'created_at'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInner
 */
export interface ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInner
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInnerAttributes}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInner
     */
    'attributes'?: ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInnerAttributes;
}
/**
 * 
 * @export
 * @interface ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInnerAttributes
 */
export interface ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInnerAttributes {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInnerAttributes
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInnerAttributes
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInnerAttributes
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInnerAttributes
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner
 */
export interface ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner
     */
    'description'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner
     */
    'investment'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner
     */
    'account'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner
     */
    'user'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser;
}
/**
 * 
 * @export
 * @interface ListConnectionTransactions200ResponseTransactionsInnerTransaction
 */
export interface ListConnectionTransactions200ResponseTransactionsInnerTransaction {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerTransaction
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerTransaction
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerTransaction
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerTransaction
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerTransaction
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerTransaction
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerTransaction
     */
    'investment'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerTransaction
     */
    'account'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerTransaction
     */
    'user'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser;
}
/**
 * @type ListConnectionTransactionsTransactionParameter
 * @export
 */
export type ListConnectionTransactionsTransactionParameter = string;

/**
 * 
 * @export
 * @interface ListConnectionUsers200Response
 */
export interface ListConnectionUsers200Response {
    /**
     * 
     * @type {number}
     * @memberof ListConnectionUsers200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListConnectionUsers200ResponseUsersInner>}
     * @memberof ListConnectionUsers200Response
     */
    'users'?: Array<ListConnectionUsers200ResponseUsersInner>;
}
/**
 * 
 * @export
 * @interface ListConnectionUsers200ResponseUsersInner
 */
export interface ListConnectionUsers200ResponseUsersInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionUsers200ResponseUsersInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionUsers200ResponseUsersInner
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionUsers200ResponseUsersInnerUser}
     * @memberof ListConnectionUsers200ResponseUsersInner
     */
    'user'?: ListConnectionUsers200ResponseUsersInnerUser;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionUsers200ResponseUsersInner
     */
    'is_auto_import_enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionUsers200ResponseUsersInner
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionUsers200ResponseUsersInner
     */
    'ignore_reason'?: string;
    /**
     * 
     * @type {ListAccounts200ResponseAccountsInnerUser}
     * @memberof ListConnectionUsers200ResponseUsersInner
     */
    'attributes'?: ListAccounts200ResponseAccountsInnerUser;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionUsers200ResponseUsersInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {Array<GetAuditResult200ResponseAuditResultCompletedBy>}
     * @memberof ListConnectionUsers200ResponseUsersInner
     */
    'likely_matches'?: Array<GetAuditResult200ResponseAuditResultCompletedBy>;
}
/**
 * 
 * @export
 * @interface ListConnectionUsers200ResponseUsersInnerUser
 */
export interface ListConnectionUsers200ResponseUsersInnerUser {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionUsers200ResponseUsersInnerUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionUsers200ResponseUsersInnerUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionUsers200ResponseUsersInnerUser
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionUsers200ResponseUsersInnerUser
     */
    'deleted_at'?: string;
}
/**
 * @type ListConnectionUsersUserParameter
 * @export
 */
export type ListConnectionUsersUserParameter = string;

/**
 * 
 * @export
 * @interface ListConnectionValuations200Response
 */
export interface ListConnectionValuations200Response {
    /**
     * 
     * @type {number}
     * @memberof ListConnectionValuations200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListConnectionValuations200ResponseValuationsInner>}
     * @memberof ListConnectionValuations200Response
     */
    'valuations'?: Array<ListConnectionValuations200ResponseValuationsInner>;
}
/**
 * 
 * @export
 * @interface ListConnectionValuations200ResponseValuationsInner
 */
export interface ListConnectionValuations200ResponseValuationsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionValuations200ResponseValuationsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionValuations200ResponseValuationsInner
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment}
     * @memberof ListConnectionValuations200ResponseValuationsInner
     */
    'connection_investment'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment;
    /**
     * 
     * @type {ListConnectionValuations200ResponseValuationsInnerValuation}
     * @memberof ListConnectionValuations200ResponseValuationsInner
     */
    'valuation'?: ListConnectionValuations200ResponseValuationsInnerValuation;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionValuations200ResponseValuationsInner
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionValuations200ResponseValuationsInner
     */
    'ignore_reason'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerAttributes}
     * @memberof ListConnectionValuations200ResponseValuationsInner
     */
    'attributes'?: ListConnectionCommitments200ResponseCommitmentsInnerAttributes;
    /**
     * 
     * @type {Array<ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner>}
     * @memberof ListConnectionValuations200ResponseValuationsInner
     */
    'likely_matches'?: Array<ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner>;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionValuations200ResponseValuationsInner
     */
    'created_at'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionValuations200ResponseValuationsInnerValuation
 */
export interface ListConnectionValuations200ResponseValuationsInnerValuation {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionValuations200ResponseValuationsInnerValuation
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionValuations200ResponseValuationsInnerValuation
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionValuations200ResponseValuationsInnerValuation
     */
    'date'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment}
     * @memberof ListConnectionValuations200ResponseValuationsInnerValuation
     */
    'investment'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount}
     * @memberof ListConnectionValuations200ResponseValuationsInnerValuation
     */
    'account'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser}
     * @memberof ListConnectionValuations200ResponseValuationsInnerValuation
     */
    'user'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser;
}
/**
 * @type ListConnectionValuationsValuationParameter
 * @export
 */
export type ListConnectionValuationsValuationParameter = string;

/**
 * 
 * @export
 * @interface ListConnections200Response
 */
export interface ListConnections200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListConnections200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListConnections200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListConnections200ResponseConnectionsInner>}
     * @memberof ListConnections200Response
     */
    'connections'?: Array<ListConnections200ResponseConnectionsInner>;
}
/**
 * 
 * @export
 * @interface ListConnections200ResponseConnectionsInner
 */
export interface ListConnections200ResponseConnectionsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'name'?: string;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerIntegration}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'integration'?: ListConnections200ResponseConnectionsInnerIntegration;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerUser}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'user'?: ListConnections200ResponseConnectionsInnerUser;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerAdvisoryGroup}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'advisory_group'?: ListConnections200ResponseConnectionsInnerAdvisoryGroup;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInnerGroup}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'account'?: GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInnerGroup;
    /**
     * 
     * @type {Array<GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInnerGroup>}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'account_list'?: Array<GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInnerGroup>;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInnerGroup}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'asset_manager'?: GetAuditResult200ResponseAuditResultBreadcrumbsUserAdvisorsInnerGroup;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerUser>}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'contacts'?: Array<ListAccounts200ResponseAccountsInnerUser>;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerMostRecentSync}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'most_recent_sync'?: ListConnections200ResponseConnectionsInnerMostRecentSync;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerMostRecentImport}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'most_recent_import'?: ListConnections200ResponseConnectionsInnerMostRecentImport;
    /**
     * 
     * @type {Array<ListConnections200ResponseConnectionsInnerAuditLogInner>}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'audit_log'?: Array<ListConnections200ResponseConnectionsInnerAuditLogInner>;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'ral_status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'is_enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'long_running'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'outreach_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'outreach_status_updated_at'?: string;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerError}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'error'?: ListConnections200ResponseConnectionsInnerError;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerErrorInformation}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'error_information'?: ListConnections200ResponseConnectionsInnerErrorInformation;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'credentials'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'tfa_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'tfa_phone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'require_phone'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'expect_documents_over_email'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'newest_data_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'last_connection_at'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'data'?: { [key: string]: any; };
    /**
     * 
     * @type {boolean}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'use_tfa'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'test'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {Array<ListConnections200ResponseConnectionsInnerMessagesInner>}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'messages'?: Array<ListConnections200ResponseConnectionsInnerMessagesInner>;
}
/**
 * 
 * @export
 * @interface ListConnections200ResponseConnectionsInnerAdvisoryGroup
 */
export interface ListConnections200ResponseConnectionsInnerAdvisoryGroup {
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerAdvisoryGroup
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerAdvisoryGroup
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ListConnections200ResponseConnectionsInnerAuditLogInner
 */
export interface ListConnections200ResponseConnectionsInnerAuditLogInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerAuditLogInner
     */
    'timestamp'?: string;
}
/**
 * 
 * @export
 * @interface ListConnections200ResponseConnectionsInnerError
 */
export interface ListConnections200ResponseConnectionsInnerError {
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerError
     */
    'user'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerError
     */
    'traceback'?: string;
}
/**
 * 
 * @export
 * @interface ListConnections200ResponseConnectionsInnerErrorInformation
 */
export interface ListConnections200ResponseConnectionsInnerErrorInformation {
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerErrorInformation
     */
    'error_job_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerErrorInformation
     */
    'error_job_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerErrorInformation
     */
    'error_code'?: string;
}
/**
 * 
 * @export
 * @interface ListConnections200ResponseConnectionsInnerIntegration
 */
export interface ListConnections200ResponseConnectionsInnerIntegration {
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerIntegration
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerIntegration
     */
    'logo_url'?: string;
}
/**
 * 
 * @export
 * @interface ListConnections200ResponseConnectionsInnerMessagesInner
 */
export interface ListConnections200ResponseConnectionsInnerMessagesInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInner
     */
    '_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInner
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInner
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInner
     */
    'to'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInner
     */
    'account'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInner
     */
    'user'?: string | null;
    /**
     * 
     * @type {Array<ListConnections200ResponseConnectionsInnerMessagesInnerAttachmentsInner>}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInner
     */
    'attachments'?: Array<ListConnections200ResponseConnectionsInnerMessagesInnerAttachmentsInner>;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerMessagesInnerMetadata}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInner
     */
    'metadata'?: ListConnections200ResponseConnectionsInnerMessagesInnerMetadata;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInner
     */
    'category'?: ListConnections200ResponseConnectionsInnerMessagesInnerCategoryEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInner
     */
    'connection_expects_documents'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInner
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInner
     */
    'completed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInner
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInner
     */
    'body'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInner
     */
    'parsed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInner
     */
    'deleted_at'?: string;
}

export const ListConnections200ResponseConnectionsInnerMessagesInnerCategoryEnum = {
    Tfa: 'tfa',
    Portal: 'portal',
    Deactivated: 'deactivated'
} as const;

export type ListConnections200ResponseConnectionsInnerMessagesInnerCategoryEnum = typeof ListConnections200ResponseConnectionsInnerMessagesInnerCategoryEnum[keyof typeof ListConnections200ResponseConnectionsInnerMessagesInnerCategoryEnum];

/**
 * 
 * @export
 * @interface ListConnections200ResponseConnectionsInnerMessagesInnerAttachmentsInner
 */
export interface ListConnections200ResponseConnectionsInnerMessagesInnerAttachmentsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInnerAttachmentsInner
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInnerAttachmentsInner
     */
    'mimetype'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInnerAttachmentsInner
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInnerAttachmentsInner
     */
    'added_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInnerAttachmentsInner
     */
    '_id'?: string | null;
}
/**
 * 
 * @export
 * @interface ListConnections200ResponseConnectionsInnerMessagesInnerMetadata
 */
export interface ListConnections200ResponseConnectionsInnerMessagesInnerMetadata {
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerMessagesInnerMetadataAdmin}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInnerMetadata
     */
    'admin'?: ListConnections200ResponseConnectionsInnerMessagesInnerMetadataAdmin;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerMessagesInnerMetadataAdmin}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInnerMetadata
     */
    'advisor'?: ListConnections200ResponseConnectionsInnerMessagesInnerMetadataAdmin;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerMessagesInnerMetadataAdmin}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInnerMetadata
     */
    'investor'?: ListConnections200ResponseConnectionsInnerMessagesInnerMetadataAdmin;
}
/**
 * 
 * @export
 * @interface ListConnections200ResponseConnectionsInnerMessagesInnerMetadataAdmin
 */
export interface ListConnections200ResponseConnectionsInnerMessagesInnerMetadataAdmin {
    /**
     * 
     * @type {boolean}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInnerMetadataAdmin
     */
    'flagged'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInnerMetadataAdmin
     */
    'flagged_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInnerMetadataAdmin
     */
    'read'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMessagesInnerMetadataAdmin
     */
    'read_at'?: string;
}
/**
 * 
 * @export
 * @interface ListConnections200ResponseConnectionsInnerMostRecentImport
 */
export interface ListConnections200ResponseConnectionsInnerMostRecentImport {
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMostRecentImport
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMostRecentImport
     */
    'started_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMostRecentImport
     */
    'exit_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMostRecentImport
     */
    'error_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMostRecentImport
     */
    'completed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMostRecentImport
     */
    'job_id'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ListConnections200ResponseConnectionsInnerMostRecentImport
     */
    'job_response'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMostRecentImport
     */
    'initiator'?: string;
}
/**
 * 
 * @export
 * @interface ListConnections200ResponseConnectionsInnerMostRecentSync
 */
export interface ListConnections200ResponseConnectionsInnerMostRecentSync {
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMostRecentSync
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMostRecentSync
     */
    'updated_at'?: string;
    /**
     * 
     * @type {object}
     * @memberof ListConnections200ResponseConnectionsInnerMostRecentSync
     */
    'completion_result'?: object;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ListConnections200ResponseConnectionsInnerMostRecentSync
     */
    'error_report'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMostRecentSync
     */
    'job_id'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ListConnections200ResponseConnectionsInnerMostRecentSync
     */
    'job_response'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMostRecentSync
     */
    'error_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMostRecentSync
     */
    'completed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMostRecentSync
     */
    'exit_status'?: string;
    /**
     * 
     * @type {object}
     * @memberof ListConnections200ResponseConnectionsInnerMostRecentSync
     */
    'job_request'?: object;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMostRecentSync
     */
    'connection'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMostRecentSync
     */
    'user'?: string | null;
}
/**
 * 
 * @export
 * @interface ListConnections200ResponseConnectionsInnerUser
 */
export interface ListConnections200ResponseConnectionsInnerUser {
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerUser
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnections200ResponseConnectionsInnerUser
     */
    'is_deactivated'?: boolean;
    /**
     * 
     * @type {Array<ListConnections200ResponseConnectionsInnerUserAdvisorsInner>}
     * @memberof ListConnections200ResponseConnectionsInnerUser
     */
    'advisors'?: Array<ListConnections200ResponseConnectionsInnerUserAdvisorsInner>;
}
/**
 * 
 * @export
 * @interface ListConnections200ResponseConnectionsInnerUserAdvisorsInner
 */
export interface ListConnections200ResponseConnectionsInnerUserAdvisorsInner {
    /**
     * 
     * @type {ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInnerAccount}
     * @memberof ListConnections200ResponseConnectionsInnerUserAdvisorsInner
     */
    'group'?: ListAssetManagers200ResponseAssetManagersInnerConnectionsInnerRalRequestsInnerOwnershipIdsInnerAccount;
}
/**
 * 
 * @export
 * @interface ListContacts200Response
 */
export interface ListContacts200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListContacts200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListContacts200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListContacts200ResponseContactsInner>}
     * @memberof ListContacts200Response
     */
    'contacts'?: Array<ListContacts200ResponseContactsInner>;
}
/**
 * 
 * @export
 * @interface ListContacts200ResponseContactsInner
 */
export interface ListContacts200ResponseContactsInner {
    /**
     * 
     * @type {string}
     * @memberof ListContacts200ResponseContactsInner
     */
    '_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListContacts200ResponseContactsInner
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListContacts200ResponseContactsInner
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListContacts200ResponseContactsInner
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListContacts200ResponseContactsInner
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListContacts200ResponseContactsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListContacts200ResponseContactsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListContacts200ResponseContactsInner
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListDefaultDocumentImportSettings200Response
 */
export interface ListDefaultDocumentImportSettings200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListDefaultDocumentImportSettings200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListDefaultDocumentImportSettings200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner>}
     * @memberof ListDefaultDocumentImportSettings200Response
     */
    'default_document_import_settings'?: Array<ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner>;
    /**
     * 
     * @type {ListDefaultDocumentImportSettings200ResponseMergedSetting}
     * @memberof ListDefaultDocumentImportSettings200Response
     */
    'merged_setting'?: ListDefaultDocumentImportSettings200ResponseMergedSetting;
}
/**
 * 
 * @export
 * @interface ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
 */
export interface ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner {
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    '_id'?: string | null;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerAdvisoryGroup}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'integration'?: ListConnections200ResponseConnectionsInnerAdvisoryGroup;
    /**
     * 
     * @type {ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAssetManager}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'asset_manager'?: ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAssetManager;
    /**
     * 
     * @type {ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAdvisoryGroup}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'advisory_group'?: ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAdvisoryGroup;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'global_default_extraction_setting'?: ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultExtractionSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'global_default_auto_sort_setting'?: ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultAutoSortSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'global_default_transaction_setting'?: ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultTransactionSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'global_default_process_setting'?: ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultProcessSettingEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'default_transaction_settings'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'deleted_at'?: string;
}

export const ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultExtractionSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultExtractionSettingEnum = typeof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultExtractionSettingEnum[keyof typeof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultExtractionSettingEnum];
export const ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultAutoSortSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultAutoSortSettingEnum = typeof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultAutoSortSettingEnum[keyof typeof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultAutoSortSettingEnum];
export const ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultTransactionSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultTransactionSettingEnum = typeof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultTransactionSettingEnum[keyof typeof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultTransactionSettingEnum];
export const ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultProcessSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultProcessSettingEnum = typeof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultProcessSettingEnum[keyof typeof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultProcessSettingEnum];

/**
 * 
 * @export
 * @interface ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAdvisoryGroup
 */
export interface ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAdvisoryGroup {
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAdvisoryGroup
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAdvisoryGroup
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAssetManager
 */
export interface ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAssetManager {
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAssetManager
     */
    '_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAssetManager
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ListDefaultDocumentImportSettings200ResponseMergedSetting
 */
export interface ListDefaultDocumentImportSettings200ResponseMergedSetting {
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseMergedSetting
     */
    'global_default_extraction_setting'?: ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultExtractionSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseMergedSetting
     */
    'global_default_auto_sort_setting'?: ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultAutoSortSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseMergedSetting
     */
    'global_default_transaction_setting'?: ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultTransactionSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseMergedSetting
     */
    'global_default_process_setting'?: ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultProcessSettingEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ListDefaultDocumentImportSettings200ResponseMergedSetting
     */
    'default_transaction_settings'?: { [key: string]: string; };
}

export const ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultExtractionSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled'
} as const;

export type ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultExtractionSettingEnum = typeof ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultExtractionSettingEnum[keyof typeof ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultExtractionSettingEnum];
export const ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultAutoSortSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled'
} as const;

export type ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultAutoSortSettingEnum = typeof ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultAutoSortSettingEnum[keyof typeof ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultAutoSortSettingEnum];
export const ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultTransactionSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled'
} as const;

export type ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultTransactionSettingEnum = typeof ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultTransactionSettingEnum[keyof typeof ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultTransactionSettingEnum];
export const ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultProcessSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled'
} as const;

export type ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultProcessSettingEnum = typeof ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultProcessSettingEnum[keyof typeof ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultProcessSettingEnum];

/**
 * 
 * @export
 * @interface ListDocuments200Response
 */
export interface ListDocuments200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListDocuments200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListDocuments200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListDocuments200ResponseDocumentsInner>}
     * @memberof ListDocuments200Response
     */
    'documents'?: Array<ListDocuments200ResponseDocumentsInner>;
}
/**
 * 
 * @export
 * @interface ListDocuments200ResponseDocumentsInner
 */
export interface ListDocuments200ResponseDocumentsInner {
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    '_id'?: string | null;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerUser}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'user'?: ListDocuments200ResponseDocumentsInnerUser;
    /**
     * 
     * @type {Array<ListDocuments200ResponseDocumentsInnerOwnershipIdsInner>}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'ownership_ids'?: Array<ListDocuments200ResponseDocumentsInnerOwnershipIdsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'ownership_type'?: string;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'connection'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {Array<ListDocuments200ResponseDocumentsInnerMetadataInner>}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'metadata'?: Array<ListDocuments200ResponseDocumentsInnerMetadataInner>;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'hasValidMetadata'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'shouldGenerateTransactions'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'shouldProcessDocument'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'file_type'?: string;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerFiles}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'files'?: ListDocuments200ResponseDocumentsInnerFiles;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerTraining}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'training'?: ListDocuments200ResponseDocumentsInnerTraining;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerTraining}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'post_job_review_required'?: ListDocuments200ResponseDocumentsInnerTraining;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerFileDownloadUrls}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'file_download_urls'?: ListDocuments200ResponseDocumentsInnerFileDownloadUrls;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerAuditLogInner>}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'audit_log'?: Array<ListAccounts200ResponseAccountsInnerAuditLogInner>;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerTransactionDescriptions}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'transaction_descriptions'?: ListDocuments200ResponseDocumentsInnerTransactionDescriptions;
}
/**
 * 
 * @export
 * @interface ListDocuments200ResponseDocumentsInnerFileDownloadUrls
 */
export interface ListDocuments200ResponseDocumentsInnerFileDownloadUrls {
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerFileDownloadUrls
     */
    'words'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerFileDownloadUrls
     */
    'doc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerFileDownloadUrls
     */
    'stax_doc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerFileDownloadUrls
     */
    'ocr_result'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListDocuments200ResponseDocumentsInnerFileDownloadUrls
     */
    'pages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListDocuments200ResponseDocumentsInnerFiles
 */
export interface ListDocuments200ResponseDocumentsInnerFiles {
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerFiles
     */
    'words'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerFiles
     */
    'doc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerFiles
     */
    'stax_doc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerFiles
     */
    'ocr_result'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListDocuments200ResponseDocumentsInnerFiles
     */
    'pages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListDocuments200ResponseDocumentsInnerMetadataInner
 */
export interface ListDocuments200ResponseDocumentsInnerMetadataInner {
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerMetadataInner
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerMetadataInner
     */
    'investment'?: string;
    /**
     * 
     * @type {any}
     * @memberof ListDocuments200ResponseDocumentsInnerMetadataInner
     */
    'value'?: any;
    /**
     * 
     * @type {Array<ListDocuments200ResponseDocumentsInnerMetadataInnerTagsInner>}
     * @memberof ListDocuments200ResponseDocumentsInnerMetadataInner
     */
    'tags'?: Array<ListDocuments200ResponseDocumentsInnerMetadataInnerTagsInner>;
    /**
     * 
     * @type {boolean}
     * @memberof ListDocuments200ResponseDocumentsInnerMetadataInner
     */
    'shouldGenerateTransactions'?: boolean;
}
/**
 * 
 * @export
 * @interface ListDocuments200ResponseDocumentsInnerMetadataInnerTagsInner
 */
export interface ListDocuments200ResponseDocumentsInnerMetadataInnerTagsInner {
    /**
     * 
     * @type {number}
     * @memberof ListDocuments200ResponseDocumentsInnerMetadataInnerTagsInner
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ListDocuments200ResponseDocumentsInnerMetadataInnerTagsInner
     */
    'line': number;
    /**
     * 
     * @type {number}
     * @memberof ListDocuments200ResponseDocumentsInnerMetadataInnerTagsInner
     */
    'word': number;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerMetadataInnerTagsInner
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface ListDocuments200ResponseDocumentsInnerOwnershipIdsInner
 */
export interface ListDocuments200ResponseDocumentsInnerOwnershipIdsInner {
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerOwnershipIdsInner
     */
    '_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerOwnershipIdsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerOwnershipIdsInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerOwnershipIdsInner
     */
    'currency'?: string;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof ListDocuments200ResponseDocumentsInnerOwnershipIdsInner
     */
    'user'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof ListDocuments200ResponseDocumentsInnerOwnershipIdsInner
     */
    'account'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
}
/**
 * 
 * @export
 * @interface ListDocuments200ResponseDocumentsInnerTraining
 */
export interface ListDocuments200ResponseDocumentsInnerTraining {
    /**
     * 
     * @type {boolean}
     * @memberof ListDocuments200ResponseDocumentsInnerTraining
     */
    'sort'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDocuments200ResponseDocumentsInnerTraining
     */
    'extraction'?: boolean;
}
/**
 * 
 * @export
 * @interface ListDocuments200ResponseDocumentsInnerTransactionDescriptions
 */
export interface ListDocuments200ResponseDocumentsInnerTransactionDescriptions {
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'buy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'sale'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'capital_call'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'cash_distribution_return_of_capital'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'capital_commitment'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'recallable_return_of_capital'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'recallable_return_on_capital'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'return_of_capital'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'return_on_capital'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'interest_income'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'interest_expense'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'incentive_fee'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'redemption'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'subscription'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'valuation_change'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'cash_deposit'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'cash_withdrawal'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'reinvestment'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'dividend'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'dividend_reversal'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'management_fee'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'management_fee_rebate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'other_fee'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'transfer_in'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'transfer_out'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'tax_distribution'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'other'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerTransactionDescriptions
     */
    'joint_transaction'?: string;
}
/**
 * 
 * @export
 * @interface ListDocuments200ResponseDocumentsInnerUser
 */
export interface ListDocuments200ResponseDocumentsInnerUser {
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerUser
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface ListExchanges200Response
 */
export interface ListExchanges200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListExchanges200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListExchanges200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListExchanges200ResponseExchangesInner>}
     * @memberof ListExchanges200Response
     */
    'exchanges'?: Array<ListExchanges200ResponseExchangesInner>;
}
/**
 * 
 * @export
 * @interface ListExchanges200ResponseExchangesInner
 */
export interface ListExchanges200ResponseExchangesInner {
    /**
     * 
     * @type {string}
     * @memberof ListExchanges200ResponseExchangesInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListExchanges200ResponseExchangesInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListExchanges200ResponseExchangesInner
     */
    'ticker_prefix'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListExchanges200ResponseExchangesInner
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListExchanges200ResponseExchangesInner
     */
    'website'?: string;
    /**
     * 
     * @type {ListExchanges200ResponseExchangesInnerLocation}
     * @memberof ListExchanges200ResponseExchangesInner
     */
    'location'?: ListExchanges200ResponseExchangesInnerLocation;
    /**
     * 
     * @type {string}
     * @memberof ListExchanges200ResponseExchangesInner
     */
    'founded'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListExchanges200ResponseExchangesInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListExchanges200ResponseExchangesInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListExchanges200ResponseExchangesInner
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListExchanges200ResponseExchangesInnerLocation
 */
export interface ListExchanges200ResponseExchangesInnerLocation {
    /**
     * 
     * @type {string}
     * @memberof ListExchanges200ResponseExchangesInnerLocation
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListExchanges200ResponseExchangesInnerLocation
     */
    'city'?: string;
}
/**
 * 
 * @export
 * @interface ListExtIntegrations200Response
 */
export interface ListExtIntegrations200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListExtIntegrations200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListExtIntegrations200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListExtIntegrations200ResponseExtegrationsInner>}
     * @memberof ListExtIntegrations200Response
     */
    'extegrations'?: Array<ListExtIntegrations200ResponseExtegrationsInner>;
}
/**
 * 
 * @export
 * @interface ListExtIntegrations200ResponseExtegrationsInner
 */
export interface ListExtIntegrations200ResponseExtegrationsInner {
    /**
     * 
     * @type {string}
     * @memberof ListExtIntegrations200ResponseExtegrationsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListExtIntegrations200ResponseExtegrationsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListExtIntegrations200ResponseExtegrationsInner
     */
    'lambda_arn'?: string;
    /**
     * 
     * @type {Array<ListExtIntegrations200ResponseExtegrationsInnerRequiredCredsInner>}
     * @memberof ListExtIntegrations200ResponseExtegrationsInner
     */
    'required_creds'?: Array<ListExtIntegrations200ResponseExtegrationsInnerRequiredCredsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListExtIntegrations200ResponseExtegrationsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListExtIntegrations200ResponseExtegrationsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListExtIntegrations200ResponseExtegrationsInner
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListExtIntegrations200ResponseExtegrationsInnerRequiredCredsInner
 */
export interface ListExtIntegrations200ResponseExtegrationsInnerRequiredCredsInner {
    /**
     * 
     * @type {string}
     * @memberof ListExtIntegrations200ResponseExtegrationsInnerRequiredCredsInner
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListExtIntegrations200ResponseExtegrationsInnerRequiredCredsInner
     */
    'display_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListExtIntegrations200ResponseExtegrationsInnerRequiredCredsInner
     */
    'input'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListExtIntegrations200ResponseExtegrationsInnerRequiredCredsInner
     */
    'options'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListExtIntegrations200ResponseExtegrationsInnerRequiredCredsInner
     */
    'hint'?: string;
}
/**
 * 
 * @export
 * @interface ListExtegrationConnections200Response
 */
export interface ListExtegrationConnections200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListExtegrationConnections200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListExtegrationConnections200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListAdvisoryGroups200ResponseAdvisoryGroupsInnerExtegrationsInner>}
     * @memberof ListExtegrationConnections200Response
     */
    'extegrations'?: Array<ListAdvisoryGroups200ResponseAdvisoryGroupsInnerExtegrationsInner>;
}
/**
 * 
 * @export
 * @interface ListIntegrations200Response
 */
export interface ListIntegrations200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListIntegrations200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListIntegrations200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListIntegrations200ResponseIntegrationsInner>}
     * @memberof ListIntegrations200Response
     */
    'integrations'?: Array<ListIntegrations200ResponseIntegrationsInner>;
}
/**
 * 
 * @export
 * @interface ListIntegrations200ResponseIntegrationsInner
 */
export interface ListIntegrations200ResponseIntegrationsInner {
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'has_worker'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'tfa_required'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'phone_required'?: boolean;
    /**
     * 
     * @type {Array<ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner>}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'required_creds'?: Array<ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'portal_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'lambda_arn'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'from_email_domains'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'from_email_addresses'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'cycle_container_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'worker_version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'connections_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'errored_connections_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'logo_url'?: string;
    /**
     * 
     * @type {ListIntegrations200ResponseIntegrationsInnerAutologinConfig}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'autologin_config'?: ListIntegrations200ResponseIntegrationsInnerAutologinConfig;
}
/**
 * 
 * @export
 * @interface ListIntegrations200ResponseIntegrationsInnerAutologinConfig
 */
export interface ListIntegrations200ResponseIntegrationsInnerAutologinConfig {
    /**
     * 
     * @type {ListIntegrations200ResponseIntegrationsInnerAutologinConfigQuerySelectors}
     * @memberof ListIntegrations200ResponseIntegrationsInnerAutologinConfig
     */
    'query_selectors'?: ListIntegrations200ResponseIntegrationsInnerAutologinConfigQuerySelectors;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInnerAutologinConfig
     */
    'tfa_code_regex'?: string;
}
/**
 * 
 * @export
 * @interface ListIntegrations200ResponseIntegrationsInnerAutologinConfigQuerySelectors
 */
export interface ListIntegrations200ResponseIntegrationsInnerAutologinConfigQuerySelectors {
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInnerAutologinConfigQuerySelectors
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInnerAutologinConfigQuerySelectors
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInnerAutologinConfigQuerySelectors
     */
    'submit'?: string;
}
/**
 * 
 * @export
 * @interface ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner
 */
export interface ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner {
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner
     */
    'input'?: ListIntegrations200ResponseIntegrationsInnerRequiredCredsInnerInputEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner
     */
    'options'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner
     */
    'hint'?: string;
}

export const ListIntegrations200ResponseIntegrationsInnerRequiredCredsInnerInputEnum = {
    Text: 'text',
    Password: 'password',
    Select: 'select',
    Checkbox: 'checkbox'
} as const;

export type ListIntegrations200ResponseIntegrationsInnerRequiredCredsInnerInputEnum = typeof ListIntegrations200ResponseIntegrationsInnerRequiredCredsInnerInputEnum[keyof typeof ListIntegrations200ResponseIntegrationsInnerRequiredCredsInnerInputEnum];

/**
 * 
 * @export
 * @interface ListInvestmentHistory200Response
 */
export interface ListInvestmentHistory200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListInvestmentHistory200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListInvestmentHistory200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListInvestmentHistory200ResponseHistoryInner>}
     * @memberof ListInvestmentHistory200Response
     */
    'history'?: Array<ListInvestmentHistory200ResponseHistoryInner>;
}
/**
 * 
 * @export
 * @interface ListInvestmentHistory200ResponseHistoryInner
 */
export interface ListInvestmentHistory200ResponseHistoryInner {
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentHistory200ResponseHistoryInner
     */
    'type'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ListInvestmentHistory200ResponseHistoryInner
     */
    'data'?: { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface ListInvestmentMasters200Response
 */
export interface ListInvestmentMasters200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListInvestmentMasters200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListInvestmentMasters200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListInvestmentMasters200ResponseInvestmentMastersInner>}
     * @memberof ListInvestmentMasters200Response
     */
    'investment_masters'?: Array<ListInvestmentMasters200ResponseInvestmentMastersInner>;
}
/**
 * 
 * @export
 * @interface ListInvestmentMasters200ResponseInvestmentMastersInner
 */
export interface ListInvestmentMasters200ResponseInvestmentMastersInner {
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInner
     */
    '_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInner
     */
    'ticker'?: string;
    /**
     * 
     * @type {ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInner
     */
    'asset_manager'?: ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInner
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInner
     */
    'sub_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInner
     */
    'website'?: string;
    /**
     * 
     * @type {ListInvestmentMasters200ResponseInvestmentMastersInnerSocials}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInner
     */
    'socials'?: ListInvestmentMasters200ResponseInvestmentMastersInnerSocials;
    /**
     * 
     * @type {Array<ListInvestmentMasters200ResponseInvestmentMastersInnerContactsInner>}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInner
     */
    'contacts'?: Array<ListInvestmentMasters200ResponseInvestmentMastersInnerContactsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInner
     */
    'currency'?: ListInvestmentMasters200ResponseInvestmentMastersInnerCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInner
     */
    'country'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInner
     */
    'price'?: number;
    /**
     * 
     * @type {ListInvestmentMasters200ResponseInvestmentMastersInnerExchange}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInner
     */
    'exchange'?: ListInvestmentMasters200ResponseInvestmentMastersInnerExchange;
    /**
     * 
     * @type {number}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInner
     */
    'vintage_year'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInner
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInner
     */
    'deleted_at'?: string;
}

export const ListInvestmentMasters200ResponseInvestmentMastersInnerCurrencyEnum = {
    Usd: 'USD',
    Eur: 'EUR',
    Cny: 'CNY',
    Jpy: 'JPY',
    Gbp: 'GBP',
    Aud: 'AUD',
    Cad: 'CAD',
    Chf: 'CHF',
    Inr: 'INR',
    Krw: 'KRW',
    Btc: 'BTC'
} as const;

export type ListInvestmentMasters200ResponseInvestmentMastersInnerCurrencyEnum = typeof ListInvestmentMasters200ResponseInvestmentMastersInnerCurrencyEnum[keyof typeof ListInvestmentMasters200ResponseInvestmentMastersInnerCurrencyEnum];

/**
 * 
 * @export
 * @interface ListInvestmentMasters200ResponseInvestmentMastersInnerContactsInner
 */
export interface ListInvestmentMasters200ResponseInvestmentMastersInnerContactsInner {
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInnerContactsInner
     */
    'contact'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInnerContactsInner
     */
    'roles'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListInvestmentMasters200ResponseInvestmentMastersInnerExchange
 */
export interface ListInvestmentMasters200ResponseInvestmentMastersInnerExchange {
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInnerExchange
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInnerExchange
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInnerExchange
     */
    'ticker_prefix'?: string;
}
/**
 * 
 * @export
 * @interface ListInvestmentMasters200ResponseInvestmentMastersInnerSocials
 */
export interface ListInvestmentMasters200ResponseInvestmentMastersInnerSocials {
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInnerSocials
     */
    'twitter'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInnerSocials
     */
    'facebook'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInnerSocials
     */
    'linkedin'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentMasters200ResponseInvestmentMastersInnerSocials
     */
    'instagram'?: string;
}
/**
 * 
 * @export
 * @interface ListInvestments200Response
 */
export interface ListInvestments200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListInvestments200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListInvestments200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListInvestments200ResponseInvestmentsInner>}
     * @memberof ListInvestments200Response
     */
    'investments'?: Array<ListInvestments200ResponseInvestmentsInner>;
}
/**
 * 
 * @export
 * @interface ListInvestments200ResponseInvestmentsInner
 */
export interface ListInvestments200ResponseInvestmentsInner {
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'name'?: string;
    /**
     * 
     * @type {ListInvestments200ResponseInvestmentsInnerUser}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'user'?: ListInvestments200ResponseInvestmentsInnerUser;
    /**
     * 
     * @type {ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'account'?: ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser;
    /**
     * 
     * @type {ListInvestments200ResponseInvestmentsInnerConnection}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'connection'?: ListInvestments200ResponseInvestmentsInnerConnection;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'last_recalculate_time'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'hidden'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'incomplete'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'inactive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'inactive_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'initial_contribution'?: number;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'investment_data'?: { [key: string]: any; };
    /**
     * 
     * @type {ListInvestments200ResponseInvestmentsInnerInvestmentMaster}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'investment_master'?: ListInvestments200ResponseInvestmentsInnerInvestmentMaster;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'currency'?: ListInvestments200ResponseInvestmentsInnerCurrencyEnum;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'performance'?: { [key: string]: any; };
    /**
     * 
     * @type {number}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'valuation'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'beginning'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'tax_document_requested'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'expected_tax_documents'?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'external_ids'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'started_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'first_cashflow_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerAuditLogInner>}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'audit_log'?: Array<ListAccounts200ResponseAccountsInnerAuditLogInner>;
    /**
     * 
     * @type {boolean}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'exclude_from_billing'?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'attributes'?: { [key: string]: string; };
    /**
     * 
     * @type {ListInvestments200ResponseInvestmentsInnerMostRecentValuation}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'most_recent_valuation'?: ListInvestments200ResponseInvestmentsInnerMostRecentValuation;
    /**
     * 
     * @type {ListInvestments200ResponseInvestmentsInnerMostRecentTransaction}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'most_recent_transaction'?: ListInvestments200ResponseInvestmentsInnerMostRecentTransaction;
    /**
     * 
     * @type {Array<ListInvestments200ResponseInvestmentsInnerAuditResultsInner>}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'audit_results'?: Array<ListInvestments200ResponseInvestmentsInnerAuditResultsInner>;
}

export const ListInvestments200ResponseInvestmentsInnerCurrencyEnum = {
    Usd: 'USD',
    Eur: 'EUR',
    Cny: 'CNY',
    Jpy: 'JPY',
    Gbp: 'GBP',
    Aud: 'AUD',
    Cad: 'CAD',
    Chf: 'CHF',
    Inr: 'INR',
    Krw: 'KRW',
    Btc: 'BTC'
} as const;

export type ListInvestments200ResponseInvestmentsInnerCurrencyEnum = typeof ListInvestments200ResponseInvestmentsInnerCurrencyEnum[keyof typeof ListInvestments200ResponseInvestmentsInnerCurrencyEnum];

/**
 * 
 * @export
 * @interface ListInvestments200ResponseInvestmentsInnerAuditResultsInner
 */
export interface ListInvestments200ResponseInvestmentsInnerAuditResultsInner {
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerAuditResultsInner
     */
    'check_name'?: string;
}
/**
 * 
 * @export
 * @interface ListInvestments200ResponseInvestmentsInnerConnection
 */
export interface ListInvestments200ResponseInvestmentsInnerConnection {
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerConnection
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerConnection
     */
    'name'?: string;
    /**
     * 
     * @type {ListInvestments200ResponseInvestmentsInnerConnectionIntegration}
     * @memberof ListInvestments200ResponseInvestmentsInnerConnection
     */
    'integration'?: ListInvestments200ResponseInvestmentsInnerConnectionIntegration;
}
/**
 * 
 * @export
 * @interface ListInvestments200ResponseInvestmentsInnerConnectionIntegration
 */
export interface ListInvestments200ResponseInvestmentsInnerConnectionIntegration {
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerConnectionIntegration
     */
    'logo_url'?: string;
}
/**
 * 
 * @export
 * @interface ListInvestments200ResponseInvestmentsInnerInvestmentMaster
 */
export interface ListInvestments200ResponseInvestmentsInnerInvestmentMaster {
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerInvestmentMaster
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerInvestmentMaster
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerInvestmentMaster
     */
    'ticker'?: string;
    /**
     * 
     * @type {ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser}
     * @memberof ListInvestments200ResponseInvestmentsInnerInvestmentMaster
     */
    'asset_manager'?: ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser;
}
/**
 * 
 * @export
 * @interface ListInvestments200ResponseInvestmentsInnerMostRecentTransaction
 */
export interface ListInvestments200ResponseInvestmentsInnerMostRecentTransaction {
    /**
     * 
     * @type {number}
     * @memberof ListInvestments200ResponseInvestmentsInnerMostRecentTransaction
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerMostRecentTransaction
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerMostRecentTransaction
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerMostRecentTransaction
     */
    'document'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListInvestments200ResponseInvestmentsInnerMostRecentTransaction
     */
    'open'?: boolean;
}
/**
 * 
 * @export
 * @interface ListInvestments200ResponseInvestmentsInnerMostRecentValuation
 */
export interface ListInvestments200ResponseInvestmentsInnerMostRecentValuation {
    /**
     * 
     * @type {number}
     * @memberof ListInvestments200ResponseInvestmentsInnerMostRecentValuation
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerMostRecentValuation
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface ListInvestments200ResponseInvestmentsInnerUser
 */
export interface ListInvestments200ResponseInvestmentsInnerUser {
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerUser
     */
    'email'?: string;
    /**
     * 
     * @type {Array<ListInvestments200ResponseInvestmentsInnerUserAdvisorsInner>}
     * @memberof ListInvestments200ResponseInvestmentsInnerUser
     */
    'advisors'?: Array<ListInvestments200ResponseInvestmentsInnerUserAdvisorsInner>;
}
/**
 * 
 * @export
 * @interface ListInvestments200ResponseInvestmentsInnerUserAdvisorsInner
 */
export interface ListInvestments200ResponseInvestmentsInnerUserAdvisorsInner {
    /**
     * 
     * @type {ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser}
     * @memberof ListInvestments200ResponseInvestmentsInnerUserAdvisorsInner
     */
    'group'?: ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser;
}
/**
 * 
 * @export
 * @interface ListInvestmentsByInvestmentMaster200Response
 */
export interface ListInvestmentsByInvestmentMaster200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListInvestmentsByInvestmentMaster200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListInvestmentsByInvestmentMaster200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListInvestmentsByInvestmentMaster200ResponseResultsInner>}
     * @memberof ListInvestmentsByInvestmentMaster200Response
     */
    'results'?: Array<ListInvestmentsByInvestmentMaster200ResponseResultsInner>;
}
/**
 * 
 * @export
 * @interface ListInvestmentsByInvestmentMaster200ResponseResultsInner
 */
export interface ListInvestmentsByInvestmentMaster200ResponseResultsInner {
    /**
     * 
     * @type {ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentMaster}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInner
     */
    'investment_master'?: ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentMaster;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInner
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInner
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInner
     */
    'totalInvested'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInner
     */
    'totalCommitted'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInner
     */
    'totalFees'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInner
     */
    'totalValuation'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInner
     */
    'totalGain'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInner
     */
    'activeCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInner
     */
    'averageInvested'?: number;
    /**
     * 
     * @type {Array<ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentSummariesInner>}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInner
     */
    'investmentSummaries'?: Array<ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentSummariesInner>;
}
/**
 * 
 * @export
 * @interface ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentMaster
 */
export interface ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentMaster {
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentMaster
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentMaster
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentMaster
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentMaster
     */
    'ticker'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentMaster
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentMaster
     */
    'exchange'?: string;
}
/**
 * 
 * @export
 * @interface ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentSummariesInner
 */
export interface ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentSummariesInner {
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentSummariesInner
     */
    'investmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentSummariesInner
     */
    'investmentType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentSummariesInner
     */
    'investmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentSummariesInner
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentSummariesInner
     */
    'accountName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentSummariesInner
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentSummariesInner
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentSummariesInner
     */
    'userEmail'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentSummariesInner
     */
    'committed'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentSummariesInner
     */
    'invested'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentSummariesInner
     */
    'fees'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentSummariesInner
     */
    'valuation'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentSummariesInner
     */
    'gain'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentSummariesInner
     */
    'irr'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentSummariesInner
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListInvestmentsByInvestmentMaster200ResponseResultsInnerInvestmentSummariesInner
     */
    'currency'?: string;
}
/**
 * @type ListInvestmentsInvestmentMastersParameter
 * @export
 */
export type ListInvestmentsInvestmentMastersParameter = Array<string> | boolean;

/**
 * 
 * @export
 * @interface ListInvitations200Response
 */
export interface ListInvitations200Response {
    /**
     * 
     * @type {any}
     * @memberof ListInvitations200Response
     */
    'success'?: any;
    /**
     * 
     * @type {number}
     * @memberof ListInvitations200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListInvitations200ResponseInvitationsInner>}
     * @memberof ListInvitations200Response
     */
    'invitations'?: Array<ListInvitations200ResponseInvitationsInner>;
}
/**
 * 
 * @export
 * @interface ListInvitations200ResponseInvitationsInner
 */
export interface ListInvitations200ResponseInvitationsInner {
    /**
     * 
     * @type {string}
     * @memberof ListInvitations200ResponseInvitationsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvitations200ResponseInvitationsInner
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvitations200ResponseInvitationsInner
     */
    'tokenExpiration'?: string;
    /**
     * 
     * @type {ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser}
     * @memberof ListInvitations200ResponseInvitationsInner
     */
    'group'?: ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultCompletedBy}
     * @memberof ListInvitations200ResponseInvitationsInner
     */
    'sender'?: GetAuditResult200ResponseAuditResultCompletedBy;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultCompletedBy}
     * @memberof ListInvitations200ResponseInvitationsInner
     */
    'receiver'?: GetAuditResult200ResponseAuditResultCompletedBy;
    /**
     * 
     * @type {boolean}
     * @memberof ListInvitations200ResponseInvitationsInner
     */
    'read'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListInvitations200ResponseInvitationsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvitations200ResponseInvitationsInner
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface ListMessages200Response
 */
export interface ListMessages200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListMessages200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListMessages200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListMessages200ResponseMessagesInner>}
     * @memberof ListMessages200Response
     */
    'messages'?: Array<ListMessages200ResponseMessagesInner>;
}
/**
 * 
 * @export
 * @interface ListMessages200ResponseMessagesInner
 */
export interface ListMessages200ResponseMessagesInner {
    /**
     * 
     * @type {string}
     * @memberof ListMessages200ResponseMessagesInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListMessages200ResponseMessagesInner
     */
    'type'?: string;
    /**
     * 
     * @type {Array<GetMessageById200ResponseMessageConnectionsInner>}
     * @memberof ListMessages200ResponseMessagesInner
     */
    'connections'?: Array<GetMessageById200ResponseMessageConnectionsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListMessages200ResponseMessagesInner
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListMessages200ResponseMessagesInner
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListMessages200ResponseMessagesInner
     */
    'to'?: string;
    /**
     * 
     * @type {ListInvestments200ResponseInvestmentsInnerUser}
     * @memberof ListMessages200ResponseMessagesInner
     */
    'user'?: ListInvestments200ResponseInvestmentsInnerUser;
    /**
     * 
     * @type {string}
     * @memberof ListMessages200ResponseMessagesInner
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListMessages200ResponseMessagesInner
     */
    'body'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListMessages200ResponseMessagesInner
     */
    'parsed'?: boolean;
    /**
     * 
     * @type {Array<GetMessageById200ResponseMessageAttachmentsInner>}
     * @memberof ListMessages200ResponseMessagesInner
     */
    'attachments'?: Array<GetMessageById200ResponseMessageAttachmentsInner>;
    /**
     * 
     * @type {ListMessages200ResponseMessagesInnerEmailData}
     * @memberof ListMessages200ResponseMessagesInner
     */
    'email_data'?: ListMessages200ResponseMessagesInnerEmailData;
    /**
     * 
     * @type {GetMessageById200ResponseMessageMetadata}
     * @memberof ListMessages200ResponseMessagesInner
     */
    'metadata'?: GetMessageById200ResponseMessageMetadata;
    /**
     * 
     * @type {boolean}
     * @memberof ListMessages200ResponseMessagesInner
     */
    'completed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListMessages200ResponseMessagesInner
     */
    'category'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListMessages200ResponseMessagesInner
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ListMessages200ResponseMessagesInner
     */
    'connection_expects_documents'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListMessages200ResponseMessagesInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListMessages200ResponseMessagesInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListMessages200ResponseMessagesInner
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerAuditLogInner>}
     * @memberof ListMessages200ResponseMessagesInner
     */
    'audit_log'?: Array<ListAccounts200ResponseAccountsInnerAuditLogInner>;
}
/**
 * 
 * @export
 * @interface ListMessages200ResponseMessagesInnerEmailData
 */
export interface ListMessages200ResponseMessagesInnerEmailData {
    [key: string]: any;

    /**
     * 
     * @type {any}
     * @memberof ListMessages200ResponseMessagesInnerEmailData
     */
    'cc'?: any;
    /**
     * 
     * @type {any}
     * @memberof ListMessages200ResponseMessagesInnerEmailData
     */
    'raw_from'?: any;
    /**
     * 
     * @type {any}
     * @memberof ListMessages200ResponseMessagesInnerEmailData
     */
    'raw_to'?: any;
}
/**
 * @type ListMessagesExcludeIntegrationsParameterInner
 * @export
 */
export type ListMessagesExcludeIntegrationsParameterInner = string;

/**
 * @type ListMessagesIntegrationsParameterInner
 * @export
 */
export type ListMessagesIntegrationsParameterInner = string;

/**
 * 
 * @export
 * @interface ListNotes200Response
 */
export interface ListNotes200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListNotes200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListNotes200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListNotes200ResponseNotesInner>}
     * @memberof ListNotes200Response
     */
    'notes'?: Array<ListNotes200ResponseNotesInner>;
}
/**
 * 
 * @export
 * @interface ListNotes200ResponseNotesInner
 */
export interface ListNotes200ResponseNotesInner {
    /**
     * 
     * @type {string}
     * @memberof ListNotes200ResponseNotesInner
     */
    '_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListNotes200ResponseNotesInner
     */
    'type'?: ListNotes200ResponseNotesInnerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ListNotes200ResponseNotesInner
     */
    'body'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListNotes200ResponseNotesInner
     */
    'creator'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListNotes200ResponseNotesInner
     */
    'editor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListNotes200ResponseNotesInner
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListNotes200ResponseNotesInner
     */
    'account'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListNotes200ResponseNotesInner
     */
    'investment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListNotes200ResponseNotesInner
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListNotes200ResponseNotesInner
     */
    'transaction'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListNotes200ResponseNotesInner
     */
    'admin_audit_result'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListNotes200ResponseNotesInner
     */
    'admin_asset_manager'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListNotes200ResponseNotesInner
     */
    'admin_investment_master'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListNotes200ResponseNotesInner
     */
    'admin_investment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListNotes200ResponseNotesInner
     */
    'admin_document_type'?: string;
}

export const ListNotes200ResponseNotesInnerTypeEnum = {
    Transaction: 'transaction',
    Investment: 'investment',
    Account: 'account',
    User: 'user',
    Document: 'document',
    AuditResult: 'auditResult',
    AssetManager: 'assetManager',
    InvestmentMaster: 'investmentMaster',
    DocumentType: 'documentType',
    AdminInvestment: 'adminInvestment',
    AuditResult2: 'audit_result',
    AssetManager2: 'asset_manager',
    InvestmentMaster2: 'investment_master',
    DocumentType2: 'document_type',
    AdminAuditResult: 'admin_audit_result',
    AdminAssetManager: 'admin_asset_manager',
    AdminInvestmentMaster: 'admin_investment_master',
    AdminDocumentType: 'admin_document_type',
    AdminInvestment2: 'admin_investment'
} as const;

export type ListNotes200ResponseNotesInnerTypeEnum = typeof ListNotes200ResponseNotesInnerTypeEnum[keyof typeof ListNotes200ResponseNotesInnerTypeEnum];

/**
 * 
 * @export
 * @interface ListRALRequestTemplates200Response
 */
export interface ListRALRequestTemplates200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListRALRequestTemplates200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<ListRALRequestTemplates200ResponseTemplatesInner>}
     * @memberof ListRALRequestTemplates200Response
     */
    'templates'?: Array<ListRALRequestTemplates200ResponseTemplatesInner>;
}
/**
 * 
 * @export
 * @interface ListRALRequestTemplates200ResponseTemplatesInner
 */
export interface ListRALRequestTemplates200ResponseTemplatesInner {
    /**
     * 
     * @type {string}
     * @memberof ListRALRequestTemplates200ResponseTemplatesInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequestTemplates200ResponseTemplatesInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequestTemplates200ResponseTemplatesInner
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequestTemplates200ResponseTemplatesInner
     */
    'remote_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListRALRequestTemplates200ResponseTemplatesInner
     */
    'default'?: boolean;
    /**
     * 
     * @type {Array<ListRALRequestTemplates200ResponseTemplatesInnerRequiredSignatoriesInner>}
     * @memberof ListRALRequestTemplates200ResponseTemplatesInner
     */
    'required_signatories'?: Array<ListRALRequestTemplates200ResponseTemplatesInnerRequiredSignatoriesInner>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListRALRequestTemplates200ResponseTemplatesInner
     */
    'required_tokens'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListRALRequestTemplates200ResponseTemplatesInnerRequiredSignatoriesInner
 */
export interface ListRALRequestTemplates200ResponseTemplatesInnerRequiredSignatoriesInner {
    /**
     * 
     * @type {string}
     * @memberof ListRALRequestTemplates200ResponseTemplatesInnerRequiredSignatoriesInner
     */
    'role'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListRALRequestTemplates200ResponseTemplatesInnerRequiredSignatoriesInner
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface ListRALRequests200Response
 */
export interface ListRALRequests200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListRALRequests200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListRALRequests200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListRALRequests200ResponseRalRequestsInner>}
     * @memberof ListRALRequests200Response
     */
    'ral_requests'?: Array<ListRALRequests200ResponseRalRequestsInner>;
}
/**
 * 
 * @export
 * @interface ListRALRequests200ResponseRalRequestsInner
 */
export interface ListRALRequests200ResponseRalRequestsInner {
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    '_id'?: string | null;
    /**
     * 
     * @type {ListRALRequests200ResponseRalRequestsInnerUser}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'user'?: ListRALRequests200ResponseRalRequestsInnerUser;
    /**
     * 
     * @type {Array<GetDocument200ResponseDocumentOwnershipIdsInner>}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'ownership_ids'?: Array<GetDocument200ResponseDocumentOwnershipIdsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'ownership_type'?: string;
    /**
     * 
     * @type {ListRALRequests200ResponseRalRequestsInnerSignedRal}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'signed_ral'?: ListRALRequests200ResponseRalRequestsInnerSignedRal;
    /**
     * 
     * @type {Array<ListRALRequests200ResponseRalRequestsInnerSignatoriesInner>}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'signatories'?: Array<ListRALRequests200ResponseRalRequestsInnerSignatoriesInner>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'tokens'?: { [key: string]: string; };
    /**
     * 
     * @type {ListRALRequests200ResponseRalRequestsInnerRalRequestTemplate}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'ral_request_template'?: ListRALRequests200ResponseRalRequestsInnerRalRequestTemplate;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'remote_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'remote_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'remote_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'sent_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'signed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListRALRequests200ResponseRalRequestsInnerRalRequestTemplate
 */
export interface ListRALRequests200ResponseRalRequestsInnerRalRequestTemplate {
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerRalRequestTemplate
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerRalRequestTemplate
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerRalRequestTemplate
     */
    'remote_type'?: string;
}
/**
 * 
 * @export
 * @interface ListRALRequests200ResponseRalRequestsInnerSignatoriesInner
 */
export interface ListRALRequests200ResponseRalRequestsInnerSignatoriesInner {
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerSignatoriesInner
     */
    '_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerSignatoriesInner
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerSignatoriesInner
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerSignatoriesInner
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerSignatoriesInner
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface ListRALRequests200ResponseRalRequestsInnerSignedRal
 */
export interface ListRALRequests200ResponseRalRequestsInnerSignedRal {
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerSignedRal
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerSignedRal
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerSignedRal
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface ListRALRequests200ResponseRalRequestsInnerUser
 */
export interface ListRALRequests200ResponseRalRequestsInnerUser {
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerUser
     */
    'email'?: string;
    /**
     * 
     * @type {Array<ListRALRequests200ResponseRalRequestsInnerUserAdvisorsInner>}
     * @memberof ListRALRequests200ResponseRalRequestsInnerUser
     */
    'advisors'?: Array<ListRALRequests200ResponseRalRequestsInnerUserAdvisorsInner>;
}
/**
 * 
 * @export
 * @interface ListRALRequests200ResponseRalRequestsInnerUserAdvisorsInner
 */
export interface ListRALRequests200ResponseRalRequestsInnerUserAdvisorsInner {
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerUserAdvisorsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser}
     * @memberof ListRALRequests200ResponseRalRequestsInnerUserAdvisorsInner
     */
    'group'?: ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser;
}
/**
 * 
 * @export
 * @interface ListTransactions200Response
 */
export interface ListTransactions200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListTransactions200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListTransactions200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListTransactions200ResponseTransactionsInner>}
     * @memberof ListTransactions200Response
     */
    'transactions'?: Array<ListTransactions200ResponseTransactionsInner>;
}
/**
 * 
 * @export
 * @interface ListTransactions200ResponseTransactionsInner
 */
export interface ListTransactions200ResponseTransactionsInner {
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    '_id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'sample_data'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'type'?: string;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'user'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'account'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {ListCommitments200ResponseCommitmentsInnerInvestment}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'investment'?: ListCommitments200ResponseCommitmentsInnerInvestment;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'document'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'connection'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {boolean}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'auto_extracted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'units'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'open'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'linked_transaction'?: string;
    /**
     * 
     * @type {ListTransactions200ResponseTransactionsInnerJointTransaction}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'joint_transaction'?: ListTransactions200ResponseTransactionsInnerJointTransaction;
    /**
     * 
     * @type {Array<ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner>}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'joint_transactions'?: Array<ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerAuditLogInner>}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'audit_log'?: Array<ListAccounts200ResponseAccountsInnerAuditLogInner>;
}
/**
 * 
 * @export
 * @interface ListTransactions200ResponseTransactionsInnerJointTransaction
 */
export interface ListTransactions200ResponseTransactionsInnerJointTransaction {
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInnerJointTransaction
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListTransactions200ResponseTransactionsInnerJointTransaction
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInnerJointTransaction
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInnerJointTransaction
     */
    'date'?: string;
    /**
     * 
     * @type {Array<ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner>}
     * @memberof ListTransactions200ResponseTransactionsInnerJointTransaction
     */
    'joint_transactions'?: Array<ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner>;
}
/**
 * 
 * @export
 * @interface ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner
 */
export interface ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner {
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface ListUsers200Response
 */
export interface ListUsers200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListUsers200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInner>}
     * @memberof ListUsers200Response
     */
    'users'?: Array<ListUsers200ResponseUsersInner>;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInner
 */
export interface ListUsers200ResponseUsersInner {
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInner
     */
    '_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200ResponseUsersInner
     */
    'has_password'?: boolean;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerInvestmentsInner>}
     * @memberof ListUsers200ResponseUsersInner
     */
    'investments'?: Array<ListUsers200ResponseUsersInnerInvestmentsInner>;
    /**
     * 
     * @type {number}
     * @memberof ListUsers200ResponseUsersInner
     */
    'investments_count'?: number;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerAccountsInner>}
     * @memberof ListUsers200ResponseUsersInner
     */
    'accounts'?: Array<ListUsers200ResponseUsersInnerAccountsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInner
     */
    'email'?: string;
    /**
     * 
     * @type {ListUsers200ResponseUsersInnerEmailVerification}
     * @memberof ListUsers200ResponseUsersInner
     */
    'email_verification'?: ListUsers200ResponseUsersInnerEmailVerification;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInner
     */
    'access'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInner
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInner
     */
    'status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200ResponseUsersInner
     */
    'is_deactivated'?: boolean;
    /**
     * 
     * @type {ListUsers200ResponseUsersInnerTfa}
     * @memberof ListUsers200ResponseUsersInner
     */
    'tfa'?: ListUsers200ResponseUsersInnerTfa;
    /**
     * 
     * @type {ListUsers200ResponseUsersInnerNotificationSettings}
     * @memberof ListUsers200ResponseUsersInner
     */
    'notificationSettings'?: ListUsers200ResponseUsersInnerNotificationSettings;
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200ResponseUsersInner
     */
    'sample_data'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListUsers200ResponseUsersInner
     */
    'failed_attempts'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListUsers200ResponseUsersInner
     */
    'setup_email_sent_times'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ListUsers200ResponseUsersInner
     */
    'external_ids'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInner
     */
    'last_login_at'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListUsers200ResponseUsersInner
     */
    'setup_email_send_times'?: Array<string>;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInner>}
     * @memberof ListUsers200ResponseUsersInner
     */
    'advisor_group_memberships'?: Array<ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInner>;
    /**
     * 
     * @type {any}
     * @memberof ListUsers200ResponseUsersInner
     */
    'sessions'?: any;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerNotificationsInner>}
     * @memberof ListUsers200ResponseUsersInner
     */
    'notifications'?: Array<ListUsers200ResponseUsersInnerNotificationsInner>;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerAdvisorsInner>}
     * @memberof ListUsers200ResponseUsersInner
     */
    'advisors'?: Array<ListUsers200ResponseUsersInnerAdvisorsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInner
     */
    'outreach_status'?: string;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerAccountsInner
 */
export interface ListUsers200ResponseUsersInnerAccountsInner {
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAccountsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAccountsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAccountsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAccountsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAccountsInner
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAccountsInner
     */
    'account_type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListUsers200ResponseUsersInnerAccountsInner
     */
    'investments_count'?: number;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInner
 */
export interface ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInner {
    /**
     * 
     * @type {ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerGroup}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInner
     */
    'group'?: ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerGroup;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInner
     */
    'role'?: string;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerClientsInner>}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInner
     */
    'clients'?: Array<ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerClientsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInner
     */
    'invited_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInner
     */
    'accepted_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInner
     */
    '_id'?: string;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerClientsInner
 */
export interface ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerClientsInner {
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerClientsInner
     */
    '_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerClientsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerClientsInner
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerClientsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerClientsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerClientsInner
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerGroup
 */
export interface ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerGroup {
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerGroup
     */
    '_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerGroup
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerGroup
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerGroup
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerGroup
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerAdvisorsInner
 */
export interface ListUsers200ResponseUsersInnerAdvisorsInner {
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorsInner
     */
    'role'?: string;
    /**
     * 
     * @type {ListUsers200ResponseUsersInnerAdvisorsInnerGroup}
     * @memberof ListUsers200ResponseUsersInnerAdvisorsInner
     */
    'group'?: ListUsers200ResponseUsersInnerAdvisorsInnerGroup;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorsInner
     */
    'invited_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorsInner
     */
    'accepted_on'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListUsers200ResponseUsersInnerAdvisorsInner
     */
    'permissions'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorsInner
     */
    '_id'?: string;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerAdvisorsInnerGroup
 */
export interface ListUsers200ResponseUsersInnerAdvisorsInnerGroup {
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorsInnerGroup
     */
    '_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorsInnerGroup
     */
    'name'?: string;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerAdvisorsInnerGroupExtegrationsInner>}
     * @memberof ListUsers200ResponseUsersInnerAdvisorsInnerGroup
     */
    'extegrations'?: Array<ListUsers200ResponseUsersInnerAdvisorsInnerGroupExtegrationsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorsInnerGroup
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorsInnerGroup
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorsInnerGroup
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerAdvisorsInnerGroupExtegrationsInner
 */
export interface ListUsers200ResponseUsersInnerAdvisorsInnerGroupExtegrationsInner {
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorsInnerGroupExtegrationsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorsInnerGroupExtegrationsInner
     */
    'extegration_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorsInnerGroupExtegrationsInner
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerEmailVerification
 */
export interface ListUsers200ResponseUsersInnerEmailVerification {
    /**
     * 
     * @type {Array<string>}
     * @memberof ListUsers200ResponseUsersInnerEmailVerification
     */
    'send_times'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200ResponseUsersInnerEmailVerification
     */
    'verified'?: boolean;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerInvestmentsInner
 */
export interface ListUsers200ResponseUsersInnerInvestmentsInner {
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerInvestmentsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerInvestmentsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerInvestmentsInner
     */
    'account'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerInvestmentsInner
     */
    'connection'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerInvestmentsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerInvestmentsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerInvestmentsInner
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerNotificationSettings
 */
export interface ListUsers200ResponseUsersInnerNotificationSettings {
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200ResponseUsersInnerNotificationSettings
     */
    'Master Enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200ResponseUsersInnerNotificationSettings
     */
    'CapitalCall'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200ResponseUsersInnerNotificationSettings
     */
    'Distribution'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200ResponseUsersInnerNotificationSettings
     */
    'TaxDocument'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200ResponseUsersInnerNotificationSettings
     */
    'ConnectionActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200ResponseUsersInnerNotificationSettings
     */
    'ConnectionError'?: boolean;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerNotificationsInner
 */
export interface ListUsers200ResponseUsersInnerNotificationsInner {
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerNotificationsInner
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerNotificationsInner
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerNotificationsInner
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerNotificationsInner
     */
    'notificationType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerNotificationsInner
     */
    '_id'?: string;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerTfa
 */
export interface ListUsers200ResponseUsersInnerTfa {
    /**
     * 
     * @type {ListUsers200ResponseUsersInnerTfaTotp}
     * @memberof ListUsers200ResponseUsersInnerTfa
     */
    'totp'?: ListUsers200ResponseUsersInnerTfaTotp;
    /**
     * 
     * @type {ListUsers200ResponseUsersInnerTfaTotp}
     * @memberof ListUsers200ResponseUsersInnerTfa
     */
    'sms'?: ListUsers200ResponseUsersInnerTfaTotp;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerTfaRecoveryCodesInner>}
     * @memberof ListUsers200ResponseUsersInnerTfa
     */
    'recovery_codes'?: Array<ListUsers200ResponseUsersInnerTfaRecoveryCodesInner>;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerTfaRecoveryCodesInner
 */
export interface ListUsers200ResponseUsersInnerTfaRecoveryCodesInner {
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerTfaRecoveryCodesInner
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerTfaTotp
 */
export interface ListUsers200ResponseUsersInnerTfaTotp {
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200ResponseUsersInnerTfaTotp
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface ListValuations200Response
 */
export interface ListValuations200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListValuations200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListValuations200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListValuations200ResponseValuationsInner>}
     * @memberof ListValuations200Response
     */
    'valuations'?: Array<ListValuations200ResponseValuationsInner>;
}
/**
 * 
 * @export
 * @interface ListValuations200ResponseValuationsInner
 */
export interface ListValuations200ResponseValuationsInner {
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInner
     */
    '_id'?: string | null;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerUser}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'user'?: ListDocuments200ResponseDocumentsInnerUser;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'account'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {ListCommitments200ResponseCommitmentsInnerInvestment}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'investment'?: ListCommitments200ResponseCommitmentsInnerInvestment;
    /**
     * 
     * @type {GetAuditResult200ResponseAuditResultBreadcrumbsAccount}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'connection'?: GetAuditResult200ResponseAuditResultBreadcrumbsAccount;
    /**
     * 
     * @type {Array<ListValuations200ResponseValuationsInnerDocumentsInner>}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'documents'?: Array<ListValuations200ResponseValuationsInnerDocumentsInner>;
    /**
     * 
     * @type {boolean}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'auto_extracted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'unit_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerAuditLogInner>}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'audit_log'?: Array<ListAccounts200ResponseAccountsInnerAuditLogInner>;
}
/**
 * 
 * @export
 * @interface ListValuations200ResponseValuationsInnerDocumentsInner
 */
export interface ListValuations200ResponseValuationsInnerDocumentsInner {
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInnerDocumentsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInnerDocumentsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInnerDocumentsInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInnerDocumentsInner
     */
    'user'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInnerDocumentsInner
     */
    'ownership_type'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListValuations200ResponseValuationsInnerDocumentsInner
     */
    'ownership_ids'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInnerDocumentsInner
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'tfaMethod'?: LoginRequestTfaMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'tfaCode'?: string;
}

export const LoginRequestTfaMethodEnum = {
    Totp: 'totp',
    Sms: 'sms',
    Recovery: 'recovery'
} as const;

export type LoginRequestTfaMethodEnum = typeof LoginRequestTfaMethodEnum[keyof typeof LoginRequestTfaMethodEnum];

/**
 * 
 * @export
 * @interface LoginUserWithGoogle200Response
 */
export interface LoginUserWithGoogle200Response {
    /**
     * 
     * @type {boolean}
     * @memberof LoginUserWithGoogle200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoginUserWithGoogle200Response
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface LoginUserWithGoogleRequest
 */
export interface LoginUserWithGoogleRequest {
    /**
     * A JWT credential from the Google Identity API
     * @type {string}
     * @memberof LoginUserWithGoogleRequest
     */
    'credential': string;
}
/**
 * 
 * @export
 * @interface LogoutRequest
 */
export interface LogoutRequest {
    [key: string]: any;

    /**
     * 
     * @type {boolean}
     * @memberof LogoutRequest
     */
    'all'?: boolean;
}
/**
 * Message categories.
 * @export
 * @enum {string}
 */

export const MessageCategory = {
    Tfa: 'tfa',
    Portal: 'portal',
    Deactivated: 'deactivated'
} as const;

export type MessageCategory = typeof MessageCategory[keyof typeof MessageCategory];


/**
 * Message types.
 * @export
 * @enum {string}
 */

export const MessageType = {
    Email: 'email',
    Sms: 'sms',
    Manual: 'manual'
} as const;

export type MessageType = typeof MessageType[keyof typeof MessageType];


/**
 * 
 * @export
 * @enum {string}
 */

export const NoteType = {
    Transaction: 'transaction',
    Investment: 'investment',
    Account: 'account',
    User: 'user',
    Document: 'document',
    AuditResult: 'auditResult',
    AssetManager: 'assetManager',
    InvestmentMaster: 'investmentMaster',
    DocumentType: 'documentType',
    AdminInvestment: 'adminInvestment',
    AuditResult2: 'audit_result',
    AssetManager2: 'asset_manager',
    InvestmentMaster2: 'investment_master',
    DocumentType2: 'document_type',
    AdminAuditResult: 'admin_audit_result',
    AdminAssetManager: 'admin_asset_manager',
    AdminInvestmentMaster: 'admin_investment_master',
    AdminDocumentType: 'admin_document_type',
    AdminInvestment2: 'admin_investment'
} as const;

export type NoteType = typeof NoteType[keyof typeof NoteType];


/**
 * Notification send options.
 * @export
 * @enum {string}
 */

export const NotificationSendOptions = {
    Email: 'email',
    Calendar: 'calendar'
} as const;

export type NotificationSendOptions = typeof NotificationSendOptions[keyof typeof NotificationSendOptions];


/**
 * Notification types.
 * @export
 * @enum {string}
 */

export const NotificationTypes = {
    CapitalCall: 'Capital Call',
    Distribution: 'Distribution',
    TaxDocument: 'Tax Document',
    ConnectionActive: 'Connection Active',
    ConnectionError: 'Connection Error'
} as const;

export type NotificationTypes = typeof NotificationTypes[keyof typeof NotificationTypes];


/**
 * User permissions.
 * @export
 * @enum {string}
 */

export const Permissions = {
    User: 'user',
    Partner: 'partner',
    Admin: 'admin',
    Advisor: 'advisor',
    Internal: 'internal',
    Extegration: 'extegration',
    Investor: 'investor',
    Sync: 'sync',
    None: 'none'
} as const;

export type Permissions = typeof Permissions[keyof typeof Permissions];


/**
 * RAL PandaDoc statuses.
 * @export
 * @enum {string}
 */

export const RALCommonStatus = {
    Unstarted: 'unstarted',
    Draft: 'draft',
    Sent: 'sent',
    Viewed: 'viewed',
    Completed: 'completed',
    Voided: 'voided',
    Declined: 'declined'
} as const;

export type RALCommonStatus = typeof RALCommonStatus[keyof typeof RALCommonStatus];


/**
 * 
 * @export
 * @interface ReceiveDocusignHookRequest
 */
export interface ReceiveDocusignHookRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequest
     */
    'event'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequest
     */
    'uri'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequest
     */
    'retryCount'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequest
     */
    'configurationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequest
     */
    'apiVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequest
     */
    'generatedDateTime'?: string;
    /**
     * 
     * @type {ReceiveDocusignHookRequestData}
     * @memberof ReceiveDocusignHookRequest
     */
    'data'?: ReceiveDocusignHookRequestData;
}
/**
 * 
 * @export
 * @interface ReceiveDocusignHookRequestData
 */
export interface ReceiveDocusignHookRequestData {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestData
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestData
     */
    'recipientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestData
     */
    'envelopeId'?: string;
    /**
     * 
     * @type {ReceiveDocusignHookRequestDataEnvelopeSummary}
     * @memberof ReceiveDocusignHookRequestData
     */
    'envelopeSummary'?: ReceiveDocusignHookRequestDataEnvelopeSummary;
}
/**
 * 
 * @export
 * @interface ReceiveDocusignHookRequestDataEnvelopeSummary
 */
export interface ReceiveDocusignHookRequestDataEnvelopeSummary {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummary
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummary
     */
    'emailSubject'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummary
     */
    'emailBlurb'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummary
     */
    'signingLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummary
     */
    'enableWetSign'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummary
     */
    'allowMarkup'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummary
     */
    'allowReassign'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummary
     */
    'createdDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummary
     */
    'lastModifiedDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummary
     */
    'statusChangedDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummary
     */
    'useDisclosure'?: string;
    /**
     * 
     * @type {ReceiveDocusignHookRequestDataEnvelopeSummarySender}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummary
     */
    'sender'?: ReceiveDocusignHookRequestDataEnvelopeSummarySender;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummary
     */
    'recipients'?: { [key: string]: any; };
    /**
     * 
     * @type {Array<ReceiveDocusignHookRequestDataEnvelopeSummaryEnvelopeDocumentsInner>}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummary
     */
    'envelopeDocuments'?: Array<ReceiveDocusignHookRequestDataEnvelopeSummaryEnvelopeDocumentsInner>;
    /**
     * 
     * @type {any}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummary
     */
    'additionalProperties'?: any;
}
/**
 * 
 * @export
 * @interface ReceiveDocusignHookRequestDataEnvelopeSummaryEnvelopeDocumentsInner
 */
export interface ReceiveDocusignHookRequestDataEnvelopeSummaryEnvelopeDocumentsInner {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummaryEnvelopeDocumentsInner
     */
    'documentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummaryEnvelopeDocumentsInner
     */
    'documentIdGuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummaryEnvelopeDocumentsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummaryEnvelopeDocumentsInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummaryEnvelopeDocumentsInner
     */
    'order'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummaryEnvelopeDocumentsInner
     */
    'display'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummaryEnvelopeDocumentsInner
     */
    'includeInDownload'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummaryEnvelopeDocumentsInner
     */
    'signerMustAcknowledge'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummaryEnvelopeDocumentsInner
     */
    'templateRequired'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummaryEnvelopeDocumentsInner
     */
    'authoritative'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummaryEnvelopeDocumentsInner
     */
    'PDFBytes'?: string;
}
/**
 * 
 * @export
 * @interface ReceiveDocusignHookRequestDataEnvelopeSummarySender
 */
export interface ReceiveDocusignHookRequestDataEnvelopeSummarySender {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummarySender
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummarySender
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummarySender
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDocusignHookRequestDataEnvelopeSummarySender
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface ReceiveTextVerifiedHookRequest
 */
export interface ReceiveTextVerifiedHookRequest {
    [key: string]: any;

    /**
     * 
     * @type {any}
     * @memberof ReceiveTextVerifiedHookRequest
     */
    'Attempt'?: any;
    /**
     * 
     * @type {string}
     * @memberof ReceiveTextVerifiedHookRequest
     */
    'OccurredAt'?: string;
    /**
     * 
     * @type {ReceiveTextVerifiedHookRequestData}
     * @memberof ReceiveTextVerifiedHookRequest
     */
    'Data'?: ReceiveTextVerifiedHookRequestData;
    /**
     * 
     * @type {string}
     * @memberof ReceiveTextVerifiedHookRequest
     */
    'Event'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveTextVerifiedHookRequest
     */
    'Id'?: string;
}
/**
 * 
 * @export
 * @interface ReceiveTextVerifiedHookRequestData
 */
export interface ReceiveTextVerifiedHookRequestData {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ReceiveTextVerifiedHookRequestData
     */
    'From'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceiveTextVerifiedHookRequestData
     */
    'To'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveTextVerifiedHookRequestData
     */
    'CreatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveTextVerifiedHookRequestData
     */
    'SmsContent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceiveTextVerifiedHookRequestData
     */
    'ParsedCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReceiveTextVerifiedHookRequestData
     */
    'Encrypted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReceiveTextVerifiedHookRequestData
     */
    'ReservationId'?: string | null;
}
/**
 * 
 * @export
 * @interface ReprocessMetadata200Response
 */
export interface ReprocessMetadata200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ReprocessMetadata200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReprocessMetadata200Response
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReprocessMetadata200Response
     */
    'metadata_error'?: string;
}
/**
 * 
 * @export
 * @interface ResendVerifyRequest
 */
export interface ResendVerifyRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ResendVerifyRequest
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResendVerifyRequest
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface ResetRequest
 */
export interface ResetRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ResetRequest
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetRequest
     */
    'verify'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetRequest
     */
    'password'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResetRequest
     */
    'isFirstSetup'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResetRequest
     */
    'remember'?: boolean;
}
/**
 * 
 * @export
 * @interface ResolveAuditResult200Response
 */
export interface ResolveAuditResult200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ResolveAuditResult200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResolveAuditResult200Response
     */
    'message'?: string;
}
/**
 * Resource methods.
 * @export
 * @enum {string}
 */

export const ResourceMethods = {
    Portal: 'portal',
    Link: 'link',
    Api: 'api',
    Auto: 'auto',
    None: 'none',
    Unknown: 'unknown'
} as const;

export type ResourceMethods = typeof ResourceMethods[keyof typeof ResourceMethods];


/**
 * 
 * @export
 * @interface RunSlaIssues200Response
 */
export interface RunSlaIssues200Response {
    /**
     * 
     * @type {string}
     * @memberof RunSlaIssues200Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface RunSlaIssuesRequest
 */
export interface RunSlaIssuesRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof RunSlaIssuesRequest
     */
    'user_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RunSlaIssuesRequest
     */
    'investment_id'?: string | null;
}
/**
 * 
 * @export
 * @interface SendNotification200Response
 */
export interface SendNotification200Response {
    /**
     * 
     * @type {boolean}
     * @memberof SendNotification200Response
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof SendNotification200Response
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface SendNotificationRequest
 */
export interface SendNotificationRequest {
    /**
     * 
     * @type {string}
     * @memberof SendNotificationRequest
     */
    'subject': SendNotificationRequestSubjectEnum;
    /**
     * 
     * @type {string}
     * @memberof SendNotificationRequest
     */
    'type': SendNotificationRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SendNotificationRequest
     */
    'receiver': string | null;
    /**
     * 
     * @type {string}
     * @memberof SendNotificationRequest
     */
    'transaction'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SendNotificationRequest
     */
    'investment'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SendNotificationRequest
     */
    'force_send'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SendNotificationRequest
     */
    'connection'?: string | null;
}

export const SendNotificationRequestSubjectEnum = {
    CapitalCall: 'Capital Call',
    Distribution: 'Distribution',
    TaxDocument: 'Tax Document',
    ConnectionActive: 'Connection Active',
    ConnectionError: 'Connection Error',
    JointTransaction: 'joint_transaction'
} as const;

export type SendNotificationRequestSubjectEnum = typeof SendNotificationRequestSubjectEnum[keyof typeof SendNotificationRequestSubjectEnum];
export const SendNotificationRequestTypeEnum = {
    Email: 'email',
    Calendar: 'calendar'
} as const;

export type SendNotificationRequestTypeEnum = typeof SendNotificationRequestTypeEnum[keyof typeof SendNotificationRequestTypeEnum];

/**
 * 
 * @export
 * @interface SendRALRequestRequest
 */
export interface SendRALRequestRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof SendRALRequestRequest
     */
    'ral_request_ids': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof SendRALRequestRequest
     */
    'send_silent'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SendRALRequestRequest
     */
    'send_method'?: SendRALRequestRequestSendMethodEnum;
}

export const SendRALRequestRequestSendMethodEnum = {
    Pandadoc: 'pandadoc',
    Docusign: 'docusign'
} as const;

export type SendRALRequestRequestSendMethodEnum = typeof SendRALRequestRequestSendMethodEnum[keyof typeof SendRALRequestRequestSendMethodEnum];

/**
 * 
 * @export
 * @interface SendSetupEmailRequest
 */
export interface SendSetupEmailRequest {
    [key: string]: any;

    /**
     * 
     * @type {any}
     * @memberof SendSetupEmailRequest
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof SendSetupEmailRequest
     */
    'email'?: any;
}
/**
 * 
 * @export
 * @interface StartMultipartUpload200Response
 */
export interface StartMultipartUpload200Response {
    /**
     * 
     * @type {boolean}
     * @memberof StartMultipartUpload200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StartMultipartUpload200Response
     */
    'upload_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StartMultipartUpload200Response
     */
    'urls'?: Array<string>;
}
/**
 * 
 * @export
 * @interface StartMultipartUploadRequest
 */
export interface StartMultipartUploadRequest {
    /**
     * 
     * @type {number}
     * @memberof StartMultipartUploadRequest
     */
    'parts': number;
}
/**
 * 
 * @export
 * @interface SyncExtegration200Response
 */
export interface SyncExtegration200Response {
    /**
     * 
     * @type {boolean}
     * @memberof SyncExtegration200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof SyncExtegration200Response
     */
    'workerRequest'?: object;
}
/**
 * 
 * @export
 * @interface SyncExtegrationRequest
 */
export interface SyncExtegrationRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncExtegrationRequest
     */
    'extegration_connection_id': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SyncExtegrationRequest
     */
    'entities'?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SyncInterval = {
    Daily: 'daily',
    Weekdays: 'weekdays',
    Weekly: 'weekly',
    Monthly: 'monthly',
    Tetrahourly: 'tetrahourly'
} as const;

export type SyncInterval = typeof SyncInterval[keyof typeof SyncInterval];


/**
 * Tax document form types.
 * @export
 * @enum {string}
 */

export const TaxDocumentFormType = {
    K1: 'K-1',
    Estimate: 'Estimate',
    _1099: '1099',
    W9: 'W-9',
    Other: 'Other'
} as const;

export type TaxDocumentFormType = typeof TaxDocumentFormType[keyof typeof TaxDocumentFormType];


/**
 * 
 * @export
 * @enum {string}
 */

export const TransactionNotificationStatus = {
    Unsent: 'unsent',
    Sent: 'sent',
    Error: 'error',
    NoNeedToSend: 'no_need_to_send'
} as const;

export type TransactionNotificationStatus = typeof TransactionNotificationStatus[keyof typeof TransactionNotificationStatus];


/**
 * The types of transactions.
 * @export
 * @interface TransactionTypes
 */
export interface TransactionTypes {
    /**
     * 
     * @type {string}
     * @memberof TransactionTypes
     */
    'typeArr'?: TransactionTypesTypeArrEnum;
    /**
     * 
     * @type {TransactionTypesTypeMap}
     * @memberof TransactionTypes
     */
    'typeMap'?: TransactionTypesTypeMap;
}

export const TransactionTypesTypeArrEnum = {
    CapitalCalltransactionTypeNameCapitalCalltransactionTypeNameOldCapitalCallinvestmentTypePrivateEquitycategoryAlternativesallowedNegative1allowedExport1capitalCalled1amountInvested1unitEffect1unrealizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1contributionEffect0transactionTypeBlackDiamondNameCapitalCalltransactionTypeAddeparNamecapitalCall: '{\"transaction_type\":\"capital_call\",\"transaction_type_name\":\"Capital Call\",\"transaction_type_name_old\":\"Capital Call\",\"investment_type\":\"Private Equity\",\"category\":\"Alternatives\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"capital_called\":\"1\",\"amount_invested\":\"1\",\"unit_effect\":\"1\",\"unrealized_value\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"joint_transaction_calculation_effect\":\"-1\",\"contribution_effect\":\"0\",\"transaction_type_black_diamond_name\":\"Capital Call\",\"transaction_type_addepar_name\":\"capital_call\"}',
    CapitalCommitmenttransactionTypeNameCapitalCommittmenttransactionTypeNameOldCapitalCommittmentinvestmentTypePrivateEquitycategoryJournalsallowedNegative1allowedExport1committedCapital1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameCommittedCapitaltransactionTypeAddeparNamecommitment: '{\"transaction_type\":\"capital_commitment\",\"transaction_type_name\":\"Capital Committment\",\"transaction_type_name_old\":\"Capital Committment\",\"investment_type\":\"Private Equity\",\"category\":\"Journals\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"committed_capital\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Committed Capital\",\"transaction_type_addepar_name\":\"commitment\"}',
    CashDeposittransactionTypeNameCashDeposittransactionTypeNameOldCashDepositinvestmentTypeStandardcategoryContributionsallowedExport1cashEffect1displayEffect1jointTransactionCalculationEffect1contributionEffect1transactionTypeBlackDiamondNameCashDeposit: '{\"transaction_type\":\"cash_deposit\",\"transaction_type_name\":\"Cash Deposit\",\"transaction_type_name_old\":\"Cash Deposit\",\"investment_type\":\"Standard\",\"category\":\"Contributions\",\"allowed_export\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"contribution_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Deposit\"}',
    CashDistributionReturnOfCapitaltransactionTypeNameCashDistributionReturnOfCapitaltransactionTypeNameOldCashDistributionReturnOfCapitalinvestmentTypeGeneralAlternativescategoryOtherallowedExport1amountInvested1unrealizedValue1realizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameCashDistributionReturnOfCapitaltransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"cash_distribution_return_of_capital\",\"transaction_type_name\":\"Cash Distribution - Return of Capital\",\"transaction_type_name_old\":\"Cash Distribution - Return of Capital\",\"investment_type\":\"General Alternatives\",\"category\":\"Other\",\"allowed_export\":\"1\",\"amount_invested\":\"-1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Distribution - Return of Capital\",\"transaction_type_addepar_name\":\"distribution\"}',
    CashWithdrawaltransactionTypeNameCashWithdrawaltransactionTypeNameOldCashWithdrawalinvestmentTypeStandardcategoryWithdrawalsallowedExport1cashEffect1displayEffect1jointTransactionCalculationEffect1contributionEffect1transactionTypeBlackDiamondNameCashWithdrawal: '{\"transaction_type\":\"cash_withdrawal\",\"transaction_type_name\":\"Cash Withdrawal\",\"transaction_type_name_old\":\"Cash Withdrawal\",\"investment_type\":\"Standard\",\"category\":\"Withdrawals\",\"allowed_export\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"joint_transaction_calculation_effect\":\"-1\",\"contribution_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Cash Withdrawal\"}',
    BuytransactionTypeNameContributiontransactionTypeNameOldContributioninvestmentTypeHedgeFundscategoryBuysallowedNegative1allowedExport1capitalCalled1amountInvested1unitEffect1unrealizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1contributionEffect0transactionTypeBlackDiamondNameBuytransactionTypeAddeparNamecontribution: '{\"transaction_type\":\"buy\",\"transaction_type_name\":\"Contribution\",\"transaction_type_name_old\":\"Contribution\",\"investment_type\":\"Hedge Funds\",\"category\":\"Buys\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"capital_called\":\"1\",\"amount_invested\":\"1\",\"unit_effect\":\"1\",\"unrealized_value\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"joint_transaction_calculation_effect\":\"-1\",\"contribution_effect\":\"0\",\"transaction_type_black_diamond_name\":\"Buy\",\"transaction_type_addepar_name\":\"contribution\"}',
    DividendReversaltransactionTypeNameDividendReversaltransactionTypeNameOldDividendReversalinvestmentTypeStandardcategoryIncomeallowedExport1realizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameDividendReversal: '{\"transaction_type\":\"dividend_reversal\",\"transaction_type_name\":\"Dividend Reversal\",\"transaction_type_name_old\":\"Dividend Reversal\",\"investment_type\":\"Standard\",\"category\":\"Income\",\"allowed_export\":\"1\",\"realized_value\":\"-1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"joint_transaction_calculation_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Dividend Reversal\"}',
    DividendtransactionTypeNameDividendIncometransactionTypeNameOldDividendinvestmentTypeStandardcategoryIncomeallowedExport1realizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameDividendtransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"dividend\",\"transaction_type_name\":\"Dividend/Income\",\"transaction_type_name_old\":\"Dividend\",\"investment_type\":\"Standard\",\"category\":\"Income\",\"allowed_export\":\"1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Dividend\",\"transaction_type_addepar_name\":\"distribution\"}',
    IncentiveFeetransactionTypeNameIncentiveFeetransactionTypeNameOldPerformanceAllocationinvestmentTypeGeneralAlternativescategoryFeesallowedNegative1allowedExport1unrealizedValue1feeEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameIncentiveFeetransactionTypeAddeparNamefee: '{\"transaction_type\":\"incentive_fee\",\"transaction_type_name\":\"Incentive Fee\",\"transaction_type_name_old\":\"Performance Allocation\",\"investment_type\":\"General Alternatives\",\"category\":\"Fees\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"unrealized_value\":\"-1\",\"fee_effect\":\"1\",\"display_effect\":\"-1\",\"joint_transaction_calculation_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Incentive Fee\",\"transaction_type_addepar_name\":\"fee\"}',
    InterestExpensetransactionTypeNameInterestExpenseinvestmentTypeGeneralAlternativescategoryIncomeallowedExport1unrealizedValue1feeEffect1cashEffect1displayEffect1jointTransactionCalculationEffect1contributionEffect1transactionTypeAddeparNameinterestExpense: '{\"transaction_type\":\"interest_expense\",\"transaction_type_name\":\"Interest Expense\",\"investment_type\":\"General Alternatives\",\"category\":\"Income\",\"allowed_export\":\"1\",\"unrealized_value\":\"-1\",\"fee_effect\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"joint_transaction_calculation_effect\":\"-1\",\"contribution_effect\":\"-1\",\"transaction_type_addepar_name\":\"interest_expense\"}',
    InterestIncometransactionTypeNameInterestIncomeinvestmentTypeGeneralAlternativescategoryIncomeallowedExport1unrealizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1contributionEffect1transactionTypeAddeparNameincome: '{\"transaction_type\":\"interest_income\",\"transaction_type_name\":\"Interest Income\",\"investment_type\":\"General Alternatives\",\"category\":\"Income\",\"allowed_export\":\"1\",\"unrealized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"contribution_effect\":\"1\",\"transaction_type_addepar_name\":\"income\"}',
    JointTransactiontransactionTypeNameJointTransactiontransactionTypeNameOldJointTransactioninvestmentTypeStandardcategoryOtherdisplayEffect1jointTransactionCalculationEffect1: '{\"transaction_type\":\"joint_transaction\",\"transaction_type_name\":\"Joint Transaction\",\"transaction_type_name_old\":\"Joint Transaction\",\"investment_type\":\"Standard\",\"category\":\"Other\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\"}',
    ManagementFeetransactionTypeNameManagementFeetransactionTypeNameOldManagementFeeinvestmentTypeStandardcategoryFeesallowedNegative1allowedExport1unrealizedValue1feeEffect1displayEffect1contributionEffect1transactionTypeBlackDiamondNameManagementFeetransactionTypeAddeparNamefee: '{\"transaction_type\":\"management_fee\",\"transaction_type_name\":\"Management Fee\",\"transaction_type_name_old\":\"Management Fee\",\"investment_type\":\"Standard\",\"category\":\"Fees\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"unrealized_value\":\"-1\",\"fee_effect\":\"1\",\"display_effect\":\"-1\",\"contribution_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Management Fee\",\"transaction_type_addepar_name\":\"fee\"}',
    ManagementFeeRebatetransactionTypeNameManagementFeeRebatetransactionTypeNameOldManagementFeeRebateinvestmentTypeStandardcategoryFeesallowedNegative1allowedExport1unrealizedValue1feeEffect1displayEffect1contributionEffect1transactionTypeBlackDiamondNameManagementFeeReversaltransactionTypeAddeparNamefeeReimbursement: '{\"transaction_type\":\"management_fee_rebate\",\"transaction_type_name\":\"Management Fee Rebate\",\"transaction_type_name_old\":\"Management Fee Rebate\",\"investment_type\":\"Standard\",\"category\":\"Fees\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"unrealized_value\":\"1\",\"fee_effect\":\"-1\",\"display_effect\":\"1\",\"contribution_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Management Fee Reversal\",\"transaction_type_addepar_name\":\"fee_reimbursement\"}',
    OthertransactionTypeNameOtherinvestmentTypeStandardcategoryOtherdisplayEffect1: '{\"transaction_type\":\"other\",\"transaction_type_name\":\"Other\",\"investment_type\":\"Standard\",\"category\":\"Other\",\"display_effect\":\"1\"}',
    OtherFeetransactionTypeNameOtherFeetransactionTypeNameOldOtherFeeinvestmentTypeStandardcategoryWithdrawalsallowedNegative1allowedExport1unrealizedValue1feeEffect1displayEffect1contributionEffect1transactionTypeBlackDiamondNameOtherFeetransactionTypeAddeparNamefee: '{\"transaction_type\":\"other_fee\",\"transaction_type_name\":\"Other Fee\",\"transaction_type_name_old\":\"Other Fee\",\"investment_type\":\"Standard\",\"category\":\"Withdrawals\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"unrealized_value\":\"-1\",\"fee_effect\":\"1\",\"display_effect\":\"-1\",\"contribution_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Other Fee\",\"transaction_type_addepar_name\":\"fee\"}',
    ReturnOfCapitaltransactionTypeNamePrincipalDistributiontransactionTypeNameOldPrincipalDistributioninvestmentTypeGeneralAlternativescategoryOtherallowedNegative1allowedExport1amountInvested1unrealizedValue1realizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameCashDistributionReturnOfCapitaltransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"return_of_capital\",\"transaction_type_name\":\"Principal Distribution\",\"transaction_type_name_old\":\"Principal Distribution\",\"investment_type\":\"General Alternatives\",\"category\":\"Other\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"amount_invested\":\"-1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Distribution - Return of Capital\",\"transaction_type_addepar_name\":\"distribution\"}',
    ReturnOnCapitaltransactionTypeNameProfitDistributiontransactionTypeNameOldProfitDistributioninvestmentTypeGeneralAlternativescategoryIncomeallowedNegative1allowedExport1realizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameCashDistributionIncometransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"return_on_capital\",\"transaction_type_name\":\"Profit Distribution\",\"transaction_type_name_old\":\"Profit Distribution\",\"investment_type\":\"General Alternatives\",\"category\":\"Income\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Distribution - Income\",\"transaction_type_addepar_name\":\"distribution\"}',
    RecallableReturnOfCapitaltransactionTypeNameRecallablePrincipalDistributiontransactionTypeNameOldRecallablePrincipalDistributioninvestmentTypePrivateEquitycategoryOtherallowedNegative1allowedExport1capitalCalled1amountInvested1unrealizedValue1realizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameCashDistributionReturnOfCapitaltransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"recallable_return_of_capital\",\"transaction_type_name\":\"Recallable Principal Distribution\",\"transaction_type_name_old\":\"Recallable Principal Distribution\",\"investment_type\":\"Private Equity\",\"category\":\"Other\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"capital_called\":\"-1\",\"amount_invested\":\"-1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Distribution - Return of Capital\",\"transaction_type_addepar_name\":\"distribution\"}',
    RecallableReturnOnCapitaltransactionTypeNameRecallableProfitDistributiontransactionTypeNameOldRecallableProfitDistributioninvestmentTypePrivateEquitycategoryIncomeallowedNegative1allowedExport1capitalCalled1realizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameCashDistributionIncometransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"recallable_return_on_capital\",\"transaction_type_name\":\"Recallable Profit Distribution\",\"transaction_type_name_old\":\"Recallable Profit Distribution\",\"investment_type\":\"Private Equity\",\"category\":\"Income\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"capital_called\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Distribution - Income\",\"transaction_type_addepar_name\":\"distribution\"}',
    RedemptiontransactionTypeNameRedemptiontransactionTypeNameOldRedemptionWithdrawalHFinvestmentTypeHedgeFundscategorySellsallowedExport1unrealizedValue1realizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameRedemptiontransactionTypeAddeparNamefundRedemption: '{\"transaction_type\":\"redemption\",\"transaction_type_name\":\"Redemption\",\"transaction_type_name_old\":\"Redemption / Withdrawal (HF)\",\"investment_type\":\"Hedge Funds\",\"category\":\"Sells\",\"allowed_export\":\"1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Redemption\",\"transaction_type_addepar_name\":\"fund_redemption\"}',
    ReinvestmenttransactionTypeNameReinvestmentinvestmentTypeStandardcategoryBuysallowedExport1amountInvested1unitEffect1unrealizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1contributionEffect0transactionTypeBlackDiamondNameBuytransactionTypeAddeparNamereinvestment: '{\"transaction_type\":\"reinvestment\",\"transaction_type_name\":\"Reinvestment\",\"investment_type\":\"Standard\",\"category\":\"Buys\",\"allowed_export\":\"1\",\"amount_invested\":\"1\",\"unit_effect\":\"1\",\"unrealized_value\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"joint_transaction_calculation_effect\":\"-1\",\"contribution_effect\":\"0\",\"transaction_type_black_diamond_name\":\"Buy\",\"transaction_type_addepar_name\":\"reinvestment\"}',
    SubscriptiontransactionTypeNameSubscriptiontransactionTypeNameOldSubscriptionContributionHFinvestmentTypeHedgeFundscategoryBuyallowedExport1amountInvested1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameSubscriptiontransactionTypeAddeparNamebuy: '{\"transaction_type\":\"subscription\",\"transaction_type_name\":\"Subscription\",\"transaction_type_name_old\":\"Subscription / Contribution (HF)\",\"investment_type\":\"Hedge Funds\",\"category\":\"Buy\",\"allowed_export\":\"1\",\"amount_invested\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"joint_transaction_calculation_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Subscription\",\"transaction_type_addepar_name\":\"buy\"}',
    TaxDistributiontransactionTypeNameTaxDistributiontransactionTypeNameOldTaxDistributioninvestmentTypeStandardcategoryOtherallowedNegative1allowedExport1realizedValue1displayEffect1transactionTypeAddeparNametax: '{\"transaction_type\":\"tax_distribution\",\"transaction_type_name\":\"Tax Distribution\",\"transaction_type_name_old\":\"Tax Distribution\",\"investment_type\":\"Standard\",\"category\":\"Other\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"realized_value\":\"1\",\"display_effect\":\"1\",\"transaction_type_addepar_name\":\"tax\"}',
    TransferIntransactionTypeNameTransferIntransactionTypeNameOldTransferIninvestmentTypeGeneralAlternativescategoryBuysallowedExport1amountInvested1unitEffect1unrealizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameTransfertransactionTypeAddeparNametransfer: '{\"transaction_type\":\"transfer_in\",\"transaction_type_name\":\"Transfer In\",\"transaction_type_name_old\":\"Transfer In\",\"investment_type\":\"General Alternatives\",\"category\":\"Buys\",\"allowed_export\":\"1\",\"amount_invested\":\"1\",\"unit_effect\":\"1\",\"unrealized_value\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Transfer\",\"transaction_type_addepar_name\":\"transfer\"}',
    TransferOuttransactionTypeNameTransferOuttransactionTypeNameOldTransferOutinvestmentTypeGeneralAlternativescategorySellsallowedExport1unitEffect1unrealizedValue1realizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameTransfertransactionTypeAddeparNametransfer: '{\"transaction_type\":\"transfer_out\",\"transaction_type_name\":\"Transfer Out\",\"transaction_type_name_old\":\"Transfer Out\",\"investment_type\":\"General Alternatives\",\"category\":\"Sells\",\"allowed_export\":\"1\",\"unit_effect\":\"-1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Transfer\",\"transaction_type_addepar_name\":\"transfer\"}',
    ValuationChangetransactionTypeNameValuationChangetransactionTypeNameOldValuationChangeinvestmentTypeGeneralAlternativescategoryAlternativesallowedNegative1allowedExport1unrealizedValue1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameValuationChange: '{\"transaction_type\":\"valuation_change\",\"transaction_type_name\":\"Valuation Change\",\"transaction_type_name_old\":\"Valuation Change\",\"investment_type\":\"General Alternatives\",\"category\":\"Alternatives\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"unrealized_value\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Valuation Change\"}',
    SaletransactionTypeNameWithdrawaltransactionTypeNameOldWithdrawalinvestmentTypeHedgeFundscategorySellsallowedExport1amountInvested1unitEffect1unrealizedValue1realizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameSaletransactionTypeAddeparNamefundRedemption: '{\"transaction_type\":\"sale\",\"transaction_type_name\":\"Withdrawal\",\"transaction_type_name_old\":\"Withdrawal\",\"investment_type\":\"Hedge Funds\",\"category\":\"Sells\",\"allowed_export\":\"1\",\"amount_invested\":\"-1\",\"unit_effect\":\"-1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Sale\",\"transaction_type_addepar_name\":\"fund_redemption\"}'
} as const;

export type TransactionTypesTypeArrEnum = typeof TransactionTypesTypeArrEnum[keyof typeof TransactionTypesTypeArrEnum];

/**
 * 
 * @export
 * @interface TransactionTypesTypeMap
 */
export interface TransactionTypesTypeMap {
    /**
     * 
     * @type {string}
     * @memberof TransactionTypesTypeMap
     */
    'keys'?: TransactionTypesTypeMapKeysEnum;
    /**
     * 
     * @type {string}
     * @memberof TransactionTypesTypeMap
     */
    'values'?: TransactionTypesTypeMapValuesEnum;
}

export const TransactionTypesTypeMapKeysEnum = {
    Buy: 'buy',
    Sale: 'sale',
    CapitalCall: 'capital_call',
    CashDistributionReturnOfCapital: 'cash_distribution_return_of_capital',
    CapitalCommitment: 'capital_commitment',
    RecallableReturnOfCapital: 'recallable_return_of_capital',
    RecallableReturnOnCapital: 'recallable_return_on_capital',
    ReturnOfCapital: 'return_of_capital',
    ReturnOnCapital: 'return_on_capital',
    InterestIncome: 'interest_income',
    InterestExpense: 'interest_expense',
    IncentiveFee: 'incentive_fee',
    Redemption: 'redemption',
    Subscription: 'subscription',
    ValuationChange: 'valuation_change',
    CashDeposit: 'cash_deposit',
    CashWithdrawal: 'cash_withdrawal',
    Reinvestment: 'reinvestment',
    Dividend: 'dividend',
    DividendReversal: 'dividend_reversal',
    ManagementFee: 'management_fee',
    ManagementFeeRebate: 'management_fee_rebate',
    OtherFee: 'other_fee',
    TransferIn: 'transfer_in',
    TransferOut: 'transfer_out',
    TaxDistribution: 'tax_distribution',
    Other: 'other',
    JointTransaction: 'joint_transaction'
} as const;

export type TransactionTypesTypeMapKeysEnum = typeof TransactionTypesTypeMapKeysEnum[keyof typeof TransactionTypesTypeMapKeysEnum];
export const TransactionTypesTypeMapValuesEnum = {
    BuytransactionTypeNameContributiontransactionTypeNameOldContributioninvestmentTypeHedgeFundscategoryBuysallowedNegative1allowedExport1capitalCalled1amountInvested1unitEffect1unrealizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1contributionEffect0transactionTypeBlackDiamondNameBuytransactionTypeAddeparNamecontribution: '{\"transaction_type\":\"buy\",\"transaction_type_name\":\"Contribution\",\"transaction_type_name_old\":\"Contribution\",\"investment_type\":\"Hedge Funds\",\"category\":\"Buys\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"capital_called\":\"1\",\"amount_invested\":\"1\",\"unit_effect\":\"1\",\"unrealized_value\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"joint_transaction_calculation_effect\":\"-1\",\"contribution_effect\":\"0\",\"transaction_type_black_diamond_name\":\"Buy\",\"transaction_type_addepar_name\":\"contribution\"}',
    SaletransactionTypeNameWithdrawaltransactionTypeNameOldWithdrawalinvestmentTypeHedgeFundscategorySellsallowedExport1amountInvested1unitEffect1unrealizedValue1realizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameSaletransactionTypeAddeparNamefundRedemption: '{\"transaction_type\":\"sale\",\"transaction_type_name\":\"Withdrawal\",\"transaction_type_name_old\":\"Withdrawal\",\"investment_type\":\"Hedge Funds\",\"category\":\"Sells\",\"allowed_export\":\"1\",\"amount_invested\":\"-1\",\"unit_effect\":\"-1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Sale\",\"transaction_type_addepar_name\":\"fund_redemption\"}',
    CapitalCalltransactionTypeNameCapitalCalltransactionTypeNameOldCapitalCallinvestmentTypePrivateEquitycategoryAlternativesallowedNegative1allowedExport1capitalCalled1amountInvested1unitEffect1unrealizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1contributionEffect0transactionTypeBlackDiamondNameCapitalCalltransactionTypeAddeparNamecapitalCall: '{\"transaction_type\":\"capital_call\",\"transaction_type_name\":\"Capital Call\",\"transaction_type_name_old\":\"Capital Call\",\"investment_type\":\"Private Equity\",\"category\":\"Alternatives\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"capital_called\":\"1\",\"amount_invested\":\"1\",\"unit_effect\":\"1\",\"unrealized_value\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"joint_transaction_calculation_effect\":\"-1\",\"contribution_effect\":\"0\",\"transaction_type_black_diamond_name\":\"Capital Call\",\"transaction_type_addepar_name\":\"capital_call\"}',
    CashDistributionReturnOfCapitaltransactionTypeNameCashDistributionReturnOfCapitaltransactionTypeNameOldCashDistributionReturnOfCapitalinvestmentTypeGeneralAlternativescategoryOtherallowedExport1amountInvested1unrealizedValue1realizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameCashDistributionReturnOfCapitaltransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"cash_distribution_return_of_capital\",\"transaction_type_name\":\"Cash Distribution - Return of Capital\",\"transaction_type_name_old\":\"Cash Distribution - Return of Capital\",\"investment_type\":\"General Alternatives\",\"category\":\"Other\",\"allowed_export\":\"1\",\"amount_invested\":\"-1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Distribution - Return of Capital\",\"transaction_type_addepar_name\":\"distribution\"}',
    CapitalCommitmenttransactionTypeNameCapitalCommittmenttransactionTypeNameOldCapitalCommittmentinvestmentTypePrivateEquitycategoryJournalsallowedNegative1allowedExport1committedCapital1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameCommittedCapitaltransactionTypeAddeparNamecommitment: '{\"transaction_type\":\"capital_commitment\",\"transaction_type_name\":\"Capital Committment\",\"transaction_type_name_old\":\"Capital Committment\",\"investment_type\":\"Private Equity\",\"category\":\"Journals\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"committed_capital\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Committed Capital\",\"transaction_type_addepar_name\":\"commitment\"}',
    RecallableReturnOfCapitaltransactionTypeNameRecallablePrincipalDistributiontransactionTypeNameOldRecallablePrincipalDistributioninvestmentTypePrivateEquitycategoryOtherallowedNegative1allowedExport1capitalCalled1amountInvested1unrealizedValue1realizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameCashDistributionReturnOfCapitaltransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"recallable_return_of_capital\",\"transaction_type_name\":\"Recallable Principal Distribution\",\"transaction_type_name_old\":\"Recallable Principal Distribution\",\"investment_type\":\"Private Equity\",\"category\":\"Other\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"capital_called\":\"-1\",\"amount_invested\":\"-1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Distribution - Return of Capital\",\"transaction_type_addepar_name\":\"distribution\"}',
    RecallableReturnOnCapitaltransactionTypeNameRecallableProfitDistributiontransactionTypeNameOldRecallableProfitDistributioninvestmentTypePrivateEquitycategoryIncomeallowedNegative1allowedExport1capitalCalled1realizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameCashDistributionIncometransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"recallable_return_on_capital\",\"transaction_type_name\":\"Recallable Profit Distribution\",\"transaction_type_name_old\":\"Recallable Profit Distribution\",\"investment_type\":\"Private Equity\",\"category\":\"Income\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"capital_called\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Distribution - Income\",\"transaction_type_addepar_name\":\"distribution\"}',
    ReturnOfCapitaltransactionTypeNamePrincipalDistributiontransactionTypeNameOldPrincipalDistributioninvestmentTypeGeneralAlternativescategoryOtherallowedNegative1allowedExport1amountInvested1unrealizedValue1realizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameCashDistributionReturnOfCapitaltransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"return_of_capital\",\"transaction_type_name\":\"Principal Distribution\",\"transaction_type_name_old\":\"Principal Distribution\",\"investment_type\":\"General Alternatives\",\"category\":\"Other\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"amount_invested\":\"-1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Distribution - Return of Capital\",\"transaction_type_addepar_name\":\"distribution\"}',
    ReturnOnCapitaltransactionTypeNameProfitDistributiontransactionTypeNameOldProfitDistributioninvestmentTypeGeneralAlternativescategoryIncomeallowedNegative1allowedExport1realizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameCashDistributionIncometransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"return_on_capital\",\"transaction_type_name\":\"Profit Distribution\",\"transaction_type_name_old\":\"Profit Distribution\",\"investment_type\":\"General Alternatives\",\"category\":\"Income\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Distribution - Income\",\"transaction_type_addepar_name\":\"distribution\"}',
    InterestIncometransactionTypeNameInterestIncomeinvestmentTypeGeneralAlternativescategoryIncomeallowedExport1unrealizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1contributionEffect1transactionTypeAddeparNameincome: '{\"transaction_type\":\"interest_income\",\"transaction_type_name\":\"Interest Income\",\"investment_type\":\"General Alternatives\",\"category\":\"Income\",\"allowed_export\":\"1\",\"unrealized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"contribution_effect\":\"1\",\"transaction_type_addepar_name\":\"income\"}',
    InterestExpensetransactionTypeNameInterestExpenseinvestmentTypeGeneralAlternativescategoryIncomeallowedExport1unrealizedValue1feeEffect1cashEffect1displayEffect1jointTransactionCalculationEffect1contributionEffect1transactionTypeAddeparNameinterestExpense: '{\"transaction_type\":\"interest_expense\",\"transaction_type_name\":\"Interest Expense\",\"investment_type\":\"General Alternatives\",\"category\":\"Income\",\"allowed_export\":\"1\",\"unrealized_value\":\"-1\",\"fee_effect\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"joint_transaction_calculation_effect\":\"-1\",\"contribution_effect\":\"-1\",\"transaction_type_addepar_name\":\"interest_expense\"}',
    IncentiveFeetransactionTypeNameIncentiveFeetransactionTypeNameOldPerformanceAllocationinvestmentTypeGeneralAlternativescategoryFeesallowedNegative1allowedExport1unrealizedValue1feeEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameIncentiveFeetransactionTypeAddeparNamefee: '{\"transaction_type\":\"incentive_fee\",\"transaction_type_name\":\"Incentive Fee\",\"transaction_type_name_old\":\"Performance Allocation\",\"investment_type\":\"General Alternatives\",\"category\":\"Fees\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"unrealized_value\":\"-1\",\"fee_effect\":\"1\",\"display_effect\":\"-1\",\"joint_transaction_calculation_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Incentive Fee\",\"transaction_type_addepar_name\":\"fee\"}',
    RedemptiontransactionTypeNameRedemptiontransactionTypeNameOldRedemptionWithdrawalHFinvestmentTypeHedgeFundscategorySellsallowedExport1unrealizedValue1realizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameRedemptiontransactionTypeAddeparNamefundRedemption: '{\"transaction_type\":\"redemption\",\"transaction_type_name\":\"Redemption\",\"transaction_type_name_old\":\"Redemption / Withdrawal (HF)\",\"investment_type\":\"Hedge Funds\",\"category\":\"Sells\",\"allowed_export\":\"1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Redemption\",\"transaction_type_addepar_name\":\"fund_redemption\"}',
    SubscriptiontransactionTypeNameSubscriptiontransactionTypeNameOldSubscriptionContributionHFinvestmentTypeHedgeFundscategoryBuyallowedExport1amountInvested1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameSubscriptiontransactionTypeAddeparNamebuy: '{\"transaction_type\":\"subscription\",\"transaction_type_name\":\"Subscription\",\"transaction_type_name_old\":\"Subscription / Contribution (HF)\",\"investment_type\":\"Hedge Funds\",\"category\":\"Buy\",\"allowed_export\":\"1\",\"amount_invested\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"joint_transaction_calculation_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Subscription\",\"transaction_type_addepar_name\":\"buy\"}',
    ValuationChangetransactionTypeNameValuationChangetransactionTypeNameOldValuationChangeinvestmentTypeGeneralAlternativescategoryAlternativesallowedNegative1allowedExport1unrealizedValue1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameValuationChange: '{\"transaction_type\":\"valuation_change\",\"transaction_type_name\":\"Valuation Change\",\"transaction_type_name_old\":\"Valuation Change\",\"investment_type\":\"General Alternatives\",\"category\":\"Alternatives\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"unrealized_value\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Valuation Change\"}',
    CashDeposittransactionTypeNameCashDeposittransactionTypeNameOldCashDepositinvestmentTypeStandardcategoryContributionsallowedExport1cashEffect1displayEffect1jointTransactionCalculationEffect1contributionEffect1transactionTypeBlackDiamondNameCashDeposit: '{\"transaction_type\":\"cash_deposit\",\"transaction_type_name\":\"Cash Deposit\",\"transaction_type_name_old\":\"Cash Deposit\",\"investment_type\":\"Standard\",\"category\":\"Contributions\",\"allowed_export\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"contribution_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Deposit\"}',
    CashWithdrawaltransactionTypeNameCashWithdrawaltransactionTypeNameOldCashWithdrawalinvestmentTypeStandardcategoryWithdrawalsallowedExport1cashEffect1displayEffect1jointTransactionCalculationEffect1contributionEffect1transactionTypeBlackDiamondNameCashWithdrawal: '{\"transaction_type\":\"cash_withdrawal\",\"transaction_type_name\":\"Cash Withdrawal\",\"transaction_type_name_old\":\"Cash Withdrawal\",\"investment_type\":\"Standard\",\"category\":\"Withdrawals\",\"allowed_export\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"joint_transaction_calculation_effect\":\"-1\",\"contribution_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Cash Withdrawal\"}',
    ReinvestmenttransactionTypeNameReinvestmentinvestmentTypeStandardcategoryBuysallowedExport1amountInvested1unitEffect1unrealizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1contributionEffect0transactionTypeBlackDiamondNameBuytransactionTypeAddeparNamereinvestment: '{\"transaction_type\":\"reinvestment\",\"transaction_type_name\":\"Reinvestment\",\"investment_type\":\"Standard\",\"category\":\"Buys\",\"allowed_export\":\"1\",\"amount_invested\":\"1\",\"unit_effect\":\"1\",\"unrealized_value\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"joint_transaction_calculation_effect\":\"-1\",\"contribution_effect\":\"0\",\"transaction_type_black_diamond_name\":\"Buy\",\"transaction_type_addepar_name\":\"reinvestment\"}',
    DividendtransactionTypeNameDividendIncometransactionTypeNameOldDividendinvestmentTypeStandardcategoryIncomeallowedExport1realizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameDividendtransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"dividend\",\"transaction_type_name\":\"Dividend/Income\",\"transaction_type_name_old\":\"Dividend\",\"investment_type\":\"Standard\",\"category\":\"Income\",\"allowed_export\":\"1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Dividend\",\"transaction_type_addepar_name\":\"distribution\"}',
    DividendReversaltransactionTypeNameDividendReversaltransactionTypeNameOldDividendReversalinvestmentTypeStandardcategoryIncomeallowedExport1realizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameDividendReversal: '{\"transaction_type\":\"dividend_reversal\",\"transaction_type_name\":\"Dividend Reversal\",\"transaction_type_name_old\":\"Dividend Reversal\",\"investment_type\":\"Standard\",\"category\":\"Income\",\"allowed_export\":\"1\",\"realized_value\":\"-1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"joint_transaction_calculation_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Dividend Reversal\"}',
    ManagementFeetransactionTypeNameManagementFeetransactionTypeNameOldManagementFeeinvestmentTypeStandardcategoryFeesallowedNegative1allowedExport1unrealizedValue1feeEffect1displayEffect1contributionEffect1transactionTypeBlackDiamondNameManagementFeetransactionTypeAddeparNamefee: '{\"transaction_type\":\"management_fee\",\"transaction_type_name\":\"Management Fee\",\"transaction_type_name_old\":\"Management Fee\",\"investment_type\":\"Standard\",\"category\":\"Fees\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"unrealized_value\":\"-1\",\"fee_effect\":\"1\",\"display_effect\":\"-1\",\"contribution_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Management Fee\",\"transaction_type_addepar_name\":\"fee\"}',
    ManagementFeeRebatetransactionTypeNameManagementFeeRebatetransactionTypeNameOldManagementFeeRebateinvestmentTypeStandardcategoryFeesallowedNegative1allowedExport1unrealizedValue1feeEffect1displayEffect1contributionEffect1transactionTypeBlackDiamondNameManagementFeeReversaltransactionTypeAddeparNamefeeReimbursement: '{\"transaction_type\":\"management_fee_rebate\",\"transaction_type_name\":\"Management Fee Rebate\",\"transaction_type_name_old\":\"Management Fee Rebate\",\"investment_type\":\"Standard\",\"category\":\"Fees\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"unrealized_value\":\"1\",\"fee_effect\":\"-1\",\"display_effect\":\"1\",\"contribution_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Management Fee Reversal\",\"transaction_type_addepar_name\":\"fee_reimbursement\"}',
    OtherFeetransactionTypeNameOtherFeetransactionTypeNameOldOtherFeeinvestmentTypeStandardcategoryWithdrawalsallowedNegative1allowedExport1unrealizedValue1feeEffect1displayEffect1contributionEffect1transactionTypeBlackDiamondNameOtherFeetransactionTypeAddeparNamefee: '{\"transaction_type\":\"other_fee\",\"transaction_type_name\":\"Other Fee\",\"transaction_type_name_old\":\"Other Fee\",\"investment_type\":\"Standard\",\"category\":\"Withdrawals\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"unrealized_value\":\"-1\",\"fee_effect\":\"1\",\"display_effect\":\"-1\",\"contribution_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Other Fee\",\"transaction_type_addepar_name\":\"fee\"}',
    TransferIntransactionTypeNameTransferIntransactionTypeNameOldTransferIninvestmentTypeGeneralAlternativescategoryBuysallowedExport1amountInvested1unitEffect1unrealizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameTransfertransactionTypeAddeparNametransfer: '{\"transaction_type\":\"transfer_in\",\"transaction_type_name\":\"Transfer In\",\"transaction_type_name_old\":\"Transfer In\",\"investment_type\":\"General Alternatives\",\"category\":\"Buys\",\"allowed_export\":\"1\",\"amount_invested\":\"1\",\"unit_effect\":\"1\",\"unrealized_value\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Transfer\",\"transaction_type_addepar_name\":\"transfer\"}',
    TransferOuttransactionTypeNameTransferOuttransactionTypeNameOldTransferOutinvestmentTypeGeneralAlternativescategorySellsallowedExport1unitEffect1unrealizedValue1realizedValue1cashEffect1displayEffect1jointTransactionCalculationEffect1transactionTypeBlackDiamondNameTransfertransactionTypeAddeparNametransfer: '{\"transaction_type\":\"transfer_out\",\"transaction_type_name\":\"Transfer Out\",\"transaction_type_name_old\":\"Transfer Out\",\"investment_type\":\"General Alternatives\",\"category\":\"Sells\",\"allowed_export\":\"1\",\"unit_effect\":\"-1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Transfer\",\"transaction_type_addepar_name\":\"transfer\"}',
    TaxDistributiontransactionTypeNameTaxDistributiontransactionTypeNameOldTaxDistributioninvestmentTypeStandardcategoryOtherallowedNegative1allowedExport1realizedValue1displayEffect1transactionTypeAddeparNametax: '{\"transaction_type\":\"tax_distribution\",\"transaction_type_name\":\"Tax Distribution\",\"transaction_type_name_old\":\"Tax Distribution\",\"investment_type\":\"Standard\",\"category\":\"Other\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"realized_value\":\"1\",\"display_effect\":\"1\",\"transaction_type_addepar_name\":\"tax\"}',
    OthertransactionTypeNameOtherinvestmentTypeStandardcategoryOtherdisplayEffect1: '{\"transaction_type\":\"other\",\"transaction_type_name\":\"Other\",\"investment_type\":\"Standard\",\"category\":\"Other\",\"display_effect\":\"1\"}',
    JointTransactiontransactionTypeNameJointTransactiontransactionTypeNameOldJointTransactioninvestmentTypeStandardcategoryOtherdisplayEffect1jointTransactionCalculationEffect1: '{\"transaction_type\":\"joint_transaction\",\"transaction_type_name\":\"Joint Transaction\",\"transaction_type_name_old\":\"Joint Transaction\",\"investment_type\":\"Standard\",\"category\":\"Other\",\"display_effect\":\"1\",\"joint_transaction_calculation_effect\":\"1\"}'
} as const;

export type TransactionTypesTypeMapValuesEnum = typeof TransactionTypesTypeMapValuesEnum[keyof typeof TransactionTypesTypeMapValuesEnum];

/**
 * 
 * @export
 * @interface UpdateAccountRequest
 */
export interface UpdateAccountRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateAccountRequest
     */
    'name'?: string;
    /**
     * 
     * @type {any}
     * @memberof UpdateAccountRequest
     */
    'account_type'?: UpdateAccountRequestAccountTypeEnum;
    /**
     * 
     * @type {any}
     * @memberof UpdateAccountRequest
     */
    'id_type'?: UpdateAccountRequestIdTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountRequest
     */
    'id_number'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateAccountRequest
     */
    'external_ids'?: { [key: string]: string; };
    /**
     * 
     * @type {UpdateAccountRequestAddress}
     * @memberof UpdateAccountRequest
     */
    'address'?: UpdateAccountRequestAddress;
    /**
     * 
     * @type {object}
     * @memberof UpdateAccountRequest
     */
    'external_ids_diff'?: object;
}

export const UpdateAccountRequestAccountTypeEnum = {
    Individual: 'individual',
    Joint: 'joint',
    Trust: 'trust',
    Ira: 'ira',
    Llc: 'llc',
    Partnership: 'partnership',
    Corporation: 'corporation',
    Other: 'other',
    Empty: ''
} as const;

export type UpdateAccountRequestAccountTypeEnum = typeof UpdateAccountRequestAccountTypeEnum[keyof typeof UpdateAccountRequestAccountTypeEnum];
export const UpdateAccountRequestIdTypeEnum = {
    Ssn: 'SSN',
    Ein: 'EIN',
    Tin: 'TIN',
    Empty: ''
} as const;

export type UpdateAccountRequestIdTypeEnum = typeof UpdateAccountRequestIdTypeEnum[keyof typeof UpdateAccountRequestIdTypeEnum];

/**
 * 
 * @export
 * @interface UpdateAccountRequestAddress
 */
export interface UpdateAccountRequestAddress {
    /**
     * 
     * @type {any}
     * @memberof UpdateAccountRequestAddress
     */
    'streetLine1'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdateAccountRequestAddress
     */
    'streetLine2'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdateAccountRequestAddress
     */
    'city'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdateAccountRequestAddress
     */
    'state'?: UpdateAccountRequestAddressStateEnum;
    /**
     * 
     * @type {any}
     * @memberof UpdateAccountRequestAddress
     */
    'country'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdateAccountRequestAddress
     */
    'zipCode'?: any;
}

export const UpdateAccountRequestAddressStateEnum = {
    Al: 'AL',
    Ak: 'AK',
    Az: 'AZ',
    Ar: 'AR',
    Ca: 'CA',
    Co: 'CO',
    Ct: 'CT',
    De: 'DE',
    Fl: 'FL',
    Ga: 'GA',
    Hi: 'HI',
    Id: 'ID',
    Il: 'IL',
    In: 'IN',
    Ia: 'IA',
    Ks: 'KS',
    Ky: 'KY',
    La: 'LA',
    Me: 'ME',
    Md: 'MD',
    Ma: 'MA',
    Mi: 'MI',
    Mn: 'MN',
    Ms: 'MS',
    Mo: 'MO',
    Mt: 'MT',
    Ne: 'NE',
    Nv: 'NV',
    Nh: 'NH',
    Nj: 'NJ',
    Nm: 'NM',
    Ny: 'NY',
    Nc: 'NC',
    Nd: 'ND',
    Oh: 'OH',
    Ok: 'OK',
    Or: 'OR',
    Pa: 'PA',
    Ri: 'RI',
    Sc: 'SC',
    Sd: 'SD',
    Tn: 'TN',
    Tx: 'TX',
    Ut: 'UT',
    Vt: 'VT',
    Va: 'VA',
    Wa: 'WA',
    Wv: 'WV',
    Wi: 'WI',
    Wy: 'WY',
    Nl: 'NL',
    Pe: 'PE',
    Ns: 'NS',
    Nb: 'NB',
    Qc: 'QC',
    True: 'true',
    Mb: 'MB',
    Sk: 'SK',
    Ab: 'AB',
    Bc: 'BC',
    Yt: 'YT',
    Nt: 'NT',
    Nu: 'NU',
    Empty: ''
} as const;

export type UpdateAccountRequestAddressStateEnum = typeof UpdateAccountRequestAddressStateEnum[keyof typeof UpdateAccountRequestAddressStateEnum];

/**
 * @type UpdateAccountRequestExternalIdsDiffValue
 * @export
 */
export type UpdateAccountRequestExternalIdsDiffValue = any | string;

/**
 * 
 * @export
 * @interface UpdateAdminTeamRequest
 */
export interface UpdateAdminTeamRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateAdminTeamRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminTeamRequest
     */
    'type'?: UpdateAdminTeamRequestTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAdminTeamRequest
     */
    'advisory_groups'?: Array<string>;
}

export const UpdateAdminTeamRequestTypeEnum = {
    AccountManagement: 'account_management',
    Onboarding: 'onboarding'
} as const;

export type UpdateAdminTeamRequestTypeEnum = typeof UpdateAdminTeamRequestTypeEnum[keyof typeof UpdateAdminTeamRequestTypeEnum];

/**
 * 
 * @export
 * @interface UpdateAdvisorInGroupRequest
 */
export interface UpdateAdvisorInGroupRequest {
    [key: string]: any;

    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAdvisorInGroupRequest
     */
    'clients'?: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof UpdateAdvisorInGroupRequest
     */
    'role'?: UpdateAdvisorInGroupRequestRoleEnum;
}

export const UpdateAdvisorInGroupRequestRoleEnum = {
    Owner: 'Owner',
    Admin: 'Admin',
    Member: 'Member'
} as const;

export type UpdateAdvisorInGroupRequestRoleEnum = typeof UpdateAdvisorInGroupRequestRoleEnum[keyof typeof UpdateAdvisorInGroupRequestRoleEnum];

/**
 * 
 * @export
 * @interface UpdateAdvisoryGroup200Response
 */
export interface UpdateAdvisoryGroup200Response {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAdvisoryGroup200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {ListAdvisoryGroups200ResponseAdvisoryGroupsInner}
     * @memberof UpdateAdvisoryGroup200Response
     */
    'advisoryGroup'?: ListAdvisoryGroups200ResponseAdvisoryGroupsInner;
}
/**
 * 
 * @export
 * @interface UpdateAdvisoryGroupRequest
 */
export interface UpdateAdvisoryGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdvisoryGroupRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdvisoryGroupRequest
     */
    'custom_connection_email_domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdvisoryGroupRequest
     */
    'assets'?: string;
    /**
     * 
     * @type {Array<CreateAdvisoryGroupRequestExtegrationsInner>}
     * @memberof UpdateAdvisoryGroupRequest
     */
    'extegrations'?: Array<CreateAdvisoryGroupRequestExtegrationsInner>;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdvisoryGroupRequest
     */
    'plan'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAdvisoryGroupRequest
     */
    'disable_sla_issues'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAdvisoryGroupRequest
     */
    'allowed_extegrations'?: Array<string>;
    /**
     * 
     * @type {UpdateAdvisoryGroupRequestSettings}
     * @memberof UpdateAdvisoryGroupRequest
     */
    'settings'?: UpdateAdvisoryGroupRequestSettings;
}
/**
 * 
 * @export
 * @interface UpdateAdvisoryGroupRequestSettings
 */
export interface UpdateAdvisoryGroupRequestSettings {
    [key: string]: any;

    /**
     * 
     * @type {any}
     * @memberof UpdateAdvisoryGroupRequestSettings
     */
    'hide_fees_for_investors'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdateAdvisoryGroupRequestSettings
     */
    'hide_fees_for_advisors'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdateAdvisoryGroupRequestSettings
     */
    'logo_in_toolbar'?: any;
}
/**
 * 
 * @export
 * @interface UpdateAssetManager200Response
 */
export interface UpdateAssetManager200Response {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAssetManager200Response
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateAssetManagerRequest
 */
export interface UpdateAssetManagerRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateAssetManagerRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAssetManagerRequest
     */
    'website'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAssetManagerRequest
     */
    'tax_document_distribution_method'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof UpdateAssetManagerRequest
     */
    'notification_method'?: UpdateAssetManagerRequestNotificationMethodEnum;
    /**
     * 
     * @type {Array<any>}
     * @memberof UpdateAssetManagerRequest
     */
    'integrations'?: Array<any>;
    /**
     * 
     * @type {any}
     * @memberof UpdateAssetManagerRequest
     */
    'outreach_status'?: UpdateAssetManagerRequestOutreachStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAssetManagerRequest
     */
    'initial_outreach_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAssetManagerRequest
     */
    'num_outreach_attempts'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAssetManagerRequest
     */
    'passkey'?: string;
    /**
     * 
     * @type {Array<UpdateAssetManagerRequestContactsInner>}
     * @memberof UpdateAssetManagerRequest
     */
    'contacts'?: Array<UpdateAssetManagerRequestContactsInner>;
}

export const UpdateAssetManagerRequestNotificationMethodEnum = {
    None: 'none',
    Unknown: 'unknown',
    Email: 'email',
    Mail: 'mail',
    Portal: 'portal',
    Null: 'null',
    Null2: 'null'
} as const;

export type UpdateAssetManagerRequestNotificationMethodEnum = typeof UpdateAssetManagerRequestNotificationMethodEnum[keyof typeof UpdateAssetManagerRequestNotificationMethodEnum];
export const UpdateAssetManagerRequestOutreachStatusEnum = {
    NotStarted: 'Not Started',
    NoResponse: 'No Response',
    UnableToSupport: 'Unable to Support',
    GrantingPortalAccess: 'Granting Portal Access',
    InvestmentSetup: 'Investment Setup',
    Done: 'Done'
} as const;

export type UpdateAssetManagerRequestOutreachStatusEnum = typeof UpdateAssetManagerRequestOutreachStatusEnum[keyof typeof UpdateAssetManagerRequestOutreachStatusEnum];

/**
 * 
 * @export
 * @interface UpdateAssetManagerRequestContactsInner
 */
export interface UpdateAssetManagerRequestContactsInner {
    /**
     * 
     * @type {string}
     * @memberof UpdateAssetManagerRequestContactsInner
     */
    'contact': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAssetManagerRequestContactsInner
     */
    'roles': Array<UpdateAssetManagerRequestContactsInnerRolesEnum>;
}

export const UpdateAssetManagerRequestContactsInnerRolesEnum = {
    Primary: 'primary',
    Direct: 'direct',
    Tax: 'tax',
    Legal: 'legal',
    Ir: 'ir',
    Other: 'other'
} as const;

export type UpdateAssetManagerRequestContactsInnerRolesEnum = typeof UpdateAssetManagerRequestContactsInnerRolesEnum[keyof typeof UpdateAssetManagerRequestContactsInnerRolesEnum];

/**
 * 
 * @export
 * @interface UpdateAuditResultRequest
 */
export interface UpdateAuditResultRequest {
    [key: string]: any;

    /**
     * 
     * @type {any}
     * @memberof UpdateAuditResultRequest
     */
    'status'?: UpdateAuditResultRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuditResultRequest
     */
    'ignore_until'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAuditResultRequest
     */
    'read'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAuditResultRequest
     */
    'flagged'?: boolean;
}

export const UpdateAuditResultRequestStatusEnum = {
    Active: 'active',
    Ignored: 'ignored',
    AutoIgnored: 'auto-ignored',
    Completed: 'completed'
} as const;

export type UpdateAuditResultRequestStatusEnum = typeof UpdateAuditResultRequestStatusEnum[keyof typeof UpdateAuditResultRequestStatusEnum];

/**
 * 
 * @export
 * @interface UpdateCommitmentRequest
 */
export interface UpdateCommitmentRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateCommitmentRequest
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateCommitmentRequest
     */
    'amount'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCommitmentRequest
     */
    'documents'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateConnectionAccountRequest
 */
export interface UpdateConnectionAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionAccountRequest
     */
    'account'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionAccountRequest
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionAccountRequest
     */
    'ignore_reason'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionAccountRequest
     */
    'set_children_ignored'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionAccountRequest
     */
    'unmap_children'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionAccountRequest
     */
    'is_auto_import_enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionAccountRequest
     */
    'is_auto_import_documents_enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionAccountRequest
     */
    'is_hierarchy_override_enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateConnectionCommitmentRequest
 */
export interface UpdateConnectionCommitmentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionCommitmentRequest
     */
    'commitment'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionCommitmentRequest
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionCommitmentRequest
     */
    'ignore_reason'?: string;
}
/**
 * 
 * @export
 * @interface UpdateConnectionCredentialsRequest
 */
export interface UpdateConnectionCredentialsRequest {
    [key: string]: any;

    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof UpdateConnectionCredentialsRequest
     */
    'credentials': { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface UpdateConnectionDocumentRequest
 */
export interface UpdateConnectionDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionDocumentRequest
     */
    'document'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionDocumentRequest
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionDocumentRequest
     */
    'ignore_reason'?: string;
}
/**
 * 
 * @export
 * @interface UpdateConnectionInvestmentRequest
 */
export interface UpdateConnectionInvestmentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionInvestmentRequest
     */
    'investment'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionInvestmentRequest
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionInvestmentRequest
     */
    'ignore_reason'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionInvestmentRequest
     */
    'set_children_ignored'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionInvestmentRequest
     */
    'unmap_children'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionInvestmentRequest
     */
    'is_auto_import_enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionInvestmentRequest
     */
    'is_hierarchy_override_enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateConnectionRequest
 */
export interface UpdateConnectionRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionRequest
     */
    'name'?: string;
    /**
     * 
     * @type {any}
     * @memberof UpdateConnectionRequest
     */
    'status'?: UpdateConnectionRequestStatusEnum;
    /**
     * 
     * @type {any}
     * @memberof UpdateConnectionRequest
     */
    'ral_status'?: UpdateConnectionRequestRalStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionRequest
     */
    'is_enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionRequest
     */
    'long_running'?: boolean;
    /**
     * 
     * @type {any}
     * @memberof UpdateConnectionRequest
     */
    'outreach_status'?: UpdateConnectionRequestOutreachStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionRequest
     */
    'require_phone'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionRequest
     */
    'expect_documents_over_email'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionRequest
     */
    'use_tfa'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionRequest
     */
    'test'?: boolean;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof UpdateConnectionRequest
     */
    'data'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionRequest
     */
    'account'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateConnectionRequest
     */
    'account_list'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateConnectionRequest
     */
    'contacts'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateConnectionRequest
     */
    'ral_requests'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionRequest
     */
    'asset_manager'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionRequest
     */
    'integration'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionRequest
     */
    'last_connection_at'?: string;
    /**
     * 
     * @type {any}
     * @memberof UpdateConnectionRequest
     */
    'tfa_email'?: any;
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionRequest
     */
    'advisory_group'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateConnectionRequest
     */
    'tags'?: Array<string>;
}

export const UpdateConnectionRequestStatusEnum = {
    UnderReview: 'Under Review',
    Pending: 'Pending',
    Importing: 'Importing',
    Authenticating: 'Authenticating',
    Syncing: 'Syncing',
    Active: 'Active',
    Error: 'Error'
} as const;

export type UpdateConnectionRequestStatusEnum = typeof UpdateConnectionRequestStatusEnum[keyof typeof UpdateConnectionRequestStatusEnum];
export const UpdateConnectionRequestRalStatusEnum = {
    Send: 'Send',
    Resend: 'Resend',
    Received: 'Received',
    Approved: 'Approved'
} as const;

export type UpdateConnectionRequestRalStatusEnum = typeof UpdateConnectionRequestRalStatusEnum[keyof typeof UpdateConnectionRequestRalStatusEnum];
export const UpdateConnectionRequestOutreachStatusEnum = {
    ConnectionCreated: 'Connection Created',
    ReadyForAmToStart: 'Ready For AM To Start',
    AmUnableToSupport: 'AM Unable To Support',
    AmNeedsMoreInformation: 'AM Needs More Information',
    ConnectionSetupCompleteByAm: 'Connection Setup Complete By AM',
    PortalCredentialsCreated: 'Portal Credentials Created'
} as const;

export type UpdateConnectionRequestOutreachStatusEnum = typeof UpdateConnectionRequestOutreachStatusEnum[keyof typeof UpdateConnectionRequestOutreachStatusEnum];

/**
 * 
 * @export
 * @interface UpdateConnectionTransactionRequest
 */
export interface UpdateConnectionTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionTransactionRequest
     */
    'transaction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionTransactionRequest
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionTransactionRequest
     */
    'ignore_reason'?: string;
}
/**
 * 
 * @export
 * @interface UpdateConnectionUserRequest
 */
export interface UpdateConnectionUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionUserRequest
     */
    'user'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionUserRequest
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionUserRequest
     */
    'ignore_reason'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionUserRequest
     */
    'is_auto_import_enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateConnectionValuationRequest
 */
export interface UpdateConnectionValuationRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionValuationRequest
     */
    'valuation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionValuationRequest
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionValuationRequest
     */
    'ignore_reason'?: string;
}
/**
 * 
 * @export
 * @interface UpdateContactRequest
 */
export interface UpdateContactRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateContactRequest
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactRequest
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactRequest
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDefaultDocumentImportSettingRequest
 */
export interface UpdateDefaultDocumentImportSettingRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultDocumentImportSettingRequest
     */
    'integration'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultDocumentImportSettingRequest
     */
    'asset_manager'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultDocumentImportSettingRequest
     */
    'advisory_group'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultDocumentImportSettingRequest
     */
    'global_default_extraction_setting'?: UpdateDefaultDocumentImportSettingRequestGlobalDefaultExtractionSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultDocumentImportSettingRequest
     */
    'global_default_auto_sort_setting'?: UpdateDefaultDocumentImportSettingRequestGlobalDefaultAutoSortSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultDocumentImportSettingRequest
     */
    'global_default_transaction_setting'?: UpdateDefaultDocumentImportSettingRequestGlobalDefaultTransactionSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultDocumentImportSettingRequest
     */
    'global_default_process_setting'?: UpdateDefaultDocumentImportSettingRequestGlobalDefaultProcessSettingEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateDefaultDocumentImportSettingRequest
     */
    'default_transaction_settings'?: { [key: string]: string; };
}

export const UpdateDefaultDocumentImportSettingRequestGlobalDefaultExtractionSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type UpdateDefaultDocumentImportSettingRequestGlobalDefaultExtractionSettingEnum = typeof UpdateDefaultDocumentImportSettingRequestGlobalDefaultExtractionSettingEnum[keyof typeof UpdateDefaultDocumentImportSettingRequestGlobalDefaultExtractionSettingEnum];
export const UpdateDefaultDocumentImportSettingRequestGlobalDefaultAutoSortSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type UpdateDefaultDocumentImportSettingRequestGlobalDefaultAutoSortSettingEnum = typeof UpdateDefaultDocumentImportSettingRequestGlobalDefaultAutoSortSettingEnum[keyof typeof UpdateDefaultDocumentImportSettingRequestGlobalDefaultAutoSortSettingEnum];
export const UpdateDefaultDocumentImportSettingRequestGlobalDefaultTransactionSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type UpdateDefaultDocumentImportSettingRequestGlobalDefaultTransactionSettingEnum = typeof UpdateDefaultDocumentImportSettingRequestGlobalDefaultTransactionSettingEnum[keyof typeof UpdateDefaultDocumentImportSettingRequestGlobalDefaultTransactionSettingEnum];
export const UpdateDefaultDocumentImportSettingRequestGlobalDefaultProcessSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type UpdateDefaultDocumentImportSettingRequestGlobalDefaultProcessSettingEnum = typeof UpdateDefaultDocumentImportSettingRequestGlobalDefaultProcessSettingEnum[keyof typeof UpdateDefaultDocumentImportSettingRequestGlobalDefaultProcessSettingEnum];

/**
 * 
 * @export
 * @interface UpdateDocument200Response
 */
export interface UpdateDocument200Response {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDocument200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocument200Response
     */
    'metadata_error'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDocumentRequest
 */
export interface UpdateDocumentRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentRequest
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentRequest
     */
    'type'?: string | null;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerTraining}
     * @memberof UpdateDocumentRequest
     */
    'training'?: ListDocuments200ResponseDocumentsInnerTraining;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDocumentRequest
     */
    'shouldGenerateTransactions'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDocumentRequest
     */
    'shouldProcessDocument'?: boolean;
    /**
     * 
     * @type {Array<ListDocuments200ResponseDocumentsInnerMetadataInner>}
     * @memberof UpdateDocumentRequest
     */
    'metadata'?: Array<ListDocuments200ResponseDocumentsInnerMetadataInner>;
    /**
     * 
     * @type {Array<any>}
     * @memberof UpdateDocumentRequest
     */
    'ownership_ids'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentRequest
     */
    'ownership_type'?: UpdateDocumentRequestOwnershipTypeEnum | null;
    /**
     * 
     * @type {any}
     * @memberof UpdateDocumentRequest
     */
    'file_type'?: UpdateDocumentRequestFileTypeEnum;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerTransactionDescriptions}
     * @memberof UpdateDocumentRequest
     */
    'transaction_descriptions_diff'?: ListDocuments200ResponseDocumentsInnerTransactionDescriptions;
}

export const UpdateDocumentRequestOwnershipTypeEnum = {
    Account: 'Account',
    Investment: 'Investment',
    Null: 'null'
} as const;

export type UpdateDocumentRequestOwnershipTypeEnum = typeof UpdateDocumentRequestOwnershipTypeEnum[keyof typeof UpdateDocumentRequestOwnershipTypeEnum];
export const UpdateDocumentRequestFileTypeEnum = {
    Pdf: 'pdf',
    Csv: 'csv',
    Xlsx: 'xlsx',
    Xls: 'xls'
} as const;

export type UpdateDocumentRequestFileTypeEnum = typeof UpdateDocumentRequestFileTypeEnum[keyof typeof UpdateDocumentRequestFileTypeEnum];

/**
 * 
 * @export
 * @interface UpdateExchangeRequest
 */
export interface UpdateExchangeRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateExchangeRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateExchangeRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateExchangeRequest
     */
    'website'?: string;
    /**
     * 
     * @type {ListExchanges200ResponseExchangesInnerLocation}
     * @memberof UpdateExchangeRequest
     */
    'location'?: ListExchanges200ResponseExchangesInnerLocation;
    /**
     * 
     * @type {string}
     * @memberof UpdateExchangeRequest
     */
    'founded'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateExchangeRequest
     */
    'ticker_prefix'?: string;
}
/**
 * 
 * @export
 * @interface UpdateExtegrationConnectionRequest
 */
export interface UpdateExtegrationConnectionRequest {
    [key: string]: any;

    /**
     * 
     * @type {any}
     * @memberof UpdateExtegrationConnectionRequest
     */
    'enabled'?: any;
    /**
     * 
     * @type {Array<any>}
     * @memberof UpdateExtegrationConnectionRequest
     */
    'entities'?: Array<any>;
    /**
     * 
     * @type {any}
     * @memberof UpdateExtegrationConnectionRequest
     */
    'name'?: any;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof UpdateExtegrationConnectionRequest
     */
    'credentials'?: { [key: string]: any; };
    /**
     * 
     * @type {any}
     * @memberof UpdateExtegrationConnectionRequest
     */
    'sync_interval'?: UpdateExtegrationConnectionRequestSyncIntervalEnum;
    /**
     * 
     * @type {any}
     * @memberof UpdateExtegrationConnectionRequest
     */
    'extegration_type_name'?: any;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof UpdateExtegrationConnectionRequest
     */
    'user_account_map'?: { [key: string]: any; };
}

export const UpdateExtegrationConnectionRequestSyncIntervalEnum = {
    Null: 'null',
    Daily: 'daily',
    Weekdays: 'weekdays',
    Weekly: 'weekly',
    Monthly: 'monthly',
    Tetrahourly: 'tetrahourly'
} as const;

export type UpdateExtegrationConnectionRequestSyncIntervalEnum = typeof UpdateExtegrationConnectionRequestSyncIntervalEnum[keyof typeof UpdateExtegrationConnectionRequestSyncIntervalEnum];

/**
 * 
 * @export
 * @interface UpdateExtegrationSecretsRequest
 */
export interface UpdateExtegrationSecretsRequest {
    /**
     * 
     * @type {UpdateExtegrationSecretsRequestSecrets}
     * @memberof UpdateExtegrationSecretsRequest
     */
    'secrets': UpdateExtegrationSecretsRequestSecrets;
}
/**
 * 
 * @export
 * @interface UpdateExtegrationSecretsRequestSecrets
 */
export interface UpdateExtegrationSecretsRequestSecrets {
    /**
     * 
     * @type {string}
     * @memberof UpdateExtegrationSecretsRequestSecrets
     */
    'access_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateExtegrationSecretsRequestSecrets
     */
    'refresh_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateExtegrationSecretsRequestSecrets
     */
    'expiry'?: string;
}
/**
 * 
 * @export
 * @interface UpdateExternalIdRequest
 */
export interface UpdateExternalIdRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateExternalIdRequest
     */
    'internal_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateExternalIdRequest
     */
    'external_id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateExternalIdRequest
     */
    'object_type': UpdateExternalIdRequestObjectTypeEnum;
}

export const UpdateExternalIdRequestObjectTypeEnum = {
    Transaction: 'transaction',
    Investment: 'investment',
    Valuation: 'valuation',
    Account: 'account',
    Commitment: 'commitment',
    Document: 'document',
    User: 'user'
} as const;

export type UpdateExternalIdRequestObjectTypeEnum = typeof UpdateExternalIdRequestObjectTypeEnum[keyof typeof UpdateExternalIdRequestObjectTypeEnum];

/**
 * 
 * @export
 * @interface UpdateIntegrationRequest
 */
export interface UpdateIntegrationRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationRequest
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIntegrationRequest
     */
    'has_worker'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIntegrationRequest
     */
    'tfa_required'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIntegrationRequest
     */
    'phone_required'?: boolean;
    /**
     * 
     * @type {Array<ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner>}
     * @memberof UpdateIntegrationRequest
     */
    'required_creds'?: Array<ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner>;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationRequest
     */
    'portal_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationRequest
     */
    'lambda_arn'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof UpdateIntegrationRequest
     */
    'from_email_domains'?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof UpdateIntegrationRequest
     */
    'from_email_addresses'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationRequest
     */
    'cycle_container_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateIntegrationRequest
     */
    'worker_version'?: number;
}
/**
 * 
 * @export
 * @interface UpdateInvestmentMasterRequest
 */
export interface UpdateInvestmentMasterRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentMasterRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentMasterRequest
     */
    'ticker'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentMasterRequest
     */
    'asset_manager'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentMasterRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentMasterRequest
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentMasterRequest
     */
    'sub_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentMasterRequest
     */
    'website'?: string;
    /**
     * 
     * @type {ListInvestmentMasters200ResponseInvestmentMastersInnerSocials}
     * @memberof UpdateInvestmentMasterRequest
     */
    'socials'?: ListInvestmentMasters200ResponseInvestmentMastersInnerSocials;
    /**
     * 
     * @type {Array<UpdateAssetManagerRequestContactsInner>}
     * @memberof UpdateInvestmentMasterRequest
     */
    'contacts'?: Array<UpdateAssetManagerRequestContactsInner>;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentMasterRequest
     */
    'currency'?: UpdateInvestmentMasterRequestCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentMasterRequest
     */
    'country'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateInvestmentMasterRequest
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentMasterRequest
     */
    'exchange'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateInvestmentMasterRequest
     */
    'vintage_year'?: number;
    /**
     * 
     * @type {any}
     * @memberof UpdateInvestmentMasterRequest
     */
    'status'?: UpdateInvestmentMasterRequestStatusEnum;
}

export const UpdateInvestmentMasterRequestCurrencyEnum = {
    Usd: 'USD',
    Eur: 'EUR',
    Cny: 'CNY',
    Jpy: 'JPY',
    Gbp: 'GBP',
    Aud: 'AUD',
    Cad: 'CAD',
    Chf: 'CHF',
    Inr: 'INR',
    Krw: 'KRW',
    Btc: 'BTC'
} as const;

export type UpdateInvestmentMasterRequestCurrencyEnum = typeof UpdateInvestmentMasterRequestCurrencyEnum[keyof typeof UpdateInvestmentMasterRequestCurrencyEnum];
export const UpdateInvestmentMasterRequestStatusEnum = {
    Fundraising: 'fundraising',
    Investing: 'investing',
    Harvesting: 'harvesting',
    Liquidated: 'liquidated'
} as const;

export type UpdateInvestmentMasterRequestStatusEnum = typeof UpdateInvestmentMasterRequestStatusEnum[keyof typeof UpdateInvestmentMasterRequestStatusEnum];

/**
 * 
 * @export
 * @interface UpdateInvestmentRequest
 */
export interface UpdateInvestmentRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentRequest
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateInvestmentRequest
     */
    'expected_tax_documents'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateInvestmentRequest
     */
    'hidden'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateInvestmentRequest
     */
    'exclude_from_billing'?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateInvestmentRequest
     */
    'external_ids'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentRequest
     */
    'currency'?: UpdateInvestmentRequestCurrencyEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateInvestmentRequest
     */
    'attributes'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentRequest
     */
    'account'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentRequest
     */
    'connection'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentRequest
     */
    'investment_master'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateInvestmentRequest
     */
    'inactive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentRequest
     */
    'inactive_date'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof UpdateInvestmentRequest
     */
    'investment_data'?: { [key: string]: any; };
}

export const UpdateInvestmentRequestCurrencyEnum = {
    Usd: 'USD',
    Eur: 'EUR',
    Cny: 'CNY',
    Jpy: 'JPY',
    Gbp: 'GBP',
    Aud: 'AUD',
    Cad: 'CAD',
    Chf: 'CHF',
    Inr: 'INR',
    Krw: 'KRW',
    Btc: 'BTC'
} as const;

export type UpdateInvestmentRequestCurrencyEnum = typeof UpdateInvestmentRequestCurrencyEnum[keyof typeof UpdateInvestmentRequestCurrencyEnum];

/**
 * 
 * @export
 * @interface UpdateMessageRequest
 */
export interface UpdateMessageRequest {
    [key: string]: any;

    /**
     * 
     * @type {boolean}
     * @memberof UpdateMessageRequest
     */
    'completed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateMessageRequest
     */
    'read'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateMessageRequest
     */
    'flagged'?: boolean;
    /**
     * 
     * @type {any}
     * @memberof UpdateMessageRequest
     */
    'access'?: UpdateMessageRequestAccessEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateMessageRequest
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof UpdateMessageRequest
     */
    'category'?: UpdateMessageRequestCategoryEnum;
}

export const UpdateMessageRequestAccessEnum = {
    Investor: 'investor',
    Advisor: 'advisor',
    Admin: 'admin'
} as const;

export type UpdateMessageRequestAccessEnum = typeof UpdateMessageRequestAccessEnum[keyof typeof UpdateMessageRequestAccessEnum];
export const UpdateMessageRequestCategoryEnum = {
    Null: 'null',
    Tfa: 'tfa',
    Portal: 'portal',
    Deactivated: 'deactivated'
} as const;

export type UpdateMessageRequestCategoryEnum = typeof UpdateMessageRequestCategoryEnum[keyof typeof UpdateMessageRequestCategoryEnum];

/**
 * 
 * @export
 * @interface UpdateNoteRequest
 */
export interface UpdateNoteRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateNoteRequest
     */
    'body'?: string;
}
/**
 * 
 * @export
 * @interface UpdateRALRequestRequest
 */
export interface UpdateRALRequestRequest {
    [key: string]: any;

    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRALRequestRequest
     */
    'ownership_ids'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateRALRequestRequest
     */
    'ownership_type'?: UpdateRALRequestRequestOwnershipTypeEnum | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateRALRequestRequest
     */
    'tokens'?: { [key: string]: string; };
    /**
     * 
     * @type {Array<UpdateRALRequestRequestSignatoriesInner>}
     * @memberof UpdateRALRequestRequest
     */
    'signatories'?: Array<UpdateRALRequestRequestSignatoriesInner>;
    /**
     * 
     * @type {string}
     * @memberof UpdateRALRequestRequest
     */
    'remote_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRALRequestRequest
     */
    'ral_request_template'?: string | null;
}

export const UpdateRALRequestRequestOwnershipTypeEnum = {
    Account: 'Account',
    Investment: 'Investment',
    Null: 'null'
} as const;

export type UpdateRALRequestRequestOwnershipTypeEnum = typeof UpdateRALRequestRequestOwnershipTypeEnum[keyof typeof UpdateRALRequestRequestOwnershipTypeEnum];

/**
 * 
 * @export
 * @interface UpdateRALRequestRequestSignatoriesInner
 */
export interface UpdateRALRequestRequestSignatoriesInner {
    /**
     * 
     * @type {any}
     * @memberof UpdateRALRequestRequestSignatoriesInner
     */
    'first_name'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdateRALRequestRequestSignatoriesInner
     */
    'last_name'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdateRALRequestRequestSignatoriesInner
     */
    'role'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdateRALRequestRequestSignatoriesInner
     */
    'email'?: any;
}
/**
 * 
 * @export
 * @interface UpdateTransactionRequest
 */
export interface UpdateTransactionRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionRequest
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionRequest
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateTransactionRequest
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateTransactionRequest
     */
    'units'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTransactionRequest
     */
    'open'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionRequest
     */
    'joint_transaction'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionRequest
     */
    'document'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionRequest
     */
    'user'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'newPassword'?: string;
    /**
     * 
     * @type {any}
     * @memberof UpdateUserRequest
     */
    'status'?: UpdateUserRequestStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequest
     */
    'emailVerified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'advisory_group_id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequest
     */
    'is_deactivated'?: boolean;
    /**
     * 
     * @type {UpdateUserRequestNotificationSettings}
     * @memberof UpdateUserRequest
     */
    'notificationSettings'?: UpdateUserRequestNotificationSettings;
}

export const UpdateUserRequestStatusEnum = {
    Active: 'Active',
    Staging: 'Staging',
    Disabled: 'Disabled'
} as const;

export type UpdateUserRequestStatusEnum = typeof UpdateUserRequestStatusEnum[keyof typeof UpdateUserRequestStatusEnum];

/**
 * 
 * @export
 * @interface UpdateUserRequestNotificationSettings
 */
export interface UpdateUserRequestNotificationSettings {
    [key: string]: any;

    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequestNotificationSettings
     */
    'Capital Call'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequestNotificationSettings
     */
    'Distribution'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequestNotificationSettings
     */
    'Tax Document'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateValuationRequest
 */
export interface UpdateValuationRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateValuationRequest
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateValuationRequest
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateValuationRequest
     */
    'unit_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateValuationRequest
     */
    'investment'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateValuationRequest
     */
    'documents'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UploadAdvisoryGroupLogo200Response
 */
export interface UploadAdvisoryGroupLogo200Response {
    /**
     * 
     * @type {boolean}
     * @memberof UploadAdvisoryGroupLogo200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UploadAdvisoryGroupLogo200Response
     */
    'key'?: string;
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalAdvisorygroupsGet200Response
 */
export interface V2AssetmanagersPortalAdvisorygroupsGet200Response {
    /**
     * 
     * @type {Array<V2AssetmanagersPortalAdvisorygroupsGet200ResponseAdvisoryGroupsInner>}
     * @memberof V2AssetmanagersPortalAdvisorygroupsGet200Response
     */
    'advisory_groups'?: Array<V2AssetmanagersPortalAdvisorygroupsGet200ResponseAdvisoryGroupsInner>;
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalAdvisorygroupsGet200ResponseAdvisoryGroupsInner
 */
export interface V2AssetmanagersPortalAdvisorygroupsGet200ResponseAdvisoryGroupsInner {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalAdvisorygroupsGet200ResponseAdvisoryGroupsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalAdvisorygroupsGet200ResponseAdvisoryGroupsInner
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalConnectionsIdPatchRequest
 */
export interface V2AssetmanagersPortalConnectionsIdPatchRequest {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof V2AssetmanagersPortalConnectionsIdPatchRequest
     */
    'credentials'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalConnectionsIdPatchRequest
     */
    'outreach_status'?: V2AssetmanagersPortalConnectionsIdPatchRequestOutreachStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalConnectionsIdPatchRequest
     */
    'asset_manager_request'?: string;
}

export const V2AssetmanagersPortalConnectionsIdPatchRequestOutreachStatusEnum = {
    ReadyForAmToStart: 'Ready For AM To Start',
    AmUnableToSupport: 'AM Unable To Support',
    AmNeedsMoreInformation: 'AM Needs More Information',
    ConnectionSetupCompleteByAm: 'Connection Setup Complete By AM'
} as const;

export type V2AssetmanagersPortalConnectionsIdPatchRequestOutreachStatusEnum = typeof V2AssetmanagersPortalConnectionsIdPatchRequestOutreachStatusEnum[keyof typeof V2AssetmanagersPortalConnectionsIdPatchRequestOutreachStatusEnum];

/**
 * 
 * @export
 * @interface V2AssetmanagersPortalListGet200Response
 */
export interface V2AssetmanagersPortalListGet200Response {
    /**
     * 
     * @type {number}
     * @memberof V2AssetmanagersPortalListGet200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<V2AssetmanagersPortalListGet200ResponseConnectionsInner>}
     * @memberof V2AssetmanagersPortalListGet200Response
     */
    'connections'?: Array<V2AssetmanagersPortalListGet200ResponseConnectionsInner>;
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalListGet200ResponseConnectionsInner
 */
export interface V2AssetmanagersPortalListGet200ResponseConnectionsInner {
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'outreach_status'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'credentials'?: { [key: string]: any; };
    /**
     * 
     * @type {Array<V2AssetmanagersPortalListGet200ResponseConnectionsInnerRequiredCredsInner>}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'required_creds'?: Array<V2AssetmanagersPortalListGet200ResponseConnectionsInnerRequiredCredsInner>;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'tfa_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'tfa_phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'user_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'user_email'?: string;
    /**
     * 
     * @type {Array<V2AssetmanagersPortalListGet200ResponseConnectionsInnerAccountListInner>}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'account_list'?: Array<V2AssetmanagersPortalListGet200ResponseConnectionsInnerAccountListInner>;
    /**
     * 
     * @type {Array<V2AssetmanagersPortalListGet200ResponseConnectionsInnerAdvisoryGroupsInner>}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'advisory_groups'?: Array<V2AssetmanagersPortalListGet200ResponseConnectionsInnerAdvisoryGroupsInner>;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'asset_manager_request'?: string;
    /**
     * 
     * @type {Array<V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner>}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'ral_requests'?: Array<V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner>;
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalListGet200ResponseConnectionsInnerAccountListInner
 */
export interface V2AssetmanagersPortalListGet200ResponseConnectionsInnerAccountListInner {
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerAccountListInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerAccountListInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerAccountListInner
     */
    'signed_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerAccountListInner
     */
    'remote_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerAccountListInner
     */
    'reporting_auth_id'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerAccountListInner
     */
    'reporting_auth_urls'?: { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalListGet200ResponseConnectionsInnerAdvisoryGroupsInner
 */
export interface V2AssetmanagersPortalListGet200ResponseConnectionsInnerAdvisoryGroupsInner {
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerAdvisoryGroupsInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerAdvisoryGroupsInner
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
 */
export interface V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner {
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
     */
    'id'?: string;
    /**
     * 
     * @type {V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInnerSignedRal}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
     */
    'signed_ral'?: V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInnerSignedRal;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
     */
    'sent_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
     */
    'signed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
     */
    'remote_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
     */
    'ownership_type'?: string;
    /**
     * 
     * @type {Array<ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser>}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
     */
    'ownership_ids'?: Array<ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser>;
    /**
     * 
     * @type {ListAccounts200ResponseAccountsInnerUser}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
     */
    'user'?: ListAccounts200ResponseAccountsInnerUser;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
     */
    'reporting_auth_id'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
     */
    'reporting_auth_urls'?: { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInnerSignedRal
 */
export interface V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInnerSignedRal {
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInnerSignedRal
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInnerSignedRal
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInnerSignedRal
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInnerSignedRal
     */
    'updated_at'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInnerSignedRal
     */
    'files'?: { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalListGet200ResponseConnectionsInnerRequiredCredsInner
 */
export interface V2AssetmanagersPortalListGet200ResponseConnectionsInnerRequiredCredsInner {
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRequiredCredsInner
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRequiredCredsInner
     */
    'input'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRequiredCredsInner
     */
    'options'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRequiredCredsInner
     */
    'hint'?: string;
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalLoginPost200Response
 */
export interface V2AssetmanagersPortalLoginPost200Response {
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalLoginPost200Response
     */
    'auth_token'?: string;
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalLoginPostRequest
 */
export interface V2AssetmanagersPortalLoginPostRequest {
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalLoginPostRequest
     */
    'asset_manager_id': string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalLoginPostRequest
     */
    'asset_manager_passkey': string;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncCredentialsGet200Response
 */
export interface V2ConnectionsSyncCredentialsGet200Response {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof V2ConnectionsSyncCredentialsGet200Response
     */
    'credentials'?: { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncExistingGet200Response
 */
export interface V2ConnectionsSyncExistingGet200Response {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof V2ConnectionsSyncExistingGet200Response
     */
    'existing_data'?: { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncExistingGet400Response
 */
export interface V2ConnectionsSyncExistingGet400Response {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncExistingGet400Response
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdCompletePost412Response
 */
export interface V2ConnectionsSyncIdCompletePost412Response {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdCompletePost412Response
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdCompletePost412Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdCompletePostRequest
 */
export interface V2ConnectionsSyncIdCompletePostRequest {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdCompletePostRequest
     */
    'status': V2ConnectionsSyncIdCompletePostRequestStatusEnum;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof V2ConnectionsSyncIdCompletePostRequest
     */
    'job_response': { [key: string]: any; };
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof V2ConnectionsSyncIdCompletePostRequest
     */
    'error_report': { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdCompletePostRequest
     */
    'error_code'?: string;
}

export const V2ConnectionsSyncIdCompletePostRequestStatusEnum = {
    Success: 'success',
    Failure: 'failure',
    Pending: 'pending'
} as const;

export type V2ConnectionsSyncIdCompletePostRequestStatusEnum = typeof V2ConnectionsSyncIdCompletePostRequestStatusEnum[keyof typeof V2ConnectionsSyncIdCompletePostRequestStatusEnum];

/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportAccountsPostRequest
 */
export interface V2ConnectionsSyncIdImportAccountsPostRequest {
    /**
     * 
     * @type {Set<V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner>}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequest
     */
    'accounts': Set<V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner>;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
 */
export interface V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'custom_id': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'id_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'id_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'street_line1'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'street_line2'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'user_custom_id'?: string;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportCommitmentsPostRequest
 */
export interface V2ConnectionsSyncIdImportCommitmentsPostRequest {
    /**
     * 
     * @type {Set<V2ConnectionsSyncIdImportCommitmentsPostRequestCommitmentsInner>}
     * @memberof V2ConnectionsSyncIdImportCommitmentsPostRequest
     */
    'commitments': Set<V2ConnectionsSyncIdImportCommitmentsPostRequestCommitmentsInner>;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportCommitmentsPostRequestCommitmentsInner
 */
export interface V2ConnectionsSyncIdImportCommitmentsPostRequestCommitmentsInner {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportCommitmentsPostRequestCommitmentsInner
     */
    'custom_id': string;
    /**
     * 
     * @type {number}
     * @memberof V2ConnectionsSyncIdImportCommitmentsPostRequestCommitmentsInner
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportCommitmentsPostRequestCommitmentsInner
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportCommitmentsPostRequestCommitmentsInner
     */
    'investment_custom_id'?: string;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportDocumentsPost200Response
 */
export interface V2ConnectionsSyncIdImportDocumentsPost200Response {
    /**
     * 
     * @type {boolean}
     * @memberof V2ConnectionsSyncIdImportDocumentsPost200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<V2ConnectionsSyncIdImportDocumentsPost200ResponseUploadUrlsInner>}
     * @memberof V2ConnectionsSyncIdImportDocumentsPost200Response
     */
    'upload_urls'?: Array<V2ConnectionsSyncIdImportDocumentsPost200ResponseUploadUrlsInner>;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportDocumentsPost200ResponseUploadUrlsInner
 */
export interface V2ConnectionsSyncIdImportDocumentsPost200ResponseUploadUrlsInner {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportDocumentsPost200ResponseUploadUrlsInner
     */
    'custom_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportDocumentsPost200ResponseUploadUrlsInner
     */
    'hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportDocumentsPost200ResponseUploadUrlsInner
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportDocumentsPostRequest
 */
export interface V2ConnectionsSyncIdImportDocumentsPostRequest {
    /**
     * 
     * @type {Set<V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner>}
     * @memberof V2ConnectionsSyncIdImportDocumentsPostRequest
     */
    'documents': Set<V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner>;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner
 */
export interface V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner
     */
    'custom_id': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner
     */
    'hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner
     */
    'investment_custom_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner
     */
    'account_custom_id'?: string;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportInvestmentsPostRequest
 */
export interface V2ConnectionsSyncIdImportInvestmentsPostRequest {
    /**
     * 
     * @type {Set<V2ConnectionsSyncIdImportInvestmentsPostRequestInvestmentsInner>}
     * @memberof V2ConnectionsSyncIdImportInvestmentsPostRequest
     */
    'investments': Set<V2ConnectionsSyncIdImportInvestmentsPostRequestInvestmentsInner>;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportInvestmentsPostRequestInvestmentsInner
 */
export interface V2ConnectionsSyncIdImportInvestmentsPostRequestInvestmentsInner {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportInvestmentsPostRequestInvestmentsInner
     */
    'custom_id': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportInvestmentsPostRequestInvestmentsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportInvestmentsPostRequestInvestmentsInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportInvestmentsPostRequestInvestmentsInner
     */
    'subtype'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportInvestmentsPostRequestInvestmentsInner
     */
    'account_custom_id'?: string;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportTransactionsPostRequest
 */
export interface V2ConnectionsSyncIdImportTransactionsPostRequest {
    /**
     * 
     * @type {Set<V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner>}
     * @memberof V2ConnectionsSyncIdImportTransactionsPostRequest
     */
    'transactions': Set<V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner>;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner
 */
export interface V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner
     */
    'custom_id': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner
     */
    'type': V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInnerTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner
     */
    'joint_transaction_custom_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner
     */
    'investment_custom_id': string;
}

export const V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInnerTypeEnum = {
    Buy: 'buy',
    Sale: 'sale',
    CapitalCall: 'capital_call',
    CashDistributionReturnOfCapital: 'cash_distribution_return_of_capital',
    CapitalCommitment: 'capital_commitment',
    RecallableReturnOfCapital: 'recallable_return_of_capital',
    RecallableReturnOnCapital: 'recallable_return_on_capital',
    ReturnOfCapital: 'return_of_capital',
    ReturnOnCapital: 'return_on_capital',
    InterestIncome: 'interest_income',
    InterestExpense: 'interest_expense',
    IncentiveFee: 'incentive_fee',
    Redemption: 'redemption',
    Subscription: 'subscription',
    ValuationChange: 'valuation_change',
    CashDeposit: 'cash_deposit',
    CashWithdrawal: 'cash_withdrawal',
    Reinvestment: 'reinvestment',
    Dividend: 'dividend',
    DividendReversal: 'dividend_reversal',
    ManagementFee: 'management_fee',
    ManagementFeeRebate: 'management_fee_rebate',
    OtherFee: 'other_fee',
    TransferIn: 'transfer_in',
    TransferOut: 'transfer_out',
    TaxDistribution: 'tax_distribution',
    Other: 'other',
    JointTransaction: 'joint_transaction'
} as const;

export type V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInnerTypeEnum = typeof V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInnerTypeEnum[keyof typeof V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInnerTypeEnum];

/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportUsersPostRequest
 */
export interface V2ConnectionsSyncIdImportUsersPostRequest {
    /**
     * 
     * @type {Set<V2ConnectionsSyncIdImportUsersPostRequestUsersInner>}
     * @memberof V2ConnectionsSyncIdImportUsersPostRequest
     */
    'users': Set<V2ConnectionsSyncIdImportUsersPostRequestUsersInner>;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportUsersPostRequestUsersInner
 */
export interface V2ConnectionsSyncIdImportUsersPostRequestUsersInner {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportUsersPostRequestUsersInner
     */
    'custom_id': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportUsersPostRequestUsersInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportUsersPostRequestUsersInner
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportValuationsPostRequest
 */
export interface V2ConnectionsSyncIdImportValuationsPostRequest {
    /**
     * 
     * @type {Set<V2ConnectionsSyncIdImportValuationsPostRequestValuationsInner>}
     * @memberof V2ConnectionsSyncIdImportValuationsPostRequest
     */
    'valuations': Set<V2ConnectionsSyncIdImportValuationsPostRequestValuationsInner>;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportValuationsPostRequestValuationsInner
 */
export interface V2ConnectionsSyncIdImportValuationsPostRequestValuationsInner {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportValuationsPostRequestValuationsInner
     */
    'custom_id': string;
    /**
     * 
     * @type {number}
     * @memberof V2ConnectionsSyncIdImportValuationsPostRequestValuationsInner
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportValuationsPostRequestValuationsInner
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportValuationsPostRequestValuationsInner
     */
    'investment_custom_id': string;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncMessagesGet200Response
 */
export interface V2ConnectionsSyncMessagesGet200Response {
    /**
     * 
     * @type {Array<V2ConnectionsSyncMessagesGet200ResponseMessagesInner>}
     * @memberof V2ConnectionsSyncMessagesGet200Response
     */
    'messages'?: Array<V2ConnectionsSyncMessagesGet200ResponseMessagesInner>;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncMessagesGet200ResponseMessagesInner
 */
export interface V2ConnectionsSyncMessagesGet200ResponseMessagesInner {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncMessagesGet200ResponseMessagesInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncMessagesGet200ResponseMessagesInner
     */
    'type'?: V2ConnectionsSyncMessagesGet200ResponseMessagesInnerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncMessagesGet200ResponseMessagesInner
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncMessagesGet200ResponseMessagesInner
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncMessagesGet200ResponseMessagesInner
     */
    'body'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof V2ConnectionsSyncMessagesGet200ResponseMessagesInner
     */
    'parsed'?: boolean;
}

export const V2ConnectionsSyncMessagesGet200ResponseMessagesInnerTypeEnum = {
    Email: 'email',
    Sms: 'sms',
    Manual: 'manual'
} as const;

export type V2ConnectionsSyncMessagesGet200ResponseMessagesInnerTypeEnum = typeof V2ConnectionsSyncMessagesGet200ResponseMessagesInnerTypeEnum[keyof typeof V2ConnectionsSyncMessagesGet200ResponseMessagesInnerTypeEnum];

/**
 * 
 * @export
 * @interface VerifyRequest
 */
export interface VerifyRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof VerifyRequest
     */
    'key': string;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Account document.
         * @param {CreateAccountRequest} CreateAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount: async (CreateAccountRequest: CreateAccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateAccountRequest' is not null or undefined
            assertParamExists('createAccount', 'CreateAccountRequest', CreateAccountRequest)
            const localVarPath = `/v2/accounts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an Account document.
         * @param {string} account_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount: async (account_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'account_id' is not null or undefined
            assertParamExists('deleteAccount', 'account_id', account_id)
            const localVarPath = `/v2/accounts/{account_id}`
                .replace(`{${"account_id"}}`, encodeURIComponent(String(account_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single Account document by ID.
         * @param {string | null} account_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_investments_count] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: async (account_id: string | null, populate_user?: boolean, populate_investments_count?: boolean, populate_audit_log?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'account_id' is not null or undefined
            assertParamExists('getAccount', 'account_id', account_id)
            const localVarPath = `/v2/accounts/{account_id}`
                .replace(`{${"account_id"}}`, encodeURIComponent(String(account_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_investments_count !== undefined) {
                localVarQueryParameter['populate_investments_count'] = populate_investments_count;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Account documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListAccountsSortFieldEnum} [sort_field] 
         * @param {ListAccountsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_investments_count] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccounts: async (skip?: number, limit?: number, search?: string, sort_field?: ListAccountsSortFieldEnum, sort_direction?: ListAccountsSortDirectionEnum, populate_user?: boolean, populate_investments_count?: boolean, users?: Array<string>, accounts?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/accounts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_investments_count !== undefined) {
                localVarQueryParameter['populate_investments_count'] = populate_investments_count;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (accounts) {
                localVarQueryParameter['accounts'] = accounts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an Account document.
         * @param {string} account_id 
         * @param {UpdateAccountRequest} [UpdateAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount: async (account_id: string, UpdateAccountRequest?: UpdateAccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'account_id' is not null or undefined
            assertParamExists('updateAccount', 'account_id', account_id)
            const localVarPath = `/v2/accounts/{account_id}`
                .replace(`{${"account_id"}}`, encodeURIComponent(String(account_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Account document.
         * @param {CreateAccountRequest} CreateAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccount(CreateAccountRequest: CreateAccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAccount201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccount(CreateAccountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.createAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an Account document.
         * @param {string} account_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccount(account_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccount(account_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.deleteAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single Account document by ID.
         * @param {string | null} account_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_investments_count] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccount(account_id: string | null, populate_user?: boolean, populate_investments_count?: boolean, populate_audit_log?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccount(account_id, populate_user, populate_investments_count, populate_audit_log, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.getAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Account documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListAccountsSortFieldEnum} [sort_field] 
         * @param {ListAccountsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_investments_count] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAccounts(skip?: number, limit?: number, search?: string, sort_field?: ListAccountsSortFieldEnum, sort_direction?: ListAccountsSortDirectionEnum, populate_user?: boolean, populate_investments_count?: boolean, users?: Array<string>, accounts?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAccounts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAccounts(skip, limit, search, sort_field, sort_direction, populate_user, populate_investments_count, users, accounts, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.listAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an Account document.
         * @param {string} account_id 
         * @param {UpdateAccountRequest} [UpdateAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccount(account_id: string, UpdateAccountRequest?: UpdateAccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccount(account_id, UpdateAccountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.updateAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * Create a new Account document.
         * @param {AccountApiCreateAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(requestParameters: AccountApiCreateAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateAccount201Response> {
            return localVarFp.createAccount(requestParameters.CreateAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an Account document.
         * @param {AccountApiDeleteAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(requestParameters: AccountApiDeleteAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteAccount(requestParameters.account_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single Account document by ID.
         * @param {AccountApiGetAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(requestParameters: AccountApiGetAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetAccount200Response> {
            return localVarFp.getAccount(requestParameters.account_id, requestParameters.populate_user, requestParameters.populate_investments_count, requestParameters.populate_audit_log, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Account documents.
         * @param {AccountApiListAccountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccounts(requestParameters: AccountApiListAccountsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListAccounts200Response> {
            return localVarFp.listAccounts(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.populate_user, requestParameters.populate_investments_count, requestParameters.users, requestParameters.accounts, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an Account document.
         * @param {AccountApiUpdateAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(requestParameters: AccountApiUpdateAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateAccount(requestParameters.account_id, requestParameters.UpdateAccountRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAccount operation in AccountApi.
 * @export
 * @interface AccountApiCreateAccountRequest
 */
export interface AccountApiCreateAccountRequest {
    /**
     * 
     * @type {CreateAccountRequest}
     * @memberof AccountApiCreateAccount
     */
    readonly CreateAccountRequest: CreateAccountRequest
}

/**
 * Request parameters for deleteAccount operation in AccountApi.
 * @export
 * @interface AccountApiDeleteAccountRequest
 */
export interface AccountApiDeleteAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountApiDeleteAccount
     */
    readonly account_id: string
}

/**
 * Request parameters for getAccount operation in AccountApi.
 * @export
 * @interface AccountApiGetAccountRequest
 */
export interface AccountApiGetAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountApiGetAccount
     */
    readonly account_id: string | null

    /**
     * 
     * @type {boolean}
     * @memberof AccountApiGetAccount
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AccountApiGetAccount
     */
    readonly populate_investments_count?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AccountApiGetAccount
     */
    readonly populate_audit_log?: boolean
}

/**
 * Request parameters for listAccounts operation in AccountApi.
 * @export
 * @interface AccountApiListAccountsRequest
 */
export interface AccountApiListAccountsRequest {
    /**
     * 
     * @type {number}
     * @memberof AccountApiListAccounts
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AccountApiListAccounts
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof AccountApiListAccounts
     */
    readonly search?: string

    /**
     * 
     * @type {'created_at' | 'name' | 'account_type'}
     * @memberof AccountApiListAccounts
     */
    readonly sort_field?: ListAccountsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof AccountApiListAccounts
     */
    readonly sort_direction?: ListAccountsSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof AccountApiListAccounts
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AccountApiListAccounts
     */
    readonly populate_investments_count?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof AccountApiListAccounts
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AccountApiListAccounts
     */
    readonly accounts?: Array<string>
}

/**
 * Request parameters for updateAccount operation in AccountApi.
 * @export
 * @interface AccountApiUpdateAccountRequest
 */
export interface AccountApiUpdateAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountApiUpdateAccount
     */
    readonly account_id: string

    /**
     * 
     * @type {UpdateAccountRequest}
     * @memberof AccountApiUpdateAccount
     */
    readonly UpdateAccountRequest?: UpdateAccountRequest
}

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * Create a new Account document.
     * @param {AccountApiCreateAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public createAccount(requestParameters: AccountApiCreateAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).createAccount(requestParameters.CreateAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an Account document.
     * @param {AccountApiDeleteAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public deleteAccount(requestParameters: AccountApiDeleteAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).deleteAccount(requestParameters.account_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single Account document by ID.
     * @param {AccountApiGetAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccount(requestParameters: AccountApiGetAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).getAccount(requestParameters.account_id, requestParameters.populate_user, requestParameters.populate_investments_count, requestParameters.populate_audit_log, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Account documents.
     * @param {AccountApiListAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public listAccounts(requestParameters: AccountApiListAccountsRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).listAccounts(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.populate_user, requestParameters.populate_investments_count, requestParameters.users, requestParameters.accounts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an Account document.
     * @param {AccountApiUpdateAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateAccount(requestParameters: AccountApiUpdateAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateAccount(requestParameters.account_id, requestParameters.UpdateAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListAccountsSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name',
    AccountType: 'account_type'
} as const;
export type ListAccountsSortFieldEnum = typeof ListAccountsSortFieldEnum[keyof typeof ListAccountsSortFieldEnum];
/**
 * @export
 */
export const ListAccountsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListAccountsSortDirectionEnum = typeof ListAccountsSortDirectionEnum[keyof typeof ListAccountsSortDirectionEnum];


/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get counts of various activities within a specified time range.
         * @param {string} start_date 
         * @param {string} end_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityCounts: async (start_date: string, end_date: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start_date' is not null or undefined
            assertParamExists('getActivityCounts', 'start_date', start_date)
            // verify required parameter 'end_date' is not null or undefined
            assertParamExists('getActivityCounts', 'end_date', end_date)
            const localVarPath = `/v2/admin/activity_counts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = (start_date as any instanceof Date) ?
                    (start_date as any).toISOString() :
                    start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = (end_date as any instanceof Date) ?
                    (end_date as any).toISOString() :
                    end_date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * Get counts of various activities within a specified time range.
         * @param {string} start_date 
         * @param {string} end_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityCounts(start_date: string, end_date: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetActivityCounts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityCounts(start_date, end_date, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.getActivityCounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * Get counts of various activities within a specified time range.
         * @param {AdminApiGetActivityCountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityCounts(requestParameters: AdminApiGetActivityCountsRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetActivityCounts200Response> {
            return localVarFp.getActivityCounts(requestParameters.start_date, requestParameters.end_date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getActivityCounts operation in AdminApi.
 * @export
 * @interface AdminApiGetActivityCountsRequest
 */
export interface AdminApiGetActivityCountsRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiGetActivityCounts
     */
    readonly start_date: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetActivityCounts
     */
    readonly end_date: string
}

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * Get counts of various activities within a specified time range.
     * @param {AdminApiGetActivityCountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getActivityCounts(requestParameters: AdminApiGetActivityCountsRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).getActivityCounts(requestParameters.start_date, requestParameters.end_date, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminTeamApi - axios parameter creator
 * @export
 */
export const AdminTeamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Admin Team.
         * @param {CreateAdminTeamRequest} CreateAdminTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminTeam: async (CreateAdminTeamRequest: CreateAdminTeamRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateAdminTeamRequest' is not null or undefined
            assertParamExists('createAdminTeam', 'CreateAdminTeamRequest', CreateAdminTeamRequest)
            const localVarPath = `/v2/adminteams/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateAdminTeamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an Admin Team document.
         * @param {string} admin_team_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminTeam: async (admin_team_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'admin_team_id' is not null or undefined
            assertParamExists('deleteAdminTeam', 'admin_team_id', admin_team_id)
            const localVarPath = `/v2/adminteams/{admin_team_id}`
                .replace(`{${"admin_team_id"}}`, encodeURIComponent(String(admin_team_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single Admin Team document by ID.
         * @param {string | null} admin_team_id 
         * @param {boolean} [populate_advisory_groups] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeam: async (admin_team_id: string | null, populate_advisory_groups?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'admin_team_id' is not null or undefined
            assertParamExists('getAdminTeam', 'admin_team_id', admin_team_id)
            const localVarPath = `/v2/adminteams/{admin_team_id}`
                .replace(`{${"admin_team_id"}}`, encodeURIComponent(String(admin_team_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_advisory_groups !== undefined) {
                localVarQueryParameter['populate_advisory_groups'] = populate_advisory_groups;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Admin Team documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListAdminTeamsSortFieldEnum} [sort_field] 
         * @param {ListAdminTeamsSortDirectionEnum} [sort_direction] 
         * @param {string} [name] 
         * @param {ListAdminTeamsTypeEnum} [type] 
         * @param {Array<string>} [advisory_groups] 
         * @param {boolean} [populate_advisory_groups] 
         * @param {Array<string>} [admin_teams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdminTeams: async (skip?: number, limit?: number, sort_field?: ListAdminTeamsSortFieldEnum, sort_direction?: ListAdminTeamsSortDirectionEnum, name?: string, type?: ListAdminTeamsTypeEnum, advisory_groups?: Array<string>, populate_advisory_groups?: boolean, admin_teams?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/adminteams/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (advisory_groups) {
                localVarQueryParameter['advisory_groups'] = advisory_groups;
            }

            if (populate_advisory_groups !== undefined) {
                localVarQueryParameter['populate_advisory_groups'] = populate_advisory_groups;
            }

            if (admin_teams) {
                localVarQueryParameter['admin_teams'] = admin_teams;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Admin Team document.
         * @param {string} admin_team_id 
         * @param {UpdateAdminTeamRequest} [UpdateAdminTeamRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminTeam: async (admin_team_id: string, UpdateAdminTeamRequest?: UpdateAdminTeamRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'admin_team_id' is not null or undefined
            assertParamExists('updateAdminTeam', 'admin_team_id', admin_team_id)
            const localVarPath = `/v2/adminteams/{admin_team_id}`
                .replace(`{${"admin_team_id"}}`, encodeURIComponent(String(admin_team_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateAdminTeamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminTeamApi - functional programming interface
 * @export
 */
export const AdminTeamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminTeamApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Admin Team.
         * @param {CreateAdminTeamRequest} CreateAdminTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdminTeam(CreateAdminTeamRequest: CreateAdminTeamRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAdminTeam201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdminTeam(CreateAdminTeamRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminTeamApi.createAdminTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an Admin Team document.
         * @param {string} admin_team_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminTeam(admin_team_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminTeam(admin_team_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminTeamApi.deleteAdminTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single Admin Team document by ID.
         * @param {string | null} admin_team_id 
         * @param {boolean} [populate_advisory_groups] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminTeam(admin_team_id: string | null, populate_advisory_groups?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdminTeam200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminTeam(admin_team_id, populate_advisory_groups, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminTeamApi.getAdminTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Admin Team documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListAdminTeamsSortFieldEnum} [sort_field] 
         * @param {ListAdminTeamsSortDirectionEnum} [sort_direction] 
         * @param {string} [name] 
         * @param {ListAdminTeamsTypeEnum} [type] 
         * @param {Array<string>} [advisory_groups] 
         * @param {boolean} [populate_advisory_groups] 
         * @param {Array<string>} [admin_teams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAdminTeams(skip?: number, limit?: number, sort_field?: ListAdminTeamsSortFieldEnum, sort_direction?: ListAdminTeamsSortDirectionEnum, name?: string, type?: ListAdminTeamsTypeEnum, advisory_groups?: Array<string>, populate_advisory_groups?: boolean, admin_teams?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAdminTeams200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAdminTeams(skip, limit, sort_field, sort_direction, name, type, advisory_groups, populate_advisory_groups, admin_teams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminTeamApi.listAdminTeams']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing Admin Team document.
         * @param {string} admin_team_id 
         * @param {UpdateAdminTeamRequest} [UpdateAdminTeamRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminTeam(admin_team_id: string, UpdateAdminTeamRequest?: UpdateAdminTeamRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminTeam(admin_team_id, UpdateAdminTeamRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminTeamApi.updateAdminTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminTeamApi - factory interface
 * @export
 */
export const AdminTeamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminTeamApiFp(configuration)
    return {
        /**
         * Create a new Admin Team.
         * @param {AdminTeamApiCreateAdminTeamRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminTeam(requestParameters: AdminTeamApiCreateAdminTeamRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateAdminTeam201Response> {
            return localVarFp.createAdminTeam(requestParameters.CreateAdminTeamRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an Admin Team document.
         * @param {AdminTeamApiDeleteAdminTeamRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminTeam(requestParameters: AdminTeamApiDeleteAdminTeamRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteAdminTeam(requestParameters.admin_team_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single Admin Team document by ID.
         * @param {AdminTeamApiGetAdminTeamRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeam(requestParameters: AdminTeamApiGetAdminTeamRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetAdminTeam200Response> {
            return localVarFp.getAdminTeam(requestParameters.admin_team_id, requestParameters.populate_advisory_groups, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Admin Team documents.
         * @param {AdminTeamApiListAdminTeamsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdminTeams(requestParameters: AdminTeamApiListAdminTeamsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListAdminTeams200Response> {
            return localVarFp.listAdminTeams(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.name, requestParameters.type, requestParameters.advisory_groups, requestParameters.populate_advisory_groups, requestParameters.admin_teams, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Admin Team document.
         * @param {AdminTeamApiUpdateAdminTeamRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminTeam(requestParameters: AdminTeamApiUpdateAdminTeamRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateAdminTeam(requestParameters.admin_team_id, requestParameters.UpdateAdminTeamRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAdminTeam operation in AdminTeamApi.
 * @export
 * @interface AdminTeamApiCreateAdminTeamRequest
 */
export interface AdminTeamApiCreateAdminTeamRequest {
    /**
     * 
     * @type {CreateAdminTeamRequest}
     * @memberof AdminTeamApiCreateAdminTeam
     */
    readonly CreateAdminTeamRequest: CreateAdminTeamRequest
}

/**
 * Request parameters for deleteAdminTeam operation in AdminTeamApi.
 * @export
 * @interface AdminTeamApiDeleteAdminTeamRequest
 */
export interface AdminTeamApiDeleteAdminTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminTeamApiDeleteAdminTeam
     */
    readonly admin_team_id: string
}

/**
 * Request parameters for getAdminTeam operation in AdminTeamApi.
 * @export
 * @interface AdminTeamApiGetAdminTeamRequest
 */
export interface AdminTeamApiGetAdminTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminTeamApiGetAdminTeam
     */
    readonly admin_team_id: string | null

    /**
     * 
     * @type {boolean}
     * @memberof AdminTeamApiGetAdminTeam
     */
    readonly populate_advisory_groups?: boolean
}

/**
 * Request parameters for listAdminTeams operation in AdminTeamApi.
 * @export
 * @interface AdminTeamApiListAdminTeamsRequest
 */
export interface AdminTeamApiListAdminTeamsRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminTeamApiListAdminTeams
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AdminTeamApiListAdminTeams
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'updated_at' | 'name' | 'type'}
     * @memberof AdminTeamApiListAdminTeams
     */
    readonly sort_field?: ListAdminTeamsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof AdminTeamApiListAdminTeams
     */
    readonly sort_direction?: ListAdminTeamsSortDirectionEnum

    /**
     * 
     * @type {string}
     * @memberof AdminTeamApiListAdminTeams
     */
    readonly name?: string

    /**
     * 
     * @type {'account_management' | 'onboarding'}
     * @memberof AdminTeamApiListAdminTeams
     */
    readonly type?: ListAdminTeamsTypeEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof AdminTeamApiListAdminTeams
     */
    readonly advisory_groups?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof AdminTeamApiListAdminTeams
     */
    readonly populate_advisory_groups?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof AdminTeamApiListAdminTeams
     */
    readonly admin_teams?: Array<string>
}

/**
 * Request parameters for updateAdminTeam operation in AdminTeamApi.
 * @export
 * @interface AdminTeamApiUpdateAdminTeamRequest
 */
export interface AdminTeamApiUpdateAdminTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminTeamApiUpdateAdminTeam
     */
    readonly admin_team_id: string

    /**
     * 
     * @type {UpdateAdminTeamRequest}
     * @memberof AdminTeamApiUpdateAdminTeam
     */
    readonly UpdateAdminTeamRequest?: UpdateAdminTeamRequest
}

/**
 * AdminTeamApi - object-oriented interface
 * @export
 * @class AdminTeamApi
 * @extends {BaseAPI}
 */
export class AdminTeamApi extends BaseAPI {
    /**
     * Create a new Admin Team.
     * @param {AdminTeamApiCreateAdminTeamRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamApi
     */
    public createAdminTeam(requestParameters: AdminTeamApiCreateAdminTeamRequest, options?: RawAxiosRequestConfig) {
        return AdminTeamApiFp(this.configuration).createAdminTeam(requestParameters.CreateAdminTeamRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an Admin Team document.
     * @param {AdminTeamApiDeleteAdminTeamRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamApi
     */
    public deleteAdminTeam(requestParameters: AdminTeamApiDeleteAdminTeamRequest, options?: RawAxiosRequestConfig) {
        return AdminTeamApiFp(this.configuration).deleteAdminTeam(requestParameters.admin_team_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single Admin Team document by ID.
     * @param {AdminTeamApiGetAdminTeamRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamApi
     */
    public getAdminTeam(requestParameters: AdminTeamApiGetAdminTeamRequest, options?: RawAxiosRequestConfig) {
        return AdminTeamApiFp(this.configuration).getAdminTeam(requestParameters.admin_team_id, requestParameters.populate_advisory_groups, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Admin Team documents.
     * @param {AdminTeamApiListAdminTeamsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamApi
     */
    public listAdminTeams(requestParameters: AdminTeamApiListAdminTeamsRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminTeamApiFp(this.configuration).listAdminTeams(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.name, requestParameters.type, requestParameters.advisory_groups, requestParameters.populate_advisory_groups, requestParameters.admin_teams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Admin Team document.
     * @param {AdminTeamApiUpdateAdminTeamRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamApi
     */
    public updateAdminTeam(requestParameters: AdminTeamApiUpdateAdminTeamRequest, options?: RawAxiosRequestConfig) {
        return AdminTeamApiFp(this.configuration).updateAdminTeam(requestParameters.admin_team_id, requestParameters.UpdateAdminTeamRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListAdminTeamsSortFieldEnum = {
    CreatedAt: 'created_at',
    UpdatedAt: 'updated_at',
    Name: 'name',
    Type: 'type'
} as const;
export type ListAdminTeamsSortFieldEnum = typeof ListAdminTeamsSortFieldEnum[keyof typeof ListAdminTeamsSortFieldEnum];
/**
 * @export
 */
export const ListAdminTeamsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListAdminTeamsSortDirectionEnum = typeof ListAdminTeamsSortDirectionEnum[keyof typeof ListAdminTeamsSortDirectionEnum];
/**
 * @export
 */
export const ListAdminTeamsTypeEnum = {
    AccountManagement: 'account_management',
    Onboarding: 'onboarding'
} as const;
export type ListAdminTeamsTypeEnum = typeof ListAdminTeamsTypeEnum[keyof typeof ListAdminTeamsTypeEnum];


/**
 * AdvisoryGroupApi - axios parameter creator
 * @export
 */
export const AdvisoryGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add an advisor to an advisory group
         * @param {string} advisorygroup_id 
         * @param {AddAdvisorToGroupRequest} AddAdvisorToGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdvisorToGroup: async (advisorygroup_id: string, AddAdvisorToGroupRequest: AddAdvisorToGroupRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advisorygroup_id' is not null or undefined
            assertParamExists('addAdvisorToGroup', 'advisorygroup_id', advisorygroup_id)
            // verify required parameter 'AddAdvisorToGroupRequest' is not null or undefined
            assertParamExists('addAdvisorToGroup', 'AddAdvisorToGroupRequest', AddAdvisorToGroupRequest)
            const localVarPath = `/v2/advisorygroups/{advisorygroup_id}/advisors/`
                .replace(`{${"advisorygroup_id"}}`, encodeURIComponent(String(advisorygroup_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(AddAdvisorToGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new Advisory Group document.
         * @param {CreateAdvisoryGroupRequest} CreateAdvisoryGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdvisoryGroup: async (CreateAdvisoryGroupRequest: CreateAdvisoryGroupRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateAdvisoryGroupRequest' is not null or undefined
            assertParamExists('createAdvisoryGroup', 'CreateAdvisoryGroupRequest', CreateAdvisoryGroupRequest)
            const localVarPath = `/v2/advisorygroups/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateAdvisoryGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an Advisory Group document.
         * @param {string | null} group_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdvisoryGroup: async (group_id: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'group_id' is not null or undefined
            assertParamExists('deleteAdvisoryGroup', 'group_id', group_id)
            const localVarPath = `/v2/advisorygroups/{group_id}`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(group_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an advisory group by its ID
         * @param {string | null} advisory_group_id 
         * @param {boolean} [populate_logo_urls] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvisoryGroupById: async (advisory_group_id: string | null, populate_logo_urls?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advisory_group_id' is not null or undefined
            assertParamExists('getAdvisoryGroupById', 'advisory_group_id', advisory_group_id)
            const localVarPath = `/v2/advisorygroups/{advisory_group_id}`
                .replace(`{${"advisory_group_id"}}`, encodeURIComponent(String(advisory_group_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_logo_urls !== undefined) {
                localVarQueryParameter['populate_logo_urls'] = populate_logo_urls;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List advisors in an advisory group
         * @param {string} advisorygroup_id 
         * @param {boolean} [populate_clients] 
         * @param {boolean} [populate_aum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdvisorsInGroup: async (advisorygroup_id: string, populate_clients?: boolean, populate_aum?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advisorygroup_id' is not null or undefined
            assertParamExists('listAdvisorsInGroup', 'advisorygroup_id', advisorygroup_id)
            const localVarPath = `/v2/advisorygroups/{advisorygroup_id}/advisors/`
                .replace(`{${"advisorygroup_id"}}`, encodeURIComponent(String(advisorygroup_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_clients !== undefined) {
                localVarQueryParameter['populate_clients'] = populate_clients;
            }

            if (populate_aum !== undefined) {
                localVarQueryParameter['populate_aum'] = populate_aum;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of advisory groups
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [advisory_group_ids] 
         * @param {ListAdvisoryGroupsSortFieldEnum} [sort_field] 
         * @param {ListAdvisoryGroupsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [populate_logo_urls] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdvisoryGroups: async (skip?: number, limit?: number, search?: string, advisory_group_ids?: Array<string>, sort_field?: ListAdvisoryGroupsSortFieldEnum, sort_direction?: ListAdvisoryGroupsSortDirectionEnum, populate_logo_urls?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/advisorygroups/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (advisory_group_ids) {
                localVarQueryParameter['advisory_group_ids'] = advisory_group_ids;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (populate_logo_urls !== undefined) {
                localVarQueryParameter['populate_logo_urls'] = populate_logo_urls;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List available clients for an advisor in an advisory group
         * @param {string} advisorygroup_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableClients: async (advisorygroup_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advisorygroup_id' is not null or undefined
            assertParamExists('listAvailableClients', 'advisorygroup_id', advisorygroup_id)
            const localVarPath = `/v2/advisorygroups/{advisorygroup_id}/clients/`
                .replace(`{${"advisorygroup_id"}}`, encodeURIComponent(String(advisorygroup_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove an advisor from an advisory group
         * @param {string} advisorygroup_id 
         * @param {string} advisor_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAdvisorFromGroup: async (advisorygroup_id: string, advisor_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advisorygroup_id' is not null or undefined
            assertParamExists('removeAdvisorFromGroup', 'advisorygroup_id', advisorygroup_id)
            // verify required parameter 'advisor_id' is not null or undefined
            assertParamExists('removeAdvisorFromGroup', 'advisor_id', advisor_id)
            const localVarPath = `/v2/advisorygroups/{advisorygroup_id}/advisors/{advisor_id}`
                .replace(`{${"advisorygroup_id"}}`, encodeURIComponent(String(advisorygroup_id)))
                .replace(`{${"advisor_id"}}`, encodeURIComponent(String(advisor_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sync an extegration for an advisory group.
         * @param {string} advisorygroup_id 
         * @param {SyncExtegrationRequest} SyncExtegrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncExtegration: async (advisorygroup_id: string, SyncExtegrationRequest: SyncExtegrationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advisorygroup_id' is not null or undefined
            assertParamExists('syncExtegration', 'advisorygroup_id', advisorygroup_id)
            // verify required parameter 'SyncExtegrationRequest' is not null or undefined
            assertParamExists('syncExtegration', 'SyncExtegrationRequest', SyncExtegrationRequest)
            const localVarPath = `/v2/advisorygroups/{advisorygroup_id}/sync`
                .replace(`{${"advisorygroup_id"}}`, encodeURIComponent(String(advisorygroup_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(SyncExtegrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an advisor\'s membership in an advisory group
         * @param {string} advisorygroup_id 
         * @param {string | null} advisor_id 
         * @param {UpdateAdvisorInGroupRequest} [UpdateAdvisorInGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdvisorInGroup: async (advisorygroup_id: string, advisor_id: string | null, UpdateAdvisorInGroupRequest?: UpdateAdvisorInGroupRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advisorygroup_id' is not null or undefined
            assertParamExists('updateAdvisorInGroup', 'advisorygroup_id', advisorygroup_id)
            // verify required parameter 'advisor_id' is not null or undefined
            assertParamExists('updateAdvisorInGroup', 'advisor_id', advisor_id)
            const localVarPath = `/v2/advisorygroups/{advisorygroup_id}/advisors/{advisor_id}`
                .replace(`{${"advisorygroup_id"}}`, encodeURIComponent(String(advisorygroup_id)))
                .replace(`{${"advisor_id"}}`, encodeURIComponent(String(advisor_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateAdvisorInGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an advisory group
         * @param {string | null} advisorygroup_id 
         * @param {UpdateAdvisoryGroupRequest} [UpdateAdvisoryGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdvisoryGroup: async (advisorygroup_id: string | null, UpdateAdvisoryGroupRequest?: UpdateAdvisoryGroupRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advisorygroup_id' is not null or undefined
            assertParamExists('updateAdvisoryGroup', 'advisorygroup_id', advisorygroup_id)
            const localVarPath = `/v2/advisorygroups/{advisorygroup_id}`
                .replace(`{${"advisorygroup_id"}}`, encodeURIComponent(String(advisorygroup_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateAdvisoryGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload or delete a logo for an advisory group
         * @param {string} advisorygroup_id 
         * @param {boolean} [delete_logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAdvisoryGroupLogo: async (advisorygroup_id: string, delete_logo?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advisorygroup_id' is not null or undefined
            assertParamExists('uploadAdvisoryGroupLogo', 'advisorygroup_id', advisorygroup_id)
            const localVarPath = `/v2/advisorygroups/{advisorygroup_id}/logo`
                .replace(`{${"advisorygroup_id"}}`, encodeURIComponent(String(advisorygroup_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (delete_logo !== undefined) {
                localVarQueryParameter['delete_logo'] = delete_logo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvisoryGroupApi - functional programming interface
 * @export
 */
export const AdvisoryGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvisoryGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * Add an advisor to an advisory group
         * @param {string} advisorygroup_id 
         * @param {AddAdvisorToGroupRequest} AddAdvisorToGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAdvisorToGroup(advisorygroup_id: string, AddAdvisorToGroupRequest: AddAdvisorToGroupRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAdvisorToGroup(advisorygroup_id, AddAdvisorToGroupRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdvisoryGroupApi.addAdvisorToGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new Advisory Group document.
         * @param {CreateAdvisoryGroupRequest} CreateAdvisoryGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdvisoryGroup(CreateAdvisoryGroupRequest: CreateAdvisoryGroupRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAdvisoryGroup201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdvisoryGroup(CreateAdvisoryGroupRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdvisoryGroupApi.createAdvisoryGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an Advisory Group document.
         * @param {string | null} group_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdvisoryGroup(group_id: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdvisoryGroup(group_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdvisoryGroupApi.deleteAdvisoryGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get an advisory group by its ID
         * @param {string | null} advisory_group_id 
         * @param {boolean} [populate_logo_urls] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvisoryGroupById(advisory_group_id: string | null, populate_logo_urls?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdvisoryGroupById200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvisoryGroupById(advisory_group_id, populate_logo_urls, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdvisoryGroupApi.getAdvisoryGroupById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List advisors in an advisory group
         * @param {string} advisorygroup_id 
         * @param {boolean} [populate_clients] 
         * @param {boolean} [populate_aum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAdvisorsInGroup(advisorygroup_id: string, populate_clients?: boolean, populate_aum?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAdvisorsInGroup200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAdvisorsInGroup(advisorygroup_id, populate_clients, populate_aum, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdvisoryGroupApi.listAdvisorsInGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a list of advisory groups
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [advisory_group_ids] 
         * @param {ListAdvisoryGroupsSortFieldEnum} [sort_field] 
         * @param {ListAdvisoryGroupsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [populate_logo_urls] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAdvisoryGroups(skip?: number, limit?: number, search?: string, advisory_group_ids?: Array<string>, sort_field?: ListAdvisoryGroupsSortFieldEnum, sort_direction?: ListAdvisoryGroupsSortDirectionEnum, populate_logo_urls?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAdvisoryGroups200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAdvisoryGroups(skip, limit, search, advisory_group_ids, sort_field, sort_direction, populate_logo_urls, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdvisoryGroupApi.listAdvisoryGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List available clients for an advisor in an advisory group
         * @param {string} advisorygroup_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAvailableClients(advisorygroup_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAvailableClients200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAvailableClients(advisorygroup_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdvisoryGroupApi.listAvailableClients']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Remove an advisor from an advisory group
         * @param {string} advisorygroup_id 
         * @param {string} advisor_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeAdvisorFromGroup(advisorygroup_id: string, advisor_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeAdvisorFromGroup(advisorygroup_id, advisor_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdvisoryGroupApi.removeAdvisorFromGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Sync an extegration for an advisory group.
         * @param {string} advisorygroup_id 
         * @param {SyncExtegrationRequest} SyncExtegrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncExtegration(advisorygroup_id: string, SyncExtegrationRequest: SyncExtegrationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncExtegration200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncExtegration(advisorygroup_id, SyncExtegrationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdvisoryGroupApi.syncExtegration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an advisor\'s membership in an advisory group
         * @param {string} advisorygroup_id 
         * @param {string | null} advisor_id 
         * @param {UpdateAdvisorInGroupRequest} [UpdateAdvisorInGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdvisorInGroup(advisorygroup_id: string, advisor_id: string | null, UpdateAdvisorInGroupRequest?: UpdateAdvisorInGroupRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdvisorInGroup(advisorygroup_id, advisor_id, UpdateAdvisorInGroupRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdvisoryGroupApi.updateAdvisorInGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an advisory group
         * @param {string | null} advisorygroup_id 
         * @param {UpdateAdvisoryGroupRequest} [UpdateAdvisoryGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdvisoryGroup(advisorygroup_id: string | null, UpdateAdvisoryGroupRequest?: UpdateAdvisoryGroupRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateAdvisoryGroup200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdvisoryGroup(advisorygroup_id, UpdateAdvisoryGroupRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdvisoryGroupApi.updateAdvisoryGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Upload or delete a logo for an advisory group
         * @param {string} advisorygroup_id 
         * @param {boolean} [delete_logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAdvisoryGroupLogo(advisorygroup_id: string, delete_logo?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadAdvisoryGroupLogo200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadAdvisoryGroupLogo(advisorygroup_id, delete_logo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdvisoryGroupApi.uploadAdvisoryGroupLogo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdvisoryGroupApi - factory interface
 * @export
 */
export const AdvisoryGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvisoryGroupApiFp(configuration)
    return {
        /**
         * Add an advisor to an advisory group
         * @param {AdvisoryGroupApiAddAdvisorToGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdvisorToGroup(requestParameters: AdvisoryGroupApiAddAdvisorToGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.addAdvisorToGroup(requestParameters.advisorygroup_id, requestParameters.AddAdvisorToGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new Advisory Group document.
         * @param {AdvisoryGroupApiCreateAdvisoryGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdvisoryGroup(requestParameters: AdvisoryGroupApiCreateAdvisoryGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateAdvisoryGroup201Response> {
            return localVarFp.createAdvisoryGroup(requestParameters.CreateAdvisoryGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an Advisory Group document.
         * @param {AdvisoryGroupApiDeleteAdvisoryGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdvisoryGroup(requestParameters: AdvisoryGroupApiDeleteAdvisoryGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteAdvisoryGroup(requestParameters.group_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an advisory group by its ID
         * @param {AdvisoryGroupApiGetAdvisoryGroupByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvisoryGroupById(requestParameters: AdvisoryGroupApiGetAdvisoryGroupByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetAdvisoryGroupById200Response> {
            return localVarFp.getAdvisoryGroupById(requestParameters.advisory_group_id, requestParameters.populate_logo_urls, options).then((request) => request(axios, basePath));
        },
        /**
         * List advisors in an advisory group
         * @param {AdvisoryGroupApiListAdvisorsInGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdvisorsInGroup(requestParameters: AdvisoryGroupApiListAdvisorsInGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListAdvisorsInGroup200Response> {
            return localVarFp.listAdvisorsInGroup(requestParameters.advisorygroup_id, requestParameters.populate_clients, requestParameters.populate_aum, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of advisory groups
         * @param {AdvisoryGroupApiListAdvisoryGroupsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdvisoryGroups(requestParameters: AdvisoryGroupApiListAdvisoryGroupsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListAdvisoryGroups200Response> {
            return localVarFp.listAdvisoryGroups(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.advisory_group_ids, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.populate_logo_urls, options).then((request) => request(axios, basePath));
        },
        /**
         * List available clients for an advisor in an advisory group
         * @param {AdvisoryGroupApiListAvailableClientsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableClients(requestParameters: AdvisoryGroupApiListAvailableClientsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListAvailableClients200Response> {
            return localVarFp.listAvailableClients(requestParameters.advisorygroup_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove an advisor from an advisory group
         * @param {AdvisoryGroupApiRemoveAdvisorFromGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAdvisorFromGroup(requestParameters: AdvisoryGroupApiRemoveAdvisorFromGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.removeAdvisorFromGroup(requestParameters.advisorygroup_id, requestParameters.advisor_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Sync an extegration for an advisory group.
         * @param {AdvisoryGroupApiSyncExtegrationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncExtegration(requestParameters: AdvisoryGroupApiSyncExtegrationRequest, options?: RawAxiosRequestConfig): AxiosPromise<SyncExtegration200Response> {
            return localVarFp.syncExtegration(requestParameters.advisorygroup_id, requestParameters.SyncExtegrationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an advisor\'s membership in an advisory group
         * @param {AdvisoryGroupApiUpdateAdvisorInGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdvisorInGroup(requestParameters: AdvisoryGroupApiUpdateAdvisorInGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateAdvisorInGroup(requestParameters.advisorygroup_id, requestParameters.advisor_id, requestParameters.UpdateAdvisorInGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an advisory group
         * @param {AdvisoryGroupApiUpdateAdvisoryGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdvisoryGroup(requestParameters: AdvisoryGroupApiUpdateAdvisoryGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<UpdateAdvisoryGroup200Response> {
            return localVarFp.updateAdvisoryGroup(requestParameters.advisorygroup_id, requestParameters.UpdateAdvisoryGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload or delete a logo for an advisory group
         * @param {AdvisoryGroupApiUploadAdvisoryGroupLogoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAdvisoryGroupLogo(requestParameters: AdvisoryGroupApiUploadAdvisoryGroupLogoRequest, options?: RawAxiosRequestConfig): AxiosPromise<UploadAdvisoryGroupLogo200Response> {
            return localVarFp.uploadAdvisoryGroupLogo(requestParameters.advisorygroup_id, requestParameters.delete_logo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addAdvisorToGroup operation in AdvisoryGroupApi.
 * @export
 * @interface AdvisoryGroupApiAddAdvisorToGroupRequest
 */
export interface AdvisoryGroupApiAddAdvisorToGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvisoryGroupApiAddAdvisorToGroup
     */
    readonly advisorygroup_id: string

    /**
     * 
     * @type {AddAdvisorToGroupRequest}
     * @memberof AdvisoryGroupApiAddAdvisorToGroup
     */
    readonly AddAdvisorToGroupRequest: AddAdvisorToGroupRequest
}

/**
 * Request parameters for createAdvisoryGroup operation in AdvisoryGroupApi.
 * @export
 * @interface AdvisoryGroupApiCreateAdvisoryGroupRequest
 */
export interface AdvisoryGroupApiCreateAdvisoryGroupRequest {
    /**
     * 
     * @type {CreateAdvisoryGroupRequest}
     * @memberof AdvisoryGroupApiCreateAdvisoryGroup
     */
    readonly CreateAdvisoryGroupRequest: CreateAdvisoryGroupRequest
}

/**
 * Request parameters for deleteAdvisoryGroup operation in AdvisoryGroupApi.
 * @export
 * @interface AdvisoryGroupApiDeleteAdvisoryGroupRequest
 */
export interface AdvisoryGroupApiDeleteAdvisoryGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvisoryGroupApiDeleteAdvisoryGroup
     */
    readonly group_id: string | null
}

/**
 * Request parameters for getAdvisoryGroupById operation in AdvisoryGroupApi.
 * @export
 * @interface AdvisoryGroupApiGetAdvisoryGroupByIdRequest
 */
export interface AdvisoryGroupApiGetAdvisoryGroupByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvisoryGroupApiGetAdvisoryGroupById
     */
    readonly advisory_group_id: string | null

    /**
     * 
     * @type {boolean}
     * @memberof AdvisoryGroupApiGetAdvisoryGroupById
     */
    readonly populate_logo_urls?: boolean
}

/**
 * Request parameters for listAdvisorsInGroup operation in AdvisoryGroupApi.
 * @export
 * @interface AdvisoryGroupApiListAdvisorsInGroupRequest
 */
export interface AdvisoryGroupApiListAdvisorsInGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvisoryGroupApiListAdvisorsInGroup
     */
    readonly advisorygroup_id: string

    /**
     * 
     * @type {boolean}
     * @memberof AdvisoryGroupApiListAdvisorsInGroup
     */
    readonly populate_clients?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AdvisoryGroupApiListAdvisorsInGroup
     */
    readonly populate_aum?: boolean
}

/**
 * Request parameters for listAdvisoryGroups operation in AdvisoryGroupApi.
 * @export
 * @interface AdvisoryGroupApiListAdvisoryGroupsRequest
 */
export interface AdvisoryGroupApiListAdvisoryGroupsRequest {
    /**
     * 
     * @type {number}
     * @memberof AdvisoryGroupApiListAdvisoryGroups
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AdvisoryGroupApiListAdvisoryGroups
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof AdvisoryGroupApiListAdvisoryGroups
     */
    readonly search?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof AdvisoryGroupApiListAdvisoryGroups
     */
    readonly advisory_group_ids?: Array<string>

    /**
     * 
     * @type {'name' | 'created_at' | 'updated_at'}
     * @memberof AdvisoryGroupApiListAdvisoryGroups
     */
    readonly sort_field?: ListAdvisoryGroupsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof AdvisoryGroupApiListAdvisoryGroups
     */
    readonly sort_direction?: ListAdvisoryGroupsSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof AdvisoryGroupApiListAdvisoryGroups
     */
    readonly populate_logo_urls?: boolean
}

/**
 * Request parameters for listAvailableClients operation in AdvisoryGroupApi.
 * @export
 * @interface AdvisoryGroupApiListAvailableClientsRequest
 */
export interface AdvisoryGroupApiListAvailableClientsRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvisoryGroupApiListAvailableClients
     */
    readonly advisorygroup_id: string
}

/**
 * Request parameters for removeAdvisorFromGroup operation in AdvisoryGroupApi.
 * @export
 * @interface AdvisoryGroupApiRemoveAdvisorFromGroupRequest
 */
export interface AdvisoryGroupApiRemoveAdvisorFromGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvisoryGroupApiRemoveAdvisorFromGroup
     */
    readonly advisorygroup_id: string

    /**
     * 
     * @type {string}
     * @memberof AdvisoryGroupApiRemoveAdvisorFromGroup
     */
    readonly advisor_id: string
}

/**
 * Request parameters for syncExtegration operation in AdvisoryGroupApi.
 * @export
 * @interface AdvisoryGroupApiSyncExtegrationRequest
 */
export interface AdvisoryGroupApiSyncExtegrationRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvisoryGroupApiSyncExtegration
     */
    readonly advisorygroup_id: string

    /**
     * 
     * @type {SyncExtegrationRequest}
     * @memberof AdvisoryGroupApiSyncExtegration
     */
    readonly SyncExtegrationRequest: SyncExtegrationRequest
}

/**
 * Request parameters for updateAdvisorInGroup operation in AdvisoryGroupApi.
 * @export
 * @interface AdvisoryGroupApiUpdateAdvisorInGroupRequest
 */
export interface AdvisoryGroupApiUpdateAdvisorInGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvisoryGroupApiUpdateAdvisorInGroup
     */
    readonly advisorygroup_id: string

    /**
     * 
     * @type {string}
     * @memberof AdvisoryGroupApiUpdateAdvisorInGroup
     */
    readonly advisor_id: string | null

    /**
     * 
     * @type {UpdateAdvisorInGroupRequest}
     * @memberof AdvisoryGroupApiUpdateAdvisorInGroup
     */
    readonly UpdateAdvisorInGroupRequest?: UpdateAdvisorInGroupRequest
}

/**
 * Request parameters for updateAdvisoryGroup operation in AdvisoryGroupApi.
 * @export
 * @interface AdvisoryGroupApiUpdateAdvisoryGroupRequest
 */
export interface AdvisoryGroupApiUpdateAdvisoryGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvisoryGroupApiUpdateAdvisoryGroup
     */
    readonly advisorygroup_id: string | null

    /**
     * 
     * @type {UpdateAdvisoryGroupRequest}
     * @memberof AdvisoryGroupApiUpdateAdvisoryGroup
     */
    readonly UpdateAdvisoryGroupRequest?: UpdateAdvisoryGroupRequest
}

/**
 * Request parameters for uploadAdvisoryGroupLogo operation in AdvisoryGroupApi.
 * @export
 * @interface AdvisoryGroupApiUploadAdvisoryGroupLogoRequest
 */
export interface AdvisoryGroupApiUploadAdvisoryGroupLogoRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvisoryGroupApiUploadAdvisoryGroupLogo
     */
    readonly advisorygroup_id: string

    /**
     * 
     * @type {boolean}
     * @memberof AdvisoryGroupApiUploadAdvisoryGroupLogo
     */
    readonly delete_logo?: boolean
}

/**
 * AdvisoryGroupApi - object-oriented interface
 * @export
 * @class AdvisoryGroupApi
 * @extends {BaseAPI}
 */
export class AdvisoryGroupApi extends BaseAPI {
    /**
     * Add an advisor to an advisory group
     * @param {AdvisoryGroupApiAddAdvisorToGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisoryGroupApi
     */
    public addAdvisorToGroup(requestParameters: AdvisoryGroupApiAddAdvisorToGroupRequest, options?: RawAxiosRequestConfig) {
        return AdvisoryGroupApiFp(this.configuration).addAdvisorToGroup(requestParameters.advisorygroup_id, requestParameters.AddAdvisorToGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new Advisory Group document.
     * @param {AdvisoryGroupApiCreateAdvisoryGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisoryGroupApi
     */
    public createAdvisoryGroup(requestParameters: AdvisoryGroupApiCreateAdvisoryGroupRequest, options?: RawAxiosRequestConfig) {
        return AdvisoryGroupApiFp(this.configuration).createAdvisoryGroup(requestParameters.CreateAdvisoryGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an Advisory Group document.
     * @param {AdvisoryGroupApiDeleteAdvisoryGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisoryGroupApi
     */
    public deleteAdvisoryGroup(requestParameters: AdvisoryGroupApiDeleteAdvisoryGroupRequest, options?: RawAxiosRequestConfig) {
        return AdvisoryGroupApiFp(this.configuration).deleteAdvisoryGroup(requestParameters.group_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an advisory group by its ID
     * @param {AdvisoryGroupApiGetAdvisoryGroupByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisoryGroupApi
     */
    public getAdvisoryGroupById(requestParameters: AdvisoryGroupApiGetAdvisoryGroupByIdRequest, options?: RawAxiosRequestConfig) {
        return AdvisoryGroupApiFp(this.configuration).getAdvisoryGroupById(requestParameters.advisory_group_id, requestParameters.populate_logo_urls, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List advisors in an advisory group
     * @param {AdvisoryGroupApiListAdvisorsInGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisoryGroupApi
     */
    public listAdvisorsInGroup(requestParameters: AdvisoryGroupApiListAdvisorsInGroupRequest, options?: RawAxiosRequestConfig) {
        return AdvisoryGroupApiFp(this.configuration).listAdvisorsInGroup(requestParameters.advisorygroup_id, requestParameters.populate_clients, requestParameters.populate_aum, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a list of advisory groups
     * @param {AdvisoryGroupApiListAdvisoryGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisoryGroupApi
     */
    public listAdvisoryGroups(requestParameters: AdvisoryGroupApiListAdvisoryGroupsRequest = {}, options?: RawAxiosRequestConfig) {
        return AdvisoryGroupApiFp(this.configuration).listAdvisoryGroups(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.advisory_group_ids, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.populate_logo_urls, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List available clients for an advisor in an advisory group
     * @param {AdvisoryGroupApiListAvailableClientsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisoryGroupApi
     */
    public listAvailableClients(requestParameters: AdvisoryGroupApiListAvailableClientsRequest, options?: RawAxiosRequestConfig) {
        return AdvisoryGroupApiFp(this.configuration).listAvailableClients(requestParameters.advisorygroup_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove an advisor from an advisory group
     * @param {AdvisoryGroupApiRemoveAdvisorFromGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisoryGroupApi
     */
    public removeAdvisorFromGroup(requestParameters: AdvisoryGroupApiRemoveAdvisorFromGroupRequest, options?: RawAxiosRequestConfig) {
        return AdvisoryGroupApiFp(this.configuration).removeAdvisorFromGroup(requestParameters.advisorygroup_id, requestParameters.advisor_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sync an extegration for an advisory group.
     * @param {AdvisoryGroupApiSyncExtegrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisoryGroupApi
     */
    public syncExtegration(requestParameters: AdvisoryGroupApiSyncExtegrationRequest, options?: RawAxiosRequestConfig) {
        return AdvisoryGroupApiFp(this.configuration).syncExtegration(requestParameters.advisorygroup_id, requestParameters.SyncExtegrationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an advisor\'s membership in an advisory group
     * @param {AdvisoryGroupApiUpdateAdvisorInGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisoryGroupApi
     */
    public updateAdvisorInGroup(requestParameters: AdvisoryGroupApiUpdateAdvisorInGroupRequest, options?: RawAxiosRequestConfig) {
        return AdvisoryGroupApiFp(this.configuration).updateAdvisorInGroup(requestParameters.advisorygroup_id, requestParameters.advisor_id, requestParameters.UpdateAdvisorInGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an advisory group
     * @param {AdvisoryGroupApiUpdateAdvisoryGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisoryGroupApi
     */
    public updateAdvisoryGroup(requestParameters: AdvisoryGroupApiUpdateAdvisoryGroupRequest, options?: RawAxiosRequestConfig) {
        return AdvisoryGroupApiFp(this.configuration).updateAdvisoryGroup(requestParameters.advisorygroup_id, requestParameters.UpdateAdvisoryGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload or delete a logo for an advisory group
     * @param {AdvisoryGroupApiUploadAdvisoryGroupLogoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisoryGroupApi
     */
    public uploadAdvisoryGroupLogo(requestParameters: AdvisoryGroupApiUploadAdvisoryGroupLogoRequest, options?: RawAxiosRequestConfig) {
        return AdvisoryGroupApiFp(this.configuration).uploadAdvisoryGroupLogo(requestParameters.advisorygroup_id, requestParameters.delete_logo, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListAdvisoryGroupsSortFieldEnum = {
    Name: 'name',
    CreatedAt: 'created_at',
    UpdatedAt: 'updated_at'
} as const;
export type ListAdvisoryGroupsSortFieldEnum = typeof ListAdvisoryGroupsSortFieldEnum[keyof typeof ListAdvisoryGroupsSortFieldEnum];
/**
 * @export
 */
export const ListAdvisoryGroupsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListAdvisoryGroupsSortDirectionEnum = typeof ListAdvisoryGroupsSortDirectionEnum[keyof typeof ListAdvisoryGroupsSortDirectionEnum];


/**
 * AssetManagerApi - axios parameter creator
 * @export
 */
export const AssetManagerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Asset Manager.
         * @param {CreateAssetManagerRequest} CreateAssetManagerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssetManager: async (CreateAssetManagerRequest: CreateAssetManagerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateAssetManagerRequest' is not null or undefined
            assertParamExists('createAssetManager', 'CreateAssetManagerRequest', CreateAssetManagerRequest)
            const localVarPath = `/v2/assetmanagers/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateAssetManagerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an Asset Manager document.
         * @param {string} asset_manager_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssetManager: async (asset_manager_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'asset_manager_id' is not null or undefined
            assertParamExists('deleteAssetManager', 'asset_manager_id', asset_manager_id)
            const localVarPath = `/v2/assetmanagers/{asset_manager_id}`
                .replace(`{${"asset_manager_id"}}`, encodeURIComponent(String(asset_manager_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a single asset manager
         * @param {string | null} asset_manager_id 
         * @param {boolean} [populate_integrations] 
         * @param {boolean} [populate_connection_count] 
         * @param {boolean} [populate_connections] 
         * @param {boolean} [populate_note_count] 
         * @param {boolean} [populate_contacts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetManager: async (asset_manager_id: string | null, populate_integrations?: boolean, populate_connection_count?: boolean, populate_connections?: boolean, populate_note_count?: boolean, populate_contacts?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'asset_manager_id' is not null or undefined
            assertParamExists('getAssetManager', 'asset_manager_id', asset_manager_id)
            const localVarPath = `/v2/assetmanagers/{asset_manager_id}`
                .replace(`{${"asset_manager_id"}}`, encodeURIComponent(String(asset_manager_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_integrations !== undefined) {
                localVarQueryParameter['populate_integrations'] = populate_integrations;
            }

            if (populate_connection_count !== undefined) {
                localVarQueryParameter['populate_connection_count'] = populate_connection_count;
            }

            if (populate_connections !== undefined) {
                localVarQueryParameter['populate_connections'] = populate_connections;
            }

            if (populate_note_count !== undefined) {
                localVarQueryParameter['populate_note_count'] = populate_note_count;
            }

            if (populate_contacts !== undefined) {
                localVarQueryParameter['populate_contacts'] = populate_contacts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of asset managers
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListAssetManagersSortFieldEnum} [sort_field] 
         * @param {ListAssetManagersSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [asset_manager_ids] 
         * @param {boolean} [populate_investment_masters] 
         * @param {boolean} [populate_integrations] 
         * @param {boolean} [populate_connection_count] 
         * @param {boolean} [populate_connections] 
         * @param {boolean} [populate_contacts] 
         * @param {boolean} [populate_note_count] 
         * @param {string} [search] 
         * @param {Array<ListAssetManagersConnectionOutreachStatusesEnum>} [connection_outreach_statuses] 
         * @param {ListAssetManagersOutreachStatusEnum} [outreach_status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssetManagers: async (skip?: number, limit?: number, sort_field?: ListAssetManagersSortFieldEnum, sort_direction?: ListAssetManagersSortDirectionEnum, asset_manager_ids?: Array<string>, populate_investment_masters?: boolean, populate_integrations?: boolean, populate_connection_count?: boolean, populate_connections?: boolean, populate_contacts?: boolean, populate_note_count?: boolean, search?: string, connection_outreach_statuses?: Array<ListAssetManagersConnectionOutreachStatusesEnum>, outreach_status?: ListAssetManagersOutreachStatusEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/assetmanagers/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (asset_manager_ids) {
                localVarQueryParameter['asset_manager_ids'] = asset_manager_ids;
            }

            if (populate_investment_masters !== undefined) {
                localVarQueryParameter['populate_investment_masters'] = populate_investment_masters;
            }

            if (populate_integrations !== undefined) {
                localVarQueryParameter['populate_integrations'] = populate_integrations;
            }

            if (populate_connection_count !== undefined) {
                localVarQueryParameter['populate_connection_count'] = populate_connection_count;
            }

            if (populate_connections !== undefined) {
                localVarQueryParameter['populate_connections'] = populate_connections;
            }

            if (populate_contacts !== undefined) {
                localVarQueryParameter['populate_contacts'] = populate_contacts;
            }

            if (populate_note_count !== undefined) {
                localVarQueryParameter['populate_note_count'] = populate_note_count;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (connection_outreach_statuses) {
                localVarQueryParameter['connection_outreach_statuses'] = connection_outreach_statuses;
            }

            if (outreach_status !== undefined) {
                localVarQueryParameter['outreach_status'] = outreach_status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an Asset Manager document.
         * @param {string} asset_manager_id 
         * @param {UpdateAssetManagerRequest} [UpdateAssetManagerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssetManager: async (asset_manager_id: string, UpdateAssetManagerRequest?: UpdateAssetManagerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'asset_manager_id' is not null or undefined
            assertParamExists('updateAssetManager', 'asset_manager_id', asset_manager_id)
            const localVarPath = `/v2/assetmanagers/{asset_manager_id}`
                .replace(`{${"asset_manager_id"}}`, encodeURIComponent(String(asset_manager_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateAssetManagerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetManagerApi - functional programming interface
 * @export
 */
export const AssetManagerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetManagerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Asset Manager.
         * @param {CreateAssetManagerRequest} CreateAssetManagerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAssetManager(CreateAssetManagerRequest: CreateAssetManagerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAssetManager201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAssetManager(CreateAssetManagerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetManagerApi.createAssetManager']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an Asset Manager document.
         * @param {string} asset_manager_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAssetManager(asset_manager_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAssetManager(asset_manager_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetManagerApi.deleteAssetManager']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a single asset manager
         * @param {string | null} asset_manager_id 
         * @param {boolean} [populate_integrations] 
         * @param {boolean} [populate_connection_count] 
         * @param {boolean} [populate_connections] 
         * @param {boolean} [populate_note_count] 
         * @param {boolean} [populate_contacts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetManager(asset_manager_id: string | null, populate_integrations?: boolean, populate_connection_count?: boolean, populate_connections?: boolean, populate_note_count?: boolean, populate_contacts?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAssetManager200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetManager(asset_manager_id, populate_integrations, populate_connection_count, populate_connections, populate_note_count, populate_contacts, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetManagerApi.getAssetManager']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a list of asset managers
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListAssetManagersSortFieldEnum} [sort_field] 
         * @param {ListAssetManagersSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [asset_manager_ids] 
         * @param {boolean} [populate_investment_masters] 
         * @param {boolean} [populate_integrations] 
         * @param {boolean} [populate_connection_count] 
         * @param {boolean} [populate_connections] 
         * @param {boolean} [populate_contacts] 
         * @param {boolean} [populate_note_count] 
         * @param {string} [search] 
         * @param {Array<ListAssetManagersConnectionOutreachStatusesEnum>} [connection_outreach_statuses] 
         * @param {ListAssetManagersOutreachStatusEnum} [outreach_status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAssetManagers(skip?: number, limit?: number, sort_field?: ListAssetManagersSortFieldEnum, sort_direction?: ListAssetManagersSortDirectionEnum, asset_manager_ids?: Array<string>, populate_investment_masters?: boolean, populate_integrations?: boolean, populate_connection_count?: boolean, populate_connections?: boolean, populate_contacts?: boolean, populate_note_count?: boolean, search?: string, connection_outreach_statuses?: Array<ListAssetManagersConnectionOutreachStatusesEnum>, outreach_status?: ListAssetManagersOutreachStatusEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAssetManagers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAssetManagers(skip, limit, sort_field, sort_direction, asset_manager_ids, populate_investment_masters, populate_integrations, populate_connection_count, populate_connections, populate_contacts, populate_note_count, search, connection_outreach_statuses, outreach_status, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetManagerApi.listAssetManagers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an Asset Manager document.
         * @param {string} asset_manager_id 
         * @param {UpdateAssetManagerRequest} [UpdateAssetManagerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssetManager(asset_manager_id: string, UpdateAssetManagerRequest?: UpdateAssetManagerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateAssetManager200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssetManager(asset_manager_id, UpdateAssetManagerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetManagerApi.updateAssetManager']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssetManagerApi - factory interface
 * @export
 */
export const AssetManagerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetManagerApiFp(configuration)
    return {
        /**
         * Create a new Asset Manager.
         * @param {AssetManagerApiCreateAssetManagerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssetManager(requestParameters: AssetManagerApiCreateAssetManagerRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateAssetManager201Response> {
            return localVarFp.createAssetManager(requestParameters.CreateAssetManagerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an Asset Manager document.
         * @param {AssetManagerApiDeleteAssetManagerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssetManager(requestParameters: AssetManagerApiDeleteAssetManagerRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteAssetManager(requestParameters.asset_manager_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a single asset manager
         * @param {AssetManagerApiGetAssetManagerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetManager(requestParameters: AssetManagerApiGetAssetManagerRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetAssetManager200Response> {
            return localVarFp.getAssetManager(requestParameters.asset_manager_id, requestParameters.populate_integrations, requestParameters.populate_connection_count, requestParameters.populate_connections, requestParameters.populate_note_count, requestParameters.populate_contacts, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of asset managers
         * @param {AssetManagerApiListAssetManagersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssetManagers(requestParameters: AssetManagerApiListAssetManagersRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListAssetManagers200Response> {
            return localVarFp.listAssetManagers(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.asset_manager_ids, requestParameters.populate_investment_masters, requestParameters.populate_integrations, requestParameters.populate_connection_count, requestParameters.populate_connections, requestParameters.populate_contacts, requestParameters.populate_note_count, requestParameters.search, requestParameters.connection_outreach_statuses, requestParameters.outreach_status, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an Asset Manager document.
         * @param {AssetManagerApiUpdateAssetManagerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssetManager(requestParameters: AssetManagerApiUpdateAssetManagerRequest, options?: RawAxiosRequestConfig): AxiosPromise<UpdateAssetManager200Response> {
            return localVarFp.updateAssetManager(requestParameters.asset_manager_id, requestParameters.UpdateAssetManagerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAssetManager operation in AssetManagerApi.
 * @export
 * @interface AssetManagerApiCreateAssetManagerRequest
 */
export interface AssetManagerApiCreateAssetManagerRequest {
    /**
     * 
     * @type {CreateAssetManagerRequest}
     * @memberof AssetManagerApiCreateAssetManager
     */
    readonly CreateAssetManagerRequest: CreateAssetManagerRequest
}

/**
 * Request parameters for deleteAssetManager operation in AssetManagerApi.
 * @export
 * @interface AssetManagerApiDeleteAssetManagerRequest
 */
export interface AssetManagerApiDeleteAssetManagerRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetManagerApiDeleteAssetManager
     */
    readonly asset_manager_id: string
}

/**
 * Request parameters for getAssetManager operation in AssetManagerApi.
 * @export
 * @interface AssetManagerApiGetAssetManagerRequest
 */
export interface AssetManagerApiGetAssetManagerRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetManagerApiGetAssetManager
     */
    readonly asset_manager_id: string | null

    /**
     * 
     * @type {boolean}
     * @memberof AssetManagerApiGetAssetManager
     */
    readonly populate_integrations?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AssetManagerApiGetAssetManager
     */
    readonly populate_connection_count?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AssetManagerApiGetAssetManager
     */
    readonly populate_connections?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AssetManagerApiGetAssetManager
     */
    readonly populate_note_count?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AssetManagerApiGetAssetManager
     */
    readonly populate_contacts?: boolean
}

/**
 * Request parameters for listAssetManagers operation in AssetManagerApi.
 * @export
 * @interface AssetManagerApiListAssetManagersRequest
 */
export interface AssetManagerApiListAssetManagersRequest {
    /**
     * 
     * @type {number}
     * @memberof AssetManagerApiListAssetManagers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AssetManagerApiListAssetManagers
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'date' | 'name' | 'notification_method' | 'website' | 'tax_document_distribution_method' | 'outreach_status' | 'initial_outreach_date'}
     * @memberof AssetManagerApiListAssetManagers
     */
    readonly sort_field?: ListAssetManagersSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof AssetManagerApiListAssetManagers
     */
    readonly sort_direction?: ListAssetManagersSortDirectionEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetManagerApiListAssetManagers
     */
    readonly asset_manager_ids?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof AssetManagerApiListAssetManagers
     */
    readonly populate_investment_masters?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AssetManagerApiListAssetManagers
     */
    readonly populate_integrations?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AssetManagerApiListAssetManagers
     */
    readonly populate_connection_count?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AssetManagerApiListAssetManagers
     */
    readonly populate_connections?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AssetManagerApiListAssetManagers
     */
    readonly populate_contacts?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AssetManagerApiListAssetManagers
     */
    readonly populate_note_count?: boolean

    /**
     * 
     * @type {string}
     * @memberof AssetManagerApiListAssetManagers
     */
    readonly search?: string

    /**
     * 
     * @type {Array<'Connection Created' | 'Ready For AM To Start' | 'AM Unable To Support' | 'AM Needs More Information' | 'Connection Setup Complete By AM' | 'Portal Credentials Created'>}
     * @memberof AssetManagerApiListAssetManagers
     */
    readonly connection_outreach_statuses?: Array<ListAssetManagersConnectionOutreachStatusesEnum>

    /**
     * 
     * @type {'Not Started' | 'No Response' | 'Unable to Support' | 'Granting Portal Access' | 'Investment Setup' | 'Done'}
     * @memberof AssetManagerApiListAssetManagers
     */
    readonly outreach_status?: ListAssetManagersOutreachStatusEnum
}

/**
 * Request parameters for updateAssetManager operation in AssetManagerApi.
 * @export
 * @interface AssetManagerApiUpdateAssetManagerRequest
 */
export interface AssetManagerApiUpdateAssetManagerRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetManagerApiUpdateAssetManager
     */
    readonly asset_manager_id: string

    /**
     * 
     * @type {UpdateAssetManagerRequest}
     * @memberof AssetManagerApiUpdateAssetManager
     */
    readonly UpdateAssetManagerRequest?: UpdateAssetManagerRequest
}

/**
 * AssetManagerApi - object-oriented interface
 * @export
 * @class AssetManagerApi
 * @extends {BaseAPI}
 */
export class AssetManagerApi extends BaseAPI {
    /**
     * Create a new Asset Manager.
     * @param {AssetManagerApiCreateAssetManagerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetManagerApi
     */
    public createAssetManager(requestParameters: AssetManagerApiCreateAssetManagerRequest, options?: RawAxiosRequestConfig) {
        return AssetManagerApiFp(this.configuration).createAssetManager(requestParameters.CreateAssetManagerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an Asset Manager document.
     * @param {AssetManagerApiDeleteAssetManagerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetManagerApi
     */
    public deleteAssetManager(requestParameters: AssetManagerApiDeleteAssetManagerRequest, options?: RawAxiosRequestConfig) {
        return AssetManagerApiFp(this.configuration).deleteAssetManager(requestParameters.asset_manager_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a single asset manager
     * @param {AssetManagerApiGetAssetManagerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetManagerApi
     */
    public getAssetManager(requestParameters: AssetManagerApiGetAssetManagerRequest, options?: RawAxiosRequestConfig) {
        return AssetManagerApiFp(this.configuration).getAssetManager(requestParameters.asset_manager_id, requestParameters.populate_integrations, requestParameters.populate_connection_count, requestParameters.populate_connections, requestParameters.populate_note_count, requestParameters.populate_contacts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of asset managers
     * @param {AssetManagerApiListAssetManagersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetManagerApi
     */
    public listAssetManagers(requestParameters: AssetManagerApiListAssetManagersRequest = {}, options?: RawAxiosRequestConfig) {
        return AssetManagerApiFp(this.configuration).listAssetManagers(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.asset_manager_ids, requestParameters.populate_investment_masters, requestParameters.populate_integrations, requestParameters.populate_connection_count, requestParameters.populate_connections, requestParameters.populate_contacts, requestParameters.populate_note_count, requestParameters.search, requestParameters.connection_outreach_statuses, requestParameters.outreach_status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an Asset Manager document.
     * @param {AssetManagerApiUpdateAssetManagerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetManagerApi
     */
    public updateAssetManager(requestParameters: AssetManagerApiUpdateAssetManagerRequest, options?: RawAxiosRequestConfig) {
        return AssetManagerApiFp(this.configuration).updateAssetManager(requestParameters.asset_manager_id, requestParameters.UpdateAssetManagerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListAssetManagersSortFieldEnum = {
    CreatedAt: 'created_at',
    Date: 'date',
    Name: 'name',
    NotificationMethod: 'notification_method',
    Website: 'website',
    TaxDocumentDistributionMethod: 'tax_document_distribution_method',
    OutreachStatus: 'outreach_status',
    InitialOutreachDate: 'initial_outreach_date'
} as const;
export type ListAssetManagersSortFieldEnum = typeof ListAssetManagersSortFieldEnum[keyof typeof ListAssetManagersSortFieldEnum];
/**
 * @export
 */
export const ListAssetManagersSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListAssetManagersSortDirectionEnum = typeof ListAssetManagersSortDirectionEnum[keyof typeof ListAssetManagersSortDirectionEnum];
/**
 * @export
 */
export const ListAssetManagersConnectionOutreachStatusesEnum = {
    ConnectionCreated: 'Connection Created',
    ReadyForAmToStart: 'Ready For AM To Start',
    AmUnableToSupport: 'AM Unable To Support',
    AmNeedsMoreInformation: 'AM Needs More Information',
    ConnectionSetupCompleteByAm: 'Connection Setup Complete By AM',
    PortalCredentialsCreated: 'Portal Credentials Created'
} as const;
export type ListAssetManagersConnectionOutreachStatusesEnum = typeof ListAssetManagersConnectionOutreachStatusesEnum[keyof typeof ListAssetManagersConnectionOutreachStatusesEnum];
/**
 * @export
 */
export const ListAssetManagersOutreachStatusEnum = {
    NotStarted: 'Not Started',
    NoResponse: 'No Response',
    UnableToSupport: 'Unable to Support',
    GrantingPortalAccess: 'Granting Portal Access',
    InvestmentSetup: 'Investment Setup',
    Done: 'Done'
} as const;
export type ListAssetManagersOutreachStatusEnum = typeof ListAssetManagersOutreachStatusEnum[keyof typeof ListAssetManagersOutreachStatusEnum];


/**
 * AssetManagerPortalApi - axios parameter creator
 * @export
 */
export const AssetManagerPortalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get counts of connections in each outreach status for the asset manager.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStats: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/assetmanagers/portal/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication assetManagerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the list of advisory groups for an asset manager.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [asset_manager] 
         * @param {string} [connection] 
         * @param {Array<string>} [advisory_groups] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AssetmanagersPortalAdvisorygroupsGet: async (skip?: number, limit?: number, asset_manager?: string, connection?: string, advisory_groups?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/assetmanagers/portal/advisorygroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication assetManagerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (asset_manager !== undefined) {
                localVarQueryParameter['asset_manager'] = asset_manager;
            }

            if (connection !== undefined) {
                localVarQueryParameter['connection'] = connection;
            }

            if (advisory_groups) {
                localVarQueryParameter['advisory_groups'] = advisory_groups;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provide the ability for an asset manager to update their connection credentials and outreach status.
         * @param {string} id 
         * @param {V2AssetmanagersPortalConnectionsIdPatchRequest} [V2AssetmanagersPortalConnectionsIdPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AssetmanagersPortalConnectionsIdPatch: async (id: string, V2AssetmanagersPortalConnectionsIdPatchRequest?: V2AssetmanagersPortalConnectionsIdPatchRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2AssetmanagersPortalConnectionsIdPatch', 'id', id)
            const localVarPath = `/v2/assetmanagers/portal/connections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication assetManagerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(V2AssetmanagersPortalConnectionsIdPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch the assigned asset manager connections for the asset manager to work through.
         * @param {V2AssetmanagersPortalListGetSortFieldEnum} [sort_field] 
         * @param {string} [sort_direction] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [outreach_statuses] 
         * @param {string} [advisory_group] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AssetmanagersPortalListGet: async (sort_field?: V2AssetmanagersPortalListGetSortFieldEnum, sort_direction?: string, skip?: number, limit?: number, search?: string, outreach_statuses?: Array<string>, advisory_group?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/assetmanagers/portal/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication assetManagerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (outreach_statuses) {
                localVarQueryParameter['outreach_statuses'] = outreach_statuses;
            }

            if (advisory_group !== undefined) {
                localVarQueryParameter['advisory_group'] = advisory_group;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an authentication token for an asset manager to fetch and update information with.
         * @param {V2AssetmanagersPortalLoginPostRequest} V2AssetmanagersPortalLoginPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AssetmanagersPortalLoginPost: async (V2AssetmanagersPortalLoginPostRequest: V2AssetmanagersPortalLoginPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'V2AssetmanagersPortalLoginPostRequest' is not null or undefined
            assertParamExists('v2AssetmanagersPortalLoginPost', 'V2AssetmanagersPortalLoginPostRequest', V2AssetmanagersPortalLoginPostRequest)
            const localVarPath = `/v2/assetmanagers/portal/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication assetManagerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(V2AssetmanagersPortalLoginPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetManagerPortalApi - functional programming interface
 * @export
 */
export const AssetManagerPortalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetManagerPortalApiAxiosParamCreator(configuration)
    return {
        /**
         * Get counts of connections in each outreach status for the asset manager.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStats(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStats200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStats(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetManagerPortalApi.getStats']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the list of advisory groups for an asset manager.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [asset_manager] 
         * @param {string} [connection] 
         * @param {Array<string>} [advisory_groups] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2AssetmanagersPortalAdvisorygroupsGet(skip?: number, limit?: number, asset_manager?: string, connection?: string, advisory_groups?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2AssetmanagersPortalAdvisorygroupsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2AssetmanagersPortalAdvisorygroupsGet(skip, limit, asset_manager, connection, advisory_groups, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetManagerPortalApi.v2AssetmanagersPortalAdvisorygroupsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provide the ability for an asset manager to update their connection credentials and outreach status.
         * @param {string} id 
         * @param {V2AssetmanagersPortalConnectionsIdPatchRequest} [V2AssetmanagersPortalConnectionsIdPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2AssetmanagersPortalConnectionsIdPatch(id: string, V2AssetmanagersPortalConnectionsIdPatchRequest?: V2AssetmanagersPortalConnectionsIdPatchRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2AssetmanagersPortalConnectionsIdPatch(id, V2AssetmanagersPortalConnectionsIdPatchRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetManagerPortalApi.v2AssetmanagersPortalConnectionsIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetch the assigned asset manager connections for the asset manager to work through.
         * @param {V2AssetmanagersPortalListGetSortFieldEnum} [sort_field] 
         * @param {string} [sort_direction] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [outreach_statuses] 
         * @param {string} [advisory_group] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2AssetmanagersPortalListGet(sort_field?: V2AssetmanagersPortalListGetSortFieldEnum, sort_direction?: string, skip?: number, limit?: number, search?: string, outreach_statuses?: Array<string>, advisory_group?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2AssetmanagersPortalListGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2AssetmanagersPortalListGet(sort_field, sort_direction, skip, limit, search, outreach_statuses, advisory_group, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetManagerPortalApi.v2AssetmanagersPortalListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create an authentication token for an asset manager to fetch and update information with.
         * @param {V2AssetmanagersPortalLoginPostRequest} V2AssetmanagersPortalLoginPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2AssetmanagersPortalLoginPost(V2AssetmanagersPortalLoginPostRequest: V2AssetmanagersPortalLoginPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2AssetmanagersPortalLoginPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2AssetmanagersPortalLoginPost(V2AssetmanagersPortalLoginPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetManagerPortalApi.v2AssetmanagersPortalLoginPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssetManagerPortalApi - factory interface
 * @export
 */
export const AssetManagerPortalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetManagerPortalApiFp(configuration)
    return {
        /**
         * Get counts of connections in each outreach status for the asset manager.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStats(options?: RawAxiosRequestConfig): AxiosPromise<GetStats200Response> {
            return localVarFp.getStats(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the list of advisory groups for an asset manager.
         * @param {AssetManagerPortalApiV2AssetmanagersPortalAdvisorygroupsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AssetmanagersPortalAdvisorygroupsGet(requestParameters: AssetManagerPortalApiV2AssetmanagersPortalAdvisorygroupsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<V2AssetmanagersPortalAdvisorygroupsGet200Response> {
            return localVarFp.v2AssetmanagersPortalAdvisorygroupsGet(requestParameters.skip, requestParameters.limit, requestParameters.asset_manager, requestParameters.connection, requestParameters.advisory_groups, options).then((request) => request(axios, basePath));
        },
        /**
         * Provide the ability for an asset manager to update their connection credentials and outreach status.
         * @param {AssetManagerPortalApiV2AssetmanagersPortalConnectionsIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AssetmanagersPortalConnectionsIdPatch(requestParameters: AssetManagerPortalApiV2AssetmanagersPortalConnectionsIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.v2AssetmanagersPortalConnectionsIdPatch(requestParameters.id, requestParameters.V2AssetmanagersPortalConnectionsIdPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch the assigned asset manager connections for the asset manager to work through.
         * @param {AssetManagerPortalApiV2AssetmanagersPortalListGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AssetmanagersPortalListGet(requestParameters: AssetManagerPortalApiV2AssetmanagersPortalListGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<V2AssetmanagersPortalListGet200Response> {
            return localVarFp.v2AssetmanagersPortalListGet(requestParameters.sort_field, requestParameters.sort_direction, requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.outreach_statuses, requestParameters.advisory_group, options).then((request) => request(axios, basePath));
        },
        /**
         * Create an authentication token for an asset manager to fetch and update information with.
         * @param {AssetManagerPortalApiV2AssetmanagersPortalLoginPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AssetmanagersPortalLoginPost(requestParameters: AssetManagerPortalApiV2AssetmanagersPortalLoginPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<V2AssetmanagersPortalLoginPost200Response> {
            return localVarFp.v2AssetmanagersPortalLoginPost(requestParameters.V2AssetmanagersPortalLoginPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v2AssetmanagersPortalAdvisorygroupsGet operation in AssetManagerPortalApi.
 * @export
 * @interface AssetManagerPortalApiV2AssetmanagersPortalAdvisorygroupsGetRequest
 */
export interface AssetManagerPortalApiV2AssetmanagersPortalAdvisorygroupsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalAdvisorygroupsGet
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalAdvisorygroupsGet
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalAdvisorygroupsGet
     */
    readonly asset_manager?: string

    /**
     * 
     * @type {string}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalAdvisorygroupsGet
     */
    readonly connection?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalAdvisorygroupsGet
     */
    readonly advisory_groups?: Array<string>
}

/**
 * Request parameters for v2AssetmanagersPortalConnectionsIdPatch operation in AssetManagerPortalApi.
 * @export
 * @interface AssetManagerPortalApiV2AssetmanagersPortalConnectionsIdPatchRequest
 */
export interface AssetManagerPortalApiV2AssetmanagersPortalConnectionsIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalConnectionsIdPatch
     */
    readonly id: string

    /**
     * 
     * @type {V2AssetmanagersPortalConnectionsIdPatchRequest}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalConnectionsIdPatch
     */
    readonly V2AssetmanagersPortalConnectionsIdPatchRequest?: V2AssetmanagersPortalConnectionsIdPatchRequest
}

/**
 * Request parameters for v2AssetmanagersPortalListGet operation in AssetManagerPortalApi.
 * @export
 * @interface AssetManagerPortalApiV2AssetmanagersPortalListGetRequest
 */
export interface AssetManagerPortalApiV2AssetmanagersPortalListGetRequest {
    /**
     * 
     * @type {'user_name' | 'name' | 'created_at' | 'updated_at' | 'outreach_status'}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalListGet
     */
    readonly sort_field?: V2AssetmanagersPortalListGetSortFieldEnum

    /**
     * 
     * @type {string}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalListGet
     */
    readonly sort_direction?: string

    /**
     * 
     * @type {number}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalListGet
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalListGet
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalListGet
     */
    readonly search?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalListGet
     */
    readonly outreach_statuses?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalListGet
     */
    readonly advisory_group?: string
}

/**
 * Request parameters for v2AssetmanagersPortalLoginPost operation in AssetManagerPortalApi.
 * @export
 * @interface AssetManagerPortalApiV2AssetmanagersPortalLoginPostRequest
 */
export interface AssetManagerPortalApiV2AssetmanagersPortalLoginPostRequest {
    /**
     * 
     * @type {V2AssetmanagersPortalLoginPostRequest}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalLoginPost
     */
    readonly V2AssetmanagersPortalLoginPostRequest: V2AssetmanagersPortalLoginPostRequest
}

/**
 * AssetManagerPortalApi - object-oriented interface
 * @export
 * @class AssetManagerPortalApi
 * @extends {BaseAPI}
 */
export class AssetManagerPortalApi extends BaseAPI {
    /**
     * Get counts of connections in each outreach status for the asset manager.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetManagerPortalApi
     */
    public getStats(options?: RawAxiosRequestConfig) {
        return AssetManagerPortalApiFp(this.configuration).getStats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the list of advisory groups for an asset manager.
     * @param {AssetManagerPortalApiV2AssetmanagersPortalAdvisorygroupsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetManagerPortalApi
     */
    public v2AssetmanagersPortalAdvisorygroupsGet(requestParameters: AssetManagerPortalApiV2AssetmanagersPortalAdvisorygroupsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return AssetManagerPortalApiFp(this.configuration).v2AssetmanagersPortalAdvisorygroupsGet(requestParameters.skip, requestParameters.limit, requestParameters.asset_manager, requestParameters.connection, requestParameters.advisory_groups, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provide the ability for an asset manager to update their connection credentials and outreach status.
     * @param {AssetManagerPortalApiV2AssetmanagersPortalConnectionsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetManagerPortalApi
     */
    public v2AssetmanagersPortalConnectionsIdPatch(requestParameters: AssetManagerPortalApiV2AssetmanagersPortalConnectionsIdPatchRequest, options?: RawAxiosRequestConfig) {
        return AssetManagerPortalApiFp(this.configuration).v2AssetmanagersPortalConnectionsIdPatch(requestParameters.id, requestParameters.V2AssetmanagersPortalConnectionsIdPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch the assigned asset manager connections for the asset manager to work through.
     * @param {AssetManagerPortalApiV2AssetmanagersPortalListGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetManagerPortalApi
     */
    public v2AssetmanagersPortalListGet(requestParameters: AssetManagerPortalApiV2AssetmanagersPortalListGetRequest = {}, options?: RawAxiosRequestConfig) {
        return AssetManagerPortalApiFp(this.configuration).v2AssetmanagersPortalListGet(requestParameters.sort_field, requestParameters.sort_direction, requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.outreach_statuses, requestParameters.advisory_group, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an authentication token for an asset manager to fetch and update information with.
     * @param {AssetManagerPortalApiV2AssetmanagersPortalLoginPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetManagerPortalApi
     */
    public v2AssetmanagersPortalLoginPost(requestParameters: AssetManagerPortalApiV2AssetmanagersPortalLoginPostRequest, options?: RawAxiosRequestConfig) {
        return AssetManagerPortalApiFp(this.configuration).v2AssetmanagersPortalLoginPost(requestParameters.V2AssetmanagersPortalLoginPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const V2AssetmanagersPortalListGetSortFieldEnum = {
    UserName: 'user_name',
    Name: 'name',
    CreatedAt: 'created_at',
    UpdatedAt: 'updated_at',
    OutreachStatus: 'outreach_status'
} as const;
export type V2AssetmanagersPortalListGetSortFieldEnum = typeof V2AssetmanagersPortalListGetSortFieldEnum[keyof typeof V2AssetmanagersPortalListGetSortFieldEnum];


/**
 * AuditResultApi - axios parameter creator
 * @export
 */
export const AuditResultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete an AuditResult document by ID.
         * @param {string} audit_result_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuditResult: async (audit_result_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'audit_result_id' is not null or undefined
            assertParamExists('deleteAuditResult', 'audit_result_id', audit_result_id)
            const localVarPath = `/v2/auditresults/{audit_result_id}`
                .replace(`{${"audit_result_id"}}`, encodeURIComponent(String(audit_result_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single AuditResult document by ID.
         * @param {string | null} audit_result_id 
         * @param {boolean} [populate_breadcrumbs] 
         * @param {boolean} [populate_completed_by] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditResult: async (audit_result_id: string | null, populate_breadcrumbs?: boolean, populate_completed_by?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'audit_result_id' is not null or undefined
            assertParamExists('getAuditResult', 'audit_result_id', audit_result_id)
            const localVarPath = `/v2/auditresults/{audit_result_id}`
                .replace(`{${"audit_result_id"}}`, encodeURIComponent(String(audit_result_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_breadcrumbs !== undefined) {
                localVarQueryParameter['populate_breadcrumbs'] = populate_breadcrumbs;
            }

            if (populate_completed_by !== undefined) {
                localVarQueryParameter['populate_completed_by'] = populate_completed_by;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the types of audit results.
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [investments] 
         * @param {boolean} [read] 
         * @param {boolean} [flagged] 
         * @param {GetAuditResultTypesStatusEnum} [status] 
         * @param {boolean} [hidden_investments] 
         * @param {number} [sla_level] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditResultTypes: async (users?: Array<string>, accounts?: Array<string>, investments?: Array<string>, read?: boolean, flagged?: boolean, status?: GetAuditResultTypesStatusEnum, hidden_investments?: boolean, sla_level?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/auditresults/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (accounts) {
                localVarQueryParameter['accounts'] = accounts;
            }

            if (investments) {
                localVarQueryParameter['investments'] = investments;
            }

            if (read !== undefined) {
                localVarQueryParameter['read'] = read;
            }

            if (flagged !== undefined) {
                localVarQueryParameter['flagged'] = flagged;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (hidden_investments !== undefined) {
                localVarQueryParameter['hidden_investments'] = hidden_investments;
            }

            if (sla_level !== undefined) {
                localVarQueryParameter['sla_level'] = sla_level;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through AuditResult documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListAuditResultsSortFieldEnum} [sort_field] 
         * @param {ListAuditResultsSortDirectionEnum} [sort_direction] 
         * @param {ListAuditResultsCheckNameEnum} [check_name] 
         * @param {string} [check_subname] 
         * @param {boolean} [read] 
         * @param {boolean} [flagged] 
         * @param {ListAuditResultsStatusEnum} [status] 
         * @param {ListAuditResultsCategoryEnum} [category] 
         * @param {Array<string>} [advisory_groups] 
         * @param {number} [sla_level] 
         * @param {string | null} [investment_by_investment_master] 
         * @param {boolean} [hidden_investments] 
         * @param {boolean} [populate_breadcrumbs] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [investments] 
         * @param {Array<string>} [documents] 
         * @param {Array<string>} [transactions] 
         * @param {Array<string>} [valuations] 
         * @param {Array<string>} [connections] 
         * @param {Array<string>} [messages] 
         * @param {Array<string>} [investment_masters] 
         * @param {Array<string>} [integrations] 
         * @param {ListAuditResultsOwnershipTypeEnum} [ownership_type] 
         * @param {Array<string>} [ownership_ids] 
         * @param {string | null} [extegrationsync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAuditResults: async (skip?: number, limit?: number, sort_field?: ListAuditResultsSortFieldEnum, sort_direction?: ListAuditResultsSortDirectionEnum, check_name?: ListAuditResultsCheckNameEnum, check_subname?: string, read?: boolean, flagged?: boolean, status?: ListAuditResultsStatusEnum, category?: ListAuditResultsCategoryEnum, advisory_groups?: Array<string>, sla_level?: number, investment_by_investment_master?: string | null, hidden_investments?: boolean, populate_breadcrumbs?: boolean, users?: Array<string>, accounts?: Array<string>, investments?: Array<string>, documents?: Array<string>, transactions?: Array<string>, valuations?: Array<string>, connections?: Array<string>, messages?: Array<string>, investment_masters?: Array<string>, integrations?: Array<string>, ownership_type?: ListAuditResultsOwnershipTypeEnum, ownership_ids?: Array<string>, extegrationsync?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/auditresults/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (check_name !== undefined) {
                localVarQueryParameter['check_name'] = check_name;
            }

            if (check_subname !== undefined) {
                localVarQueryParameter['check_subname'] = check_subname;
            }

            if (read !== undefined) {
                localVarQueryParameter['read'] = read;
            }

            if (flagged !== undefined) {
                localVarQueryParameter['flagged'] = flagged;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (advisory_groups) {
                localVarQueryParameter['advisory_groups'] = advisory_groups;
            }

            if (sla_level !== undefined) {
                localVarQueryParameter['sla_level'] = sla_level;
            }

            if (investment_by_investment_master !== undefined) {
                localVarQueryParameter['investment_by_investment_master'] = investment_by_investment_master;
            }

            if (hidden_investments !== undefined) {
                localVarQueryParameter['hidden_investments'] = hidden_investments;
            }

            if (populate_breadcrumbs !== undefined) {
                localVarQueryParameter['populate_breadcrumbs'] = populate_breadcrumbs;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (accounts) {
                localVarQueryParameter['accounts'] = accounts;
            }

            if (investments) {
                localVarQueryParameter['investments'] = investments;
            }

            if (documents) {
                localVarQueryParameter['documents'] = documents;
            }

            if (transactions) {
                localVarQueryParameter['transactions'] = transactions;
            }

            if (valuations) {
                localVarQueryParameter['valuations'] = valuations;
            }

            if (connections) {
                localVarQueryParameter['connections'] = connections;
            }

            if (messages) {
                localVarQueryParameter['messages'] = messages;
            }

            if (investment_masters) {
                localVarQueryParameter['investment_masters'] = investment_masters;
            }

            if (integrations) {
                localVarQueryParameter['integrations'] = integrations;
            }

            if (ownership_type !== undefined) {
                localVarQueryParameter['ownership_type'] = ownership_type;
            }

            if (ownership_ids) {
                localVarQueryParameter['ownership_ids'] = ownership_ids;
            }

            if (extegrationsync !== undefined) {
                localVarQueryParameter['extegrationsync'] = extegrationsync;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Resolve an audit result by marking it as completed.
         * @param {string} audit_result_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resolveAuditResult: async (audit_result_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'audit_result_id' is not null or undefined
            assertParamExists('resolveAuditResult', 'audit_result_id', audit_result_id)
            const localVarPath = `/v2/auditresults/{audit_result_id}/resolve`
                .replace(`{${"audit_result_id"}}`, encodeURIComponent(String(audit_result_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Run SLA issues for a user or investment
         * @param {RunSlaIssuesRequest} [RunSlaIssuesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runSlaIssues: async (RunSlaIssuesRequest?: RunSlaIssuesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/auditresults/run_sla_issues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(RunSlaIssuesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an AuditResult document.
         * @param {string} audit_result_id 
         * @param {UpdateAuditResultRequest} [UpdateAuditResultRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAuditResult: async (audit_result_id: string, UpdateAuditResultRequest?: UpdateAuditResultRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'audit_result_id' is not null or undefined
            assertParamExists('updateAuditResult', 'audit_result_id', audit_result_id)
            const localVarPath = `/v2/auditresults/{audit_result_id}`
                .replace(`{${"audit_result_id"}}`, encodeURIComponent(String(audit_result_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateAuditResultRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditResultApi - functional programming interface
 * @export
 */
export const AuditResultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditResultApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete an AuditResult document by ID.
         * @param {string} audit_result_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAuditResult(audit_result_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAuditResult(audit_result_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditResultApi.deleteAuditResult']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single AuditResult document by ID.
         * @param {string | null} audit_result_id 
         * @param {boolean} [populate_breadcrumbs] 
         * @param {boolean} [populate_completed_by] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuditResult(audit_result_id: string | null, populate_breadcrumbs?: boolean, populate_completed_by?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAuditResult200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuditResult(audit_result_id, populate_breadcrumbs, populate_completed_by, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditResultApi.getAuditResult']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the types of audit results.
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [investments] 
         * @param {boolean} [read] 
         * @param {boolean} [flagged] 
         * @param {GetAuditResultTypesStatusEnum} [status] 
         * @param {boolean} [hidden_investments] 
         * @param {number} [sla_level] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuditResultTypes(users?: Array<string>, accounts?: Array<string>, investments?: Array<string>, read?: boolean, flagged?: boolean, status?: GetAuditResultTypesStatusEnum, hidden_investments?: boolean, sla_level?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAuditResultTypes200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuditResultTypes(users, accounts, investments, read, flagged, status, hidden_investments, sla_level, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditResultApi.getAuditResultTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through AuditResult documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListAuditResultsSortFieldEnum} [sort_field] 
         * @param {ListAuditResultsSortDirectionEnum} [sort_direction] 
         * @param {ListAuditResultsCheckNameEnum} [check_name] 
         * @param {string} [check_subname] 
         * @param {boolean} [read] 
         * @param {boolean} [flagged] 
         * @param {ListAuditResultsStatusEnum} [status] 
         * @param {ListAuditResultsCategoryEnum} [category] 
         * @param {Array<string>} [advisory_groups] 
         * @param {number} [sla_level] 
         * @param {string | null} [investment_by_investment_master] 
         * @param {boolean} [hidden_investments] 
         * @param {boolean} [populate_breadcrumbs] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [investments] 
         * @param {Array<string>} [documents] 
         * @param {Array<string>} [transactions] 
         * @param {Array<string>} [valuations] 
         * @param {Array<string>} [connections] 
         * @param {Array<string>} [messages] 
         * @param {Array<string>} [investment_masters] 
         * @param {Array<string>} [integrations] 
         * @param {ListAuditResultsOwnershipTypeEnum} [ownership_type] 
         * @param {Array<string>} [ownership_ids] 
         * @param {string | null} [extegrationsync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAuditResults(skip?: number, limit?: number, sort_field?: ListAuditResultsSortFieldEnum, sort_direction?: ListAuditResultsSortDirectionEnum, check_name?: ListAuditResultsCheckNameEnum, check_subname?: string, read?: boolean, flagged?: boolean, status?: ListAuditResultsStatusEnum, category?: ListAuditResultsCategoryEnum, advisory_groups?: Array<string>, sla_level?: number, investment_by_investment_master?: string | null, hidden_investments?: boolean, populate_breadcrumbs?: boolean, users?: Array<string>, accounts?: Array<string>, investments?: Array<string>, documents?: Array<string>, transactions?: Array<string>, valuations?: Array<string>, connections?: Array<string>, messages?: Array<string>, investment_masters?: Array<string>, integrations?: Array<string>, ownership_type?: ListAuditResultsOwnershipTypeEnum, ownership_ids?: Array<string>, extegrationsync?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAuditResults200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAuditResults(skip, limit, sort_field, sort_direction, check_name, check_subname, read, flagged, status, category, advisory_groups, sla_level, investment_by_investment_master, hidden_investments, populate_breadcrumbs, users, accounts, investments, documents, transactions, valuations, connections, messages, investment_masters, integrations, ownership_type, ownership_ids, extegrationsync, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditResultApi.listAuditResults']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Resolve an audit result by marking it as completed.
         * @param {string} audit_result_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resolveAuditResult(audit_result_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResolveAuditResult200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resolveAuditResult(audit_result_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditResultApi.resolveAuditResult']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Run SLA issues for a user or investment
         * @param {RunSlaIssuesRequest} [RunSlaIssuesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runSlaIssues(RunSlaIssuesRequest?: RunSlaIssuesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunSlaIssues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runSlaIssues(RunSlaIssuesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditResultApi.runSlaIssues']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an AuditResult document.
         * @param {string} audit_result_id 
         * @param {UpdateAuditResultRequest} [UpdateAuditResultRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAuditResult(audit_result_id: string, UpdateAuditResultRequest?: UpdateAuditResultRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAuditResult(audit_result_id, UpdateAuditResultRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditResultApi.updateAuditResult']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuditResultApi - factory interface
 * @export
 */
export const AuditResultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditResultApiFp(configuration)
    return {
        /**
         * Delete an AuditResult document by ID.
         * @param {AuditResultApiDeleteAuditResultRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuditResult(requestParameters: AuditResultApiDeleteAuditResultRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteAuditResult(requestParameters.audit_result_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single AuditResult document by ID.
         * @param {AuditResultApiGetAuditResultRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditResult(requestParameters: AuditResultApiGetAuditResultRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetAuditResult200Response> {
            return localVarFp.getAuditResult(requestParameters.audit_result_id, requestParameters.populate_breadcrumbs, requestParameters.populate_completed_by, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the types of audit results.
         * @param {AuditResultApiGetAuditResultTypesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditResultTypes(requestParameters: AuditResultApiGetAuditResultTypesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetAuditResultTypes200Response> {
            return localVarFp.getAuditResultTypes(requestParameters.users, requestParameters.accounts, requestParameters.investments, requestParameters.read, requestParameters.flagged, requestParameters.status, requestParameters.hidden_investments, requestParameters.sla_level, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through AuditResult documents.
         * @param {AuditResultApiListAuditResultsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAuditResults(requestParameters: AuditResultApiListAuditResultsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListAuditResults200Response> {
            return localVarFp.listAuditResults(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.check_name, requestParameters.check_subname, requestParameters.read, requestParameters.flagged, requestParameters.status, requestParameters.category, requestParameters.advisory_groups, requestParameters.sla_level, requestParameters.investment_by_investment_master, requestParameters.hidden_investments, requestParameters.populate_breadcrumbs, requestParameters.users, requestParameters.accounts, requestParameters.investments, requestParameters.documents, requestParameters.transactions, requestParameters.valuations, requestParameters.connections, requestParameters.messages, requestParameters.investment_masters, requestParameters.integrations, requestParameters.ownership_type, requestParameters.ownership_ids, requestParameters.extegrationsync, options).then((request) => request(axios, basePath));
        },
        /**
         * Resolve an audit result by marking it as completed.
         * @param {AuditResultApiResolveAuditResultRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resolveAuditResult(requestParameters: AuditResultApiResolveAuditResultRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResolveAuditResult200Response> {
            return localVarFp.resolveAuditResult(requestParameters.audit_result_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Run SLA issues for a user or investment
         * @param {AuditResultApiRunSlaIssuesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runSlaIssues(requestParameters: AuditResultApiRunSlaIssuesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RunSlaIssues200Response> {
            return localVarFp.runSlaIssues(requestParameters.RunSlaIssuesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an AuditResult document.
         * @param {AuditResultApiUpdateAuditResultRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAuditResult(requestParameters: AuditResultApiUpdateAuditResultRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateAuditResult(requestParameters.audit_result_id, requestParameters.UpdateAuditResultRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteAuditResult operation in AuditResultApi.
 * @export
 * @interface AuditResultApiDeleteAuditResultRequest
 */
export interface AuditResultApiDeleteAuditResultRequest {
    /**
     * 
     * @type {string}
     * @memberof AuditResultApiDeleteAuditResult
     */
    readonly audit_result_id: string
}

/**
 * Request parameters for getAuditResult operation in AuditResultApi.
 * @export
 * @interface AuditResultApiGetAuditResultRequest
 */
export interface AuditResultApiGetAuditResultRequest {
    /**
     * 
     * @type {string}
     * @memberof AuditResultApiGetAuditResult
     */
    readonly audit_result_id: string | null

    /**
     * 
     * @type {boolean}
     * @memberof AuditResultApiGetAuditResult
     */
    readonly populate_breadcrumbs?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AuditResultApiGetAuditResult
     */
    readonly populate_completed_by?: boolean
}

/**
 * Request parameters for getAuditResultTypes operation in AuditResultApi.
 * @export
 * @interface AuditResultApiGetAuditResultTypesRequest
 */
export interface AuditResultApiGetAuditResultTypesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AuditResultApiGetAuditResultTypes
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AuditResultApiGetAuditResultTypes
     */
    readonly accounts?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AuditResultApiGetAuditResultTypes
     */
    readonly investments?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof AuditResultApiGetAuditResultTypes
     */
    readonly read?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AuditResultApiGetAuditResultTypes
     */
    readonly flagged?: boolean

    /**
     * 
     * @type {'active' | 'ignored' | 'auto-ignored' | 'completed'}
     * @memberof AuditResultApiGetAuditResultTypes
     */
    readonly status?: GetAuditResultTypesStatusEnum

    /**
     * 
     * @type {boolean}
     * @memberof AuditResultApiGetAuditResultTypes
     */
    readonly hidden_investments?: boolean

    /**
     * 
     * @type {number}
     * @memberof AuditResultApiGetAuditResultTypes
     */
    readonly sla_level?: number
}

/**
 * Request parameters for listAuditResults operation in AuditResultApi.
 * @export
 * @interface AuditResultApiListAuditResultsRequest
 */
export interface AuditResultApiListAuditResultsRequest {
    /**
     * 
     * @type {number}
     * @memberof AuditResultApiListAuditResults
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AuditResultApiListAuditResults
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'check_name' | 'breadcrumbs.investment' | 'parent_id' | 'sla_level' | 'deadline' | 'updated_at' | 'status' | 'flagged'}
     * @memberof AuditResultApiListAuditResults
     */
    readonly sort_field?: ListAuditResultsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof AuditResultApiListAuditResults
     */
    readonly sort_direction?: ListAuditResultsSortDirectionEnum

    /**
     * 
     * @type {'Security Master Documents Received by All Investments' | 'Investment Under Administrative Review' | 'Unrealized Value Spike' | 'Ending/beginning balance mismatch' | 'Invalid date range' | 'Inaccurate distributions sum' | 'Inaccurate contributions sum' | 'Cap/NAV Statement Timeliness' | 'Have Cap/NAV Statement every period' | 'Tax Document Timeliness' | 'Has Tax Document every year since investment start' | 'Transaction with default date data' | 'Duplicate Transactions' | 'Unsorted Document' | 'Capital Statement With Invalid Metadata' | 'Capital Call With Invalid Metadata' | 'Distribution With Invalid Metadata' | 'NAV Statement WithInvalid Metadata' | 'Net Capital Call With Invalid Metadata' | 'Net Distribution With Invalid Metadata' | 'Tax Document With Invalid Metadata' | 'Manual Connection Portal Sync' | 'Connection Status Not Active' | 'Connection Without Contacts' | 'Connection Completed By Asset Manager' | 'Unmapped Data in Most Recent Connection Sync (Staging Area)' | 'Messages Unread for 3 Days' | 'Messages with Documents Attached' | 'Messages Flagged for 5 Days' | 'Fund investments without Security Master Ticker' | 'Fund investments missing Subtype' | 'Non-hidden Investment has type Diverse Investments' | 'Investments without past year tax doc' | 'Abnormal Investment IRR' | 'Investment Hidden Too Long' | 'Investment Type / Statement Type Mismatch' | 'Investment with negative unfunded amount' | 'Extegration Sync Failed by Advisory Group'}
     * @memberof AuditResultApiListAuditResults
     */
    readonly check_name?: ListAuditResultsCheckNameEnum

    /**
     * 
     * @type {string}
     * @memberof AuditResultApiListAuditResults
     */
    readonly check_subname?: string

    /**
     * 
     * @type {boolean}
     * @memberof AuditResultApiListAuditResults
     */
    readonly read?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AuditResultApiListAuditResults
     */
    readonly flagged?: boolean

    /**
     * 
     * @type {'active' | 'ignored' | 'auto-ignored' | 'completed'}
     * @memberof AuditResultApiListAuditResults
     */
    readonly status?: ListAuditResultsStatusEnum

    /**
     * 
     * @type {'None' | 'BIQC' | 'Sorting' | 'Tagging' | 'Document Processing' | 'Integrations' | 'Users' | 'Entities' | 'Connections' | 'Messages' | 'Advisor' | 'Investments' | 'RALs' | 'Security Master' | 'Extegrations'}
     * @memberof AuditResultApiListAuditResults
     */
    readonly category?: ListAuditResultsCategoryEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof AuditResultApiListAuditResults
     */
    readonly advisory_groups?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof AuditResultApiListAuditResults
     */
    readonly sla_level?: number

    /**
     * 
     * @type {string}
     * @memberof AuditResultApiListAuditResults
     */
    readonly investment_by_investment_master?: string | null

    /**
     * 
     * @type {boolean}
     * @memberof AuditResultApiListAuditResults
     */
    readonly hidden_investments?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AuditResultApiListAuditResults
     */
    readonly populate_breadcrumbs?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof AuditResultApiListAuditResults
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AuditResultApiListAuditResults
     */
    readonly accounts?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AuditResultApiListAuditResults
     */
    readonly investments?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AuditResultApiListAuditResults
     */
    readonly documents?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AuditResultApiListAuditResults
     */
    readonly transactions?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AuditResultApiListAuditResults
     */
    readonly valuations?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AuditResultApiListAuditResults
     */
    readonly connections?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AuditResultApiListAuditResults
     */
    readonly messages?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AuditResultApiListAuditResults
     */
    readonly investment_masters?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AuditResultApiListAuditResults
     */
    readonly integrations?: Array<string>

    /**
     * 
     * @type {'Account' | 'Investment'}
     * @memberof AuditResultApiListAuditResults
     */
    readonly ownership_type?: ListAuditResultsOwnershipTypeEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof AuditResultApiListAuditResults
     */
    readonly ownership_ids?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof AuditResultApiListAuditResults
     */
    readonly extegrationsync?: string | null
}

/**
 * Request parameters for resolveAuditResult operation in AuditResultApi.
 * @export
 * @interface AuditResultApiResolveAuditResultRequest
 */
export interface AuditResultApiResolveAuditResultRequest {
    /**
     * 
     * @type {string}
     * @memberof AuditResultApiResolveAuditResult
     */
    readonly audit_result_id: string
}

/**
 * Request parameters for runSlaIssues operation in AuditResultApi.
 * @export
 * @interface AuditResultApiRunSlaIssuesRequest
 */
export interface AuditResultApiRunSlaIssuesRequest {
    /**
     * 
     * @type {RunSlaIssuesRequest}
     * @memberof AuditResultApiRunSlaIssues
     */
    readonly RunSlaIssuesRequest?: RunSlaIssuesRequest
}

/**
 * Request parameters for updateAuditResult operation in AuditResultApi.
 * @export
 * @interface AuditResultApiUpdateAuditResultRequest
 */
export interface AuditResultApiUpdateAuditResultRequest {
    /**
     * 
     * @type {string}
     * @memberof AuditResultApiUpdateAuditResult
     */
    readonly audit_result_id: string

    /**
     * 
     * @type {UpdateAuditResultRequest}
     * @memberof AuditResultApiUpdateAuditResult
     */
    readonly UpdateAuditResultRequest?: UpdateAuditResultRequest
}

/**
 * AuditResultApi - object-oriented interface
 * @export
 * @class AuditResultApi
 * @extends {BaseAPI}
 */
export class AuditResultApi extends BaseAPI {
    /**
     * Delete an AuditResult document by ID.
     * @param {AuditResultApiDeleteAuditResultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditResultApi
     */
    public deleteAuditResult(requestParameters: AuditResultApiDeleteAuditResultRequest, options?: RawAxiosRequestConfig) {
        return AuditResultApiFp(this.configuration).deleteAuditResult(requestParameters.audit_result_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single AuditResult document by ID.
     * @param {AuditResultApiGetAuditResultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditResultApi
     */
    public getAuditResult(requestParameters: AuditResultApiGetAuditResultRequest, options?: RawAxiosRequestConfig) {
        return AuditResultApiFp(this.configuration).getAuditResult(requestParameters.audit_result_id, requestParameters.populate_breadcrumbs, requestParameters.populate_completed_by, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the types of audit results.
     * @param {AuditResultApiGetAuditResultTypesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditResultApi
     */
    public getAuditResultTypes(requestParameters: AuditResultApiGetAuditResultTypesRequest = {}, options?: RawAxiosRequestConfig) {
        return AuditResultApiFp(this.configuration).getAuditResultTypes(requestParameters.users, requestParameters.accounts, requestParameters.investments, requestParameters.read, requestParameters.flagged, requestParameters.status, requestParameters.hidden_investments, requestParameters.sla_level, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through AuditResult documents.
     * @param {AuditResultApiListAuditResultsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditResultApi
     */
    public listAuditResults(requestParameters: AuditResultApiListAuditResultsRequest = {}, options?: RawAxiosRequestConfig) {
        return AuditResultApiFp(this.configuration).listAuditResults(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.check_name, requestParameters.check_subname, requestParameters.read, requestParameters.flagged, requestParameters.status, requestParameters.category, requestParameters.advisory_groups, requestParameters.sla_level, requestParameters.investment_by_investment_master, requestParameters.hidden_investments, requestParameters.populate_breadcrumbs, requestParameters.users, requestParameters.accounts, requestParameters.investments, requestParameters.documents, requestParameters.transactions, requestParameters.valuations, requestParameters.connections, requestParameters.messages, requestParameters.investment_masters, requestParameters.integrations, requestParameters.ownership_type, requestParameters.ownership_ids, requestParameters.extegrationsync, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Resolve an audit result by marking it as completed.
     * @param {AuditResultApiResolveAuditResultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditResultApi
     */
    public resolveAuditResult(requestParameters: AuditResultApiResolveAuditResultRequest, options?: RawAxiosRequestConfig) {
        return AuditResultApiFp(this.configuration).resolveAuditResult(requestParameters.audit_result_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Run SLA issues for a user or investment
     * @param {AuditResultApiRunSlaIssuesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditResultApi
     */
    public runSlaIssues(requestParameters: AuditResultApiRunSlaIssuesRequest = {}, options?: RawAxiosRequestConfig) {
        return AuditResultApiFp(this.configuration).runSlaIssues(requestParameters.RunSlaIssuesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an AuditResult document.
     * @param {AuditResultApiUpdateAuditResultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditResultApi
     */
    public updateAuditResult(requestParameters: AuditResultApiUpdateAuditResultRequest, options?: RawAxiosRequestConfig) {
        return AuditResultApiFp(this.configuration).updateAuditResult(requestParameters.audit_result_id, requestParameters.UpdateAuditResultRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAuditResultTypesStatusEnum = {
    Active: 'active',
    Ignored: 'ignored',
    AutoIgnored: 'auto-ignored',
    Completed: 'completed'
} as const;
export type GetAuditResultTypesStatusEnum = typeof GetAuditResultTypesStatusEnum[keyof typeof GetAuditResultTypesStatusEnum];
/**
 * @export
 */
export const ListAuditResultsSortFieldEnum = {
    CreatedAt: 'created_at',
    CheckName: 'check_name',
    BreadcrumbsInvestment: 'breadcrumbs.investment',
    ParentId: 'parent_id',
    SlaLevel: 'sla_level',
    Deadline: 'deadline',
    UpdatedAt: 'updated_at',
    Status: 'status',
    Flagged: 'flagged'
} as const;
export type ListAuditResultsSortFieldEnum = typeof ListAuditResultsSortFieldEnum[keyof typeof ListAuditResultsSortFieldEnum];
/**
 * @export
 */
export const ListAuditResultsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListAuditResultsSortDirectionEnum = typeof ListAuditResultsSortDirectionEnum[keyof typeof ListAuditResultsSortDirectionEnum];
/**
 * @export
 */
export const ListAuditResultsCheckNameEnum = {
    SecurityMasterDocumentsReceivedByAllInvestments: 'Security Master Documents Received by All Investments',
    InvestmentUnderAdministrativeReview: 'Investment Under Administrative Review',
    UnrealizedValueSpike: 'Unrealized Value Spike',
    EndingBeginningBalanceMismatch: 'Ending/beginning balance mismatch',
    InvalidDateRange: 'Invalid date range',
    InaccurateDistributionsSum: 'Inaccurate distributions sum',
    InaccurateContributionsSum: 'Inaccurate contributions sum',
    CapNavStatementTimeliness: 'Cap/NAV Statement Timeliness',
    HaveCapNavStatementEveryPeriod: 'Have Cap/NAV Statement every period',
    TaxDocumentTimeliness: 'Tax Document Timeliness',
    HasTaxDocumentEveryYearSinceInvestmentStart: 'Has Tax Document every year since investment start',
    TransactionWithDefaultDateData: 'Transaction with default date data',
    DuplicateTransactions: 'Duplicate Transactions',
    UnsortedDocument: 'Unsorted Document',
    CapitalStatementWithInvalidMetadata: 'Capital Statement With Invalid Metadata',
    CapitalCallWithInvalidMetadata: 'Capital Call With Invalid Metadata',
    DistributionWithInvalidMetadata: 'Distribution With Invalid Metadata',
    NavStatementWithInvalidMetadata: 'NAV Statement WithInvalid Metadata',
    NetCapitalCallWithInvalidMetadata: 'Net Capital Call With Invalid Metadata',
    NetDistributionWithInvalidMetadata: 'Net Distribution With Invalid Metadata',
    TaxDocumentWithInvalidMetadata: 'Tax Document With Invalid Metadata',
    ManualConnectionPortalSync: 'Manual Connection Portal Sync',
    ConnectionStatusNotActive: 'Connection Status Not Active',
    ConnectionWithoutContacts: 'Connection Without Contacts',
    ConnectionCompletedByAssetManager: 'Connection Completed By Asset Manager',
    UnmappedDataInMostRecentConnectionSyncStagingArea: 'Unmapped Data in Most Recent Connection Sync (Staging Area)',
    MessagesUnreadFor3Days: 'Messages Unread for 3 Days',
    MessagesWithDocumentsAttached: 'Messages with Documents Attached',
    MessagesFlaggedFor5Days: 'Messages Flagged for 5 Days',
    FundInvestmentsWithoutSecurityMasterTicker: 'Fund investments without Security Master Ticker',
    FundInvestmentsMissingSubtype: 'Fund investments missing Subtype',
    NonHiddenInvestmentHasTypeDiverseInvestments: 'Non-hidden Investment has type Diverse Investments',
    InvestmentsWithoutPastYearTaxDoc: 'Investments without past year tax doc',
    AbnormalInvestmentIrr: 'Abnormal Investment IRR',
    InvestmentHiddenTooLong: 'Investment Hidden Too Long',
    InvestmentTypeStatementTypeMismatch: 'Investment Type / Statement Type Mismatch',
    InvestmentWithNegativeUnfundedAmount: 'Investment with negative unfunded amount',
    ExtegrationSyncFailedByAdvisoryGroup: 'Extegration Sync Failed by Advisory Group'
} as const;
export type ListAuditResultsCheckNameEnum = typeof ListAuditResultsCheckNameEnum[keyof typeof ListAuditResultsCheckNameEnum];
/**
 * @export
 */
export const ListAuditResultsStatusEnum = {
    Active: 'active',
    Ignored: 'ignored',
    AutoIgnored: 'auto-ignored',
    Completed: 'completed'
} as const;
export type ListAuditResultsStatusEnum = typeof ListAuditResultsStatusEnum[keyof typeof ListAuditResultsStatusEnum];
/**
 * @export
 */
export const ListAuditResultsCategoryEnum = {
    None: 'None',
    Biqc: 'BIQC',
    Sorting: 'Sorting',
    Tagging: 'Tagging',
    DocumentProcessing: 'Document Processing',
    Integrations: 'Integrations',
    Users: 'Users',
    Entities: 'Entities',
    Connections: 'Connections',
    Messages: 'Messages',
    Advisor: 'Advisor',
    Investments: 'Investments',
    Rals: 'RALs',
    SecurityMaster: 'Security Master',
    Extegrations: 'Extegrations'
} as const;
export type ListAuditResultsCategoryEnum = typeof ListAuditResultsCategoryEnum[keyof typeof ListAuditResultsCategoryEnum];
/**
 * @export
 */
export const ListAuditResultsOwnershipTypeEnum = {
    Account: 'Account',
    Investment: 'Investment'
} as const;
export type ListAuditResultsOwnershipTypeEnum = typeof ListAuditResultsOwnershipTypeEnum[keyof typeof ListAuditResultsOwnershipTypeEnum];


/**
 * CommitmentApi - axios parameter creator
 * @export
 */
export const CommitmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Commitment document.
         * @param {CreateCommitmentRequest} CreateCommitmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommitment: async (CreateCommitmentRequest: CreateCommitmentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateCommitmentRequest' is not null or undefined
            assertParamExists('createCommitment', 'CreateCommitmentRequest', CreateCommitmentRequest)
            const localVarPath = `/v2/commitments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateCommitmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Commitment document.
         * @param {string} commitment_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommitment: async (commitment_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commitment_id' is not null or undefined
            assertParamExists('deleteCommitment', 'commitment_id', commitment_id)
            const localVarPath = `/v2/commitments/{commitment_id}`
                .replace(`{${"commitment_id"}}`, encodeURIComponent(String(commitment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single Commitment document by ID.
         * @param {string | null} commitment_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_documents] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommitment: async (commitment_id: string | null, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_documents?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commitment_id' is not null or undefined
            assertParamExists('getCommitment', 'commitment_id', commitment_id)
            const localVarPath = `/v2/commitments/{commitment_id}`
                .replace(`{${"commitment_id"}}`, encodeURIComponent(String(commitment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_investment !== undefined) {
                localVarQueryParameter['populate_investment'] = populate_investment;
            }

            if (populate_documents !== undefined) {
                localVarQueryParameter['populate_documents'] = populate_documents;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Commitment documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListCommitmentsSortFieldEnum} [sort_field] 
         * @param {ListCommitmentsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [investments] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_documents] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommitments: async (skip?: number, limit?: number, search?: string, sort_field?: ListCommitmentsSortFieldEnum, sort_direction?: ListCommitmentsSortDirectionEnum, users?: Array<string>, accounts?: Array<string>, investments?: Array<string>, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_documents?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/commitments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (accounts) {
                localVarQueryParameter['accounts'] = accounts;
            }

            if (investments) {
                localVarQueryParameter['investments'] = investments;
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_investment !== undefined) {
                localVarQueryParameter['populate_investment'] = populate_investment;
            }

            if (populate_documents !== undefined) {
                localVarQueryParameter['populate_documents'] = populate_documents;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Commitment document.
         * @param {string} commitment_id 
         * @param {UpdateCommitmentRequest} [UpdateCommitmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommitment: async (commitment_id: string, UpdateCommitmentRequest?: UpdateCommitmentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commitment_id' is not null or undefined
            assertParamExists('updateCommitment', 'commitment_id', commitment_id)
            const localVarPath = `/v2/commitments/{commitment_id}`
                .replace(`{${"commitment_id"}}`, encodeURIComponent(String(commitment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateCommitmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommitmentApi - functional programming interface
 * @export
 */
export const CommitmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommitmentApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Commitment document.
         * @param {CreateCommitmentRequest} CreateCommitmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommitment(CreateCommitmentRequest: CreateCommitmentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCommitment201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommitment(CreateCommitmentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentApi.createCommitment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a Commitment document.
         * @param {string} commitment_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCommitment(commitment_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCommitment(commitment_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentApi.deleteCommitment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single Commitment document by ID.
         * @param {string | null} commitment_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_documents] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommitment(commitment_id: string | null, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_documents?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommitment200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommitment(commitment_id, populate_user, populate_account, populate_investment, populate_documents, populate_connection, populate_audit_log, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentApi.getCommitment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Commitment documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListCommitmentsSortFieldEnum} [sort_field] 
         * @param {ListCommitmentsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [investments] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_documents] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommitments(skip?: number, limit?: number, search?: string, sort_field?: ListCommitmentsSortFieldEnum, sort_direction?: ListCommitmentsSortDirectionEnum, users?: Array<string>, accounts?: Array<string>, investments?: Array<string>, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_documents?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommitments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommitments(skip, limit, search, sort_field, sort_direction, users, accounts, investments, populate_user, populate_account, populate_investment, populate_documents, populate_connection, populate_audit_log, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentApi.listCommitments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing Commitment document.
         * @param {string} commitment_id 
         * @param {UpdateCommitmentRequest} [UpdateCommitmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCommitment(commitment_id: string, UpdateCommitmentRequest?: UpdateCommitmentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCommitment(commitment_id, UpdateCommitmentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentApi.updateCommitment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CommitmentApi - factory interface
 * @export
 */
export const CommitmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommitmentApiFp(configuration)
    return {
        /**
         * Create a new Commitment document.
         * @param {CommitmentApiCreateCommitmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommitment(requestParameters: CommitmentApiCreateCommitmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateCommitment201Response> {
            return localVarFp.createCommitment(requestParameters.CreateCommitmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Commitment document.
         * @param {CommitmentApiDeleteCommitmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommitment(requestParameters: CommitmentApiDeleteCommitmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteCommitment(requestParameters.commitment_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single Commitment document by ID.
         * @param {CommitmentApiGetCommitmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommitment(requestParameters: CommitmentApiGetCommitmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetCommitment200Response> {
            return localVarFp.getCommitment(requestParameters.commitment_id, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_documents, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Commitment documents.
         * @param {CommitmentApiListCommitmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommitments(requestParameters: CommitmentApiListCommitmentsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListCommitments200Response> {
            return localVarFp.listCommitments(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.accounts, requestParameters.investments, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_documents, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Commitment document.
         * @param {CommitmentApiUpdateCommitmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommitment(requestParameters: CommitmentApiUpdateCommitmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateCommitment(requestParameters.commitment_id, requestParameters.UpdateCommitmentRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCommitment operation in CommitmentApi.
 * @export
 * @interface CommitmentApiCreateCommitmentRequest
 */
export interface CommitmentApiCreateCommitmentRequest {
    /**
     * 
     * @type {CreateCommitmentRequest}
     * @memberof CommitmentApiCreateCommitment
     */
    readonly CreateCommitmentRequest: CreateCommitmentRequest
}

/**
 * Request parameters for deleteCommitment operation in CommitmentApi.
 * @export
 * @interface CommitmentApiDeleteCommitmentRequest
 */
export interface CommitmentApiDeleteCommitmentRequest {
    /**
     * 
     * @type {string}
     * @memberof CommitmentApiDeleteCommitment
     */
    readonly commitment_id: string
}

/**
 * Request parameters for getCommitment operation in CommitmentApi.
 * @export
 * @interface CommitmentApiGetCommitmentRequest
 */
export interface CommitmentApiGetCommitmentRequest {
    /**
     * 
     * @type {string}
     * @memberof CommitmentApiGetCommitment
     */
    readonly commitment_id: string | null

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiGetCommitment
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiGetCommitment
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiGetCommitment
     */
    readonly populate_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiGetCommitment
     */
    readonly populate_documents?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiGetCommitment
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiGetCommitment
     */
    readonly populate_audit_log?: boolean
}

/**
 * Request parameters for listCommitments operation in CommitmentApi.
 * @export
 * @interface CommitmentApiListCommitmentsRequest
 */
export interface CommitmentApiListCommitmentsRequest {
    /**
     * 
     * @type {number}
     * @memberof CommitmentApiListCommitments
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CommitmentApiListCommitments
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof CommitmentApiListCommitments
     */
    readonly search?: string

    /**
     * 
     * @type {'date' | 'amount'}
     * @memberof CommitmentApiListCommitments
     */
    readonly sort_field?: ListCommitmentsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof CommitmentApiListCommitments
     */
    readonly sort_direction?: ListCommitmentsSortDirectionEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof CommitmentApiListCommitments
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof CommitmentApiListCommitments
     */
    readonly accounts?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof CommitmentApiListCommitments
     */
    readonly investments?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiListCommitments
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiListCommitments
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiListCommitments
     */
    readonly populate_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiListCommitments
     */
    readonly populate_documents?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiListCommitments
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiListCommitments
     */
    readonly populate_audit_log?: boolean
}

/**
 * Request parameters for updateCommitment operation in CommitmentApi.
 * @export
 * @interface CommitmentApiUpdateCommitmentRequest
 */
export interface CommitmentApiUpdateCommitmentRequest {
    /**
     * 
     * @type {string}
     * @memberof CommitmentApiUpdateCommitment
     */
    readonly commitment_id: string

    /**
     * 
     * @type {UpdateCommitmentRequest}
     * @memberof CommitmentApiUpdateCommitment
     */
    readonly UpdateCommitmentRequest?: UpdateCommitmentRequest
}

/**
 * CommitmentApi - object-oriented interface
 * @export
 * @class CommitmentApi
 * @extends {BaseAPI}
 */
export class CommitmentApi extends BaseAPI {
    /**
     * Create a new Commitment document.
     * @param {CommitmentApiCreateCommitmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentApi
     */
    public createCommitment(requestParameters: CommitmentApiCreateCommitmentRequest, options?: RawAxiosRequestConfig) {
        return CommitmentApiFp(this.configuration).createCommitment(requestParameters.CreateCommitmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Commitment document.
     * @param {CommitmentApiDeleteCommitmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentApi
     */
    public deleteCommitment(requestParameters: CommitmentApiDeleteCommitmentRequest, options?: RawAxiosRequestConfig) {
        return CommitmentApiFp(this.configuration).deleteCommitment(requestParameters.commitment_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single Commitment document by ID.
     * @param {CommitmentApiGetCommitmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentApi
     */
    public getCommitment(requestParameters: CommitmentApiGetCommitmentRequest, options?: RawAxiosRequestConfig) {
        return CommitmentApiFp(this.configuration).getCommitment(requestParameters.commitment_id, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_documents, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Commitment documents.
     * @param {CommitmentApiListCommitmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentApi
     */
    public listCommitments(requestParameters: CommitmentApiListCommitmentsRequest = {}, options?: RawAxiosRequestConfig) {
        return CommitmentApiFp(this.configuration).listCommitments(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.accounts, requestParameters.investments, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_documents, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Commitment document.
     * @param {CommitmentApiUpdateCommitmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentApi
     */
    public updateCommitment(requestParameters: CommitmentApiUpdateCommitmentRequest, options?: RawAxiosRequestConfig) {
        return CommitmentApiFp(this.configuration).updateCommitment(requestParameters.commitment_id, requestParameters.UpdateCommitmentRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListCommitmentsSortFieldEnum = {
    Date: 'date',
    Amount: 'amount'
} as const;
export type ListCommitmentsSortFieldEnum = typeof ListCommitmentsSortFieldEnum[keyof typeof ListCommitmentsSortFieldEnum];
/**
 * @export
 */
export const ListCommitmentsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListCommitmentsSortDirectionEnum = typeof ListCommitmentsSortDirectionEnum[keyof typeof ListCommitmentsSortDirectionEnum];


/**
 * ConnectionApi - axios parameter creator
 * @export
 */
export const ConnectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Connection document.
         * @param {CreateConnectionRequest} CreateConnectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConnection: async (CreateConnectionRequest: CreateConnectionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateConnectionRequest' is not null or undefined
            assertParamExists('createConnection', 'CreateConnectionRequest', CreateConnectionRequest)
            const localVarPath = `/v2/connections/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateConnectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Connection document.
         * @param {string} connection_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConnection: async (connection_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('deleteConnection', 'connection_id', connection_id)
            const localVarPath = `/v2/connections/{connection_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Finish the OAuth process for an API.
         * @param {string} connection_id 
         * @param {string} integration_slug 
         * @param {FinishOAuthRequest} FinishOAuthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishOAuth: async (connection_id: string, integration_slug: string, FinishOAuthRequest: FinishOAuthRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('finishOAuth', 'connection_id', connection_id)
            // verify required parameter 'integration_slug' is not null or undefined
            assertParamExists('finishOAuth', 'integration_slug', integration_slug)
            // verify required parameter 'FinishOAuthRequest' is not null or undefined
            assertParamExists('finishOAuth', 'FinishOAuthRequest', FinishOAuthRequest)
            const localVarPath = `/v2/connections/{connection_id}/oauth/{integration_slug}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"integration_slug"}}`, encodeURIComponent(String(integration_slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(FinishOAuthRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a Connection by ID.
         * @param {string | null} connection_id 
         * @param {boolean} [populate_account_list] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_asset_manager] 
         * @param {boolean} [populate_integration] 
         * @param {boolean} [populate_messages] 
         * @param {boolean} [populate_most_recent_sync] 
         * @param {boolean} [populate_most_recent_import] 
         * @param {boolean} [populate_advisory_groups] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionById: async (connection_id: string | null, populate_account_list?: boolean, populate_account?: boolean, populate_user?: boolean, populate_asset_manager?: boolean, populate_integration?: boolean, populate_messages?: boolean, populate_most_recent_sync?: boolean, populate_most_recent_import?: boolean, populate_advisory_groups?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('getConnectionById', 'connection_id', connection_id)
            const localVarPath = `/v2/connections/{connection_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_account_list !== undefined) {
                localVarQueryParameter['populate_account_list'] = populate_account_list;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_asset_manager !== undefined) {
                localVarQueryParameter['populate_asset_manager'] = populate_asset_manager;
            }

            if (populate_integration !== undefined) {
                localVarQueryParameter['populate_integration'] = populate_integration;
            }

            if (populate_messages !== undefined) {
                localVarQueryParameter['populate_messages'] = populate_messages;
            }

            if (populate_most_recent_sync !== undefined) {
                localVarQueryParameter['populate_most_recent_sync'] = populate_most_recent_sync;
            }

            if (populate_most_recent_import !== undefined) {
                localVarQueryParameter['populate_most_recent_import'] = populate_most_recent_import;
            }

            if (populate_advisory_groups !== undefined) {
                localVarQueryParameter['populate_advisory_groups'] = populate_advisory_groups;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List connection tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionTags: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/connections/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search and list all Connections.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListConnectionsSortFieldEnum} [sort_field] 
         * @param {ListConnectionsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [connections] 
         * @param {Array<string>} [advisory_groups] 
         * @param {boolean} [include_userless] 
         * @param {boolean} [populate_most_recent_sync] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_account_list] 
         * @param {boolean} [populate_asset_manager] 
         * @param {boolean} [populate_contacts] 
         * @param {boolean} [populate_audit_log] 
         * @param {boolean} [populate_integration] 
         * @param {boolean} [populate_advisory_groups] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [statuses] 
         * @param {boolean} [incomplete] 
         * @param {Array<string>} [outreach_statuses] 
         * @param {boolean} [is_enabled] 
         * @param {string | null} [asset_managers] 
         * @param {Array<string>} [integrations] 
         * @param {Array<string>} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnections: async (skip?: number, limit?: number, search?: string, sort_field?: ListConnectionsSortFieldEnum, sort_direction?: ListConnectionsSortDirectionEnum, users?: Array<string>, connections?: Array<string>, advisory_groups?: Array<string>, include_userless?: boolean, populate_most_recent_sync?: boolean, populate_user?: boolean, populate_account?: boolean, populate_account_list?: boolean, populate_asset_manager?: boolean, populate_contacts?: boolean, populate_audit_log?: boolean, populate_integration?: boolean, populate_advisory_groups?: boolean, accounts?: Array<string>, statuses?: Array<string>, incomplete?: boolean, outreach_statuses?: Array<string>, is_enabled?: boolean, asset_managers?: string | null, integrations?: Array<string>, tags?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/connections/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (connections) {
                localVarQueryParameter['connections'] = connections;
            }

            if (advisory_groups) {
                localVarQueryParameter['advisory_groups'] = advisory_groups;
            }

            if (include_userless !== undefined) {
                localVarQueryParameter['include_userless'] = include_userless;
            }

            if (populate_most_recent_sync !== undefined) {
                localVarQueryParameter['populate_most_recent_sync'] = populate_most_recent_sync;
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_account_list !== undefined) {
                localVarQueryParameter['populate_account_list'] = populate_account_list;
            }

            if (populate_asset_manager !== undefined) {
                localVarQueryParameter['populate_asset_manager'] = populate_asset_manager;
            }

            if (populate_contacts !== undefined) {
                localVarQueryParameter['populate_contacts'] = populate_contacts;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }

            if (populate_integration !== undefined) {
                localVarQueryParameter['populate_integration'] = populate_integration;
            }

            if (populate_advisory_groups !== undefined) {
                localVarQueryParameter['populate_advisory_groups'] = populate_advisory_groups;
            }

            if (accounts) {
                localVarQueryParameter['accounts'] = accounts;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (incomplete !== undefined) {
                localVarQueryParameter['incomplete'] = incomplete;
            }

            if (outreach_statuses) {
                localVarQueryParameter['outreach_statuses'] = outreach_statuses;
            }

            if (is_enabled !== undefined) {
                localVarQueryParameter['is_enabled'] = is_enabled;
            }

            if (asset_managers !== undefined) {
                localVarQueryParameter['asset_managers'] = asset_managers;
            }

            if (integrations) {
                localVarQueryParameter['integrations'] = integrations;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Connection document.
         * @param {string} connection_id 
         * @param {UpdateConnectionRequest} [UpdateConnectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnection: async (connection_id: string, UpdateConnectionRequest?: UpdateConnectionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('updateConnection', 'connection_id', connection_id)
            const localVarPath = `/v2/connections/{connection_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateConnectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConnectionApi - functional programming interface
 * @export
 */
export const ConnectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConnectionApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Connection document.
         * @param {CreateConnectionRequest} CreateConnectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConnection(CreateConnectionRequest: CreateConnectionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateConnection201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConnection(CreateConnectionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionApi.createConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a Connection document.
         * @param {string} connection_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConnection(connection_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConnection(connection_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionApi.deleteConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Finish the OAuth process for an API.
         * @param {string} connection_id 
         * @param {string} integration_slug 
         * @param {FinishOAuthRequest} FinishOAuthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finishOAuth(connection_id: string, integration_slug: string, FinishOAuthRequest: FinishOAuthRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finishOAuth(connection_id, integration_slug, FinishOAuthRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionApi.finishOAuth']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a Connection by ID.
         * @param {string | null} connection_id 
         * @param {boolean} [populate_account_list] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_asset_manager] 
         * @param {boolean} [populate_integration] 
         * @param {boolean} [populate_messages] 
         * @param {boolean} [populate_most_recent_sync] 
         * @param {boolean} [populate_most_recent_import] 
         * @param {boolean} [populate_advisory_groups] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectionById(connection_id: string | null, populate_account_list?: boolean, populate_account?: boolean, populate_user?: boolean, populate_asset_manager?: boolean, populate_integration?: boolean, populate_messages?: boolean, populate_most_recent_sync?: boolean, populate_most_recent_import?: boolean, populate_advisory_groups?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConnectionById200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectionById(connection_id, populate_account_list, populate_account, populate_user, populate_asset_manager, populate_integration, populate_messages, populate_most_recent_sync, populate_most_recent_import, populate_advisory_groups, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionApi.getConnectionById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List connection tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectionTags(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnectionTags200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectionTags(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionApi.listConnectionTags']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Search and list all Connections.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListConnectionsSortFieldEnum} [sort_field] 
         * @param {ListConnectionsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [connections] 
         * @param {Array<string>} [advisory_groups] 
         * @param {boolean} [include_userless] 
         * @param {boolean} [populate_most_recent_sync] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_account_list] 
         * @param {boolean} [populate_asset_manager] 
         * @param {boolean} [populate_contacts] 
         * @param {boolean} [populate_audit_log] 
         * @param {boolean} [populate_integration] 
         * @param {boolean} [populate_advisory_groups] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [statuses] 
         * @param {boolean} [incomplete] 
         * @param {Array<string>} [outreach_statuses] 
         * @param {boolean} [is_enabled] 
         * @param {string | null} [asset_managers] 
         * @param {Array<string>} [integrations] 
         * @param {Array<string>} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnections(skip?: number, limit?: number, search?: string, sort_field?: ListConnectionsSortFieldEnum, sort_direction?: ListConnectionsSortDirectionEnum, users?: Array<string>, connections?: Array<string>, advisory_groups?: Array<string>, include_userless?: boolean, populate_most_recent_sync?: boolean, populate_user?: boolean, populate_account?: boolean, populate_account_list?: boolean, populate_asset_manager?: boolean, populate_contacts?: boolean, populate_audit_log?: boolean, populate_integration?: boolean, populate_advisory_groups?: boolean, accounts?: Array<string>, statuses?: Array<string>, incomplete?: boolean, outreach_statuses?: Array<string>, is_enabled?: boolean, asset_managers?: string | null, integrations?: Array<string>, tags?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnections200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnections(skip, limit, search, sort_field, sort_direction, users, connections, advisory_groups, include_userless, populate_most_recent_sync, populate_user, populate_account, populate_account_list, populate_asset_manager, populate_contacts, populate_audit_log, populate_integration, populate_advisory_groups, accounts, statuses, incomplete, outreach_statuses, is_enabled, asset_managers, integrations, tags, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionApi.listConnections']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing Connection document.
         * @param {string} connection_id 
         * @param {UpdateConnectionRequest} [UpdateConnectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnection(connection_id: string, UpdateConnectionRequest?: UpdateConnectionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnection(connection_id, UpdateConnectionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionApi.updateConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConnectionApi - factory interface
 * @export
 */
export const ConnectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConnectionApiFp(configuration)
    return {
        /**
         * Create a new Connection document.
         * @param {ConnectionApiCreateConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConnection(requestParameters: ConnectionApiCreateConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateConnection201Response> {
            return localVarFp.createConnection(requestParameters.CreateConnectionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Connection document.
         * @param {ConnectionApiDeleteConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConnection(requestParameters: ConnectionApiDeleteConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteConnection(requestParameters.connection_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Finish the OAuth process for an API.
         * @param {ConnectionApiFinishOAuthRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishOAuth(requestParameters: ConnectionApiFinishOAuthRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.finishOAuth(requestParameters.connection_id, requestParameters.integration_slug, requestParameters.FinishOAuthRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a Connection by ID.
         * @param {ConnectionApiGetConnectionByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionById(requestParameters: ConnectionApiGetConnectionByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetConnectionById200Response> {
            return localVarFp.getConnectionById(requestParameters.connection_id, requestParameters.populate_account_list, requestParameters.populate_account, requestParameters.populate_user, requestParameters.populate_asset_manager, requestParameters.populate_integration, requestParameters.populate_messages, requestParameters.populate_most_recent_sync, requestParameters.populate_most_recent_import, requestParameters.populate_advisory_groups, options).then((request) => request(axios, basePath));
        },
        /**
         * List connection tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionTags(options?: RawAxiosRequestConfig): AxiosPromise<ListConnectionTags200Response> {
            return localVarFp.listConnectionTags(options).then((request) => request(axios, basePath));
        },
        /**
         * Search and list all Connections.
         * @param {ConnectionApiListConnectionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnections(requestParameters: ConnectionApiListConnectionsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListConnections200Response> {
            return localVarFp.listConnections(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.connections, requestParameters.advisory_groups, requestParameters.include_userless, requestParameters.populate_most_recent_sync, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_account_list, requestParameters.populate_asset_manager, requestParameters.populate_contacts, requestParameters.populate_audit_log, requestParameters.populate_integration, requestParameters.populate_advisory_groups, requestParameters.accounts, requestParameters.statuses, requestParameters.incomplete, requestParameters.outreach_statuses, requestParameters.is_enabled, requestParameters.asset_managers, requestParameters.integrations, requestParameters.tags, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Connection document.
         * @param {ConnectionApiUpdateConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnection(requestParameters: ConnectionApiUpdateConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateConnection(requestParameters.connection_id, requestParameters.UpdateConnectionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createConnection operation in ConnectionApi.
 * @export
 * @interface ConnectionApiCreateConnectionRequest
 */
export interface ConnectionApiCreateConnectionRequest {
    /**
     * 
     * @type {CreateConnectionRequest}
     * @memberof ConnectionApiCreateConnection
     */
    readonly CreateConnectionRequest: CreateConnectionRequest
}

/**
 * Request parameters for deleteConnection operation in ConnectionApi.
 * @export
 * @interface ConnectionApiDeleteConnectionRequest
 */
export interface ConnectionApiDeleteConnectionRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionApiDeleteConnection
     */
    readonly connection_id: string
}

/**
 * Request parameters for finishOAuth operation in ConnectionApi.
 * @export
 * @interface ConnectionApiFinishOAuthRequest
 */
export interface ConnectionApiFinishOAuthRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionApiFinishOAuth
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionApiFinishOAuth
     */
    readonly integration_slug: string

    /**
     * 
     * @type {FinishOAuthRequest}
     * @memberof ConnectionApiFinishOAuth
     */
    readonly FinishOAuthRequest: FinishOAuthRequest
}

/**
 * Request parameters for getConnectionById operation in ConnectionApi.
 * @export
 * @interface ConnectionApiGetConnectionByIdRequest
 */
export interface ConnectionApiGetConnectionByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionApiGetConnectionById
     */
    readonly connection_id: string | null

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiGetConnectionById
     */
    readonly populate_account_list?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiGetConnectionById
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiGetConnectionById
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiGetConnectionById
     */
    readonly populate_asset_manager?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiGetConnectionById
     */
    readonly populate_integration?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiGetConnectionById
     */
    readonly populate_messages?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiGetConnectionById
     */
    readonly populate_most_recent_sync?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiGetConnectionById
     */
    readonly populate_most_recent_import?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiGetConnectionById
     */
    readonly populate_advisory_groups?: boolean
}

/**
 * Request parameters for listConnections operation in ConnectionApi.
 * @export
 * @interface ConnectionApiListConnectionsRequest
 */
export interface ConnectionApiListConnectionsRequest {
    /**
     * 
     * @type {number}
     * @memberof ConnectionApiListConnections
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ConnectionApiListConnections
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ConnectionApiListConnections
     */
    readonly search?: string

    /**
     * 
     * @type {'created_at' | 'tfa_email' | 'tfa_phone' | 'name' | 'user' | 'account' | 'asset_manager' | 'contacts' | 'integration' | 'status' | 'outreach_status' | 'last_connection_at' | 'use_tfa'}
     * @memberof ConnectionApiListConnections
     */
    readonly sort_field?: ListConnectionsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ConnectionApiListConnections
     */
    readonly sort_direction?: ListConnectionsSortDirectionEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof ConnectionApiListConnections
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ConnectionApiListConnections
     */
    readonly connections?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ConnectionApiListConnections
     */
    readonly advisory_groups?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiListConnections
     */
    readonly include_userless?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiListConnections
     */
    readonly populate_most_recent_sync?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiListConnections
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiListConnections
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiListConnections
     */
    readonly populate_account_list?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiListConnections
     */
    readonly populate_asset_manager?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiListConnections
     */
    readonly populate_contacts?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiListConnections
     */
    readonly populate_audit_log?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiListConnections
     */
    readonly populate_integration?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiListConnections
     */
    readonly populate_advisory_groups?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof ConnectionApiListConnections
     */
    readonly accounts?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ConnectionApiListConnections
     */
    readonly statuses?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiListConnections
     */
    readonly incomplete?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof ConnectionApiListConnections
     */
    readonly outreach_statuses?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiListConnections
     */
    readonly is_enabled?: boolean

    /**
     * 
     * @type {string}
     * @memberof ConnectionApiListConnections
     */
    readonly asset_managers?: string | null

    /**
     * 
     * @type {Array<string>}
     * @memberof ConnectionApiListConnections
     */
    readonly integrations?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ConnectionApiListConnections
     */
    readonly tags?: Array<string>
}

/**
 * Request parameters for updateConnection operation in ConnectionApi.
 * @export
 * @interface ConnectionApiUpdateConnectionRequest
 */
export interface ConnectionApiUpdateConnectionRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionApiUpdateConnection
     */
    readonly connection_id: string

    /**
     * 
     * @type {UpdateConnectionRequest}
     * @memberof ConnectionApiUpdateConnection
     */
    readonly UpdateConnectionRequest?: UpdateConnectionRequest
}

/**
 * ConnectionApi - object-oriented interface
 * @export
 * @class ConnectionApi
 * @extends {BaseAPI}
 */
export class ConnectionApi extends BaseAPI {
    /**
     * Create a new Connection document.
     * @param {ConnectionApiCreateConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public createConnection(requestParameters: ConnectionApiCreateConnectionRequest, options?: RawAxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).createConnection(requestParameters.CreateConnectionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Connection document.
     * @param {ConnectionApiDeleteConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public deleteConnection(requestParameters: ConnectionApiDeleteConnectionRequest, options?: RawAxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).deleteConnection(requestParameters.connection_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Finish the OAuth process for an API.
     * @param {ConnectionApiFinishOAuthRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public finishOAuth(requestParameters: ConnectionApiFinishOAuthRequest, options?: RawAxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).finishOAuth(requestParameters.connection_id, requestParameters.integration_slug, requestParameters.FinishOAuthRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a Connection by ID.
     * @param {ConnectionApiGetConnectionByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public getConnectionById(requestParameters: ConnectionApiGetConnectionByIdRequest, options?: RawAxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).getConnectionById(requestParameters.connection_id, requestParameters.populate_account_list, requestParameters.populate_account, requestParameters.populate_user, requestParameters.populate_asset_manager, requestParameters.populate_integration, requestParameters.populate_messages, requestParameters.populate_most_recent_sync, requestParameters.populate_most_recent_import, requestParameters.populate_advisory_groups, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List connection tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public listConnectionTags(options?: RawAxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).listConnectionTags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search and list all Connections.
     * @param {ConnectionApiListConnectionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public listConnections(requestParameters: ConnectionApiListConnectionsRequest = {}, options?: RawAxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).listConnections(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.connections, requestParameters.advisory_groups, requestParameters.include_userless, requestParameters.populate_most_recent_sync, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_account_list, requestParameters.populate_asset_manager, requestParameters.populate_contacts, requestParameters.populate_audit_log, requestParameters.populate_integration, requestParameters.populate_advisory_groups, requestParameters.accounts, requestParameters.statuses, requestParameters.incomplete, requestParameters.outreach_statuses, requestParameters.is_enabled, requestParameters.asset_managers, requestParameters.integrations, requestParameters.tags, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Connection document.
     * @param {ConnectionApiUpdateConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public updateConnection(requestParameters: ConnectionApiUpdateConnectionRequest, options?: RawAxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).updateConnection(requestParameters.connection_id, requestParameters.UpdateConnectionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListConnectionsSortFieldEnum = {
    CreatedAt: 'created_at',
    TfaEmail: 'tfa_email',
    TfaPhone: 'tfa_phone',
    Name: 'name',
    User: 'user',
    Account: 'account',
    AssetManager: 'asset_manager',
    Contacts: 'contacts',
    Integration: 'integration',
    Status: 'status',
    OutreachStatus: 'outreach_status',
    LastConnectionAt: 'last_connection_at',
    UseTfa: 'use_tfa'
} as const;
export type ListConnectionsSortFieldEnum = typeof ListConnectionsSortFieldEnum[keyof typeof ListConnectionsSortFieldEnum];
/**
 * @export
 */
export const ListConnectionsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListConnectionsSortDirectionEnum = typeof ListConnectionsSortDirectionEnum[keyof typeof ListConnectionsSortDirectionEnum];


/**
 * ConnectionDataApi - axios parameter creator
 * @export
 */
export const ConnectionDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Attempt import of a ConnectionAccount document with optional user override.
         * @param {string} connection_id 
         * @param {string} account_id 
         * @param {ImportConnectionAccountRequest} [ImportConnectionAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionAccount: async (connection_id: string, account_id: string, ImportConnectionAccountRequest?: ImportConnectionAccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('importConnectionAccount', 'connection_id', connection_id)
            // verify required parameter 'account_id' is not null or undefined
            assertParamExists('importConnectionAccount', 'account_id', account_id)
            const localVarPath = `/v2/connections/{connection_id}/data/accounts/{account_id}/import`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"account_id"}}`, encodeURIComponent(String(account_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ImportConnectionAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Attempt import of a ConnectionCommitment document with optional user override.
         * @param {string} connection_id 
         * @param {string} commitment_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionCommitment: async (connection_id: string, commitment_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('importConnectionCommitment', 'connection_id', connection_id)
            // verify required parameter 'commitment_id' is not null or undefined
            assertParamExists('importConnectionCommitment', 'commitment_id', commitment_id)
            const localVarPath = `/v2/connections/{connection_id}/data/commitments/{commitment_id}/import`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"commitment_id"}}`, encodeURIComponent(String(commitment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Attempt import of a ConnectionDocument document.
         * @param {string} connection_id 
         * @param {string} document_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionDocument: async (connection_id: string, document_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('importConnectionDocument', 'connection_id', connection_id)
            // verify required parameter 'document_id' is not null or undefined
            assertParamExists('importConnectionDocument', 'document_id', document_id)
            const localVarPath = `/v2/connections/{connection_id}/data/documents/{document_id}/import`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(document_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Attempt import of a ConnectionInvestment document with optional account override.
         * @param {string} connection_id 
         * @param {string} investment_id 
         * @param {ImportConnectionInvestmentRequest} [ImportConnectionInvestmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionInvestment: async (connection_id: string, investment_id: string, ImportConnectionInvestmentRequest?: ImportConnectionInvestmentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('importConnectionInvestment', 'connection_id', connection_id)
            // verify required parameter 'investment_id' is not null or undefined
            assertParamExists('importConnectionInvestment', 'investment_id', investment_id)
            const localVarPath = `/v2/connections/{connection_id}/data/investments/{investment_id}/import`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"investment_id"}}`, encodeURIComponent(String(investment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ImportConnectionInvestmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Attempt import of a ConnectionTransaction document.
         * @param {string} connection_id 
         * @param {string} transaction_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionTransaction: async (connection_id: string, transaction_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('importConnectionTransaction', 'connection_id', connection_id)
            // verify required parameter 'transaction_id' is not null or undefined
            assertParamExists('importConnectionTransaction', 'transaction_id', transaction_id)
            const localVarPath = `/v2/connections/{connection_id}/data/transactions/{transaction_id}/import`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transaction_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Attempt import of a ConnectionUser document.
         * @param {string} connection_id 
         * @param {string} user_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionUser: async (connection_id: string, user_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('importConnectionUser', 'connection_id', connection_id)
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('importConnectionUser', 'user_id', user_id)
            const localVarPath = `/v2/connections/{connection_id}/data/users/{user_id}/import`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Attempt import of a ConnectionValuation document.
         * @param {string} connection_id 
         * @param {string} valuation_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionValuation: async (connection_id: string, valuation_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('importConnectionValuation', 'connection_id', connection_id)
            // verify required parameter 'valuation_id' is not null or undefined
            assertParamExists('importConnectionValuation', 'valuation_id', valuation_id)
            const localVarPath = `/v2/connections/{connection_id}/data/valuations/{valuation_id}/import`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"valuation_id"}}`, encodeURIComponent(String(valuation_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List and paginate through ConnectionAccount documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionAccountsSortFieldEnum} [sort_field] 
         * @param {ListConnectionAccountsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_auto_import_enabled] 
         * @param {boolean} [is_auto_import_documents_enabled] 
         * @param {boolean} [is_ignored] 
         * @param {string} [connection_user] 
         * @param {ListConnectionAccountsAccountParameter} [account] 
         * @param {string} [search] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_connection_user] 
         * @param {boolean} [populate_likely_matches] 
         * @param {string} [connection_account] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionAccounts: async (connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionAccountsSortFieldEnum, sort_direction?: ListConnectionAccountsSortDirectionEnum, is_auto_import_enabled?: boolean, is_auto_import_documents_enabled?: boolean, is_ignored?: boolean, connection_user?: string, account?: ListConnectionAccountsAccountParameter, search?: string, populate_account?: boolean, populate_connection_user?: boolean, populate_likely_matches?: boolean, connection_account?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('listConnectionAccounts', 'connection_id', connection_id)
            // verify required parameter 'connectionsync_id' is not null or undefined
            assertParamExists('listConnectionAccounts', 'connectionsync_id', connectionsync_id)
            const localVarPath = `/v2/connections/{connection_id}/sync/{connectionsync_id}/data/accounts`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"connectionsync_id"}}`, encodeURIComponent(String(connectionsync_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (is_auto_import_enabled !== undefined) {
                localVarQueryParameter['is_auto_import_enabled'] = is_auto_import_enabled;
            }

            if (is_auto_import_documents_enabled !== undefined) {
                localVarQueryParameter['is_auto_import_documents_enabled'] = is_auto_import_documents_enabled;
            }

            if (is_ignored !== undefined) {
                localVarQueryParameter['is_ignored'] = is_ignored;
            }

            if (connection_user !== undefined) {
                localVarQueryParameter['connection_user'] = connection_user;
            }

            if (account !== undefined) {
                for (const [key, value] of Object.entries(account)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_connection_user !== undefined) {
                localVarQueryParameter['populate_connection_user'] = populate_connection_user;
            }

            if (populate_likely_matches !== undefined) {
                localVarQueryParameter['populate_likely_matches'] = populate_likely_matches;
            }

            if (connection_account !== undefined) {
                localVarQueryParameter['connection_account'] = connection_account;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List and paginate through ConnectionCommitment documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionCommitmentsSortFieldEnum} [sort_field] 
         * @param {ListConnectionCommitmentsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_ignored] 
         * @param {ListConnectionCommitmentsCommitmentParameter} [commitment] 
         * @param {string} [connection_investment] 
         * @param {boolean} [populate_commitment] 
         * @param {boolean} [populate_connection_investment] 
         * @param {boolean} [populate_likely_matches] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionCommitments: async (connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionCommitmentsSortFieldEnum, sort_direction?: ListConnectionCommitmentsSortDirectionEnum, is_ignored?: boolean, commitment?: ListConnectionCommitmentsCommitmentParameter, connection_investment?: string, populate_commitment?: boolean, populate_connection_investment?: boolean, populate_likely_matches?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('listConnectionCommitments', 'connection_id', connection_id)
            // verify required parameter 'connectionsync_id' is not null or undefined
            assertParamExists('listConnectionCommitments', 'connectionsync_id', connectionsync_id)
            const localVarPath = `/v2/connections/{connection_id}/sync/{connectionsync_id}/data/commitments`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"connectionsync_id"}}`, encodeURIComponent(String(connectionsync_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (is_ignored !== undefined) {
                localVarQueryParameter['is_ignored'] = is_ignored;
            }

            if (commitment !== undefined) {
                for (const [key, value] of Object.entries(commitment)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (connection_investment !== undefined) {
                localVarQueryParameter['connection_investment'] = connection_investment;
            }

            if (populate_commitment !== undefined) {
                localVarQueryParameter['populate_commitment'] = populate_commitment;
            }

            if (populate_connection_investment !== undefined) {
                localVarQueryParameter['populate_connection_investment'] = populate_connection_investment;
            }

            if (populate_likely_matches !== undefined) {
                localVarQueryParameter['populate_likely_matches'] = populate_likely_matches;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List and paginate through ConnectionDocument documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionDocumentsSortFieldEnum} [sort_field] 
         * @param {ListConnectionDocumentsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_ignored] 
         * @param {string} [connection_investment] 
         * @param {string} [connection_account] 
         * @param {boolean} [populate_document] 
         * @param {boolean} [populate_parent] 
         * @param {boolean} [populate_likely_matches] 
         * @param {ListConnectionDocumentsDocumentParameter} [document] 
         * @param {boolean} [populate_connection_investment] 
         * @param {boolean} [populate_connection_account] 
         * @param {string} [document_types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionDocuments: async (connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionDocumentsSortFieldEnum, sort_direction?: ListConnectionDocumentsSortDirectionEnum, is_ignored?: boolean, connection_investment?: string, connection_account?: string, populate_document?: boolean, populate_parent?: boolean, populate_likely_matches?: boolean, document?: ListConnectionDocumentsDocumentParameter, populate_connection_investment?: boolean, populate_connection_account?: boolean, document_types?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('listConnectionDocuments', 'connection_id', connection_id)
            // verify required parameter 'connectionsync_id' is not null or undefined
            assertParamExists('listConnectionDocuments', 'connectionsync_id', connectionsync_id)
            const localVarPath = `/v2/connections/{connection_id}/sync/{connectionsync_id}/data/documents`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"connectionsync_id"}}`, encodeURIComponent(String(connectionsync_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (is_ignored !== undefined) {
                localVarQueryParameter['is_ignored'] = is_ignored;
            }

            if (connection_investment !== undefined) {
                localVarQueryParameter['connection_investment'] = connection_investment;
            }

            if (connection_account !== undefined) {
                localVarQueryParameter['connection_account'] = connection_account;
            }

            if (populate_document !== undefined) {
                localVarQueryParameter['populate_document'] = populate_document;
            }

            if (populate_parent !== undefined) {
                localVarQueryParameter['populate_parent'] = populate_parent;
            }

            if (populate_likely_matches !== undefined) {
                localVarQueryParameter['populate_likely_matches'] = populate_likely_matches;
            }

            if (document !== undefined) {
                for (const [key, value] of Object.entries(document)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (populate_connection_investment !== undefined) {
                localVarQueryParameter['populate_connection_investment'] = populate_connection_investment;
            }

            if (populate_connection_account !== undefined) {
                localVarQueryParameter['populate_connection_account'] = populate_connection_account;
            }

            if (document_types !== undefined) {
                localVarQueryParameter['document_types'] = document_types;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List and paginate through ConnectionInvestment documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionInvestmentsSortFieldEnum} [sort_field] 
         * @param {ListConnectionInvestmentsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_auto_import_enabled] 
         * @param {boolean} [is_ignored] 
         * @param {ListConnectionInvestmentsInvestmentParameter} [investment] 
         * @param {string} [search] 
         * @param {string} [connection_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_connection_account] 
         * @param {boolean} [populate_likely_matches] 
         * @param {string} [connection_investment] 
         * @param {string} [investment_types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionInvestments: async (connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionInvestmentsSortFieldEnum, sort_direction?: ListConnectionInvestmentsSortDirectionEnum, is_auto_import_enabled?: boolean, is_ignored?: boolean, investment?: ListConnectionInvestmentsInvestmentParameter, search?: string, connection_account?: string, populate_investment?: boolean, populate_connection_account?: boolean, populate_likely_matches?: boolean, connection_investment?: string, investment_types?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('listConnectionInvestments', 'connection_id', connection_id)
            // verify required parameter 'connectionsync_id' is not null or undefined
            assertParamExists('listConnectionInvestments', 'connectionsync_id', connectionsync_id)
            const localVarPath = `/v2/connections/{connection_id}/sync/{connectionsync_id}/data/investments`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"connectionsync_id"}}`, encodeURIComponent(String(connectionsync_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (is_auto_import_enabled !== undefined) {
                localVarQueryParameter['is_auto_import_enabled'] = is_auto_import_enabled;
            }

            if (is_ignored !== undefined) {
                localVarQueryParameter['is_ignored'] = is_ignored;
            }

            if (investment !== undefined) {
                for (const [key, value] of Object.entries(investment)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (connection_account !== undefined) {
                localVarQueryParameter['connection_account'] = connection_account;
            }

            if (populate_investment !== undefined) {
                localVarQueryParameter['populate_investment'] = populate_investment;
            }

            if (populate_connection_account !== undefined) {
                localVarQueryParameter['populate_connection_account'] = populate_connection_account;
            }

            if (populate_likely_matches !== undefined) {
                localVarQueryParameter['populate_likely_matches'] = populate_likely_matches;
            }

            if (connection_investment !== undefined) {
                localVarQueryParameter['connection_investment'] = connection_investment;
            }

            if (investment_types !== undefined) {
                localVarQueryParameter['investment_types'] = investment_types;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List and paginate through ConnectionSync documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionSyncsSortFieldEnum} [sort_field] 
         * @param {ListConnectionSyncsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [populate_counts] 
         * @param {boolean} [populate_connection] 
         * @param {ListConnectionSyncsExitStatusEnum} [exit_status] 
         * @param {string} [connection] 
         * @param {string} [connection_sync_ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionSyncs: async (skip?: number, limit?: number, sort_field?: ListConnectionSyncsSortFieldEnum, sort_direction?: ListConnectionSyncsSortDirectionEnum, populate_counts?: boolean, populate_connection?: boolean, exit_status?: ListConnectionSyncsExitStatusEnum, connection?: string, connection_sync_ids?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/connectionsyncs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (populate_counts !== undefined) {
                localVarQueryParameter['populate_counts'] = populate_counts;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (exit_status !== undefined) {
                localVarQueryParameter['exit_status'] = exit_status;
            }

            if (connection !== undefined) {
                localVarQueryParameter['connection'] = connection;
            }

            if (connection_sync_ids !== undefined) {
                localVarQueryParameter['connection_sync_ids'] = connection_sync_ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List and paginate through ConnectionTransaction documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionTransactionsSortFieldEnum} [sort_field] 
         * @param {ListConnectionTransactionsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_ignored] 
         * @param {ListConnectionTransactionsTransactionParameter} [transaction] 
         * @param {string} [connection_investment] 
         * @param {boolean} [populate_transaction] 
         * @param {boolean} [populate_connection_investment] 
         * @param {boolean} [populate_likely_matches] 
         * @param {string} [transaction_types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionTransactions: async (connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionTransactionsSortFieldEnum, sort_direction?: ListConnectionTransactionsSortDirectionEnum, is_ignored?: boolean, transaction?: ListConnectionTransactionsTransactionParameter, connection_investment?: string, populate_transaction?: boolean, populate_connection_investment?: boolean, populate_likely_matches?: boolean, transaction_types?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('listConnectionTransactions', 'connection_id', connection_id)
            // verify required parameter 'connectionsync_id' is not null or undefined
            assertParamExists('listConnectionTransactions', 'connectionsync_id', connectionsync_id)
            const localVarPath = `/v2/connections/{connection_id}/sync/{connectionsync_id}/data/transactions`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"connectionsync_id"}}`, encodeURIComponent(String(connectionsync_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (is_ignored !== undefined) {
                localVarQueryParameter['is_ignored'] = is_ignored;
            }

            if (transaction !== undefined) {
                for (const [key, value] of Object.entries(transaction)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (connection_investment !== undefined) {
                localVarQueryParameter['connection_investment'] = connection_investment;
            }

            if (populate_transaction !== undefined) {
                localVarQueryParameter['populate_transaction'] = populate_transaction;
            }

            if (populate_connection_investment !== undefined) {
                localVarQueryParameter['populate_connection_investment'] = populate_connection_investment;
            }

            if (populate_likely_matches !== undefined) {
                localVarQueryParameter['populate_likely_matches'] = populate_likely_matches;
            }

            if (transaction_types !== undefined) {
                localVarQueryParameter['transaction_types'] = transaction_types;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List and paginate through ConnectionUser documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionUsersSortFieldEnum} [sort_field] 
         * @param {ListConnectionUsersSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_ignored] 
         * @param {boolean} [is_auto_import_enabled] 
         * @param {ListConnectionUsersUserParameter} [user] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_likely_matches] 
         * @param {string} [search] 
         * @param {string} [connection_user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionUsers: async (connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionUsersSortFieldEnum, sort_direction?: ListConnectionUsersSortDirectionEnum, is_ignored?: boolean, is_auto_import_enabled?: boolean, user?: ListConnectionUsersUserParameter, populate_user?: boolean, populate_likely_matches?: boolean, search?: string, connection_user?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('listConnectionUsers', 'connection_id', connection_id)
            // verify required parameter 'connectionsync_id' is not null or undefined
            assertParamExists('listConnectionUsers', 'connectionsync_id', connectionsync_id)
            const localVarPath = `/v2/connections/{connection_id}/sync/{connectionsync_id}/data/users`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"connectionsync_id"}}`, encodeURIComponent(String(connectionsync_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (is_ignored !== undefined) {
                localVarQueryParameter['is_ignored'] = is_ignored;
            }

            if (is_auto_import_enabled !== undefined) {
                localVarQueryParameter['is_auto_import_enabled'] = is_auto_import_enabled;
            }

            if (user !== undefined) {
                for (const [key, value] of Object.entries(user)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_likely_matches !== undefined) {
                localVarQueryParameter['populate_likely_matches'] = populate_likely_matches;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (connection_user !== undefined) {
                localVarQueryParameter['connection_user'] = connection_user;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List and paginate through ConnectionValuation documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionValuationsSortFieldEnum} [sort_field] 
         * @param {ListConnectionValuationsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_ignored] 
         * @param {ListConnectionValuationsValuationParameter} [valuation] 
         * @param {string} [connection_investment] 
         * @param {boolean} [populate_valuation] 
         * @param {boolean} [populate_connection_investment] 
         * @param {boolean} [populate_likely_matches] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionValuations: async (connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionValuationsSortFieldEnum, sort_direction?: ListConnectionValuationsSortDirectionEnum, is_ignored?: boolean, valuation?: ListConnectionValuationsValuationParameter, connection_investment?: string, populate_valuation?: boolean, populate_connection_investment?: boolean, populate_likely_matches?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('listConnectionValuations', 'connection_id', connection_id)
            // verify required parameter 'connectionsync_id' is not null or undefined
            assertParamExists('listConnectionValuations', 'connectionsync_id', connectionsync_id)
            const localVarPath = `/v2/connections/{connection_id}/sync/{connectionsync_id}/data/valuations`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"connectionsync_id"}}`, encodeURIComponent(String(connectionsync_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (is_ignored !== undefined) {
                localVarQueryParameter['is_ignored'] = is_ignored;
            }

            if (valuation !== undefined) {
                for (const [key, value] of Object.entries(valuation)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (connection_investment !== undefined) {
                localVarQueryParameter['connection_investment'] = connection_investment;
            }

            if (populate_valuation !== undefined) {
                localVarQueryParameter['populate_valuation'] = populate_valuation;
            }

            if (populate_connection_investment !== undefined) {
                localVarQueryParameter['populate_connection_investment'] = populate_connection_investment;
            }

            if (populate_likely_matches !== undefined) {
                localVarQueryParameter['populate_likely_matches'] = populate_likely_matches;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a ConnectionAccount document.
         * @param {string} connection_id 
         * @param {string} account_id 
         * @param {UpdateConnectionAccountRequest} [UpdateConnectionAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionAccount: async (connection_id: string, account_id: string, UpdateConnectionAccountRequest?: UpdateConnectionAccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('updateConnectionAccount', 'connection_id', connection_id)
            // verify required parameter 'account_id' is not null or undefined
            assertParamExists('updateConnectionAccount', 'account_id', account_id)
            const localVarPath = `/v2/connections/{connection_id}/data/accounts/{account_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"account_id"}}`, encodeURIComponent(String(account_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateConnectionAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a ConnectionCommitment document.
         * @param {string} connection_id 
         * @param {string} commitment_id 
         * @param {UpdateConnectionCommitmentRequest} [UpdateConnectionCommitmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionCommitment: async (connection_id: string, commitment_id: string, UpdateConnectionCommitmentRequest?: UpdateConnectionCommitmentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('updateConnectionCommitment', 'connection_id', connection_id)
            // verify required parameter 'commitment_id' is not null or undefined
            assertParamExists('updateConnectionCommitment', 'commitment_id', commitment_id)
            const localVarPath = `/v2/connections/{connection_id}/data/commitments/{commitment_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"commitment_id"}}`, encodeURIComponent(String(commitment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateConnectionCommitmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a ConnectionDocument document.
         * @param {string} connection_id 
         * @param {string} document_id 
         * @param {UpdateConnectionDocumentRequest} [UpdateConnectionDocumentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionDocument: async (connection_id: string, document_id: string, UpdateConnectionDocumentRequest?: UpdateConnectionDocumentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('updateConnectionDocument', 'connection_id', connection_id)
            // verify required parameter 'document_id' is not null or undefined
            assertParamExists('updateConnectionDocument', 'document_id', document_id)
            const localVarPath = `/v2/connections/{connection_id}/data/documents/{document_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(document_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateConnectionDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a ConnectionInvestment document.
         * @param {string} connection_id 
         * @param {string} investment_id 
         * @param {UpdateConnectionInvestmentRequest} [UpdateConnectionInvestmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionInvestment: async (connection_id: string, investment_id: string, UpdateConnectionInvestmentRequest?: UpdateConnectionInvestmentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('updateConnectionInvestment', 'connection_id', connection_id)
            // verify required parameter 'investment_id' is not null or undefined
            assertParamExists('updateConnectionInvestment', 'investment_id', investment_id)
            const localVarPath = `/v2/connections/{connection_id}/data/investments/{investment_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"investment_id"}}`, encodeURIComponent(String(investment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateConnectionInvestmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a ConnectionTransaction document.
         * @param {string} connection_id 
         * @param {string} transaction_id 
         * @param {UpdateConnectionTransactionRequest} [UpdateConnectionTransactionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionTransaction: async (connection_id: string, transaction_id: string, UpdateConnectionTransactionRequest?: UpdateConnectionTransactionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('updateConnectionTransaction', 'connection_id', connection_id)
            // verify required parameter 'transaction_id' is not null or undefined
            assertParamExists('updateConnectionTransaction', 'transaction_id', transaction_id)
            const localVarPath = `/v2/connections/{connection_id}/data/transactions/{transaction_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transaction_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateConnectionTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a ConnectionUser document.
         * @param {string} connection_id 
         * @param {string} user_id 
         * @param {UpdateConnectionUserRequest} [UpdateConnectionUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionUser: async (connection_id: string, user_id: string, UpdateConnectionUserRequest?: UpdateConnectionUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('updateConnectionUser', 'connection_id', connection_id)
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('updateConnectionUser', 'user_id', user_id)
            const localVarPath = `/v2/connections/{connection_id}/data/users/{user_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateConnectionUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a ConnectionValuation document.
         * @param {string} connection_id 
         * @param {string} valuation_id 
         * @param {UpdateConnectionValuationRequest} [UpdateConnectionValuationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionValuation: async (connection_id: string, valuation_id: string, UpdateConnectionValuationRequest?: UpdateConnectionValuationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('updateConnectionValuation', 'connection_id', connection_id)
            // verify required parameter 'valuation_id' is not null or undefined
            assertParamExists('updateConnectionValuation', 'valuation_id', valuation_id)
            const localVarPath = `/v2/connections/{connection_id}/data/valuations/{valuation_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"valuation_id"}}`, encodeURIComponent(String(valuation_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateConnectionValuationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConnectionDataApi - functional programming interface
 * @export
 */
export const ConnectionDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConnectionDataApiAxiosParamCreator(configuration)
    return {
        /**
         * Attempt import of a ConnectionAccount document with optional user override.
         * @param {string} connection_id 
         * @param {string} account_id 
         * @param {ImportConnectionAccountRequest} [ImportConnectionAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importConnectionAccount(connection_id: string, account_id: string, ImportConnectionAccountRequest?: ImportConnectionAccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importConnectionAccount(connection_id, account_id, ImportConnectionAccountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.importConnectionAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Attempt import of a ConnectionCommitment document with optional user override.
         * @param {string} connection_id 
         * @param {string} commitment_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importConnectionCommitment(connection_id: string, commitment_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importConnectionCommitment(connection_id, commitment_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.importConnectionCommitment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Attempt import of a ConnectionDocument document.
         * @param {string} connection_id 
         * @param {string} document_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importConnectionDocument(connection_id: string, document_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importConnectionDocument(connection_id, document_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.importConnectionDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Attempt import of a ConnectionInvestment document with optional account override.
         * @param {string} connection_id 
         * @param {string} investment_id 
         * @param {ImportConnectionInvestmentRequest} [ImportConnectionInvestmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importConnectionInvestment(connection_id: string, investment_id: string, ImportConnectionInvestmentRequest?: ImportConnectionInvestmentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importConnectionInvestment(connection_id, investment_id, ImportConnectionInvestmentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.importConnectionInvestment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Attempt import of a ConnectionTransaction document.
         * @param {string} connection_id 
         * @param {string} transaction_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importConnectionTransaction(connection_id: string, transaction_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importConnectionTransaction(connection_id, transaction_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.importConnectionTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Attempt import of a ConnectionUser document.
         * @param {string} connection_id 
         * @param {string} user_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importConnectionUser(connection_id: string, user_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importConnectionUser(connection_id, user_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.importConnectionUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Attempt import of a ConnectionValuation document.
         * @param {string} connection_id 
         * @param {string} valuation_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importConnectionValuation(connection_id: string, valuation_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importConnectionValuation(connection_id, valuation_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.importConnectionValuation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List and paginate through ConnectionAccount documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionAccountsSortFieldEnum} [sort_field] 
         * @param {ListConnectionAccountsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_auto_import_enabled] 
         * @param {boolean} [is_auto_import_documents_enabled] 
         * @param {boolean} [is_ignored] 
         * @param {string} [connection_user] 
         * @param {ListConnectionAccountsAccountParameter} [account] 
         * @param {string} [search] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_connection_user] 
         * @param {boolean} [populate_likely_matches] 
         * @param {string} [connection_account] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectionAccounts(connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionAccountsSortFieldEnum, sort_direction?: ListConnectionAccountsSortDirectionEnum, is_auto_import_enabled?: boolean, is_auto_import_documents_enabled?: boolean, is_ignored?: boolean, connection_user?: string, account?: ListConnectionAccountsAccountParameter, search?: string, populate_account?: boolean, populate_connection_user?: boolean, populate_likely_matches?: boolean, connection_account?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnectionAccounts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectionAccounts(connection_id, connectionsync_id, skip, limit, sort_field, sort_direction, is_auto_import_enabled, is_auto_import_documents_enabled, is_ignored, connection_user, account, search, populate_account, populate_connection_user, populate_likely_matches, connection_account, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.listConnectionAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List and paginate through ConnectionCommitment documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionCommitmentsSortFieldEnum} [sort_field] 
         * @param {ListConnectionCommitmentsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_ignored] 
         * @param {ListConnectionCommitmentsCommitmentParameter} [commitment] 
         * @param {string} [connection_investment] 
         * @param {boolean} [populate_commitment] 
         * @param {boolean} [populate_connection_investment] 
         * @param {boolean} [populate_likely_matches] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectionCommitments(connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionCommitmentsSortFieldEnum, sort_direction?: ListConnectionCommitmentsSortDirectionEnum, is_ignored?: boolean, commitment?: ListConnectionCommitmentsCommitmentParameter, connection_investment?: string, populate_commitment?: boolean, populate_connection_investment?: boolean, populate_likely_matches?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnectionCommitments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectionCommitments(connection_id, connectionsync_id, skip, limit, sort_field, sort_direction, is_ignored, commitment, connection_investment, populate_commitment, populate_connection_investment, populate_likely_matches, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.listConnectionCommitments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List and paginate through ConnectionDocument documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionDocumentsSortFieldEnum} [sort_field] 
         * @param {ListConnectionDocumentsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_ignored] 
         * @param {string} [connection_investment] 
         * @param {string} [connection_account] 
         * @param {boolean} [populate_document] 
         * @param {boolean} [populate_parent] 
         * @param {boolean} [populate_likely_matches] 
         * @param {ListConnectionDocumentsDocumentParameter} [document] 
         * @param {boolean} [populate_connection_investment] 
         * @param {boolean} [populate_connection_account] 
         * @param {string} [document_types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectionDocuments(connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionDocumentsSortFieldEnum, sort_direction?: ListConnectionDocumentsSortDirectionEnum, is_ignored?: boolean, connection_investment?: string, connection_account?: string, populate_document?: boolean, populate_parent?: boolean, populate_likely_matches?: boolean, document?: ListConnectionDocumentsDocumentParameter, populate_connection_investment?: boolean, populate_connection_account?: boolean, document_types?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnectionDocuments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectionDocuments(connection_id, connectionsync_id, skip, limit, sort_field, sort_direction, is_ignored, connection_investment, connection_account, populate_document, populate_parent, populate_likely_matches, document, populate_connection_investment, populate_connection_account, document_types, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.listConnectionDocuments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List and paginate through ConnectionInvestment documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionInvestmentsSortFieldEnum} [sort_field] 
         * @param {ListConnectionInvestmentsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_auto_import_enabled] 
         * @param {boolean} [is_ignored] 
         * @param {ListConnectionInvestmentsInvestmentParameter} [investment] 
         * @param {string} [search] 
         * @param {string} [connection_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_connection_account] 
         * @param {boolean} [populate_likely_matches] 
         * @param {string} [connection_investment] 
         * @param {string} [investment_types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectionInvestments(connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionInvestmentsSortFieldEnum, sort_direction?: ListConnectionInvestmentsSortDirectionEnum, is_auto_import_enabled?: boolean, is_ignored?: boolean, investment?: ListConnectionInvestmentsInvestmentParameter, search?: string, connection_account?: string, populate_investment?: boolean, populate_connection_account?: boolean, populate_likely_matches?: boolean, connection_investment?: string, investment_types?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnectionInvestments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectionInvestments(connection_id, connectionsync_id, skip, limit, sort_field, sort_direction, is_auto_import_enabled, is_ignored, investment, search, connection_account, populate_investment, populate_connection_account, populate_likely_matches, connection_investment, investment_types, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.listConnectionInvestments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List and paginate through ConnectionSync documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionSyncsSortFieldEnum} [sort_field] 
         * @param {ListConnectionSyncsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [populate_counts] 
         * @param {boolean} [populate_connection] 
         * @param {ListConnectionSyncsExitStatusEnum} [exit_status] 
         * @param {string} [connection] 
         * @param {string} [connection_sync_ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectionSyncs(skip?: number, limit?: number, sort_field?: ListConnectionSyncsSortFieldEnum, sort_direction?: ListConnectionSyncsSortDirectionEnum, populate_counts?: boolean, populate_connection?: boolean, exit_status?: ListConnectionSyncsExitStatusEnum, connection?: string, connection_sync_ids?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnectionSyncs200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectionSyncs(skip, limit, sort_field, sort_direction, populate_counts, populate_connection, exit_status, connection, connection_sync_ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.listConnectionSyncs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List and paginate through ConnectionTransaction documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionTransactionsSortFieldEnum} [sort_field] 
         * @param {ListConnectionTransactionsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_ignored] 
         * @param {ListConnectionTransactionsTransactionParameter} [transaction] 
         * @param {string} [connection_investment] 
         * @param {boolean} [populate_transaction] 
         * @param {boolean} [populate_connection_investment] 
         * @param {boolean} [populate_likely_matches] 
         * @param {string} [transaction_types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectionTransactions(connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionTransactionsSortFieldEnum, sort_direction?: ListConnectionTransactionsSortDirectionEnum, is_ignored?: boolean, transaction?: ListConnectionTransactionsTransactionParameter, connection_investment?: string, populate_transaction?: boolean, populate_connection_investment?: boolean, populate_likely_matches?: boolean, transaction_types?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnectionTransactions200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectionTransactions(connection_id, connectionsync_id, skip, limit, sort_field, sort_direction, is_ignored, transaction, connection_investment, populate_transaction, populate_connection_investment, populate_likely_matches, transaction_types, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.listConnectionTransactions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List and paginate through ConnectionUser documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionUsersSortFieldEnum} [sort_field] 
         * @param {ListConnectionUsersSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_ignored] 
         * @param {boolean} [is_auto_import_enabled] 
         * @param {ListConnectionUsersUserParameter} [user] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_likely_matches] 
         * @param {string} [search] 
         * @param {string} [connection_user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectionUsers(connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionUsersSortFieldEnum, sort_direction?: ListConnectionUsersSortDirectionEnum, is_ignored?: boolean, is_auto_import_enabled?: boolean, user?: ListConnectionUsersUserParameter, populate_user?: boolean, populate_likely_matches?: boolean, search?: string, connection_user?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnectionUsers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectionUsers(connection_id, connectionsync_id, skip, limit, sort_field, sort_direction, is_ignored, is_auto_import_enabled, user, populate_user, populate_likely_matches, search, connection_user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.listConnectionUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List and paginate through ConnectionValuation documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionValuationsSortFieldEnum} [sort_field] 
         * @param {ListConnectionValuationsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_ignored] 
         * @param {ListConnectionValuationsValuationParameter} [valuation] 
         * @param {string} [connection_investment] 
         * @param {boolean} [populate_valuation] 
         * @param {boolean} [populate_connection_investment] 
         * @param {boolean} [populate_likely_matches] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectionValuations(connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionValuationsSortFieldEnum, sort_direction?: ListConnectionValuationsSortDirectionEnum, is_ignored?: boolean, valuation?: ListConnectionValuationsValuationParameter, connection_investment?: string, populate_valuation?: boolean, populate_connection_investment?: boolean, populate_likely_matches?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnectionValuations200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectionValuations(connection_id, connectionsync_id, skip, limit, sort_field, sort_direction, is_ignored, valuation, connection_investment, populate_valuation, populate_connection_investment, populate_likely_matches, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.listConnectionValuations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a ConnectionAccount document.
         * @param {string} connection_id 
         * @param {string} account_id 
         * @param {UpdateConnectionAccountRequest} [UpdateConnectionAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnectionAccount(connection_id: string, account_id: string, UpdateConnectionAccountRequest?: UpdateConnectionAccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectionAccount(connection_id, account_id, UpdateConnectionAccountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.updateConnectionAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a ConnectionCommitment document.
         * @param {string} connection_id 
         * @param {string} commitment_id 
         * @param {UpdateConnectionCommitmentRequest} [UpdateConnectionCommitmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnectionCommitment(connection_id: string, commitment_id: string, UpdateConnectionCommitmentRequest?: UpdateConnectionCommitmentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectionCommitment(connection_id, commitment_id, UpdateConnectionCommitmentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.updateConnectionCommitment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a ConnectionDocument document.
         * @param {string} connection_id 
         * @param {string} document_id 
         * @param {UpdateConnectionDocumentRequest} [UpdateConnectionDocumentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnectionDocument(connection_id: string, document_id: string, UpdateConnectionDocumentRequest?: UpdateConnectionDocumentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectionDocument(connection_id, document_id, UpdateConnectionDocumentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.updateConnectionDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a ConnectionInvestment document.
         * @param {string} connection_id 
         * @param {string} investment_id 
         * @param {UpdateConnectionInvestmentRequest} [UpdateConnectionInvestmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnectionInvestment(connection_id: string, investment_id: string, UpdateConnectionInvestmentRequest?: UpdateConnectionInvestmentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectionInvestment(connection_id, investment_id, UpdateConnectionInvestmentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.updateConnectionInvestment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a ConnectionTransaction document.
         * @param {string} connection_id 
         * @param {string} transaction_id 
         * @param {UpdateConnectionTransactionRequest} [UpdateConnectionTransactionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnectionTransaction(connection_id: string, transaction_id: string, UpdateConnectionTransactionRequest?: UpdateConnectionTransactionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectionTransaction(connection_id, transaction_id, UpdateConnectionTransactionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.updateConnectionTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a ConnectionUser document.
         * @param {string} connection_id 
         * @param {string} user_id 
         * @param {UpdateConnectionUserRequest} [UpdateConnectionUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnectionUser(connection_id: string, user_id: string, UpdateConnectionUserRequest?: UpdateConnectionUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectionUser(connection_id, user_id, UpdateConnectionUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.updateConnectionUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a ConnectionValuation document.
         * @param {string} connection_id 
         * @param {string} valuation_id 
         * @param {UpdateConnectionValuationRequest} [UpdateConnectionValuationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnectionValuation(connection_id: string, valuation_id: string, UpdateConnectionValuationRequest?: UpdateConnectionValuationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectionValuation(connection_id, valuation_id, UpdateConnectionValuationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.updateConnectionValuation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConnectionDataApi - factory interface
 * @export
 */
export const ConnectionDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConnectionDataApiFp(configuration)
    return {
        /**
         * Attempt import of a ConnectionAccount document with optional user override.
         * @param {ConnectionDataApiImportConnectionAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionAccount(requestParameters: ConnectionDataApiImportConnectionAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.importConnectionAccount(requestParameters.connection_id, requestParameters.account_id, requestParameters.ImportConnectionAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Attempt import of a ConnectionCommitment document with optional user override.
         * @param {ConnectionDataApiImportConnectionCommitmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionCommitment(requestParameters: ConnectionDataApiImportConnectionCommitmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.importConnectionCommitment(requestParameters.connection_id, requestParameters.commitment_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Attempt import of a ConnectionDocument document.
         * @param {ConnectionDataApiImportConnectionDocumentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionDocument(requestParameters: ConnectionDataApiImportConnectionDocumentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.importConnectionDocument(requestParameters.connection_id, requestParameters.document_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Attempt import of a ConnectionInvestment document with optional account override.
         * @param {ConnectionDataApiImportConnectionInvestmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionInvestment(requestParameters: ConnectionDataApiImportConnectionInvestmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.importConnectionInvestment(requestParameters.connection_id, requestParameters.investment_id, requestParameters.ImportConnectionInvestmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Attempt import of a ConnectionTransaction document.
         * @param {ConnectionDataApiImportConnectionTransactionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionTransaction(requestParameters: ConnectionDataApiImportConnectionTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.importConnectionTransaction(requestParameters.connection_id, requestParameters.transaction_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Attempt import of a ConnectionUser document.
         * @param {ConnectionDataApiImportConnectionUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionUser(requestParameters: ConnectionDataApiImportConnectionUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.importConnectionUser(requestParameters.connection_id, requestParameters.user_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Attempt import of a ConnectionValuation document.
         * @param {ConnectionDataApiImportConnectionValuationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionValuation(requestParameters: ConnectionDataApiImportConnectionValuationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.importConnectionValuation(requestParameters.connection_id, requestParameters.valuation_id, options).then((request) => request(axios, basePath));
        },
        /**
         * List and paginate through ConnectionAccount documents.
         * @param {ConnectionDataApiListConnectionAccountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionAccounts(requestParameters: ConnectionDataApiListConnectionAccountsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListConnectionAccounts200Response> {
            return localVarFp.listConnectionAccounts(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_auto_import_enabled, requestParameters.is_auto_import_documents_enabled, requestParameters.is_ignored, requestParameters.connection_user, requestParameters.account, requestParameters.search, requestParameters.populate_account, requestParameters.populate_connection_user, requestParameters.populate_likely_matches, requestParameters.connection_account, options).then((request) => request(axios, basePath));
        },
        /**
         * List and paginate through ConnectionCommitment documents.
         * @param {ConnectionDataApiListConnectionCommitmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionCommitments(requestParameters: ConnectionDataApiListConnectionCommitmentsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListConnectionCommitments200Response> {
            return localVarFp.listConnectionCommitments(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_ignored, requestParameters.commitment, requestParameters.connection_investment, requestParameters.populate_commitment, requestParameters.populate_connection_investment, requestParameters.populate_likely_matches, options).then((request) => request(axios, basePath));
        },
        /**
         * List and paginate through ConnectionDocument documents.
         * @param {ConnectionDataApiListConnectionDocumentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionDocuments(requestParameters: ConnectionDataApiListConnectionDocumentsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListConnectionDocuments200Response> {
            return localVarFp.listConnectionDocuments(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_ignored, requestParameters.connection_investment, requestParameters.connection_account, requestParameters.populate_document, requestParameters.populate_parent, requestParameters.populate_likely_matches, requestParameters.document, requestParameters.populate_connection_investment, requestParameters.populate_connection_account, requestParameters.document_types, options).then((request) => request(axios, basePath));
        },
        /**
         * List and paginate through ConnectionInvestment documents.
         * @param {ConnectionDataApiListConnectionInvestmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionInvestments(requestParameters: ConnectionDataApiListConnectionInvestmentsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListConnectionInvestments200Response> {
            return localVarFp.listConnectionInvestments(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_auto_import_enabled, requestParameters.is_ignored, requestParameters.investment, requestParameters.search, requestParameters.connection_account, requestParameters.populate_investment, requestParameters.populate_connection_account, requestParameters.populate_likely_matches, requestParameters.connection_investment, requestParameters.investment_types, options).then((request) => request(axios, basePath));
        },
        /**
         * List and paginate through ConnectionSync documents.
         * @param {ConnectionDataApiListConnectionSyncsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionSyncs(requestParameters: ConnectionDataApiListConnectionSyncsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListConnectionSyncs200Response> {
            return localVarFp.listConnectionSyncs(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.populate_counts, requestParameters.populate_connection, requestParameters.exit_status, requestParameters.connection, requestParameters.connection_sync_ids, options).then((request) => request(axios, basePath));
        },
        /**
         * List and paginate through ConnectionTransaction documents.
         * @param {ConnectionDataApiListConnectionTransactionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionTransactions(requestParameters: ConnectionDataApiListConnectionTransactionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListConnectionTransactions200Response> {
            return localVarFp.listConnectionTransactions(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_ignored, requestParameters.transaction, requestParameters.connection_investment, requestParameters.populate_transaction, requestParameters.populate_connection_investment, requestParameters.populate_likely_matches, requestParameters.transaction_types, options).then((request) => request(axios, basePath));
        },
        /**
         * List and paginate through ConnectionUser documents.
         * @param {ConnectionDataApiListConnectionUsersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionUsers(requestParameters: ConnectionDataApiListConnectionUsersRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListConnectionUsers200Response> {
            return localVarFp.listConnectionUsers(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_ignored, requestParameters.is_auto_import_enabled, requestParameters.user, requestParameters.populate_user, requestParameters.populate_likely_matches, requestParameters.search, requestParameters.connection_user, options).then((request) => request(axios, basePath));
        },
        /**
         * List and paginate through ConnectionValuation documents.
         * @param {ConnectionDataApiListConnectionValuationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionValuations(requestParameters: ConnectionDataApiListConnectionValuationsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListConnectionValuations200Response> {
            return localVarFp.listConnectionValuations(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_ignored, requestParameters.valuation, requestParameters.connection_investment, requestParameters.populate_valuation, requestParameters.populate_connection_investment, requestParameters.populate_likely_matches, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a ConnectionAccount document.
         * @param {ConnectionDataApiUpdateConnectionAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionAccount(requestParameters: ConnectionDataApiUpdateConnectionAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateConnectionAccount(requestParameters.connection_id, requestParameters.account_id, requestParameters.UpdateConnectionAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a ConnectionCommitment document.
         * @param {ConnectionDataApiUpdateConnectionCommitmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionCommitment(requestParameters: ConnectionDataApiUpdateConnectionCommitmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateConnectionCommitment(requestParameters.connection_id, requestParameters.commitment_id, requestParameters.UpdateConnectionCommitmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a ConnectionDocument document.
         * @param {ConnectionDataApiUpdateConnectionDocumentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionDocument(requestParameters: ConnectionDataApiUpdateConnectionDocumentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateConnectionDocument(requestParameters.connection_id, requestParameters.document_id, requestParameters.UpdateConnectionDocumentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a ConnectionInvestment document.
         * @param {ConnectionDataApiUpdateConnectionInvestmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionInvestment(requestParameters: ConnectionDataApiUpdateConnectionInvestmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateConnectionInvestment(requestParameters.connection_id, requestParameters.investment_id, requestParameters.UpdateConnectionInvestmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a ConnectionTransaction document.
         * @param {ConnectionDataApiUpdateConnectionTransactionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionTransaction(requestParameters: ConnectionDataApiUpdateConnectionTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateConnectionTransaction(requestParameters.connection_id, requestParameters.transaction_id, requestParameters.UpdateConnectionTransactionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a ConnectionUser document.
         * @param {ConnectionDataApiUpdateConnectionUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionUser(requestParameters: ConnectionDataApiUpdateConnectionUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateConnectionUser(requestParameters.connection_id, requestParameters.user_id, requestParameters.UpdateConnectionUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a ConnectionValuation document.
         * @param {ConnectionDataApiUpdateConnectionValuationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionValuation(requestParameters: ConnectionDataApiUpdateConnectionValuationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateConnectionValuation(requestParameters.connection_id, requestParameters.valuation_id, requestParameters.UpdateConnectionValuationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for importConnectionAccount operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiImportConnectionAccountRequest
 */
export interface ConnectionDataApiImportConnectionAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionAccount
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionAccount
     */
    readonly account_id: string

    /**
     * 
     * @type {ImportConnectionAccountRequest}
     * @memberof ConnectionDataApiImportConnectionAccount
     */
    readonly ImportConnectionAccountRequest?: ImportConnectionAccountRequest
}

/**
 * Request parameters for importConnectionCommitment operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiImportConnectionCommitmentRequest
 */
export interface ConnectionDataApiImportConnectionCommitmentRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionCommitment
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionCommitment
     */
    readonly commitment_id: string
}

/**
 * Request parameters for importConnectionDocument operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiImportConnectionDocumentRequest
 */
export interface ConnectionDataApiImportConnectionDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionDocument
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionDocument
     */
    readonly document_id: string
}

/**
 * Request parameters for importConnectionInvestment operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiImportConnectionInvestmentRequest
 */
export interface ConnectionDataApiImportConnectionInvestmentRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionInvestment
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionInvestment
     */
    readonly investment_id: string

    /**
     * 
     * @type {ImportConnectionInvestmentRequest}
     * @memberof ConnectionDataApiImportConnectionInvestment
     */
    readonly ImportConnectionInvestmentRequest?: ImportConnectionInvestmentRequest
}

/**
 * Request parameters for importConnectionTransaction operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiImportConnectionTransactionRequest
 */
export interface ConnectionDataApiImportConnectionTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionTransaction
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionTransaction
     */
    readonly transaction_id: string
}

/**
 * Request parameters for importConnectionUser operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiImportConnectionUserRequest
 */
export interface ConnectionDataApiImportConnectionUserRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionUser
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionUser
     */
    readonly user_id: string
}

/**
 * Request parameters for importConnectionValuation operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiImportConnectionValuationRequest
 */
export interface ConnectionDataApiImportConnectionValuationRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionValuation
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionValuation
     */
    readonly valuation_id: string
}

/**
 * Request parameters for listConnectionAccounts operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiListConnectionAccountsRequest
 */
export interface ConnectionDataApiListConnectionAccountsRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly connectionsync_id: string

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'name'}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly sort_field?: ListConnectionAccountsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly sort_direction?: ListConnectionAccountsSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly is_auto_import_enabled?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly is_auto_import_documents_enabled?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly is_ignored?: boolean

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly connection_user?: string

    /**
     * 
     * @type {ListConnectionAccountsAccountParameter}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly account?: ListConnectionAccountsAccountParameter

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly populate_connection_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly populate_likely_matches?: boolean

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly connection_account?: string
}

/**
 * Request parameters for listConnectionCommitments operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiListConnectionCommitmentsRequest
 */
export interface ConnectionDataApiListConnectionCommitmentsRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly connectionsync_id: string

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'name'}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly sort_field?: ListConnectionCommitmentsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly sort_direction?: ListConnectionCommitmentsSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly is_ignored?: boolean

    /**
     * 
     * @type {ListConnectionCommitmentsCommitmentParameter}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly commitment?: ListConnectionCommitmentsCommitmentParameter

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly connection_investment?: string

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly populate_commitment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly populate_connection_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly populate_likely_matches?: boolean
}

/**
 * Request parameters for listConnectionDocuments operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiListConnectionDocumentsRequest
 */
export interface ConnectionDataApiListConnectionDocumentsRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly connectionsync_id: string

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'name'}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly sort_field?: ListConnectionDocumentsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly sort_direction?: ListConnectionDocumentsSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly is_ignored?: boolean

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly connection_investment?: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly connection_account?: string

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly populate_document?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly populate_parent?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly populate_likely_matches?: boolean

    /**
     * 
     * @type {ListConnectionDocumentsDocumentParameter}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly document?: ListConnectionDocumentsDocumentParameter

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly populate_connection_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly populate_connection_account?: boolean

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly document_types?: string
}

/**
 * Request parameters for listConnectionInvestments operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiListConnectionInvestmentsRequest
 */
export interface ConnectionDataApiListConnectionInvestmentsRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly connectionsync_id: string

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'name'}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly sort_field?: ListConnectionInvestmentsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly sort_direction?: ListConnectionInvestmentsSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly is_auto_import_enabled?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly is_ignored?: boolean

    /**
     * 
     * @type {ListConnectionInvestmentsInvestmentParameter}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly investment?: ListConnectionInvestmentsInvestmentParameter

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly connection_account?: string

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly populate_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly populate_connection_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly populate_likely_matches?: boolean

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly connection_investment?: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly investment_types?: string
}

/**
 * Request parameters for listConnectionSyncs operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiListConnectionSyncsRequest
 */
export interface ConnectionDataApiListConnectionSyncsRequest {
    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionSyncs
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionSyncs
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'completed_at'}
     * @memberof ConnectionDataApiListConnectionSyncs
     */
    readonly sort_field?: ListConnectionSyncsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ConnectionDataApiListConnectionSyncs
     */
    readonly sort_direction?: ListConnectionSyncsSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionSyncs
     */
    readonly populate_counts?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionSyncs
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {'success' | 'failure' | 'pending' | 'queued'}
     * @memberof ConnectionDataApiListConnectionSyncs
     */
    readonly exit_status?: ListConnectionSyncsExitStatusEnum

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionSyncs
     */
    readonly connection?: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionSyncs
     */
    readonly connection_sync_ids?: string
}

/**
 * Request parameters for listConnectionTransactions operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiListConnectionTransactionsRequest
 */
export interface ConnectionDataApiListConnectionTransactionsRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly connectionsync_id: string

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'name'}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly sort_field?: ListConnectionTransactionsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly sort_direction?: ListConnectionTransactionsSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly is_ignored?: boolean

    /**
     * 
     * @type {ListConnectionTransactionsTransactionParameter}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly transaction?: ListConnectionTransactionsTransactionParameter

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly connection_investment?: string

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly populate_transaction?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly populate_connection_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly populate_likely_matches?: boolean

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly transaction_types?: string
}

/**
 * Request parameters for listConnectionUsers operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiListConnectionUsersRequest
 */
export interface ConnectionDataApiListConnectionUsersRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly connectionsync_id: string

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'name'}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly sort_field?: ListConnectionUsersSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly sort_direction?: ListConnectionUsersSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly is_ignored?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly is_auto_import_enabled?: boolean

    /**
     * 
     * @type {ListConnectionUsersUserParameter}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly user?: ListConnectionUsersUserParameter

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly populate_likely_matches?: boolean

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly connection_user?: string
}

/**
 * Request parameters for listConnectionValuations operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiListConnectionValuationsRequest
 */
export interface ConnectionDataApiListConnectionValuationsRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly connectionsync_id: string

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'name'}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly sort_field?: ListConnectionValuationsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly sort_direction?: ListConnectionValuationsSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly is_ignored?: boolean

    /**
     * 
     * @type {ListConnectionValuationsValuationParameter}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly valuation?: ListConnectionValuationsValuationParameter

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly connection_investment?: string

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly populate_valuation?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly populate_connection_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly populate_likely_matches?: boolean
}

/**
 * Request parameters for updateConnectionAccount operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiUpdateConnectionAccountRequest
 */
export interface ConnectionDataApiUpdateConnectionAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionAccount
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionAccount
     */
    readonly account_id: string

    /**
     * 
     * @type {UpdateConnectionAccountRequest}
     * @memberof ConnectionDataApiUpdateConnectionAccount
     */
    readonly UpdateConnectionAccountRequest?: UpdateConnectionAccountRequest
}

/**
 * Request parameters for updateConnectionCommitment operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiUpdateConnectionCommitmentRequest
 */
export interface ConnectionDataApiUpdateConnectionCommitmentRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionCommitment
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionCommitment
     */
    readonly commitment_id: string

    /**
     * 
     * @type {UpdateConnectionCommitmentRequest}
     * @memberof ConnectionDataApiUpdateConnectionCommitment
     */
    readonly UpdateConnectionCommitmentRequest?: UpdateConnectionCommitmentRequest
}

/**
 * Request parameters for updateConnectionDocument operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiUpdateConnectionDocumentRequest
 */
export interface ConnectionDataApiUpdateConnectionDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionDocument
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionDocument
     */
    readonly document_id: string

    /**
     * 
     * @type {UpdateConnectionDocumentRequest}
     * @memberof ConnectionDataApiUpdateConnectionDocument
     */
    readonly UpdateConnectionDocumentRequest?: UpdateConnectionDocumentRequest
}

/**
 * Request parameters for updateConnectionInvestment operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiUpdateConnectionInvestmentRequest
 */
export interface ConnectionDataApiUpdateConnectionInvestmentRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionInvestment
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionInvestment
     */
    readonly investment_id: string

    /**
     * 
     * @type {UpdateConnectionInvestmentRequest}
     * @memberof ConnectionDataApiUpdateConnectionInvestment
     */
    readonly UpdateConnectionInvestmentRequest?: UpdateConnectionInvestmentRequest
}

/**
 * Request parameters for updateConnectionTransaction operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiUpdateConnectionTransactionRequest
 */
export interface ConnectionDataApiUpdateConnectionTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionTransaction
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionTransaction
     */
    readonly transaction_id: string

    /**
     * 
     * @type {UpdateConnectionTransactionRequest}
     * @memberof ConnectionDataApiUpdateConnectionTransaction
     */
    readonly UpdateConnectionTransactionRequest?: UpdateConnectionTransactionRequest
}

/**
 * Request parameters for updateConnectionUser operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiUpdateConnectionUserRequest
 */
export interface ConnectionDataApiUpdateConnectionUserRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionUser
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionUser
     */
    readonly user_id: string

    /**
     * 
     * @type {UpdateConnectionUserRequest}
     * @memberof ConnectionDataApiUpdateConnectionUser
     */
    readonly UpdateConnectionUserRequest?: UpdateConnectionUserRequest
}

/**
 * Request parameters for updateConnectionValuation operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiUpdateConnectionValuationRequest
 */
export interface ConnectionDataApiUpdateConnectionValuationRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionValuation
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionValuation
     */
    readonly valuation_id: string

    /**
     * 
     * @type {UpdateConnectionValuationRequest}
     * @memberof ConnectionDataApiUpdateConnectionValuation
     */
    readonly UpdateConnectionValuationRequest?: UpdateConnectionValuationRequest
}

/**
 * ConnectionDataApi - object-oriented interface
 * @export
 * @class ConnectionDataApi
 * @extends {BaseAPI}
 */
export class ConnectionDataApi extends BaseAPI {
    /**
     * Attempt import of a ConnectionAccount document with optional user override.
     * @param {ConnectionDataApiImportConnectionAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public importConnectionAccount(requestParameters: ConnectionDataApiImportConnectionAccountRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).importConnectionAccount(requestParameters.connection_id, requestParameters.account_id, requestParameters.ImportConnectionAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Attempt import of a ConnectionCommitment document with optional user override.
     * @param {ConnectionDataApiImportConnectionCommitmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public importConnectionCommitment(requestParameters: ConnectionDataApiImportConnectionCommitmentRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).importConnectionCommitment(requestParameters.connection_id, requestParameters.commitment_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Attempt import of a ConnectionDocument document.
     * @param {ConnectionDataApiImportConnectionDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public importConnectionDocument(requestParameters: ConnectionDataApiImportConnectionDocumentRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).importConnectionDocument(requestParameters.connection_id, requestParameters.document_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Attempt import of a ConnectionInvestment document with optional account override.
     * @param {ConnectionDataApiImportConnectionInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public importConnectionInvestment(requestParameters: ConnectionDataApiImportConnectionInvestmentRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).importConnectionInvestment(requestParameters.connection_id, requestParameters.investment_id, requestParameters.ImportConnectionInvestmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Attempt import of a ConnectionTransaction document.
     * @param {ConnectionDataApiImportConnectionTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public importConnectionTransaction(requestParameters: ConnectionDataApiImportConnectionTransactionRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).importConnectionTransaction(requestParameters.connection_id, requestParameters.transaction_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Attempt import of a ConnectionUser document.
     * @param {ConnectionDataApiImportConnectionUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public importConnectionUser(requestParameters: ConnectionDataApiImportConnectionUserRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).importConnectionUser(requestParameters.connection_id, requestParameters.user_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Attempt import of a ConnectionValuation document.
     * @param {ConnectionDataApiImportConnectionValuationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public importConnectionValuation(requestParameters: ConnectionDataApiImportConnectionValuationRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).importConnectionValuation(requestParameters.connection_id, requestParameters.valuation_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List and paginate through ConnectionAccount documents.
     * @param {ConnectionDataApiListConnectionAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public listConnectionAccounts(requestParameters: ConnectionDataApiListConnectionAccountsRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).listConnectionAccounts(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_auto_import_enabled, requestParameters.is_auto_import_documents_enabled, requestParameters.is_ignored, requestParameters.connection_user, requestParameters.account, requestParameters.search, requestParameters.populate_account, requestParameters.populate_connection_user, requestParameters.populate_likely_matches, requestParameters.connection_account, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List and paginate through ConnectionCommitment documents.
     * @param {ConnectionDataApiListConnectionCommitmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public listConnectionCommitments(requestParameters: ConnectionDataApiListConnectionCommitmentsRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).listConnectionCommitments(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_ignored, requestParameters.commitment, requestParameters.connection_investment, requestParameters.populate_commitment, requestParameters.populate_connection_investment, requestParameters.populate_likely_matches, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List and paginate through ConnectionDocument documents.
     * @param {ConnectionDataApiListConnectionDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public listConnectionDocuments(requestParameters: ConnectionDataApiListConnectionDocumentsRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).listConnectionDocuments(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_ignored, requestParameters.connection_investment, requestParameters.connection_account, requestParameters.populate_document, requestParameters.populate_parent, requestParameters.populate_likely_matches, requestParameters.document, requestParameters.populate_connection_investment, requestParameters.populate_connection_account, requestParameters.document_types, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List and paginate through ConnectionInvestment documents.
     * @param {ConnectionDataApiListConnectionInvestmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public listConnectionInvestments(requestParameters: ConnectionDataApiListConnectionInvestmentsRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).listConnectionInvestments(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_auto_import_enabled, requestParameters.is_ignored, requestParameters.investment, requestParameters.search, requestParameters.connection_account, requestParameters.populate_investment, requestParameters.populate_connection_account, requestParameters.populate_likely_matches, requestParameters.connection_investment, requestParameters.investment_types, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List and paginate through ConnectionSync documents.
     * @param {ConnectionDataApiListConnectionSyncsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public listConnectionSyncs(requestParameters: ConnectionDataApiListConnectionSyncsRequest = {}, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).listConnectionSyncs(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.populate_counts, requestParameters.populate_connection, requestParameters.exit_status, requestParameters.connection, requestParameters.connection_sync_ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List and paginate through ConnectionTransaction documents.
     * @param {ConnectionDataApiListConnectionTransactionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public listConnectionTransactions(requestParameters: ConnectionDataApiListConnectionTransactionsRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).listConnectionTransactions(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_ignored, requestParameters.transaction, requestParameters.connection_investment, requestParameters.populate_transaction, requestParameters.populate_connection_investment, requestParameters.populate_likely_matches, requestParameters.transaction_types, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List and paginate through ConnectionUser documents.
     * @param {ConnectionDataApiListConnectionUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public listConnectionUsers(requestParameters: ConnectionDataApiListConnectionUsersRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).listConnectionUsers(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_ignored, requestParameters.is_auto_import_enabled, requestParameters.user, requestParameters.populate_user, requestParameters.populate_likely_matches, requestParameters.search, requestParameters.connection_user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List and paginate through ConnectionValuation documents.
     * @param {ConnectionDataApiListConnectionValuationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public listConnectionValuations(requestParameters: ConnectionDataApiListConnectionValuationsRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).listConnectionValuations(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_ignored, requestParameters.valuation, requestParameters.connection_investment, requestParameters.populate_valuation, requestParameters.populate_connection_investment, requestParameters.populate_likely_matches, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a ConnectionAccount document.
     * @param {ConnectionDataApiUpdateConnectionAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public updateConnectionAccount(requestParameters: ConnectionDataApiUpdateConnectionAccountRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).updateConnectionAccount(requestParameters.connection_id, requestParameters.account_id, requestParameters.UpdateConnectionAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a ConnectionCommitment document.
     * @param {ConnectionDataApiUpdateConnectionCommitmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public updateConnectionCommitment(requestParameters: ConnectionDataApiUpdateConnectionCommitmentRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).updateConnectionCommitment(requestParameters.connection_id, requestParameters.commitment_id, requestParameters.UpdateConnectionCommitmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a ConnectionDocument document.
     * @param {ConnectionDataApiUpdateConnectionDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public updateConnectionDocument(requestParameters: ConnectionDataApiUpdateConnectionDocumentRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).updateConnectionDocument(requestParameters.connection_id, requestParameters.document_id, requestParameters.UpdateConnectionDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a ConnectionInvestment document.
     * @param {ConnectionDataApiUpdateConnectionInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public updateConnectionInvestment(requestParameters: ConnectionDataApiUpdateConnectionInvestmentRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).updateConnectionInvestment(requestParameters.connection_id, requestParameters.investment_id, requestParameters.UpdateConnectionInvestmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a ConnectionTransaction document.
     * @param {ConnectionDataApiUpdateConnectionTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public updateConnectionTransaction(requestParameters: ConnectionDataApiUpdateConnectionTransactionRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).updateConnectionTransaction(requestParameters.connection_id, requestParameters.transaction_id, requestParameters.UpdateConnectionTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a ConnectionUser document.
     * @param {ConnectionDataApiUpdateConnectionUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public updateConnectionUser(requestParameters: ConnectionDataApiUpdateConnectionUserRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).updateConnectionUser(requestParameters.connection_id, requestParameters.user_id, requestParameters.UpdateConnectionUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a ConnectionValuation document.
     * @param {ConnectionDataApiUpdateConnectionValuationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public updateConnectionValuation(requestParameters: ConnectionDataApiUpdateConnectionValuationRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).updateConnectionValuation(requestParameters.connection_id, requestParameters.valuation_id, requestParameters.UpdateConnectionValuationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListConnectionAccountsSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name'
} as const;
export type ListConnectionAccountsSortFieldEnum = typeof ListConnectionAccountsSortFieldEnum[keyof typeof ListConnectionAccountsSortFieldEnum];
/**
 * @export
 */
export const ListConnectionAccountsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListConnectionAccountsSortDirectionEnum = typeof ListConnectionAccountsSortDirectionEnum[keyof typeof ListConnectionAccountsSortDirectionEnum];
/**
 * @export
 */
export const ListConnectionCommitmentsSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name'
} as const;
export type ListConnectionCommitmentsSortFieldEnum = typeof ListConnectionCommitmentsSortFieldEnum[keyof typeof ListConnectionCommitmentsSortFieldEnum];
/**
 * @export
 */
export const ListConnectionCommitmentsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListConnectionCommitmentsSortDirectionEnum = typeof ListConnectionCommitmentsSortDirectionEnum[keyof typeof ListConnectionCommitmentsSortDirectionEnum];
/**
 * @export
 */
export const ListConnectionDocumentsSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name'
} as const;
export type ListConnectionDocumentsSortFieldEnum = typeof ListConnectionDocumentsSortFieldEnum[keyof typeof ListConnectionDocumentsSortFieldEnum];
/**
 * @export
 */
export const ListConnectionDocumentsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListConnectionDocumentsSortDirectionEnum = typeof ListConnectionDocumentsSortDirectionEnum[keyof typeof ListConnectionDocumentsSortDirectionEnum];
/**
 * @export
 */
export const ListConnectionInvestmentsSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name'
} as const;
export type ListConnectionInvestmentsSortFieldEnum = typeof ListConnectionInvestmentsSortFieldEnum[keyof typeof ListConnectionInvestmentsSortFieldEnum];
/**
 * @export
 */
export const ListConnectionInvestmentsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListConnectionInvestmentsSortDirectionEnum = typeof ListConnectionInvestmentsSortDirectionEnum[keyof typeof ListConnectionInvestmentsSortDirectionEnum];
/**
 * @export
 */
export const ListConnectionSyncsSortFieldEnum = {
    CreatedAt: 'created_at',
    CompletedAt: 'completed_at'
} as const;
export type ListConnectionSyncsSortFieldEnum = typeof ListConnectionSyncsSortFieldEnum[keyof typeof ListConnectionSyncsSortFieldEnum];
/**
 * @export
 */
export const ListConnectionSyncsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListConnectionSyncsSortDirectionEnum = typeof ListConnectionSyncsSortDirectionEnum[keyof typeof ListConnectionSyncsSortDirectionEnum];
/**
 * @export
 */
export const ListConnectionSyncsExitStatusEnum = {
    Success: 'success',
    Failure: 'failure',
    Pending: 'pending',
    Queued: 'queued'
} as const;
export type ListConnectionSyncsExitStatusEnum = typeof ListConnectionSyncsExitStatusEnum[keyof typeof ListConnectionSyncsExitStatusEnum];
/**
 * @export
 */
export const ListConnectionTransactionsSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name'
} as const;
export type ListConnectionTransactionsSortFieldEnum = typeof ListConnectionTransactionsSortFieldEnum[keyof typeof ListConnectionTransactionsSortFieldEnum];
/**
 * @export
 */
export const ListConnectionTransactionsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListConnectionTransactionsSortDirectionEnum = typeof ListConnectionTransactionsSortDirectionEnum[keyof typeof ListConnectionTransactionsSortDirectionEnum];
/**
 * @export
 */
export const ListConnectionUsersSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name'
} as const;
export type ListConnectionUsersSortFieldEnum = typeof ListConnectionUsersSortFieldEnum[keyof typeof ListConnectionUsersSortFieldEnum];
/**
 * @export
 */
export const ListConnectionUsersSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListConnectionUsersSortDirectionEnum = typeof ListConnectionUsersSortDirectionEnum[keyof typeof ListConnectionUsersSortDirectionEnum];
/**
 * @export
 */
export const ListConnectionValuationsSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name'
} as const;
export type ListConnectionValuationsSortFieldEnum = typeof ListConnectionValuationsSortFieldEnum[keyof typeof ListConnectionValuationsSortFieldEnum];
/**
 * @export
 */
export const ListConnectionValuationsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListConnectionValuationsSortDirectionEnum = typeof ListConnectionValuationsSortDirectionEnum[keyof typeof ListConnectionValuationsSortDirectionEnum];


/**
 * ContactApi - axios parameter creator
 * @export
 */
export const ContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a Contact document.
         * @param {ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact} [ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact: async (ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact?: ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/contacts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Contact document.
         * @param {string | null} contact_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContact: async (contact_id: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contact_id' is not null or undefined
            assertParamExists('deleteContact', 'contact_id', contact_id)
            const localVarPath = `/v2/contacts/{contact_id}`
                .replace(`{${"contact_id"}}`, encodeURIComponent(String(contact_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Contact documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListContactsSortDirectionEnum} [sort_direction] 
         * @param {ListContactsSortFieldEnum} [sort_field] 
         * @param {string} [search] 
         * @param {Array<string>} [contact_ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContacts: async (skip?: number, limit?: number, sort_direction?: ListContactsSortDirectionEnum, sort_field?: ListContactsSortFieldEnum, search?: string, contact_ids?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/contacts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (contact_ids) {
                localVarQueryParameter['contact_ids'] = contact_ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a Contact document.
         * @param {string} contact_id 
         * @param {UpdateContactRequest} [UpdateContactRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContact: async (contact_id: string, UpdateContactRequest?: UpdateContactRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contact_id' is not null or undefined
            assertParamExists('updateContact', 'contact_id', contact_id)
            const localVarPath = `/v2/contacts/{contact_id}`
                .replace(`{${"contact_id"}}`, encodeURIComponent(String(contact_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateContactRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactApi - functional programming interface
 * @export
 */
export const ContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a Contact document.
         * @param {ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact} [ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContact(ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact?: ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateContact201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContact(ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactApi.createContact']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a Contact document.
         * @param {string | null} contact_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContact(contact_id: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContact(contact_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactApi.deleteContact']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Contact documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListContactsSortDirectionEnum} [sort_direction] 
         * @param {ListContactsSortFieldEnum} [sort_field] 
         * @param {string} [search] 
         * @param {Array<string>} [contact_ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listContacts(skip?: number, limit?: number, sort_direction?: ListContactsSortDirectionEnum, sort_field?: ListContactsSortFieldEnum, search?: string, contact_ids?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListContacts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listContacts(skip, limit, sort_direction, sort_field, search, contact_ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactApi.listContacts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a Contact document.
         * @param {string} contact_id 
         * @param {UpdateContactRequest} [UpdateContactRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContact(contact_id: string, UpdateContactRequest?: UpdateContactRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContact(contact_id, UpdateContactRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactApi.updateContact']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ContactApi - factory interface
 * @export
 */
export const ContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactApiFp(configuration)
    return {
        /**
         * Create a Contact document.
         * @param {ContactApiCreateContactRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact(requestParameters: ContactApiCreateContactRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CreateContact201Response> {
            return localVarFp.createContact(requestParameters.ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Contact document.
         * @param {ContactApiDeleteContactRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContact(requestParameters: ContactApiDeleteContactRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteContact(requestParameters.contact_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Contact documents.
         * @param {ContactApiListContactsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContacts(requestParameters: ContactApiListContactsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListContacts200Response> {
            return localVarFp.listContacts(requestParameters.skip, requestParameters.limit, requestParameters.sort_direction, requestParameters.sort_field, requestParameters.search, requestParameters.contact_ids, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a Contact document.
         * @param {ContactApiUpdateContactRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContact(requestParameters: ContactApiUpdateContactRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateContact(requestParameters.contact_id, requestParameters.UpdateContactRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createContact operation in ContactApi.
 * @export
 * @interface ContactApiCreateContactRequest
 */
export interface ContactApiCreateContactRequest {
    /**
     * 
     * @type {ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact}
     * @memberof ContactApiCreateContact
     */
    readonly ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact?: ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact
}

/**
 * Request parameters for deleteContact operation in ContactApi.
 * @export
 * @interface ContactApiDeleteContactRequest
 */
export interface ContactApiDeleteContactRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactApiDeleteContact
     */
    readonly contact_id: string | null
}

/**
 * Request parameters for listContacts operation in ContactApi.
 * @export
 * @interface ContactApiListContactsRequest
 */
export interface ContactApiListContactsRequest {
    /**
     * 
     * @type {number}
     * @memberof ContactApiListContacts
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ContactApiListContacts
     */
    readonly limit?: number

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ContactApiListContacts
     */
    readonly sort_direction?: ListContactsSortDirectionEnum

    /**
     * 
     * @type {'created_at' | 'updated_at' | 'first_name' | 'last_name' | 'email' | 'phone'}
     * @memberof ContactApiListContacts
     */
    readonly sort_field?: ListContactsSortFieldEnum

    /**
     * 
     * @type {string}
     * @memberof ContactApiListContacts
     */
    readonly search?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ContactApiListContacts
     */
    readonly contact_ids?: Array<string>
}

/**
 * Request parameters for updateContact operation in ContactApi.
 * @export
 * @interface ContactApiUpdateContactRequest
 */
export interface ContactApiUpdateContactRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactApiUpdateContact
     */
    readonly contact_id: string

    /**
     * 
     * @type {UpdateContactRequest}
     * @memberof ContactApiUpdateContact
     */
    readonly UpdateContactRequest?: UpdateContactRequest
}

/**
 * ContactApi - object-oriented interface
 * @export
 * @class ContactApi
 * @extends {BaseAPI}
 */
export class ContactApi extends BaseAPI {
    /**
     * Create a Contact document.
     * @param {ContactApiCreateContactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public createContact(requestParameters: ContactApiCreateContactRequest = {}, options?: RawAxiosRequestConfig) {
        return ContactApiFp(this.configuration).createContact(requestParameters.ListAssetManagers200ResponseAssetManagersInnerContactsInnerContact, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Contact document.
     * @param {ContactApiDeleteContactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public deleteContact(requestParameters: ContactApiDeleteContactRequest, options?: RawAxiosRequestConfig) {
        return ContactApiFp(this.configuration).deleteContact(requestParameters.contact_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Contact documents.
     * @param {ContactApiListContactsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public listContacts(requestParameters: ContactApiListContactsRequest = {}, options?: RawAxiosRequestConfig) {
        return ContactApiFp(this.configuration).listContacts(requestParameters.skip, requestParameters.limit, requestParameters.sort_direction, requestParameters.sort_field, requestParameters.search, requestParameters.contact_ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a Contact document.
     * @param {ContactApiUpdateContactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public updateContact(requestParameters: ContactApiUpdateContactRequest, options?: RawAxiosRequestConfig) {
        return ContactApiFp(this.configuration).updateContact(requestParameters.contact_id, requestParameters.UpdateContactRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListContactsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListContactsSortDirectionEnum = typeof ListContactsSortDirectionEnum[keyof typeof ListContactsSortDirectionEnum];
/**
 * @export
 */
export const ListContactsSortFieldEnum = {
    CreatedAt: 'created_at',
    UpdatedAt: 'updated_at',
    FirstName: 'first_name',
    LastName: 'last_name',
    Email: 'email',
    Phone: 'phone'
} as const;
export type ListContactsSortFieldEnum = typeof ListContactsSortFieldEnum[keyof typeof ListContactsSortFieldEnum];


/**
 * DefaultDocumentImportSettingApi - axios parameter creator
 * @export
 */
export const DefaultDocumentImportSettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Default Document Import Setting.
         * @param {CreateDefaultDocumentImportSettingRequest} [CreateDefaultDocumentImportSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDefaultDocumentImportSetting: async (CreateDefaultDocumentImportSettingRequest?: CreateDefaultDocumentImportSettingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/defaultdocumentimportsettings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateDefaultDocumentImportSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Default Document Import Setting document.
         * @param {string} default_document_import_setting_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDefaultDocumentImportSetting: async (default_document_import_setting_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'default_document_import_setting_id' is not null or undefined
            assertParamExists('deleteDefaultDocumentImportSetting', 'default_document_import_setting_id', default_document_import_setting_id)
            const localVarPath = `/v2/defaultdocumentimportsettings/{default_document_import_setting_id}`
                .replace(`{${"default_document_import_setting_id"}}`, encodeURIComponent(String(default_document_import_setting_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single Default Document Import Setting document by ID.
         * @param {string | null} default_document_import_setting_id 
         * @param {boolean} [populate_integration] 
         * @param {boolean} [populate_asset_manager] 
         * @param {boolean} [populate_advisory_group] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultDocumentImportSetting: async (default_document_import_setting_id: string | null, populate_integration?: boolean, populate_asset_manager?: boolean, populate_advisory_group?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'default_document_import_setting_id' is not null or undefined
            assertParamExists('getDefaultDocumentImportSetting', 'default_document_import_setting_id', default_document_import_setting_id)
            const localVarPath = `/v2/defaultdocumentimportsettings/{default_document_import_setting_id}`
                .replace(`{${"default_document_import_setting_id"}}`, encodeURIComponent(String(default_document_import_setting_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_integration !== undefined) {
                localVarQueryParameter['populate_integration'] = populate_integration;
            }

            if (populate_asset_manager !== undefined) {
                localVarQueryParameter['populate_asset_manager'] = populate_asset_manager;
            }

            if (populate_advisory_group !== undefined) {
                localVarQueryParameter['populate_advisory_group'] = populate_advisory_group;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Default Document Import Setting documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListDefaultDocumentImportSettingsSortFieldEnum} [sort_field] 
         * @param {ListDefaultDocumentImportSettingsSortDirectionEnum} [sort_direction] 
         * @param {string} [integration] 
         * @param {ListDefaultDocumentImportSettingsGlobalDefaultTransactionSettingEnum} [global_default_transaction_setting] 
         * @param {string} [asset_manager] 
         * @param {string} [connection] 
         * @param {boolean} [populate_integration] 
         * @param {boolean} [populate_asset_manager] 
         * @param {boolean} [populate_advisory_group] 
         * @param {boolean} [include_merged_setting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDefaultDocumentImportSettings: async (skip?: number, limit?: number, sort_field?: ListDefaultDocumentImportSettingsSortFieldEnum, sort_direction?: ListDefaultDocumentImportSettingsSortDirectionEnum, integration?: string, global_default_transaction_setting?: ListDefaultDocumentImportSettingsGlobalDefaultTransactionSettingEnum, asset_manager?: string, connection?: string, populate_integration?: boolean, populate_asset_manager?: boolean, populate_advisory_group?: boolean, include_merged_setting?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/defaultdocumentimportsettings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (integration !== undefined) {
                localVarQueryParameter['integration'] = integration;
            }

            if (global_default_transaction_setting !== undefined) {
                localVarQueryParameter['global_default_transaction_setting'] = global_default_transaction_setting;
            }

            if (asset_manager !== undefined) {
                localVarQueryParameter['asset_manager'] = asset_manager;
            }

            if (connection !== undefined) {
                localVarQueryParameter['connection'] = connection;
            }

            if (populate_integration !== undefined) {
                localVarQueryParameter['populate_integration'] = populate_integration;
            }

            if (populate_asset_manager !== undefined) {
                localVarQueryParameter['populate_asset_manager'] = populate_asset_manager;
            }

            if (populate_advisory_group !== undefined) {
                localVarQueryParameter['populate_advisory_group'] = populate_advisory_group;
            }

            if (include_merged_setting !== undefined) {
                localVarQueryParameter['include_merged_setting'] = include_merged_setting;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Default Document Import Setting document.
         * @param {string} default_document_import_setting_id 
         * @param {UpdateDefaultDocumentImportSettingRequest} [UpdateDefaultDocumentImportSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDefaultDocumentImportSetting: async (default_document_import_setting_id: string, UpdateDefaultDocumentImportSettingRequest?: UpdateDefaultDocumentImportSettingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'default_document_import_setting_id' is not null or undefined
            assertParamExists('updateDefaultDocumentImportSetting', 'default_document_import_setting_id', default_document_import_setting_id)
            const localVarPath = `/v2/defaultdocumentimportsettings/{default_document_import_setting_id}`
                .replace(`{${"default_document_import_setting_id"}}`, encodeURIComponent(String(default_document_import_setting_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateDefaultDocumentImportSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultDocumentImportSettingApi - functional programming interface
 * @export
 */
export const DefaultDocumentImportSettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultDocumentImportSettingApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Default Document Import Setting.
         * @param {CreateDefaultDocumentImportSettingRequest} [CreateDefaultDocumentImportSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDefaultDocumentImportSetting(CreateDefaultDocumentImportSettingRequest?: CreateDefaultDocumentImportSettingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDefaultDocumentImportSetting201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDefaultDocumentImportSetting(CreateDefaultDocumentImportSettingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultDocumentImportSettingApi.createDefaultDocumentImportSetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a Default Document Import Setting document.
         * @param {string} default_document_import_setting_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDefaultDocumentImportSetting(default_document_import_setting_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDefaultDocumentImportSetting(default_document_import_setting_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultDocumentImportSettingApi.deleteDefaultDocumentImportSetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single Default Document Import Setting document by ID.
         * @param {string | null} default_document_import_setting_id 
         * @param {boolean} [populate_integration] 
         * @param {boolean} [populate_asset_manager] 
         * @param {boolean} [populate_advisory_group] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultDocumentImportSetting(default_document_import_setting_id: string | null, populate_integration?: boolean, populate_asset_manager?: boolean, populate_advisory_group?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDefaultDocumentImportSetting200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultDocumentImportSetting(default_document_import_setting_id, populate_integration, populate_asset_manager, populate_advisory_group, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultDocumentImportSettingApi.getDefaultDocumentImportSetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Default Document Import Setting documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListDefaultDocumentImportSettingsSortFieldEnum} [sort_field] 
         * @param {ListDefaultDocumentImportSettingsSortDirectionEnum} [sort_direction] 
         * @param {string} [integration] 
         * @param {ListDefaultDocumentImportSettingsGlobalDefaultTransactionSettingEnum} [global_default_transaction_setting] 
         * @param {string} [asset_manager] 
         * @param {string} [connection] 
         * @param {boolean} [populate_integration] 
         * @param {boolean} [populate_asset_manager] 
         * @param {boolean} [populate_advisory_group] 
         * @param {boolean} [include_merged_setting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDefaultDocumentImportSettings(skip?: number, limit?: number, sort_field?: ListDefaultDocumentImportSettingsSortFieldEnum, sort_direction?: ListDefaultDocumentImportSettingsSortDirectionEnum, integration?: string, global_default_transaction_setting?: ListDefaultDocumentImportSettingsGlobalDefaultTransactionSettingEnum, asset_manager?: string, connection?: string, populate_integration?: boolean, populate_asset_manager?: boolean, populate_advisory_group?: boolean, include_merged_setting?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDefaultDocumentImportSettings200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDefaultDocumentImportSettings(skip, limit, sort_field, sort_direction, integration, global_default_transaction_setting, asset_manager, connection, populate_integration, populate_asset_manager, populate_advisory_group, include_merged_setting, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultDocumentImportSettingApi.listDefaultDocumentImportSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing Default Document Import Setting document.
         * @param {string} default_document_import_setting_id 
         * @param {UpdateDefaultDocumentImportSettingRequest} [UpdateDefaultDocumentImportSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDefaultDocumentImportSetting(default_document_import_setting_id: string, UpdateDefaultDocumentImportSettingRequest?: UpdateDefaultDocumentImportSettingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDefaultDocumentImportSetting(default_document_import_setting_id, UpdateDefaultDocumentImportSettingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultDocumentImportSettingApi.updateDefaultDocumentImportSetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultDocumentImportSettingApi - factory interface
 * @export
 */
export const DefaultDocumentImportSettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultDocumentImportSettingApiFp(configuration)
    return {
        /**
         * Create a new Default Document Import Setting.
         * @param {DefaultDocumentImportSettingApiCreateDefaultDocumentImportSettingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDefaultDocumentImportSetting(requestParameters: DefaultDocumentImportSettingApiCreateDefaultDocumentImportSettingRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CreateDefaultDocumentImportSetting201Response> {
            return localVarFp.createDefaultDocumentImportSetting(requestParameters.CreateDefaultDocumentImportSettingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Default Document Import Setting document.
         * @param {DefaultDocumentImportSettingApiDeleteDefaultDocumentImportSettingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDefaultDocumentImportSetting(requestParameters: DefaultDocumentImportSettingApiDeleteDefaultDocumentImportSettingRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteDefaultDocumentImportSetting(requestParameters.default_document_import_setting_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single Default Document Import Setting document by ID.
         * @param {DefaultDocumentImportSettingApiGetDefaultDocumentImportSettingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultDocumentImportSetting(requestParameters: DefaultDocumentImportSettingApiGetDefaultDocumentImportSettingRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetDefaultDocumentImportSetting200Response> {
            return localVarFp.getDefaultDocumentImportSetting(requestParameters.default_document_import_setting_id, requestParameters.populate_integration, requestParameters.populate_asset_manager, requestParameters.populate_advisory_group, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Default Document Import Setting documents.
         * @param {DefaultDocumentImportSettingApiListDefaultDocumentImportSettingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDefaultDocumentImportSettings(requestParameters: DefaultDocumentImportSettingApiListDefaultDocumentImportSettingsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListDefaultDocumentImportSettings200Response> {
            return localVarFp.listDefaultDocumentImportSettings(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.integration, requestParameters.global_default_transaction_setting, requestParameters.asset_manager, requestParameters.connection, requestParameters.populate_integration, requestParameters.populate_asset_manager, requestParameters.populate_advisory_group, requestParameters.include_merged_setting, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Default Document Import Setting document.
         * @param {DefaultDocumentImportSettingApiUpdateDefaultDocumentImportSettingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDefaultDocumentImportSetting(requestParameters: DefaultDocumentImportSettingApiUpdateDefaultDocumentImportSettingRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateDefaultDocumentImportSetting(requestParameters.default_document_import_setting_id, requestParameters.UpdateDefaultDocumentImportSettingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createDefaultDocumentImportSetting operation in DefaultDocumentImportSettingApi.
 * @export
 * @interface DefaultDocumentImportSettingApiCreateDefaultDocumentImportSettingRequest
 */
export interface DefaultDocumentImportSettingApiCreateDefaultDocumentImportSettingRequest {
    /**
     * 
     * @type {CreateDefaultDocumentImportSettingRequest}
     * @memberof DefaultDocumentImportSettingApiCreateDefaultDocumentImportSetting
     */
    readonly CreateDefaultDocumentImportSettingRequest?: CreateDefaultDocumentImportSettingRequest
}

/**
 * Request parameters for deleteDefaultDocumentImportSetting operation in DefaultDocumentImportSettingApi.
 * @export
 * @interface DefaultDocumentImportSettingApiDeleteDefaultDocumentImportSettingRequest
 */
export interface DefaultDocumentImportSettingApiDeleteDefaultDocumentImportSettingRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultDocumentImportSettingApiDeleteDefaultDocumentImportSetting
     */
    readonly default_document_import_setting_id: string
}

/**
 * Request parameters for getDefaultDocumentImportSetting operation in DefaultDocumentImportSettingApi.
 * @export
 * @interface DefaultDocumentImportSettingApiGetDefaultDocumentImportSettingRequest
 */
export interface DefaultDocumentImportSettingApiGetDefaultDocumentImportSettingRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultDocumentImportSettingApiGetDefaultDocumentImportSetting
     */
    readonly default_document_import_setting_id: string | null

    /**
     * 
     * @type {boolean}
     * @memberof DefaultDocumentImportSettingApiGetDefaultDocumentImportSetting
     */
    readonly populate_integration?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DefaultDocumentImportSettingApiGetDefaultDocumentImportSetting
     */
    readonly populate_asset_manager?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DefaultDocumentImportSettingApiGetDefaultDocumentImportSetting
     */
    readonly populate_advisory_group?: boolean
}

/**
 * Request parameters for listDefaultDocumentImportSettings operation in DefaultDocumentImportSettingApi.
 * @export
 * @interface DefaultDocumentImportSettingApiListDefaultDocumentImportSettingsRequest
 */
export interface DefaultDocumentImportSettingApiListDefaultDocumentImportSettingsRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'updated_at' | 'global_default_transaction_setting'}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly sort_field?: ListDefaultDocumentImportSettingsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly sort_direction?: ListDefaultDocumentImportSettingsSortDirectionEnum

    /**
     * 
     * @type {string}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly integration?: string

    /**
     * 
     * @type {'enabled' | 'disabled'}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly global_default_transaction_setting?: ListDefaultDocumentImportSettingsGlobalDefaultTransactionSettingEnum

    /**
     * 
     * @type {string}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly asset_manager?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly connection?: string

    /**
     * 
     * @type {boolean}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly populate_integration?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly populate_asset_manager?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly populate_advisory_group?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly include_merged_setting?: boolean
}

/**
 * Request parameters for updateDefaultDocumentImportSetting operation in DefaultDocumentImportSettingApi.
 * @export
 * @interface DefaultDocumentImportSettingApiUpdateDefaultDocumentImportSettingRequest
 */
export interface DefaultDocumentImportSettingApiUpdateDefaultDocumentImportSettingRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultDocumentImportSettingApiUpdateDefaultDocumentImportSetting
     */
    readonly default_document_import_setting_id: string

    /**
     * 
     * @type {UpdateDefaultDocumentImportSettingRequest}
     * @memberof DefaultDocumentImportSettingApiUpdateDefaultDocumentImportSetting
     */
    readonly UpdateDefaultDocumentImportSettingRequest?: UpdateDefaultDocumentImportSettingRequest
}

/**
 * DefaultDocumentImportSettingApi - object-oriented interface
 * @export
 * @class DefaultDocumentImportSettingApi
 * @extends {BaseAPI}
 */
export class DefaultDocumentImportSettingApi extends BaseAPI {
    /**
     * Create a new Default Document Import Setting.
     * @param {DefaultDocumentImportSettingApiCreateDefaultDocumentImportSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultDocumentImportSettingApi
     */
    public createDefaultDocumentImportSetting(requestParameters: DefaultDocumentImportSettingApiCreateDefaultDocumentImportSettingRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultDocumentImportSettingApiFp(this.configuration).createDefaultDocumentImportSetting(requestParameters.CreateDefaultDocumentImportSettingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Default Document Import Setting document.
     * @param {DefaultDocumentImportSettingApiDeleteDefaultDocumentImportSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultDocumentImportSettingApi
     */
    public deleteDefaultDocumentImportSetting(requestParameters: DefaultDocumentImportSettingApiDeleteDefaultDocumentImportSettingRequest, options?: RawAxiosRequestConfig) {
        return DefaultDocumentImportSettingApiFp(this.configuration).deleteDefaultDocumentImportSetting(requestParameters.default_document_import_setting_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single Default Document Import Setting document by ID.
     * @param {DefaultDocumentImportSettingApiGetDefaultDocumentImportSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultDocumentImportSettingApi
     */
    public getDefaultDocumentImportSetting(requestParameters: DefaultDocumentImportSettingApiGetDefaultDocumentImportSettingRequest, options?: RawAxiosRequestConfig) {
        return DefaultDocumentImportSettingApiFp(this.configuration).getDefaultDocumentImportSetting(requestParameters.default_document_import_setting_id, requestParameters.populate_integration, requestParameters.populate_asset_manager, requestParameters.populate_advisory_group, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Default Document Import Setting documents.
     * @param {DefaultDocumentImportSettingApiListDefaultDocumentImportSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultDocumentImportSettingApi
     */
    public listDefaultDocumentImportSettings(requestParameters: DefaultDocumentImportSettingApiListDefaultDocumentImportSettingsRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultDocumentImportSettingApiFp(this.configuration).listDefaultDocumentImportSettings(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.integration, requestParameters.global_default_transaction_setting, requestParameters.asset_manager, requestParameters.connection, requestParameters.populate_integration, requestParameters.populate_asset_manager, requestParameters.populate_advisory_group, requestParameters.include_merged_setting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Default Document Import Setting document.
     * @param {DefaultDocumentImportSettingApiUpdateDefaultDocumentImportSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultDocumentImportSettingApi
     */
    public updateDefaultDocumentImportSetting(requestParameters: DefaultDocumentImportSettingApiUpdateDefaultDocumentImportSettingRequest, options?: RawAxiosRequestConfig) {
        return DefaultDocumentImportSettingApiFp(this.configuration).updateDefaultDocumentImportSetting(requestParameters.default_document_import_setting_id, requestParameters.UpdateDefaultDocumentImportSettingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListDefaultDocumentImportSettingsSortFieldEnum = {
    CreatedAt: 'created_at',
    UpdatedAt: 'updated_at',
    GlobalDefaultTransactionSetting: 'global_default_transaction_setting'
} as const;
export type ListDefaultDocumentImportSettingsSortFieldEnum = typeof ListDefaultDocumentImportSettingsSortFieldEnum[keyof typeof ListDefaultDocumentImportSettingsSortFieldEnum];
/**
 * @export
 */
export const ListDefaultDocumentImportSettingsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListDefaultDocumentImportSettingsSortDirectionEnum = typeof ListDefaultDocumentImportSettingsSortDirectionEnum[keyof typeof ListDefaultDocumentImportSettingsSortDirectionEnum];
/**
 * @export
 */
export const ListDefaultDocumentImportSettingsGlobalDefaultTransactionSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled'
} as const;
export type ListDefaultDocumentImportSettingsGlobalDefaultTransactionSettingEnum = typeof ListDefaultDocumentImportSettingsGlobalDefaultTransactionSettingEnum[keyof typeof ListDefaultDocumentImportSettingsGlobalDefaultTransactionSettingEnum];


/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Document.
         * @param {CreateDocumentRequest} CreateDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocument: async (CreateDocumentRequest: CreateDocumentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateDocumentRequest' is not null or undefined
            assertParamExists('createDocument', 'CreateDocumentRequest', CreateDocumentRequest)
            const localVarPath = `/v2/documents/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Document.
         * @param {string} document_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument: async (document_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'document_id' is not null or undefined
            assertParamExists('deleteDocument', 'document_id', document_id)
            const localVarPath = `/v2/documents/{document_id}`
                .replace(`{${"document_id"}}`, encodeURIComponent(String(document_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single Document by ID.
         * @param {string | null} document_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_ownership] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument: async (document_id: string | null, populate_user?: boolean, populate_ownership?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'document_id' is not null or undefined
            assertParamExists('getDocument', 'document_id', document_id)
            const localVarPath = `/v2/documents/{document_id}`
                .replace(`{${"document_id"}}`, encodeURIComponent(String(document_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_ownership !== undefined) {
                localVarQueryParameter['populate_ownership'] = populate_ownership;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Document documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListDocumentsSortFieldEnum} [sort_field] 
         * @param {ListDocumentsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [ownership_ids] 
         * @param {ListDocumentsOwnershipTypeEnum} [ownership_type] 
         * @param {Array<string>} [investment_masters] 
         * @param {Array<string>} [advisory_groups] 
         * @param {boolean} [hidden] 
         * @param {boolean} [inactive] 
         * @param {Array<number>} [tax_year] 
         * @param {Array<string>} [accounts] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_ownership] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {boolean} [typeIsNull] 
         * @param {boolean} [hasValidMetadata] 
         * @param {boolean} [shouldGenerateTransactions] 
         * @param {boolean} [shouldProcessDocument] 
         * @param {boolean} [trainingSort] 
         * @param {boolean} [trainingExtraction] 
         * @param {boolean} [postJobReviewRequiredSort] 
         * @param {boolean} [postJobReviewRequiredExtraction] 
         * @param {Array<ListDocumentsTypesEnum>} [types] 
         * @param {Array<ListDocumentsFormTypesEnum>} [form_types] 
         * @param {string} [start_date] 
         * @param {string} [end_date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocuments: async (skip?: number, limit?: number, search?: string, sort_field?: ListDocumentsSortFieldEnum, sort_direction?: ListDocumentsSortDirectionEnum, users?: Array<string>, ownership_ids?: Array<string>, ownership_type?: ListDocumentsOwnershipTypeEnum, investment_masters?: Array<string>, advisory_groups?: Array<string>, hidden?: boolean, inactive?: boolean, tax_year?: Array<number>, accounts?: Array<string>, populate_user?: boolean, populate_ownership?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, typeIsNull?: boolean, hasValidMetadata?: boolean, shouldGenerateTransactions?: boolean, shouldProcessDocument?: boolean, trainingSort?: boolean, trainingExtraction?: boolean, postJobReviewRequiredSort?: boolean, postJobReviewRequiredExtraction?: boolean, types?: Array<ListDocumentsTypesEnum>, form_types?: Array<ListDocumentsFormTypesEnum>, start_date?: string, end_date?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/documents/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (ownership_ids) {
                localVarQueryParameter['ownership_ids'] = ownership_ids;
            }

            if (ownership_type !== undefined) {
                localVarQueryParameter['ownership_type'] = ownership_type;
            }

            if (investment_masters) {
                localVarQueryParameter['investment_masters'] = investment_masters;
            }

            if (advisory_groups) {
                localVarQueryParameter['advisory_groups'] = advisory_groups;
            }

            if (hidden !== undefined) {
                localVarQueryParameter['hidden'] = hidden;
            }

            if (inactive !== undefined) {
                localVarQueryParameter['inactive'] = inactive;
            }

            if (tax_year) {
                localVarQueryParameter['tax_year'] = tax_year;
            }

            if (accounts) {
                localVarQueryParameter['accounts'] = accounts;
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_ownership !== undefined) {
                localVarQueryParameter['populate_ownership'] = populate_ownership;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }

            if (typeIsNull !== undefined) {
                localVarQueryParameter['typeIsNull'] = typeIsNull;
            }

            if (hasValidMetadata !== undefined) {
                localVarQueryParameter['hasValidMetadata'] = hasValidMetadata;
            }

            if (shouldGenerateTransactions !== undefined) {
                localVarQueryParameter['shouldGenerateTransactions'] = shouldGenerateTransactions;
            }

            if (shouldProcessDocument !== undefined) {
                localVarQueryParameter['shouldProcessDocument'] = shouldProcessDocument;
            }

            if (trainingSort !== undefined) {
                localVarQueryParameter['trainingSort'] = trainingSort;
            }

            if (trainingExtraction !== undefined) {
                localVarQueryParameter['trainingExtraction'] = trainingExtraction;
            }

            if (postJobReviewRequiredSort !== undefined) {
                localVarQueryParameter['postJobReviewRequiredSort'] = postJobReviewRequiredSort;
            }

            if (postJobReviewRequiredExtraction !== undefined) {
                localVarQueryParameter['postJobReviewRequiredExtraction'] = postJobReviewRequiredExtraction;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (form_types) {
                localVarQueryParameter['form_types'] = form_types;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = (start_date as any instanceof Date) ?
                    (start_date as any).toISOString().substring(0,10) :
                    start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = (end_date as any instanceof Date) ?
                    (end_date as any).toISOString().substring(0,10) :
                    end_date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mark a Document as uploaded and process it accordingly.
         * @param {string} document_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markDocumentAsUploaded: async (document_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'document_id' is not null or undefined
            assertParamExists('markDocumentAsUploaded', 'document_id', document_id)
            const localVarPath = `/v2/documents/{document_id}/uploaded`
                .replace(`{${"document_id"}}`, encodeURIComponent(String(document_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reprocess metadata for a given document.
         * @param {string} document_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reprocessMetadata: async (document_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'document_id' is not null or undefined
            assertParamExists('reprocessMetadata', 'document_id', document_id)
            const localVarPath = `/v2/documents/{document_id}/reprocess_metadata`
                .replace(`{${"document_id"}}`, encodeURIComponent(String(document_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Document.
         * @param {string} document_id 
         * @param {UpdateDocumentRequest} [UpdateDocumentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocument: async (document_id: string, UpdateDocumentRequest?: UpdateDocumentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'document_id' is not null or undefined
            assertParamExists('updateDocument', 'document_id', document_id)
            const localVarPath = `/v2/documents/{document_id}`
                .replace(`{${"document_id"}}`, encodeURIComponent(String(document_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Document.
         * @param {CreateDocumentRequest} CreateDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDocument(CreateDocumentRequest: CreateDocumentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDocument201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDocument(CreateDocumentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.createDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a Document.
         * @param {string} document_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocument(document_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocument(document_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.deleteDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single Document by ID.
         * @param {string | null} document_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_ownership] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocument(document_id: string | null, populate_user?: boolean, populate_ownership?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocument200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument(document_id, populate_user, populate_ownership, populate_connection, populate_audit_log, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.getDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Document documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListDocumentsSortFieldEnum} [sort_field] 
         * @param {ListDocumentsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [ownership_ids] 
         * @param {ListDocumentsOwnershipTypeEnum} [ownership_type] 
         * @param {Array<string>} [investment_masters] 
         * @param {Array<string>} [advisory_groups] 
         * @param {boolean} [hidden] 
         * @param {boolean} [inactive] 
         * @param {Array<number>} [tax_year] 
         * @param {Array<string>} [accounts] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_ownership] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {boolean} [typeIsNull] 
         * @param {boolean} [hasValidMetadata] 
         * @param {boolean} [shouldGenerateTransactions] 
         * @param {boolean} [shouldProcessDocument] 
         * @param {boolean} [trainingSort] 
         * @param {boolean} [trainingExtraction] 
         * @param {boolean} [postJobReviewRequiredSort] 
         * @param {boolean} [postJobReviewRequiredExtraction] 
         * @param {Array<ListDocumentsTypesEnum>} [types] 
         * @param {Array<ListDocumentsFormTypesEnum>} [form_types] 
         * @param {string} [start_date] 
         * @param {string} [end_date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDocuments(skip?: number, limit?: number, search?: string, sort_field?: ListDocumentsSortFieldEnum, sort_direction?: ListDocumentsSortDirectionEnum, users?: Array<string>, ownership_ids?: Array<string>, ownership_type?: ListDocumentsOwnershipTypeEnum, investment_masters?: Array<string>, advisory_groups?: Array<string>, hidden?: boolean, inactive?: boolean, tax_year?: Array<number>, accounts?: Array<string>, populate_user?: boolean, populate_ownership?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, typeIsNull?: boolean, hasValidMetadata?: boolean, shouldGenerateTransactions?: boolean, shouldProcessDocument?: boolean, trainingSort?: boolean, trainingExtraction?: boolean, postJobReviewRequiredSort?: boolean, postJobReviewRequiredExtraction?: boolean, types?: Array<ListDocumentsTypesEnum>, form_types?: Array<ListDocumentsFormTypesEnum>, start_date?: string, end_date?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDocuments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDocuments(skip, limit, search, sort_field, sort_direction, users, ownership_ids, ownership_type, investment_masters, advisory_groups, hidden, inactive, tax_year, accounts, populate_user, populate_ownership, populate_connection, populate_audit_log, typeIsNull, hasValidMetadata, shouldGenerateTransactions, shouldProcessDocument, trainingSort, trainingExtraction, postJobReviewRequiredSort, postJobReviewRequiredExtraction, types, form_types, start_date, end_date, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.listDocuments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mark a Document as uploaded and process it accordingly.
         * @param {string} document_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markDocumentAsUploaded(document_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResolveAuditResult200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markDocumentAsUploaded(document_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.markDocumentAsUploaded']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Reprocess metadata for a given document.
         * @param {string} document_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reprocessMetadata(document_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReprocessMetadata200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reprocessMetadata(document_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.reprocessMetadata']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing Document.
         * @param {string} document_id 
         * @param {UpdateDocumentRequest} [UpdateDocumentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocument(document_id: string, UpdateDocumentRequest?: UpdateDocumentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDocument200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocument(document_id, UpdateDocumentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.updateDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentApiFp(configuration)
    return {
        /**
         * Create a new Document.
         * @param {DocumentApiCreateDocumentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocument(requestParameters: DocumentApiCreateDocumentRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateDocument201Response> {
            return localVarFp.createDocument(requestParameters.CreateDocumentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Document.
         * @param {DocumentApiDeleteDocumentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument(requestParameters: DocumentApiDeleteDocumentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteDocument(requestParameters.document_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single Document by ID.
         * @param {DocumentApiGetDocumentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument(requestParameters: DocumentApiGetDocumentRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetDocument200Response> {
            return localVarFp.getDocument(requestParameters.document_id, requestParameters.populate_user, requestParameters.populate_ownership, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Document documents.
         * @param {DocumentApiListDocumentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocuments(requestParameters: DocumentApiListDocumentsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListDocuments200Response> {
            return localVarFp.listDocuments(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.ownership_ids, requestParameters.ownership_type, requestParameters.investment_masters, requestParameters.advisory_groups, requestParameters.hidden, requestParameters.inactive, requestParameters.tax_year, requestParameters.accounts, requestParameters.populate_user, requestParameters.populate_ownership, requestParameters.populate_connection, requestParameters.populate_audit_log, requestParameters.typeIsNull, requestParameters.hasValidMetadata, requestParameters.shouldGenerateTransactions, requestParameters.shouldProcessDocument, requestParameters.trainingSort, requestParameters.trainingExtraction, requestParameters.postJobReviewRequiredSort, requestParameters.postJobReviewRequiredExtraction, requestParameters.types, requestParameters.form_types, requestParameters.start_date, requestParameters.end_date, options).then((request) => request(axios, basePath));
        },
        /**
         * Mark a Document as uploaded and process it accordingly.
         * @param {DocumentApiMarkDocumentAsUploadedRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markDocumentAsUploaded(requestParameters: DocumentApiMarkDocumentAsUploadedRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResolveAuditResult200Response> {
            return localVarFp.markDocumentAsUploaded(requestParameters.document_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Reprocess metadata for a given document.
         * @param {DocumentApiReprocessMetadataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reprocessMetadata(requestParameters: DocumentApiReprocessMetadataRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReprocessMetadata200Response> {
            return localVarFp.reprocessMetadata(requestParameters.document_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Document.
         * @param {DocumentApiUpdateDocumentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocument(requestParameters: DocumentApiUpdateDocumentRequest, options?: RawAxiosRequestConfig): AxiosPromise<UpdateDocument200Response> {
            return localVarFp.updateDocument(requestParameters.document_id, requestParameters.UpdateDocumentRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createDocument operation in DocumentApi.
 * @export
 * @interface DocumentApiCreateDocumentRequest
 */
export interface DocumentApiCreateDocumentRequest {
    /**
     * 
     * @type {CreateDocumentRequest}
     * @memberof DocumentApiCreateDocument
     */
    readonly CreateDocumentRequest: CreateDocumentRequest
}

/**
 * Request parameters for deleteDocument operation in DocumentApi.
 * @export
 * @interface DocumentApiDeleteDocumentRequest
 */
export interface DocumentApiDeleteDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof DocumentApiDeleteDocument
     */
    readonly document_id: string
}

/**
 * Request parameters for getDocument operation in DocumentApi.
 * @export
 * @interface DocumentApiGetDocumentRequest
 */
export interface DocumentApiGetDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof DocumentApiGetDocument
     */
    readonly document_id: string | null

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiGetDocument
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiGetDocument
     */
    readonly populate_ownership?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiGetDocument
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiGetDocument
     */
    readonly populate_audit_log?: boolean
}

/**
 * Request parameters for listDocuments operation in DocumentApi.
 * @export
 * @interface DocumentApiListDocumentsRequest
 */
export interface DocumentApiListDocumentsRequest {
    /**
     * 
     * @type {number}
     * @memberof DocumentApiListDocuments
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof DocumentApiListDocuments
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof DocumentApiListDocuments
     */
    readonly search?: string

    /**
     * 
     * @type {'date' | 'name' | 'type' | 'created_at' | 'updated_at' | 'ownership_type'}
     * @memberof DocumentApiListDocuments
     */
    readonly sort_field?: ListDocumentsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DocumentApiListDocuments
     */
    readonly sort_direction?: ListDocumentsSortDirectionEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentApiListDocuments
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentApiListDocuments
     */
    readonly ownership_ids?: Array<string>

    /**
     * 
     * @type {'Account' | 'Investment' | 'null'}
     * @memberof DocumentApiListDocuments
     */
    readonly ownership_type?: ListDocumentsOwnershipTypeEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentApiListDocuments
     */
    readonly investment_masters?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentApiListDocuments
     */
    readonly advisory_groups?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly hidden?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly inactive?: boolean

    /**
     * 
     * @type {Array<number>}
     * @memberof DocumentApiListDocuments
     */
    readonly tax_year?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentApiListDocuments
     */
    readonly accounts?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly populate_ownership?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly populate_audit_log?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly typeIsNull?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly hasValidMetadata?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly shouldGenerateTransactions?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly shouldProcessDocument?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly trainingSort?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly trainingExtraction?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly postJobReviewRequiredSort?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly postJobReviewRequiredExtraction?: boolean

    /**
     * 
     * @type {Array<'null' | 'Capital Account Statement' | 'Capital Call Notice' | 'Distribution Notice' | 'Tax Document' | 'Other' | 'Net Capital Call' | 'Net Distribution' | 'Hedge Fund' | 'PPM/OM' | 'Privacy Notice' | 'Disclosure / ADV' | 'Investment Update' | 'Legal Agreement' | 'Wire Instruction / Confirmation' | 'Reporting Authorization Letter'>}
     * @memberof DocumentApiListDocuments
     */
    readonly types?: Array<ListDocumentsTypesEnum>

    /**
     * 
     * @type {Array<'K-1' | 'Estimate' | '1099' | 'W-9' | 'Other'>}
     * @memberof DocumentApiListDocuments
     */
    readonly form_types?: Array<ListDocumentsFormTypesEnum>

    /**
     * 
     * @type {string}
     * @memberof DocumentApiListDocuments
     */
    readonly start_date?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentApiListDocuments
     */
    readonly end_date?: string
}

/**
 * Request parameters for markDocumentAsUploaded operation in DocumentApi.
 * @export
 * @interface DocumentApiMarkDocumentAsUploadedRequest
 */
export interface DocumentApiMarkDocumentAsUploadedRequest {
    /**
     * 
     * @type {string}
     * @memberof DocumentApiMarkDocumentAsUploaded
     */
    readonly document_id: string
}

/**
 * Request parameters for reprocessMetadata operation in DocumentApi.
 * @export
 * @interface DocumentApiReprocessMetadataRequest
 */
export interface DocumentApiReprocessMetadataRequest {
    /**
     * 
     * @type {string}
     * @memberof DocumentApiReprocessMetadata
     */
    readonly document_id: string
}

/**
 * Request parameters for updateDocument operation in DocumentApi.
 * @export
 * @interface DocumentApiUpdateDocumentRequest
 */
export interface DocumentApiUpdateDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof DocumentApiUpdateDocument
     */
    readonly document_id: string

    /**
     * 
     * @type {UpdateDocumentRequest}
     * @memberof DocumentApiUpdateDocument
     */
    readonly UpdateDocumentRequest?: UpdateDocumentRequest
}

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI {
    /**
     * Create a new Document.
     * @param {DocumentApiCreateDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public createDocument(requestParameters: DocumentApiCreateDocumentRequest, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).createDocument(requestParameters.CreateDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Document.
     * @param {DocumentApiDeleteDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public deleteDocument(requestParameters: DocumentApiDeleteDocumentRequest, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).deleteDocument(requestParameters.document_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single Document by ID.
     * @param {DocumentApiGetDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public getDocument(requestParameters: DocumentApiGetDocumentRequest, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).getDocument(requestParameters.document_id, requestParameters.populate_user, requestParameters.populate_ownership, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Document documents.
     * @param {DocumentApiListDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public listDocuments(requestParameters: DocumentApiListDocumentsRequest = {}, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).listDocuments(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.ownership_ids, requestParameters.ownership_type, requestParameters.investment_masters, requestParameters.advisory_groups, requestParameters.hidden, requestParameters.inactive, requestParameters.tax_year, requestParameters.accounts, requestParameters.populate_user, requestParameters.populate_ownership, requestParameters.populate_connection, requestParameters.populate_audit_log, requestParameters.typeIsNull, requestParameters.hasValidMetadata, requestParameters.shouldGenerateTransactions, requestParameters.shouldProcessDocument, requestParameters.trainingSort, requestParameters.trainingExtraction, requestParameters.postJobReviewRequiredSort, requestParameters.postJobReviewRequiredExtraction, requestParameters.types, requestParameters.form_types, requestParameters.start_date, requestParameters.end_date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mark a Document as uploaded and process it accordingly.
     * @param {DocumentApiMarkDocumentAsUploadedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public markDocumentAsUploaded(requestParameters: DocumentApiMarkDocumentAsUploadedRequest, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).markDocumentAsUploaded(requestParameters.document_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reprocess metadata for a given document.
     * @param {DocumentApiReprocessMetadataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public reprocessMetadata(requestParameters: DocumentApiReprocessMetadataRequest, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).reprocessMetadata(requestParameters.document_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Document.
     * @param {DocumentApiUpdateDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public updateDocument(requestParameters: DocumentApiUpdateDocumentRequest, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).updateDocument(requestParameters.document_id, requestParameters.UpdateDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListDocumentsSortFieldEnum = {
    Date: 'date',
    Name: 'name',
    Type: 'type',
    CreatedAt: 'created_at',
    UpdatedAt: 'updated_at',
    OwnershipType: 'ownership_type'
} as const;
export type ListDocumentsSortFieldEnum = typeof ListDocumentsSortFieldEnum[keyof typeof ListDocumentsSortFieldEnum];
/**
 * @export
 */
export const ListDocumentsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListDocumentsSortDirectionEnum = typeof ListDocumentsSortDirectionEnum[keyof typeof ListDocumentsSortDirectionEnum];
/**
 * @export
 */
export const ListDocumentsOwnershipTypeEnum = {
    Account: 'Account',
    Investment: 'Investment',
    Null: 'null'
} as const;
export type ListDocumentsOwnershipTypeEnum = typeof ListDocumentsOwnershipTypeEnum[keyof typeof ListDocumentsOwnershipTypeEnum];
/**
 * @export
 */
export const ListDocumentsTypesEnum = {
    Null: 'null',
    CapitalAccountStatement: 'Capital Account Statement',
    CapitalCallNotice: 'Capital Call Notice',
    DistributionNotice: 'Distribution Notice',
    TaxDocument: 'Tax Document',
    Other: 'Other',
    NetCapitalCall: 'Net Capital Call',
    NetDistribution: 'Net Distribution',
    HedgeFund: 'Hedge Fund',
    PpmOm: 'PPM/OM',
    PrivacyNotice: 'Privacy Notice',
    DisclosureAdv: 'Disclosure / ADV',
    InvestmentUpdate: 'Investment Update',
    LegalAgreement: 'Legal Agreement',
    WireInstructionConfirmation: 'Wire Instruction / Confirmation',
    ReportingAuthorizationLetter: 'Reporting Authorization Letter'
} as const;
export type ListDocumentsTypesEnum = typeof ListDocumentsTypesEnum[keyof typeof ListDocumentsTypesEnum];
/**
 * @export
 */
export const ListDocumentsFormTypesEnum = {
    K1: 'K-1',
    Estimate: 'Estimate',
    _1099: '1099',
    W9: 'W-9',
    Other: 'Other'
} as const;
export type ListDocumentsFormTypesEnum = typeof ListDocumentsFormTypesEnum[keyof typeof ListDocumentsFormTypesEnum];


/**
 * DocusignApi - axios parameter creator
 * @export
 */
export const DocusignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Receive webhook events from Docusign and update RAL Request status.
         * @param {ReceiveDocusignHookRequest} [ReceiveDocusignHookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveDocusignHook: async (ReceiveDocusignHookRequest?: ReceiveDocusignHookRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/ralrequests/docusign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ReceiveDocusignHookRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocusignApi - functional programming interface
 * @export
 */
export const DocusignApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocusignApiAxiosParamCreator(configuration)
    return {
        /**
         * Receive webhook events from Docusign and update RAL Request status.
         * @param {ReceiveDocusignHookRequest} [ReceiveDocusignHookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiveDocusignHook(ReceiveDocusignHookRequest?: ReceiveDocusignHookRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunSlaIssues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiveDocusignHook(ReceiveDocusignHookRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocusignApi.receiveDocusignHook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DocusignApi - factory interface
 * @export
 */
export const DocusignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocusignApiFp(configuration)
    return {
        /**
         * Receive webhook events from Docusign and update RAL Request status.
         * @param {DocusignApiReceiveDocusignHookRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveDocusignHook(requestParameters: DocusignApiReceiveDocusignHookRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RunSlaIssues200Response> {
            return localVarFp.receiveDocusignHook(requestParameters.ReceiveDocusignHookRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for receiveDocusignHook operation in DocusignApi.
 * @export
 * @interface DocusignApiReceiveDocusignHookRequest
 */
export interface DocusignApiReceiveDocusignHookRequest {
    /**
     * 
     * @type {ReceiveDocusignHookRequest}
     * @memberof DocusignApiReceiveDocusignHook
     */
    readonly ReceiveDocusignHookRequest?: ReceiveDocusignHookRequest
}

/**
 * DocusignApi - object-oriented interface
 * @export
 * @class DocusignApi
 * @extends {BaseAPI}
 */
export class DocusignApi extends BaseAPI {
    /**
     * Receive webhook events from Docusign and update RAL Request status.
     * @param {DocusignApiReceiveDocusignHookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocusignApi
     */
    public receiveDocusignHook(requestParameters: DocusignApiReceiveDocusignHookRequest = {}, options?: RawAxiosRequestConfig) {
        return DocusignApiFp(this.configuration).receiveDocusignHook(requestParameters.ReceiveDocusignHookRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ExchangeApi - axios parameter creator
 * @export
 */
export const ExchangeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Exchange document.
         * @param {CreateExchangeRequest} CreateExchangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExchange: async (CreateExchangeRequest: CreateExchangeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateExchangeRequest' is not null or undefined
            assertParamExists('createExchange', 'CreateExchangeRequest', CreateExchangeRequest)
            const localVarPath = `/v2/exchanges/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateExchangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an Exchange document.
         * @param {string} exchange_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExchange: async (exchange_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchange_id' is not null or undefined
            assertParamExists('deleteExchange', 'exchange_id', exchange_id)
            const localVarPath = `/v2/exchanges/{exchange_id}`
                .replace(`{${"exchange_id"}}`, encodeURIComponent(String(exchange_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an existing Exchange document by id.
         * @param {string | null} exchange_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchangeById: async (exchange_id: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchange_id' is not null or undefined
            assertParamExists('getExchangeById', 'exchange_id', exchange_id)
            const localVarPath = `/v2/exchanges/{exchange_id}`
                .replace(`{${"exchange_id"}}`, encodeURIComponent(String(exchange_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Exchange documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListExchangesSortFieldEnum} [sort_field] 
         * @param {ListExchangesSortDirectionEnum} [sort_direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExchanges: async (skip?: number, limit?: number, sort_field?: ListExchangesSortFieldEnum, sort_direction?: ListExchangesSortDirectionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/exchanges/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Exchange document.
         * @param {string} exchange_id 
         * @param {UpdateExchangeRequest} [UpdateExchangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExchange: async (exchange_id: string, UpdateExchangeRequest?: UpdateExchangeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchange_id' is not null or undefined
            assertParamExists('updateExchange', 'exchange_id', exchange_id)
            const localVarPath = `/v2/exchanges/{exchange_id}`
                .replace(`{${"exchange_id"}}`, encodeURIComponent(String(exchange_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateExchangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExchangeApi - functional programming interface
 * @export
 */
export const ExchangeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExchangeApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Exchange document.
         * @param {CreateExchangeRequest} CreateExchangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createExchange(CreateExchangeRequest: CreateExchangeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateExchange201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExchange(CreateExchangeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExchangeApi.createExchange']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an Exchange document.
         * @param {string} exchange_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteExchange(exchange_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExchange(exchange_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExchangeApi.deleteExchange']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get an existing Exchange document by id.
         * @param {string | null} exchange_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExchangeById(exchange_id: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateExchange201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExchangeById(exchange_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExchangeApi.getExchangeById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Exchange documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListExchangesSortFieldEnum} [sort_field] 
         * @param {ListExchangesSortDirectionEnum} [sort_direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listExchanges(skip?: number, limit?: number, sort_field?: ListExchangesSortFieldEnum, sort_direction?: ListExchangesSortDirectionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListExchanges200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listExchanges(skip, limit, sort_field, sort_direction, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExchangeApi.listExchanges']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing Exchange document.
         * @param {string} exchange_id 
         * @param {UpdateExchangeRequest} [UpdateExchangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExchange(exchange_id: string, UpdateExchangeRequest?: UpdateExchangeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExchange(exchange_id, UpdateExchangeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExchangeApi.updateExchange']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ExchangeApi - factory interface
 * @export
 */
export const ExchangeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExchangeApiFp(configuration)
    return {
        /**
         * Create a new Exchange document.
         * @param {ExchangeApiCreateExchangeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExchange(requestParameters: ExchangeApiCreateExchangeRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateExchange201Response> {
            return localVarFp.createExchange(requestParameters.CreateExchangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an Exchange document.
         * @param {ExchangeApiDeleteExchangeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExchange(requestParameters: ExchangeApiDeleteExchangeRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteExchange(requestParameters.exchange_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an existing Exchange document by id.
         * @param {ExchangeApiGetExchangeByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchangeById(requestParameters: ExchangeApiGetExchangeByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateExchange201Response> {
            return localVarFp.getExchangeById(requestParameters.exchange_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Exchange documents.
         * @param {ExchangeApiListExchangesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExchanges(requestParameters: ExchangeApiListExchangesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListExchanges200Response> {
            return localVarFp.listExchanges(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Exchange document.
         * @param {ExchangeApiUpdateExchangeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExchange(requestParameters: ExchangeApiUpdateExchangeRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateExchange(requestParameters.exchange_id, requestParameters.UpdateExchangeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createExchange operation in ExchangeApi.
 * @export
 * @interface ExchangeApiCreateExchangeRequest
 */
export interface ExchangeApiCreateExchangeRequest {
    /**
     * 
     * @type {CreateExchangeRequest}
     * @memberof ExchangeApiCreateExchange
     */
    readonly CreateExchangeRequest: CreateExchangeRequest
}

/**
 * Request parameters for deleteExchange operation in ExchangeApi.
 * @export
 * @interface ExchangeApiDeleteExchangeRequest
 */
export interface ExchangeApiDeleteExchangeRequest {
    /**
     * 
     * @type {string}
     * @memberof ExchangeApiDeleteExchange
     */
    readonly exchange_id: string
}

/**
 * Request parameters for getExchangeById operation in ExchangeApi.
 * @export
 * @interface ExchangeApiGetExchangeByIdRequest
 */
export interface ExchangeApiGetExchangeByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof ExchangeApiGetExchangeById
     */
    readonly exchange_id: string | null
}

/**
 * Request parameters for listExchanges operation in ExchangeApi.
 * @export
 * @interface ExchangeApiListExchangesRequest
 */
export interface ExchangeApiListExchangesRequest {
    /**
     * 
     * @type {number}
     * @memberof ExchangeApiListExchanges
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ExchangeApiListExchanges
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'name' | 'description' | 'ticker_prefix' | 'website' | 'location.city' | 'founded'}
     * @memberof ExchangeApiListExchanges
     */
    readonly sort_field?: ListExchangesSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ExchangeApiListExchanges
     */
    readonly sort_direction?: ListExchangesSortDirectionEnum
}

/**
 * Request parameters for updateExchange operation in ExchangeApi.
 * @export
 * @interface ExchangeApiUpdateExchangeRequest
 */
export interface ExchangeApiUpdateExchangeRequest {
    /**
     * 
     * @type {string}
     * @memberof ExchangeApiUpdateExchange
     */
    readonly exchange_id: string

    /**
     * 
     * @type {UpdateExchangeRequest}
     * @memberof ExchangeApiUpdateExchange
     */
    readonly UpdateExchangeRequest?: UpdateExchangeRequest
}

/**
 * ExchangeApi - object-oriented interface
 * @export
 * @class ExchangeApi
 * @extends {BaseAPI}
 */
export class ExchangeApi extends BaseAPI {
    /**
     * Create a new Exchange document.
     * @param {ExchangeApiCreateExchangeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeApi
     */
    public createExchange(requestParameters: ExchangeApiCreateExchangeRequest, options?: RawAxiosRequestConfig) {
        return ExchangeApiFp(this.configuration).createExchange(requestParameters.CreateExchangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an Exchange document.
     * @param {ExchangeApiDeleteExchangeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeApi
     */
    public deleteExchange(requestParameters: ExchangeApiDeleteExchangeRequest, options?: RawAxiosRequestConfig) {
        return ExchangeApiFp(this.configuration).deleteExchange(requestParameters.exchange_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an existing Exchange document by id.
     * @param {ExchangeApiGetExchangeByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeApi
     */
    public getExchangeById(requestParameters: ExchangeApiGetExchangeByIdRequest, options?: RawAxiosRequestConfig) {
        return ExchangeApiFp(this.configuration).getExchangeById(requestParameters.exchange_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Exchange documents.
     * @param {ExchangeApiListExchangesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeApi
     */
    public listExchanges(requestParameters: ExchangeApiListExchangesRequest = {}, options?: RawAxiosRequestConfig) {
        return ExchangeApiFp(this.configuration).listExchanges(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Exchange document.
     * @param {ExchangeApiUpdateExchangeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeApi
     */
    public updateExchange(requestParameters: ExchangeApiUpdateExchangeRequest, options?: RawAxiosRequestConfig) {
        return ExchangeApiFp(this.configuration).updateExchange(requestParameters.exchange_id, requestParameters.UpdateExchangeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListExchangesSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name',
    Description: 'description',
    TickerPrefix: 'ticker_prefix',
    Website: 'website',
    LocationCity: 'location.city',
    Founded: 'founded'
} as const;
export type ListExchangesSortFieldEnum = typeof ListExchangesSortFieldEnum[keyof typeof ListExchangesSortFieldEnum];
/**
 * @export
 */
export const ListExchangesSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListExchangesSortDirectionEnum = typeof ListExchangesSortDirectionEnum[keyof typeof ListExchangesSortDirectionEnum];


/**
 * ExtegrationApi - axios parameter creator
 * @export
 */
export const ExtegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get an advisory group from its extegration id
         * @param {string} extegration_id 
         * @param {string} [_public] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advisoryGroupFromExtIntegrationId: async (extegration_id: string, _public?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'extegration_id' is not null or undefined
            assertParamExists('advisoryGroupFromExtIntegrationId', 'extegration_id', extegration_id)
            const localVarPath = `/v2/extegrations/{extegration_id}/advisory_group`
                .replace(`{${"extegration_id"}}`, encodeURIComponent(String(extegration_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (_public !== undefined) {
                localVarQueryParameter['public'] = _public;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through the diff of documents for an extegration connection.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsDiff: async (skip?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/extegrations/get_documents_diff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication extegrationAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get extegration data.
         * @param {boolean} [include_connection_syncs_diff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExtIntegrationData: async (include_connection_syncs_diff?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/extegrations/get_data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication extegrationAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include_connection_syncs_diff !== undefined) {
                localVarQueryParameter['include_connection_syncs_diff'] = include_connection_syncs_diff;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through ExtIntegration documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListExtIntegrationsSortFieldEnum} [sort_field] 
         * @param {ListExtIntegrationsSortDirectionEnum} [sort_direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExtIntegrations: async (skip?: number, limit?: number, search?: string, sort_field?: ListExtIntegrationsSortFieldEnum, sort_direction?: ListExtIntegrationsSortDirectionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/extegrations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the secrets of an extegration.
         * @param {UpdateExtegrationSecretsRequest} UpdateExtegrationSecretsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExtegrationSecrets: async (UpdateExtegrationSecretsRequest: UpdateExtegrationSecretsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'UpdateExtegrationSecretsRequest' is not null or undefined
            assertParamExists('updateExtegrationSecrets', 'UpdateExtegrationSecretsRequest', UpdateExtegrationSecretsRequest)
            const localVarPath = `/v2/extegrations/secrets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication extegrationAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateExtegrationSecretsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the external id for an extegration.
         * @param {UpdateExternalIdRequest} UpdateExternalIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExternalId: async (UpdateExternalIdRequest: UpdateExternalIdRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'UpdateExternalIdRequest' is not null or undefined
            assertParamExists('updateExternalId', 'UpdateExternalIdRequest', UpdateExternalIdRequest)
            const localVarPath = `/v2/extegrations/external_id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication extegrationAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateExternalIdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExtegrationApi - functional programming interface
 * @export
 */
export const ExtegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExtegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * Get an advisory group from its extegration id
         * @param {string} extegration_id 
         * @param {string} [_public] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advisoryGroupFromExtIntegrationId(extegration_id: string, _public?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvisoryGroupFromExtIntegrationId200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advisoryGroupFromExtIntegrationId(extegration_id, _public, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExtegrationApi.advisoryGroupFromExtIntegrationId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through the diff of documents for an extegration connection.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentsDiff(skip?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentsDiff200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentsDiff(skip, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExtegrationApi.getDocumentsDiff']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get extegration data.
         * @param {boolean} [include_connection_syncs_diff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExtIntegrationData(include_connection_syncs_diff?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetExtIntegrationData200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExtIntegrationData(include_connection_syncs_diff, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExtegrationApi.getExtIntegrationData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through ExtIntegration documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListExtIntegrationsSortFieldEnum} [sort_field] 
         * @param {ListExtIntegrationsSortDirectionEnum} [sort_direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listExtIntegrations(skip?: number, limit?: number, search?: string, sort_field?: ListExtIntegrationsSortFieldEnum, sort_direction?: ListExtIntegrationsSortDirectionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListExtIntegrations200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listExtIntegrations(skip, limit, search, sort_field, sort_direction, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExtegrationApi.listExtIntegrations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update the secrets of an extegration.
         * @param {UpdateExtegrationSecretsRequest} UpdateExtegrationSecretsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExtegrationSecrets(UpdateExtegrationSecretsRequest: UpdateExtegrationSecretsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExtegrationSecrets(UpdateExtegrationSecretsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExtegrationApi.updateExtegrationSecrets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update the external id for an extegration.
         * @param {UpdateExternalIdRequest} UpdateExternalIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExternalId(UpdateExternalIdRequest: UpdateExternalIdRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExternalId(UpdateExternalIdRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExtegrationApi.updateExternalId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ExtegrationApi - factory interface
 * @export
 */
export const ExtegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExtegrationApiFp(configuration)
    return {
        /**
         * Get an advisory group from its extegration id
         * @param {ExtegrationApiAdvisoryGroupFromExtIntegrationIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advisoryGroupFromExtIntegrationId(requestParameters: ExtegrationApiAdvisoryGroupFromExtIntegrationIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdvisoryGroupFromExtIntegrationId200Response> {
            return localVarFp.advisoryGroupFromExtIntegrationId(requestParameters.extegration_id, requestParameters._public, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through the diff of documents for an extegration connection.
         * @param {ExtegrationApiGetDocumentsDiffRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsDiff(requestParameters: ExtegrationApiGetDocumentsDiffRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetDocumentsDiff200Response> {
            return localVarFp.getDocumentsDiff(requestParameters.skip, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get extegration data.
         * @param {ExtegrationApiGetExtIntegrationDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExtIntegrationData(requestParameters: ExtegrationApiGetExtIntegrationDataRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetExtIntegrationData200Response> {
            return localVarFp.getExtIntegrationData(requestParameters.include_connection_syncs_diff, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through ExtIntegration documents.
         * @param {ExtegrationApiListExtIntegrationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExtIntegrations(requestParameters: ExtegrationApiListExtIntegrationsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListExtIntegrations200Response> {
            return localVarFp.listExtIntegrations(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the secrets of an extegration.
         * @param {ExtegrationApiUpdateExtegrationSecretsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExtegrationSecrets(requestParameters: ExtegrationApiUpdateExtegrationSecretsRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateExtegrationSecrets(requestParameters.UpdateExtegrationSecretsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the external id for an extegration.
         * @param {ExtegrationApiUpdateExternalIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExternalId(requestParameters: ExtegrationApiUpdateExternalIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateExternalId(requestParameters.UpdateExternalIdRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for advisoryGroupFromExtIntegrationId operation in ExtegrationApi.
 * @export
 * @interface ExtegrationApiAdvisoryGroupFromExtIntegrationIdRequest
 */
export interface ExtegrationApiAdvisoryGroupFromExtIntegrationIdRequest {
    /**
     * 
     * @type {string}
     * @memberof ExtegrationApiAdvisoryGroupFromExtIntegrationId
     */
    readonly extegration_id: string

    /**
     * 
     * @type {string}
     * @memberof ExtegrationApiAdvisoryGroupFromExtIntegrationId
     */
    readonly _public?: string
}

/**
 * Request parameters for getDocumentsDiff operation in ExtegrationApi.
 * @export
 * @interface ExtegrationApiGetDocumentsDiffRequest
 */
export interface ExtegrationApiGetDocumentsDiffRequest {
    /**
     * 
     * @type {number}
     * @memberof ExtegrationApiGetDocumentsDiff
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ExtegrationApiGetDocumentsDiff
     */
    readonly limit?: number
}

/**
 * Request parameters for getExtIntegrationData operation in ExtegrationApi.
 * @export
 * @interface ExtegrationApiGetExtIntegrationDataRequest
 */
export interface ExtegrationApiGetExtIntegrationDataRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ExtegrationApiGetExtIntegrationData
     */
    readonly include_connection_syncs_diff?: boolean
}

/**
 * Request parameters for listExtIntegrations operation in ExtegrationApi.
 * @export
 * @interface ExtegrationApiListExtIntegrationsRequest
 */
export interface ExtegrationApiListExtIntegrationsRequest {
    /**
     * 
     * @type {number}
     * @memberof ExtegrationApiListExtIntegrations
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ExtegrationApiListExtIntegrations
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ExtegrationApiListExtIntegrations
     */
    readonly search?: string

    /**
     * 
     * @type {'created_at' | 'name'}
     * @memberof ExtegrationApiListExtIntegrations
     */
    readonly sort_field?: ListExtIntegrationsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ExtegrationApiListExtIntegrations
     */
    readonly sort_direction?: ListExtIntegrationsSortDirectionEnum
}

/**
 * Request parameters for updateExtegrationSecrets operation in ExtegrationApi.
 * @export
 * @interface ExtegrationApiUpdateExtegrationSecretsRequest
 */
export interface ExtegrationApiUpdateExtegrationSecretsRequest {
    /**
     * 
     * @type {UpdateExtegrationSecretsRequest}
     * @memberof ExtegrationApiUpdateExtegrationSecrets
     */
    readonly UpdateExtegrationSecretsRequest: UpdateExtegrationSecretsRequest
}

/**
 * Request parameters for updateExternalId operation in ExtegrationApi.
 * @export
 * @interface ExtegrationApiUpdateExternalIdRequest
 */
export interface ExtegrationApiUpdateExternalIdRequest {
    /**
     * 
     * @type {UpdateExternalIdRequest}
     * @memberof ExtegrationApiUpdateExternalId
     */
    readonly UpdateExternalIdRequest: UpdateExternalIdRequest
}

/**
 * ExtegrationApi - object-oriented interface
 * @export
 * @class ExtegrationApi
 * @extends {BaseAPI}
 */
export class ExtegrationApi extends BaseAPI {
    /**
     * Get an advisory group from its extegration id
     * @param {ExtegrationApiAdvisoryGroupFromExtIntegrationIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtegrationApi
     */
    public advisoryGroupFromExtIntegrationId(requestParameters: ExtegrationApiAdvisoryGroupFromExtIntegrationIdRequest, options?: RawAxiosRequestConfig) {
        return ExtegrationApiFp(this.configuration).advisoryGroupFromExtIntegrationId(requestParameters.extegration_id, requestParameters._public, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through the diff of documents for an extegration connection.
     * @param {ExtegrationApiGetDocumentsDiffRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtegrationApi
     */
    public getDocumentsDiff(requestParameters: ExtegrationApiGetDocumentsDiffRequest = {}, options?: RawAxiosRequestConfig) {
        return ExtegrationApiFp(this.configuration).getDocumentsDiff(requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get extegration data.
     * @param {ExtegrationApiGetExtIntegrationDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtegrationApi
     */
    public getExtIntegrationData(requestParameters: ExtegrationApiGetExtIntegrationDataRequest = {}, options?: RawAxiosRequestConfig) {
        return ExtegrationApiFp(this.configuration).getExtIntegrationData(requestParameters.include_connection_syncs_diff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through ExtIntegration documents.
     * @param {ExtegrationApiListExtIntegrationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtegrationApi
     */
    public listExtIntegrations(requestParameters: ExtegrationApiListExtIntegrationsRequest = {}, options?: RawAxiosRequestConfig) {
        return ExtegrationApiFp(this.configuration).listExtIntegrations(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the secrets of an extegration.
     * @param {ExtegrationApiUpdateExtegrationSecretsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtegrationApi
     */
    public updateExtegrationSecrets(requestParameters: ExtegrationApiUpdateExtegrationSecretsRequest, options?: RawAxiosRequestConfig) {
        return ExtegrationApiFp(this.configuration).updateExtegrationSecrets(requestParameters.UpdateExtegrationSecretsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the external id for an extegration.
     * @param {ExtegrationApiUpdateExternalIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtegrationApi
     */
    public updateExternalId(requestParameters: ExtegrationApiUpdateExternalIdRequest, options?: RawAxiosRequestConfig) {
        return ExtegrationApiFp(this.configuration).updateExternalId(requestParameters.UpdateExternalIdRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListExtIntegrationsSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name'
} as const;
export type ListExtIntegrationsSortFieldEnum = typeof ListExtIntegrationsSortFieldEnum[keyof typeof ListExtIntegrationsSortFieldEnum];
/**
 * @export
 */
export const ListExtIntegrationsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListExtIntegrationsSortDirectionEnum = typeof ListExtIntegrationsSortDirectionEnum[keyof typeof ListExtIntegrationsSortDirectionEnum];


/**
 * ExtegrationConnectionApi - axios parameter creator
 * @export
 */
export const ExtegrationConnectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Complete the Addepar OAuth process for an extegration.
         * @param {string} advisorygroup_id 
         * @param {string | null} extegration_id 
         * @param {FinishOAuthRequest} FinishOAuthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addeparOAuthComplete: async (advisorygroup_id: string, extegration_id: string | null, FinishOAuthRequest: FinishOAuthRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advisorygroup_id' is not null or undefined
            assertParamExists('addeparOAuthComplete', 'advisorygroup_id', advisorygroup_id)
            // verify required parameter 'extegration_id' is not null or undefined
            assertParamExists('addeparOAuthComplete', 'extegration_id', extegration_id)
            // verify required parameter 'FinishOAuthRequest' is not null or undefined
            assertParamExists('addeparOAuthComplete', 'FinishOAuthRequest', FinishOAuthRequest)
            const localVarPath = `/v2/advisorygroups/{advisorygroup_id}/extegrationconnections/{extegration_id}/addepar_oauth_complete`
                .replace(`{${"advisorygroup_id"}}`, encodeURIComponent(String(advisorygroup_id)))
                .replace(`{${"extegration_id"}}`, encodeURIComponent(String(extegration_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(FinishOAuthRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an Extegration on an Advisory Group document.
         * @param {string} advisorygroup_id 
         * @param {CreateExtegrationConnectionRequest} [CreateExtegrationConnectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExtegrationConnection: async (advisorygroup_id: string, CreateExtegrationConnectionRequest?: CreateExtegrationConnectionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advisorygroup_id' is not null or undefined
            assertParamExists('createExtegrationConnection', 'advisorygroup_id', advisorygroup_id)
            const localVarPath = `/v2/advisorygroups/{advisorygroup_id}/extegrationconnections/`
                .replace(`{${"advisorygroup_id"}}`, encodeURIComponent(String(advisorygroup_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateExtegrationConnectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an Extegration on an Advisory Group document.
         * @param {string} extegration_id 
         * @param {string} advisorygroup_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExtegrationConnection: async (extegration_id: string, advisorygroup_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'extegration_id' is not null or undefined
            assertParamExists('deleteExtegrationConnection', 'extegration_id', extegration_id)
            // verify required parameter 'advisorygroup_id' is not null or undefined
            assertParamExists('deleteExtegrationConnection', 'advisorygroup_id', advisorygroup_id)
            const localVarPath = `/v2/advisorygroups/{advisorygroup_id}/extegrationconnections/{extegration_id}`
                .replace(`{${"extegration_id"}}`, encodeURIComponent(String(extegration_id)))
                .replace(`{${"advisorygroup_id"}}`, encodeURIComponent(String(advisorygroup_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a specific extegration connection for an advisory group.
         * @param {string} extegration_id 
         * @param {string} advisorygroup_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExtegrationConnection: async (extegration_id: string, advisorygroup_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'extegration_id' is not null or undefined
            assertParamExists('getExtegrationConnection', 'extegration_id', extegration_id)
            // verify required parameter 'advisorygroup_id' is not null or undefined
            assertParamExists('getExtegrationConnection', 'advisorygroup_id', advisorygroup_id)
            const localVarPath = `/v2/advisorygroups/{advisorygroup_id}/extegrationconnections/{extegration_id}`
                .replace(`{${"extegration_id"}}`, encodeURIComponent(String(extegration_id)))
                .replace(`{${"advisorygroup_id"}}`, encodeURIComponent(String(advisorygroup_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch the extegration connections for an advisory group.
         * @param {string} advisorygroup_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExtegrationConnections: async (advisorygroup_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advisorygroup_id' is not null or undefined
            assertParamExists('listExtegrationConnections', 'advisorygroup_id', advisorygroup_id)
            const localVarPath = `/v2/advisorygroups/{advisorygroup_id}/extegrationconnections/`
                .replace(`{${"advisorygroup_id"}}`, encodeURIComponent(String(advisorygroup_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an extegration document.
         * @param {string} advisorygroup_id 
         * @param {string} extegration_id 
         * @param {UpdateExtegrationConnectionRequest} [UpdateExtegrationConnectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExtegrationConnection: async (advisorygroup_id: string, extegration_id: string, UpdateExtegrationConnectionRequest?: UpdateExtegrationConnectionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advisorygroup_id' is not null or undefined
            assertParamExists('updateExtegrationConnection', 'advisorygroup_id', advisorygroup_id)
            // verify required parameter 'extegration_id' is not null or undefined
            assertParamExists('updateExtegrationConnection', 'extegration_id', extegration_id)
            const localVarPath = `/v2/advisorygroups/{advisorygroup_id}/extegrationconnections/{extegration_id}`
                .replace(`{${"advisorygroup_id"}}`, encodeURIComponent(String(advisorygroup_id)))
                .replace(`{${"extegration_id"}}`, encodeURIComponent(String(extegration_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateExtegrationConnectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExtegrationConnectionApi - functional programming interface
 * @export
 */
export const ExtegrationConnectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExtegrationConnectionApiAxiosParamCreator(configuration)
    return {
        /**
         * Complete the Addepar OAuth process for an extegration.
         * @param {string} advisorygroup_id 
         * @param {string | null} extegration_id 
         * @param {FinishOAuthRequest} FinishOAuthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addeparOAuthComplete(advisorygroup_id: string, extegration_id: string | null, FinishOAuthRequest: FinishOAuthRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addeparOAuthComplete(advisorygroup_id, extegration_id, FinishOAuthRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExtegrationConnectionApi.addeparOAuthComplete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create an Extegration on an Advisory Group document.
         * @param {string} advisorygroup_id 
         * @param {CreateExtegrationConnectionRequest} [CreateExtegrationConnectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createExtegrationConnection(advisorygroup_id: string, CreateExtegrationConnectionRequest?: CreateExtegrationConnectionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateExtegrationConnection201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExtegrationConnection(advisorygroup_id, CreateExtegrationConnectionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExtegrationConnectionApi.createExtegrationConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an Extegration on an Advisory Group document.
         * @param {string} extegration_id 
         * @param {string} advisorygroup_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteExtegrationConnection(extegration_id: string, advisorygroup_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExtegrationConnection(extegration_id, advisorygroup_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExtegrationConnectionApi.deleteExtegrationConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetch a specific extegration connection for an advisory group.
         * @param {string} extegration_id 
         * @param {string} advisorygroup_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExtegrationConnection(extegration_id: string, advisorygroup_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetExtegrationConnection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExtegrationConnection(extegration_id, advisorygroup_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExtegrationConnectionApi.getExtegrationConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetch the extegration connections for an advisory group.
         * @param {string} advisorygroup_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listExtegrationConnections(advisorygroup_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListExtegrationConnections200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listExtegrationConnections(advisorygroup_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExtegrationConnectionApi.listExtegrationConnections']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an extegration document.
         * @param {string} advisorygroup_id 
         * @param {string} extegration_id 
         * @param {UpdateExtegrationConnectionRequest} [UpdateExtegrationConnectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExtegrationConnection(advisorygroup_id: string, extegration_id: string, UpdateExtegrationConnectionRequest?: UpdateExtegrationConnectionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExtegrationConnection(advisorygroup_id, extegration_id, UpdateExtegrationConnectionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExtegrationConnectionApi.updateExtegrationConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ExtegrationConnectionApi - factory interface
 * @export
 */
export const ExtegrationConnectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExtegrationConnectionApiFp(configuration)
    return {
        /**
         * Complete the Addepar OAuth process for an extegration.
         * @param {ExtegrationConnectionApiAddeparOAuthCompleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addeparOAuthComplete(requestParameters: ExtegrationConnectionApiAddeparOAuthCompleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.addeparOAuthComplete(requestParameters.advisorygroup_id, requestParameters.extegration_id, requestParameters.FinishOAuthRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create an Extegration on an Advisory Group document.
         * @param {ExtegrationConnectionApiCreateExtegrationConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExtegrationConnection(requestParameters: ExtegrationConnectionApiCreateExtegrationConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateExtegrationConnection201Response> {
            return localVarFp.createExtegrationConnection(requestParameters.advisorygroup_id, requestParameters.CreateExtegrationConnectionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an Extegration on an Advisory Group document.
         * @param {ExtegrationConnectionApiDeleteExtegrationConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExtegrationConnection(requestParameters: ExtegrationConnectionApiDeleteExtegrationConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteExtegrationConnection(requestParameters.extegration_id, requestParameters.advisorygroup_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a specific extegration connection for an advisory group.
         * @param {ExtegrationConnectionApiGetExtegrationConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExtegrationConnection(requestParameters: ExtegrationConnectionApiGetExtegrationConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetExtegrationConnection200Response> {
            return localVarFp.getExtegrationConnection(requestParameters.extegration_id, requestParameters.advisorygroup_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch the extegration connections for an advisory group.
         * @param {ExtegrationConnectionApiListExtegrationConnectionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExtegrationConnections(requestParameters: ExtegrationConnectionApiListExtegrationConnectionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListExtegrationConnections200Response> {
            return localVarFp.listExtegrationConnections(requestParameters.advisorygroup_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an extegration document.
         * @param {ExtegrationConnectionApiUpdateExtegrationConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExtegrationConnection(requestParameters: ExtegrationConnectionApiUpdateExtegrationConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateExtegrationConnection(requestParameters.advisorygroup_id, requestParameters.extegration_id, requestParameters.UpdateExtegrationConnectionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addeparOAuthComplete operation in ExtegrationConnectionApi.
 * @export
 * @interface ExtegrationConnectionApiAddeparOAuthCompleteRequest
 */
export interface ExtegrationConnectionApiAddeparOAuthCompleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ExtegrationConnectionApiAddeparOAuthComplete
     */
    readonly advisorygroup_id: string

    /**
     * 
     * @type {string}
     * @memberof ExtegrationConnectionApiAddeparOAuthComplete
     */
    readonly extegration_id: string | null

    /**
     * 
     * @type {FinishOAuthRequest}
     * @memberof ExtegrationConnectionApiAddeparOAuthComplete
     */
    readonly FinishOAuthRequest: FinishOAuthRequest
}

/**
 * Request parameters for createExtegrationConnection operation in ExtegrationConnectionApi.
 * @export
 * @interface ExtegrationConnectionApiCreateExtegrationConnectionRequest
 */
export interface ExtegrationConnectionApiCreateExtegrationConnectionRequest {
    /**
     * 
     * @type {string}
     * @memberof ExtegrationConnectionApiCreateExtegrationConnection
     */
    readonly advisorygroup_id: string

    /**
     * 
     * @type {CreateExtegrationConnectionRequest}
     * @memberof ExtegrationConnectionApiCreateExtegrationConnection
     */
    readonly CreateExtegrationConnectionRequest?: CreateExtegrationConnectionRequest
}

/**
 * Request parameters for deleteExtegrationConnection operation in ExtegrationConnectionApi.
 * @export
 * @interface ExtegrationConnectionApiDeleteExtegrationConnectionRequest
 */
export interface ExtegrationConnectionApiDeleteExtegrationConnectionRequest {
    /**
     * 
     * @type {string}
     * @memberof ExtegrationConnectionApiDeleteExtegrationConnection
     */
    readonly extegration_id: string

    /**
     * 
     * @type {string}
     * @memberof ExtegrationConnectionApiDeleteExtegrationConnection
     */
    readonly advisorygroup_id: string
}

/**
 * Request parameters for getExtegrationConnection operation in ExtegrationConnectionApi.
 * @export
 * @interface ExtegrationConnectionApiGetExtegrationConnectionRequest
 */
export interface ExtegrationConnectionApiGetExtegrationConnectionRequest {
    /**
     * 
     * @type {string}
     * @memberof ExtegrationConnectionApiGetExtegrationConnection
     */
    readonly extegration_id: string

    /**
     * 
     * @type {string}
     * @memberof ExtegrationConnectionApiGetExtegrationConnection
     */
    readonly advisorygroup_id: string
}

/**
 * Request parameters for listExtegrationConnections operation in ExtegrationConnectionApi.
 * @export
 * @interface ExtegrationConnectionApiListExtegrationConnectionsRequest
 */
export interface ExtegrationConnectionApiListExtegrationConnectionsRequest {
    /**
     * 
     * @type {string}
     * @memberof ExtegrationConnectionApiListExtegrationConnections
     */
    readonly advisorygroup_id: string
}

/**
 * Request parameters for updateExtegrationConnection operation in ExtegrationConnectionApi.
 * @export
 * @interface ExtegrationConnectionApiUpdateExtegrationConnectionRequest
 */
export interface ExtegrationConnectionApiUpdateExtegrationConnectionRequest {
    /**
     * 
     * @type {string}
     * @memberof ExtegrationConnectionApiUpdateExtegrationConnection
     */
    readonly advisorygroup_id: string

    /**
     * 
     * @type {string}
     * @memberof ExtegrationConnectionApiUpdateExtegrationConnection
     */
    readonly extegration_id: string

    /**
     * 
     * @type {UpdateExtegrationConnectionRequest}
     * @memberof ExtegrationConnectionApiUpdateExtegrationConnection
     */
    readonly UpdateExtegrationConnectionRequest?: UpdateExtegrationConnectionRequest
}

/**
 * ExtegrationConnectionApi - object-oriented interface
 * @export
 * @class ExtegrationConnectionApi
 * @extends {BaseAPI}
 */
export class ExtegrationConnectionApi extends BaseAPI {
    /**
     * Complete the Addepar OAuth process for an extegration.
     * @param {ExtegrationConnectionApiAddeparOAuthCompleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtegrationConnectionApi
     */
    public addeparOAuthComplete(requestParameters: ExtegrationConnectionApiAddeparOAuthCompleteRequest, options?: RawAxiosRequestConfig) {
        return ExtegrationConnectionApiFp(this.configuration).addeparOAuthComplete(requestParameters.advisorygroup_id, requestParameters.extegration_id, requestParameters.FinishOAuthRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an Extegration on an Advisory Group document.
     * @param {ExtegrationConnectionApiCreateExtegrationConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtegrationConnectionApi
     */
    public createExtegrationConnection(requestParameters: ExtegrationConnectionApiCreateExtegrationConnectionRequest, options?: RawAxiosRequestConfig) {
        return ExtegrationConnectionApiFp(this.configuration).createExtegrationConnection(requestParameters.advisorygroup_id, requestParameters.CreateExtegrationConnectionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an Extegration on an Advisory Group document.
     * @param {ExtegrationConnectionApiDeleteExtegrationConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtegrationConnectionApi
     */
    public deleteExtegrationConnection(requestParameters: ExtegrationConnectionApiDeleteExtegrationConnectionRequest, options?: RawAxiosRequestConfig) {
        return ExtegrationConnectionApiFp(this.configuration).deleteExtegrationConnection(requestParameters.extegration_id, requestParameters.advisorygroup_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch a specific extegration connection for an advisory group.
     * @param {ExtegrationConnectionApiGetExtegrationConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtegrationConnectionApi
     */
    public getExtegrationConnection(requestParameters: ExtegrationConnectionApiGetExtegrationConnectionRequest, options?: RawAxiosRequestConfig) {
        return ExtegrationConnectionApiFp(this.configuration).getExtegrationConnection(requestParameters.extegration_id, requestParameters.advisorygroup_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch the extegration connections for an advisory group.
     * @param {ExtegrationConnectionApiListExtegrationConnectionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtegrationConnectionApi
     */
    public listExtegrationConnections(requestParameters: ExtegrationConnectionApiListExtegrationConnectionsRequest, options?: RawAxiosRequestConfig) {
        return ExtegrationConnectionApiFp(this.configuration).listExtegrationConnections(requestParameters.advisorygroup_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an extegration document.
     * @param {ExtegrationConnectionApiUpdateExtegrationConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtegrationConnectionApi
     */
    public updateExtegrationConnection(requestParameters: ExtegrationConnectionApiUpdateExtegrationConnectionRequest, options?: RawAxiosRequestConfig) {
        return ExtegrationConnectionApiFp(this.configuration).updateExtegrationConnection(requestParameters.advisorygroup_id, requestParameters.extegration_id, requestParameters.UpdateExtegrationConnectionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ExtegrationSyncApi - axios parameter creator
 * @export
 */
export const ExtegrationSyncApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add attachments to an extegration sync.
         * @param {string | null} id 
         * @param {string} advisorygroup_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAttachements: async (id: string | null, advisorygroup_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addAttachements', 'id', id)
            // verify required parameter 'advisorygroup_id' is not null or undefined
            assertParamExists('addAttachements', 'advisorygroup_id', advisorygroup_id)
            const localVarPath = `/v2/advisorygroups/{advisorygroup_id}/extegrationsyncs/{id}/attachments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"advisorygroup_id"}}`, encodeURIComponent(String(advisorygroup_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication extegrationAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Complete an extegration sync.
         * @param {string} id 
         * @param {string} advisorygroup_id 
         * @param {CompleteExtegrationSyncRequest} CompleteExtegrationSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeExtegrationSync: async (id: string, advisorygroup_id: string, CompleteExtegrationSyncRequest: CompleteExtegrationSyncRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('completeExtegrationSync', 'id', id)
            // verify required parameter 'advisorygroup_id' is not null or undefined
            assertParamExists('completeExtegrationSync', 'advisorygroup_id', advisorygroup_id)
            // verify required parameter 'CompleteExtegrationSyncRequest' is not null or undefined
            assertParamExists('completeExtegrationSync', 'CompleteExtegrationSyncRequest', CompleteExtegrationSyncRequest)
            const localVarPath = `/v2/advisorygroups/{advisorygroup_id}/extegrationsyncs/{id}/complete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"advisorygroup_id"}}`, encodeURIComponent(String(advisorygroup_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication extegrationAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CompleteExtegrationSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an extegration sync by its ID
         * @param {string} advisorygroup_id 
         * @param {string | null} extegrationsync_id 
         * @param {boolean} [populate_initiator] 
         * @param {boolean} [populate_entities] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvisoryGroupExtegrationSyncById: async (advisorygroup_id: string, extegrationsync_id: string | null, populate_initiator?: boolean, populate_entities?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advisorygroup_id' is not null or undefined
            assertParamExists('getAdvisoryGroupExtegrationSyncById', 'advisorygroup_id', advisorygroup_id)
            // verify required parameter 'extegrationsync_id' is not null or undefined
            assertParamExists('getAdvisoryGroupExtegrationSyncById', 'extegrationsync_id', extegrationsync_id)
            const localVarPath = `/v2/advisorygroups/{advisorygroup_id}/extegrationsyncs/{extegrationsync_id}`
                .replace(`{${"advisorygroup_id"}}`, encodeURIComponent(String(advisorygroup_id)))
                .replace(`{${"extegrationsync_id"}}`, encodeURIComponent(String(extegrationsync_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_initiator !== undefined) {
                localVarQueryParameter['populate_initiator'] = populate_initiator;
            }

            if (populate_entities !== undefined) {
                localVarQueryParameter['populate_entities'] = populate_entities;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List extegration syncs for an advisory group
         * @param {string} advisorygroup_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [populate_initiator] 
         * @param {boolean} [populate_entities] 
         * @param {ListAdvisoryGroupExtegrationSyncsSortFieldEnum} [sort_field] 
         * @param {ListAdvisoryGroupExtegrationSyncsSortDirectionEnum} [sort_direction] 
         * @param {string | null} [group] 
         * @param {Array<string>} [extegration_ids] 
         * @param {ListAdvisoryGroupExtegrationSyncsExitStatusEnum} [exit_status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdvisoryGroupExtegrationSyncs: async (advisorygroup_id: string, skip?: number, limit?: number, populate_initiator?: boolean, populate_entities?: boolean, sort_field?: ListAdvisoryGroupExtegrationSyncsSortFieldEnum, sort_direction?: ListAdvisoryGroupExtegrationSyncsSortDirectionEnum, group?: string | null, extegration_ids?: Array<string>, exit_status?: ListAdvisoryGroupExtegrationSyncsExitStatusEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advisorygroup_id' is not null or undefined
            assertParamExists('listAdvisoryGroupExtegrationSyncs', 'advisorygroup_id', advisorygroup_id)
            const localVarPath = `/v2/advisorygroups/{advisorygroup_id}/extegrationsyncs/`
                .replace(`{${"advisorygroup_id"}}`, encodeURIComponent(String(advisorygroup_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (populate_initiator !== undefined) {
                localVarQueryParameter['populate_initiator'] = populate_initiator;
            }

            if (populate_entities !== undefined) {
                localVarQueryParameter['populate_entities'] = populate_entities;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (group !== undefined) {
                localVarQueryParameter['group'] = group;
            }

            if (extegration_ids) {
                localVarQueryParameter['extegration_ids'] = extegration_ids;
            }

            if (exit_status !== undefined) {
                localVarQueryParameter['exit_status'] = exit_status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExtegrationSyncApi - functional programming interface
 * @export
 */
export const ExtegrationSyncApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExtegrationSyncApiAxiosParamCreator(configuration)
    return {
        /**
         * Add attachments to an extegration sync.
         * @param {string | null} id 
         * @param {string} advisorygroup_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAttachements(id: string | null, advisorygroup_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAttachements(id, advisorygroup_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExtegrationSyncApi.addAttachements']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Complete an extegration sync.
         * @param {string} id 
         * @param {string} advisorygroup_id 
         * @param {CompleteExtegrationSyncRequest} CompleteExtegrationSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeExtegrationSync(id: string, advisorygroup_id: string, CompleteExtegrationSyncRequest: CompleteExtegrationSyncRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeExtegrationSync(id, advisorygroup_id, CompleteExtegrationSyncRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExtegrationSyncApi.completeExtegrationSync']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get an extegration sync by its ID
         * @param {string} advisorygroup_id 
         * @param {string | null} extegrationsync_id 
         * @param {boolean} [populate_initiator] 
         * @param {boolean} [populate_entities] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvisoryGroupExtegrationSyncById(advisorygroup_id: string, extegrationsync_id: string | null, populate_initiator?: boolean, populate_entities?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdvisoryGroupExtegrationSyncById200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvisoryGroupExtegrationSyncById(advisorygroup_id, extegrationsync_id, populate_initiator, populate_entities, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExtegrationSyncApi.getAdvisoryGroupExtegrationSyncById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List extegration syncs for an advisory group
         * @param {string} advisorygroup_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [populate_initiator] 
         * @param {boolean} [populate_entities] 
         * @param {ListAdvisoryGroupExtegrationSyncsSortFieldEnum} [sort_field] 
         * @param {ListAdvisoryGroupExtegrationSyncsSortDirectionEnum} [sort_direction] 
         * @param {string | null} [group] 
         * @param {Array<string>} [extegration_ids] 
         * @param {ListAdvisoryGroupExtegrationSyncsExitStatusEnum} [exit_status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAdvisoryGroupExtegrationSyncs(advisorygroup_id: string, skip?: number, limit?: number, populate_initiator?: boolean, populate_entities?: boolean, sort_field?: ListAdvisoryGroupExtegrationSyncsSortFieldEnum, sort_direction?: ListAdvisoryGroupExtegrationSyncsSortDirectionEnum, group?: string | null, extegration_ids?: Array<string>, exit_status?: ListAdvisoryGroupExtegrationSyncsExitStatusEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAdvisoryGroupExtegrationSyncs200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAdvisoryGroupExtegrationSyncs(advisorygroup_id, skip, limit, populate_initiator, populate_entities, sort_field, sort_direction, group, extegration_ids, exit_status, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExtegrationSyncApi.listAdvisoryGroupExtegrationSyncs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ExtegrationSyncApi - factory interface
 * @export
 */
export const ExtegrationSyncApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExtegrationSyncApiFp(configuration)
    return {
        /**
         * Add attachments to an extegration sync.
         * @param {ExtegrationSyncApiAddAttachementsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAttachements(requestParameters: ExtegrationSyncApiAddAttachementsRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.addAttachements(requestParameters.id, requestParameters.advisorygroup_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Complete an extegration sync.
         * @param {ExtegrationSyncApiCompleteExtegrationSyncRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeExtegrationSync(requestParameters: ExtegrationSyncApiCompleteExtegrationSyncRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.completeExtegrationSync(requestParameters.id, requestParameters.advisorygroup_id, requestParameters.CompleteExtegrationSyncRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an extegration sync by its ID
         * @param {ExtegrationSyncApiGetAdvisoryGroupExtegrationSyncByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvisoryGroupExtegrationSyncById(requestParameters: ExtegrationSyncApiGetAdvisoryGroupExtegrationSyncByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetAdvisoryGroupExtegrationSyncById200Response> {
            return localVarFp.getAdvisoryGroupExtegrationSyncById(requestParameters.advisorygroup_id, requestParameters.extegrationsync_id, requestParameters.populate_initiator, requestParameters.populate_entities, options).then((request) => request(axios, basePath));
        },
        /**
         * List extegration syncs for an advisory group
         * @param {ExtegrationSyncApiListAdvisoryGroupExtegrationSyncsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdvisoryGroupExtegrationSyncs(requestParameters: ExtegrationSyncApiListAdvisoryGroupExtegrationSyncsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListAdvisoryGroupExtegrationSyncs200Response> {
            return localVarFp.listAdvisoryGroupExtegrationSyncs(requestParameters.advisorygroup_id, requestParameters.skip, requestParameters.limit, requestParameters.populate_initiator, requestParameters.populate_entities, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.group, requestParameters.extegration_ids, requestParameters.exit_status, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addAttachements operation in ExtegrationSyncApi.
 * @export
 * @interface ExtegrationSyncApiAddAttachementsRequest
 */
export interface ExtegrationSyncApiAddAttachementsRequest {
    /**
     * 
     * @type {string}
     * @memberof ExtegrationSyncApiAddAttachements
     */
    readonly id: string | null

    /**
     * 
     * @type {string}
     * @memberof ExtegrationSyncApiAddAttachements
     */
    readonly advisorygroup_id: string
}

/**
 * Request parameters for completeExtegrationSync operation in ExtegrationSyncApi.
 * @export
 * @interface ExtegrationSyncApiCompleteExtegrationSyncRequest
 */
export interface ExtegrationSyncApiCompleteExtegrationSyncRequest {
    /**
     * 
     * @type {string}
     * @memberof ExtegrationSyncApiCompleteExtegrationSync
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ExtegrationSyncApiCompleteExtegrationSync
     */
    readonly advisorygroup_id: string

    /**
     * 
     * @type {CompleteExtegrationSyncRequest}
     * @memberof ExtegrationSyncApiCompleteExtegrationSync
     */
    readonly CompleteExtegrationSyncRequest: CompleteExtegrationSyncRequest
}

/**
 * Request parameters for getAdvisoryGroupExtegrationSyncById operation in ExtegrationSyncApi.
 * @export
 * @interface ExtegrationSyncApiGetAdvisoryGroupExtegrationSyncByIdRequest
 */
export interface ExtegrationSyncApiGetAdvisoryGroupExtegrationSyncByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof ExtegrationSyncApiGetAdvisoryGroupExtegrationSyncById
     */
    readonly advisorygroup_id: string

    /**
     * 
     * @type {string}
     * @memberof ExtegrationSyncApiGetAdvisoryGroupExtegrationSyncById
     */
    readonly extegrationsync_id: string | null

    /**
     * 
     * @type {boolean}
     * @memberof ExtegrationSyncApiGetAdvisoryGroupExtegrationSyncById
     */
    readonly populate_initiator?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ExtegrationSyncApiGetAdvisoryGroupExtegrationSyncById
     */
    readonly populate_entities?: boolean
}

/**
 * Request parameters for listAdvisoryGroupExtegrationSyncs operation in ExtegrationSyncApi.
 * @export
 * @interface ExtegrationSyncApiListAdvisoryGroupExtegrationSyncsRequest
 */
export interface ExtegrationSyncApiListAdvisoryGroupExtegrationSyncsRequest {
    /**
     * 
     * @type {string}
     * @memberof ExtegrationSyncApiListAdvisoryGroupExtegrationSyncs
     */
    readonly advisorygroup_id: string

    /**
     * 
     * @type {number}
     * @memberof ExtegrationSyncApiListAdvisoryGroupExtegrationSyncs
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ExtegrationSyncApiListAdvisoryGroupExtegrationSyncs
     */
    readonly limit?: number

    /**
     * 
     * @type {boolean}
     * @memberof ExtegrationSyncApiListAdvisoryGroupExtegrationSyncs
     */
    readonly populate_initiator?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ExtegrationSyncApiListAdvisoryGroupExtegrationSyncs
     */
    readonly populate_entities?: boolean

    /**
     * 
     * @type {'_id' | 'created_at' | 'updated_at' | 'started_at' | 'completed_at' | 'exit_status'}
     * @memberof ExtegrationSyncApiListAdvisoryGroupExtegrationSyncs
     */
    readonly sort_field?: ListAdvisoryGroupExtegrationSyncsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ExtegrationSyncApiListAdvisoryGroupExtegrationSyncs
     */
    readonly sort_direction?: ListAdvisoryGroupExtegrationSyncsSortDirectionEnum

    /**
     * 
     * @type {string}
     * @memberof ExtegrationSyncApiListAdvisoryGroupExtegrationSyncs
     */
    readonly group?: string | null

    /**
     * 
     * @type {Array<string>}
     * @memberof ExtegrationSyncApiListAdvisoryGroupExtegrationSyncs
     */
    readonly extegration_ids?: Array<string>

    /**
     * 
     * @type {'success' | 'failure' | 'pending'}
     * @memberof ExtegrationSyncApiListAdvisoryGroupExtegrationSyncs
     */
    readonly exit_status?: ListAdvisoryGroupExtegrationSyncsExitStatusEnum
}

/**
 * ExtegrationSyncApi - object-oriented interface
 * @export
 * @class ExtegrationSyncApi
 * @extends {BaseAPI}
 */
export class ExtegrationSyncApi extends BaseAPI {
    /**
     * Add attachments to an extegration sync.
     * @param {ExtegrationSyncApiAddAttachementsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtegrationSyncApi
     */
    public addAttachements(requestParameters: ExtegrationSyncApiAddAttachementsRequest, options?: RawAxiosRequestConfig) {
        return ExtegrationSyncApiFp(this.configuration).addAttachements(requestParameters.id, requestParameters.advisorygroup_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Complete an extegration sync.
     * @param {ExtegrationSyncApiCompleteExtegrationSyncRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtegrationSyncApi
     */
    public completeExtegrationSync(requestParameters: ExtegrationSyncApiCompleteExtegrationSyncRequest, options?: RawAxiosRequestConfig) {
        return ExtegrationSyncApiFp(this.configuration).completeExtegrationSync(requestParameters.id, requestParameters.advisorygroup_id, requestParameters.CompleteExtegrationSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an extegration sync by its ID
     * @param {ExtegrationSyncApiGetAdvisoryGroupExtegrationSyncByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtegrationSyncApi
     */
    public getAdvisoryGroupExtegrationSyncById(requestParameters: ExtegrationSyncApiGetAdvisoryGroupExtegrationSyncByIdRequest, options?: RawAxiosRequestConfig) {
        return ExtegrationSyncApiFp(this.configuration).getAdvisoryGroupExtegrationSyncById(requestParameters.advisorygroup_id, requestParameters.extegrationsync_id, requestParameters.populate_initiator, requestParameters.populate_entities, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List extegration syncs for an advisory group
     * @param {ExtegrationSyncApiListAdvisoryGroupExtegrationSyncsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtegrationSyncApi
     */
    public listAdvisoryGroupExtegrationSyncs(requestParameters: ExtegrationSyncApiListAdvisoryGroupExtegrationSyncsRequest, options?: RawAxiosRequestConfig) {
        return ExtegrationSyncApiFp(this.configuration).listAdvisoryGroupExtegrationSyncs(requestParameters.advisorygroup_id, requestParameters.skip, requestParameters.limit, requestParameters.populate_initiator, requestParameters.populate_entities, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.group, requestParameters.extegration_ids, requestParameters.exit_status, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListAdvisoryGroupExtegrationSyncsSortFieldEnum = {
    Id: '_id',
    CreatedAt: 'created_at',
    UpdatedAt: 'updated_at',
    StartedAt: 'started_at',
    CompletedAt: 'completed_at',
    ExitStatus: 'exit_status'
} as const;
export type ListAdvisoryGroupExtegrationSyncsSortFieldEnum = typeof ListAdvisoryGroupExtegrationSyncsSortFieldEnum[keyof typeof ListAdvisoryGroupExtegrationSyncsSortFieldEnum];
/**
 * @export
 */
export const ListAdvisoryGroupExtegrationSyncsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListAdvisoryGroupExtegrationSyncsSortDirectionEnum = typeof ListAdvisoryGroupExtegrationSyncsSortDirectionEnum[keyof typeof ListAdvisoryGroupExtegrationSyncsSortDirectionEnum];
/**
 * @export
 */
export const ListAdvisoryGroupExtegrationSyncsExitStatusEnum = {
    Success: 'success',
    Failure: 'failure',
    Pending: 'pending'
} as const;
export type ListAdvisoryGroupExtegrationSyncsExitStatusEnum = typeof ListAdvisoryGroupExtegrationSyncsExitStatusEnum[keyof typeof ListAdvisoryGroupExtegrationSyncsExitStatusEnum];


/**
 * IntegrationApi - axios parameter creator
 * @export
 */
export const IntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Integration document.
         * @param {CreateIntegrationRequest} CreateIntegrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIntegration: async (CreateIntegrationRequest: CreateIntegrationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateIntegrationRequest' is not null or undefined
            assertParamExists('createIntegration', 'CreateIntegrationRequest', CreateIntegrationRequest)
            const localVarPath = `/v2/integrations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateIntegrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an Integration document.
         * @param {string} integration_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIntegration: async (integration_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integration_id' is not null or undefined
            assertParamExists('deleteIntegration', 'integration_id', integration_id)
            const localVarPath = `/v2/integrations/{integration_id}`
                .replace(`{${"integration_id"}}`, encodeURIComponent(String(integration_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single Integration document by ID.
         * @param {string | null} integration_id 
         * @param {boolean} [populate_connection_counts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegration: async (integration_id: string | null, populate_connection_counts?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integration_id' is not null or undefined
            assertParamExists('getIntegration', 'integration_id', integration_id)
            const localVarPath = `/v2/integrations/{integration_id}`
                .replace(`{${"integration_id"}}`, encodeURIComponent(String(integration_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_connection_counts !== undefined) {
                localVarQueryParameter['populate_connection_counts'] = populate_connection_counts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Integration documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListIntegrationsSortFieldEnum} [sort_field] 
         * @param {ListIntegrationsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [populate_connection_counts] 
         * @param {Array<string>} [integrations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIntegrations: async (skip?: number, limit?: number, search?: string, sort_field?: ListIntegrationsSortFieldEnum, sort_direction?: ListIntegrationsSortDirectionEnum, populate_connection_counts?: boolean, integrations?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/integrations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (populate_connection_counts !== undefined) {
                localVarQueryParameter['populate_connection_counts'] = populate_connection_counts;
            }

            if (integrations) {
                localVarQueryParameter['integrations'] = integrations;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Integration document.
         * @param {string} integration_id 
         * @param {UpdateIntegrationRequest} [UpdateIntegrationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIntegration: async (integration_id: string, UpdateIntegrationRequest?: UpdateIntegrationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integration_id' is not null or undefined
            assertParamExists('updateIntegration', 'integration_id', integration_id)
            const localVarPath = `/v2/integrations/{integration_id}`
                .replace(`{${"integration_id"}}`, encodeURIComponent(String(integration_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateIntegrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationApi - functional programming interface
 * @export
 */
export const IntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Integration document.
         * @param {CreateIntegrationRequest} CreateIntegrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIntegration(CreateIntegrationRequest: CreateIntegrationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateIntegration201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIntegration(CreateIntegrationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApi.createIntegration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an Integration document.
         * @param {string} integration_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIntegration(integration_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIntegration(integration_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApi.deleteIntegration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single Integration document by ID.
         * @param {string | null} integration_id 
         * @param {boolean} [populate_connection_counts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntegration(integration_id: string | null, populate_connection_counts?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetIntegration200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntegration(integration_id, populate_connection_counts, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApi.getIntegration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Integration documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListIntegrationsSortFieldEnum} [sort_field] 
         * @param {ListIntegrationsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [populate_connection_counts] 
         * @param {Array<string>} [integrations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listIntegrations(skip?: number, limit?: number, search?: string, sort_field?: ListIntegrationsSortFieldEnum, sort_direction?: ListIntegrationsSortDirectionEnum, populate_connection_counts?: boolean, integrations?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListIntegrations200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listIntegrations(skip, limit, search, sort_field, sort_direction, populate_connection_counts, integrations, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApi.listIntegrations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing Integration document.
         * @param {string} integration_id 
         * @param {UpdateIntegrationRequest} [UpdateIntegrationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIntegration(integration_id: string, UpdateIntegrationRequest?: UpdateIntegrationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIntegration(integration_id, UpdateIntegrationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApi.updateIntegration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * IntegrationApi - factory interface
 * @export
 */
export const IntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationApiFp(configuration)
    return {
        /**
         * Create a new Integration document.
         * @param {IntegrationApiCreateIntegrationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIntegration(requestParameters: IntegrationApiCreateIntegrationRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateIntegration201Response> {
            return localVarFp.createIntegration(requestParameters.CreateIntegrationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an Integration document.
         * @param {IntegrationApiDeleteIntegrationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIntegration(requestParameters: IntegrationApiDeleteIntegrationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteIntegration(requestParameters.integration_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single Integration document by ID.
         * @param {IntegrationApiGetIntegrationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegration(requestParameters: IntegrationApiGetIntegrationRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetIntegration200Response> {
            return localVarFp.getIntegration(requestParameters.integration_id, requestParameters.populate_connection_counts, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Integration documents.
         * @param {IntegrationApiListIntegrationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIntegrations(requestParameters: IntegrationApiListIntegrationsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListIntegrations200Response> {
            return localVarFp.listIntegrations(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.populate_connection_counts, requestParameters.integrations, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Integration document.
         * @param {IntegrationApiUpdateIntegrationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIntegration(requestParameters: IntegrationApiUpdateIntegrationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateIntegration(requestParameters.integration_id, requestParameters.UpdateIntegrationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createIntegration operation in IntegrationApi.
 * @export
 * @interface IntegrationApiCreateIntegrationRequest
 */
export interface IntegrationApiCreateIntegrationRequest {
    /**
     * 
     * @type {CreateIntegrationRequest}
     * @memberof IntegrationApiCreateIntegration
     */
    readonly CreateIntegrationRequest: CreateIntegrationRequest
}

/**
 * Request parameters for deleteIntegration operation in IntegrationApi.
 * @export
 * @interface IntegrationApiDeleteIntegrationRequest
 */
export interface IntegrationApiDeleteIntegrationRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiDeleteIntegration
     */
    readonly integration_id: string
}

/**
 * Request parameters for getIntegration operation in IntegrationApi.
 * @export
 * @interface IntegrationApiGetIntegrationRequest
 */
export interface IntegrationApiGetIntegrationRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiGetIntegration
     */
    readonly integration_id: string | null

    /**
     * 
     * @type {boolean}
     * @memberof IntegrationApiGetIntegration
     */
    readonly populate_connection_counts?: boolean
}

/**
 * Request parameters for listIntegrations operation in IntegrationApi.
 * @export
 * @interface IntegrationApiListIntegrationsRequest
 */
export interface IntegrationApiListIntegrationsRequest {
    /**
     * 
     * @type {number}
     * @memberof IntegrationApiListIntegrations
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof IntegrationApiListIntegrations
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof IntegrationApiListIntegrations
     */
    readonly search?: string

    /**
     * 
     * @type {'created_at' | 'name' | 'worker_version' | 'has_worker'}
     * @memberof IntegrationApiListIntegrations
     */
    readonly sort_field?: ListIntegrationsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof IntegrationApiListIntegrations
     */
    readonly sort_direction?: ListIntegrationsSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof IntegrationApiListIntegrations
     */
    readonly populate_connection_counts?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrationApiListIntegrations
     */
    readonly integrations?: Array<string>
}

/**
 * Request parameters for updateIntegration operation in IntegrationApi.
 * @export
 * @interface IntegrationApiUpdateIntegrationRequest
 */
export interface IntegrationApiUpdateIntegrationRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiUpdateIntegration
     */
    readonly integration_id: string

    /**
     * 
     * @type {UpdateIntegrationRequest}
     * @memberof IntegrationApiUpdateIntegration
     */
    readonly UpdateIntegrationRequest?: UpdateIntegrationRequest
}

/**
 * IntegrationApi - object-oriented interface
 * @export
 * @class IntegrationApi
 * @extends {BaseAPI}
 */
export class IntegrationApi extends BaseAPI {
    /**
     * Create a new Integration document.
     * @param {IntegrationApiCreateIntegrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public createIntegration(requestParameters: IntegrationApiCreateIntegrationRequest, options?: RawAxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).createIntegration(requestParameters.CreateIntegrationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an Integration document.
     * @param {IntegrationApiDeleteIntegrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public deleteIntegration(requestParameters: IntegrationApiDeleteIntegrationRequest, options?: RawAxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).deleteIntegration(requestParameters.integration_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single Integration document by ID.
     * @param {IntegrationApiGetIntegrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public getIntegration(requestParameters: IntegrationApiGetIntegrationRequest, options?: RawAxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).getIntegration(requestParameters.integration_id, requestParameters.populate_connection_counts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Integration documents.
     * @param {IntegrationApiListIntegrationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public listIntegrations(requestParameters: IntegrationApiListIntegrationsRequest = {}, options?: RawAxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).listIntegrations(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.populate_connection_counts, requestParameters.integrations, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Integration document.
     * @param {IntegrationApiUpdateIntegrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public updateIntegration(requestParameters: IntegrationApiUpdateIntegrationRequest, options?: RawAxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).updateIntegration(requestParameters.integration_id, requestParameters.UpdateIntegrationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListIntegrationsSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name',
    WorkerVersion: 'worker_version',
    HasWorker: 'has_worker'
} as const;
export type ListIntegrationsSortFieldEnum = typeof ListIntegrationsSortFieldEnum[keyof typeof ListIntegrationsSortFieldEnum];
/**
 * @export
 */
export const ListIntegrationsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListIntegrationsSortDirectionEnum = typeof ListIntegrationsSortDirectionEnum[keyof typeof ListIntegrationsSortDirectionEnum];


/**
 * InvestmentApi - axios parameter creator
 * @export
 */
export const InvestmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Investment document.
         * @param {CreateInvestmentRequest} CreateInvestmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvestment: async (CreateInvestmentRequest: CreateInvestmentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateInvestmentRequest' is not null or undefined
            assertParamExists('createInvestment', 'CreateInvestmentRequest', CreateInvestmentRequest)
            const localVarPath = `/v2/investments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateInvestmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an Investment document.
         * @param {string} investment_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvestment: async (investment_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investment_id' is not null or undefined
            assertParamExists('deleteInvestment', 'investment_id', investment_id)
            const localVarPath = `/v2/investments/{investment_id}`
                .replace(`{${"investment_id"}}`, encodeURIComponent(String(investment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single Investment document by ID.
         * @param {string | null} investment_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_audit_log] 
         * @param {boolean} [populate_investment_master] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_integration_logo] 
         * @param {boolean} [populate_recent_valuation] 
         * @param {boolean} [populate_recent_transaction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestment: async (investment_id: string | null, populate_user?: boolean, populate_account?: boolean, populate_audit_log?: boolean, populate_investment_master?: boolean, populate_connection?: boolean, populate_integration_logo?: boolean, populate_recent_valuation?: boolean, populate_recent_transaction?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investment_id' is not null or undefined
            assertParamExists('getInvestment', 'investment_id', investment_id)
            const localVarPath = `/v2/investments/{investment_id}`
                .replace(`{${"investment_id"}}`, encodeURIComponent(String(investment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }

            if (populate_investment_master !== undefined) {
                localVarQueryParameter['populate_investment_master'] = populate_investment_master;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (populate_integration_logo !== undefined) {
                localVarQueryParameter['populate_integration_logo'] = populate_integration_logo;
            }

            if (populate_recent_valuation !== undefined) {
                localVarQueryParameter['populate_recent_valuation'] = populate_recent_valuation;
            }

            if (populate_recent_transaction !== undefined) {
                localVarQueryParameter['populate_recent_transaction'] = populate_recent_transaction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve JSON metadata information about historical statements for a given Investment.
         * @param {string} investment_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatementHistory: async (investment_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investment_id' is not null or undefined
            assertParamExists('getStatementHistory', 'investment_id', investment_id)
            const localVarPath = `/v2/investments/{investment_id}/get_statement_history`
                .replace(`{${"investment_id"}}`, encodeURIComponent(String(investment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the history of transactions and valuations for a given investment ID.
         * @param {string} investment_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListInvestmentHistorySortFieldEnum} [sort_field] 
         * @param {ListInvestmentHistorySortDirectionEnum} [sort_direction] 
         * @param {Array<ListInvestmentHistoryTypesEnum>} [types] 
         * @param {boolean} [populate_documents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvestmentHistory: async (investment_id: string, skip?: number, limit?: number, sort_field?: ListInvestmentHistorySortFieldEnum, sort_direction?: ListInvestmentHistorySortDirectionEnum, types?: Array<ListInvestmentHistoryTypesEnum>, populate_documents?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investment_id' is not null or undefined
            assertParamExists('listInvestmentHistory', 'investment_id', investment_id)
            const localVarPath = `/v2/investments/{investment_id}/history`
                .replace(`{${"investment_id"}}`, encodeURIComponent(String(investment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (populate_documents !== undefined) {
                localVarQueryParameter['populate_documents'] = populate_documents;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Investment documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListInvestmentsSortFieldEnum} [sort_field] 
         * @param {ListInvestmentsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [connections] 
         * @param {Array<string>} [investments] 
         * @param {ListInvestmentsInvestmentMastersParameter} [investment_masters] 
         * @param {Array<string>} [advisory_groups] 
         * @param {boolean} [hidden] 
         * @param {boolean} [incomplete] 
         * @param {boolean} [inactive] 
         * @param {boolean} [exclude_from_billing] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_advisory_groups] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_audit_log] 
         * @param {boolean} [populate_audit_results] 
         * @param {boolean} [populate_investment_master] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_integration_logo] 
         * @param {boolean} [populate_recent_valuation] 
         * @param {boolean} [populate_recent_transaction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvestments: async (skip?: number, limit?: number, search?: string, sort_field?: ListInvestmentsSortFieldEnum, sort_direction?: ListInvestmentsSortDirectionEnum, users?: Array<string>, accounts?: Array<string>, connections?: Array<string>, investments?: Array<string>, investment_masters?: ListInvestmentsInvestmentMastersParameter, advisory_groups?: Array<string>, hidden?: boolean, incomplete?: boolean, inactive?: boolean, exclude_from_billing?: boolean, populate_user?: boolean, populate_advisory_groups?: boolean, populate_account?: boolean, populate_audit_log?: boolean, populate_audit_results?: boolean, populate_investment_master?: boolean, populate_connection?: boolean, populate_integration_logo?: boolean, populate_recent_valuation?: boolean, populate_recent_transaction?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/investments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (accounts) {
                localVarQueryParameter['accounts'] = accounts;
            }

            if (connections) {
                localVarQueryParameter['connections'] = connections;
            }

            if (investments) {
                localVarQueryParameter['investments'] = investments;
            }

            if (investment_masters !== undefined) {
                for (const [key, value] of Object.entries(investment_masters)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (advisory_groups) {
                localVarQueryParameter['advisory_groups'] = advisory_groups;
            }

            if (hidden !== undefined) {
                localVarQueryParameter['hidden'] = hidden;
            }

            if (incomplete !== undefined) {
                localVarQueryParameter['incomplete'] = incomplete;
            }

            if (inactive !== undefined) {
                localVarQueryParameter['inactive'] = inactive;
            }

            if (exclude_from_billing !== undefined) {
                localVarQueryParameter['exclude_from_billing'] = exclude_from_billing;
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_advisory_groups !== undefined) {
                localVarQueryParameter['populate_advisory_groups'] = populate_advisory_groups;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }

            if (populate_audit_results !== undefined) {
                localVarQueryParameter['populate_audit_results'] = populate_audit_results;
            }

            if (populate_investment_master !== undefined) {
                localVarQueryParameter['populate_investment_master'] = populate_investment_master;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (populate_integration_logo !== undefined) {
                localVarQueryParameter['populate_integration_logo'] = populate_integration_logo;
            }

            if (populate_recent_valuation !== undefined) {
                localVarQueryParameter['populate_recent_valuation'] = populate_recent_valuation;
            }

            if (populate_recent_transaction !== undefined) {
                localVarQueryParameter['populate_recent_transaction'] = populate_recent_transaction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Investment documents grouped by Investment Master.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListInvestmentsByInvestmentMasterSortFieldEnum} [sort_field] 
         * @param {ListInvestmentsByInvestmentMasterSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [connections] 
         * @param {Array<string>} [investments] 
         * @param {Array<string>} [investment_masters] 
         * @param {Array<string>} [advisory_groups] 
         * @param {Array<string>} [types] 
         * @param {boolean} [no_investment_master] 
         * @param {boolean} [hidden] 
         * @param {boolean} [incomplete] 
         * @param {boolean} [inactive] 
         * @param {boolean} [exclude_from_billing] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvestmentsByInvestmentMaster: async (skip?: number, limit?: number, search?: string, sort_field?: ListInvestmentsByInvestmentMasterSortFieldEnum, sort_direction?: ListInvestmentsByInvestmentMasterSortDirectionEnum, users?: Array<string>, accounts?: Array<string>, connections?: Array<string>, investments?: Array<string>, investment_masters?: Array<string>, advisory_groups?: Array<string>, types?: Array<string>, no_investment_master?: boolean, hidden?: boolean, incomplete?: boolean, inactive?: boolean, exclude_from_billing?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/investments/list_by_investment_master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (accounts) {
                localVarQueryParameter['accounts'] = accounts;
            }

            if (connections) {
                localVarQueryParameter['connections'] = connections;
            }

            if (investments) {
                localVarQueryParameter['investments'] = investments;
            }

            if (investment_masters) {
                localVarQueryParameter['investment_masters'] = investment_masters;
            }

            if (advisory_groups) {
                localVarQueryParameter['advisory_groups'] = advisory_groups;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (no_investment_master !== undefined) {
                localVarQueryParameter['no_investment_master'] = no_investment_master;
            }

            if (hidden !== undefined) {
                localVarQueryParameter['hidden'] = hidden;
            }

            if (incomplete !== undefined) {
                localVarQueryParameter['incomplete'] = incomplete;
            }

            if (inactive !== undefined) {
                localVarQueryParameter['inactive'] = inactive;
            }

            if (exclude_from_billing !== undefined) {
                localVarQueryParameter['exclude_from_billing'] = exclude_from_billing;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Investment document.
         * @param {string} investment_id 
         * @param {UpdateInvestmentRequest} [UpdateInvestmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvestment: async (investment_id: string, UpdateInvestmentRequest?: UpdateInvestmentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investment_id' is not null or undefined
            assertParamExists('updateInvestment', 'investment_id', investment_id)
            const localVarPath = `/v2/investments/{investment_id}`
                .replace(`{${"investment_id"}}`, encodeURIComponent(String(investment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateInvestmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestmentApi - functional programming interface
 * @export
 */
export const InvestmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestmentApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Investment document.
         * @param {CreateInvestmentRequest} CreateInvestmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvestment(CreateInvestmentRequest: CreateInvestmentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateInvestment201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvestment(CreateInvestmentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentApi.createInvestment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an Investment document.
         * @param {string} investment_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInvestment(investment_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInvestment(investment_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentApi.deleteInvestment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single Investment document by ID.
         * @param {string | null} investment_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_audit_log] 
         * @param {boolean} [populate_investment_master] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_integration_logo] 
         * @param {boolean} [populate_recent_valuation] 
         * @param {boolean} [populate_recent_transaction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestment(investment_id: string | null, populate_user?: boolean, populate_account?: boolean, populate_audit_log?: boolean, populate_investment_master?: boolean, populate_connection?: boolean, populate_integration_logo?: boolean, populate_recent_valuation?: boolean, populate_recent_transaction?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInvestment200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestment(investment_id, populate_user, populate_account, populate_audit_log, populate_investment_master, populate_connection, populate_integration_logo, populate_recent_valuation, populate_recent_transaction, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentApi.getInvestment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve JSON metadata information about historical statements for a given Investment.
         * @param {string} investment_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatementHistory(investment_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStatementHistory200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatementHistory(investment_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentApi.getStatementHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the history of transactions and valuations for a given investment ID.
         * @param {string} investment_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListInvestmentHistorySortFieldEnum} [sort_field] 
         * @param {ListInvestmentHistorySortDirectionEnum} [sort_direction] 
         * @param {Array<ListInvestmentHistoryTypesEnum>} [types] 
         * @param {boolean} [populate_documents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInvestmentHistory(investment_id: string, skip?: number, limit?: number, sort_field?: ListInvestmentHistorySortFieldEnum, sort_direction?: ListInvestmentHistorySortDirectionEnum, types?: Array<ListInvestmentHistoryTypesEnum>, populate_documents?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListInvestmentHistory200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInvestmentHistory(investment_id, skip, limit, sort_field, sort_direction, types, populate_documents, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentApi.listInvestmentHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Investment documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListInvestmentsSortFieldEnum} [sort_field] 
         * @param {ListInvestmentsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [connections] 
         * @param {Array<string>} [investments] 
         * @param {ListInvestmentsInvestmentMastersParameter} [investment_masters] 
         * @param {Array<string>} [advisory_groups] 
         * @param {boolean} [hidden] 
         * @param {boolean} [incomplete] 
         * @param {boolean} [inactive] 
         * @param {boolean} [exclude_from_billing] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_advisory_groups] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_audit_log] 
         * @param {boolean} [populate_audit_results] 
         * @param {boolean} [populate_investment_master] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_integration_logo] 
         * @param {boolean} [populate_recent_valuation] 
         * @param {boolean} [populate_recent_transaction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInvestments(skip?: number, limit?: number, search?: string, sort_field?: ListInvestmentsSortFieldEnum, sort_direction?: ListInvestmentsSortDirectionEnum, users?: Array<string>, accounts?: Array<string>, connections?: Array<string>, investments?: Array<string>, investment_masters?: ListInvestmentsInvestmentMastersParameter, advisory_groups?: Array<string>, hidden?: boolean, incomplete?: boolean, inactive?: boolean, exclude_from_billing?: boolean, populate_user?: boolean, populate_advisory_groups?: boolean, populate_account?: boolean, populate_audit_log?: boolean, populate_audit_results?: boolean, populate_investment_master?: boolean, populate_connection?: boolean, populate_integration_logo?: boolean, populate_recent_valuation?: boolean, populate_recent_transaction?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListInvestments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInvestments(skip, limit, search, sort_field, sort_direction, users, accounts, connections, investments, investment_masters, advisory_groups, hidden, incomplete, inactive, exclude_from_billing, populate_user, populate_advisory_groups, populate_account, populate_audit_log, populate_audit_results, populate_investment_master, populate_connection, populate_integration_logo, populate_recent_valuation, populate_recent_transaction, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentApi.listInvestments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Investment documents grouped by Investment Master.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListInvestmentsByInvestmentMasterSortFieldEnum} [sort_field] 
         * @param {ListInvestmentsByInvestmentMasterSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [connections] 
         * @param {Array<string>} [investments] 
         * @param {Array<string>} [investment_masters] 
         * @param {Array<string>} [advisory_groups] 
         * @param {Array<string>} [types] 
         * @param {boolean} [no_investment_master] 
         * @param {boolean} [hidden] 
         * @param {boolean} [incomplete] 
         * @param {boolean} [inactive] 
         * @param {boolean} [exclude_from_billing] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInvestmentsByInvestmentMaster(skip?: number, limit?: number, search?: string, sort_field?: ListInvestmentsByInvestmentMasterSortFieldEnum, sort_direction?: ListInvestmentsByInvestmentMasterSortDirectionEnum, users?: Array<string>, accounts?: Array<string>, connections?: Array<string>, investments?: Array<string>, investment_masters?: Array<string>, advisory_groups?: Array<string>, types?: Array<string>, no_investment_master?: boolean, hidden?: boolean, incomplete?: boolean, inactive?: boolean, exclude_from_billing?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListInvestmentsByInvestmentMaster200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInvestmentsByInvestmentMaster(skip, limit, search, sort_field, sort_direction, users, accounts, connections, investments, investment_masters, advisory_groups, types, no_investment_master, hidden, incomplete, inactive, exclude_from_billing, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentApi.listInvestmentsByInvestmentMaster']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing Investment document.
         * @param {string} investment_id 
         * @param {UpdateInvestmentRequest} [UpdateInvestmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInvestment(investment_id: string, UpdateInvestmentRequest?: UpdateInvestmentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvestment(investment_id, UpdateInvestmentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentApi.updateInvestment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InvestmentApi - factory interface
 * @export
 */
export const InvestmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestmentApiFp(configuration)
    return {
        /**
         * Create a new Investment document.
         * @param {InvestmentApiCreateInvestmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvestment(requestParameters: InvestmentApiCreateInvestmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateInvestment201Response> {
            return localVarFp.createInvestment(requestParameters.CreateInvestmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an Investment document.
         * @param {InvestmentApiDeleteInvestmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvestment(requestParameters: InvestmentApiDeleteInvestmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteInvestment(requestParameters.investment_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single Investment document by ID.
         * @param {InvestmentApiGetInvestmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestment(requestParameters: InvestmentApiGetInvestmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetInvestment200Response> {
            return localVarFp.getInvestment(requestParameters.investment_id, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_audit_log, requestParameters.populate_investment_master, requestParameters.populate_connection, requestParameters.populate_integration_logo, requestParameters.populate_recent_valuation, requestParameters.populate_recent_transaction, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve JSON metadata information about historical statements for a given Investment.
         * @param {InvestmentApiGetStatementHistoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatementHistory(requestParameters: InvestmentApiGetStatementHistoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetStatementHistory200Response> {
            return localVarFp.getStatementHistory(requestParameters.investment_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the history of transactions and valuations for a given investment ID.
         * @param {InvestmentApiListInvestmentHistoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvestmentHistory(requestParameters: InvestmentApiListInvestmentHistoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListInvestmentHistory200Response> {
            return localVarFp.listInvestmentHistory(requestParameters.investment_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.types, requestParameters.populate_documents, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Investment documents.
         * @param {InvestmentApiListInvestmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvestments(requestParameters: InvestmentApiListInvestmentsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListInvestments200Response> {
            return localVarFp.listInvestments(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.accounts, requestParameters.connections, requestParameters.investments, requestParameters.investment_masters, requestParameters.advisory_groups, requestParameters.hidden, requestParameters.incomplete, requestParameters.inactive, requestParameters.exclude_from_billing, requestParameters.populate_user, requestParameters.populate_advisory_groups, requestParameters.populate_account, requestParameters.populate_audit_log, requestParameters.populate_audit_results, requestParameters.populate_investment_master, requestParameters.populate_connection, requestParameters.populate_integration_logo, requestParameters.populate_recent_valuation, requestParameters.populate_recent_transaction, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Investment documents grouped by Investment Master.
         * @param {InvestmentApiListInvestmentsByInvestmentMasterRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvestmentsByInvestmentMaster(requestParameters: InvestmentApiListInvestmentsByInvestmentMasterRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListInvestmentsByInvestmentMaster200Response> {
            return localVarFp.listInvestmentsByInvestmentMaster(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.accounts, requestParameters.connections, requestParameters.investments, requestParameters.investment_masters, requestParameters.advisory_groups, requestParameters.types, requestParameters.no_investment_master, requestParameters.hidden, requestParameters.incomplete, requestParameters.inactive, requestParameters.exclude_from_billing, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Investment document.
         * @param {InvestmentApiUpdateInvestmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvestment(requestParameters: InvestmentApiUpdateInvestmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateInvestment(requestParameters.investment_id, requestParameters.UpdateInvestmentRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createInvestment operation in InvestmentApi.
 * @export
 * @interface InvestmentApiCreateInvestmentRequest
 */
export interface InvestmentApiCreateInvestmentRequest {
    /**
     * 
     * @type {CreateInvestmentRequest}
     * @memberof InvestmentApiCreateInvestment
     */
    readonly CreateInvestmentRequest: CreateInvestmentRequest
}

/**
 * Request parameters for deleteInvestment operation in InvestmentApi.
 * @export
 * @interface InvestmentApiDeleteInvestmentRequest
 */
export interface InvestmentApiDeleteInvestmentRequest {
    /**
     * 
     * @type {string}
     * @memberof InvestmentApiDeleteInvestment
     */
    readonly investment_id: string
}

/**
 * Request parameters for getInvestment operation in InvestmentApi.
 * @export
 * @interface InvestmentApiGetInvestmentRequest
 */
export interface InvestmentApiGetInvestmentRequest {
    /**
     * 
     * @type {string}
     * @memberof InvestmentApiGetInvestment
     */
    readonly investment_id: string | null

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiGetInvestment
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiGetInvestment
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiGetInvestment
     */
    readonly populate_audit_log?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiGetInvestment
     */
    readonly populate_investment_master?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiGetInvestment
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiGetInvestment
     */
    readonly populate_integration_logo?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiGetInvestment
     */
    readonly populate_recent_valuation?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiGetInvestment
     */
    readonly populate_recent_transaction?: boolean
}

/**
 * Request parameters for getStatementHistory operation in InvestmentApi.
 * @export
 * @interface InvestmentApiGetStatementHistoryRequest
 */
export interface InvestmentApiGetStatementHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof InvestmentApiGetStatementHistory
     */
    readonly investment_id: string
}

/**
 * Request parameters for listInvestmentHistory operation in InvestmentApi.
 * @export
 * @interface InvestmentApiListInvestmentHistoryRequest
 */
export interface InvestmentApiListInvestmentHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof InvestmentApiListInvestmentHistory
     */
    readonly investment_id: string

    /**
     * 
     * @type {number}
     * @memberof InvestmentApiListInvestmentHistory
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof InvestmentApiListInvestmentHistory
     */
    readonly limit?: number

    /**
     * 
     * @type {'type' | 'data.date' | 'data.amount' | 'history_type' | 'document_count'}
     * @memberof InvestmentApiListInvestmentHistory
     */
    readonly sort_field?: ListInvestmentHistorySortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof InvestmentApiListInvestmentHistory
     */
    readonly sort_direction?: ListInvestmentHistorySortDirectionEnum

    /**
     * 
     * @type {Array<'commitment' | 'transaction' | 'valuation'>}
     * @memberof InvestmentApiListInvestmentHistory
     */
    readonly types?: Array<ListInvestmentHistoryTypesEnum>

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestmentHistory
     */
    readonly populate_documents?: boolean
}

/**
 * Request parameters for listInvestments operation in InvestmentApi.
 * @export
 * @interface InvestmentApiListInvestmentsRequest
 */
export interface InvestmentApiListInvestmentsRequest {
    /**
     * 
     * @type {number}
     * @memberof InvestmentApiListInvestments
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof InvestmentApiListInvestments
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof InvestmentApiListInvestments
     */
    readonly search?: string

    /**
     * 
     * @type {'created_at' | 'name' | 'type' | 'date' | 'inactive' | 'started_at' | 'hidden' | 'performance.itd.committed' | 'performance.itd.contributions' | 'performance.itd.distributions' | 'performance.itd.recallable' | 'performance.itd.irr' | 'valuation' | 'first_cashflow_date'}
     * @memberof InvestmentApiListInvestments
     */
    readonly sort_field?: ListInvestmentsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof InvestmentApiListInvestments
     */
    readonly sort_direction?: ListInvestmentsSortDirectionEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentApiListInvestments
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentApiListInvestments
     */
    readonly accounts?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentApiListInvestments
     */
    readonly connections?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentApiListInvestments
     */
    readonly investments?: Array<string>

    /**
     * 
     * @type {ListInvestmentsInvestmentMastersParameter}
     * @memberof InvestmentApiListInvestments
     */
    readonly investment_masters?: ListInvestmentsInvestmentMastersParameter

    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentApiListInvestments
     */
    readonly advisory_groups?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly hidden?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly incomplete?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly inactive?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly exclude_from_billing?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly populate_advisory_groups?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly populate_audit_log?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly populate_audit_results?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly populate_investment_master?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly populate_integration_logo?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly populate_recent_valuation?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly populate_recent_transaction?: boolean
}

/**
 * Request parameters for listInvestmentsByInvestmentMaster operation in InvestmentApi.
 * @export
 * @interface InvestmentApiListInvestmentsByInvestmentMasterRequest
 */
export interface InvestmentApiListInvestmentsByInvestmentMasterRequest {
    /**
     * 
     * @type {number}
     * @memberof InvestmentApiListInvestmentsByInvestmentMaster
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof InvestmentApiListInvestmentsByInvestmentMaster
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof InvestmentApiListInvestmentsByInvestmentMaster
     */
    readonly search?: string

    /**
     * 
     * @type {'name' | 'investment_master.ticker' | 'type' | 'totalCount' | 'totalInvested' | 'totalCommitted' | 'totalFees' | 'totalValuation' | 'totalGain' | 'activeCount' | 'averageInvested' | 'investment_master.currency'}
     * @memberof InvestmentApiListInvestmentsByInvestmentMaster
     */
    readonly sort_field?: ListInvestmentsByInvestmentMasterSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof InvestmentApiListInvestmentsByInvestmentMaster
     */
    readonly sort_direction?: ListInvestmentsByInvestmentMasterSortDirectionEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentApiListInvestmentsByInvestmentMaster
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentApiListInvestmentsByInvestmentMaster
     */
    readonly accounts?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentApiListInvestmentsByInvestmentMaster
     */
    readonly connections?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentApiListInvestmentsByInvestmentMaster
     */
    readonly investments?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentApiListInvestmentsByInvestmentMaster
     */
    readonly investment_masters?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentApiListInvestmentsByInvestmentMaster
     */
    readonly advisory_groups?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentApiListInvestmentsByInvestmentMaster
     */
    readonly types?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestmentsByInvestmentMaster
     */
    readonly no_investment_master?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestmentsByInvestmentMaster
     */
    readonly hidden?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestmentsByInvestmentMaster
     */
    readonly incomplete?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestmentsByInvestmentMaster
     */
    readonly inactive?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestmentsByInvestmentMaster
     */
    readonly exclude_from_billing?: boolean
}

/**
 * Request parameters for updateInvestment operation in InvestmentApi.
 * @export
 * @interface InvestmentApiUpdateInvestmentRequest
 */
export interface InvestmentApiUpdateInvestmentRequest {
    /**
     * 
     * @type {string}
     * @memberof InvestmentApiUpdateInvestment
     */
    readonly investment_id: string

    /**
     * 
     * @type {UpdateInvestmentRequest}
     * @memberof InvestmentApiUpdateInvestment
     */
    readonly UpdateInvestmentRequest?: UpdateInvestmentRequest
}

/**
 * InvestmentApi - object-oriented interface
 * @export
 * @class InvestmentApi
 * @extends {BaseAPI}
 */
export class InvestmentApi extends BaseAPI {
    /**
     * Create a new Investment document.
     * @param {InvestmentApiCreateInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentApi
     */
    public createInvestment(requestParameters: InvestmentApiCreateInvestmentRequest, options?: RawAxiosRequestConfig) {
        return InvestmentApiFp(this.configuration).createInvestment(requestParameters.CreateInvestmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an Investment document.
     * @param {InvestmentApiDeleteInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentApi
     */
    public deleteInvestment(requestParameters: InvestmentApiDeleteInvestmentRequest, options?: RawAxiosRequestConfig) {
        return InvestmentApiFp(this.configuration).deleteInvestment(requestParameters.investment_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single Investment document by ID.
     * @param {InvestmentApiGetInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentApi
     */
    public getInvestment(requestParameters: InvestmentApiGetInvestmentRequest, options?: RawAxiosRequestConfig) {
        return InvestmentApiFp(this.configuration).getInvestment(requestParameters.investment_id, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_audit_log, requestParameters.populate_investment_master, requestParameters.populate_connection, requestParameters.populate_integration_logo, requestParameters.populate_recent_valuation, requestParameters.populate_recent_transaction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve JSON metadata information about historical statements for a given Investment.
     * @param {InvestmentApiGetStatementHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentApi
     */
    public getStatementHistory(requestParameters: InvestmentApiGetStatementHistoryRequest, options?: RawAxiosRequestConfig) {
        return InvestmentApiFp(this.configuration).getStatementHistory(requestParameters.investment_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the history of transactions and valuations for a given investment ID.
     * @param {InvestmentApiListInvestmentHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentApi
     */
    public listInvestmentHistory(requestParameters: InvestmentApiListInvestmentHistoryRequest, options?: RawAxiosRequestConfig) {
        return InvestmentApiFp(this.configuration).listInvestmentHistory(requestParameters.investment_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.types, requestParameters.populate_documents, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Investment documents.
     * @param {InvestmentApiListInvestmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentApi
     */
    public listInvestments(requestParameters: InvestmentApiListInvestmentsRequest = {}, options?: RawAxiosRequestConfig) {
        return InvestmentApiFp(this.configuration).listInvestments(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.accounts, requestParameters.connections, requestParameters.investments, requestParameters.investment_masters, requestParameters.advisory_groups, requestParameters.hidden, requestParameters.incomplete, requestParameters.inactive, requestParameters.exclude_from_billing, requestParameters.populate_user, requestParameters.populate_advisory_groups, requestParameters.populate_account, requestParameters.populate_audit_log, requestParameters.populate_audit_results, requestParameters.populate_investment_master, requestParameters.populate_connection, requestParameters.populate_integration_logo, requestParameters.populate_recent_valuation, requestParameters.populate_recent_transaction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Investment documents grouped by Investment Master.
     * @param {InvestmentApiListInvestmentsByInvestmentMasterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentApi
     */
    public listInvestmentsByInvestmentMaster(requestParameters: InvestmentApiListInvestmentsByInvestmentMasterRequest = {}, options?: RawAxiosRequestConfig) {
        return InvestmentApiFp(this.configuration).listInvestmentsByInvestmentMaster(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.accounts, requestParameters.connections, requestParameters.investments, requestParameters.investment_masters, requestParameters.advisory_groups, requestParameters.types, requestParameters.no_investment_master, requestParameters.hidden, requestParameters.incomplete, requestParameters.inactive, requestParameters.exclude_from_billing, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Investment document.
     * @param {InvestmentApiUpdateInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentApi
     */
    public updateInvestment(requestParameters: InvestmentApiUpdateInvestmentRequest, options?: RawAxiosRequestConfig) {
        return InvestmentApiFp(this.configuration).updateInvestment(requestParameters.investment_id, requestParameters.UpdateInvestmentRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListInvestmentHistorySortFieldEnum = {
    Type: 'type',
    DataDate: 'data.date',
    DataAmount: 'data.amount',
    HistoryType: 'history_type',
    DocumentCount: 'document_count'
} as const;
export type ListInvestmentHistorySortFieldEnum = typeof ListInvestmentHistorySortFieldEnum[keyof typeof ListInvestmentHistorySortFieldEnum];
/**
 * @export
 */
export const ListInvestmentHistorySortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListInvestmentHistorySortDirectionEnum = typeof ListInvestmentHistorySortDirectionEnum[keyof typeof ListInvestmentHistorySortDirectionEnum];
/**
 * @export
 */
export const ListInvestmentHistoryTypesEnum = {
    Commitment: 'commitment',
    Transaction: 'transaction',
    Valuation: 'valuation'
} as const;
export type ListInvestmentHistoryTypesEnum = typeof ListInvestmentHistoryTypesEnum[keyof typeof ListInvestmentHistoryTypesEnum];
/**
 * @export
 */
export const ListInvestmentsSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name',
    Type: 'type',
    Date: 'date',
    Inactive: 'inactive',
    StartedAt: 'started_at',
    Hidden: 'hidden',
    PerformanceItdCommitted: 'performance.itd.committed',
    PerformanceItdContributions: 'performance.itd.contributions',
    PerformanceItdDistributions: 'performance.itd.distributions',
    PerformanceItdRecallable: 'performance.itd.recallable',
    PerformanceItdIrr: 'performance.itd.irr',
    Valuation: 'valuation',
    FirstCashflowDate: 'first_cashflow_date'
} as const;
export type ListInvestmentsSortFieldEnum = typeof ListInvestmentsSortFieldEnum[keyof typeof ListInvestmentsSortFieldEnum];
/**
 * @export
 */
export const ListInvestmentsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListInvestmentsSortDirectionEnum = typeof ListInvestmentsSortDirectionEnum[keyof typeof ListInvestmentsSortDirectionEnum];
/**
 * @export
 */
export const ListInvestmentsByInvestmentMasterSortFieldEnum = {
    Name: 'name',
    InvestmentMasterTicker: 'investment_master.ticker',
    Type: 'type',
    TotalCount: 'totalCount',
    TotalInvested: 'totalInvested',
    TotalCommitted: 'totalCommitted',
    TotalFees: 'totalFees',
    TotalValuation: 'totalValuation',
    TotalGain: 'totalGain',
    ActiveCount: 'activeCount',
    AverageInvested: 'averageInvested',
    InvestmentMasterCurrency: 'investment_master.currency'
} as const;
export type ListInvestmentsByInvestmentMasterSortFieldEnum = typeof ListInvestmentsByInvestmentMasterSortFieldEnum[keyof typeof ListInvestmentsByInvestmentMasterSortFieldEnum];
/**
 * @export
 */
export const ListInvestmentsByInvestmentMasterSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListInvestmentsByInvestmentMasterSortDirectionEnum = typeof ListInvestmentsByInvestmentMasterSortDirectionEnum[keyof typeof ListInvestmentsByInvestmentMasterSortDirectionEnum];


/**
 * InvestmentMasterApi - axios parameter creator
 * @export
 */
export const InvestmentMasterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Apply all Investment.
         * @param {string | null} investment_master_id 
         * @param {ApplyAllInvestmentRequest} [ApplyAllInvestmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyAllInvestment: async (investment_master_id: string | null, ApplyAllInvestmentRequest?: ApplyAllInvestmentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investment_master_id' is not null or undefined
            assertParamExists('applyAllInvestment', 'investment_master_id', investment_master_id)
            const localVarPath = `/v2/investmentmasters/{investment_master_id}/apply_all`
                .replace(`{${"investment_master_id"}}`, encodeURIComponent(String(investment_master_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ApplyAllInvestmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new InvestmentMaster document.
         * @param {CreateInvestmentMasterRequest} CreateInvestmentMasterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvestmentMaster: async (CreateInvestmentMasterRequest: CreateInvestmentMasterRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateInvestmentMasterRequest' is not null or undefined
            assertParamExists('createInvestmentMaster', 'CreateInvestmentMasterRequest', CreateInvestmentMasterRequest)
            const localVarPath = `/v2/investmentmasters/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateInvestmentMasterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an InvestmentMaster document.
         * @param {string} investment_master_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvestmentMaster: async (investment_master_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investment_master_id' is not null or undefined
            assertParamExists('deleteInvestmentMaster', 'investment_master_id', investment_master_id)
            const localVarPath = `/v2/investmentmasters/{investment_master_id}`
                .replace(`{${"investment_master_id"}}`, encodeURIComponent(String(investment_master_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through InvestmentMaster documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListInvestmentMastersSortFieldEnum} [sort_field] 
         * @param {ListInvestmentMastersSortDirectionEnum} [sort_direction] 
         * @param {string} [search] 
         * @param {ListInvestmentMastersCurrencyEnum} [currency] 
         * @param {string} [type] 
         * @param {string | null} [asset_manager] 
         * @param {string | null} [exchange] 
         * @param {string} [sub_type] 
         * @param {Array<string>} [ids] 
         * @param {boolean} [populate_asset_manager] 
         * @param {boolean} [populate_exchange] 
         * @param {boolean} [populate_contacts] 
         * @param {boolean} [has_sla_issues] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [investment_masters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvestmentMasters: async (skip?: number, limit?: number, sort_field?: ListInvestmentMastersSortFieldEnum, sort_direction?: ListInvestmentMastersSortDirectionEnum, search?: string, currency?: ListInvestmentMastersCurrencyEnum, type?: string, asset_manager?: string | null, exchange?: string | null, sub_type?: string, ids?: Array<string>, populate_asset_manager?: boolean, populate_exchange?: boolean, populate_contacts?: boolean, has_sla_issues?: boolean, users?: Array<string>, investment_masters?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/investmentmasters/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (asset_manager !== undefined) {
                localVarQueryParameter['asset_manager'] = asset_manager;
            }

            if (exchange !== undefined) {
                localVarQueryParameter['exchange'] = exchange;
            }

            if (sub_type !== undefined) {
                localVarQueryParameter['sub_type'] = sub_type;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (populate_asset_manager !== undefined) {
                localVarQueryParameter['populate_asset_manager'] = populate_asset_manager;
            }

            if (populate_exchange !== undefined) {
                localVarQueryParameter['populate_exchange'] = populate_exchange;
            }

            if (populate_contacts !== undefined) {
                localVarQueryParameter['populate_contacts'] = populate_contacts;
            }

            if (has_sla_issues !== undefined) {
                localVarQueryParameter['has_sla_issues'] = has_sla_issues;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (investment_masters) {
                localVarQueryParameter['investment_masters'] = investment_masters;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing InvestmentMaster document.
         * @param {string} investment_master_id 
         * @param {UpdateInvestmentMasterRequest} [UpdateInvestmentMasterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvestmentMaster: async (investment_master_id: string, UpdateInvestmentMasterRequest?: UpdateInvestmentMasterRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investment_master_id' is not null or undefined
            assertParamExists('updateInvestmentMaster', 'investment_master_id', investment_master_id)
            const localVarPath = `/v2/investmentmasters/{investment_master_id}`
                .replace(`{${"investment_master_id"}}`, encodeURIComponent(String(investment_master_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateInvestmentMasterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestmentMasterApi - functional programming interface
 * @export
 */
export const InvestmentMasterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestmentMasterApiAxiosParamCreator(configuration)
    return {
        /**
         * Apply all Investment.
         * @param {string | null} investment_master_id 
         * @param {ApplyAllInvestmentRequest} [ApplyAllInvestmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyAllInvestment(investment_master_id: string | null, ApplyAllInvestmentRequest?: ApplyAllInvestmentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplyAllInvestment200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyAllInvestment(investment_master_id, ApplyAllInvestmentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentMasterApi.applyAllInvestment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new InvestmentMaster document.
         * @param {CreateInvestmentMasterRequest} CreateInvestmentMasterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvestmentMaster(CreateInvestmentMasterRequest: CreateInvestmentMasterRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateInvestmentMaster201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvestmentMaster(CreateInvestmentMasterRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentMasterApi.createInvestmentMaster']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an InvestmentMaster document.
         * @param {string} investment_master_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInvestmentMaster(investment_master_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInvestmentMaster(investment_master_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentMasterApi.deleteInvestmentMaster']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through InvestmentMaster documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListInvestmentMastersSortFieldEnum} [sort_field] 
         * @param {ListInvestmentMastersSortDirectionEnum} [sort_direction] 
         * @param {string} [search] 
         * @param {ListInvestmentMastersCurrencyEnum} [currency] 
         * @param {string} [type] 
         * @param {string | null} [asset_manager] 
         * @param {string | null} [exchange] 
         * @param {string} [sub_type] 
         * @param {Array<string>} [ids] 
         * @param {boolean} [populate_asset_manager] 
         * @param {boolean} [populate_exchange] 
         * @param {boolean} [populate_contacts] 
         * @param {boolean} [has_sla_issues] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [investment_masters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInvestmentMasters(skip?: number, limit?: number, sort_field?: ListInvestmentMastersSortFieldEnum, sort_direction?: ListInvestmentMastersSortDirectionEnum, search?: string, currency?: ListInvestmentMastersCurrencyEnum, type?: string, asset_manager?: string | null, exchange?: string | null, sub_type?: string, ids?: Array<string>, populate_asset_manager?: boolean, populate_exchange?: boolean, populate_contacts?: boolean, has_sla_issues?: boolean, users?: Array<string>, investment_masters?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListInvestmentMasters200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInvestmentMasters(skip, limit, sort_field, sort_direction, search, currency, type, asset_manager, exchange, sub_type, ids, populate_asset_manager, populate_exchange, populate_contacts, has_sla_issues, users, investment_masters, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentMasterApi.listInvestmentMasters']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing InvestmentMaster document.
         * @param {string} investment_master_id 
         * @param {UpdateInvestmentMasterRequest} [UpdateInvestmentMasterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInvestmentMaster(investment_master_id: string, UpdateInvestmentMasterRequest?: UpdateInvestmentMasterRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvestmentMaster(investment_master_id, UpdateInvestmentMasterRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentMasterApi.updateInvestmentMaster']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InvestmentMasterApi - factory interface
 * @export
 */
export const InvestmentMasterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestmentMasterApiFp(configuration)
    return {
        /**
         * Apply all Investment.
         * @param {InvestmentMasterApiApplyAllInvestmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyAllInvestment(requestParameters: InvestmentMasterApiApplyAllInvestmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApplyAllInvestment200Response> {
            return localVarFp.applyAllInvestment(requestParameters.investment_master_id, requestParameters.ApplyAllInvestmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new InvestmentMaster document.
         * @param {InvestmentMasterApiCreateInvestmentMasterRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvestmentMaster(requestParameters: InvestmentMasterApiCreateInvestmentMasterRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateInvestmentMaster201Response> {
            return localVarFp.createInvestmentMaster(requestParameters.CreateInvestmentMasterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an InvestmentMaster document.
         * @param {InvestmentMasterApiDeleteInvestmentMasterRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvestmentMaster(requestParameters: InvestmentMasterApiDeleteInvestmentMasterRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteInvestmentMaster(requestParameters.investment_master_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through InvestmentMaster documents.
         * @param {InvestmentMasterApiListInvestmentMastersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvestmentMasters(requestParameters: InvestmentMasterApiListInvestmentMastersRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListInvestmentMasters200Response> {
            return localVarFp.listInvestmentMasters(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.search, requestParameters.currency, requestParameters.type, requestParameters.asset_manager, requestParameters.exchange, requestParameters.sub_type, requestParameters.ids, requestParameters.populate_asset_manager, requestParameters.populate_exchange, requestParameters.populate_contacts, requestParameters.has_sla_issues, requestParameters.users, requestParameters.investment_masters, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing InvestmentMaster document.
         * @param {InvestmentMasterApiUpdateInvestmentMasterRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvestmentMaster(requestParameters: InvestmentMasterApiUpdateInvestmentMasterRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateInvestmentMaster(requestParameters.investment_master_id, requestParameters.UpdateInvestmentMasterRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for applyAllInvestment operation in InvestmentMasterApi.
 * @export
 * @interface InvestmentMasterApiApplyAllInvestmentRequest
 */
export interface InvestmentMasterApiApplyAllInvestmentRequest {
    /**
     * 
     * @type {string}
     * @memberof InvestmentMasterApiApplyAllInvestment
     */
    readonly investment_master_id: string | null

    /**
     * 
     * @type {ApplyAllInvestmentRequest}
     * @memberof InvestmentMasterApiApplyAllInvestment
     */
    readonly ApplyAllInvestmentRequest?: ApplyAllInvestmentRequest
}

/**
 * Request parameters for createInvestmentMaster operation in InvestmentMasterApi.
 * @export
 * @interface InvestmentMasterApiCreateInvestmentMasterRequest
 */
export interface InvestmentMasterApiCreateInvestmentMasterRequest {
    /**
     * 
     * @type {CreateInvestmentMasterRequest}
     * @memberof InvestmentMasterApiCreateInvestmentMaster
     */
    readonly CreateInvestmentMasterRequest: CreateInvestmentMasterRequest
}

/**
 * Request parameters for deleteInvestmentMaster operation in InvestmentMasterApi.
 * @export
 * @interface InvestmentMasterApiDeleteInvestmentMasterRequest
 */
export interface InvestmentMasterApiDeleteInvestmentMasterRequest {
    /**
     * 
     * @type {string}
     * @memberof InvestmentMasterApiDeleteInvestmentMaster
     */
    readonly investment_master_id: string
}

/**
 * Request parameters for listInvestmentMasters operation in InvestmentMasterApi.
 * @export
 * @interface InvestmentMasterApiListInvestmentMastersRequest
 */
export interface InvestmentMasterApiListInvestmentMastersRequest {
    /**
     * 
     * @type {number}
     * @memberof InvestmentMasterApiListInvestmentMasters
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof InvestmentMasterApiListInvestmentMasters
     */
    readonly limit?: number

    /**
     * 
     * @type {'name' | 'ticker' | 'type' | 'sub_type' | 'asset_manager' | 'exchange' | 'created_at' | 'price' | 'description' | 'website'}
     * @memberof InvestmentMasterApiListInvestmentMasters
     */
    readonly sort_field?: ListInvestmentMastersSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof InvestmentMasterApiListInvestmentMasters
     */
    readonly sort_direction?: ListInvestmentMastersSortDirectionEnum

    /**
     * 
     * @type {string}
     * @memberof InvestmentMasterApiListInvestmentMasters
     */
    readonly search?: string

    /**
     * 
     * @type {'USD' | 'EUR' | 'CNY' | 'JPY' | 'GBP' | 'AUD' | 'CAD' | 'CHF' | 'INR' | 'KRW' | 'BTC'}
     * @memberof InvestmentMasterApiListInvestmentMasters
     */
    readonly currency?: ListInvestmentMastersCurrencyEnum

    /**
     * 
     * @type {string}
     * @memberof InvestmentMasterApiListInvestmentMasters
     */
    readonly type?: string

    /**
     * 
     * @type {string}
     * @memberof InvestmentMasterApiListInvestmentMasters
     */
    readonly asset_manager?: string | null

    /**
     * 
     * @type {string}
     * @memberof InvestmentMasterApiListInvestmentMasters
     */
    readonly exchange?: string | null

    /**
     * 
     * @type {string}
     * @memberof InvestmentMasterApiListInvestmentMasters
     */
    readonly sub_type?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentMasterApiListInvestmentMasters
     */
    readonly ids?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentMasterApiListInvestmentMasters
     */
    readonly populate_asset_manager?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentMasterApiListInvestmentMasters
     */
    readonly populate_exchange?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentMasterApiListInvestmentMasters
     */
    readonly populate_contacts?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentMasterApiListInvestmentMasters
     */
    readonly has_sla_issues?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentMasterApiListInvestmentMasters
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentMasterApiListInvestmentMasters
     */
    readonly investment_masters?: Array<string>
}

/**
 * Request parameters for updateInvestmentMaster operation in InvestmentMasterApi.
 * @export
 * @interface InvestmentMasterApiUpdateInvestmentMasterRequest
 */
export interface InvestmentMasterApiUpdateInvestmentMasterRequest {
    /**
     * 
     * @type {string}
     * @memberof InvestmentMasterApiUpdateInvestmentMaster
     */
    readonly investment_master_id: string

    /**
     * 
     * @type {UpdateInvestmentMasterRequest}
     * @memberof InvestmentMasterApiUpdateInvestmentMaster
     */
    readonly UpdateInvestmentMasterRequest?: UpdateInvestmentMasterRequest
}

/**
 * InvestmentMasterApi - object-oriented interface
 * @export
 * @class InvestmentMasterApi
 * @extends {BaseAPI}
 */
export class InvestmentMasterApi extends BaseAPI {
    /**
     * Apply all Investment.
     * @param {InvestmentMasterApiApplyAllInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentMasterApi
     */
    public applyAllInvestment(requestParameters: InvestmentMasterApiApplyAllInvestmentRequest, options?: RawAxiosRequestConfig) {
        return InvestmentMasterApiFp(this.configuration).applyAllInvestment(requestParameters.investment_master_id, requestParameters.ApplyAllInvestmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new InvestmentMaster document.
     * @param {InvestmentMasterApiCreateInvestmentMasterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentMasterApi
     */
    public createInvestmentMaster(requestParameters: InvestmentMasterApiCreateInvestmentMasterRequest, options?: RawAxiosRequestConfig) {
        return InvestmentMasterApiFp(this.configuration).createInvestmentMaster(requestParameters.CreateInvestmentMasterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an InvestmentMaster document.
     * @param {InvestmentMasterApiDeleteInvestmentMasterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentMasterApi
     */
    public deleteInvestmentMaster(requestParameters: InvestmentMasterApiDeleteInvestmentMasterRequest, options?: RawAxiosRequestConfig) {
        return InvestmentMasterApiFp(this.configuration).deleteInvestmentMaster(requestParameters.investment_master_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through InvestmentMaster documents.
     * @param {InvestmentMasterApiListInvestmentMastersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentMasterApi
     */
    public listInvestmentMasters(requestParameters: InvestmentMasterApiListInvestmentMastersRequest = {}, options?: RawAxiosRequestConfig) {
        return InvestmentMasterApiFp(this.configuration).listInvestmentMasters(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.search, requestParameters.currency, requestParameters.type, requestParameters.asset_manager, requestParameters.exchange, requestParameters.sub_type, requestParameters.ids, requestParameters.populate_asset_manager, requestParameters.populate_exchange, requestParameters.populate_contacts, requestParameters.has_sla_issues, requestParameters.users, requestParameters.investment_masters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing InvestmentMaster document.
     * @param {InvestmentMasterApiUpdateInvestmentMasterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentMasterApi
     */
    public updateInvestmentMaster(requestParameters: InvestmentMasterApiUpdateInvestmentMasterRequest, options?: RawAxiosRequestConfig) {
        return InvestmentMasterApiFp(this.configuration).updateInvestmentMaster(requestParameters.investment_master_id, requestParameters.UpdateInvestmentMasterRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListInvestmentMastersSortFieldEnum = {
    Name: 'name',
    Ticker: 'ticker',
    Type: 'type',
    SubType: 'sub_type',
    AssetManager: 'asset_manager',
    Exchange: 'exchange',
    CreatedAt: 'created_at',
    Price: 'price',
    Description: 'description',
    Website: 'website'
} as const;
export type ListInvestmentMastersSortFieldEnum = typeof ListInvestmentMastersSortFieldEnum[keyof typeof ListInvestmentMastersSortFieldEnum];
/**
 * @export
 */
export const ListInvestmentMastersSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListInvestmentMastersSortDirectionEnum = typeof ListInvestmentMastersSortDirectionEnum[keyof typeof ListInvestmentMastersSortDirectionEnum];
/**
 * @export
 */
export const ListInvestmentMastersCurrencyEnum = {
    Usd: 'USD',
    Eur: 'EUR',
    Cny: 'CNY',
    Jpy: 'JPY',
    Gbp: 'GBP',
    Aud: 'AUD',
    Cad: 'CAD',
    Chf: 'CHF',
    Inr: 'INR',
    Krw: 'KRW',
    Btc: 'BTC'
} as const;
export type ListInvestmentMastersCurrencyEnum = typeof ListInvestmentMastersCurrencyEnum[keyof typeof ListInvestmentMastersCurrencyEnum];


/**
 * InvitationsApi - axios parameter creator
 * @export
 */
export const InvitationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create invitation
         * @param {CreateInvitationRequest} [CreateInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvitation: async (CreateInvitationRequest?: CreateInvitationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/invitations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateInvitationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete invitation
         * @param {string} invitation_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvitation: async (invitation_id: string, skip?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitation_id' is not null or undefined
            assertParamExists('deleteInvitation', 'invitation_id', invitation_id)
            const localVarPath = `/v2/invitations/{invitation_id}`
                .replace(`{${"invitation_id"}}`, encodeURIComponent(String(invitation_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get invitation by id
         * @param {string | null} invitation_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationById: async (invitation_id: string | null, skip?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitation_id' is not null or undefined
            assertParamExists('getInvitationById', 'invitation_id', invitation_id)
            const localVarPath = `/v2/invitations/{invitation_id}`
                .replace(`{${"invitation_id"}}`, encodeURIComponent(String(invitation_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List invitations
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sort_field] 
         * @param {ListInvitationsSortDirectionEnum} [sort_direction] 
         * @param {string | null} [user_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvitations: async (skip?: number, limit?: number, sort_field?: string, sort_direction?: ListInvitationsSortDirectionEnum, user_id?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/invitations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (user_id !== undefined) {
                localVarQueryParameter['user_id'] = user_id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvitationsApi - functional programming interface
 * @export
 */
export const InvitationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvitationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create invitation
         * @param {CreateInvitationRequest} [CreateInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvitation(CreateInvitationRequest?: CreateInvitationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateInvitation200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvitation(CreateInvitationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.createInvitation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete invitation
         * @param {string} invitation_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInvitation(invitation_id: string, skip?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteInvitation200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInvitation(invitation_id, skip, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.deleteInvitation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get invitation by id
         * @param {string | null} invitation_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvitationById(invitation_id: string | null, skip?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInvitationById200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvitationById(invitation_id, skip, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.getInvitationById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List invitations
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sort_field] 
         * @param {ListInvitationsSortDirectionEnum} [sort_direction] 
         * @param {string | null} [user_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInvitations(skip?: number, limit?: number, sort_field?: string, sort_direction?: ListInvitationsSortDirectionEnum, user_id?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListInvitations200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInvitations(skip, limit, sort_field, sort_direction, user_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.listInvitations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InvitationsApi - factory interface
 * @export
 */
export const InvitationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvitationsApiFp(configuration)
    return {
        /**
         * Create invitation
         * @param {InvitationsApiCreateInvitationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvitation(requestParameters: InvitationsApiCreateInvitationRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CreateInvitation200Response> {
            return localVarFp.createInvitation(requestParameters.CreateInvitationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete invitation
         * @param {InvitationsApiDeleteInvitationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvitation(requestParameters: InvitationsApiDeleteInvitationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteInvitation200Response> {
            return localVarFp.deleteInvitation(requestParameters.invitation_id, requestParameters.skip, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get invitation by id
         * @param {InvitationsApiGetInvitationByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationById(requestParameters: InvitationsApiGetInvitationByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetInvitationById200Response> {
            return localVarFp.getInvitationById(requestParameters.invitation_id, requestParameters.skip, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
        /**
         * List invitations
         * @param {InvitationsApiListInvitationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvitations(requestParameters: InvitationsApiListInvitationsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListInvitations200Response> {
            return localVarFp.listInvitations(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.user_id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createInvitation operation in InvitationsApi.
 * @export
 * @interface InvitationsApiCreateInvitationRequest
 */
export interface InvitationsApiCreateInvitationRequest {
    /**
     * 
     * @type {CreateInvitationRequest}
     * @memberof InvitationsApiCreateInvitation
     */
    readonly CreateInvitationRequest?: CreateInvitationRequest
}

/**
 * Request parameters for deleteInvitation operation in InvitationsApi.
 * @export
 * @interface InvitationsApiDeleteInvitationRequest
 */
export interface InvitationsApiDeleteInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof InvitationsApiDeleteInvitation
     */
    readonly invitation_id: string

    /**
     * 
     * @type {number}
     * @memberof InvitationsApiDeleteInvitation
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof InvitationsApiDeleteInvitation
     */
    readonly limit?: number
}

/**
 * Request parameters for getInvitationById operation in InvitationsApi.
 * @export
 * @interface InvitationsApiGetInvitationByIdRequest
 */
export interface InvitationsApiGetInvitationByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof InvitationsApiGetInvitationById
     */
    readonly invitation_id: string | null

    /**
     * 
     * @type {number}
     * @memberof InvitationsApiGetInvitationById
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof InvitationsApiGetInvitationById
     */
    readonly limit?: number
}

/**
 * Request parameters for listInvitations operation in InvitationsApi.
 * @export
 * @interface InvitationsApiListInvitationsRequest
 */
export interface InvitationsApiListInvitationsRequest {
    /**
     * 
     * @type {number}
     * @memberof InvitationsApiListInvitations
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof InvitationsApiListInvitations
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof InvitationsApiListInvitations
     */
    readonly sort_field?: string

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof InvitationsApiListInvitations
     */
    readonly sort_direction?: ListInvitationsSortDirectionEnum

    /**
     * 
     * @type {string}
     * @memberof InvitationsApiListInvitations
     */
    readonly user_id?: string | null
}

/**
 * InvitationsApi - object-oriented interface
 * @export
 * @class InvitationsApi
 * @extends {BaseAPI}
 */
export class InvitationsApi extends BaseAPI {
    /**
     * Create invitation
     * @param {InvitationsApiCreateInvitationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public createInvitation(requestParameters: InvitationsApiCreateInvitationRequest = {}, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).createInvitation(requestParameters.CreateInvitationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete invitation
     * @param {InvitationsApiDeleteInvitationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public deleteInvitation(requestParameters: InvitationsApiDeleteInvitationRequest, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).deleteInvitation(requestParameters.invitation_id, requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get invitation by id
     * @param {InvitationsApiGetInvitationByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public getInvitationById(requestParameters: InvitationsApiGetInvitationByIdRequest, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).getInvitationById(requestParameters.invitation_id, requestParameters.skip, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List invitations
     * @param {InvitationsApiListInvitationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public listInvitations(requestParameters: InvitationsApiListInvitationsRequest = {}, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).listInvitations(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.user_id, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListInvitationsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListInvitationsSortDirectionEnum = typeof ListInvitationsSortDirectionEnum[keyof typeof ListInvitationsSortDirectionEnum];


/**
 * MessagesApi - axios parameter creator
 * @export
 */
export const MessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get a message by its ID
         * @param {string | null} message_id 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_connections] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageById: async (message_id: string | null, populate_account?: boolean, populate_user?: boolean, populate_connections?: boolean, populate_audit_log?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'message_id' is not null or undefined
            assertParamExists('getMessageById', 'message_id', message_id)
            const localVarPath = `/v2/messages/{message_id}`
                .replace(`{${"message_id"}}`, encodeURIComponent(String(message_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_connections !== undefined) {
                localVarQueryParameter['populate_connections'] = populate_connections;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List message tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMessageTags: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/messages/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get messages
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_connections] 
         * @param {boolean} [populate_advisory_groups] 
         * @param {ListMessagesSortFieldEnum} [sort_field] 
         * @param {ListMessagesSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [advisory_groups] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [connections] 
         * @param {ListMessagesConnectionStatusEnum} [connection_status] 
         * @param {boolean} [connection_expects_documents] 
         * @param {Array<string>} [message_ids] 
         * @param {string} [search] 
         * @param {ListMessagesHasAttachmentsEnum} [has_attachments] 
         * @param {ListMessagesHasConnectionEnum} [has_connection] 
         * @param {ListMessagesMetadataInvestorReadEnum} [metadata_investor_read] 
         * @param {ListMessagesMetadataInvestorFlaggedEnum} [metadata_investor_flagged] 
         * @param {ListMessagesMetadataAdvisorReadEnum} [metadata_advisor_read] 
         * @param {ListMessagesMetadataAdvisorFlaggedEnum} [metadata_advisor_flagged] 
         * @param {ListMessagesMetadataAdminReadEnum} [metadata_admin_read] 
         * @param {ListMessagesMetadataAdminFlaggedEnum} [metadata_admin_flagged] 
         * @param {ListMessagesCategoryEnum} [category] 
         * @param {string} [date] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [asset_managers] 
         * @param {Array<ListMessagesIntegrationsParameterInner>} [integrations] 
         * @param {Array<ListMessagesExcludeIntegrationsParameterInner>} [exclude_integrations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMessages: async (skip?: number, limit?: number, populate_user?: boolean, populate_connections?: boolean, populate_advisory_groups?: boolean, sort_field?: ListMessagesSortFieldEnum, sort_direction?: ListMessagesSortDirectionEnum, advisory_groups?: Array<string>, users?: Array<string>, connections?: Array<string>, connection_status?: ListMessagesConnectionStatusEnum, connection_expects_documents?: boolean, message_ids?: Array<string>, search?: string, has_attachments?: ListMessagesHasAttachmentsEnum, has_connection?: ListMessagesHasConnectionEnum, metadata_investor_read?: ListMessagesMetadataInvestorReadEnum, metadata_investor_flagged?: ListMessagesMetadataInvestorFlaggedEnum, metadata_advisor_read?: ListMessagesMetadataAdvisorReadEnum, metadata_advisor_flagged?: ListMessagesMetadataAdvisorFlaggedEnum, metadata_admin_read?: ListMessagesMetadataAdminReadEnum, metadata_admin_flagged?: ListMessagesMetadataAdminFlaggedEnum, category?: ListMessagesCategoryEnum, date?: string, start?: string, end?: string, tags?: Array<string>, asset_managers?: Array<string>, integrations?: Array<ListMessagesIntegrationsParameterInner>, exclude_integrations?: Array<ListMessagesExcludeIntegrationsParameterInner>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/messages/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_connections !== undefined) {
                localVarQueryParameter['populate_connections'] = populate_connections;
            }

            if (populate_advisory_groups !== undefined) {
                localVarQueryParameter['populate_advisory_groups'] = populate_advisory_groups;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (advisory_groups) {
                localVarQueryParameter['advisory_groups'] = advisory_groups;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (connections) {
                localVarQueryParameter['connections'] = connections;
            }

            if (connection_status !== undefined) {
                localVarQueryParameter['connection_status'] = connection_status;
            }

            if (connection_expects_documents !== undefined) {
                localVarQueryParameter['connection_expects_documents'] = connection_expects_documents;
            }

            if (message_ids) {
                localVarQueryParameter['message_ids'] = message_ids;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (has_attachments !== undefined) {
                localVarQueryParameter['has_attachments'] = has_attachments;
            }

            if (has_connection !== undefined) {
                localVarQueryParameter['has_connection'] = has_connection;
            }

            if (metadata_investor_read !== undefined) {
                localVarQueryParameter['metadata.investor.read'] = metadata_investor_read;
            }

            if (metadata_investor_flagged !== undefined) {
                localVarQueryParameter['metadata.investor.flagged'] = metadata_investor_flagged;
            }

            if (metadata_advisor_read !== undefined) {
                localVarQueryParameter['metadata.advisor.read'] = metadata_advisor_read;
            }

            if (metadata_advisor_flagged !== undefined) {
                localVarQueryParameter['metadata.advisor.flagged'] = metadata_advisor_flagged;
            }

            if (metadata_admin_read !== undefined) {
                localVarQueryParameter['metadata.admin.read'] = metadata_admin_read;
            }

            if (metadata_admin_flagged !== undefined) {
                localVarQueryParameter['metadata.admin.flagged'] = metadata_admin_flagged;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString().substring(0,10) :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString().substring(0,10) :
                    end;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (asset_managers) {
                localVarQueryParameter['asset_managers'] = asset_managers;
            }

            if (integrations) {
                localVarQueryParameter['integrations'] = integrations;
            }

            if (exclude_integrations) {
                localVarQueryParameter['exclude_integrations'] = exclude_integrations;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a Message document.
         * @param {string} message_id 
         * @param {UpdateMessageRequest} [UpdateMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessage: async (message_id: string, UpdateMessageRequest?: UpdateMessageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'message_id' is not null or undefined
            assertParamExists('updateMessage', 'message_id', message_id)
            const localVarPath = `/v2/messages/{message_id}`
                .replace(`{${"message_id"}}`, encodeURIComponent(String(message_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessagesApi - functional programming interface
 * @export
 */
export const MessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get a message by its ID
         * @param {string | null} message_id 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_connections] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageById(message_id: string | null, populate_account?: boolean, populate_user?: boolean, populate_connections?: boolean, populate_audit_log?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMessageById200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageById(message_id, populate_account, populate_user, populate_connections, populate_audit_log, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessagesApi.getMessageById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List message tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMessageTags(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnectionTags200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMessageTags(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessagesApi.listMessageTags']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get messages
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_connections] 
         * @param {boolean} [populate_advisory_groups] 
         * @param {ListMessagesSortFieldEnum} [sort_field] 
         * @param {ListMessagesSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [advisory_groups] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [connections] 
         * @param {ListMessagesConnectionStatusEnum} [connection_status] 
         * @param {boolean} [connection_expects_documents] 
         * @param {Array<string>} [message_ids] 
         * @param {string} [search] 
         * @param {ListMessagesHasAttachmentsEnum} [has_attachments] 
         * @param {ListMessagesHasConnectionEnum} [has_connection] 
         * @param {ListMessagesMetadataInvestorReadEnum} [metadata_investor_read] 
         * @param {ListMessagesMetadataInvestorFlaggedEnum} [metadata_investor_flagged] 
         * @param {ListMessagesMetadataAdvisorReadEnum} [metadata_advisor_read] 
         * @param {ListMessagesMetadataAdvisorFlaggedEnum} [metadata_advisor_flagged] 
         * @param {ListMessagesMetadataAdminReadEnum} [metadata_admin_read] 
         * @param {ListMessagesMetadataAdminFlaggedEnum} [metadata_admin_flagged] 
         * @param {ListMessagesCategoryEnum} [category] 
         * @param {string} [date] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {Array<string>} [tags] 
         * @param {Array<string>} [asset_managers] 
         * @param {Array<ListMessagesIntegrationsParameterInner>} [integrations] 
         * @param {Array<ListMessagesExcludeIntegrationsParameterInner>} [exclude_integrations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMessages(skip?: number, limit?: number, populate_user?: boolean, populate_connections?: boolean, populate_advisory_groups?: boolean, sort_field?: ListMessagesSortFieldEnum, sort_direction?: ListMessagesSortDirectionEnum, advisory_groups?: Array<string>, users?: Array<string>, connections?: Array<string>, connection_status?: ListMessagesConnectionStatusEnum, connection_expects_documents?: boolean, message_ids?: Array<string>, search?: string, has_attachments?: ListMessagesHasAttachmentsEnum, has_connection?: ListMessagesHasConnectionEnum, metadata_investor_read?: ListMessagesMetadataInvestorReadEnum, metadata_investor_flagged?: ListMessagesMetadataInvestorFlaggedEnum, metadata_advisor_read?: ListMessagesMetadataAdvisorReadEnum, metadata_advisor_flagged?: ListMessagesMetadataAdvisorFlaggedEnum, metadata_admin_read?: ListMessagesMetadataAdminReadEnum, metadata_admin_flagged?: ListMessagesMetadataAdminFlaggedEnum, category?: ListMessagesCategoryEnum, date?: string, start?: string, end?: string, tags?: Array<string>, asset_managers?: Array<string>, integrations?: Array<ListMessagesIntegrationsParameterInner>, exclude_integrations?: Array<ListMessagesExcludeIntegrationsParameterInner>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMessages200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMessages(skip, limit, populate_user, populate_connections, populate_advisory_groups, sort_field, sort_direction, advisory_groups, users, connections, connection_status, connection_expects_documents, message_ids, search, has_attachments, has_connection, metadata_investor_read, metadata_investor_flagged, metadata_advisor_read, metadata_advisor_flagged, metadata_admin_read, metadata_admin_flagged, category, date, start, end, tags, asset_managers, integrations, exclude_integrations, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessagesApi.listMessages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a Message document.
         * @param {string} message_id 
         * @param {UpdateMessageRequest} [UpdateMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMessage(message_id: string, UpdateMessageRequest?: UpdateMessageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateAssetManager200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMessage(message_id, UpdateMessageRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessagesApi.updateMessage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MessagesApi - factory interface
 * @export
 */
export const MessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessagesApiFp(configuration)
    return {
        /**
         * Get a message by its ID
         * @param {MessagesApiGetMessageByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageById(requestParameters: MessagesApiGetMessageByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetMessageById200Response> {
            return localVarFp.getMessageById(requestParameters.message_id, requestParameters.populate_account, requestParameters.populate_user, requestParameters.populate_connections, requestParameters.populate_audit_log, options).then((request) => request(axios, basePath));
        },
        /**
         * List message tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMessageTags(options?: RawAxiosRequestConfig): AxiosPromise<ListConnectionTags200Response> {
            return localVarFp.listMessageTags(options).then((request) => request(axios, basePath));
        },
        /**
         * Get messages
         * @param {MessagesApiListMessagesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMessages(requestParameters: MessagesApiListMessagesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListMessages200Response> {
            return localVarFp.listMessages(requestParameters.skip, requestParameters.limit, requestParameters.populate_user, requestParameters.populate_connections, requestParameters.populate_advisory_groups, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.advisory_groups, requestParameters.users, requestParameters.connections, requestParameters.connection_status, requestParameters.connection_expects_documents, requestParameters.message_ids, requestParameters.search, requestParameters.has_attachments, requestParameters.has_connection, requestParameters.metadata_investor_read, requestParameters.metadata_investor_flagged, requestParameters.metadata_advisor_read, requestParameters.metadata_advisor_flagged, requestParameters.metadata_admin_read, requestParameters.metadata_admin_flagged, requestParameters.category, requestParameters.date, requestParameters.start, requestParameters.end, requestParameters.tags, requestParameters.asset_managers, requestParameters.integrations, requestParameters.exclude_integrations, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a Message document.
         * @param {MessagesApiUpdateMessageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessage(requestParameters: MessagesApiUpdateMessageRequest, options?: RawAxiosRequestConfig): AxiosPromise<UpdateAssetManager200Response> {
            return localVarFp.updateMessage(requestParameters.message_id, requestParameters.UpdateMessageRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getMessageById operation in MessagesApi.
 * @export
 * @interface MessagesApiGetMessageByIdRequest
 */
export interface MessagesApiGetMessageByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof MessagesApiGetMessageById
     */
    readonly message_id: string | null

    /**
     * 
     * @type {boolean}
     * @memberof MessagesApiGetMessageById
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MessagesApiGetMessageById
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MessagesApiGetMessageById
     */
    readonly populate_connections?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MessagesApiGetMessageById
     */
    readonly populate_audit_log?: boolean
}

/**
 * Request parameters for listMessages operation in MessagesApi.
 * @export
 * @interface MessagesApiListMessagesRequest
 */
export interface MessagesApiListMessagesRequest {
    /**
     * 
     * @type {number}
     * @memberof MessagesApiListMessages
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof MessagesApiListMessages
     */
    readonly limit?: number

    /**
     * 
     * @type {boolean}
     * @memberof MessagesApiListMessages
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MessagesApiListMessages
     */
    readonly populate_connections?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MessagesApiListMessages
     */
    readonly populate_advisory_groups?: boolean

    /**
     * 
     * @type {'_id' | 'date' | 'updated_at' | 'created_at' | 'subject' | 'connections' | 'user' | 'from' | 'to' | 'metadata.admin.read' | 'metadata.admin.flagged' | 'metadata.advisor.read' | 'metadata.advisor.flagged' | 'metadata.investor.read' | 'metadata.investor.flagged' | 'completed'}
     * @memberof MessagesApiListMessages
     */
    readonly sort_field?: ListMessagesSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof MessagesApiListMessages
     */
    readonly sort_direction?: ListMessagesSortDirectionEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof MessagesApiListMessages
     */
    readonly advisory_groups?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof MessagesApiListMessages
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof MessagesApiListMessages
     */
    readonly connections?: Array<string>

    /**
     * 
     * @type {'Under Review' | 'Pending' | 'Importing' | 'Authenticating' | 'Syncing' | 'Active' | 'Error'}
     * @memberof MessagesApiListMessages
     */
    readonly connection_status?: ListMessagesConnectionStatusEnum

    /**
     * 
     * @type {boolean}
     * @memberof MessagesApiListMessages
     */
    readonly connection_expects_documents?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof MessagesApiListMessages
     */
    readonly message_ids?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof MessagesApiListMessages
     */
    readonly search?: string

    /**
     * 
     * @type {'true' | 'false'}
     * @memberof MessagesApiListMessages
     */
    readonly has_attachments?: ListMessagesHasAttachmentsEnum

    /**
     * 
     * @type {'true' | 'false'}
     * @memberof MessagesApiListMessages
     */
    readonly has_connection?: ListMessagesHasConnectionEnum

    /**
     * 
     * @type {'true' | 'false'}
     * @memberof MessagesApiListMessages
     */
    readonly metadata_investor_read?: ListMessagesMetadataInvestorReadEnum

    /**
     * 
     * @type {'true' | 'false'}
     * @memberof MessagesApiListMessages
     */
    readonly metadata_investor_flagged?: ListMessagesMetadataInvestorFlaggedEnum

    /**
     * 
     * @type {'true' | 'false'}
     * @memberof MessagesApiListMessages
     */
    readonly metadata_advisor_read?: ListMessagesMetadataAdvisorReadEnum

    /**
     * 
     * @type {'true' | 'false'}
     * @memberof MessagesApiListMessages
     */
    readonly metadata_advisor_flagged?: ListMessagesMetadataAdvisorFlaggedEnum

    /**
     * 
     * @type {'true' | 'false'}
     * @memberof MessagesApiListMessages
     */
    readonly metadata_admin_read?: ListMessagesMetadataAdminReadEnum

    /**
     * 
     * @type {'true' | 'false'}
     * @memberof MessagesApiListMessages
     */
    readonly metadata_admin_flagged?: ListMessagesMetadataAdminFlaggedEnum

    /**
     * 
     * @type {'null' | 'tfa' | 'portal' | 'deactivated'}
     * @memberof MessagesApiListMessages
     */
    readonly category?: ListMessagesCategoryEnum

    /**
     * 
     * @type {string}
     * @memberof MessagesApiListMessages
     */
    readonly date?: string

    /**
     * 
     * @type {string}
     * @memberof MessagesApiListMessages
     */
    readonly start?: string

    /**
     * 
     * @type {string}
     * @memberof MessagesApiListMessages
     */
    readonly end?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof MessagesApiListMessages
     */
    readonly tags?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof MessagesApiListMessages
     */
    readonly asset_managers?: Array<string>

    /**
     * 
     * @type {Array<ListMessagesIntegrationsParameterInner>}
     * @memberof MessagesApiListMessages
     */
    readonly integrations?: Array<ListMessagesIntegrationsParameterInner>

    /**
     * 
     * @type {Array<ListMessagesExcludeIntegrationsParameterInner>}
     * @memberof MessagesApiListMessages
     */
    readonly exclude_integrations?: Array<ListMessagesExcludeIntegrationsParameterInner>
}

/**
 * Request parameters for updateMessage operation in MessagesApi.
 * @export
 * @interface MessagesApiUpdateMessageRequest
 */
export interface MessagesApiUpdateMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof MessagesApiUpdateMessage
     */
    readonly message_id: string

    /**
     * 
     * @type {UpdateMessageRequest}
     * @memberof MessagesApiUpdateMessage
     */
    readonly UpdateMessageRequest?: UpdateMessageRequest
}

/**
 * MessagesApi - object-oriented interface
 * @export
 * @class MessagesApi
 * @extends {BaseAPI}
 */
export class MessagesApi extends BaseAPI {
    /**
     * Get a message by its ID
     * @param {MessagesApiGetMessageByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public getMessageById(requestParameters: MessagesApiGetMessageByIdRequest, options?: RawAxiosRequestConfig) {
        return MessagesApiFp(this.configuration).getMessageById(requestParameters.message_id, requestParameters.populate_account, requestParameters.populate_user, requestParameters.populate_connections, requestParameters.populate_audit_log, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List message tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public listMessageTags(options?: RawAxiosRequestConfig) {
        return MessagesApiFp(this.configuration).listMessageTags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get messages
     * @param {MessagesApiListMessagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public listMessages(requestParameters: MessagesApiListMessagesRequest = {}, options?: RawAxiosRequestConfig) {
        return MessagesApiFp(this.configuration).listMessages(requestParameters.skip, requestParameters.limit, requestParameters.populate_user, requestParameters.populate_connections, requestParameters.populate_advisory_groups, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.advisory_groups, requestParameters.users, requestParameters.connections, requestParameters.connection_status, requestParameters.connection_expects_documents, requestParameters.message_ids, requestParameters.search, requestParameters.has_attachments, requestParameters.has_connection, requestParameters.metadata_investor_read, requestParameters.metadata_investor_flagged, requestParameters.metadata_advisor_read, requestParameters.metadata_advisor_flagged, requestParameters.metadata_admin_read, requestParameters.metadata_admin_flagged, requestParameters.category, requestParameters.date, requestParameters.start, requestParameters.end, requestParameters.tags, requestParameters.asset_managers, requestParameters.integrations, requestParameters.exclude_integrations, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a Message document.
     * @param {MessagesApiUpdateMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public updateMessage(requestParameters: MessagesApiUpdateMessageRequest, options?: RawAxiosRequestConfig) {
        return MessagesApiFp(this.configuration).updateMessage(requestParameters.message_id, requestParameters.UpdateMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListMessagesSortFieldEnum = {
    Id: '_id',
    Date: 'date',
    UpdatedAt: 'updated_at',
    CreatedAt: 'created_at',
    Subject: 'subject',
    Connections: 'connections',
    User: 'user',
    From: 'from',
    To: 'to',
    MetadataAdminRead: 'metadata.admin.read',
    MetadataAdminFlagged: 'metadata.admin.flagged',
    MetadataAdvisorRead: 'metadata.advisor.read',
    MetadataAdvisorFlagged: 'metadata.advisor.flagged',
    MetadataInvestorRead: 'metadata.investor.read',
    MetadataInvestorFlagged: 'metadata.investor.flagged',
    Completed: 'completed'
} as const;
export type ListMessagesSortFieldEnum = typeof ListMessagesSortFieldEnum[keyof typeof ListMessagesSortFieldEnum];
/**
 * @export
 */
export const ListMessagesSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListMessagesSortDirectionEnum = typeof ListMessagesSortDirectionEnum[keyof typeof ListMessagesSortDirectionEnum];
/**
 * @export
 */
export const ListMessagesConnectionStatusEnum = {
    UnderReview: 'Under Review',
    Pending: 'Pending',
    Importing: 'Importing',
    Authenticating: 'Authenticating',
    Syncing: 'Syncing',
    Active: 'Active',
    Error: 'Error'
} as const;
export type ListMessagesConnectionStatusEnum = typeof ListMessagesConnectionStatusEnum[keyof typeof ListMessagesConnectionStatusEnum];
/**
 * @export
 */
export const ListMessagesHasAttachmentsEnum = {
    True: 'true',
    False: 'false'
} as const;
export type ListMessagesHasAttachmentsEnum = typeof ListMessagesHasAttachmentsEnum[keyof typeof ListMessagesHasAttachmentsEnum];
/**
 * @export
 */
export const ListMessagesHasConnectionEnum = {
    True: 'true',
    False: 'false'
} as const;
export type ListMessagesHasConnectionEnum = typeof ListMessagesHasConnectionEnum[keyof typeof ListMessagesHasConnectionEnum];
/**
 * @export
 */
export const ListMessagesMetadataInvestorReadEnum = {
    True: 'true',
    False: 'false'
} as const;
export type ListMessagesMetadataInvestorReadEnum = typeof ListMessagesMetadataInvestorReadEnum[keyof typeof ListMessagesMetadataInvestorReadEnum];
/**
 * @export
 */
export const ListMessagesMetadataInvestorFlaggedEnum = {
    True: 'true',
    False: 'false'
} as const;
export type ListMessagesMetadataInvestorFlaggedEnum = typeof ListMessagesMetadataInvestorFlaggedEnum[keyof typeof ListMessagesMetadataInvestorFlaggedEnum];
/**
 * @export
 */
export const ListMessagesMetadataAdvisorReadEnum = {
    True: 'true',
    False: 'false'
} as const;
export type ListMessagesMetadataAdvisorReadEnum = typeof ListMessagesMetadataAdvisorReadEnum[keyof typeof ListMessagesMetadataAdvisorReadEnum];
/**
 * @export
 */
export const ListMessagesMetadataAdvisorFlaggedEnum = {
    True: 'true',
    False: 'false'
} as const;
export type ListMessagesMetadataAdvisorFlaggedEnum = typeof ListMessagesMetadataAdvisorFlaggedEnum[keyof typeof ListMessagesMetadataAdvisorFlaggedEnum];
/**
 * @export
 */
export const ListMessagesMetadataAdminReadEnum = {
    True: 'true',
    False: 'false'
} as const;
export type ListMessagesMetadataAdminReadEnum = typeof ListMessagesMetadataAdminReadEnum[keyof typeof ListMessagesMetadataAdminReadEnum];
/**
 * @export
 */
export const ListMessagesMetadataAdminFlaggedEnum = {
    True: 'true',
    False: 'false'
} as const;
export type ListMessagesMetadataAdminFlaggedEnum = typeof ListMessagesMetadataAdminFlaggedEnum[keyof typeof ListMessagesMetadataAdminFlaggedEnum];
/**
 * @export
 */
export const ListMessagesCategoryEnum = {
    Null: 'null',
    Tfa: 'tfa',
    Portal: 'portal',
    Deactivated: 'deactivated'
} as const;
export type ListMessagesCategoryEnum = typeof ListMessagesCategoryEnum[keyof typeof ListMessagesCategoryEnum];


/**
 * NoteApi - axios parameter creator
 * @export
 */
export const NoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Note document.
         * @param {CreateNoteRequest} CreateNoteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNote: async (CreateNoteRequest: CreateNoteRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateNoteRequest' is not null or undefined
            assertParamExists('createNote', 'CreateNoteRequest', CreateNoteRequest)
            const localVarPath = `/v2/notes/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateNoteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Note document.
         * @param {string} note_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote: async (note_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'note_id' is not null or undefined
            assertParamExists('deleteNote', 'note_id', note_id)
            const localVarPath = `/v2/notes/{note_id}`
                .replace(`{${"note_id"}}`, encodeURIComponent(String(note_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single Note document by ID.
         * @param {string | null} note_id 
         * @param {boolean} [populate_editor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNote: async (note_id: string | null, populate_editor?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'note_id' is not null or undefined
            assertParamExists('getNote', 'note_id', note_id)
            const localVarPath = `/v2/notes/{note_id}`
                .replace(`{${"note_id"}}`, encodeURIComponent(String(note_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_editor !== undefined) {
                localVarQueryParameter['populate_editor'] = populate_editor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Note documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListNotesSortFieldEnum} [sort_field] 
         * @param {ListNotesSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [investments] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [documents] 
         * @param {Array<string>} [transactions] 
         * @param {ListNotesTypeEnum} [type] 
         * @param {Array<string>} [admin_audit_results] 
         * @param {Array<string>} [admin_asset_managers] 
         * @param {Array<string>} [admin_investment_masters] 
         * @param {string} [admin_document_type] 
         * @param {Array<string>} [admin_investments] 
         * @param {string | null} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotes: async (skip?: number, limit?: number, search?: string, sort_field?: ListNotesSortFieldEnum, sort_direction?: ListNotesSortDirectionEnum, users?: Array<string>, investments?: Array<string>, accounts?: Array<string>, documents?: Array<string>, transactions?: Array<string>, type?: ListNotesTypeEnum, admin_audit_results?: Array<string>, admin_asset_managers?: Array<string>, admin_investment_masters?: Array<string>, admin_document_type?: string, admin_investments?: Array<string>, userId?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/notes/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (investments) {
                localVarQueryParameter['investments'] = investments;
            }

            if (accounts) {
                localVarQueryParameter['accounts'] = accounts;
            }

            if (documents) {
                localVarQueryParameter['documents'] = documents;
            }

            if (transactions) {
                localVarQueryParameter['transactions'] = transactions;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (admin_audit_results) {
                localVarQueryParameter['admin_audit_results'] = admin_audit_results;
            }

            if (admin_asset_managers) {
                localVarQueryParameter['admin_asset_managers'] = admin_asset_managers;
            }

            if (admin_investment_masters) {
                localVarQueryParameter['admin_investment_masters'] = admin_investment_masters;
            }

            if (admin_document_type !== undefined) {
                localVarQueryParameter['admin_document_type'] = admin_document_type;
            }

            if (admin_investments) {
                localVarQueryParameter['admin_investments'] = admin_investments;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Note document.
         * @param {string} note_id 
         * @param {UpdateNoteRequest} [UpdateNoteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote: async (note_id: string, UpdateNoteRequest?: UpdateNoteRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'note_id' is not null or undefined
            assertParamExists('updateNote', 'note_id', note_id)
            const localVarPath = `/v2/notes/{note_id}`
                .replace(`{${"note_id"}}`, encodeURIComponent(String(note_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateNoteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoteApi - functional programming interface
 * @export
 */
export const NoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoteApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Note document.
         * @param {CreateNoteRequest} CreateNoteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNote(CreateNoteRequest: CreateNoteRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateNote201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNote(CreateNoteRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteApi.createNote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a Note document.
         * @param {string} note_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNote(note_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateAssetManager200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNote(note_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteApi.deleteNote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single Note document by ID.
         * @param {string | null} note_id 
         * @param {boolean} [populate_editor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNote(note_id: string | null, populate_editor?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNote200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNote(note_id, populate_editor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteApi.getNote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Note documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListNotesSortFieldEnum} [sort_field] 
         * @param {ListNotesSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [investments] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [documents] 
         * @param {Array<string>} [transactions] 
         * @param {ListNotesTypeEnum} [type] 
         * @param {Array<string>} [admin_audit_results] 
         * @param {Array<string>} [admin_asset_managers] 
         * @param {Array<string>} [admin_investment_masters] 
         * @param {string} [admin_document_type] 
         * @param {Array<string>} [admin_investments] 
         * @param {string | null} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNotes(skip?: number, limit?: number, search?: string, sort_field?: ListNotesSortFieldEnum, sort_direction?: ListNotesSortDirectionEnum, users?: Array<string>, investments?: Array<string>, accounts?: Array<string>, documents?: Array<string>, transactions?: Array<string>, type?: ListNotesTypeEnum, admin_audit_results?: Array<string>, admin_asset_managers?: Array<string>, admin_investment_masters?: Array<string>, admin_document_type?: string, admin_investments?: Array<string>, userId?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListNotes200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNotes(skip, limit, search, sort_field, sort_direction, users, investments, accounts, documents, transactions, type, admin_audit_results, admin_asset_managers, admin_investment_masters, admin_document_type, admin_investments, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteApi.listNotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing Note document.
         * @param {string} note_id 
         * @param {UpdateNoteRequest} [UpdateNoteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNote(note_id: string, UpdateNoteRequest?: UpdateNoteRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateAssetManager200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNote(note_id, UpdateNoteRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteApi.updateNote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NoteApi - factory interface
 * @export
 */
export const NoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoteApiFp(configuration)
    return {
        /**
         * Create a new Note document.
         * @param {NoteApiCreateNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNote(requestParameters: NoteApiCreateNoteRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateNote201Response> {
            return localVarFp.createNote(requestParameters.CreateNoteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Note document.
         * @param {NoteApiDeleteNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote(requestParameters: NoteApiDeleteNoteRequest, options?: RawAxiosRequestConfig): AxiosPromise<UpdateAssetManager200Response> {
            return localVarFp.deleteNote(requestParameters.note_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single Note document by ID.
         * @param {NoteApiGetNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNote(requestParameters: NoteApiGetNoteRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetNote200Response> {
            return localVarFp.getNote(requestParameters.note_id, requestParameters.populate_editor, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Note documents.
         * @param {NoteApiListNotesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotes(requestParameters: NoteApiListNotesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListNotes200Response> {
            return localVarFp.listNotes(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.investments, requestParameters.accounts, requestParameters.documents, requestParameters.transactions, requestParameters.type, requestParameters.admin_audit_results, requestParameters.admin_asset_managers, requestParameters.admin_investment_masters, requestParameters.admin_document_type, requestParameters.admin_investments, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Note document.
         * @param {NoteApiUpdateNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote(requestParameters: NoteApiUpdateNoteRequest, options?: RawAxiosRequestConfig): AxiosPromise<UpdateAssetManager200Response> {
            return localVarFp.updateNote(requestParameters.note_id, requestParameters.UpdateNoteRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createNote operation in NoteApi.
 * @export
 * @interface NoteApiCreateNoteRequest
 */
export interface NoteApiCreateNoteRequest {
    /**
     * 
     * @type {CreateNoteRequest}
     * @memberof NoteApiCreateNote
     */
    readonly CreateNoteRequest: CreateNoteRequest
}

/**
 * Request parameters for deleteNote operation in NoteApi.
 * @export
 * @interface NoteApiDeleteNoteRequest
 */
export interface NoteApiDeleteNoteRequest {
    /**
     * 
     * @type {string}
     * @memberof NoteApiDeleteNote
     */
    readonly note_id: string
}

/**
 * Request parameters for getNote operation in NoteApi.
 * @export
 * @interface NoteApiGetNoteRequest
 */
export interface NoteApiGetNoteRequest {
    /**
     * 
     * @type {string}
     * @memberof NoteApiGetNote
     */
    readonly note_id: string | null

    /**
     * 
     * @type {boolean}
     * @memberof NoteApiGetNote
     */
    readonly populate_editor?: boolean
}

/**
 * Request parameters for listNotes operation in NoteApi.
 * @export
 * @interface NoteApiListNotesRequest
 */
export interface NoteApiListNotesRequest {
    /**
     * 
     * @type {number}
     * @memberof NoteApiListNotes
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof NoteApiListNotes
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof NoteApiListNotes
     */
    readonly search?: string

    /**
     * 
     * @type {'date' | 'created_at' | 'updated_at' | 'type'}
     * @memberof NoteApiListNotes
     */
    readonly sort_field?: ListNotesSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof NoteApiListNotes
     */
    readonly sort_direction?: ListNotesSortDirectionEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof NoteApiListNotes
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof NoteApiListNotes
     */
    readonly investments?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof NoteApiListNotes
     */
    readonly accounts?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof NoteApiListNotes
     */
    readonly documents?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof NoteApiListNotes
     */
    readonly transactions?: Array<string>

    /**
     * 
     * @type {'transaction' | 'investment' | 'account' | 'user' | 'document' | 'auditResult' | 'assetManager' | 'investmentMaster' | 'documentType' | 'adminInvestment' | 'audit_result' | 'asset_manager' | 'investment_master' | 'document_type' | 'admin_audit_result' | 'admin_asset_manager' | 'admin_investment_master' | 'admin_document_type' | 'admin_investment'}
     * @memberof NoteApiListNotes
     */
    readonly type?: ListNotesTypeEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof NoteApiListNotes
     */
    readonly admin_audit_results?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof NoteApiListNotes
     */
    readonly admin_asset_managers?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof NoteApiListNotes
     */
    readonly admin_investment_masters?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof NoteApiListNotes
     */
    readonly admin_document_type?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof NoteApiListNotes
     */
    readonly admin_investments?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof NoteApiListNotes
     */
    readonly userId?: string | null
}

/**
 * Request parameters for updateNote operation in NoteApi.
 * @export
 * @interface NoteApiUpdateNoteRequest
 */
export interface NoteApiUpdateNoteRequest {
    /**
     * 
     * @type {string}
     * @memberof NoteApiUpdateNote
     */
    readonly note_id: string

    /**
     * 
     * @type {UpdateNoteRequest}
     * @memberof NoteApiUpdateNote
     */
    readonly UpdateNoteRequest?: UpdateNoteRequest
}

/**
 * NoteApi - object-oriented interface
 * @export
 * @class NoteApi
 * @extends {BaseAPI}
 */
export class NoteApi extends BaseAPI {
    /**
     * Create a new Note document.
     * @param {NoteApiCreateNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public createNote(requestParameters: NoteApiCreateNoteRequest, options?: RawAxiosRequestConfig) {
        return NoteApiFp(this.configuration).createNote(requestParameters.CreateNoteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Note document.
     * @param {NoteApiDeleteNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public deleteNote(requestParameters: NoteApiDeleteNoteRequest, options?: RawAxiosRequestConfig) {
        return NoteApiFp(this.configuration).deleteNote(requestParameters.note_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single Note document by ID.
     * @param {NoteApiGetNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public getNote(requestParameters: NoteApiGetNoteRequest, options?: RawAxiosRequestConfig) {
        return NoteApiFp(this.configuration).getNote(requestParameters.note_id, requestParameters.populate_editor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Note documents.
     * @param {NoteApiListNotesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public listNotes(requestParameters: NoteApiListNotesRequest = {}, options?: RawAxiosRequestConfig) {
        return NoteApiFp(this.configuration).listNotes(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.investments, requestParameters.accounts, requestParameters.documents, requestParameters.transactions, requestParameters.type, requestParameters.admin_audit_results, requestParameters.admin_asset_managers, requestParameters.admin_investment_masters, requestParameters.admin_document_type, requestParameters.admin_investments, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Note document.
     * @param {NoteApiUpdateNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public updateNote(requestParameters: NoteApiUpdateNoteRequest, options?: RawAxiosRequestConfig) {
        return NoteApiFp(this.configuration).updateNote(requestParameters.note_id, requestParameters.UpdateNoteRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListNotesSortFieldEnum = {
    Date: 'date',
    CreatedAt: 'created_at',
    UpdatedAt: 'updated_at',
    Type: 'type'
} as const;
export type ListNotesSortFieldEnum = typeof ListNotesSortFieldEnum[keyof typeof ListNotesSortFieldEnum];
/**
 * @export
 */
export const ListNotesSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListNotesSortDirectionEnum = typeof ListNotesSortDirectionEnum[keyof typeof ListNotesSortDirectionEnum];
/**
 * @export
 */
export const ListNotesTypeEnum = {
    Transaction: 'transaction',
    Investment: 'investment',
    Account: 'account',
    User: 'user',
    Document: 'document',
    AuditResult: 'auditResult',
    AssetManager: 'assetManager',
    InvestmentMaster: 'investmentMaster',
    DocumentType: 'documentType',
    AdminInvestment: 'adminInvestment',
    AuditResult2: 'audit_result',
    AssetManager2: 'asset_manager',
    InvestmentMaster2: 'investment_master',
    DocumentType2: 'document_type',
    AdminAuditResult: 'admin_audit_result',
    AdminAssetManager: 'admin_asset_manager',
    AdminInvestmentMaster: 'admin_investment_master',
    AdminDocumentType: 'admin_document_type',
    AdminInvestment2: 'admin_investment'
} as const;
export type ListNotesTypeEnum = typeof ListNotesTypeEnum[keyof typeof ListNotesTypeEnum];


/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Send a notification to a user.
         * @param {SendNotificationRequest} SendNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification: async (SendNotificationRequest: SendNotificationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'SendNotificationRequest' is not null or undefined
            assertParamExists('sendNotification', 'SendNotificationRequest', SendNotificationRequest)
            const localVarPath = `/v2/notifications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(SendNotificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Send a notification to a user.
         * @param {SendNotificationRequest} SendNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNotification(SendNotificationRequest: SendNotificationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendNotification200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNotification(SendNotificationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.sendNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * Send a notification to a user.
         * @param {NotificationsApiSendNotificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification(requestParameters: NotificationsApiSendNotificationRequest, options?: RawAxiosRequestConfig): AxiosPromise<SendNotification200Response> {
            return localVarFp.sendNotification(requestParameters.SendNotificationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sendNotification operation in NotificationsApi.
 * @export
 * @interface NotificationsApiSendNotificationRequest
 */
export interface NotificationsApiSendNotificationRequest {
    /**
     * 
     * @type {SendNotificationRequest}
     * @memberof NotificationsApiSendNotification
     */
    readonly SendNotificationRequest: SendNotificationRequest
}

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * Send a notification to a user.
     * @param {NotificationsApiSendNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public sendNotification(requestParameters: NotificationsApiSendNotificationRequest, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).sendNotification(requestParameters.SendNotificationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RALRequestApi - axios parameter creator
 * @export
 */
export const RALRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new RAL Request.
         * @param {CreateRALRequestRequest} CreateRALRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRALRequest: async (CreateRALRequestRequest: CreateRALRequestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateRALRequestRequest' is not null or undefined
            assertParamExists('createRALRequest', 'CreateRALRequestRequest', CreateRALRequestRequest)
            const localVarPath = `/v2/ralrequests/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateRALRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a RAL Request document.
         * @param {string} ral_request_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRALRequest: async (ral_request_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ral_request_id' is not null or undefined
            assertParamExists('deleteRALRequest', 'ral_request_id', ral_request_id)
            const localVarPath = `/v2/ralrequests/{ral_request_id}`
                .replace(`{${"ral_request_id"}}`, encodeURIComponent(String(ral_request_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate PandaDoc signing URLs for RAL documents.
         * @param {Array<string>} ral_request_ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePandaDocSigningUrls: async (ral_request_ids: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ral_request_ids' is not null or undefined
            assertParamExists('generatePandaDocSigningUrls', 'ral_request_ids', ral_request_ids)
            const localVarPath = `/v2/ralrequests/generate-panda-doc-signing-urls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ral_request_ids) {
                localVarQueryParameter['ral_request_ids'] = ral_request_ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single RAL Request document by ID.
         * @param {string | null} ral_request_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_signed_ral] 
         * @param {boolean} [populate_ral_template] 
         * @param {boolean} [populate_ownership] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRALRequest: async (ral_request_id: string | null, populate_user?: boolean, populate_signed_ral?: boolean, populate_ral_template?: boolean, populate_ownership?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ral_request_id' is not null or undefined
            assertParamExists('getRALRequest', 'ral_request_id', ral_request_id)
            const localVarPath = `/v2/ralrequests/{ral_request_id}`
                .replace(`{${"ral_request_id"}}`, encodeURIComponent(String(ral_request_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_signed_ral !== undefined) {
                localVarQueryParameter['populate_signed_ral'] = populate_signed_ral;
            }

            if (populate_ral_template !== undefined) {
                localVarQueryParameter['populate_ral_template'] = populate_ral_template;
            }

            if (populate_ownership !== undefined) {
                localVarQueryParameter['populate_ownership'] = populate_ownership;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through RAL Request documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListRALRequestsSortFieldEnum} [sort_field] 
         * @param {ListRALRequestsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [templates] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [ownership_ids] 
         * @param {ListRALRequestsOwnershipTypeEnum} [ownership_type] 
         * @param {Array<string>} [advisory_groups] 
         * @param {Array<string>} [statuses] 
         * @param {boolean} [sent_at] 
         * @param {boolean} [signed_at] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_user_advisory_group] 
         * @param {boolean} [populate_signed_ral] 
         * @param {boolean} [populate_ral_template] 
         * @param {boolean} [populate_ownership] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRALRequests: async (skip?: number, limit?: number, sort_field?: ListRALRequestsSortFieldEnum, sort_direction?: ListRALRequestsSortDirectionEnum, templates?: Array<string>, users?: Array<string>, ownership_ids?: Array<string>, ownership_type?: ListRALRequestsOwnershipTypeEnum, advisory_groups?: Array<string>, statuses?: Array<string>, sent_at?: boolean, signed_at?: boolean, populate_user?: boolean, populate_user_advisory_group?: boolean, populate_signed_ral?: boolean, populate_ral_template?: boolean, populate_ownership?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/ralrequests/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (templates) {
                localVarQueryParameter['templates'] = templates;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (ownership_ids) {
                localVarQueryParameter['ownership_ids'] = ownership_ids;
            }

            if (ownership_type !== undefined) {
                localVarQueryParameter['ownership_type'] = ownership_type;
            }

            if (advisory_groups) {
                localVarQueryParameter['advisory_groups'] = advisory_groups;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (sent_at !== undefined) {
                localVarQueryParameter['sent_at'] = sent_at;
            }

            if (signed_at !== undefined) {
                localVarQueryParameter['signed_at'] = signed_at;
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_user_advisory_group !== undefined) {
                localVarQueryParameter['populate_user_advisory_group'] = populate_user_advisory_group;
            }

            if (populate_signed_ral !== undefined) {
                localVarQueryParameter['populate_signed_ral'] = populate_signed_ral;
            }

            if (populate_ral_template !== undefined) {
                localVarQueryParameter['populate_ral_template'] = populate_ral_template;
            }

            if (populate_ownership !== undefined) {
                localVarQueryParameter['populate_ownership'] = populate_ownership;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create and send RAL documents via PandaDoc or DocuSign.
         * @param {SendRALRequestRequest} SendRALRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRALRequest: async (SendRALRequestRequest: SendRALRequestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'SendRALRequestRequest' is not null or undefined
            assertParamExists('sendRALRequest', 'SendRALRequestRequest', SendRALRequestRequest)
            const localVarPath = `/v2/ralrequests/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(SendRALRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing RAL Request document.
         * @param {string} ral_request_id 
         * @param {UpdateRALRequestRequest} [UpdateRALRequestRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRALRequest: async (ral_request_id: string, UpdateRALRequestRequest?: UpdateRALRequestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ral_request_id' is not null or undefined
            assertParamExists('updateRALRequest', 'ral_request_id', ral_request_id)
            const localVarPath = `/v2/ralrequests/{ral_request_id}`
                .replace(`{${"ral_request_id"}}`, encodeURIComponent(String(ral_request_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateRALRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RALRequestApi - functional programming interface
 * @export
 */
export const RALRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RALRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new RAL Request.
         * @param {CreateRALRequestRequest} CreateRALRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRALRequest(CreateRALRequestRequest: CreateRALRequestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateRALRequest201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRALRequest(CreateRALRequestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RALRequestApi.createRALRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a RAL Request document.
         * @param {string} ral_request_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRALRequest(ral_request_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRALRequest(ral_request_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RALRequestApi.deleteRALRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Generate PandaDoc signing URLs for RAL documents.
         * @param {Array<string>} ral_request_ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generatePandaDocSigningUrls(ral_request_ids: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratePandaDocSigningUrls200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generatePandaDocSigningUrls(ral_request_ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RALRequestApi.generatePandaDocSigningUrls']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single RAL Request document by ID.
         * @param {string | null} ral_request_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_signed_ral] 
         * @param {boolean} [populate_ral_template] 
         * @param {boolean} [populate_ownership] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRALRequest(ral_request_id: string | null, populate_user?: boolean, populate_signed_ral?: boolean, populate_ral_template?: boolean, populate_ownership?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRALRequest200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRALRequest(ral_request_id, populate_user, populate_signed_ral, populate_ral_template, populate_ownership, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RALRequestApi.getRALRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through RAL Request documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListRALRequestsSortFieldEnum} [sort_field] 
         * @param {ListRALRequestsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [templates] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [ownership_ids] 
         * @param {ListRALRequestsOwnershipTypeEnum} [ownership_type] 
         * @param {Array<string>} [advisory_groups] 
         * @param {Array<string>} [statuses] 
         * @param {boolean} [sent_at] 
         * @param {boolean} [signed_at] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_user_advisory_group] 
         * @param {boolean} [populate_signed_ral] 
         * @param {boolean} [populate_ral_template] 
         * @param {boolean} [populate_ownership] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRALRequests(skip?: number, limit?: number, sort_field?: ListRALRequestsSortFieldEnum, sort_direction?: ListRALRequestsSortDirectionEnum, templates?: Array<string>, users?: Array<string>, ownership_ids?: Array<string>, ownership_type?: ListRALRequestsOwnershipTypeEnum, advisory_groups?: Array<string>, statuses?: Array<string>, sent_at?: boolean, signed_at?: boolean, populate_user?: boolean, populate_user_advisory_group?: boolean, populate_signed_ral?: boolean, populate_ral_template?: boolean, populate_ownership?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRALRequests200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRALRequests(skip, limit, sort_field, sort_direction, templates, users, ownership_ids, ownership_type, advisory_groups, statuses, sent_at, signed_at, populate_user, populate_user_advisory_group, populate_signed_ral, populate_ral_template, populate_ownership, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RALRequestApi.listRALRequests']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create and send RAL documents via PandaDoc or DocuSign.
         * @param {SendRALRequestRequest} SendRALRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendRALRequest(SendRALRequestRequest: SendRALRequestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResolveAuditResult200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendRALRequest(SendRALRequestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RALRequestApi.sendRALRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing RAL Request document.
         * @param {string} ral_request_id 
         * @param {UpdateRALRequestRequest} [UpdateRALRequestRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRALRequest(ral_request_id: string, UpdateRALRequestRequest?: UpdateRALRequestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRALRequest(ral_request_id, UpdateRALRequestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RALRequestApi.updateRALRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RALRequestApi - factory interface
 * @export
 */
export const RALRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RALRequestApiFp(configuration)
    return {
        /**
         * Create a new RAL Request.
         * @param {RALRequestApiCreateRALRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRALRequest(requestParameters: RALRequestApiCreateRALRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateRALRequest201Response> {
            return localVarFp.createRALRequest(requestParameters.CreateRALRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a RAL Request document.
         * @param {RALRequestApiDeleteRALRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRALRequest(requestParameters: RALRequestApiDeleteRALRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteRALRequest(requestParameters.ral_request_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate PandaDoc signing URLs for RAL documents.
         * @param {RALRequestApiGeneratePandaDocSigningUrlsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePandaDocSigningUrls(requestParameters: RALRequestApiGeneratePandaDocSigningUrlsRequest, options?: RawAxiosRequestConfig): AxiosPromise<GeneratePandaDocSigningUrls200Response> {
            return localVarFp.generatePandaDocSigningUrls(requestParameters.ral_request_ids, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single RAL Request document by ID.
         * @param {RALRequestApiGetRALRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRALRequest(requestParameters: RALRequestApiGetRALRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetRALRequest200Response> {
            return localVarFp.getRALRequest(requestParameters.ral_request_id, requestParameters.populate_user, requestParameters.populate_signed_ral, requestParameters.populate_ral_template, requestParameters.populate_ownership, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through RAL Request documents.
         * @param {RALRequestApiListRALRequestsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRALRequests(requestParameters: RALRequestApiListRALRequestsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListRALRequests200Response> {
            return localVarFp.listRALRequests(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.templates, requestParameters.users, requestParameters.ownership_ids, requestParameters.ownership_type, requestParameters.advisory_groups, requestParameters.statuses, requestParameters.sent_at, requestParameters.signed_at, requestParameters.populate_user, requestParameters.populate_user_advisory_group, requestParameters.populate_signed_ral, requestParameters.populate_ral_template, requestParameters.populate_ownership, options).then((request) => request(axios, basePath));
        },
        /**
         * Create and send RAL documents via PandaDoc or DocuSign.
         * @param {RALRequestApiSendRALRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRALRequest(requestParameters: RALRequestApiSendRALRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResolveAuditResult200Response> {
            return localVarFp.sendRALRequest(requestParameters.SendRALRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing RAL Request document.
         * @param {RALRequestApiUpdateRALRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRALRequest(requestParameters: RALRequestApiUpdateRALRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateRALRequest(requestParameters.ral_request_id, requestParameters.UpdateRALRequestRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createRALRequest operation in RALRequestApi.
 * @export
 * @interface RALRequestApiCreateRALRequestRequest
 */
export interface RALRequestApiCreateRALRequestRequest {
    /**
     * 
     * @type {CreateRALRequestRequest}
     * @memberof RALRequestApiCreateRALRequest
     */
    readonly CreateRALRequestRequest: CreateRALRequestRequest
}

/**
 * Request parameters for deleteRALRequest operation in RALRequestApi.
 * @export
 * @interface RALRequestApiDeleteRALRequestRequest
 */
export interface RALRequestApiDeleteRALRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof RALRequestApiDeleteRALRequest
     */
    readonly ral_request_id: string
}

/**
 * Request parameters for generatePandaDocSigningUrls operation in RALRequestApi.
 * @export
 * @interface RALRequestApiGeneratePandaDocSigningUrlsRequest
 */
export interface RALRequestApiGeneratePandaDocSigningUrlsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof RALRequestApiGeneratePandaDocSigningUrls
     */
    readonly ral_request_ids: Array<string>
}

/**
 * Request parameters for getRALRequest operation in RALRequestApi.
 * @export
 * @interface RALRequestApiGetRALRequestRequest
 */
export interface RALRequestApiGetRALRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof RALRequestApiGetRALRequest
     */
    readonly ral_request_id: string | null

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiGetRALRequest
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiGetRALRequest
     */
    readonly populate_signed_ral?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiGetRALRequest
     */
    readonly populate_ral_template?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiGetRALRequest
     */
    readonly populate_ownership?: boolean
}

/**
 * Request parameters for listRALRequests operation in RALRequestApi.
 * @export
 * @interface RALRequestApiListRALRequestsRequest
 */
export interface RALRequestApiListRALRequestsRequest {
    /**
     * 
     * @type {number}
     * @memberof RALRequestApiListRALRequests
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof RALRequestApiListRALRequests
     */
    readonly limit?: number

    /**
     * 
     * @type {'sent_at' | 'status' | 'signed_at' | 'remote_status' | 'created_at' | 'updated_at' | 'ownership_type'}
     * @memberof RALRequestApiListRALRequests
     */
    readonly sort_field?: ListRALRequestsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof RALRequestApiListRALRequests
     */
    readonly sort_direction?: ListRALRequestsSortDirectionEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof RALRequestApiListRALRequests
     */
    readonly templates?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof RALRequestApiListRALRequests
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof RALRequestApiListRALRequests
     */
    readonly ownership_ids?: Array<string>

    /**
     * 
     * @type {'Account' | 'Investment' | 'null'}
     * @memberof RALRequestApiListRALRequests
     */
    readonly ownership_type?: ListRALRequestsOwnershipTypeEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof RALRequestApiListRALRequests
     */
    readonly advisory_groups?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof RALRequestApiListRALRequests
     */
    readonly statuses?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiListRALRequests
     */
    readonly sent_at?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiListRALRequests
     */
    readonly signed_at?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiListRALRequests
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiListRALRequests
     */
    readonly populate_user_advisory_group?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiListRALRequests
     */
    readonly populate_signed_ral?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiListRALRequests
     */
    readonly populate_ral_template?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiListRALRequests
     */
    readonly populate_ownership?: boolean
}

/**
 * Request parameters for sendRALRequest operation in RALRequestApi.
 * @export
 * @interface RALRequestApiSendRALRequestRequest
 */
export interface RALRequestApiSendRALRequestRequest {
    /**
     * 
     * @type {SendRALRequestRequest}
     * @memberof RALRequestApiSendRALRequest
     */
    readonly SendRALRequestRequest: SendRALRequestRequest
}

/**
 * Request parameters for updateRALRequest operation in RALRequestApi.
 * @export
 * @interface RALRequestApiUpdateRALRequestRequest
 */
export interface RALRequestApiUpdateRALRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof RALRequestApiUpdateRALRequest
     */
    readonly ral_request_id: string

    /**
     * 
     * @type {UpdateRALRequestRequest}
     * @memberof RALRequestApiUpdateRALRequest
     */
    readonly UpdateRALRequestRequest?: UpdateRALRequestRequest
}

/**
 * RALRequestApi - object-oriented interface
 * @export
 * @class RALRequestApi
 * @extends {BaseAPI}
 */
export class RALRequestApi extends BaseAPI {
    /**
     * Create a new RAL Request.
     * @param {RALRequestApiCreateRALRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RALRequestApi
     */
    public createRALRequest(requestParameters: RALRequestApiCreateRALRequestRequest, options?: RawAxiosRequestConfig) {
        return RALRequestApiFp(this.configuration).createRALRequest(requestParameters.CreateRALRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a RAL Request document.
     * @param {RALRequestApiDeleteRALRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RALRequestApi
     */
    public deleteRALRequest(requestParameters: RALRequestApiDeleteRALRequestRequest, options?: RawAxiosRequestConfig) {
        return RALRequestApiFp(this.configuration).deleteRALRequest(requestParameters.ral_request_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate PandaDoc signing URLs for RAL documents.
     * @param {RALRequestApiGeneratePandaDocSigningUrlsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RALRequestApi
     */
    public generatePandaDocSigningUrls(requestParameters: RALRequestApiGeneratePandaDocSigningUrlsRequest, options?: RawAxiosRequestConfig) {
        return RALRequestApiFp(this.configuration).generatePandaDocSigningUrls(requestParameters.ral_request_ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single RAL Request document by ID.
     * @param {RALRequestApiGetRALRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RALRequestApi
     */
    public getRALRequest(requestParameters: RALRequestApiGetRALRequestRequest, options?: RawAxiosRequestConfig) {
        return RALRequestApiFp(this.configuration).getRALRequest(requestParameters.ral_request_id, requestParameters.populate_user, requestParameters.populate_signed_ral, requestParameters.populate_ral_template, requestParameters.populate_ownership, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through RAL Request documents.
     * @param {RALRequestApiListRALRequestsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RALRequestApi
     */
    public listRALRequests(requestParameters: RALRequestApiListRALRequestsRequest = {}, options?: RawAxiosRequestConfig) {
        return RALRequestApiFp(this.configuration).listRALRequests(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.templates, requestParameters.users, requestParameters.ownership_ids, requestParameters.ownership_type, requestParameters.advisory_groups, requestParameters.statuses, requestParameters.sent_at, requestParameters.signed_at, requestParameters.populate_user, requestParameters.populate_user_advisory_group, requestParameters.populate_signed_ral, requestParameters.populate_ral_template, requestParameters.populate_ownership, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create and send RAL documents via PandaDoc or DocuSign.
     * @param {RALRequestApiSendRALRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RALRequestApi
     */
    public sendRALRequest(requestParameters: RALRequestApiSendRALRequestRequest, options?: RawAxiosRequestConfig) {
        return RALRequestApiFp(this.configuration).sendRALRequest(requestParameters.SendRALRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing RAL Request document.
     * @param {RALRequestApiUpdateRALRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RALRequestApi
     */
    public updateRALRequest(requestParameters: RALRequestApiUpdateRALRequestRequest, options?: RawAxiosRequestConfig) {
        return RALRequestApiFp(this.configuration).updateRALRequest(requestParameters.ral_request_id, requestParameters.UpdateRALRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListRALRequestsSortFieldEnum = {
    SentAt: 'sent_at',
    Status: 'status',
    SignedAt: 'signed_at',
    RemoteStatus: 'remote_status',
    CreatedAt: 'created_at',
    UpdatedAt: 'updated_at',
    OwnershipType: 'ownership_type'
} as const;
export type ListRALRequestsSortFieldEnum = typeof ListRALRequestsSortFieldEnum[keyof typeof ListRALRequestsSortFieldEnum];
/**
 * @export
 */
export const ListRALRequestsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListRALRequestsSortDirectionEnum = typeof ListRALRequestsSortDirectionEnum[keyof typeof ListRALRequestsSortDirectionEnum];
/**
 * @export
 */
export const ListRALRequestsOwnershipTypeEnum = {
    Account: 'Account',
    Investment: 'Investment',
    Null: 'null'
} as const;
export type ListRALRequestsOwnershipTypeEnum = typeof ListRALRequestsOwnershipTypeEnum[keyof typeof ListRALRequestsOwnershipTypeEnum];


/**
 * RALRequestTemplateApi - axios parameter creator
 * @export
 */
export const RALRequestTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve all RAL Request Templates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRALRequestTemplates: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/ralrequests/templates/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RALRequestTemplateApi - functional programming interface
 * @export
 */
export const RALRequestTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RALRequestTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve all RAL Request Templates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRALRequestTemplates(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRALRequestTemplates200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRALRequestTemplates(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RALRequestTemplateApi.listRALRequestTemplates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RALRequestTemplateApi - factory interface
 * @export
 */
export const RALRequestTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RALRequestTemplateApiFp(configuration)
    return {
        /**
         * Retrieve all RAL Request Templates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRALRequestTemplates(options?: RawAxiosRequestConfig): AxiosPromise<ListRALRequestTemplates200Response> {
            return localVarFp.listRALRequestTemplates(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RALRequestTemplateApi - object-oriented interface
 * @export
 * @class RALRequestTemplateApi
 * @extends {BaseAPI}
 */
export class RALRequestTemplateApi extends BaseAPI {
    /**
     * Retrieve all RAL Request Templates.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RALRequestTemplateApi
     */
    public listRALRequestTemplates(options?: RawAxiosRequestConfig) {
        return RALRequestTemplateApiFp(this.configuration).listRALRequestTemplates(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SyncWorkerApi - axios parameter creator
 * @export
 */
export const SyncWorkerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Complete S3 multipart upload for the connection sync
         * @param {string} id 
         * @param {CompleteMultipartUploadRequest} CompleteMultipartUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeMultipartUpload: async (id: string, CompleteMultipartUploadRequest: CompleteMultipartUploadRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('completeMultipartUpload', 'id', id)
            // verify required parameter 'CompleteMultipartUploadRequest' is not null or undefined
            assertParamExists('completeMultipartUpload', 'CompleteMultipartUploadRequest', CompleteMultipartUploadRequest)
            const localVarPath = `/v2/connections/sync/{id}/complete_multipart_upload`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CompleteMultipartUploadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate S3 multipart upload URLs for the connection sync
         * @param {string} id 
         * @param {StartMultipartUploadRequest} StartMultipartUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startMultipartUpload: async (id: string, StartMultipartUploadRequest: StartMultipartUploadRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('startMultipartUpload', 'id', id)
            // verify required parameter 'StartMultipartUploadRequest' is not null or undefined
            assertParamExists('startMultipartUpload', 'StartMultipartUploadRequest', StartMultipartUploadRequest)
            const localVarPath = `/v2/connections/sync/{id}/start_multipart_upload`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(StartMultipartUploadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the credentials of a Connection document.
         * @param {UpdateConnectionCredentialsRequest} UpdateConnectionCredentialsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionCredentials: async (UpdateConnectionCredentialsRequest: UpdateConnectionCredentialsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'UpdateConnectionCredentialsRequest' is not null or undefined
            assertParamExists('updateConnectionCredentials', 'UpdateConnectionCredentialsRequest', UpdateConnectionCredentialsRequest)
            const localVarPath = `/v2/connections/sync/credentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateConnectionCredentialsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch the connection credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncCredentialsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/connections/sync/credentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch the existing data for a connection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncExistingGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/connections/sync/existing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows a worker to post the results of a sync job
         * @param {string} id 
         * @param {V2ConnectionsSyncIdCompletePostRequest} V2ConnectionsSyncIdCompletePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdCompletePost: async (id: string, V2ConnectionsSyncIdCompletePostRequest: V2ConnectionsSyncIdCompletePostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdCompletePost', 'id', id)
            // verify required parameter 'V2ConnectionsSyncIdCompletePostRequest' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdCompletePost', 'V2ConnectionsSyncIdCompletePostRequest', V2ConnectionsSyncIdCompletePostRequest)
            const localVarPath = `/v2/connections/sync/{id}/complete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(V2ConnectionsSyncIdCompletePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch the most recent messages that were attached to this connection
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncMessagesGet: async (limit: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('v2ConnectionsSyncMessagesGet', 'limit', limit)
            const localVarPath = `/v2/connections/sync/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SyncWorkerApi - functional programming interface
 * @export
 */
export const SyncWorkerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SyncWorkerApiAxiosParamCreator(configuration)
    return {
        /**
         * Complete S3 multipart upload for the connection sync
         * @param {string} id 
         * @param {CompleteMultipartUploadRequest} CompleteMultipartUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeMultipartUpload(id: string, CompleteMultipartUploadRequest: CompleteMultipartUploadRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeMultipartUpload(id, CompleteMultipartUploadRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerApi.completeMultipartUpload']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Generate S3 multipart upload URLs for the connection sync
         * @param {string} id 
         * @param {StartMultipartUploadRequest} StartMultipartUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startMultipartUpload(id: string, StartMultipartUploadRequest: StartMultipartUploadRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartMultipartUpload200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startMultipartUpload(id, StartMultipartUploadRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerApi.startMultipartUpload']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update the credentials of a Connection document.
         * @param {UpdateConnectionCredentialsRequest} UpdateConnectionCredentialsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnectionCredentials(UpdateConnectionCredentialsRequest: UpdateConnectionCredentialsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectionCredentials(UpdateConnectionCredentialsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerApi.updateConnectionCredentials']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetch the connection credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncCredentialsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2ConnectionsSyncCredentialsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncCredentialsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerApi.v2ConnectionsSyncCredentialsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetch the existing data for a connection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncExistingGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2ConnectionsSyncExistingGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncExistingGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerApi.v2ConnectionsSyncExistingGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Allows a worker to post the results of a sync job
         * @param {string} id 
         * @param {V2ConnectionsSyncIdCompletePostRequest} V2ConnectionsSyncIdCompletePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncIdCompletePost(id: string, V2ConnectionsSyncIdCompletePostRequest: V2ConnectionsSyncIdCompletePostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncIdCompletePost(id, V2ConnectionsSyncIdCompletePostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerApi.v2ConnectionsSyncIdCompletePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetch the most recent messages that were attached to this connection
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncMessagesGet(limit: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2ConnectionsSyncMessagesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncMessagesGet(limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerApi.v2ConnectionsSyncMessagesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SyncWorkerApi - factory interface
 * @export
 */
export const SyncWorkerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SyncWorkerApiFp(configuration)
    return {
        /**
         * Complete S3 multipart upload for the connection sync
         * @param {SyncWorkerApiCompleteMultipartUploadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeMultipartUpload(requestParameters: SyncWorkerApiCompleteMultipartUploadRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.completeMultipartUpload(requestParameters.id, requestParameters.CompleteMultipartUploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate S3 multipart upload URLs for the connection sync
         * @param {SyncWorkerApiStartMultipartUploadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startMultipartUpload(requestParameters: SyncWorkerApiStartMultipartUploadRequest, options?: RawAxiosRequestConfig): AxiosPromise<StartMultipartUpload200Response> {
            return localVarFp.startMultipartUpload(requestParameters.id, requestParameters.StartMultipartUploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the credentials of a Connection document.
         * @param {SyncWorkerApiUpdateConnectionCredentialsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionCredentials(requestParameters: SyncWorkerApiUpdateConnectionCredentialsRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateConnectionCredentials(requestParameters.UpdateConnectionCredentialsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch the connection credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncCredentialsGet(options?: RawAxiosRequestConfig): AxiosPromise<V2ConnectionsSyncCredentialsGet200Response> {
            return localVarFp.v2ConnectionsSyncCredentialsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch the existing data for a connection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncExistingGet(options?: RawAxiosRequestConfig): AxiosPromise<V2ConnectionsSyncExistingGet200Response> {
            return localVarFp.v2ConnectionsSyncExistingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Allows a worker to post the results of a sync job
         * @param {SyncWorkerApiV2ConnectionsSyncIdCompletePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdCompletePost(requestParameters: SyncWorkerApiV2ConnectionsSyncIdCompletePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.v2ConnectionsSyncIdCompletePost(requestParameters.id, requestParameters.V2ConnectionsSyncIdCompletePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch the most recent messages that were attached to this connection
         * @param {SyncWorkerApiV2ConnectionsSyncMessagesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncMessagesGet(requestParameters: SyncWorkerApiV2ConnectionsSyncMessagesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<V2ConnectionsSyncMessagesGet200Response> {
            return localVarFp.v2ConnectionsSyncMessagesGet(requestParameters.limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for completeMultipartUpload operation in SyncWorkerApi.
 * @export
 * @interface SyncWorkerApiCompleteMultipartUploadRequest
 */
export interface SyncWorkerApiCompleteMultipartUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncWorkerApiCompleteMultipartUpload
     */
    readonly id: string

    /**
     * 
     * @type {CompleteMultipartUploadRequest}
     * @memberof SyncWorkerApiCompleteMultipartUpload
     */
    readonly CompleteMultipartUploadRequest: CompleteMultipartUploadRequest
}

/**
 * Request parameters for startMultipartUpload operation in SyncWorkerApi.
 * @export
 * @interface SyncWorkerApiStartMultipartUploadRequest
 */
export interface SyncWorkerApiStartMultipartUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncWorkerApiStartMultipartUpload
     */
    readonly id: string

    /**
     * 
     * @type {StartMultipartUploadRequest}
     * @memberof SyncWorkerApiStartMultipartUpload
     */
    readonly StartMultipartUploadRequest: StartMultipartUploadRequest
}

/**
 * Request parameters for updateConnectionCredentials operation in SyncWorkerApi.
 * @export
 * @interface SyncWorkerApiUpdateConnectionCredentialsRequest
 */
export interface SyncWorkerApiUpdateConnectionCredentialsRequest {
    /**
     * 
     * @type {UpdateConnectionCredentialsRequest}
     * @memberof SyncWorkerApiUpdateConnectionCredentials
     */
    readonly UpdateConnectionCredentialsRequest: UpdateConnectionCredentialsRequest
}

/**
 * Request parameters for v2ConnectionsSyncIdCompletePost operation in SyncWorkerApi.
 * @export
 * @interface SyncWorkerApiV2ConnectionsSyncIdCompletePostRequest
 */
export interface SyncWorkerApiV2ConnectionsSyncIdCompletePostRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncWorkerApiV2ConnectionsSyncIdCompletePost
     */
    readonly id: string

    /**
     * 
     * @type {V2ConnectionsSyncIdCompletePostRequest}
     * @memberof SyncWorkerApiV2ConnectionsSyncIdCompletePost
     */
    readonly V2ConnectionsSyncIdCompletePostRequest: V2ConnectionsSyncIdCompletePostRequest
}

/**
 * Request parameters for v2ConnectionsSyncMessagesGet operation in SyncWorkerApi.
 * @export
 * @interface SyncWorkerApiV2ConnectionsSyncMessagesGetRequest
 */
export interface SyncWorkerApiV2ConnectionsSyncMessagesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof SyncWorkerApiV2ConnectionsSyncMessagesGet
     */
    readonly limit: number
}

/**
 * SyncWorkerApi - object-oriented interface
 * @export
 * @class SyncWorkerApi
 * @extends {BaseAPI}
 */
export class SyncWorkerApi extends BaseAPI {
    /**
     * Complete S3 multipart upload for the connection sync
     * @param {SyncWorkerApiCompleteMultipartUploadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerApi
     */
    public completeMultipartUpload(requestParameters: SyncWorkerApiCompleteMultipartUploadRequest, options?: RawAxiosRequestConfig) {
        return SyncWorkerApiFp(this.configuration).completeMultipartUpload(requestParameters.id, requestParameters.CompleteMultipartUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate S3 multipart upload URLs for the connection sync
     * @param {SyncWorkerApiStartMultipartUploadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerApi
     */
    public startMultipartUpload(requestParameters: SyncWorkerApiStartMultipartUploadRequest, options?: RawAxiosRequestConfig) {
        return SyncWorkerApiFp(this.configuration).startMultipartUpload(requestParameters.id, requestParameters.StartMultipartUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the credentials of a Connection document.
     * @param {SyncWorkerApiUpdateConnectionCredentialsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerApi
     */
    public updateConnectionCredentials(requestParameters: SyncWorkerApiUpdateConnectionCredentialsRequest, options?: RawAxiosRequestConfig) {
        return SyncWorkerApiFp(this.configuration).updateConnectionCredentials(requestParameters.UpdateConnectionCredentialsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch the connection credentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerApi
     */
    public v2ConnectionsSyncCredentialsGet(options?: RawAxiosRequestConfig) {
        return SyncWorkerApiFp(this.configuration).v2ConnectionsSyncCredentialsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch the existing data for a connection
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerApi
     */
    public v2ConnectionsSyncExistingGet(options?: RawAxiosRequestConfig) {
        return SyncWorkerApiFp(this.configuration).v2ConnectionsSyncExistingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows a worker to post the results of a sync job
     * @param {SyncWorkerApiV2ConnectionsSyncIdCompletePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerApi
     */
    public v2ConnectionsSyncIdCompletePost(requestParameters: SyncWorkerApiV2ConnectionsSyncIdCompletePostRequest, options?: RawAxiosRequestConfig) {
        return SyncWorkerApiFp(this.configuration).v2ConnectionsSyncIdCompletePost(requestParameters.id, requestParameters.V2ConnectionsSyncIdCompletePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch the most recent messages that were attached to this connection
     * @param {SyncWorkerApiV2ConnectionsSyncMessagesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerApi
     */
    public v2ConnectionsSyncMessagesGet(requestParameters: SyncWorkerApiV2ConnectionsSyncMessagesGetRequest, options?: RawAxiosRequestConfig) {
        return SyncWorkerApiFp(this.configuration).v2ConnectionsSyncMessagesGet(requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SyncWorkerImportApi - axios parameter creator
 * @export
 */
export const SyncWorkerImportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Provide information about accounts discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportAccountsPostRequest} V2ConnectionsSyncIdImportAccountsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportAccountsPost: async (id: string, V2ConnectionsSyncIdImportAccountsPostRequest: V2ConnectionsSyncIdImportAccountsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportAccountsPost', 'id', id)
            // verify required parameter 'V2ConnectionsSyncIdImportAccountsPostRequest' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportAccountsPost', 'V2ConnectionsSyncIdImportAccountsPostRequest', V2ConnectionsSyncIdImportAccountsPostRequest)
            const localVarPath = `/v2/connections/sync/{id}/import/accounts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(V2ConnectionsSyncIdImportAccountsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provide information about commitments discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportCommitmentsPostRequest} V2ConnectionsSyncIdImportCommitmentsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportCommitmentsPost: async (id: string, V2ConnectionsSyncIdImportCommitmentsPostRequest: V2ConnectionsSyncIdImportCommitmentsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportCommitmentsPost', 'id', id)
            // verify required parameter 'V2ConnectionsSyncIdImportCommitmentsPostRequest' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportCommitmentsPost', 'V2ConnectionsSyncIdImportCommitmentsPostRequest', V2ConnectionsSyncIdImportCommitmentsPostRequest)
            const localVarPath = `/v2/connections/sync/{id}/import/commitments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(V2ConnectionsSyncIdImportCommitmentsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provide information about documents discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportDocumentsPostRequest} V2ConnectionsSyncIdImportDocumentsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportDocumentsPost: async (id: string, V2ConnectionsSyncIdImportDocumentsPostRequest: V2ConnectionsSyncIdImportDocumentsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportDocumentsPost', 'id', id)
            // verify required parameter 'V2ConnectionsSyncIdImportDocumentsPostRequest' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportDocumentsPost', 'V2ConnectionsSyncIdImportDocumentsPostRequest', V2ConnectionsSyncIdImportDocumentsPostRequest)
            const localVarPath = `/v2/connections/sync/{id}/import/documents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(V2ConnectionsSyncIdImportDocumentsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provide information about investments discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportInvestmentsPostRequest} V2ConnectionsSyncIdImportInvestmentsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportInvestmentsPost: async (id: string, V2ConnectionsSyncIdImportInvestmentsPostRequest: V2ConnectionsSyncIdImportInvestmentsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportInvestmentsPost', 'id', id)
            // verify required parameter 'V2ConnectionsSyncIdImportInvestmentsPostRequest' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportInvestmentsPost', 'V2ConnectionsSyncIdImportInvestmentsPostRequest', V2ConnectionsSyncIdImportInvestmentsPostRequest)
            const localVarPath = `/v2/connections/sync/{id}/import/investments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(V2ConnectionsSyncIdImportInvestmentsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provide information about transactions discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportTransactionsPostRequest} V2ConnectionsSyncIdImportTransactionsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportTransactionsPost: async (id: string, V2ConnectionsSyncIdImportTransactionsPostRequest: V2ConnectionsSyncIdImportTransactionsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportTransactionsPost', 'id', id)
            // verify required parameter 'V2ConnectionsSyncIdImportTransactionsPostRequest' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportTransactionsPost', 'V2ConnectionsSyncIdImportTransactionsPostRequest', V2ConnectionsSyncIdImportTransactionsPostRequest)
            const localVarPath = `/v2/connections/sync/{id}/import/transactions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(V2ConnectionsSyncIdImportTransactionsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provide information about users discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportUsersPostRequest} V2ConnectionsSyncIdImportUsersPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportUsersPost: async (id: string, V2ConnectionsSyncIdImportUsersPostRequest: V2ConnectionsSyncIdImportUsersPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportUsersPost', 'id', id)
            // verify required parameter 'V2ConnectionsSyncIdImportUsersPostRequest' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportUsersPost', 'V2ConnectionsSyncIdImportUsersPostRequest', V2ConnectionsSyncIdImportUsersPostRequest)
            const localVarPath = `/v2/connections/sync/{id}/import/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(V2ConnectionsSyncIdImportUsersPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provide information about valuations discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportValuationsPostRequest} V2ConnectionsSyncIdImportValuationsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportValuationsPost: async (id: string, V2ConnectionsSyncIdImportValuationsPostRequest: V2ConnectionsSyncIdImportValuationsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportValuationsPost', 'id', id)
            // verify required parameter 'V2ConnectionsSyncIdImportValuationsPostRequest' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportValuationsPost', 'V2ConnectionsSyncIdImportValuationsPostRequest', V2ConnectionsSyncIdImportValuationsPostRequest)
            const localVarPath = `/v2/connections/sync/{id}/import/valuations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(V2ConnectionsSyncIdImportValuationsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SyncWorkerImportApi - functional programming interface
 * @export
 */
export const SyncWorkerImportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SyncWorkerImportApiAxiosParamCreator(configuration)
    return {
        /**
         * Provide information about accounts discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportAccountsPostRequest} V2ConnectionsSyncIdImportAccountsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncIdImportAccountsPost(id: string, V2ConnectionsSyncIdImportAccountsPostRequest: V2ConnectionsSyncIdImportAccountsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncIdImportAccountsPost(id, V2ConnectionsSyncIdImportAccountsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerImportApi.v2ConnectionsSyncIdImportAccountsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provide information about commitments discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportCommitmentsPostRequest} V2ConnectionsSyncIdImportCommitmentsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncIdImportCommitmentsPost(id: string, V2ConnectionsSyncIdImportCommitmentsPostRequest: V2ConnectionsSyncIdImportCommitmentsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncIdImportCommitmentsPost(id, V2ConnectionsSyncIdImportCommitmentsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerImportApi.v2ConnectionsSyncIdImportCommitmentsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provide information about documents discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportDocumentsPostRequest} V2ConnectionsSyncIdImportDocumentsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncIdImportDocumentsPost(id: string, V2ConnectionsSyncIdImportDocumentsPostRequest: V2ConnectionsSyncIdImportDocumentsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2ConnectionsSyncIdImportDocumentsPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncIdImportDocumentsPost(id, V2ConnectionsSyncIdImportDocumentsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerImportApi.v2ConnectionsSyncIdImportDocumentsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provide information about investments discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportInvestmentsPostRequest} V2ConnectionsSyncIdImportInvestmentsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncIdImportInvestmentsPost(id: string, V2ConnectionsSyncIdImportInvestmentsPostRequest: V2ConnectionsSyncIdImportInvestmentsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncIdImportInvestmentsPost(id, V2ConnectionsSyncIdImportInvestmentsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerImportApi.v2ConnectionsSyncIdImportInvestmentsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provide information about transactions discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportTransactionsPostRequest} V2ConnectionsSyncIdImportTransactionsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncIdImportTransactionsPost(id: string, V2ConnectionsSyncIdImportTransactionsPostRequest: V2ConnectionsSyncIdImportTransactionsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncIdImportTransactionsPost(id, V2ConnectionsSyncIdImportTransactionsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerImportApi.v2ConnectionsSyncIdImportTransactionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provide information about users discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportUsersPostRequest} V2ConnectionsSyncIdImportUsersPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncIdImportUsersPost(id: string, V2ConnectionsSyncIdImportUsersPostRequest: V2ConnectionsSyncIdImportUsersPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncIdImportUsersPost(id, V2ConnectionsSyncIdImportUsersPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerImportApi.v2ConnectionsSyncIdImportUsersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provide information about valuations discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportValuationsPostRequest} V2ConnectionsSyncIdImportValuationsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncIdImportValuationsPost(id: string, V2ConnectionsSyncIdImportValuationsPostRequest: V2ConnectionsSyncIdImportValuationsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncIdImportValuationsPost(id, V2ConnectionsSyncIdImportValuationsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerImportApi.v2ConnectionsSyncIdImportValuationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SyncWorkerImportApi - factory interface
 * @export
 */
export const SyncWorkerImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SyncWorkerImportApiFp(configuration)
    return {
        /**
         * Provide information about accounts discovered during the sync process
         * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportAccountsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportAccountsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportAccountsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.v2ConnectionsSyncIdImportAccountsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportAccountsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Provide information about commitments discovered during the sync process
         * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportCommitmentsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportCommitmentsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportCommitmentsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.v2ConnectionsSyncIdImportCommitmentsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportCommitmentsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Provide information about documents discovered during the sync process
         * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportDocumentsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportDocumentsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportDocumentsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<V2ConnectionsSyncIdImportDocumentsPost200Response> {
            return localVarFp.v2ConnectionsSyncIdImportDocumentsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportDocumentsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Provide information about investments discovered during the sync process
         * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportInvestmentsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportInvestmentsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportInvestmentsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.v2ConnectionsSyncIdImportInvestmentsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportInvestmentsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Provide information about transactions discovered during the sync process
         * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportTransactionsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportTransactionsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportTransactionsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.v2ConnectionsSyncIdImportTransactionsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportTransactionsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Provide information about users discovered during the sync process
         * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportUsersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportUsersPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportUsersPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.v2ConnectionsSyncIdImportUsersPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportUsersPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Provide information about valuations discovered during the sync process
         * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportValuationsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportValuationsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportValuationsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.v2ConnectionsSyncIdImportValuationsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportValuationsPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v2ConnectionsSyncIdImportAccountsPost operation in SyncWorkerImportApi.
 * @export
 * @interface SyncWorkerImportApiV2ConnectionsSyncIdImportAccountsPostRequest
 */
export interface SyncWorkerImportApiV2ConnectionsSyncIdImportAccountsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportAccountsPost
     */
    readonly id: string

    /**
     * 
     * @type {V2ConnectionsSyncIdImportAccountsPostRequest}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportAccountsPost
     */
    readonly V2ConnectionsSyncIdImportAccountsPostRequest: V2ConnectionsSyncIdImportAccountsPostRequest
}

/**
 * Request parameters for v2ConnectionsSyncIdImportCommitmentsPost operation in SyncWorkerImportApi.
 * @export
 * @interface SyncWorkerImportApiV2ConnectionsSyncIdImportCommitmentsPostRequest
 */
export interface SyncWorkerImportApiV2ConnectionsSyncIdImportCommitmentsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportCommitmentsPost
     */
    readonly id: string

    /**
     * 
     * @type {V2ConnectionsSyncIdImportCommitmentsPostRequest}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportCommitmentsPost
     */
    readonly V2ConnectionsSyncIdImportCommitmentsPostRequest: V2ConnectionsSyncIdImportCommitmentsPostRequest
}

/**
 * Request parameters for v2ConnectionsSyncIdImportDocumentsPost operation in SyncWorkerImportApi.
 * @export
 * @interface SyncWorkerImportApiV2ConnectionsSyncIdImportDocumentsPostRequest
 */
export interface SyncWorkerImportApiV2ConnectionsSyncIdImportDocumentsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportDocumentsPost
     */
    readonly id: string

    /**
     * 
     * @type {V2ConnectionsSyncIdImportDocumentsPostRequest}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportDocumentsPost
     */
    readonly V2ConnectionsSyncIdImportDocumentsPostRequest: V2ConnectionsSyncIdImportDocumentsPostRequest
}

/**
 * Request parameters for v2ConnectionsSyncIdImportInvestmentsPost operation in SyncWorkerImportApi.
 * @export
 * @interface SyncWorkerImportApiV2ConnectionsSyncIdImportInvestmentsPostRequest
 */
export interface SyncWorkerImportApiV2ConnectionsSyncIdImportInvestmentsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportInvestmentsPost
     */
    readonly id: string

    /**
     * 
     * @type {V2ConnectionsSyncIdImportInvestmentsPostRequest}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportInvestmentsPost
     */
    readonly V2ConnectionsSyncIdImportInvestmentsPostRequest: V2ConnectionsSyncIdImportInvestmentsPostRequest
}

/**
 * Request parameters for v2ConnectionsSyncIdImportTransactionsPost operation in SyncWorkerImportApi.
 * @export
 * @interface SyncWorkerImportApiV2ConnectionsSyncIdImportTransactionsPostRequest
 */
export interface SyncWorkerImportApiV2ConnectionsSyncIdImportTransactionsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportTransactionsPost
     */
    readonly id: string

    /**
     * 
     * @type {V2ConnectionsSyncIdImportTransactionsPostRequest}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportTransactionsPost
     */
    readonly V2ConnectionsSyncIdImportTransactionsPostRequest: V2ConnectionsSyncIdImportTransactionsPostRequest
}

/**
 * Request parameters for v2ConnectionsSyncIdImportUsersPost operation in SyncWorkerImportApi.
 * @export
 * @interface SyncWorkerImportApiV2ConnectionsSyncIdImportUsersPostRequest
 */
export interface SyncWorkerImportApiV2ConnectionsSyncIdImportUsersPostRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportUsersPost
     */
    readonly id: string

    /**
     * 
     * @type {V2ConnectionsSyncIdImportUsersPostRequest}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportUsersPost
     */
    readonly V2ConnectionsSyncIdImportUsersPostRequest: V2ConnectionsSyncIdImportUsersPostRequest
}

/**
 * Request parameters for v2ConnectionsSyncIdImportValuationsPost operation in SyncWorkerImportApi.
 * @export
 * @interface SyncWorkerImportApiV2ConnectionsSyncIdImportValuationsPostRequest
 */
export interface SyncWorkerImportApiV2ConnectionsSyncIdImportValuationsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportValuationsPost
     */
    readonly id: string

    /**
     * 
     * @type {V2ConnectionsSyncIdImportValuationsPostRequest}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportValuationsPost
     */
    readonly V2ConnectionsSyncIdImportValuationsPostRequest: V2ConnectionsSyncIdImportValuationsPostRequest
}

/**
 * SyncWorkerImportApi - object-oriented interface
 * @export
 * @class SyncWorkerImportApi
 * @extends {BaseAPI}
 */
export class SyncWorkerImportApi extends BaseAPI {
    /**
     * Provide information about accounts discovered during the sync process
     * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportAccountsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerImportApi
     */
    public v2ConnectionsSyncIdImportAccountsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportAccountsPostRequest, options?: RawAxiosRequestConfig) {
        return SyncWorkerImportApiFp(this.configuration).v2ConnectionsSyncIdImportAccountsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportAccountsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provide information about commitments discovered during the sync process
     * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportCommitmentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerImportApi
     */
    public v2ConnectionsSyncIdImportCommitmentsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportCommitmentsPostRequest, options?: RawAxiosRequestConfig) {
        return SyncWorkerImportApiFp(this.configuration).v2ConnectionsSyncIdImportCommitmentsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportCommitmentsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provide information about documents discovered during the sync process
     * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportDocumentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerImportApi
     */
    public v2ConnectionsSyncIdImportDocumentsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportDocumentsPostRequest, options?: RawAxiosRequestConfig) {
        return SyncWorkerImportApiFp(this.configuration).v2ConnectionsSyncIdImportDocumentsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportDocumentsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provide information about investments discovered during the sync process
     * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportInvestmentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerImportApi
     */
    public v2ConnectionsSyncIdImportInvestmentsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportInvestmentsPostRequest, options?: RawAxiosRequestConfig) {
        return SyncWorkerImportApiFp(this.configuration).v2ConnectionsSyncIdImportInvestmentsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportInvestmentsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provide information about transactions discovered during the sync process
     * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportTransactionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerImportApi
     */
    public v2ConnectionsSyncIdImportTransactionsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportTransactionsPostRequest, options?: RawAxiosRequestConfig) {
        return SyncWorkerImportApiFp(this.configuration).v2ConnectionsSyncIdImportTransactionsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportTransactionsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provide information about users discovered during the sync process
     * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportUsersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerImportApi
     */
    public v2ConnectionsSyncIdImportUsersPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportUsersPostRequest, options?: RawAxiosRequestConfig) {
        return SyncWorkerImportApiFp(this.configuration).v2ConnectionsSyncIdImportUsersPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportUsersPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provide information about valuations discovered during the sync process
     * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportValuationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerImportApi
     */
    public v2ConnectionsSyncIdImportValuationsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportValuationsPostRequest, options?: RawAxiosRequestConfig) {
        return SyncWorkerImportApiFp(this.configuration).v2ConnectionsSyncIdImportValuationsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportValuationsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TextVerifiedApi - axios parameter creator
 * @export
 */
export const TextVerifiedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Receive webhook events from TextVerified and process SMS data.
         * @param {ReceiveTextVerifiedHookRequest} [ReceiveTextVerifiedHookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveTextVerifiedHook: async (ReceiveTextVerifiedHookRequest?: ReceiveTextVerifiedHookRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/internal/textverified`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ReceiveTextVerifiedHookRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TextVerifiedApi - functional programming interface
 * @export
 */
export const TextVerifiedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TextVerifiedApiAxiosParamCreator(configuration)
    return {
        /**
         * Receive webhook events from TextVerified and process SMS data.
         * @param {ReceiveTextVerifiedHookRequest} [ReceiveTextVerifiedHookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiveTextVerifiedHook(ReceiveTextVerifiedHookRequest?: ReceiveTextVerifiedHookRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunSlaIssues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiveTextVerifiedHook(ReceiveTextVerifiedHookRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TextVerifiedApi.receiveTextVerifiedHook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TextVerifiedApi - factory interface
 * @export
 */
export const TextVerifiedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TextVerifiedApiFp(configuration)
    return {
        /**
         * Receive webhook events from TextVerified and process SMS data.
         * @param {TextVerifiedApiReceiveTextVerifiedHookRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveTextVerifiedHook(requestParameters: TextVerifiedApiReceiveTextVerifiedHookRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RunSlaIssues200Response> {
            return localVarFp.receiveTextVerifiedHook(requestParameters.ReceiveTextVerifiedHookRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for receiveTextVerifiedHook operation in TextVerifiedApi.
 * @export
 * @interface TextVerifiedApiReceiveTextVerifiedHookRequest
 */
export interface TextVerifiedApiReceiveTextVerifiedHookRequest {
    /**
     * 
     * @type {ReceiveTextVerifiedHookRequest}
     * @memberof TextVerifiedApiReceiveTextVerifiedHook
     */
    readonly ReceiveTextVerifiedHookRequest?: ReceiveTextVerifiedHookRequest
}

/**
 * TextVerifiedApi - object-oriented interface
 * @export
 * @class TextVerifiedApi
 * @extends {BaseAPI}
 */
export class TextVerifiedApi extends BaseAPI {
    /**
     * Receive webhook events from TextVerified and process SMS data.
     * @param {TextVerifiedApiReceiveTextVerifiedHookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextVerifiedApi
     */
    public receiveTextVerifiedHook(requestParameters: TextVerifiedApiReceiveTextVerifiedHookRequest = {}, options?: RawAxiosRequestConfig) {
        return TextVerifiedApiFp(this.configuration).receiveTextVerifiedHook(requestParameters.ReceiveTextVerifiedHookRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TransactionApi - axios parameter creator
 * @export
 */
export const TransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Transaction document.
         * @param {CreateTransactionRequest} CreateTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransaction: async (CreateTransactionRequest: CreateTransactionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateTransactionRequest' is not null or undefined
            assertParamExists('createTransaction', 'CreateTransactionRequest', CreateTransactionRequest)
            const localVarPath = `/v2/transactions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Transaction document.
         * @param {string} transaction_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransaction: async (transaction_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transaction_id' is not null or undefined
            assertParamExists('deleteTransaction', 'transaction_id', transaction_id)
            const localVarPath = `/v2/transactions/{transaction_id}`
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transaction_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single Transaction document by ID.
         * @param {string | null} transaction_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_document] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction: async (transaction_id: string | null, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_document?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transaction_id' is not null or undefined
            assertParamExists('getTransaction', 'transaction_id', transaction_id)
            const localVarPath = `/v2/transactions/{transaction_id}`
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transaction_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_investment !== undefined) {
                localVarQueryParameter['populate_investment'] = populate_investment;
            }

            if (populate_document !== undefined) {
                localVarQueryParameter['populate_document'] = populate_document;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Transaction documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListTransactionsSortFieldEnum} [sort_field] 
         * @param {ListTransactionsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [investments] 
         * @param {Array<string>} [investment_masters] 
         * @param {boolean} [hidden] 
         * @param {boolean} [inactive] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_document] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {ListTransactionsJointTransactionDisplayModeEnum} [joint_transaction_display_mode] 
         * @param {Array<string>} [types] 
         * @param {Array<string>} [exclude_types] 
         * @param {boolean} [open] 
         * @param {Array<string>} [advisory_groups] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTransactions: async (skip?: number, limit?: number, search?: string, sort_field?: ListTransactionsSortFieldEnum, sort_direction?: ListTransactionsSortDirectionEnum, users?: Array<string>, accounts?: Array<string>, investments?: Array<string>, investment_masters?: Array<string>, hidden?: boolean, inactive?: boolean, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_document?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, joint_transaction_display_mode?: ListTransactionsJointTransactionDisplayModeEnum, types?: Array<string>, exclude_types?: Array<string>, open?: boolean, advisory_groups?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/transactions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (accounts) {
                localVarQueryParameter['accounts'] = accounts;
            }

            if (investments) {
                localVarQueryParameter['investments'] = investments;
            }

            if (investment_masters) {
                localVarQueryParameter['investment_masters'] = investment_masters;
            }

            if (hidden !== undefined) {
                localVarQueryParameter['hidden'] = hidden;
            }

            if (inactive !== undefined) {
                localVarQueryParameter['inactive'] = inactive;
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_investment !== undefined) {
                localVarQueryParameter['populate_investment'] = populate_investment;
            }

            if (populate_document !== undefined) {
                localVarQueryParameter['populate_document'] = populate_document;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }

            if (joint_transaction_display_mode !== undefined) {
                localVarQueryParameter['joint_transaction_display_mode'] = joint_transaction_display_mode;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (exclude_types) {
                localVarQueryParameter['exclude_types'] = exclude_types;
            }

            if (open !== undefined) {
                localVarQueryParameter['open'] = open;
            }

            if (advisory_groups) {
                localVarQueryParameter['advisory_groups'] = advisory_groups;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Transaction document.
         * @param {string} transaction_id 
         * @param {UpdateTransactionRequest} [UpdateTransactionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransaction: async (transaction_id: string, UpdateTransactionRequest?: UpdateTransactionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transaction_id' is not null or undefined
            assertParamExists('updateTransaction', 'transaction_id', transaction_id)
            const localVarPath = `/v2/transactions/{transaction_id}`
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transaction_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionApi - functional programming interface
 * @export
 */
export const TransactionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Transaction document.
         * @param {CreateTransactionRequest} CreateTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTransaction(CreateTransactionRequest: CreateTransactionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTransaction201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTransaction(CreateTransactionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.createTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a Transaction document.
         * @param {string} transaction_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTransaction(transaction_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTransaction(transaction_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.deleteTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single Transaction document by ID.
         * @param {string | null} transaction_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_document] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransaction(transaction_id: string | null, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_document?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTransaction200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransaction(transaction_id, populate_user, populate_account, populate_investment, populate_document, populate_connection, populate_audit_log, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.getTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Transaction documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListTransactionsSortFieldEnum} [sort_field] 
         * @param {ListTransactionsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [investments] 
         * @param {Array<string>} [investment_masters] 
         * @param {boolean} [hidden] 
         * @param {boolean} [inactive] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_document] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {ListTransactionsJointTransactionDisplayModeEnum} [joint_transaction_display_mode] 
         * @param {Array<string>} [types] 
         * @param {Array<string>} [exclude_types] 
         * @param {boolean} [open] 
         * @param {Array<string>} [advisory_groups] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTransactions(skip?: number, limit?: number, search?: string, sort_field?: ListTransactionsSortFieldEnum, sort_direction?: ListTransactionsSortDirectionEnum, users?: Array<string>, accounts?: Array<string>, investments?: Array<string>, investment_masters?: Array<string>, hidden?: boolean, inactive?: boolean, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_document?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, joint_transaction_display_mode?: ListTransactionsJointTransactionDisplayModeEnum, types?: Array<string>, exclude_types?: Array<string>, open?: boolean, advisory_groups?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTransactions200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTransactions(skip, limit, search, sort_field, sort_direction, users, accounts, investments, investment_masters, hidden, inactive, populate_user, populate_account, populate_investment, populate_document, populate_connection, populate_audit_log, joint_transaction_display_mode, types, exclude_types, open, advisory_groups, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.listTransactions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing Transaction document.
         * @param {string} transaction_id 
         * @param {UpdateTransactionRequest} [UpdateTransactionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTransaction(transaction_id: string, UpdateTransactionRequest?: UpdateTransactionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTransaction(transaction_id, UpdateTransactionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.updateTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TransactionApi - factory interface
 * @export
 */
export const TransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionApiFp(configuration)
    return {
        /**
         * Create a new Transaction document.
         * @param {TransactionApiCreateTransactionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransaction(requestParameters: TransactionApiCreateTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateTransaction201Response> {
            return localVarFp.createTransaction(requestParameters.CreateTransactionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Transaction document.
         * @param {TransactionApiDeleteTransactionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransaction(requestParameters: TransactionApiDeleteTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteTransaction(requestParameters.transaction_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single Transaction document by ID.
         * @param {TransactionApiGetTransactionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction(requestParameters: TransactionApiGetTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetTransaction200Response> {
            return localVarFp.getTransaction(requestParameters.transaction_id, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_document, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Transaction documents.
         * @param {TransactionApiListTransactionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTransactions(requestParameters: TransactionApiListTransactionsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListTransactions200Response> {
            return localVarFp.listTransactions(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.accounts, requestParameters.investments, requestParameters.investment_masters, requestParameters.hidden, requestParameters.inactive, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_document, requestParameters.populate_connection, requestParameters.populate_audit_log, requestParameters.joint_transaction_display_mode, requestParameters.types, requestParameters.exclude_types, requestParameters.open, requestParameters.advisory_groups, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Transaction document.
         * @param {TransactionApiUpdateTransactionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransaction(requestParameters: TransactionApiUpdateTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateTransaction(requestParameters.transaction_id, requestParameters.UpdateTransactionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTransaction operation in TransactionApi.
 * @export
 * @interface TransactionApiCreateTransactionRequest
 */
export interface TransactionApiCreateTransactionRequest {
    /**
     * 
     * @type {CreateTransactionRequest}
     * @memberof TransactionApiCreateTransaction
     */
    readonly CreateTransactionRequest: CreateTransactionRequest
}

/**
 * Request parameters for deleteTransaction operation in TransactionApi.
 * @export
 * @interface TransactionApiDeleteTransactionRequest
 */
export interface TransactionApiDeleteTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionApiDeleteTransaction
     */
    readonly transaction_id: string
}

/**
 * Request parameters for getTransaction operation in TransactionApi.
 * @export
 * @interface TransactionApiGetTransactionRequest
 */
export interface TransactionApiGetTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionApiGetTransaction
     */
    readonly transaction_id: string | null

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiGetTransaction
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiGetTransaction
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiGetTransaction
     */
    readonly populate_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiGetTransaction
     */
    readonly populate_document?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiGetTransaction
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiGetTransaction
     */
    readonly populate_audit_log?: boolean
}

/**
 * Request parameters for listTransactions operation in TransactionApi.
 * @export
 * @interface TransactionApiListTransactionsRequest
 */
export interface TransactionApiListTransactionsRequest {
    /**
     * 
     * @type {number}
     * @memberof TransactionApiListTransactions
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof TransactionApiListTransactions
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof TransactionApiListTransactions
     */
    readonly search?: string

    /**
     * 
     * @type {'date' | 'amount' | 'type'}
     * @memberof TransactionApiListTransactions
     */
    readonly sort_field?: ListTransactionsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof TransactionApiListTransactions
     */
    readonly sort_direction?: ListTransactionsSortDirectionEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof TransactionApiListTransactions
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof TransactionApiListTransactions
     */
    readonly accounts?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof TransactionApiListTransactions
     */
    readonly investments?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof TransactionApiListTransactions
     */
    readonly investment_masters?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiListTransactions
     */
    readonly hidden?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiListTransactions
     */
    readonly inactive?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiListTransactions
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiListTransactions
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiListTransactions
     */
    readonly populate_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiListTransactions
     */
    readonly populate_document?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiListTransactions
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiListTransactions
     */
    readonly populate_audit_log?: boolean

    /**
     * 
     * @type {'parents' | 'children'}
     * @memberof TransactionApiListTransactions
     */
    readonly joint_transaction_display_mode?: ListTransactionsJointTransactionDisplayModeEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof TransactionApiListTransactions
     */
    readonly types?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof TransactionApiListTransactions
     */
    readonly exclude_types?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiListTransactions
     */
    readonly open?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof TransactionApiListTransactions
     */
    readonly advisory_groups?: Array<string>
}

/**
 * Request parameters for updateTransaction operation in TransactionApi.
 * @export
 * @interface TransactionApiUpdateTransactionRequest
 */
export interface TransactionApiUpdateTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionApiUpdateTransaction
     */
    readonly transaction_id: string

    /**
     * 
     * @type {UpdateTransactionRequest}
     * @memberof TransactionApiUpdateTransaction
     */
    readonly UpdateTransactionRequest?: UpdateTransactionRequest
}

/**
 * TransactionApi - object-oriented interface
 * @export
 * @class TransactionApi
 * @extends {BaseAPI}
 */
export class TransactionApi extends BaseAPI {
    /**
     * Create a new Transaction document.
     * @param {TransactionApiCreateTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public createTransaction(requestParameters: TransactionApiCreateTransactionRequest, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).createTransaction(requestParameters.CreateTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Transaction document.
     * @param {TransactionApiDeleteTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public deleteTransaction(requestParameters: TransactionApiDeleteTransactionRequest, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).deleteTransaction(requestParameters.transaction_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single Transaction document by ID.
     * @param {TransactionApiGetTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public getTransaction(requestParameters: TransactionApiGetTransactionRequest, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).getTransaction(requestParameters.transaction_id, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_document, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Transaction documents.
     * @param {TransactionApiListTransactionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public listTransactions(requestParameters: TransactionApiListTransactionsRequest = {}, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).listTransactions(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.accounts, requestParameters.investments, requestParameters.investment_masters, requestParameters.hidden, requestParameters.inactive, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_document, requestParameters.populate_connection, requestParameters.populate_audit_log, requestParameters.joint_transaction_display_mode, requestParameters.types, requestParameters.exclude_types, requestParameters.open, requestParameters.advisory_groups, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Transaction document.
     * @param {TransactionApiUpdateTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public updateTransaction(requestParameters: TransactionApiUpdateTransactionRequest, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).updateTransaction(requestParameters.transaction_id, requestParameters.UpdateTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListTransactionsSortFieldEnum = {
    Date: 'date',
    Amount: 'amount',
    Type: 'type'
} as const;
export type ListTransactionsSortFieldEnum = typeof ListTransactionsSortFieldEnum[keyof typeof ListTransactionsSortFieldEnum];
/**
 * @export
 */
export const ListTransactionsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListTransactionsSortDirectionEnum = typeof ListTransactionsSortDirectionEnum[keyof typeof ListTransactionsSortDirectionEnum];
/**
 * @export
 */
export const ListTransactionsJointTransactionDisplayModeEnum = {
    Parents: 'parents',
    Children: 'children'
} as const;
export type ListTransactionsJointTransactionDisplayModeEnum = typeof ListTransactionsJointTransactionDisplayModeEnum[keyof typeof ListTransactionsJointTransactionDisplayModeEnum];


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new User document.
         * @param {CreateUserRequest} CreateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (CreateUserRequest: CreateUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateUserRequest' is not null or undefined
            assertParamExists('createUser', 'CreateUserRequest', CreateUserRequest)
            const localVarPath = `/v2/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a User document.
         * @param {string} user_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (user_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('deleteUser', 'user_id', user_id)
            const localVarPath = `/v2/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends a password reset link
         * @param {ForgotRequest} ForgotRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgot: async (ForgotRequest: ForgotRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ForgotRequest' is not null or undefined
            assertParamExists('forgot', 'ForgotRequest', ForgotRequest)
            const localVarPath = `/v2/users/forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ForgotRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelf: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/users/self`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single User document by ID.
         * @param {string | null} user_id 
         * @param {boolean} [populate_advisor_groups] 
         * @param {boolean} [populate_advisory_group_memberships] 
         * @param {boolean} [populate_advisory_groups_extegrations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (user_id: string | null, populate_advisor_groups?: boolean, populate_advisory_group_memberships?: boolean, populate_advisory_groups_extegrations?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('getUser', 'user_id', user_id)
            const localVarPath = `/v2/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_advisor_groups !== undefined) {
                localVarQueryParameter['populate_advisor_groups'] = populate_advisor_groups;
            }

            if (populate_advisory_group_memberships !== undefined) {
                localVarQueryParameter['populate_advisory_group_memberships'] = populate_advisory_group_memberships;
            }

            if (populate_advisory_groups_extegrations !== undefined) {
                localVarQueryParameter['populate_advisory_groups_extegrations'] = populate_advisory_groups_extegrations;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get users
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListUsersSortFieldEnum} [sort_field] 
         * @param {ListUsersSortDirectionEnum} [sort_direction] 
         * @param {boolean} [populate_accounts] 
         * @param {boolean} [populate_investments] 
         * @param {boolean} [populate_advisor_groups] 
         * @param {boolean} [populate_advisory_group_memberships] 
         * @param {boolean} [include_deleted] 
         * @param {string} [account_search] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {ListUsersAccessEnum} [access] 
         * @param {string} [search] 
         * @param {Array<string>} [advisory_groups] 
         * @param {boolean} [include_has_password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers: async (skip?: number, limit?: number, sort_field?: ListUsersSortFieldEnum, sort_direction?: ListUsersSortDirectionEnum, populate_accounts?: boolean, populate_investments?: boolean, populate_advisor_groups?: boolean, populate_advisory_group_memberships?: boolean, include_deleted?: boolean, account_search?: string, users?: Array<string>, accounts?: Array<string>, access?: ListUsersAccessEnum, search?: string, advisory_groups?: Array<string>, include_has_password?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (populate_accounts !== undefined) {
                localVarQueryParameter['populate_accounts'] = populate_accounts;
            }

            if (populate_investments !== undefined) {
                localVarQueryParameter['populate_investments'] = populate_investments;
            }

            if (populate_advisor_groups !== undefined) {
                localVarQueryParameter['populate_advisor_groups'] = populate_advisor_groups;
            }

            if (populate_advisory_group_memberships !== undefined) {
                localVarQueryParameter['populate_advisory_group_memberships'] = populate_advisory_group_memberships;
            }

            if (include_deleted !== undefined) {
                localVarQueryParameter['include_deleted'] = include_deleted;
            }

            if (account_search !== undefined) {
                localVarQueryParameter['account_search'] = account_search;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (accounts) {
                localVarQueryParameter['accounts'] = accounts;
            }

            if (access !== undefined) {
                localVarQueryParameter['access'] = access;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (advisory_groups) {
                localVarQueryParameter['advisory_groups'] = advisory_groups;
            }

            if (include_has_password !== undefined) {
                localVarQueryParameter['include_has_password'] = include_has_password;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Login a user.
         * @param {LoginRequest} LoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (LoginRequest: LoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'LoginRequest' is not null or undefined
            assertParamExists('login', 'LoginRequest', LoginRequest)
            const localVarPath = `/v2/users/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(LoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Login an existing user with Google Identity API.
         * @param {LoginUserWithGoogleRequest} LoginUserWithGoogleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUserWithGoogle: async (LoginUserWithGoogleRequest: LoginUserWithGoogleRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'LoginUserWithGoogleRequest' is not null or undefined
            assertParamExists('loginUserWithGoogle', 'LoginUserWithGoogleRequest', LoginUserWithGoogleRequest)
            const localVarPath = `/v2/users/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(LoginUserWithGoogleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logout a user.
         * @param {LogoutRequest} [LogoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (LogoutRequest?: LogoutRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/users/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(LogoutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Resend verification email for a user.
         * @param {string} reverify 
         * @param {ResendVerifyRequest} [ResendVerifyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendVerify: async (reverify: string, ResendVerifyRequest?: ResendVerifyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reverify' is not null or undefined
            assertParamExists('resendVerify', 'reverify', reverify)
            const localVarPath = `/v2/users/{reverify}`
                .replace(`{${"reverify"}}`, encodeURIComponent(String(reverify)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ResendVerifyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends a password reset link
         * @param {string} user_id 
         * @param {ResetRequest} [ResetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reset: async (user_id: string, ResetRequest?: ResetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('reset', 'user_id', user_id)
            const localVarPath = `/v2/users/{user_id}/reset`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ResetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send setup email for a user.
         * @param {SendSetupEmailRequest} [SendSetupEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSetupEmail: async (SendSetupEmailRequest?: SendSetupEmailRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/users/send_setup_email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(SendSetupEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a token for a user.
         * @param {string} user_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        session: async (user_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('session', 'user_id', user_id)
            const localVarPath = `/v2/users/{user_id}/session`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a User document.
         * @param {string} user_id 
         * @param {UpdateUserRequest} [UpdateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (user_id: string, UpdateUserRequest?: UpdateUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('updateUser', 'user_id', user_id)
            const localVarPath = `/v2/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verify token for a user.
         * @param {string} user_id 
         * @param {VerifyRequest} VerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verify: async (user_id: string, VerifyRequest: VerifyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('verify', 'user_id', user_id)
            // verify required parameter 'VerifyRequest' is not null or undefined
            assertParamExists('verify', 'VerifyRequest', VerifyRequest)
            const localVarPath = `/v2/users/{user_id}/verify`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(VerifyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new User document.
         * @param {CreateUserRequest} CreateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(CreateUserRequest: CreateUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUser201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(CreateUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.createUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a User document.
         * @param {string} user_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(user_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(user_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.deleteUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Sends a password reset link
         * @param {ForgotRequest} ForgotRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgot(ForgotRequest: ForgotRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunSlaIssues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgot(ForgotRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.forgot']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelf(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSelf200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelf(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getSelf']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single User document by ID.
         * @param {string | null} user_id 
         * @param {boolean} [populate_advisor_groups] 
         * @param {boolean} [populate_advisory_group_memberships] 
         * @param {boolean} [populate_advisory_groups_extegrations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(user_id: string | null, populate_advisor_groups?: boolean, populate_advisory_group_memberships?: boolean, populate_advisory_groups_extegrations?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUser200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(user_id, populate_advisor_groups, populate_advisory_group_memberships, populate_advisory_groups_extegrations, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get users
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListUsersSortFieldEnum} [sort_field] 
         * @param {ListUsersSortDirectionEnum} [sort_direction] 
         * @param {boolean} [populate_accounts] 
         * @param {boolean} [populate_investments] 
         * @param {boolean} [populate_advisor_groups] 
         * @param {boolean} [populate_advisory_group_memberships] 
         * @param {boolean} [include_deleted] 
         * @param {string} [account_search] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {ListUsersAccessEnum} [access] 
         * @param {string} [search] 
         * @param {Array<string>} [advisory_groups] 
         * @param {boolean} [include_has_password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsers(skip?: number, limit?: number, sort_field?: ListUsersSortFieldEnum, sort_direction?: ListUsersSortDirectionEnum, populate_accounts?: boolean, populate_investments?: boolean, populate_advisor_groups?: boolean, populate_advisory_group_memberships?: boolean, include_deleted?: boolean, account_search?: string, users?: Array<string>, accounts?: Array<string>, access?: ListUsersAccessEnum, search?: string, advisory_groups?: Array<string>, include_has_password?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUsers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsers(skip, limit, sort_field, sort_direction, populate_accounts, populate_investments, populate_advisor_groups, populate_advisory_group_memberships, include_deleted, account_search, users, accounts, access, search, advisory_groups, include_has_password, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.listUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Login a user.
         * @param {LoginRequest} LoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(LoginRequest: LoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginUserWithGoogle200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(LoginRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.login']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Login an existing user with Google Identity API.
         * @param {LoginUserWithGoogleRequest} LoginUserWithGoogleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginUserWithGoogle(LoginUserWithGoogleRequest: LoginUserWithGoogleRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginUserWithGoogle200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginUserWithGoogle(LoginUserWithGoogleRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.loginUserWithGoogle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Logout a user.
         * @param {LogoutRequest} [LogoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(LogoutRequest?: LogoutRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(LogoutRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.logout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Resend verification email for a user.
         * @param {string} reverify 
         * @param {ResendVerifyRequest} [ResendVerifyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendVerify(reverify: string, ResendVerifyRequest?: ResendVerifyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResolveAuditResult200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendVerify(reverify, ResendVerifyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.resendVerify']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Sends a password reset link
         * @param {string} user_id 
         * @param {ResetRequest} [ResetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reset(user_id: string, ResetRequest?: ResetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunSlaIssues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reset(user_id, ResetRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.reset']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Send setup email for a user.
         * @param {SendSetupEmailRequest} [SendSetupEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSetupEmail(SendSetupEmailRequest?: SendSetupEmailRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResolveAuditResult200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSetupEmail(SendSetupEmailRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.sendSetupEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a token for a user.
         * @param {string} user_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async session(user_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginUserWithGoogle200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.session(user_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.session']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a User document.
         * @param {string} user_id 
         * @param {UpdateUserRequest} [UpdateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(user_id: string, UpdateUserRequest?: UpdateUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(user_id, UpdateUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Verify token for a user.
         * @param {string} user_id 
         * @param {VerifyRequest} VerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verify(user_id: string, VerifyRequest: VerifyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResolveAuditResult200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verify(user_id, VerifyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.verify']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Create a new User document.
         * @param {UserApiCreateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(requestParameters: UserApiCreateUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateUser201Response> {
            return localVarFp.createUser(requestParameters.CreateUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a User document.
         * @param {UserApiDeleteUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(requestParameters: UserApiDeleteUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteUser(requestParameters.user_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends a password reset link
         * @param {UserApiForgotRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgot(requestParameters: UserApiForgotRequest, options?: RawAxiosRequestConfig): AxiosPromise<RunSlaIssues200Response> {
            return localVarFp.forgot(requestParameters.ForgotRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelf(options?: RawAxiosRequestConfig): AxiosPromise<GetSelf200Response> {
            return localVarFp.getSelf(options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single User document by ID.
         * @param {UserApiGetUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(requestParameters: UserApiGetUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetUser200Response> {
            return localVarFp.getUser(requestParameters.user_id, requestParameters.populate_advisor_groups, requestParameters.populate_advisory_group_memberships, requestParameters.populate_advisory_groups_extegrations, options).then((request) => request(axios, basePath));
        },
        /**
         * Get users
         * @param {UserApiListUsersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(requestParameters: UserApiListUsersRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListUsers200Response> {
            return localVarFp.listUsers(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.populate_accounts, requestParameters.populate_investments, requestParameters.populate_advisor_groups, requestParameters.populate_advisory_group_memberships, requestParameters.include_deleted, requestParameters.account_search, requestParameters.users, requestParameters.accounts, requestParameters.access, requestParameters.search, requestParameters.advisory_groups, requestParameters.include_has_password, options).then((request) => request(axios, basePath));
        },
        /**
         * Login a user.
         * @param {UserApiLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(requestParameters: UserApiLoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<LoginUserWithGoogle200Response> {
            return localVarFp.login(requestParameters.LoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Login an existing user with Google Identity API.
         * @param {UserApiLoginUserWithGoogleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUserWithGoogle(requestParameters: UserApiLoginUserWithGoogleRequest, options?: RawAxiosRequestConfig): AxiosPromise<LoginUserWithGoogle200Response> {
            return localVarFp.loginUserWithGoogle(requestParameters.LoginUserWithGoogleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Logout a user.
         * @param {UserApiLogoutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(requestParameters: UserApiLogoutRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.logout(requestParameters.LogoutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Resend verification email for a user.
         * @param {UserApiResendVerifyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendVerify(requestParameters: UserApiResendVerifyRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResolveAuditResult200Response> {
            return localVarFp.resendVerify(requestParameters.reverify, requestParameters.ResendVerifyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends a password reset link
         * @param {UserApiResetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reset(requestParameters: UserApiResetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RunSlaIssues200Response> {
            return localVarFp.reset(requestParameters.user_id, requestParameters.ResetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Send setup email for a user.
         * @param {UserApiSendSetupEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSetupEmail(requestParameters: UserApiSendSetupEmailRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ResolveAuditResult200Response> {
            return localVarFp.sendSetupEmail(requestParameters.SendSetupEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a token for a user.
         * @param {UserApiSessionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        session(requestParameters: UserApiSessionRequest, options?: RawAxiosRequestConfig): AxiosPromise<LoginUserWithGoogle200Response> {
            return localVarFp.session(requestParameters.user_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a User document.
         * @param {UserApiUpdateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(requestParameters: UserApiUpdateUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateUser(requestParameters.user_id, requestParameters.UpdateUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Verify token for a user.
         * @param {UserApiVerifyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verify(requestParameters: UserApiVerifyRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResolveAuditResult200Response> {
            return localVarFp.verify(requestParameters.user_id, requestParameters.VerifyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createUser operation in UserApi.
 * @export
 * @interface UserApiCreateUserRequest
 */
export interface UserApiCreateUserRequest {
    /**
     * 
     * @type {CreateUserRequest}
     * @memberof UserApiCreateUser
     */
    readonly CreateUserRequest: CreateUserRequest
}

/**
 * Request parameters for deleteUser operation in UserApi.
 * @export
 * @interface UserApiDeleteUserRequest
 */
export interface UserApiDeleteUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiDeleteUser
     */
    readonly user_id: string
}

/**
 * Request parameters for forgot operation in UserApi.
 * @export
 * @interface UserApiForgotRequest
 */
export interface UserApiForgotRequest {
    /**
     * 
     * @type {ForgotRequest}
     * @memberof UserApiForgot
     */
    readonly ForgotRequest: ForgotRequest
}

/**
 * Request parameters for getUser operation in UserApi.
 * @export
 * @interface UserApiGetUserRequest
 */
export interface UserApiGetUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiGetUser
     */
    readonly user_id: string | null

    /**
     * 
     * @type {boolean}
     * @memberof UserApiGetUser
     */
    readonly populate_advisor_groups?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UserApiGetUser
     */
    readonly populate_advisory_group_memberships?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UserApiGetUser
     */
    readonly populate_advisory_groups_extegrations?: boolean
}

/**
 * Request parameters for listUsers operation in UserApi.
 * @export
 * @interface UserApiListUsersRequest
 */
export interface UserApiListUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiListUsers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof UserApiListUsers
     */
    readonly limit?: number

    /**
     * 
     * @type {'name' | 'email' | 'email_verification.verified' | 'type' | 'status' | 'is_deactivated' | 'access'}
     * @memberof UserApiListUsers
     */
    readonly sort_field?: ListUsersSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof UserApiListUsers
     */
    readonly sort_direction?: ListUsersSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof UserApiListUsers
     */
    readonly populate_accounts?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UserApiListUsers
     */
    readonly populate_investments?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UserApiListUsers
     */
    readonly populate_advisor_groups?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UserApiListUsers
     */
    readonly populate_advisory_group_memberships?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UserApiListUsers
     */
    readonly include_deleted?: boolean

    /**
     * 
     * @type {string}
     * @memberof UserApiListUsers
     */
    readonly account_search?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof UserApiListUsers
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof UserApiListUsers
     */
    readonly accounts?: Array<string>

    /**
     * 
     * @type {'admin' | 'advisor' | 'investor'}
     * @memberof UserApiListUsers
     */
    readonly access?: ListUsersAccessEnum

    /**
     * 
     * @type {string}
     * @memberof UserApiListUsers
     */
    readonly search?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof UserApiListUsers
     */
    readonly advisory_groups?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof UserApiListUsers
     */
    readonly include_has_password?: boolean
}

/**
 * Request parameters for login operation in UserApi.
 * @export
 * @interface UserApiLoginRequest
 */
export interface UserApiLoginRequest {
    /**
     * 
     * @type {LoginRequest}
     * @memberof UserApiLogin
     */
    readonly LoginRequest: LoginRequest
}

/**
 * Request parameters for loginUserWithGoogle operation in UserApi.
 * @export
 * @interface UserApiLoginUserWithGoogleRequest
 */
export interface UserApiLoginUserWithGoogleRequest {
    /**
     * 
     * @type {LoginUserWithGoogleRequest}
     * @memberof UserApiLoginUserWithGoogle
     */
    readonly LoginUserWithGoogleRequest: LoginUserWithGoogleRequest
}

/**
 * Request parameters for logout operation in UserApi.
 * @export
 * @interface UserApiLogoutRequest
 */
export interface UserApiLogoutRequest {
    /**
     * 
     * @type {LogoutRequest}
     * @memberof UserApiLogout
     */
    readonly LogoutRequest?: LogoutRequest
}

/**
 * Request parameters for resendVerify operation in UserApi.
 * @export
 * @interface UserApiResendVerifyRequest
 */
export interface UserApiResendVerifyRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiResendVerify
     */
    readonly reverify: string

    /**
     * 
     * @type {ResendVerifyRequest}
     * @memberof UserApiResendVerify
     */
    readonly ResendVerifyRequest?: ResendVerifyRequest
}

/**
 * Request parameters for reset operation in UserApi.
 * @export
 * @interface UserApiResetRequest
 */
export interface UserApiResetRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiReset
     */
    readonly user_id: string

    /**
     * 
     * @type {ResetRequest}
     * @memberof UserApiReset
     */
    readonly ResetRequest?: ResetRequest
}

/**
 * Request parameters for sendSetupEmail operation in UserApi.
 * @export
 * @interface UserApiSendSetupEmailRequest
 */
export interface UserApiSendSetupEmailRequest {
    /**
     * 
     * @type {SendSetupEmailRequest}
     * @memberof UserApiSendSetupEmail
     */
    readonly SendSetupEmailRequest?: SendSetupEmailRequest
}

/**
 * Request parameters for session operation in UserApi.
 * @export
 * @interface UserApiSessionRequest
 */
export interface UserApiSessionRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiSession
     */
    readonly user_id: string
}

/**
 * Request parameters for updateUser operation in UserApi.
 * @export
 * @interface UserApiUpdateUserRequest
 */
export interface UserApiUpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUpdateUser
     */
    readonly user_id: string

    /**
     * 
     * @type {UpdateUserRequest}
     * @memberof UserApiUpdateUser
     */
    readonly UpdateUserRequest?: UpdateUserRequest
}

/**
 * Request parameters for verify operation in UserApi.
 * @export
 * @interface UserApiVerifyRequest
 */
export interface UserApiVerifyRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiVerify
     */
    readonly user_id: string

    /**
     * 
     * @type {VerifyRequest}
     * @memberof UserApiVerify
     */
    readonly VerifyRequest: VerifyRequest
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Create a new User document.
     * @param {UserApiCreateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUser(requestParameters: UserApiCreateUserRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).createUser(requestParameters.CreateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a User document.
     * @param {UserApiDeleteUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(requestParameters: UserApiDeleteUserRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUser(requestParameters.user_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends a password reset link
     * @param {UserApiForgotRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public forgot(requestParameters: UserApiForgotRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).forgot(requestParameters.ForgotRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getSelf(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getSelf(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single User document by ID.
     * @param {UserApiGetUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(requestParameters: UserApiGetUserRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUser(requestParameters.user_id, requestParameters.populate_advisor_groups, requestParameters.populate_advisory_group_memberships, requestParameters.populate_advisory_groups_extegrations, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get users
     * @param {UserApiListUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public listUsers(requestParameters: UserApiListUsersRequest = {}, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).listUsers(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.populate_accounts, requestParameters.populate_investments, requestParameters.populate_advisor_groups, requestParameters.populate_advisory_group_memberships, requestParameters.include_deleted, requestParameters.account_search, requestParameters.users, requestParameters.accounts, requestParameters.access, requestParameters.search, requestParameters.advisory_groups, requestParameters.include_has_password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Login a user.
     * @param {UserApiLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public login(requestParameters: UserApiLoginRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).login(requestParameters.LoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Login an existing user with Google Identity API.
     * @param {UserApiLoginUserWithGoogleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public loginUserWithGoogle(requestParameters: UserApiLoginUserWithGoogleRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).loginUserWithGoogle(requestParameters.LoginUserWithGoogleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logout a user.
     * @param {UserApiLogoutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public logout(requestParameters: UserApiLogoutRequest = {}, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).logout(requestParameters.LogoutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Resend verification email for a user.
     * @param {UserApiResendVerifyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public resendVerify(requestParameters: UserApiResendVerifyRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).resendVerify(requestParameters.reverify, requestParameters.ResendVerifyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends a password reset link
     * @param {UserApiResetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public reset(requestParameters: UserApiResetRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).reset(requestParameters.user_id, requestParameters.ResetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send setup email for a user.
     * @param {UserApiSendSetupEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public sendSetupEmail(requestParameters: UserApiSendSetupEmailRequest = {}, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).sendSetupEmail(requestParameters.SendSetupEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a token for a user.
     * @param {UserApiSessionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public session(requestParameters: UserApiSessionRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).session(requestParameters.user_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a User document.
     * @param {UserApiUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(requestParameters: UserApiUpdateUserRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUser(requestParameters.user_id, requestParameters.UpdateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verify token for a user.
     * @param {UserApiVerifyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public verify(requestParameters: UserApiVerifyRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).verify(requestParameters.user_id, requestParameters.VerifyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListUsersSortFieldEnum = {
    Name: 'name',
    Email: 'email',
    EmailVerificationVerified: 'email_verification.verified',
    Type: 'type',
    Status: 'status',
    IsDeactivated: 'is_deactivated',
    Access: 'access'
} as const;
export type ListUsersSortFieldEnum = typeof ListUsersSortFieldEnum[keyof typeof ListUsersSortFieldEnum];
/**
 * @export
 */
export const ListUsersSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListUsersSortDirectionEnum = typeof ListUsersSortDirectionEnum[keyof typeof ListUsersSortDirectionEnum];
/**
 * @export
 */
export const ListUsersAccessEnum = {
    Admin: 'admin',
    Advisor: 'advisor',
    Investor: 'investor'
} as const;
export type ListUsersAccessEnum = typeof ListUsersAccessEnum[keyof typeof ListUsersAccessEnum];


/**
 * ValuationApi - axios parameter creator
 * @export
 */
export const ValuationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Valuation document.
         * @param {CreateValuationRequest} CreateValuationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createValuation: async (CreateValuationRequest: CreateValuationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateValuationRequest' is not null or undefined
            assertParamExists('createValuation', 'CreateValuationRequest', CreateValuationRequest)
            const localVarPath = `/v2/valuations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateValuationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Valuation document.
         * @param {string} valuation_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteValuation: async (valuation_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'valuation_id' is not null or undefined
            assertParamExists('deleteValuation', 'valuation_id', valuation_id)
            const localVarPath = `/v2/valuations/{valuation_id}`
                .replace(`{${"valuation_id"}}`, encodeURIComponent(String(valuation_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single Valuation document by ID.
         * @param {string | null} valuation_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_documents] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValuation: async (valuation_id: string | null, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_documents?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'valuation_id' is not null or undefined
            assertParamExists('getValuation', 'valuation_id', valuation_id)
            const localVarPath = `/v2/valuations/{valuation_id}`
                .replace(`{${"valuation_id"}}`, encodeURIComponent(String(valuation_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_investment !== undefined) {
                localVarQueryParameter['populate_investment'] = populate_investment;
            }

            if (populate_documents !== undefined) {
                localVarQueryParameter['populate_documents'] = populate_documents;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Valuation documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListValuationsSortFieldEnum} [sort_field] 
         * @param {ListValuationsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [investments] 
         * @param {boolean} [hidden] 
         * @param {boolean} [inactive] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_documents] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listValuations: async (skip?: number, limit?: number, search?: string, sort_field?: ListValuationsSortFieldEnum, sort_direction?: ListValuationsSortDirectionEnum, users?: Array<string>, accounts?: Array<string>, investments?: Array<string>, hidden?: boolean, inactive?: boolean, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_documents?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/valuations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (accounts) {
                localVarQueryParameter['accounts'] = accounts;
            }

            if (investments) {
                localVarQueryParameter['investments'] = investments;
            }

            if (hidden !== undefined) {
                localVarQueryParameter['hidden'] = hidden;
            }

            if (inactive !== undefined) {
                localVarQueryParameter['inactive'] = inactive;
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_investment !== undefined) {
                localVarQueryParameter['populate_investment'] = populate_investment;
            }

            if (populate_documents !== undefined) {
                localVarQueryParameter['populate_documents'] = populate_documents;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Valuation document.
         * @param {string} valuation_id 
         * @param {UpdateValuationRequest} [UpdateValuationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateValuation: async (valuation_id: string, UpdateValuationRequest?: UpdateValuationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'valuation_id' is not null or undefined
            assertParamExists('updateValuation', 'valuation_id', valuation_id)
            const localVarPath = `/v2/valuations/{valuation_id}`
                .replace(`{${"valuation_id"}}`, encodeURIComponent(String(valuation_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateValuationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ValuationApi - functional programming interface
 * @export
 */
export const ValuationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ValuationApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Valuation document.
         * @param {CreateValuationRequest} CreateValuationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createValuation(CreateValuationRequest: CreateValuationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateValuation201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createValuation(CreateValuationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ValuationApi.createValuation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a Valuation document.
         * @param {string} valuation_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteValuation(valuation_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteValuation(valuation_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ValuationApi.deleteValuation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single Valuation document by ID.
         * @param {string | null} valuation_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_documents] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getValuation(valuation_id: string | null, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_documents?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetValuation200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getValuation(valuation_id, populate_user, populate_account, populate_investment, populate_documents, populate_connection, populate_audit_log, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ValuationApi.getValuation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Valuation documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListValuationsSortFieldEnum} [sort_field] 
         * @param {ListValuationsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [investments] 
         * @param {boolean} [hidden] 
         * @param {boolean} [inactive] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_documents] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listValuations(skip?: number, limit?: number, search?: string, sort_field?: ListValuationsSortFieldEnum, sort_direction?: ListValuationsSortDirectionEnum, users?: Array<string>, accounts?: Array<string>, investments?: Array<string>, hidden?: boolean, inactive?: boolean, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_documents?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListValuations200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listValuations(skip, limit, search, sort_field, sort_direction, users, accounts, investments, hidden, inactive, populate_user, populate_account, populate_investment, populate_documents, populate_connection, populate_audit_log, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ValuationApi.listValuations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing Valuation document.
         * @param {string} valuation_id 
         * @param {UpdateValuationRequest} [UpdateValuationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateValuation(valuation_id: string, UpdateValuationRequest?: UpdateValuationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateValuation(valuation_id, UpdateValuationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ValuationApi.updateValuation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ValuationApi - factory interface
 * @export
 */
export const ValuationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ValuationApiFp(configuration)
    return {
        /**
         * Create a new Valuation document.
         * @param {ValuationApiCreateValuationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createValuation(requestParameters: ValuationApiCreateValuationRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateValuation201Response> {
            return localVarFp.createValuation(requestParameters.CreateValuationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Valuation document.
         * @param {ValuationApiDeleteValuationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteValuation(requestParameters: ValuationApiDeleteValuationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteValuation(requestParameters.valuation_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single Valuation document by ID.
         * @param {ValuationApiGetValuationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValuation(requestParameters: ValuationApiGetValuationRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetValuation200Response> {
            return localVarFp.getValuation(requestParameters.valuation_id, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_documents, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Valuation documents.
         * @param {ValuationApiListValuationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listValuations(requestParameters: ValuationApiListValuationsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListValuations200Response> {
            return localVarFp.listValuations(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.accounts, requestParameters.investments, requestParameters.hidden, requestParameters.inactive, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_documents, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Valuation document.
         * @param {ValuationApiUpdateValuationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateValuation(requestParameters: ValuationApiUpdateValuationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateValuation(requestParameters.valuation_id, requestParameters.UpdateValuationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createValuation operation in ValuationApi.
 * @export
 * @interface ValuationApiCreateValuationRequest
 */
export interface ValuationApiCreateValuationRequest {
    /**
     * 
     * @type {CreateValuationRequest}
     * @memberof ValuationApiCreateValuation
     */
    readonly CreateValuationRequest: CreateValuationRequest
}

/**
 * Request parameters for deleteValuation operation in ValuationApi.
 * @export
 * @interface ValuationApiDeleteValuationRequest
 */
export interface ValuationApiDeleteValuationRequest {
    /**
     * 
     * @type {string}
     * @memberof ValuationApiDeleteValuation
     */
    readonly valuation_id: string
}

/**
 * Request parameters for getValuation operation in ValuationApi.
 * @export
 * @interface ValuationApiGetValuationRequest
 */
export interface ValuationApiGetValuationRequest {
    /**
     * 
     * @type {string}
     * @memberof ValuationApiGetValuation
     */
    readonly valuation_id: string | null

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiGetValuation
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiGetValuation
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiGetValuation
     */
    readonly populate_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiGetValuation
     */
    readonly populate_documents?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiGetValuation
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiGetValuation
     */
    readonly populate_audit_log?: boolean
}

/**
 * Request parameters for listValuations operation in ValuationApi.
 * @export
 * @interface ValuationApiListValuationsRequest
 */
export interface ValuationApiListValuationsRequest {
    /**
     * 
     * @type {number}
     * @memberof ValuationApiListValuations
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ValuationApiListValuations
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ValuationApiListValuations
     */
    readonly search?: string

    /**
     * 
     * @type {'date' | 'amount'}
     * @memberof ValuationApiListValuations
     */
    readonly sort_field?: ListValuationsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ValuationApiListValuations
     */
    readonly sort_direction?: ListValuationsSortDirectionEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof ValuationApiListValuations
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ValuationApiListValuations
     */
    readonly accounts?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ValuationApiListValuations
     */
    readonly investments?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiListValuations
     */
    readonly hidden?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiListValuations
     */
    readonly inactive?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiListValuations
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiListValuations
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiListValuations
     */
    readonly populate_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiListValuations
     */
    readonly populate_documents?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiListValuations
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiListValuations
     */
    readonly populate_audit_log?: boolean
}

/**
 * Request parameters for updateValuation operation in ValuationApi.
 * @export
 * @interface ValuationApiUpdateValuationRequest
 */
export interface ValuationApiUpdateValuationRequest {
    /**
     * 
     * @type {string}
     * @memberof ValuationApiUpdateValuation
     */
    readonly valuation_id: string

    /**
     * 
     * @type {UpdateValuationRequest}
     * @memberof ValuationApiUpdateValuation
     */
    readonly UpdateValuationRequest?: UpdateValuationRequest
}

/**
 * ValuationApi - object-oriented interface
 * @export
 * @class ValuationApi
 * @extends {BaseAPI}
 */
export class ValuationApi extends BaseAPI {
    /**
     * Create a new Valuation document.
     * @param {ValuationApiCreateValuationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValuationApi
     */
    public createValuation(requestParameters: ValuationApiCreateValuationRequest, options?: RawAxiosRequestConfig) {
        return ValuationApiFp(this.configuration).createValuation(requestParameters.CreateValuationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Valuation document.
     * @param {ValuationApiDeleteValuationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValuationApi
     */
    public deleteValuation(requestParameters: ValuationApiDeleteValuationRequest, options?: RawAxiosRequestConfig) {
        return ValuationApiFp(this.configuration).deleteValuation(requestParameters.valuation_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single Valuation document by ID.
     * @param {ValuationApiGetValuationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValuationApi
     */
    public getValuation(requestParameters: ValuationApiGetValuationRequest, options?: RawAxiosRequestConfig) {
        return ValuationApiFp(this.configuration).getValuation(requestParameters.valuation_id, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_documents, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Valuation documents.
     * @param {ValuationApiListValuationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValuationApi
     */
    public listValuations(requestParameters: ValuationApiListValuationsRequest = {}, options?: RawAxiosRequestConfig) {
        return ValuationApiFp(this.configuration).listValuations(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.accounts, requestParameters.investments, requestParameters.hidden, requestParameters.inactive, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_documents, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Valuation document.
     * @param {ValuationApiUpdateValuationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValuationApi
     */
    public updateValuation(requestParameters: ValuationApiUpdateValuationRequest, options?: RawAxiosRequestConfig) {
        return ValuationApiFp(this.configuration).updateValuation(requestParameters.valuation_id, requestParameters.UpdateValuationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListValuationsSortFieldEnum = {
    Date: 'date',
    Amount: 'amount'
} as const;
export type ListValuationsSortFieldEnum = typeof ListValuationsSortFieldEnum[keyof typeof ListValuationsSortFieldEnum];
/**
 * @export
 */
export const ListValuationsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListValuationsSortDirectionEnum = typeof ListValuationsSortDirectionEnum[keyof typeof ListValuationsSortDirectionEnum];


/**
 * WebhookApi - axios parameter creator
 * @export
 */
export const WebhookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Receive webhook events from Docusign and update RAL Request status.
         * @param {ReceiveDocusignHookRequest} [ReceiveDocusignHookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveDocusignHook: async (ReceiveDocusignHookRequest?: ReceiveDocusignHookRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/ralrequests/docusign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ReceiveDocusignHookRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receive webhook events from TextVerified and process SMS data.
         * @param {ReceiveTextVerifiedHookRequest} [ReceiveTextVerifiedHookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveTextVerifiedHook: async (ReceiveTextVerifiedHookRequest?: ReceiveTextVerifiedHookRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/internal/textverified`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ReceiveTextVerifiedHookRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhookApi - functional programming interface
 * @export
 */
export const WebhookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhookApiAxiosParamCreator(configuration)
    return {
        /**
         * Receive webhook events from Docusign and update RAL Request status.
         * @param {ReceiveDocusignHookRequest} [ReceiveDocusignHookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiveDocusignHook(ReceiveDocusignHookRequest?: ReceiveDocusignHookRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunSlaIssues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiveDocusignHook(ReceiveDocusignHookRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebhookApi.receiveDocusignHook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Receive webhook events from TextVerified and process SMS data.
         * @param {ReceiveTextVerifiedHookRequest} [ReceiveTextVerifiedHookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiveTextVerifiedHook(ReceiveTextVerifiedHookRequest?: ReceiveTextVerifiedHookRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunSlaIssues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiveTextVerifiedHook(ReceiveTextVerifiedHookRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebhookApi.receiveTextVerifiedHook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WebhookApi - factory interface
 * @export
 */
export const WebhookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhookApiFp(configuration)
    return {
        /**
         * Receive webhook events from Docusign and update RAL Request status.
         * @param {WebhookApiReceiveDocusignHookRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveDocusignHook(requestParameters: WebhookApiReceiveDocusignHookRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RunSlaIssues200Response> {
            return localVarFp.receiveDocusignHook(requestParameters.ReceiveDocusignHookRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Receive webhook events from TextVerified and process SMS data.
         * @param {WebhookApiReceiveTextVerifiedHookRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveTextVerifiedHook(requestParameters: WebhookApiReceiveTextVerifiedHookRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RunSlaIssues200Response> {
            return localVarFp.receiveTextVerifiedHook(requestParameters.ReceiveTextVerifiedHookRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for receiveDocusignHook operation in WebhookApi.
 * @export
 * @interface WebhookApiReceiveDocusignHookRequest
 */
export interface WebhookApiReceiveDocusignHookRequest {
    /**
     * 
     * @type {ReceiveDocusignHookRequest}
     * @memberof WebhookApiReceiveDocusignHook
     */
    readonly ReceiveDocusignHookRequest?: ReceiveDocusignHookRequest
}

/**
 * Request parameters for receiveTextVerifiedHook operation in WebhookApi.
 * @export
 * @interface WebhookApiReceiveTextVerifiedHookRequest
 */
export interface WebhookApiReceiveTextVerifiedHookRequest {
    /**
     * 
     * @type {ReceiveTextVerifiedHookRequest}
     * @memberof WebhookApiReceiveTextVerifiedHook
     */
    readonly ReceiveTextVerifiedHookRequest?: ReceiveTextVerifiedHookRequest
}

/**
 * WebhookApi - object-oriented interface
 * @export
 * @class WebhookApi
 * @extends {BaseAPI}
 */
export class WebhookApi extends BaseAPI {
    /**
     * Receive webhook events from Docusign and update RAL Request status.
     * @param {WebhookApiReceiveDocusignHookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookApi
     */
    public receiveDocusignHook(requestParameters: WebhookApiReceiveDocusignHookRequest = {}, options?: RawAxiosRequestConfig) {
        return WebhookApiFp(this.configuration).receiveDocusignHook(requestParameters.ReceiveDocusignHookRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Receive webhook events from TextVerified and process SMS data.
     * @param {WebhookApiReceiveTextVerifiedHookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookApi
     */
    public receiveTextVerifiedHook(requestParameters: WebhookApiReceiveTextVerifiedHookRequest = {}, options?: RawAxiosRequestConfig) {
        return WebhookApiFp(this.configuration).receiveTextVerifiedHook(requestParameters.ReceiveTextVerifiedHookRequest, options).then((request) => request(this.axios, this.basePath));
    }
}




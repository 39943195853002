import api from '../../api';
import { saveAs } from 'file-saver';
import api2 from '../../api2';

export const downloadDocumentFromUrl = async (url, name, download = true) => {
    if (!url) {
        return alert('There was an error downloading the document. The document URL does not exist.');
    }
    try {
        const resp = await api.getBlob(url);
        if (!resp) {
            return alert('There was an error downloading the document. The document request failed.');
        }
        if (download) {
            await saveAs(resp, name?.includes('.pdf') ? name : name + '.pdf');
        }
        return resp;
    } catch (error) {
        console.log('Failed to open document.');
        console.log(error);
        alert('There was an unknown error downloading the document.');
    }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const downloadDocument = async (document_id, isMobile = false, download = true) => {
    if (!document_id) {
        return alert('There was an error downloading the document. The document ID does not exist.');
    }
    try {
        // const doc = await api.get(`/documents/${document_id}`);
        // if (!(doc?.url ?? doc?.downloadUrl)) {
        //     return alert('There was an error downloading the document. The document data does not exist.')
        // }
        const doc = (await api2.client.DocumentApi.getDocument({ document_id })).data.document;
        return await downloadDocumentFromUrl(doc.file_download_urls.doc, doc.name, download);
    } catch (error) {
        console.log('Failed to open document.');
        console.log(error);
        alert('There was an unknown error downloading the document.');
    }
};

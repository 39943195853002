import { Link } from 'react-router-dom';
import api from '../../api';
import api2 from '../../api2';
import renderOwnershipInfo from '../../common/documents/renderOwnershipInfo';
import { EM_DASH, VUES } from '../../constants/constantStrings';
import { ListAdminTeams200ResponseAdminTeamsInner } from '../../openApiClient';
import routes from '../../routes';
import { Document } from '../../types/Document';
import { Integration } from '../../types/Integration';
import { Investment, InvestmentAccount, InvestmentUser } from '../../types/Investment';
import { IInvestmentMaster } from '../../types/InvestmentMaster';
import { RALRequest, RALRequestSignedRal } from '../../types/RalRequest';
import { UserAccess } from '../../types/User';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import { toQueryString } from '../../utilities/apiHelpers/queryString';
import { capitalizeFirstEveryWord } from '../../utilities/format/capitalizeFirst';
import formatDate, { formatUTCDate } from '../../utilities/format/formatDate';
import { generateStringHash } from '../../utilities/string/generateStringHash';
import ObjectSearchSelectTs, { ObjectSearchSelectProps } from '../Dropdowns/ObjectSearchSelectTs';

type GenericSearchFilterProps<T> = Omit<ObjectSearchSelectProps<T>, 'getLabel' | 'loadOptions'> & {
    filter?: any;
    excludeDisplayFields?: (keyof T | 'ownership')[];
    includeDisplayFields?: (keyof T | 'ownership')[];
    disabled?: boolean;
    getLabel: (item: T) => string | null | undefined | JSX.Element;
    loadOptions: (inputValue: string, filter: any) => Promise<T[]>;
    vue?: (typeof VUES)[keyof typeof VUES] | null;
    linkDocument?: boolean;
    showInvestmentLink?: boolean;
    postProcessData?: (data: T[]) => Promise<T[]>;
};

const GenericSearchFilter = <T,>({
    filter = {},
    onChange,
    selected,
    isMulti = false,
    formatOptionLabel,
    width,
    defaultOptions = false,
    defaultLabel = '',
    excludeDisplayFields = [],
    label = '',
    additionalOptions,
    disabled = false,
    getLabel,
    loadOptions,
    postProcessData,
    placeholder = 'Select Item',
}: GenericSearchFilterProps<T>) => {
    let filterHash = generateStringHash(JSON.stringify(filter));

    return (
        <ObjectSearchSelectTs<T>
            key={filterHash}
            label={label}
            placeholder={`${placeholder}${isMulti ? 's' : ''}`}
            selected={selected}
            onChange={onChange}
            getLabel={getLabel}
            matchProperty="_id"
            loadOptions={async (inputValue: string) => {
                try {
                    // Use the same cleaned filter for the API call
                    let results = await loadOptions(inputValue, filter);
                    if (postProcessData && results.length) {
                        results = await postProcessData(results);
                    }
                    return results;
                } catch (e) {
                    console.error('Error loading items:', e);
                    return [];
                }
            }}
            width={width ?? '100%'}
            isMulti={isMulti}
            formatOptionLabel={(option) => {
                if (formatOptionLabel) return formatOptionLabel(option);
                const item: T = option.value;

                if (!item) return defaultLabel || 'None';
                return (
                    <div>
                        <div>{getLabel(item)}</div>
                    </div>
                );
            }}
            defaultLabel={defaultLabel || 'None'}
            defaultOptions={defaultOptions}
            additionalOptions={additionalOptions}
            isDisabled={disabled}
        />
    );
};

export { GenericSearchFilter };

const IntegrationSearchFilter = (props: Omit<GenericSearchFilterProps<Integration>, 'getLabel' | 'loadOptions'> & { includeManualIntegration?: boolean }) => {
    return (
        <GenericSearchFilter<Integration>
            {...props}
            getLabel={(integration: Integration) => integration?.name}
            loadOptions={async (inputValue: string, filter: any) => {
                try {
                    const integrations = (
                        await api2.client.IntegrationApi.listIntegrations({
                            ...filter,
                            search: inputValue,
                            limit: 50,
                        })
                    ).data.integrations;
                    if (props.includeManualIntegration && integrations) {
                        integrations.unshift({ name: 'AltX Manual Investments', _id: 'manual' });
                    }
                    return integrations as Integration[];
                } catch (e) {
                    console.error('Error loading integrations:', e);
                    return [];
                }
            }}
            formatOptionLabel={(option) => {
                if (props.formatOptionLabel) return props.formatOptionLabel(option);
                const integration: Integration = option.value;
                if (!integration) return props.defaultLabel || 'None';
                return (
                    <div>
                        <div>{integration.name}</div>
                    </div>
                );
            }}
        />
    );
};

export { IntegrationSearchFilter };

const AdvisoryGroupSearchFilter = (props: Omit<GenericSearchFilterProps<any>, 'getLabel' | 'loadOptions'>) => {
    return (
        <GenericSearchFilter<any>
            {...props}
            getLabel={(advisoryGroup: any) => advisoryGroup?.name}
            loadOptions={async (inputValue: string, filter: any) => {
                try {
                    const query = {
                        ...filter,
                        search: inputValue,
                        count: 50,
                        sortField: 'name',
                    };

                    const advisoryGroups = (await api.get(`/advisorygroups?${toQueryString(query)}`)).results;

                    return advisoryGroups as any[];
                } catch (e) {
                    console.error('Error loading advisory groups:', e);
                    return [];
                }
            }}
            formatOptionLabel={(option) => {
                if (props.formatOptionLabel) return props.formatOptionLabel(option);
                const advisoryGroup: any = option.value;

                if (!advisoryGroup) return props.defaultLabel || 'All Advisory Groups';

                return (
                    <div>
                        <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{advisoryGroup?.plan?.public_plan_name}</div>
                        <div>{advisoryGroup?.name}</div>
                    </div>
                );
            }}
        />
    );
};

export { AdvisoryGroupSearchFilter };

const UserSearchFilter = (props: Omit<GenericSearchFilterProps<any>, 'getLabel' | 'loadOptions'>) => {
    return (
        <GenericSearchFilter<any>
            {...props}
            getLabel={(user: any) => user?.name}
            loadOptions={async (inputValue: string, filter: any) => {
                try {
                    const userQuery = {
                        ...filter,
                        search: inputValue,
                        limit: 50,
                    };
                    const { data } = await api2.client.UserApi.listUsers(userQuery);

                    if (!data.success) return [];

                    return data.users ?? [];
                } catch (e) {
                    console.error('Error loading users:', e);
                    return [];
                }
            }}
            formatOptionLabel={(option) => {
                if (props.formatOptionLabel) return props.formatOptionLabel(option);
                const user: any = option.value;
                if (!user) return props.defaultLabel || 'All Users';
                return (
                    <div>
                        <div style={{}}>{user.name}</div>
                        <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{user.email}</div>
                    </div>
                );
            }}
        />
    );
};

export { UserSearchFilter };

const AccountSearchFilter = (props: Omit<GenericSearchFilterProps<any>, 'getLabel' | 'loadOptions'>) => {
    return (
        <GenericSearchFilter<any>
            {...props}
            getLabel={(account: any) => account?.name}
            loadOptions={async (inputValue: string, filter: any) => {
                try {
                    const accounts = (
                        await api2.client.AccountApi.listAccounts({
                            ...filter,
                            search: inputValue,
                            populate_user: true,
                            limit: 50,
                        })
                    ).data.accounts;

                    return accounts as any;
                } catch (e) {
                    console.error('Error loading accounts:', e);
                    return [];
                }
            }}
            formatOptionLabel={(option) => {
                if (props.formatOptionLabel) return props.formatOptionLabel(option);
                const account: any = option.value;

                if (!account) return props.defaultLabel || 'All Entities';
                const showUser = !(props.excludeDisplayFields ?? []).includes('user');
                const title = `${account.name}${account.user && showUser ? ` (${(account.user as any).name})` : ''}`;
                return (
                    <div title={title}>
                        {showUser && account.user && <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{(account.user as any).name}</div>}
                        <div style={{}}>{account.name}</div>
                    </div>
                );
            }}
        />
    );
};

export { AccountSearchFilter };

const ConnectionSearchFilter = (props: Omit<GenericSearchFilterProps<any>, 'getLabel' | 'loadOptions'>) => {
    return (
        <GenericSearchFilter<any>
            {...props}
            getLabel={(connection: any) => connection?.name}
            loadOptions={async (inputValue: string, filter: any) => {
                try {
                    const connections = (
                        await api2.client.ConnectionApi.listConnections({
                            ...filter,
                            search: inputValue,
                            populate_user: true,
                            limit: 50,
                        })
                    ).data.connections;

                    return connections as any[];
                } catch (e) {
                    console.error('Error loading connections:', e);
                    return [];
                }
            }}
            formatOptionLabel={(option) => {
                if (props.formatOptionLabel) return props.formatOptionLabel(option);
                const connection: any = option.value;

                if (!connection) return props.defaultLabel || 'All Connections';
                const showUser = !(props.excludeDisplayFields ?? []).includes('user');
                const title = `${connection.name}${connection.user?.name && showUser ? ` (${connection.user?.name})` : ''}`;
                return (
                    <div title={title}>
                        {showUser && connection.user && <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{connection.user?.name}</div>}
                        <div>{connection.name}</div>
                    </div>
                );
            }}
        />
    );
};

export { ConnectionSearchFilter };

const AssetManagerSearchFilter = (props: Omit<GenericSearchFilterProps<any>, 'getLabel' | 'loadOptions'>) => {
    return (
        <GenericSearchFilter<any>
            {...props}
            getLabel={(assetManager: any) => assetManager?.name}
            loadOptions={async (inputValue: string, filter: any) => {
                try {
                    const assetManagerQuery = {
                        ...filter,
                        search: inputValue,
                    };
                    const assetManagerQueryString = toQueryString(assetManagerQuery);
                    const assetManagers: any[] = (await api.get(`/assetmanagers?${assetManagerQueryString}`)).results ?? [];

                    return assetManagers;
                } catch (e) {
                    console.error('Error loading asset managers:', e);
                    return [];
                }
            }}
            formatOptionLabel={(option) => {
                if (props.formatOptionLabel) return props.formatOptionLabel(option);
                const assetManager: any = option.value;
                if (!assetManager) return props.defaultLabel || 'None';
                return (
                    <div>
                        <div style={{}}>{assetManager.name}</div>
                        <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{assetManager.website}</div>
                    </div>
                );
            }}
        />
    );
};

export { AssetManagerSearchFilter };

const MessageTagSearchFilter = (props: Omit<GenericSearchFilterProps<string>, 'getLabel' | 'loadOptions'>) => {
    return (
        <GenericSearchFilter<string>
            {...props}
            getLabel={(messageTag: string) => messageTag}
            loadOptions={async (inputValue: string, filter: any) => {
                try {
                    const messageTags = (await api2.client.MessagesApi.listMessageTags()).data.tags;
                    return messageTags ?? [];
                } catch (e) {
                    console.error('Error loading message tags:', e);
                    return [];
                }
            }}
        />
    );
};
export { MessageTagSearchFilter };

const ConnectionTagSearchFilter = (props: Omit<GenericSearchFilterProps<string>, 'getLabel' | 'loadOptions'>) => {
    return (
        <GenericSearchFilter<string>
            {...props}
            getLabel={(connectionTag: string) => connectionTag}
            loadOptions={async (inputValue: string, filter: any) => {
                try {
                    const connectionTags = (await api2.client.ConnectionApi.listConnectionTags()).data.tags;
                    return connectionTags ?? [];
                } catch (e) {
                    console.error('Error loading connection tags:', e);
                    return [];
                }
            }}
        />
    );
};
export { ConnectionTagSearchFilter };

const ConnectionUserSearchFilter = (props: Omit<GenericSearchFilterProps<any>, 'getLabel' | 'loadOptions'> & { connection_id: string; sync_id: string }) => {
    return (
        <GenericSearchFilter<any>
            {...props}
            getLabel={(user: any) => {
                return (
                    <>
                        <div>{user?.attributes?.name}</div>
                        <div style={{ fontSize: '12px', color: 'gray' }}>{user?.attributes?.email}</div>
                    </>
                );
            }}
            loadOptions={async (inputValue: string, filter: any) => {
                try {
                    const user = await api2.get(`/connections/${props.connection_id}/sync/${props.sync_id}/data/users?limit=50`);
                    return user.data.users ?? [];
                } catch (e) {
                    console.error('Error loading users:', e);
                    return [];
                }
            }}
        />
    );
};
export { ConnectionUserSearchFilter };

const ConnectionAccountSearchFilter = (props: Omit<GenericSearchFilterProps<any>, 'getLabel' | 'loadOptions'> & { connection_id: string; sync_id: string; user_id?: string }) => {
    return (
        <GenericSearchFilter<any>
            {...props}
            getLabel={(account: any) => account?.attributes?.name}
            loadOptions={async (inputValue: string, filter: any) => {
                const { connection_id, sync_id, user_id } = props;
                if (!sync_id || !connection_id) return [];
                let filterQuery = '';
                if (user_id) {
                    filterQuery += `&connection_user=${user_id}`;
                }
                if (inputValue) {
                    filterQuery += `&search=${inputValue}`;
                }
                try {
                    const accounts = await api2.get(`/connections/${connection_id}/sync/${sync_id}/data/accounts?limit=50${filterQuery}`);
                    return accounts.data.accounts ?? [];
                } catch (e) {
                    console.error('Error loading accounts:', e);
                    return [];
                }
            }}
        />
    );
};
export { ConnectionAccountSearchFilter };

const ConnectionInvestmentSearchFilter = (
    props: Omit<GenericSearchFilterProps<any>, 'getLabel' | 'loadOptions'> & { connection_id: string; sync_id: string; account_id?: string }
) => {
    return (
        <GenericSearchFilter<any>
            {...props}
            getLabel={(investment: any) =>
                `${investment?.attributes?.name} (${investment?.attributes?.type}${investment?.attributes?.subtype ? ` - ${investment?.attributes?.subtype}` : ''})`
            }
            loadOptions={async (inputValue: string, filter: any) => {
                const { connection_id, sync_id, account_id } = props;
                if (!sync_id || !connection_id) return [];
                let filterQuery = '';
                if (account_id) {
                    filterQuery += `&connection_account=${account_id}`;
                }
                if (inputValue) {
                    filterQuery += `&search=${inputValue}`;
                }
                try {
                    const investments = await api2.get(`/connections/${connection_id}/sync/${sync_id}/data/investments?limit=50${filterQuery}`);
                    return investments.data.investments ?? [];
                } catch (e) {
                    console.error('Error loading investments:', e);
                    return [];
                }
            }}
        />
    );
};
export { ConnectionInvestmentSearchFilter };

const AdvisoryGroupAssetManagerSearchFilter = (props: Omit<GenericSearchFilterProps<any>, 'getLabel' | 'loadOptions'> & { asset_manager_id: string }) => {
    return (
        <GenericSearchFilter<any>
            {...props}
            defaultLabel="All Advisory Groups"
            getLabel={(obj) => {
                if (!obj?._id) return <div>All Advisory Groups</div>;
                return <div>{obj.name}</div>;
            }}
            loadOptions={async (inputValue: string, filter: any) => {
                const { asset_manager_id } = props;
                if (!asset_manager_id) return [];
                let filterQuery = `asset_manager=${asset_manager_id}`;
                if (inputValue) {
                    filterQuery += `&search=${inputValue}`;
                }
                try {
                    const advisoryGroups = await api2.get(`/assetmanagers/portal/advisorygroups?${filterQuery}`);
                    return advisoryGroups.data.advisory_groups ?? [];
                } catch (e) {
                    console.error('Error loading advisory groups:', e);
                    return [];
                }
            }}
        />
    );
};
export { AdvisoryGroupAssetManagerSearchFilter };

const AdminTeamSearchFilter = (props: Omit<GenericSearchFilterProps<ListAdminTeams200ResponseAdminTeamsInner>, 'getLabel' | 'loadOptions'>) => {
    return (
        <GenericSearchFilter<ListAdminTeams200ResponseAdminTeamsInner>
            {...props}
            getLabel={(adminTeam: any) => adminTeam?.name}
            loadOptions={async (inputValue: string, filter: any) => {
                try {
                    const query = {
                        ...filter,
                        search: inputValue,
                        count: 50,
                        sortField: 'name',
                    };

                    const adminTeams = (
                        await api2.client.AdminTeamApi.listAdminTeams({
                            ...query,
                            limit: 50,
                        })
                    ).data.admin_teams;
                    if (!adminTeams) return [];

                    return adminTeams;
                } catch (e) {
                    console.error('Error loading admin teams:', e);
                    return [];
                }
            }}
            formatOptionLabel={(option) => {
                if (props.formatOptionLabel) return props.formatOptionLabel(option);
                const adminTeam: ListAdminTeams200ResponseAdminTeamsInner | null = option.value;

                if (!adminTeam) return props.defaultLabel || 'All Admin Teams';

                return (
                    <div>
                        {/* <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{adminTeam?.name}</div> */}
                        <div>{adminTeam?.name}</div>
                    </div>
                );
            }}
        />
    );
};
export { AdminTeamSearchFilter };

type AdminTeam = ListAdminTeams200ResponseAdminTeamsInner;

export function getGroupIdsFromAdminTeams(adminTeams: AdminTeam | AdminTeam[]): string[] {
    try {
        // Ensure adminTeams is an array
        const adminTeamsArray = Array.isArray(adminTeams) ? adminTeams : [adminTeams];

        const ids_list = adminTeamsArray.reduce((acc: string[], adminTeam) => {
            const these_group_ids =
                adminTeam?.advisory_groups
                    ?.map((group: any) => {
                        if (typeof group === 'string') {
                            return group;
                        } else if (typeof group === 'object' && group !== null) {
                            return group._id;
                        }
                        return undefined;
                    })
                    .filter((id: any): id is string => id !== undefined) ?? [];
            return [...acc, ...these_group_ids];
        }, [] as string[]);

        // Convert to set then back to array to remove duplicates
        return Array.from(new Set(ids_list));
    } catch (error) {
        console.error('Error getting group IDs from admin teams:', error);
        return [];
    }
}
const DocumentSearchFilter = (props: Omit<GenericSearchFilterProps<Document>, 'getLabel' | 'loadOptions'>) => {
    return (
        <GenericSearchFilter<Document>
            {...props}
            getLabel={(document: Document) => document?.name}
            loadOptions={async (inputValue: string, filter: any) => {
                try {
                    const documents = (
                        await api2.client.DocumentApi.listDocuments({
                            ...filter,
                            search: inputValue,
                            populate_user: true,
                            populate_ownership: true,
                            limit: 50,
                            sort_field: 'name',
                        })
                    ).data.documents;

                    return documents as any;
                } catch (e) {
                    console.error('Error loading documents:', e);
                    return [];
                }
            }}
            formatOptionLabel={(option) => {
                if (props.formatOptionLabel) return props.formatOptionLabel(option);
                const document: Document = option.value;
                if (!document) return props.defaultLabel || 'None';

                let path;
                if (props.vue && props.linkDocument) {
                    const userId = document.user?._id || (document.user as unknown as string);
                    path = routes.client.viewDocument({
                        userId,
                        access: props.vue as UserAccess,
                        documentId: document._id,
                    });
                }

                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                // marginBottom: '5px',
                                width: '100%',
                            }}
                        >
                            {!(props.excludeDisplayFields ?? []).includes('type') && <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{document.type}</div>}

                            {!(props.excludeDisplayFields ?? []).includes('date') ? (
                                <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{document.date ? formatDate(document.date) : EM_DASH}</div>
                            ) : (
                                <div />
                            )}
                        </div>

                        {!(props.excludeDisplayFields ?? []).includes('user') && (
                            <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{document.user?.name || EM_DASH}</div>
                        )}

                        {!(props.excludeDisplayFields ?? []).includes('ownership') && (
                            <div className={'table-ellipses'} style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>
                                {renderOwnershipInfo(document)}
                            </div>
                        )}

                        <div className={'table-ellipses'} style={{ fontSize: '14px' }} title={document.name}>
                            {path ? (
                                <a href={path} target="_blank" rel="noreferrer" style={{ color: 'var(--color-primary)' }}>
                                    {document.name}
                                </a>
                            ) : (
                                document.name
                            )}
                        </div>
                    </div>
                );
            }}
        />
    );
};
export { DocumentSearchFilter };

const InvestmentMasterSearchFilter = (props: Omit<GenericSearchFilterProps<IInvestmentMaster>, 'getLabel' | 'loadOptions'>) => {
    return (
        <GenericSearchFilter<IInvestmentMaster>
            {...props}
            getLabel={(investmentMaster: IInvestmentMaster) => investmentMaster?.name || 'Unnamed Investment Master'}
            loadOptions={async (inputValue: string) => {
                try {
                    const query = {
                        ...props.filter,
                        search: inputValue,
                        count: 50,
                    };
                    const queryStr = toQueryString(query);
                    const route = `/investmentmasters?${queryStr}`;
                    const investmentMasters: IInvestmentMaster[] = (await api.get(route)).results ?? [];
                    return investmentMasters;
                } catch (e) {
                    console.error('Error loading investment masters:', e);
                    return [];
                }
            }}
            formatOptionLabel={(option) => {
                if (props.formatOptionLabel) return props.formatOptionLabel(option);
                const investmentMaster: IInvestmentMaster = option.value;
                if (!investmentMaster) return props.defaultLabel || 'None';
                return (
                    <div>
                        <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{investmentMaster.ticker}</div>
                        <div style={{}}>{investmentMaster.name}</div>
                    </div>
                );
            }}
        />
    );
};
export { InvestmentMasterSearchFilter };

const InvestmentSearchFilter = (props: Omit<GenericSearchFilterProps<Investment>, 'getLabel' | 'loadOptions'>) => {
    return (
        <GenericSearchFilter<Investment>
            {...props}
            getLabel={(investment: any) => investment?.name}
            matchProperty="_id"
            loadOptions={async (inputValue: string) => {
                try {
                    // Debug API request params
                    const params = {
                        ...props.filter,
                        search: inputValue,
                        populate_account: true,
                        populate_user: true,
                        limit: 50,
                    };

                    const investments = (await api2.client.InvestmentApi.listInvestments(params)).data.investments;

                    return investments as Investment[];
                } catch (e) {
                    console.error('Error loading investments:', e);
                    return [];
                }
            }}
            formatOptionLabel={(option) => {
                if (props.formatOptionLabel) return props.formatOptionLabel(option);

                const investment: Investment | null = option.value;

                if (!investment) return 'All Investments';

                const excludeUser = (props.excludeDisplayFields ?? []).includes('user');
                const investmentLink = routes.client.investmentDetails({
                    access: UserPermissions().userAccess,
                    userId: typeof investment.user === 'string' ? investment.user : investment.user?._id,
                    accountId: typeof investment.account === 'string' ? investment.account : investment.account?._id,
                    investmentId: investment._id,
                });

                const showInvestmentType = props.includeDisplayFields?.includes('type');

                return (
                    <div>
                        {!excludeUser && investment.user && <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{(investment.user as InvestmentUser).name}</div>}

                        {investment.account && <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{(investment.account as InvestmentAccount).name}</div>}

                        {showInvestmentType && <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{investment.type}</div>}

                        {investmentLink && props.showInvestmentLink ? (
                            <Link to={investmentLink} className="table-ellipses" style={{}} title={investment.name} onClick={(e) => e.stopPropagation()}>
                                {investment.name}
                            </Link>
                        ) : (
                            <span className="table-ellipses" title={investment.name}>
                                {investment.name}
                            </span>
                        )}
                    </div>
                );
            }}
        />
    );
};
export { InvestmentSearchFilter };

const RalRequestSearchFilter = (props: Omit<GenericSearchFilterProps<RALRequest>, 'getLabel' | 'loadOptions'>) => {
    return (
        <GenericSearchFilter<RALRequest>
            {...props}
            getLabel={(account: any) => account?.name}
            loadOptions={async (inputValue: string) => {
                try {
                    const ralRequests = (
                        await api2.client.RALRequestApi.listRALRequests({
                            ...props.filter,
                            search: inputValue,
                            populate_user: true,
                            populate_ownership: true,
                            populate_signed_ral: true,
                            limit: 50,
                        })
                    ).data.ral_requests;

                    return ralRequests as any;
                } catch (e) {
                    console.error('Error loading accounts:', e);
                    return [];
                }
            }}
            formatOptionLabel={(option) => {
                if (props.formatOptionLabel) return props.formatOptionLabel(option);
                const ralRequest: RALRequest = option.value;
                if (!ralRequest) return 'All Accounts';
                return (
                    <div>
                        {renderOwnershipInfo(ralRequest, null, null, true)}

                        <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>Status: {capitalizeFirstEveryWord(ralRequest.remote_status)}</div>

                        {ralRequest.signed_at ? (
                            <div style={{ fontSize: '14px', color: 'var(--color-green)' }}>Signed At: {formatUTCDate(ralRequest.signed_at)}</div>
                        ) : ralRequest.sent_at ? (
                            <div style={{ fontSize: '14px', color: 'var(--color-blue)' }}>Sent At: {formatUTCDate(ralRequest.sent_at)}</div>
                        ) : (
                            <div style={{ fontSize: '14px' }}>Never sent or signed</div>
                        )}

                        {(ralRequest.signed_ral as RALRequestSignedRal)?.name && (
                            <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }} title={(ralRequest.signed_ral as RALRequestSignedRal).name}>
                                <span className="table-ellipses">Name: {(ralRequest.signed_ral as RALRequestSignedRal).name}</span>
                            </div>
                        )}

                        {ralRequest.deleted_at && (
                            <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'var(--color-dark-red)' }} title={ralRequest.deleted_at}>
                                Deleted
                            </div>
                        )}
                    </div>
                );
            }}
        />
    );
};

export { RalRequestSearchFilter };

const ContactSearchFilter = (props: Omit<GenericSearchFilterProps<any>, 'getLabel' | 'loadOptions'>) => {
    return (
        <GenericSearchFilter<any>
            {...props}
            getLabel={(obj) => {
                if (!obj?._id) return 'All Contacts';
                return (
                    <div style={{ display: 'flex', textAlign: 'left', flexDirection: 'column' }}>
                        <div style={{ fontSize: '12px', color: 'var(--color-gray)' }}>{obj.email}</div>
                        <div style={{ fontSize: '14px' }}>{`${obj.first_name} ${obj.last_name}`}</div>
                    </div>
                );
            }}
            loadOptions={async (inputValue: string, filter: any) => {
                try {
                    const contacts = await api2.get(`/contacts?search=${inputValue}`);
                    return contacts.data.contacts ?? [];
                } catch (e) {
                    console.error('Error loading contacts:', e);
                    return [];
                }
            }}
        />
    );
};
export { ContactSearchFilter };

// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import api2 from '../../../api2';
import '../../../styles/widget-notes.css';
import '../../../styles/widget.css';

import _ from 'lodash';
import { Letter } from 'react-letter';
import { UserPermissions } from '../../../utilities/AdvisorVue/permissions';

const UNEXPANDED_SHOW_COUNT = 3;

interface Note {
    _id?: string;
    body: string;
    date: Date;
    user?: string;
    type?: string;
    editorState?: EditorState;
    editor?: { access?: string };
}

interface NotesWidgetProps extends RouteComponentProps {
    userId?: string;
    type?: string;
    typeId?: string;
    breadcrumbs?: Record<string, string>;
    loading: (duration: number, key?: string) => void;
    loaded: (key?: string) => void;
    containerStyle?: React.CSSProperties;
    minimal?: boolean;
}

const NotesWidget: React.FC<NotesWidgetProps> = (props) => {
    const editorRef = useRef<Editor | null>(null);

    const [notes, setNotes] = useState<Note[]>([]);
    const [showAll, setShowAll] = useState(false);
    const [openNoteIdxs, setOpenNoteIdxs] = useState<Record<number, boolean>>({});
    const [editingNote, setEditingNote] = useState<number | null>(null);
    const [deleteConfirmIdx, setDeleteConfirmIdx] = useState<number | null>(null);
    const [expandedNoteIndexes, setExpandedNoteIndexes] = useState<Record<number, boolean>>({});

    const addNoteEditorState = useCallback((note: Note): Note => {
        const contentBlock = htmlToDraft(note.body);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            note.editorState = editorState;
        }
        return note;
    }, []);

    const loadData = useCallback(async () => {
        const p = props;
        if (!p.type) return;
        await p.loading(320);

        const listNotesQuery: Record<string, any> = {};

        if (p.userId) {
            listNotesQuery.userId = p.userId;
        }

        switch (p.type) {
            case 'document':
                listNotesQuery.type = p.type;
                listNotesQuery['documents'] = [p.typeId];
                break;
            case 'investment':
                listNotesQuery.type = p.type;
                listNotesQuery['investments'] = [p.typeId];
                break;
            case 'admin_document_type':
                listNotesQuery.type = p.type;
                listNotesQuery['admin_document_types'] = [p.typeId];
                break;
            case 'admin_investment':
                listNotesQuery.type = p.type;
                listNotesQuery['admin_investments'] = [p.typeId];
                break;
            case 'admin_audit_result':
                listNotesQuery.type = p.type;
                listNotesQuery['admin_audit_results'] = [p.typeId];
                break;
            case 'admin_investment_master':
                listNotesQuery.type = p.type;
                listNotesQuery['admin_investment_masters'] = [p.typeId];
                break;
            case 'admin_asset_manager':
                listNotesQuery.type = p.type;
                listNotesQuery['admin_asset_managers'] = [p.typeId];
                break;
            default:
                break;
        }

        if (p.breadcrumbs) {
            for (let key in p.breadcrumbs) {
                listNotesQuery[key] = p.breadcrumbs[key];
            }
        }

        let notes: any = (await api2.client.NotesApi.listNotes(listNotesQuery)).data.notes ?? [];

        notes = notes.map(addNoteEditorState);

        setNotes(notes);
        setEditingNote(null);
        setOpenNoteIdxs({});
        p.loaded();
    }, [props, addNoteEditorState]);

    useEffect(() => {
        loadData();
    }, []);

    const prevProps = useRef<NotesWidgetProps>(props);

    useEffect(() => {
        const p = props;
        const prev = prevProps.current;
        if (!_.isEqual(p.userId, prev.userId) || !_.isEqual(p.type, prev.type) || !_.isEqual(p.typeId, prev.typeId) || !_.isEqual(p.breadcrumbs, prev.breadcrumbs)) {
            loadData();
        }
        prevProps.current = props;
    }, [props, loadData]);

    const updateNote = async (diff: Partial<Note>, idx: number) => {
        const updatedNotes = [...notes];
        updatedNotes[idx] = { ...updatedNotes[idx], ...diff };
        setNotes(updatedNotes);
    };

    const toggleNote = async (idx: number) => openNote(idx, !openNoteIdxs[idx]);
    const openNote = async (idx: number, open = true) => {
        setOpenNoteIdxs((prev) => ({ ...prev, [idx]: open }));
    };

    const addNote = async () => {
        if (editingNote === 0 && !notes[0]._id) return;
        const newNote = addNoteEditorState({
            body: '',
            date: new Date(),
        });
        setNotes([newNote, ...notes]);
        setEditingNote(0);
    };

    const deleteNote = async (idx: number) => {
        const p = props;
        const note = notes[idx];
        if (note._id) {
            p.loading(320, 'delete_note');

            await api2.client.NotesApi.deleteNote({ note_id: note._id });
            p.loaded('delete_note');
            loadData();
        } else {
            const updatedNotes = notes.filter((_, i) => i !== idx);
            setNotes(updatedNotes);
            setEditingNote(null);
        }
    };

    const saveNote = async (idx: number) => {
        const p = props;
        let note = notes[idx];
        if (!note.user && p.userId) {
            note.user = p.userId;
        }
        if (p.type) {
            if (p.type && p.typeId) {
                (note as any)[p.type] = p.typeId;
            }
        }
        note.type = p.type;

        note = {
            ...note,
            ...p.breadcrumbs,
        };

        if (note._id) {
            p.loading(320, 'save_note');

            await api2.client.NotesApi.updateNote({
                note_id: note._id,
                UpdateNoteRequest: note,
            });
            p.loaded('save_note');
        } else {
            p.loading(320, 'save_note');

            await api2.client.NotesApi.createNote({
                CreateNoteRequest: {
                    ...note,
                    date: new Date(note.date).toISOString(),
                },
            });
            p.loaded('save_note');
        }
        loadData();
        setEditingNote(null);
    };

    const renderNote = (note: Note, noteIdx: number) => {
        const p = props;
        const isEditing = editingNote === noteIdx;
        const hasBody = note.body?.length > 0;
        const deleteConfirm = deleteConfirmIdx === noteIdx;

        return (
            <div key={`noticeRow${noteIdx}`} className={`notes_row ${isEditing ? 'notes_editing' : 'notes_notEditing'}`} onClick={() => toggleNote(noteIdx)}>
                {isEditing && (
                    <div style={{ padding: '20px' }}>
                        <Editor
                            editorRef={(ref) => (editorRef.current = ref as Editor)}
                            toolbarClassName="notes_toolbarClassName"
                            wrapperClassName="notes_wrapperClassName"
                            editorClassName="notes_editorClassName"
                            editorState={note.editorState}
                            onEditorStateChange={async (editorState: EditorState) => {
                                const body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
                                await updateNote({ body, editorState }, noteIdx);
                            }}
                            toolbar={{
                                options: p.minimal ? ['inline', 'list', 'fontSize'] : ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'emoji'],
                                inline: {
                                    options: p.minimal ? ['bold', 'italic', 'underline'] : ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
                                },
                                list: {
                                    inDropdown: false,
                                    options: ['unordered', 'ordered'],
                                },
                            }}
                        />

                        <div className="notes_editBtns">
                            <div
                                style={{ marginRight: '10px', color: 'var(--color-dark-red)' }}
                                className="a"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (deleteConfirm) {
                                        deleteNote(noteIdx);
                                    } else {
                                        setDeleteConfirmIdx(noteIdx);
                                        setTimeout(() => setDeleteConfirmIdx(null), 2000);
                                    }
                                }}
                            >
                                {deleteConfirm ? 'Confirm' : 'Delete'}
                            </div>

                            <div
                                style={{ marginRight: '10px' }}
                                className="a"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (!note._id && noteIdx === 0) {
                                        deleteNote(noteIdx);
                                    } else {
                                        setEditingNote(null);
                                    }
                                }}
                            >
                                Cancel
                            </div>

                            <div
                                style={{ marginRight: '10px', color: !hasBody ? 'var(--color-light-gray)' : undefined }}
                                className={hasBody ? 'a' : ''}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (hasBody) saveNote(noteIdx);
                                }}
                            >
                                Save
                            </div>
                        </div>

                        <div style={{ clear: 'both', height: '20px' }} />
                    </div>
                )}

                {!isEditing && (
                    <div
                        className={`notes_view`}
                        onClick={() => {
                            setExpandedNoteIndexes((prev) => ({
                                ...prev,
                                [noteIdx]: !prev[noteIdx],
                            }));
                        }}
                    >
                        <div
                            className="notes_row_body"
                            style={{
                                height: expandedNoteIndexes[noteIdx] ? 'auto' : undefined,
                            }}
                        >
                            <Letter html={note.body} />
                        </div>
                        <div className={'notes_row_dueDate'}>
                            {UserPermissions().canUpdateAnyInvestments && (
                                <div
                                    className={'edit-icon'}
                                    title="edit"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setEditingNote(noteIdx);
                                    }}
                                />
                            )}
                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                {note.editor?.access && note.editor?.access !== 'investor' && p.type !== 'admin_audit_result' && (
                                    <span
                                        style={{
                                            marginRight: '15px',
                                            color: 'var(--color-light-gray)',
                                        }}
                                    >
                                        {note.editor?.access}
                                    </span>
                                )}
                                <span>{moment(note.date).format('MMM DD, YYYY')}</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const s = { notes, showAll, openNoteIdxs, editingNote, deleteConfirmIdx, expandedNoteIndexes };
    const p = props;
    const displayedNotes = s.showAll ? s.notes : s.notes.slice(0, UNEXPANDED_SHOW_COUNT);

    return (
        <div id="notes_component" className="widget notes_component pdfIgnore" style={{ ...p.containerStyle }}>
            <div className="notes_header">
                <div className="notes_headerText">
                    There are {notes.length} note{notes.length !== 1 ? 's' : ''}
                </div>
                {UserPermissions().canUpdateAnyInvestments && <div className="plus-icon" onClick={addNote} />}
            </div>
            {displayedNotes.map(renderNote)}
            {s.notes.length > UNEXPANDED_SHOW_COUNT && (
                <div className="notes_SeeAll">
                    <span onClick={() => setShowAll(!s.showAll)}>Show {s.showAll ? 'less' : 'all'}</span>
                </div>
            )}
        </div>
    );
};

export default withRouter(NotesWidget);
